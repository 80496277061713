import notifSystem from '../notifSystem';
import { importXlsxFile } from '@lba-dev/package.local-globals/xlsxFunctions';

import { Csv } from './Csv';

const checkValidKeys = ({ requiredKeys = [] }, csvKeys) => {
  if (!csvKeys || !csvKeys.length) {
    return false;
  }
  const array = csvKeys.map(e => e.replace(/é|è/g, 'e').toLowerCase().trim());
  const errorKeys = [];
  for (let i = 0; i < requiredKeys.length; i++) {
    const key = requiredKeys[i].replace(/é|è/g, 'e').toLowerCase();
    if (!array.includes(key) && !errorKeys.includes(key)) {
      errorKeys.push(key);
    }
  }
  if (errorKeys.length) {
    notifSystem.error('Veuillez vérifier le fichier',
      `Les colonnes manquantes sont: ${errorKeys.join(', ')}`);
    return false;
  }
  return true;
};

const formatValue = ({
  'price': (value) => +((+value * 100).toFixed(0)),
  // eslint-disable-next-line no-useless-escape
  'currency': (e) => Number(e.replace(',', '.').replace(/[^0-9\.]+/g, ''))
});

const formatData = ({
  keys,
  frenchKeys,
}, csvContent, csvKeys) => {
  const array = frenchKeys.map(e => e.toLowerCase());
  const final = csvContent.map(e => {
    const obj = {};
    for (let i = 0; i < csvKeys.length; i++) {
      const key = csvKeys[i].replace(/é|è/gi, 'e').toLowerCase().trim();
      if (array.includes(key) && e[i]) {
        const index = array.findIndex(e => e === key);
        const dbKey = keys[index];
        if (dbKey) {
          obj[dbKey.name || dbKey] = e[i];
          if (dbKey.customFormat) {
            obj[dbKey.name] = dbKey.customFormat(e[i]);
          } else if (dbKey.type && formatValue[dbKey.type]) {
            obj[dbKey.name] = formatValue[dbKey.type](e[i]);
          }
        }
      }
    }
    return obj;
  });
  return final;
};


const handleCSVFile = (props, f) => {
  const file = f.target.files[0];
  if (file) {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onloadend = e => {
      if (e.target.readyState === FileReader.DONE && e.target.result) {
        const data = e.target.result;
        const dataParse = importXlsxFile(data, rABS);
        const res = new Csv(dataParse);
        const csvKeys = res.getHeader();
        const csvContent = res.toArrayWithoutHeader();
        if (checkValidKeys(props, csvKeys)) {
          let dataArray = formatData(props, csvContent, csvKeys);
          if (props.month) {
            dataArray = dataArray.map(e => ({ ...e, date: props.month }));
          }
          return props.setData(dataArray);
        }
      }
    };
    if (file.name.endsWith('.csv')) {
      reader.readAsText(file);
    } else if (rABS) {
      reader.readAsBinaryString(file);
    }
    else {
      reader.readAsArrayBuffer(file);
    }
  }
  f.target.value = '';
};

export {
  checkValidKeys,
  formatData,
  handleCSVFile
};
