import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReportList from '../../ReportList/ReportList';

const mapStateToProps = ({
  dialog: {
    contentProps: {
      signalId,
      setSignalements
    },
  },
}) => ({
  signalId,
  setSignalements
});


class SingleSignalDialog extends Component {
  state = {
    didAdd: false
  }

  reportListener = (bool) => {
    this.setState({
      didAdd: bool,
    });
  };

  render() {
    const {
      signalId,
      setSignalements
    } = this.props;
    return (
      <ReportList
        expansionTitle="Signalements"
        didAdd={this.state.didAdd}
        reportListener={this.reportListener}
        search={'_id'}
        searchObjectId={signalId}
        elevation={0}
        noMargin={true}
        callback={setSignalements}
      />
    );
  }
}

SingleSignalDialog.defaultProps = {
  open: true
};

export default connect(mapStateToProps)(SingleSignalDialog);
