import React from 'react';
import { withStyles } from 'tss-react/mui';
import { data as categories } from '@lba-dev/package.local-globals/categories';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import { Add } from '@mui/icons-material';
import { List } from 'immutable';

const CatButtons = ({
  merge,
  classes,
  selectCat,
  updateState,
  path
}) => {
  const buttons = merge && merge.getIn(path, new List())
    .map((value, i) => {
      let e = categories.find((element) => (element.name === value));
      return (
        <Button key={i} variant="contained"
          style={{ backgroundColor: e.color[500] }}
          onClick = { () => selectCat(e.name) }
          className={classes.button}
        >{e.shortName}
        </Button>
      );
    });
  return buttons.push(<Fab color="inherit" size='small' key={20}
    className={classes.fabGreen}
    onClick={ () => updateState('openAdd', true)}>
    <Add />
  </Fab>);
};

const styles = {
  button: {
    marginRight: 3,
    marginBottom: 3,
    color: 'white',
  }
};
export default withStyles(CatButtons, styles);
