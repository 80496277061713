import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Delete } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { minute, formatCompleteDate } from '../../utils/date';
import api from '../../api';
import notifSystem from '../../notifSystem';
import Typography from '@mui/material/Typography';
import { reasons } from '@lba-dev/package.local-globals/absencesConstants';
import { moment } from '@lba-dev/package.local-globals/moment';
import CustomTextField from '../CustomInputs/CustomTextField';
import DateAndHour from './DateAndHourAbsence';
import CustomCheckbox from '../CustomInputs/CustomCheckbox';

const styles = {
  menuHeight: {
    maxHeight: 300
  },
  rootContainer: {
    overflow: 'hidden',
    height: 500
  },
  container: {
    marginTop: 10
  },
  full: {
    width: '100%',
    backgroundColor: '#eee'
  },
  section: {
    height: '100%'
  },
  button: {
    textAlign: 'center',
    marginTop: 15
  },
  list: {
    maxHeight: 165,
    overflow: 'auto'
  },
  listItem: {
    justifyContent: 'center'
  }
};

const absencesNotif = {
  notRetrieved: () => notifSystem
    .error('Absence', 'Les absences n\'ont pas été récupérées'),
  added: () => notifSystem
    .success('Absence', 'L\'absence a bien été prise en compte'),
  notAdded: () => notifSystem
    .error('Absence', 'L\'absence n\'a pas pu étre ajoutée'),
  hasIntervention: () => notifSystem
    .error('Absence', 'L\'artisan a une intervention durant cette période'),
  deleted: () => notifSystem
    .success('Absence', 'L\'absence a bien été supprimée'),
  notDeleted: () => notifSystem
    .error('Absence', 'L\'absence n\'a pas pu être supprimée'),
  notFilledReason: () => notifSystem
    .error('Absence', 'Motif manquant')
};

const generateTime = (date, type = 'from') => {
  const time = date.getTime();
  if (type === 'from') {
    return new Date(time + (15 * minute) - (time % (15 * minute)));
  }
  return new Date(time - (15 * minute) - (time % (15 * minute)));
};

const duringTime = ({ from, to }) => {
  const today = new Date();

  if (today.getTime() < from.getTime()) {
    return 1;
  }
  if (today.getTime() > to.getTime()) {
    return 2;
  }
  return 0;
};

class Absences extends Component {
  state = {
    absences: [],
    from: generateTime(new Date(new Date().setHours(0, 15, 0, 0))),
    to: generateTime(new Date(new Date().setHours(23, 15, 0, 0))),
    reason: '',
    reasonIndex: reasons.length - 1,
    currentAbsences: false,
    nextAbsences: false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.artisanId !== this.props.artisanId) {
      this.retrieveAbsences(this.props.artisanId);
    }
  }

  componentDidMount() {
    this.retrieveAbsences(this.props.artisanId);
  }

  setAbsences = (value, type) => {
    const obj = Object.assign({}, this.state);
    obj[type] = value;
    if (type === 'from' && obj.from.getTime() > obj.to.getTime()) {
      obj.to = obj.from;
    } else if (type === 'to' && obj.to.getTime() < obj.from.getTime()) {
      obj.from = obj.to;
    }
    this.setState({
      from: obj.from,
      to: obj.to
    });
  }

  handleAbsences = () => {
    const {
      from,
      to,
      reason,
      reasonIndex
    } = this.state;
    const {
      artisanId,
      callback
    } = this.props;

    if ((!reasonIndex && reasonIndex !== 0)
      || (reasonIndex === reasons.length - 1 && !reason)) {
      return absencesNotif.notFilledReason();
    }
    api.absences.post({
      artisanId,
      from,
      to,
      reason: reason || reasons[reasonIndex]
    }).then(() => {
      absencesNotif.added();
      this.retrieveAbsences(artisanId);
      if (callback) {
        callback(`Artisan n'est pas disponible du ${
          moment(from).format('LLL')} au ${
          moment(to).format('LLL')}, raison : ${
          reason || reasons[reasonIndex]}`);
      }
    }).catch(e => e.message === 'Forbidden'
      ? absencesNotif.hasIntervention()
      : absencesNotif.notAdded()
    );
  }

  retrieveAbsences = id => api
    .absences
    .get(id)
    .then(res => {
      const allAbsences = res.body().map(a => {
        const { _id, reason, to, from } = a.data();
        return {
          _id,
          reason,
          from: new Date(from),
          to: new Date(to)
        };
      });
      const now = new Date();

      const filteredAbsences =
      (!this.state.currentAbsences && !this.state.nextAbsences)
        ? allAbsences
        : allAbsences.filter(a =>
          (this.state.currentAbsences && a.from <= now && a.to >= now) ||
        (this.state.nextAbsences && a.from > now));

      this.setState({
        absences: filteredAbsences
          .sort((a, b) => a.from.getTime() > b.from.getTime() ? -1 : 1)
      });
    })
    .catch(() => absencesNotif.notRetrieved());


  deleteAbsence = ({ _id, from, to }) => {
    const { artisanId } = this.props;

    api.one('absences', _id).delete({ artisanId, from, to }).then(() => {
      absencesNotif.deleted();
      this.retrieveAbsences(artisanId);
    }).catch(() => absencesNotif.notDeleted());
  }

  onChangeReasonIndex = e => this.setState({ reasonIndex: e.target.value })

  onChangeReason = reason => this.setState({ reason });

  handleChangeAbsences(artisanId, isNextAbsences) {
    const targetState = isNextAbsences ? 'nextAbsences' : 'currentAbsences';

    this.setState(
      (prevState) => ({
        [targetState]: !prevState[targetState],
      }),
      () => {
        this.retrieveAbsences(artisanId);
      }
    );
  }


  render() {
    const { classes, artisanId } = this.props;
    const {
      from,
      to,
      absences,
      reasonIndex,
      reason,
      currentAbsences,
      nextAbsences
    } = this.state;
    return (
      <Grid container className={classes.rootContainer}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h6">
              ABSENT DU:
            </Typography>
          </Grid>
          <Grid container spacing={3}>
            <DateAndHour
              xs={12}
              lg={6}
              dateLabel="Date d'absence"
              type="from"
              absence
              date={from}
              setData={this.setAbsences}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              {'JUSQU\'AU:'}
            </Typography>
          </Grid>
          <Grid container spacing={3}>
            <DateAndHour xs={12} lg={6}
              dateLabel="Date d'absence"
              type="to"
              absence
              date={to}
              setData={this.setAbsences}
            />
          </Grid>
          <Grid container spacing={3} className={classes.container}>
            <Grid item xs={6} sm={6}>
              <FormControl className={classes.full}>
                <InputLabel>Motif</InputLabel>
                <Select
                  value={reasonIndex}
                  onChange={this.onChangeReasonIndex}
                  MenuProps={{
                    classes: { paper: classes.menuHeight },
                  }}
                >
                  {reasons.map((reason, index) => (
                    <MenuItem key={index} value={index}>{reason}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {reasonIndex === reasons.length - 1 &&
              <CustomTextField
                label="Entrez un motif"
                value={reason}
                path={[]}
                setData={(path, value) => this.onChangeReason(value)}
                capsLocked={false}
                rowsMax={1}
                maxLength="150"
              />}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} className={classes.button}>
            <Button
              color="primary"
              onClick={this.handleAbsences}>
              {'Valider l\'absence'}
            </Button>
          </Grid>
        </Grid>
        {
          <Grid container justifyContent={'center'}>
            <CustomCheckbox
              setData={() => this.handleChangeAbsences(artisanId, false)}
              checked={currentAbsences}
              label={'Absences en cours'}
            />
            <CustomCheckbox
              setData={() => this.handleChangeAbsences(artisanId, true)}
              checked={nextAbsences}
              label={'Absences à venir'}
            />
          </Grid>
        }
        <Grid container>
          <Grid item xs={12} sm={12}>
            <List className={classes.list}>
              {absences.map(absence =>
                <ListItem key={absence._id} className={classes.listItem}>
                  <ListItemText
                    primary={`${[
                      'Est absent du ',
                      'Sera absent du ',
                      'A été absent du '
                    ][duringTime({
                      from: new Date(absence.from),
                      to: new Date(absence.to)
                    })]} ${
                      formatCompleteDate(absence.from)
                    } au ${
                      formatCompleteDate(absence.to)
                    }`}
                    secondary={absence.reason || ''} />
                  {absence.to.getTime() > new Date().getTime() &&
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => this.deleteAbsence(absence)}
                      size="large">
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>}
                </ListItem>)
              }
            </List>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(Absences, styles);
