import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../../actions/dialog';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Content from './Content';
import { Map } from 'immutable';

const setDomofinance = setDialogContentProp('domofinance');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      data,
      domofinance
    },
  },
  users,
  userId
}) => ({
  data,
  domofinance,
  user: users.find(e => e._id === userId)
});

const mapDispatchToProps = {
  setDomofinance
};

class DomofinanceDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      current: new Map({
        domofinance: props.domofinance
      })
    };
  }

  setData = (path, value, cb) => {
    this.setState((prevState) => ({
      current: prevState.current.setIn(path, value)
    }), () => {
      if (cb) {
        cb();
      }
      this.props.setDomofinance(
        this.state.current.get('domofinance'));
    });
  };

  render() {
    const current = this.state.current;
    return (
      <Grid container spacing={3} alignItems="center">
        {!current.getIn(['domofinance', 'modified']) ?
          <Grid item xs={12}>
            <Typography variant='caption' align="center" display="block"
              children='Veuillez enregister afin de valider le financement' />
          </Grid> : ''}
        <Content
          merge={current}
          data={this.props.data}
          user={this.props.user}
          setData={this.setData}
        />
      </Grid>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DomofinanceDialog);
