import React, { Component } from 'react';

import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
  loadingDiv: {
    backgroundColor: '#fff',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingOverrideDiv: {
    position: 'absolute',
    width: '98%',
    height: '50%',
  },
  button: {
    margin: theme.spacing(0.6),
  }
});

export default function withLoading({
  circularProgressProps = { size: 24 },
  customHandling = false,
  override = false,
  loadingMessage = ''
} = {}) {
  return WrappedComponent => withStyles(class withLoading extends Component {
    state = { loading: false }

    setLoading = loading => this.promisifiedSetState({ loading })

    render() {
      const { loading } = this.state;
      const { classes, ...otherProps } = this.props;
      const props = {
        ...otherProps,
        loading,
        setLoading: this.setLoading
      };

      const component = <WrappedComponent {...props} />;

      if (customHandling) {
        return component;
      }
      if (override && loading) {
        return (
          <div className={
            `${classes.loadingOverrideDiv} ${classes.loadingDiv}`}>
            <CircularProgress size={70} color="secondary" />
            <Typography variant="h6" className={classes.button}>
              {loadingMessage || 'Chargement en cours...'}
            </Typography>
          </div>
        );
      }
      return loading
        ? <div className={classes.loadingDiv}>
          <CircularProgress {...circularProgressProps} />
        </div>
        : component;
    }
  }, styles);
}
