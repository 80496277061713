import React from 'react';
import {
  Avatar,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { closeDialog, setDialogContentProp } from '../../../actions/dialog';
import Calls from '../../Calls/Calls';
import store from '../../../store';
import {
  callTypes,
  REPONDEUR,
  OK,
  DECALAGE,
  AUTRE,
  isProblem
} from '@lba-dev/package.local-globals/callTypes';
import { sendCallTypes } from '../../Calls/callFunctions';
import { Check, Voicemail, Warning } from '@mui/icons-material';
import { blue, green, red } from '@mui/material/colors';

const setStep = setDialogContentProp('step');
const setPanne = setDialogContentProp('panneComment');

const list = [
  { type: [REPONDEUR], xs: 2, icon: <Voicemail />, color: blue[500] },
  { type: isProblem, xs: 8, icon: <Warning />, color: red[500] },
  { type: [OK, DECALAGE], xs: 2, icon: <Check />, color: green[500] }
];

const PickCallType = ({ call }) => (
  <Grid container justifyContent="center" spacing={2}>
    <Grid item xs={8}>
      <Calls
        sm={12}
        elem={[call]}
        keyUrl="urlEnregistrement"
        dontPlay={false}
        autoPlay
      />
    </Grid>
    <Grid item xs={12}>
      <List>
        {list.map((e, i) => (
          <React.Fragment key={i}>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  children={e.icon}
                  style={{ color: '#fff', backgroundColor: e.color }}
                />
              </ListItemAvatar>
              <ListItemText
                secondary={
                  <Grid container spacing={2} justifyContent="space-between">
                    {callTypes
                      .filter(c => e.type.includes(c.id))
                      .map((e, i) => (
                        <Grid key={i} item xs={e.id === OK ? 2 : 'false'}>
                          <Button
                            fullWidth
                            children={e.label}
                            variant="outlined"
                            style={{
                              color: e.color[800], borderColor: e.color[500]
                            }}
                            onClick={() => {
                              const url = call.urlEnregistrement;
                              const type = e.id === DECALAGE ? OK : e.id;
                              sendCallTypes(type, url);
                              if (e.id === AUTRE) {
                                store.dispatch(setPanne({ url, comment: '' }));
                                return store.dispatch(setStep(AUTRE));
                              }
                              if (e.id === DECALAGE) {
                                return store.dispatch(setStep(DECALAGE));
                              }
                              store.dispatch(closeDialog());
                            }}
                          />
                        </Grid>
                      ))}
                  </Grid>
                }
              />
            </ListItem>
            {i !== 2 ? <Divider variant="inset" component="li" /> : ''}
          </React.Fragment>
        ))}
      </List>
    </Grid>
  </Grid>
);
export default PickCallType;
