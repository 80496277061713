import React, { Component } from 'react';
import { connect } from 'react-redux';
import { moment } from '@lba-dev/package.local-globals/moment';
import { List } from 'immutable';

import { withStyles } from 'tss-react/mui';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import {
  Delete
} from '@mui/icons-material';
import { green, blue } from '@mui/material/colors';

import CustomSelect from '../CustomInputs/CustomSelect';
import MoneyInput from '../CustomInputs/MoneyInput';
import DatePicker from '../DatePicker';
import CustomTextField from '../CustomInputs/CustomTextField';
import { TVA } from '@lba-dev/package.local-globals/comptaCalcules';
import {
  data as modes
} from '@lba-dev/package.local-globals/encaissementMode';

const styles = {
  paper: {
    width: '100%'
  },
  addLine: {
    float: 'right',
    color: blue[500],
    margin: '1%'
  },
  table: {
    overflowY: 'visible',
    backgroundColor: green[500]
  },
  headtr: {
    height: 56
  },
  tableCell: {
    padding: '10px',
    border: '1px solid #000000',
  },
  button: {
    width: '40px',
    height: '40px'
  },
  disabled: {
    pointerEvents: 'none'
  }
};

const mapStateToProps = ({ users, userId }) => ({ users, userId });

class EncaissementNoOs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      line: props.line,
      data: {},
      encaissements: new List()
    };
    this.getLine = this.getLine.bind(this);
    this.updateLine = this.updateLine.bind(this);
    this.delLine = this.delLine.bind(this);
    this.addLine = this.addLine.bind(this);
    this.getCompenent = this.getCompenent.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.encaissements !== state.encaissements) {
      return ({
        line: props.line,
        data: props.data,
        encaissements: props.encaissements
      });
    }
    return null;
  }

  updateLine(i, l) {
    let line = this.state.encaissements.toJS();
    line[i] = l;
    this.props.setData('encaissements', new List(line));
  }

  addLine() {
    const { data, tva } = this.props;
    let line = this.state.encaissements.toJS();
    line.push(this.props.line({ tva, id: data ? data.id : 0 }));
    this.props.setData('encaissements', new List(line));
  }

  delLine(index) {
    if (index >= 0) {
      let tmp = this.state.encaissements.toJS();
      tmp.splice(index, 1);
      this.props.setData('encaissements', new List(tmp));
    }
  }

  getCompenent(line, index) {
    const {
      classes,
      justDisplay = false,
      users
    } = this.props;
    const user = line.userId && users.find(u => u._id === line.userId);
    return (
      <TableRow key={index}>
        <TableCell className={classes.tableCell}>
          <CustomTextField
            fullWidth
            type="string"
            value={''}
            path={['']}
            setData={(p, v) => {
              line.libelle = v;
              this.updateLine(index, line);
            }}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography children={(user && user.login) || ''} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <CustomTextField
            fullWidth
            type="string"
            value={line.numChq || ''}
            path={['']}
            disabled={
              (line.isArchive || justDisplay) && line.mode !== 'caution'
            }
            setData={(p, v) => {
              line.numChq = v;
              this.updateLine(index, line);
            }}
          />
        </TableCell>
        <TableCell
          className={classes.tableCell}
          style={{ pointerEvents: 'none' }}
        >
          <DatePicker
            disabled={true}
            style={{ width: '120px' }}
            withInput
            startDate={new Date(line.saisie)}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <DatePicker
            disabled={
              (line.isArchive || justDisplay) && line.mode !== 'caution'
            }
            withInput
            startDate={new Date(line.encaissement)}
            onChange={(v) => {
              line.encaissement = new Date(moment(v).toDate()).toISOString();
              this.updateLine(index, line);
            }}
            before={['virement', 'lydia', 'hipay'].includes(line.mode)}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <CustomSelect
            disabled={
              (line.isArchive || justDisplay) && line.mode !== 'caution'
            }
            path={['']}
            value={line.mode}
            setData={(p, v) => {
              line.mode = v;
              this.updateLine(index, line);
            }}
            fullWidth
          >
            {modes.map((e) => (
              <MenuItem key={e._id} value={e.value}>
                {e.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <MoneyInput
            disabled={
              (line.isArchive || justDisplay) && line.mode !== 'caution'
            }
            fullWidth
            type="number"
            defaultValue={line.montant}
            path={['']}
            setData={(p, v) => {
              line.montant = v;
              this.updateLine(index, line);
            }}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <CustomSelect
            path={['']}
            value={line.tva}
            setData={(p, v) => {
              line.tva = v;
              this.updateLine(index, line);
            }}
            disabled={
              (line.isArchive || justDisplay) && line.mode !== 'caution'
            }
            fullWidth
          >
            {TVA.map((e) => (
              <MenuItem key={e.number} value={e.number}>
                TVA {e.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Fab
            className={classes.button}
            disabled={
              (line.isArchive || justDisplay) && line.mode !== 'caution'
            }
            onClick={() => this.delLine(index)}
            children={<Delete />}
          />
        </TableCell>
      </TableRow>
    );
  }

  getLine() {
    const encaissements = (this.state.encaissements || new List());
    return (
      encaissements.map((line, index) =>
        this.getCompenent(line, index)
      ));
  }

  render() {
    const { classes, justDisplay } = this.props;
    return (
      <Paper className={classes.paper}>
        {!justDisplay && <Button className={classes.addLine}
          onClick={this.addLine}>
          Ajouter une ligne
        </Button>}
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.headtr}>
              <TableCell style={{ minWidth: '100px' }}
                className={classes.tableCell}>
                Libellé
              </TableCell>
              <TableCell className={classes.tableCell}>
                Utilisateur
              </TableCell>
              <TableCell className={classes.tableCell}>
                Num Chq
              </TableCell>
              <TableCell className={classes.tableCell}
                style={{ minWidth: '85px' }}>
                Date de saisie
              </TableCell>
              <TableCell className={classes.tableCell}>
                Date d'encaissement
              </TableCell>
              <TableCell className={classes.tableCell}>
                Mode
              </TableCell>
              <TableCell className={classes.tableCell}>
                Montant
              </TableCell>
              <TableCell className={classes.tableCell}>
                TVA
              </TableCell>
              <TableCell className={classes.tableCell}>
                Supp
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.getLine()}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default connect(mapStateToProps)(withStyles(EncaissementNoOs, styles));
