import React from 'react';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Web } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { List } from 'immutable';
import { withStyles } from 'tss-react/mui';
import { dialogGalerie } from '../../actions/prosp';

const styles = ({
  Source: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  SourceImg: {
    display: 'flex',
    'flexDirection': 'row',
    'justifyContent': 'space-between'
  },
  SourceLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    marginBottom: 10
  }
});

const getProfilImg = (merge) => {
  const img = merge.getIn(['elem', 'obj', 'img'], null) || null;
  const images =
  merge.getIn(['elem', 'obj', 'images'], new List()) || new List();
  return img ? img : images.get(0, null);
};

const SourcesImg = ({ merge, classes, updateState, seeMore }) =>
  <CardContent className={classes.SourceImg}>
    <div className={classes.Source}>
      <Sources
        urls={merge.getIn(['elem', 'obj', 'url'],
          new List()) || new List()}
        classes={classes}
        seeMore={seeMore}
      />
      {!seeMore && (merge.getIn(['elem', 'obj', 'url'],
        new List()) || new List()).size > 3 &&
      <Button onClick={() => updateState('seeMore', true)}
        style={{ margin: 'auto' }}
        variant="outlined"
        color="primary"
        children="Voir plus">
      </Button>}
    </div>
    <div>
      <IconButton onClick={() => updateState('openIfram', true)} size="large">
        <Web />
      </IconButton>
    </div>
    {getProfilImg(merge) && <Avatar
      alt=""
      src={getProfilImg(merge)}
      style={{ width: 60, height: 60 }}
      onClick={() => dialogGalerie({ merge })}
    />}
  </CardContent>;

const Sources = ({ urls, classes, seeMore }) =>
  urls.map((el, i) =>
    !seeMore ? (
      i < 3 && (
        <a
          key={i}
          className={classes.SourceLink}
          href={el.get('name')}
          target="_blank"
          rel="noopener noreferrer"
        >
          Source : {el.get('source')}
        </a>
      )
    ) : (
      <a
        key={i}
        className={classes.SourceLink}
        href={el.get('name')}
        target="_blank"
        rel="noopener noreferrer"
      >
        Source : {el.get('source')}
      </a>
    )
  );

export default withStyles(SourcesImg, styles);
