import React from 'react';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import {
  data as natureSAV,
  SAV_RECLA
} from '@lba-dev/package.local-globals/natureSAV';
import {
  data as cSAV,
  SAV_ORDINAIRE
} from '@lba-dev/package.local-globals/cSAV';
import { withStyles } from 'tss-react/mui';
import { green, red } from '@mui/material/colors';

import CustomTextField from '../../CustomInputs/CustomTextField';
import {
  data as SAVStatus,
  SAV_ECG,
  SAV_APR,
  SAV_ENC
} from '@lba-dev/package.local-globals/SAVStatus';
import { ArrowDropDown } from '@mui/icons-material';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import store from '../../../store';
import { setDialogContentProps } from '../../../actions/dialog';
import { getSmsEnvoiSAV, steps } from '../../../actions/priseDeContact';

const listActions = (nature, user, date) => [
  {
    name: 'Mails',
    value: steps.MAIL,
    disabled: nature === SAV_RECLA,
    hide: false
  },
  {
    name: 'Courriers',
    disabled: nature === SAV_RECLA,
    hide: false
  },
  {
    name: 'Prise de RDV',
    value: steps.RDV,
    disabled: false,
    hide: nature === SAV_RECLA
  },
  {
    name: 'Changement de SST',
    value: steps.CSSTMAIL,
    disabled: !date,
    hide: nature === SAV_RECLA
  },
  {
    name: 'A Demarcher',
    value: steps.DEM,
    disabled: !permit(user, { key: 'savAssign' }),
    hide: nature === SAV_RECLA
  }
];

const styles = {
  buttons: {
    padding: 8,
  },
  redButton: {
    color: red[500],
    borderColor: red[500],
  },
  greenButton: {
    color: green[500],
    borderColor: green[500],
  },
};

const updateNature = ({ nature, savInter, value }) => {
  const contentProps = store.getState().dialog.contentProps;
  const newContentProps = {
    ...contentProps,
    nature: Number(value),
    ...(nature === SAV_RECLA
      ? {
        status: SAVStatus.find(e => e._id === savInter.status).isReclamation
          ? SAV_APR
          : savInter.status
      }
      : {}),
    ...(Number(value) === SAV_RECLA
      ? {
        catSAV: SAV_ORDINAIRE,
        status: SAV_ECG,
        mail: { name: '', text: '', subject: '', desMail: '' },
        mailSST: { name: '', text: '', subject: '', desMail: '' },
        attachments: [],
        attachmentsSST: [],
      }
      : {})
  };
  store.dispatch(setDialogContentProps(newContentProps));
};

const SavInfo = ({
  nature,
  catSAV,
  comment,
  status,
  updateSAVData,
  savInter,
  classes,
  date,
  user,
}) => (
  <Grid container justifyContent="center" align="center" spacing={2}>
    <Grid item xs={12}>
      <Button
        fullWidth
        children="Nature du SAV"
        color="secondary"
        variant="outlined"
      />
    </Grid>
    <Grid item xs={12}>
      <FormControl component="fieldset">
        <RadioGroup
          value={nature}
          style={{ display: 'inline' }}
          onChange={(_, value) => updateNature({ nature, savInter, value })}
        >
          {natureSAV.map((e) => (
            <FormControlLabel
              key={e._id}
              value={e._id}
              label={e.name}
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <Button
        fullWidth
        children="Catégorie du SAV"
        color="primary"
        variant="outlined"
      />
    </Grid>
    <Grid item xs={12}>
      <FormControl component="fieldset" disabled={nature === SAV_RECLA}>
        <RadioGroup
          value={catSAV}
          style={{ display: 'inline' }}
          onChange={(_, val) => updateSAVData('catSAV', Number(val))}
        >
          {cSAV.map((e) => (
            <FormControlLabel
              key={e._id}
              value={e._id}
              label={e.name}
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <Button
        fullWidth
        children="Actions"
        className={classes.greenButton}
        variant="outlined"
      />
    </Grid>
    {listActions(nature, user, date).map(
      (e, i) =>
        !e.hide && (
          <Grid key={i} item className={classes.buttons}>
            <Button
              disabled={e.disabled}
              key={i}
              color="secondary"
              variant="outlined"
              onClick={() => e.value && updateSAVData('stepper', e.value)}
            >
              {e.name}
              <ArrowDropDown />
            </Button>
          </Grid>
        )
    )}
    <Grid item xs={12}>
      <Button
        fullWidth
        children="Status"
        className={classes.redButton}
        variant="outlined"
      />
    </Grid>
    <Grid item xs={12}>
      <FormControl
        component="fieldset"
        disabled={nature !== SAV_RECLA &&
          !permit(user, { key: 'savAssign' })}
      >
        <RadioGroup
          value={status}
          style={{ display: 'inline' }}
          onChange={(_, val) => {
            updateSAVData('status', Number(val));
            updateSAVData(
              'sms', Number(val) === SAV_ENC ? getSmsEnvoiSAV(savInter) : null
            );
          }}
        >
          {SAVStatus.filter((e) =>
            nature === SAV_RECLA ? e.isReclamation : !e.isReclamation
          ).map((e) => (
            <FormControlLabel
              key={e._id}
              value={e._id}
              label={e.name}
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
    <CustomTextField
      customTimeOut={0}
      grid
      xs={12}
      texttransform="none"
      rows={4}
      label="Commentaire..."
      value={comment}
      path="comment"
      setData={(p, v) => updateSAVData(p, v)}
    />
  </Grid>
);

export default withStyles(SavInfo, styles);
