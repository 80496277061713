import React, { Component } from 'react';
import { Map, fromJS } from 'immutable';
import { compose } from 'redux';

/*This HOC provides own merge, original and current state
for component based on what we passed to it in buildState item*/
const buildState = (item = {}) => ({
  merge: fromJS(item),
  original: fromJS(item),
  current: item.current || new Map(),
});

export default function withChanges(generateState = () => ({})) {
  const getState = compose(
    buildState,
    generateState,
  );
  const generateImmutableState = compose(
    fromJS,
    generateState,
  );

  return WrappedComponent =>
    (class extends Component {
      state = getState(this.props);

      componentDidUpdate(prevProps) {
        if (!generateImmutableState(this.props).equals(
          generateImmutableState(prevProps)
        )) {
          this.setState(getState(this.props));
        }
      }

      modify = (keys, path, value) => state =>
        keys.reduce((a, k) => {
          a[k] = state[k].setIn(path, value);
          return a;
        }, {});

      modifyMultiple = (keys, modifications) => state =>
        keys.reduce((a, k) => {
          a[k] = state[k].withMutations(map => {
            modifications.forEach(([path, value]) => {
              if (!(path instanceof Array)) {
                path = [path];
              }
              map.setIn(path, value);
            });
          });
          return a;
        }, {});

      update = keys => (path, value) =>
        this.promisifiedSetState(
          this.modify(keys, Array.isArray(path) ? path : [path], fromJS(value))
        )

      updateMultiple = keys => (...modifications) =>
        this.promisifiedSetState(this.modifyMultiple(keys, modifications))

      set = key => value => this.promisifiedSetState({ [key]: fromJS(value) });

      render() {
        return (
          <WrappedComponent
            {...this.props}
            {...this.state}
            updateData={this.update(['merge', 'current'])}
            modifyData={this.update(['merge', 'original'])}
            updateMultipleData={this.updateMultiple(['merge', 'current'])}
            updateAll={this.update(['merge', 'current', 'original'])}
            setMerge={this.set('merge')}
            setOriginal={this.set('original')}
            setCurrent={this.set('current')}
          />
        );
      }
    });
}
