import { Grid } from '@mui/material';
import React from 'react';
import { getTime } from '../../utils/date';
import TimePicker from '../TimePicker';
import DatePicker from '../DatePicker';

export default function DateAndHour(props) {
  const {
    dateLabel,
    lg,
    xs,
    md,
    date,
    restrictions,
    format = 'L'
  } = props;


  const hour = getTime(new Date(date));
  const setTime = (e) => {
    const { setData, type } = props;
    const numberPart = e.target.value.split(':');
    const newDate = new Date(props.date);
    newDate.setHours(
      Number.parseInt(numberPart[0], 10),
      Number.parseInt(numberPart[1], 10),
      0,
      0
    );
    setData(newDate, type);
  };

  const setDate = (m) => {
    const { date, setData, type } = props;
    const newDate = new Date(date);
    newDate.setFullYear(m.year(), m.month(), m.date());
    return setData(newDate, type);
  };

  return (
    <>
      <Grid key='time' item xs={xs || 6} sm={6} lg={lg || 3}>
        <TimePicker
          restrictions={restrictions}
          label={'Heure d\'absence'}
          name='date'
          value={hour}
          onChange={setTime}
          size='100%'
          maxHeight={300}
        />
      </Grid>
      <Grid key='time3' item xs={xs || 6} sm={6} md={md} lg={lg || 3}>
        <DatePicker
          restrictions={restrictions}
          label={dateLabel}
          noBasicDate={!date}
          before
          startDate={date}
          format={format}
          onChange={setDate}
        />
      </Grid>
    </>
  );
}
