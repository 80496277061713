import React, { PureComponent } from 'react';
import { isValidIBAN, isValidBIC } from 'ibantools';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Map, List, fromJS } from 'immutable';

import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { permit } from '@lba-dev/package.local-globals/restrictions';

import api from '../../api';
import notifSystem from '../../notifSystem';
import MinCustomDialog from '../Dialogs/MinCustomDialog';
import DocumentsList from '../List/Artisan/DocumentsList';
import { Header, Inputs, InputDisable } from './';
import { withChanges } from '../../hoc';
import { DBANQ } from '@lba-dev/package.local-globals/docTypes';
import { checkBankInfo } from '../../actions/verificationsArtisan';
import { DOCVAL, DOCREF } from '@lba-dev/package.local-globals/docStatus';

const paths = {
  iban: ['iban'],
  bic: ['bic'],
  verifRIB: ['verifRIB'],
  newIban: ['newIban'],
  newBic: ['newBic'],
  dropbox: ['dropbox']
};

const mapStateToProps = ({ users, userId }) => {
  const user = users.find(u => u._id === userId);

  return {
    users,
    userId,
    user,
    permissionsCompta: permit(user, { key: 'changeBankInfo' })
  };
};

const getData = ({ defaultText, parentCurrent }) => ({
  dropbox: defaultText.get('dropbox', {}),
  bic: defaultText.get('bic'),
  iban: defaultText.get('iban'),
  newBic: defaultText.get('newBic') || defaultText.get('bic') || '',
  newIban: defaultText.get('newIban') || defaultText.get('iban') || '',
  verifRIB: defaultText.get('verifRIB') || false,
  current: parentCurrent
});

class BankInfo extends PureComponent {
  state = {
    files: [],
    checked: [],
    disable: false,
    filesUpdated: false
  }

  disableSave = disable => this.setState({ disable })

  patchRib = () => {
    const { defaultText, current } = this.props;
    if (current.isEmpty()) {
      return Promise.resolve();
    }
    return Promise.all([
      api.artisans
        .patch(defaultText.get('_id'), current.toJS())
        .catch(() =>
          notifSystem.error('Erreur', 'Le RIB n\'a pas été enregistré')
        )
    ]);
  };

  saveFile = () => {
    const { current, defaultText } = this.props;

    return api.artisans
      .patch(defaultText.get('_id'),
        { dropbox: current.get('dropbox'), verifRIB: current.get('verifRIB') })
      .then(() => this.setState({ filesUpdated: !this.state.filesUpdated }))
      .catch(() =>
        notifSystem.error('Erreur', 'Le RIB n\'a pas été enregistré')
      );
  }

  checkMissingInputs = () => {
    const {
      merge,
      current,
    } = this.props;
    const newIban = current.get('newIban', merge.get('newIban', ''));
    const newBic = current.get('newBic', merge.get('newBic', ''));
    const ribDoc = current
      .get('dropbox', merge.get('dropbox', new List()))
      .some(e => e.get('type') === DBANQ && e.get('status') === DOCVAL);

    if (!isValidBIC(newBic.toUpperCase())) {
      notifSystem.error('Erreur', 'BIC non valide');
      return false;
    }
    else if (!isValidIBAN(newIban.toUpperCase())) {
      notifSystem.error('Erreur', 'IBAN non valide');
      return false;
    }
    else if (!ribDoc) {
      notifSystem.error('Erreur', 'Fichier RIB manquant');
      return false;
    }
    return true;
  }

  valideRib = () => {
    const {
      merge,
      current,
      updateMultipleData,
    } = this.props;
    const newIban = current.get('newIban', merge.get('newIban', ''));
    const newBic = current.get('newBic', merge.get('newBic', ''));

    if (this.checkMissingInputs() && checkBankInfo(merge, current)) {
      updateMultipleData(
        [paths.iban, newIban],
        [paths.bic, newBic],
        [paths.newIban, ''],
        [paths.newBic, ''],
        [paths.verifRIB, true]
      )
        .then(this.patchRib);
    }
  }

  clearRib = () => {
    const {
      merge,
      updateMultipleData,
    } = this.props;
    const value = merge.getIn(paths.dropbox).toJS()
      .map(e => ({ ...e, status: e.type === DBANQ ? DOCREF : e.status }));

    updateMultipleData(
      [paths.dropbox, value],
      [paths.newIban, ''],
      [paths.newBic, ''],
      [paths.verifRIB, false],
    )
      .then(this.patchRib)
      .then(() => this.onClose());
  }

  save = () => {
    const { merge, current } = this.props;
    return checkBankInfo(merge, current) &&
    this.checkMissingInputs()
      && this
        .patchRib()
        .then(() => {
          notifSystem.success(
            'Message',
            'Les informations ont bien été enregistrées'
          );
          this.onClose();
        });
  }

  setData = (path, value, cb) => {
    const { setData, updateMultipleData } = this.props;
    updateMultipleData(
      [path, value],
      [paths.verifRIB, false]
    ).then(() => {
      if (setData) {
        setData(path, value, () => setData(paths.verifRIB, false));
      }
      if (cb) {
        cb();
      }
    });
  };

  deleteCheckTab = () => {
    const { merge, updateMultipleData, setData } = this.props;
    const { checked } = this.state;
    const path = paths.dropbox;
    const value = merge
      .getIn(paths.dropbox)
      .filter(e => !checked.includes(e.get('fileName')));

    updateMultipleData(
      [path, value],
      [paths.verifRIB, false],
    )
      .then(() => {
        if (setData) {
          setData(path, value, () => setData(paths.verifRIB, false));
        }
      })
      .then(() => this.promisifiedSetState({ checked: [] }))
      .then(this.saveFile)
      .then(() => notifSystem.success(
        'Message',
        'Les documents ont bien été supprimés'
      ));
  };

  handleToggle = this.toggleArray('checked')

  onClose = () => {
    const {
      onClose,
      callback,
      isTable,
      setCurrent
    } = this.props;

    if (!isTable) {
      setCurrent(new Map());
      return onClose ? onClose() : callback();
    }
  };

  fetchFiles = fetcher =>
    fetcher()
      .then(data => {
        const { setData, updateAll, updateMerge = e => e } = this.props;
        if (setData) {
          setData(
            paths.dropbox,
            fromJS(data)
          );
        }
        updateAll(paths.dropbox, data);
        updateMerge(paths.dropbox, data);
        return data.filter(e => e.type === DBANQ)
          .map(({ link, fileName, status }) => ({
            status,
            url: link,
            name: fileName
          }));
      });

  renderComponent = () => {
    const {
      defaultText,
      isTable,
      user,
      merge,
      permissionsCompta,
      displayFile = true,
      current
    } = this.props;
    const { checked, filesUpdated } = this.state;
    const bic = merge.get('bic');
    const iban = merge.get('iban');
    return (
      <Card className="cards" elevation={isTable ? 2 : 0}>
        <Header
          isTable={isTable}
          data={defaultText}
          disableSave={this.disableSave}
          user={user}
          permissions={user && permissionsCompta}
          checked={checked}
          deleteCheckTab={this.deleteCheckTab}
          verifRIB={merge.get('verifRIB', false)}
          title={'BANQUE'}
        />
        <Inputs
          permissions={user && permissionsCompta}
          newIban={current.get('newIban', merge.get('newIban', ''))}
          paths={paths}
          newBic={current.get('newBic', merge.get('newBic', ''))}
          isValidIBAN={isValidIBAN}
          isValidBIC={isValidBIC}
          setData={this.setData}
          valideRib={this.valideRib}
          clearRib={this.clearRib}
          user={user}
        />
        {bic && iban && <InputDisable iban={iban} bic={bic} />}
        <DocumentsList
          collection="artisans"
          displayFile={displayFile}
          fetchFiles={this.fetchFiles}
          type={DBANQ}
          regex="(rib|iban|bic|filtres)"
          underscoreId={defaultText.get('_id')}
          id={defaultText.get('id')}
          handleDelete={elem => this.handleToggle(elem.name)}
          selectedElems={checked}
          buttonDisabled={!(merge.get('newIban', merge.get('iban', '')) &&
            merge.get('newBic', merge.get('bic', '')))}
          filesUpdated={filesUpdated}
        />
      </Card>
    );
  };

  render() {
    const { isTable, open, merge } = this.props;
    return isTable
      ? this.renderComponent()
      : <MinCustomDialog
        open={open}
        onClose={this.onClose}
        maxWidth="md"
        actions={[
          <Button
            key={1}
            disabled={this.state.disable ||
              (!(merge.get('newIban', merge.get('iban', '')) &&
            merge.get('newBic', merge.get('bic', ''))))}
            color="primary"
            onClick={this.save}
            children={this.state.disable
              ? <CircularProgress size={24} />
              : 'Enregistrer'}
          />,
          <Button
            key={2}
            color="primary"
            onClick={this.onClose}
            children="Fermer"
          />,
        ]}
        children={this.renderComponent()}
      />;
  }
}

export default compose(
  connect(mapStateToProps),
  withChanges(getData)
)(BankInfo);
