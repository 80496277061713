/* eslint-disable indent */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { C_AUT, data } from '@lba-dev/package.local-globals/typesCancels';

import Content from './Content';
import Footer from './Footer';
import Header from './Header';
import Another from './Another';
import { orange } from '@mui/material/colors';

const autreOption = {
  type: 5,
  color: orange,
  raison: 'Autre'
};
const lastElement = 1;
const noArtisanToSendMailOrSMS = '0';

const styles = () => ({
  radioGrid: {
    justifyContent: 'center',
    display: 'flex'
  },
  list: {
    pointerEvents: 'none'
  },
  radioGroup: {
    justifyContent: 'center'
  },
  fullWidth: {
    width: '60%'
  },
  formControl: {
    margin: 30
  }
});

class CancelDialog extends PureComponent {
  state = {
    value: '',
    send: '1',
    steps: 0
  }

  setData = (n, v) => {
    const { steps, message, typeCancel } = this.state;
    const { callback, annulations, defaultText: { artisanExist } } = this.props;
    if (steps === lastElement && typeCancel !== C_AUT) {
      return callback({ message, typeCancel }, v);
    }
    const value = [ ...annulations, autreOption].find(e => e.raison === v);
    if (value) {
      return this.setState({
        message: value.raison,
        typeCancel: value.type,
        steps: n === 'send' ? steps : steps + 1,
      }, () => {
        if (!artisanExist && v !== 'Autre') {
          return callback(
            { message: value.raison, typeCancel: value.type },
            noArtisanToSendMailOrSMS
          );
        }
      });
    } else if (n === 'send' && typeCancel === C_AUT) {
      this.setState({ send: v });
    }
  };

  setValue = (e) => {
    this.setState({
      value: e.target.value,
    });
  }

  returnAction = () => {
    this.setState({
      steps: 0,
      typeCancel: null,
      value: '',
    });
  }

  render() {
    const {
      classes,
      annulations,
      open,
      callback,
      fullScreen,
      defaultText: { artisanExist }
    } = this.props;
    const ann = annulations.map(e => ({
      ...e,
      color: data.find(t => t._id === e.type).color
    })).concat(autreOption);

    const { typeCancel, send, value, steps } = this.state;
    const isAnother = typeCancel !== C_AUT;

    return annulations && (
      <Dialog
        fullScreen={fullScreen}
        maxWidth={isAnother && !fullScreen ? false : 'sm'}
        open={open}
        fullWidth
        classes={{
          paperFullWidth: isAnother && !fullScreen ? classes.fullWidth : '',
        }}
      >
        <DialogTitle>
          <Header
            isAnother={isAnother}
            steps={steps}
            callback={callback}
            returnAction={this.returnAction}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4} justifyContent="center">
            {isAnother ? (
              <Content
                setData={this.setData}
                steps={steps}
                annulations={ann}
              />
            ) : (
              <Another
                value={value}
                send={send}
                classes={classes}
                setData={this.setData}
                setValue={this.setValue}
                artisanExist={artisanExist}
              />
            )}
          </Grid>
        </DialogContent>
        {!isAnother && value.trim() && (
          <Footer
            send={() => callback({ message: value, typeCancel }, send)}
            cancel={() => callback(null)}
          />
        )}
      </Dialog>
    );
  }
}

CancelDialog.propTypes = {
  callback: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  annulations: PropTypes.array
};

const mapStateToProps = ({ annulations }) => ({ annulations });

export default connect(mapStateToProps)(withStyles(CancelDialog, styles));
