import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

import { withStyles } from 'tss-react/mui';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import notifSystem from '../../notifSystem';
import { Divider } from '@mui/material';

const styles = (theme) => ({
  container: {
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 100,
    top: 50,
    width: '100%',
    maxWidth: '25em',
  },
  suggestionsContainerOpenDevis: {
    width: '35.5em',
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    padding: 0,
    background: 'white',
    listStyleType: 'none',
    position: 'absolute',
    minWidth: '12.5em',
  },
  searchInput: {
    padding: '4px',
    border: 'rgba(0, 0, 0, 0.15) 2px solid',
    borderRadius: 6,
    margin: '16px 0 16px 0',
    minWidth: '12.5em',
    width: '25em',
    maxWidth: '90%',
    [theme.breakpoints.down('sm')]: {
      margin: 18,
      maxWidth: '85%',
    },
  },
  suggestionsCustomList: {
    top: 44,
    boxShadow: '0px 2px 30px 1px rgba(0,0,0,0.14)',
  },
});

const SuggestionInput = ({
  InputLabelProps,
  InputProps,
  classes,
  ref,
  width,
  maxWidth,
  ...props
}) => (
  <TextField
    variant="standard"
    className={classes.searchInput}
    style={{ width, maxWidth }}
    InputProps={{
      inputRef: ref,
      disableUnderline: true,
      classes: { input: classes.input },
      ...InputProps,
    }}
    InputLabelProps={InputLabelProps}
    {...props} />
);

class AutoCompleteSearchBar extends Component {
  state = {
    value: this.props.defaultValue,
    suggestions: [],
  };

  onSuggestionsFetchRequested = ({ value }) =>
    this.props
      .getSuggestions(value)
      .then(suggestions => this.setState({ suggestions }))
      .catch(() =>
        notifSystem.error('Erreur', 'Impossible de récupérer les suggestions')
      );

  onSuggestionsClearRequested = () => this.setState({ suggestions: [] });

  renderSuggestion = (suggestion, { isHighlighted }, withDivider) => [
    <MenuItem
      key={0}
      button
      className={this.props.classes.menuItem}
      onKeyDown={e =>
        isHighlighted && e.key === 'Enter' && this.props.onValidate(suggestion)
      }
      selected={isHighlighted}
      component="div"
      children={this.props.displaySuggestion(suggestion)}
    />,
    ...(withDivider ? [<Divider key={1} />] : [])
  ];

  onChange = (e, { newValue }) =>
    newValue !== undefined && this.setState({ value: newValue });

  render() {
    const {
      classes,
      placeholder,
      InputProps,
      InputLabelProps,
      onValidate,
      displaySuggestion,
      getSuggestionValue = displaySuggestion,
      customClasses,
      withDivider
    } = this.props;
    const { value, suggestions } = this.state;
    const inputProps = {
      classes,
      placeholder,
      value,
      onChange: this.onChange,
      InputProps,
      InputLabelProps,
    };

    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
          ...(customClasses ? customClasses : {})
        }}
        inputProps={inputProps}
        suggestions={suggestions}
        renderInputComponent={SuggestionInput}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={(e, { suggestion }) => onValidate(suggestion)}
        renderSuggestionsContainer={({ containerProps, children }) => (
          <Paper {...containerProps} square children={children} />
        )}
        renderSuggestion={(suggestion, params) =>
          this.renderSuggestion(suggestion, params, withDivider)
        }
      />
    );
  }
}

AutoCompleteSearchBar.propTypes = {
  defaultValue: PropTypes.string,
  getSuggestions: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  displaySuggestion: PropTypes.func,
  placeholder: PropTypes.string,
};

AutoCompleteSearchBar.defaultProps = {
  defaultValue: '',
  displaySuggestion: suggestion => (
    <span children={suggestion.properties.label} />
  ),
  placeholder: 'Rechercher...',
};

export default withStyles(AutoCompleteSearchBar, styles);
