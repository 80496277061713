import React, { Suspense } from 'react';
import {
  Navigate,
  Route,
} from 'react-router-dom';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import SuspenseLoading from './SuspenseLoading';
import config from '../../config';

const routes = (user, isChat) => {
  if (isChat) {
    const conv = config.conversation[0];
    return <Route
      key={conv.path}
      path={conv.path}
      Component={() => (
        <Suspense fallback={<SuspenseLoading />}>
          <conv.component />
        </Suspense>
      )}
    />;
  }
  return config.router.map(({
    redirectLink, path,
    component: Component, restrictions
  }, i) => {
    redirectLink = redirectLink && redirectLink(user);
    const allowed = permit(user, { custom: restrictions });
    Component = !allowed && redirectLink ?
      () => <Navigate to={redirectLink} replace={true} /> : Component;
    return user && (allowed || redirectLink) ? (
      <Route
        key={path}
        path={path}
        Component={() => (
          <Suspense key={`Suspense-${i}`} fallback={<SuspenseLoading />}>
            <Component />
          </Suspense>
        )}
      />
    ) : null
  }
  );
};

export default (user, isChat) => routes(user, isChat);

