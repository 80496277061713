export default ({ encaissements, reglement, data, update }) => {
  const montantEncaissement = encaissements
    .filter(e => e.type === 2)
    .reduce((a, b) => {
      a += b.montant || 0;
      return a;
    }, 0);
  const finalPrice = data.get('finalPrice', 0);
  const montant20 = (finalPrice + ((finalPrice * 20) / 100));
  const ttc = finalPrice * (1 + (reglement.get('tva') / 100));
  let message = null;
  if (montantEncaissement >= ttc && !reglement.get('ht')) {
    message = {
      msg: '⚠️ Attention ⚠️ Votre Encaissement correspond ' +
      'au montant de validation commercial, ' +
      'Vous n’avez pas fait de validation comptable, ' +
      'êtes-vous sûr de votre enregistrement ? ',
      id: 0,
    };
  } else if (
    update &&
    reglement.get('ht') > 0 &&
    reglement.get('ht') < finalPrice
  ) {
    message = {
      msg: '⚠️ Attention ⚠️ Votre Réglement est inférieur au montant ' +
      'de validation commercial, êtes vous sûr de votre enregistrement ? ',
      id: 1,
    };
  }
  if (reglement.get('ht') >= montant20 && update) {
    message = {
      msg: '⚠️ Attention ⚠️ Nous constatons une différence entre ' +
      'la validation et le prix final de + 20% \n' +
      'Etes vous sur de vouloir valider ce montant ?',
      id: 2,
    };
  }
  return message;
};
