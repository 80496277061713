import openSocket from 'socket.io-client';
import notifSystem from './notifSystem';
import listCollections, { chatCollections } from './constants/listCollections';
import { setCount } from './cache';
import store from './store';
import { userConnect } from './actions/users';

class Ws {
  login(user) {
    this.socket = openSocket({
      transports: ['websocket'],
    });
    this.lock = new Promise((res, rej) => {
      this.socket.once('logged', res);
      this.socket.once('disconnect', rej);
    });
    this.socket.on('error', this.printError);
    this.socket.on('disconnect', (e) => this.disconnect(e, user));
    this.socket.on('notif', notifSystem.send);
    this.socket.on('connect', () => {
      this.connect(user);
      store.dispatch(userConnect(true));
    });
  }

  printError(err) {
    notifSystem.error(err.name, err.message);
    store.dispatch(userConnect(false));
  }

  connect(user) {
    if (window.name === 'chat') {
      chatCollections.forEach(e => {
        this.send(e);
      });
      return;
    }
    setTimeout(() => {
      if (this.socket) {
        this.socket.emit('login', user);
        listCollections.forEach((e) => {
        // to join room collection
          this.send(e);
          this.socket.on(`count_${e}`, (data) => {
            const key = Object.keys(data)[0];
            setCount(key, data[key]);
          });
        });
      }
    }, 5000);
  }

  disconnect(e, user) {
    setTimeout(() => {
      if (e === 'io server disconnect') {
        this.login(user);
      } else if (!this.socket) {
        this.disconnect(e, user);
      } else {
        this.socket.connect();
      }
    }, 5000);
    store.dispatch(userConnect(false));
  }

  logout(user) {
    if (this.socket) {
      if (user) {
        this.socket.emit('idle', user, true);
      }
      listCollections.forEach((e) => this.socket.send(`exit_${e}`));
      this.socket.removeAllListeners();
      this.socket.close();
      this.socket = undefined;
    }
    store.dispatch(userConnect(false));
  }

  async send(...args) {
    await this.lock;
    if (this.socket) {
      this.socket.emit(...args);
    }
  }

  on(...args) {
    return this.socket.on(...args);
  }

  once(...args) {
    return this.socket.once(...args);
  }

  removeEventListener(...args) {
    if (this.socket) {
      return this.socket.removeListener(...args);
    }
  }
}

export default new Ws();
