/**-------------------------------------------
 * ACTIONS
 ---------------------------------------------*/
export const CALL_MODAL = 'action/EtatReseau/CALL_MODAL';
export const CLOSE_MODAL = 'action/EtatReseau/CLOSE_MODAL';
/*---------------------------------------------*/
export const SET_INPUT_JOBCAT = 'action/EtatReseau/SET_INPUT_JOBCAT';
export const SET_INPUT_CITY = 'action/EtatReaseau/SET_INPUT_CITY';
export const SET_INPUT_ZIPCODE = 'action/EtatReseau/SET_INPUT_ZIPCODE';
export const SET_DATE_START = 'action/EtatReaseau/SET_DATE_START';
export const SET_DATE_END = 'action/EtatReaseau/SET_DATE_END';
export const SET_DATA_GLOBAL_RESEAU =
'action/EtatReseau/SET_DATA_GLOBAL_RESEAU';
export const CALL_SET_DATE_START = 'action/EtatReaseau/CALL_SET_DATE_START';
export const CALL_SET_DATE_END = 'action/EtatReaseau/CALL_SET_DATE_END';
export const CALL_PREPARE_ADD_CITY = 'action/EtatReseau/CALL_PREPARE_ADD_CITY';
/*---------------------------------------------*/
export const PUT_ADDRESS_TO_INPUT = 'action/EtatReseau/PUT_ADDRESS_TO_INPUT';
/*---------------------------------------------*/
export const GET_CITIES = 'action/EtatReseau/GET_CITIES';
export const CALL_PREPARE_GET_CITIES =
'action/EtatReseau/CALL_PREPARE_GET_CITIES';
/*---------------------------------------------*/
export const GET_VALUE_INPUT_DISTANCE_ADD_CITY =
'action/EtatReseau/GET_VALUE_INPUT_DISTANCE_ADD_CITY';
/*---------------------------------------------*/
export const SWITCH_VIEW_MODAL = 'action/EtatReseau/SWITCH_VIEW_MODAL';
/*---------------------------------------------*/
export const GO_FILTERED = 'action/EtatReseau/GO_FILTERED';
export const RESET_FILTERED_TAB = 'action/EtatReseau/RESET_FILTERED_TAB';
export const OUTPUT_FILTERED = 'action/EtatReseau/OUTPUT_FILTERED';
/*---------------------------------------------*/
export const ADD_SHOW_COLUMN_MARKER =
'action/EtatReseau/ADD_SHOW_COLUMN_MARKER';
export const SET_COLUMNS_SHOW_MARKERS =
'action/EtatReseau/SET_COLUMNS_SHOW_MARKERS';
export const SHOW_MARKERS_COLUMN_SELECTED =
'action/etatReseau/SHOW_MARKERS_COLUMN_SELECTED';
/*---------------------------------------------*/
export const RESULT_FILTERED_TAB = 'action/EtatReseau/RESULT_FILTERED_TAB';
export const CLEAR_FILTER = 'action/EtatReseau/CLEAR_FILTER';
/*---------------------------------------------*/
export const OPEN_MODAL_LIST_ARTISANS_TAB_FILTERED =
'action/EtatReseau/OPEN_MODAL_LIST_ARTISANS_TAB_FILTERED';
export const CLOSE_MODAL_LIST_ARTISANS_TAB_FILTERED =
'action/EtatReseau/CLOSE_MODAL_LIST_ARTISANS_TAB_FILTERED';
/*---------------------------------------------*/
export const SET_LIST_MARKERS = 'action/EtatReseau/SET_LIST_MARKERS';
export const SET_LIST_CIRCLES = 'action/EtatReseau/SET_LIST_CIRCLES';
/*---------------------------------------------*/
export const CALL_EDIT_CITY = 'action/EtatReseau/CALL_EDIT_CITY';
export const GET_EDIT_CITY = 'action/EtatReseau/GET_EDIT_CITY';
/*---------------------------------------------*/
export const CALL_DELETE_CITY = 'action/EtatReseau/CALL_DELETE_CITY';
export const EDIT_DIST_CITY_UPDATE = 'action/EtatReseau/EDIT_DIST_CITY_UPDATE';
/*---------------------------------------------*/
export const CALL_UPDATE_CITY = 'action/EtatReseau/CALL_UPDATE_CITY';
export const SET_MARKER_TO_MAP = 'action/EtatReseau/SET_MARKER_TO_MAP';
/*---------------------------------------------*/
export const SET_SHOW_LIST_SPECIFIC_ARTISANS =
'action/EtatReseau/SET_SHOW_LIST_SPECIFIC_ARTISANS';
/*---------------------------------------------*/
export const LOADING_FINISH = 'action/EtatReseau/LOADING_FINISH';
/*---------------------------------------------*/
export const SET_EXPANDED = 'action/EtatReseau/SET_EXPANDED';
/*---------------------------------------------*/
export const FILTER_BY_COLUMNS = 'action/EtatReseau/FILTER_BY_COLUMNS';
/*---------------------------------------------*/
export const CALL_LOAD_ACTIVITIES_ARTISAN =
'action/EtatReseau/CALL_LOAD_ACTIVITIES_ARTISAN';
/*---------------------------------------------*/
export const SET_LIST_ACTIVITIES_ARTISAN =
'action/EtatReseau/SET_LIST_ACTIVITIES_ARTISAN';

/**------------------> MODAL */
export const callModal = () => ({
  type: CALL_MODAL
});

export const closeModal = () => ({
  type: CLOSE_MODAL
});

/**------------------> CALL & GET */
export const callShowGlobalReseau = (requestGlobal) => ({
  type: GO_FILTERED,
  requestGlobal
});

/**------------------> TAB FILTER LIST */
export const openModalListArtisansTabFiltered = () => ({
  type: OPEN_MODAL_LIST_ARTISANS_TAB_FILTERED,
});

export const closeModalListArtisansFiltered = () => ({
  type: CLOSE_MODAL_LIST_ARTISANS_TAB_FILTERED,
  status: false,
});

/**------------------> INPUT FILTER */
export const setInputJobCat = (categorie) => ({
  type: SET_INPUT_JOBCAT,
  categorie,
});

export const setInputCity = (city) => ({
  type: SET_INPUT_CITY,
  city,
});

export const setDateStart = (dateStart) => ({
  type: SET_DATE_START,
  dateStart,
});

export const setDateEnd = (dateEnd) => ({
  type: SET_DATE_END,
  dateEnd,
});

export const setInputZipcode = (zipcode) => ({
  type: SET_INPUT_ZIPCODE,
  zipcode,
});

/**------------------> OUTPUT FILTERED */
export const goFiltered = () => ({
  type: GO_FILTERED,
});

export const resetFilteredTab = () => ({
  type: RESET_FILTERED_TAB
});

export const outputFiltered = (artisans) => ({
  type: OUTPUT_FILTERED,
  artisans,
});

export const putAddressToInput = (addresstoInput) => ({
  type: PUT_ADDRESS_TO_INPUT,
  addresstoInput,
});

export const callPrepareAddCity = () => ({
  type: CALL_PREPARE_ADD_CITY
});

export const callPrepareGetCities = () => ({
  type: CALL_PREPARE_GET_CITIES
});

export const getCities = (cities) => ({
  type: GET_CITIES,
  cities
});

export const getValueInputDistanceAddCity = (distance) => ({
  type: GET_VALUE_INPUT_DISTANCE_ADD_CITY,
  distance
});

export const switchViewModal = () => ({
  type: SWITCH_VIEW_MODAL,
});

export const resultFilteredTab = (resultFilteredTab) => ({
  type: RESULT_FILTERED_TAB,
  resultFilteredTab
});

export const clearFilter = () => ({
  type: CLEAR_FILTER
});

/**------------------> MARKER & CIRCLES */
export const addShowColumnMarker = (column) => ({
  type: ADD_SHOW_COLUMN_MARKER,
  column
});

export const setColumnsShowMarkers = (columns) => ({
  type: SET_COLUMNS_SHOW_MARKERS,
  columns
});

export const setListMarkers = (markers) => ({
  type: SET_LIST_MARKERS,
  markers
});

export const setListCircles = (citiesFiltered) => ({
  type: SET_LIST_CIRCLES,
  citiesFiltered
});

export const callShowMarkerSelected = () => ({
  type: SHOW_MARKERS_COLUMN_SELECTED
});

export const setMarkerToMap = (markerShowToMap) => ({
  type: SET_MARKER_TO_MAP,
  markerShowToMap
});

/**------------------> EDIT CITY MANAG CITIES */
export const editDistCityUpdate = (updateDistCity) => ({
  type: EDIT_DIST_CITY_UPDATE,
  updateDistCity
});

export const callEditCity = (currentCity) => ({
  type: CALL_EDIT_CITY,
  currentCity
});

export const callUpdateCity = () => ({
  type: CALL_UPDATE_CITY,
});

export const getEditCity = (currentCityEdited) => ({
  type: GET_EDIT_CITY,
  currentCityEdited
});

/**------------------> DELETE CITY MANAG CITIES */
export const callDeleteCity = (deleteIdCity) => ({
  type: CALL_DELETE_CITY,
  deleteIdCity
});

export const getNewListCities = () => ({
  type: CALL_PREPARE_GET_CITIES
});

/**------------------> DELETE CITY MANAG CITIES */
export const setShowListSpecificArtisans = (listSpecificArtisans) => ({
  type: SET_SHOW_LIST_SPECIFIC_ARTISANS,
  listSpecificArtisans
});

/**------------------> LOADER */
export const loadingFinish = (stateLoading) => ({
  type: LOADING_FINISH,
  stateLoading,
});

export const setExpanded = (expanded) => ({
  type: SET_EXPANDED,
  expanded
});

export const filterByCol = (colNumber, styl) => ({
  type: FILTER_BY_COLUMNS,
  colNumber,
  styl
});

export const callLoadActivitiesArtisan = (artisanId) => ({
  type: CALL_LOAD_ACTIVITIES_ARTISAN,
  artisanId
});

export const setListActivitiesArtisan = (listActivitiesArtisan) => ({
  type: SET_LIST_ACTIVITIES_ARTISAN,
  listActivitiesArtisan
});
