import React from 'react';
import { withStyles } from 'tss-react/mui';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  KEYS_CONCLUSIONS,
  NAMES_CONCLUSIONS,
  OBJ_CONCLUSIONS,
  REMISE_A_ENCAISSEMENT,
  // doit faire basculer OS en orange
  REMISE_COMMERCIALE,
  //OS en gris
  PROTOCOLE_ACCORD,
  RENVOYER_FACTURE,
  LETTRE_DESISTEMENT,
  MISE_EN_DEMEURE,
  ENVOI_EN_LITIGE,
  ENVOI_EN_RECOUVREMENT,
  COURRIER_HUISSIER,
  COURRIER_PROPOSITION_ARRANGEMENT_A_AMIABLE,
  COURRIER_DERNIER_AVIS_AVANT_POURSUITE,
  LETTRE_OPPOISTION,
  COURRIER_CHEQUE_PERDU,
  MAILS_RECOUVRMENT,
  MAILS_LITIGE,
  MAIL_PRISE_EN_CHARGE_DOSSIER,
  MAIL_PROTOCOLE_ACCORD,
  MAIL_LETTRE_DESISTEMENT,
  COURRIER_TITRE_EXECUTOIRE,
  COURRIER_REQUETE_IP,
  COURRIER_SIGNIFICATION_EXECUTION_TITRE,
  MAIL_REFUS_REMISE_COMMERCIALE,
  MAIL_CLOTURE_CLASSIQUE_AMIABLE,
  MAIL_PRESSION_AVANT_SIGNIFICATION,
  MAIL_DEFAUT_DE_RETOUR,
  MAIL_CLOTURE_CLASSIQUE_REFUS_DISCUTER,
  MAIL_SIGNIFICATION_IP_HUISSIER,
} from '@lba-dev/package.local-globals/rConclusions';
import DatePicker from '../../DatePicker';
import Button from '@mui/material/Button';
import CustomCheckbox from '../../CustomInputs/CustomCheckbox';
import Grid from '@mui/material/Grid';
import CustomTextField from '../../CustomInputs/CustomTextField';
import MoneyInput from '../../CustomInputs/MoneyInput';
import MailRecouvrement from './MailComponent';

const styles = () => ({
  minSize: {
    minWidth: '320px',
    minHeight: '480px',
  },
  textField: {
    display: 'block',
  },
});

const datePickerAbility = [
  REMISE_A_ENCAISSEMENT,
  REMISE_COMMERCIALE,
  PROTOCOLE_ACCORD,
  MAIL_PROTOCOLE_ACCORD,
  RENVOYER_FACTURE,
];

const getComponent = (e, { recouvrement, handleCheckboxes }) => (
  <CustomCheckbox
    grid
    xs={6}
    key={e.id}
    label={e.name}
    checked={recouvrement.conclusions.includes(e._id) || false}
    path={[e._id.toString()]}
    setData={handleCheckboxes}
  />
);

const renderConclustion = (e, props) => {
  const { recouvrement } = props;
  if (
    [
      ENVOI_EN_LITIGE,
      COURRIER_HUISSIER,
      LETTRE_DESISTEMENT,
      ...MAILS_RECOUVRMENT.filter(
        (e) =>
          ![
            MAIL_PRISE_EN_CHARGE_DOSSIER,
            MAIL_REFUS_REMISE_COMMERCIALE,
            MAIL_CLOTURE_CLASSIQUE_AMIABLE,
            MAIL_PRESSION_AVANT_SIGNIFICATION,
            MAIL_DEFAUT_DE_RETOUR,
            MAIL_CLOTURE_CLASSIQUE_REFUS_DISCUTER,
            MAIL_SIGNIFICATION_IP_HUISSIER,
          ].includes(e)
      ),
    ].includes(e._id) &&
    recouvrement.isLitige
  ) {
    return null;
  }
  if (
    [
      ENVOI_EN_RECOUVREMENT,
      LETTRE_OPPOISTION,
      ...MAILS_LITIGE.filter(
        (e) =>
          ![
            MAIL_PRISE_EN_CHARGE_DOSSIER,
            MAIL_REFUS_REMISE_COMMERCIALE,
            MAIL_CLOTURE_CLASSIQUE_AMIABLE,
            MAIL_PRESSION_AVANT_SIGNIFICATION,
            MAIL_DEFAUT_DE_RETOUR,
            MAIL_CLOTURE_CLASSIQUE_REFUS_DISCUTER,
            MAIL_SIGNIFICATION_IP_HUISSIER,
          ].includes(e)
      ),
    ].includes(e._id) &&
    !recouvrement.isLitige
  ) {
    return null;
  }
  return getComponent(e, props);
};

const DialogConcl = (props) => {
  const {
    selected,
    classes,
    recouvrement,
    setConclusionDialog,
    setData,
    mail,
  } = props;
  if (!props.recouvrement.emisDate) {
    setData(['emisDate'], new Date());
  }
  return [
    <DialogTitle key="title2">
      <Button variant="outlined" color="secondary" fullWidth>
        {NAMES_CONCLUSIONS[selected]}
      </Button>
    </DialogTitle>,
    <DialogContent key="content2" className={classes.minSize}>
      <Grid container spacing={3}>
        {selected !== -1 &&
          selected !== 4 &&
          selected !== 5 &&
          OBJ_CONCLUSIONS[KEYS_CONCLUSIONS[selected]].map((e) =>
            renderConclustion(e, props)
          )}
      </Grid>
      <Grid container spacing={3}>
        {(selected === 4 ||
          datePickerAbility.some((s) =>
            recouvrement.conclusions.includes(s)
          )) && (
          <Grid item xs={12}>
            <DatePicker
              startDate={
                (recouvrement.relanceDate &&
                  new Date(recouvrement.relanceDate)) ||
                new Date()
              }
              onChange={(c) => setData(['relanceDate'], c.toDate())}
              label={'Date de relance'}
              before={true}
            />
          </Grid>
        )}
        {(recouvrement.conclusions.includes(LETTRE_DESISTEMENT) ||
          recouvrement.conclusions.includes(LETTRE_OPPOISTION) ||
          recouvrement.conclusions.includes(MAIL_LETTRE_DESISTEMENT)) && [
          <Grid key={4} item xs={12}>
            <DatePicker
              startDate={
                (recouvrement.emisDate && new Date(recouvrement.emisDate)) ||
                new Date()
              }
              onChange={(c) => setData(['emisDate'], c.toDate())}
              label={'Désistement/opposition  émis le'}
              before={true}
            />{' '}
          </Grid>,
          <CustomTextField
            key={1}
            label="N° chèque"
            fullWidth
            value={recouvrement.numCheque || ''}
            grid
            xs={6}
            setData={setData}
            requiredText
            type="string"
            margin="normal"
            path={['numCheque']}
          />,
          <CustomTextField
            key={2}
            grid
            xs={6}
            fullWidth
            rows={1}
            label="Banque..."
            type="string"
            value={recouvrement.bankName || ''}
            setData={setData}
            margin="normal"
            path={['bankName']}
          />,
          <MoneyInput
            grid
            xs={6}
            requiredText
            label="Montant du chèque (€)"
            key={3}
            fullWidth
            type="number"
            margin="normal"
            defaultValue={recouvrement.montant || 0}
            path={['montantBank']}
            setData={setData}
          />,
        ]}
        {recouvrement.conclusions.includes(MISE_EN_DEMEURE) && [
          <Grid key={4} item xs={12}>
            <DatePicker
              startDate={
                (recouvrement.emisDate && new Date(recouvrement.emisDate)) ||
                new Date()
              }
              onChange={(c) => setData(['emisDate'], c.toDate())}
              label={'Mise en demeure émis le'}
              before={true}
            />{' '}
          </Grid>,
          <MoneyInput
            label="Montant de mise en demeure (€)"
            key={5}
            fullWidth
            margin="normal"
            grid
            xs={6}
            requiredText
            defaultValue={recouvrement.montantEmisDemeure || 0}
            path={['montantEmisDemeure']}
            setData={setData}
          />,
        ]}
        {(recouvrement.conclusions.includes(PROTOCOLE_ACCORD) ||
          recouvrement.conclusions.includes(MAIL_PROTOCOLE_ACCORD)) && [
          <MoneyInput
            label="Montant du protocole d'accord (€)"
            key={6}
            fullWidth
            margin="normal"
            grid
            xs={6}
            requiredText
            defaultValue={recouvrement.montantProto || 0}
            path={['montantProto']}
            setData={setData}
          />,
          <CustomTextField
            key={2}
            grid
            xs={6}
            fullWidth
            rows={1}
            label="Engagement des parties"
            type="string"
            value={recouvrement.engagementParties || ''}
            setData={setData}
            margin="normal"
            multiline
            path={['engagementParties']}
          />,
        ]}
        {recouvrement.conclusions.includes(REMISE_COMMERCIALE) && (
          <MoneyInput
            label="Montant de remise commerciale (€)"
            key={7}
            fullWidth
            margin="normal"
            grid
            xs={6}
            requiredText
            defaultValue={recouvrement.montantRemise || 0}
            path={['montantRemise']}
            setData={setData}
          />
        )}
        {recouvrement.conclusions.includes(
          COURRIER_DERNIER_AVIS_AVANT_POURSUITE
        ) && (
          <MoneyInput
            label="Montant du dernier avis (€)"
            key={8}
            fullWidth
            margin="normal"
            grid
            xs={6}
            requiredText
            defaultValue={recouvrement.montantDerAvis || 0}
            path={['montantDerAvis']}
            setData={setData}
          />
        )}
        {recouvrement.conclusions.includes(
          COURRIER_PROPOSITION_ARRANGEMENT_A_AMIABLE
        ) && (
          <MoneyInput
            label="Montant de proposition (€)"
            key={9}
            fullWidth
            margin="normal"
            grid
            xs={6}
            requiredText
            defaultValue={recouvrement.montantProposition || 0}
            path={['montantProposition']}
            setData={setData}
          />
        )}
        {recouvrement.conclusions.includes(COURRIER_CHEQUE_PERDU) && (
          <MoneyInput
            label="Montant du chèque perdu (€)"
            key={10}
            fullWidth
            margin="normal"
            grid
            xs={6}
            requiredText
            defaultValue={recouvrement.montantCheqPerdu || 0}
            path={['montantCheqPerdu']}
            setData={setData}
          />
        )}
        {recouvrement.conclusions.includes(COURRIER_TITRE_EXECUTOIRE) && [
          <Grid key={4} item xs={12}>
            <DatePicker
              startDate={
                (recouvrement.dateTitreExec &&
                  new Date(recouvrement.dateTitreExec)) ||
                new Date()
              }
              onChange={(c) => setData(['dateTitreExec'], c.toDate())}
              label={'Titre éxécutoire émis le'}
              before={true}
            />{' '}
          </Grid>,
          <CustomTextField
            key={2}
            grid
            xs={6}
            fullWidth
            rows={1}
            label="Défendeur"
            type="string"
            value={recouvrement.defendeur || ''}
            setData={setData}
            margin="normal"
            requiredText
            path={['defendeur']}
          />,
          <CustomTextField
            key={2}
            grid
            xs={6}
            fullWidth
            rows={1}
            label="Huissier"
            type="string"
            value={recouvrement.huissier || ''}
            setData={setData}
            margin="normal"
            requiredText
            path={['huissier']}
          />,
        ]}
        {recouvrement.conclusions.some((i) =>
          [
            COURRIER_REQUETE_IP,
            COURRIER_SIGNIFICATION_EXECUTION_TITRE,
            COURRIER_TITRE_EXECUTOIRE,
          ].includes(i)
        ) && [
          <CustomTextField
            key={2}
            grid
            xs={6}
            fullWidth
            rows={1}
            label="Adresse du tribunal"
            type="string"
            value={recouvrement.tribunal?.fullAddresse || ''}
            setData={setData}
            margin="normal"
            requiredText={true}
            path={['tribunal', 'fullAddresse']}
          />,
          <CustomTextField
            key={2}
            grid
            xs={6}
            fullWidth
            rows={1}
            label="Code postal du tribunal"
            type="string"
            value={recouvrement.tribunal?.zipcode || ''}
            setData={setData}
            margin="normal"
            requiredText
            path={['tribunal', 'zipcode']}
          />,
        ]}
        {recouvrement.conclusions.some((i) =>
          [
            COURRIER_SIGNIFICATION_EXECUTION_TITRE,
            COURRIER_TITRE_EXECUTOIRE,
          ].includes(i)
        ) && [
          <CustomTextField
            key={1}
            label="N° ordonnance"
            fullWidth
            value={recouvrement.numOrdonnance || ''}
            grid
            xs={6}
            setData={setData}
            requiredText
            type="number"
            margin="normal"
            path={['numOrdonnance']}
          />,
        ]}
        {recouvrement.conclusions.includes(
          COURRIER_SIGNIFICATION_EXECUTION_TITRE
        ) && [
          <CustomTextField
            key={1}
            label="Références"
            fullWidth
            value={recouvrement.vosReferences || ''}
            grid
            xs={6}
            setData={setData}
            requiredText
            type="number"
            margin="normal"
            path={['vosReferences']}
          />,
        ]}
        {[...MAILS_LITIGE, ...MAILS_RECOUVRMENT].find((e) =>
          recouvrement.conclusions.includes(e)
        ) &&
          mail &&
          mail.text && <MailRecouvrement {...props} />}
      </Grid>
    </DialogContent>,
    <DialogActions key="action">
      <Button onClick={setConclusionDialog} color="primary">
        OK
      </Button>
    </DialogActions>,
  ];
};

export default withStyles(React.memo(DialogConcl), styles);
