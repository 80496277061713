import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import CustomTextField from '../CustomInputs/CustomTextField';

const paths = {
  name: ['name'],
  firstname: ['firstname']
};

class Name extends PureComponent {
  render() {
    const { setData, name, firstname } = this.props;
    return (
      <Grid container spacing={3}>
        <CustomTextField grid xs={12} sm={6} lg={5} capsLocked
          label="Nom"
          value={name}
          path={paths.name}
          setData={setData}
        />
        <CustomTextField grid xs={12} sm={6} lg={5} capsLocked
          label="Prénom"
          value={firstname}
          path={paths.firstname}
          setData={setData}
        />
      </Grid>
    );
  }
}

Name.propTypes = {
  firstname: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired
};

Name.defaultProps = {
  name: '',
  firstname: ''
};

export default Name;
