import React, { useCallback, useState } from 'react';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import CustomMaterialTable from '../../CustomMaterialTable';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { moment } from '@lba-dev/package.local-globals/moment';
import {
  getAliasFromPhone, getLogin, cacheDisabled
} from '../../../utils/function';
import AudioPlayer from '../../Calls/AudioPlayer';
import DetailPanel from './DetailPanel';
import { styles } from '../../../views/Cti/utils';
import { getAllCalls } from '../../../actions/calls';
import constant from '../../Stats/StatsCalls/constant';
import { closeDialog } from '../../../actions/dialog';
import DemandePhotoIcon from '../../Stats/Cti/DemandePhotoIcon';
import {
  getCategorie,
  getIcons,
  findTags,
} from '../../Stats/Cti/CallTable/functions';
import { groupBy } from '@lba-dev/package.local-globals/functions';
import CustomSwitch from '../../CustomInputs/CustomSwitch';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import {
  PROBLEM_COMMERCIAL
} from '@lba-dev/package.local-globals/regexps';
import { SHOW_ENR } from '@lba-dev/package.local-globals/configTypes';
import CustomTextField from '../../CustomInputs/CustomTextField';
import { MTableToolbar } from '@material-table/core';
import notifSystem from '../../../notifSystem';
import company from '@lba-dev/package.local-globals/company';
import { blue } from '@mui/material/colors';

const mapStateToProps = ({
  dialog: {
    contentProps: {
      title, calls, startAt, endAt, isMissedCalls
    }
  },
  users,
  userId,
}) => ({
  title, calls, startAt, endAt, users, userId,
  user: users.find(e => e._id === userId),
  isMissedCalls
});

const filterCalls = (calls = [], { filterByPrice, filterByProblem }) => {
  const allCalls = calls.sort(
    (e, v) =>
      moment(e.record.callStart).toDate() - moment(v.record.callStart).toDate()
  );
  let records = allCalls.map((e) => ({ ...e, ...e.record }));
  if (filterByPrice) {
    records = allCalls.filter((e) => findTags(e, 'Panier moyen') !== 'N/A');
  }
  if (filterByProblem) {
    records = allCalls.filter((e) =>
      new RegExp(PROBLEM_COMMERCIAL, 'i').test(e.processingTextChatgpt)
    );
  }
  const uniqueCalls = Object.values(groupBy(records, 'callerIdNumber')).map(
    (e) => ({ records: e })
  );

  return { allCalls, uniqueCalls };
};

const fetchCalls = ({ title, ...props }) =>
  getAllCalls(props)
    .then(statsCalls => {
      const nCalls = constant({ statsCalls }).find(e => e.title === title);
      return nCalls ? filterCalls(nCalls) : [];
    });

const findCall = (calls, record) =>
  calls.find(c => c.record.id === record.records[0].id);

const columns = (calls, closeDialog) => [
  {
    title: 'Date',
    editable: 'never',
    render: (call) => (
      <Typography>
        {moment(call.records[call.records.length - 1].callStart).format(
          'DD/MM [à] HH[h]mm'
        )}
      </Typography>
    ),
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  },
  {
    title: 'Categorie',
    render: (call) => getCategorie(call.records[call.records.length - 1]),
  },
  {
    title: 'Filtrer par prix',
    headerComponent: ({ handleFilterByPrice, filterByPrice }) => (
      <CustomSwitch
        color="secondary"
        label="Filtrer par prix"
        checked={filterByPrice}
        path={''}
        setData={(p, v) => handleFilterByPrice(v)}
      />
    ),
    render: (call) => getIcons(call.records[call.records.length - 1]),
  },
  {
    title: 'Service',
    editable: 'never',
    render: (call) => {
      const callFounded = findCall(calls, call);
      return <Typography>{
        callFounded ? callFounded.service : 'N/A'}</Typography>;
    },
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  },
  {
    title: 'Numéro',
    render: (call) => {
      const callFounded = findCall(calls, call);
      return <Typography>{callFounded ? callFounded.group : 'N/A'}</Typography>;
    },
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  },
  {
    title: 'Appellant',
    render: (call) => (
      <Typography>
        {call.records[call.records.length - 1].callerIdNumber.replace(/^0033/, '0')}
      </Typography>
    ),
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  },
  {
    title: 'Nb. appels',
    render: (call) => call.records.length,
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  },
  {
    title: 'Agent',
    render: (call) => (
      <Typography>
        {getAliasFromPhone(
          call.records[call.records.length - 1].agent.replace(/^0033/, '0')
        )}
      </Typography>
    ),
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  },
  {
    title: 'Raison',
    render: (call) => {
      const history = call.records[call.records.length - 1]?.history || [];
      return (
        <Grid>
          {history.length
            ? `${history[history.length - 1].action} par ${getLogin(
              history[history.length - 1].userId
            )}`
            : 'N/A'}
        </Grid>
      );
    },
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  },
  ...(!cacheDisabled(SHOW_ENR) ? [{
    title: 'Audio',
    render: (call) => (
      <Grid style={{ minWidth: 380 }}>
        {call.records[call.records.length - 1].fileUrl ? (
          <AudioPlayer url={call.records[call.records.length - 1].fileUrl}
            duration={call.records[call.records.length - 1].duration}/>
        ) : (
          'N/A'
        )}
      </Grid>
    ),
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  }] : []),
  {
    title: 'Photos',
    render: (call) => (
      <DemandePhotoIcon
        call={call.records[call.records.length - 1]}
        cb={closeDialog}
      />
    ),
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  },
  {
    title: 'Tags',
    render: (call) => findTags(call.records[call.records.length - 1], 'tags'),
  },
  {
    title: 'Tags correspondants',
    headerComponent: ({ handleFilterByProblem, filterByProblem }) => (
      <CustomSwitch
        color="secondary"
        label="Filtrer par erreur"
        checked={filterByProblem}
        path={''}
        setData={(p, v) => handleFilterByProblem(v)}
      />
    ),
    render: (call) => findTags(call, 'tags correspondants'),
  },
];

const mapDispatchToProps = {
  closeDialog: closeDialog,
};

const doRequest = (evt, data, timer) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  const baseUrl = process.env.NODE_ENV === 'production' ? company.ringoverWebhook : 'http://localhost:3001';
  const route = '/api/ringover/clickToCallManuel';
  const calls = data.map(e => e.records).flat();

  fetch(
    `${baseUrl}${route}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        calls: Object.values(groupBy(calls, 'callerIdNumber')).map(
          (e) => (e[0])
        ),
        timer
      }),
    }
  )
    .then(() => notifSystem.success('Opération réussi', 'Rappel envoyé.'))
    .catch(() =>
      notifSystem.error('Erreur', 'Le rappel n\'a pas pu être envoyé'))
}

const ListCallsDialog = ({
  title, calls, classes,
  startAt, endAt, isMissedCalls,
  users, userId, user, closeDialog
}) => {
  const [original, setOriginal] = useState(calls);
  const [loading, setLoading] = useState(false);
  const [filterByPrice, handleFilterByPrice] = useState(false);
  const [filterByProblem, handleFilterByProblem] = useState(false);
  const { allCalls, uniqueCalls } = filterCalls(original, {
    filterByPrice,
    filterByProblem,
  });
  const [timer, setTimer] = useState(0);
  const [materialData, setMaterialData] = useState(uniqueCalls);

  const timerTextField = useCallback(() => (
    <CustomTextField
      grid
      xs={12}
      sm={12}
      type="number"
      label="Intervalle de temps (sec)"
      value={timer}
      path={[]}
      setData={(p, v) => setTimer(+v)}
    />
  ), [timer]);


  const Toolbar = (props) => (
    <Grid container spacing={2}>
      <Grid item xs={isMissedCalls ? 10 : 12}>
        <MTableToolbar {...props} />
      </Grid>
      <Grid item xs={2}>
        {isMissedCalls ? timerTextField() : null}
      </Grid>
    </Grid>
  );

  const loadData = () => {
    setLoading(true);
    return fetchCalls({
      title,
      user,
      userId,
      users,
      endAt,
      startAt,
      filterByPrice,
    })
      .then((e) => setOriginal(e))
      .finally(() => setLoading(false));
  };

  return (
    <CustomMaterialTable
      columns={columns(allCalls, closeDialog)}
      data={materialData.length ? materialData : uniqueCalls}
      detailPanel={({ rowData }) => (
        <DetailPanel classes={classes} rowData={rowData} />
      )}
      title={title}
      isLoading={loading}
      onSelectionChange={(data, rowData) => {
        const value = rowData?.records[0]?.callStart;
        setMaterialData(materialData.map(e =>
          e.records[0].callStart === value ? { ...e, ...rowData } : e));
      }}
      actions={[
        {
          tooltip: 'Rafraishir',
          isFreeAction: !(endAt && startAt),
          icon: 'replay',
          onClick: loadData,
        },
        ...(isMissedCalls
          ? [{
            tooltip: 'Rappeler manuellement',
            icon: 'phone_forwarded',
            onClick: (evt, data) => doRequest(evt, data, timer)
          }]
          : []),
      ]}
      components={{
        Header: (props) => (
          <TableHead>
            <TableRow>
              <TableCell children={''} />
              {props.columns.map((e, i) => (
                <TableCell
                  key={i}
                  align={e.align || ''}
                  children={e.title}
                  colSpan={e.colSpan || null}
                >
                  {(e.headerComponent &&
                    e.headerComponent({
                      ...e,
                      handleFilterByPrice,
                      handleFilterByProblem,
                      filterByPrice,
                      filterByProblem,
                    })) ||
                    e.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        ),
        Toolbar: Toolbar
      }}
      options={{
        selection: !!isMissedCalls,
        search: false,
        sorting: true,
        pageSize: 25,
        pageSizeOptions: [25, 50, 100],
        rowStyle: (rowData) => rowData.records.some(e => e.calledManually)
          ? { backgroundColor: blue[200] }
          : {},
      }}
    />
  );
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(ListCallsDialog, styles));
