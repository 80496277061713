import React, { PureComponent } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Causes from './Causes';
import Resulo from './ResoluComponent';
import notifSystem from '../../../notifSystem';
import {
  R_DATAS, R_RES, R_PRE_RES
} from '@lba-dev/package.local-globals/rStatus';
class DialogStatus extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      recouvrement: props.defaultText.recouvrement,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.open !== nextProps.open ||
      this.state !== nextState
    );
  }

  setStatus = (e) => {
    const { recouvrement } = this.state;
    recouvrement.status = Number(e.target.value);
    this.setState({ recouvrement });
  }

  onValidate = () => {
    const recouvrement = this.state.recouvrement;
    if (typeof this.props.callback === 'function') {
      if ([R_RES, R_PRE_RES].includes(recouvrement?.status) &&
        !recouvrement?.resuloStatus) {
        return notifSystem.error(
          'Pour valider le statut du recouvrement',
          'Merci de selectionner une sous-catégorie pour le statut résolu.'
        );
      }
      this.props.callback(recouvrement, false);
    }
  }


  onReject = () => {
    if (typeof this.props.callback === 'function') {
      this.props.callback();
    }
  }

  setData = (path, e) => {
    const { recouvrement } = this.state;
    recouvrement[path[0]] = e;
    this.setState({ recouvrement });
  }

  render() {
    const { recouvrement } = this.state;
    const { defaultText } = this.props;
    return (
      <Dialog open={this.props.open}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button variant="outlined" color="primary" fullWidth>
                {recouvrement.isLitige
                  ? 'Statut du litige'
                  : 'Statut du recouvrement'}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Causes
                fetchData={R_DATAS}
                isPris={recouvrement.isPris}
                handleChange={this.setStatus}
                value={recouvrement.status}
              />
            </Grid>
            {[R_RES, R_PRE_RES].includes(recouvrement.status) ? (
              <Resulo
                setData={this.setData}
                recouvrement={recouvrement}
                encaissement={defaultText.encaissement}
                decaissement={defaultText.decaissement}
              />
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onReject} color="secondary">
            {'Annuler'}
          </Button>
          <Button onClick={this.onValidate} color="primary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DialogStatus;
