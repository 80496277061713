import React from 'react';
import { connect } from 'react-redux';
import Supplies from '../Supplies';
import { fromJS } from 'immutable';

const mapStateToProps = ({
  dialog: {
    contentProps: {
      current,
      original
    },
  },
  users,
  userId
}) => ({
  current,
  original,
  user: users.find(e => e._id === userId)
});

class SuppliesDialog extends React.Component {
  state = {
    current: this.props.current,
    original: this.props.original
  }

  setInter = (path, value, cb = f => f) => {
    const { original } = this.state;
    let current = this.state.current;
    current[path] = value;
    this.setState({ current, original }, cb);
  };

  render() {
    const { current } = this.state;
    const { user } = this.props;
    return (
      <Supplies
        merge={fromJS(current)}
        setData={this.setInter}
        value={fromJS(current.supplies)}
        user={user}
        expanded
      />
    );
  }
}

export default connect(mapStateToProps)(SuppliesDialog);
