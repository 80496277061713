class DbWsReducer {
  constructor(fetchName, updateName) {
    this.fetchName = fetchName;
    this.updateName = updateName;
    this.fetch = this.fetch.bind(this);
    this.update = this.update.bind(this);
  }
  fetch(payload) {
    return {
      type: this.fetchName,
      payload
    };
  }
  update(payload) {
    return {
      type: this.updateName,
      payload
    };
  }
}

export default DbWsReducer;
