export default {
  list: {
    display: 'flex',
    padding: 9,
    width: '100%',
    justifyContent: 'space-around',
  },
  listName: {
    display: 'flex',
  },
  button: {
    cursor: 'pointer',
    paddingTop: '6px',
    paddingBottom: '6px',
    backgroundColor: '#F5F5F5',
    '&:hover': {
      backgroundColor: '#DCDCDC',
    },
  },
  arrowRight: {
    position: 'absolute',
    right: 0,
    top: 2,
  },
  arrowLeft: {
    position: 'absolute',
    left: 0,
    top: 2,
  },
  linkSpan: {
    padding: 2,
    width: 30,
    fontWeight: 'bold',
    fontSize: 11,
  },
  link: {
    textDecoration: 'none',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 150,
  },
  points: {
    color: '#fff',
    fontWeight: 'bold',
  },
  login: {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#000',
    whiteSpace: 'nowrap',
    maxWidth: 100,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '3px 7px',
  },
  genChip: {
    backgroundColor: '#777',
    color: '#fff',
    height: 20,
    fontSize: 12,
  },
  logo: {
    backgroundColor: 'rgb(158, 158, 158)',
    width: 24,
    height: 24,
    fontSize: 15,
  },
  scrollIcons: {
    height: 40,
    flex: '0 0 25px',
    color: '#333333',
  },
  appBar: {
    backgroundColor: '#fff',
  },
  tabContainer: {
    alignItems: 'center',
  },
  tabs: {
    display: 'flex',
    margin: '0px 8px',
    '&:first-child': {
      marginLeft: 0,
      paddingLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
      paddingRight: 0,
    },
    '&:hover': {
      animation: '3s slidein',
    },
  },
  avatar: {
    width: 20,
    height: 20,
    fontWeight: 'bold',
    fontSize: 12,
    marginLeft: 5,
  },
  indicator: {
    background: '#fff',
  },
};
