import { Box, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { passwordReset } from '../actions/users';
import notifSystem from '../notifSystem';
import { withStyles } from 'tss-react/mui';
import { styles } from './LoginStyle';


function LoginResetPassword({
  resetPasswordTimer,
  forgetPasswordMode,
  resetPasswordDisabled,
  classes
}) {


  const [username, setUsername] = useState('');


  const resetPassword = (e) => {
    e.preventDefault();

    if (username && passwordReset(username)) {
      return resetPasswordTimer();

    }
    return notifSystem.error(
      'Champs non remplis',
      'Le champ utilisateur n\'est pas rempli.');
  };


  return <form
    onSubmit={e => resetPassword(e)}
  >
    <Box sx={{
      mb: '20px',
      display: 'flex',
      justifyContent: 'center'
    }}>
      <TextField
        variant="standard"
        label="Nom d'utilisateur"
        value={username}
        className={classes.displayInput}
        onChange={(e) => setUsername(e.target.value.toLowerCase())}
      />

    </Box>


    <Box sx={{
      display: 'flex',
      justifyContent: 'center'
    }}>
      <Box sx={{
        width: '45%',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Button
          variant="contained"
          color="primary"
          disabled={resetPasswordDisabled}
          type={'submit'}
        >
                      Réinitialiser le mot de passe
        </Button>
        <Button startIcon={<ArrowBackIcon />}
          sx={{ mt: 2 }}
          variant="contained"
          color="primary"
          onClick={forgetPasswordMode}
        >
                      Revenir en arrière
        </Button>
      </Box>
    </Box>

  </form>;
}


export default withStyles(LoginResetPassword, styles);
