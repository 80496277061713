import React, { PureComponent } from 'react';
import {
  Link
} from 'react-router-dom';
import { connect } from 'react-redux';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Avatar from '@mui/material/Avatar';

import Immutable from 'immutable';
import {
  KeyboardArrowRight,
  KeyboardArrowDown
} from '@mui/icons-material';

const mapStateToProps = (state) => ({
  counter: state.counter,
});
class ListButtons extends PureComponent {
  state = {
    counterNumber: 0
  };
  componentDidUpdate() {
    const name = this.props.name;
    if (name &&
      this.props.counter[name] !== this.state.counterNumber) {
      this.setState({ counterNumer: this.props.counter[name] });
    }
  }

  genCounter = (classes, e) => {
    const { getCurrentColor } = this.props;
    const counterNumber = this.state.counterNumer;
    const split = e.link.split('/');
    if (counterNumber > 0)
    {
      return (<ListItemSecondaryAction className={classes.avatarContainer}>
        <Avatar
          variant='square'
          className={classes.avatar}
          style={getCurrentColor(split)}>
          {counterNumber}
        </Avatar>
      </ListItemSecondaryAction>);
    }
    return null;
  }


  render() {
    const {
      data,
      classes,
      users,
      open,
      userId,
    } = this.props;
    if (data.link) {
      return (<Link to={data.link}
        style={{ display: 'inherit', textDecoration: 'none' }}>
        <ListItemIcon className={classes.ListIcon}>
          {data.icon && <data.icon className={classes.svg} />}
        </ListItemIcon>
        <ListItemText
          disableTypography={false}
          primaryTypographyProps={{
            className: classes[data.bold ? 'bold' : 'text']
          }}
          primary={data.name} className={classes.linkTxt}
          classes={{ primary: classes[data.bold ? 'bold' : 'text'] }}
        />
        {data.count && users instanceof Immutable.List && userId &&
          this.genCounter(classes, data)
        }
        {data.childs && <ListItemIcon className={classes.ListIcon}>
          {open ?
            <KeyboardArrowDown
              style={{ paddingRight: 3, marginRight: 0,
                color: 'white', height: 20, width: 20 }}/> :
            <KeyboardArrowRight
              style={{ paddingRight: 3, marginRight: 0,
                color: 'white', height: 20, width: 20 }}/>}
        </ListItemIcon>}
      </Link>);
    }
    return [<ListItemIcon key={1} className={classes.ListIcon}>
      {data.icon && <data.icon className={classes.svg} />}
    </ListItemIcon>,
    <ListItemText
      disableTypography={false}
      primaryTypographyProps={{
        className: classes[data.bold ? 'bold' : 'text']
      }}
      key={2} primary={data.name} className={classes.linkTxt}
      classes={{ primary: classes[data.bold ? 'bold' : 'text'] }}
    />,
    data.count && users instanceof Immutable.List && userId &&
      this.genCounter(classes, data),
    data.childs && <ListItemIcon key={3} className={classes.ListIcon}>
      {open ?
        <KeyboardArrowDown
          style={{ paddingRight: 3, marginRight: 0,
            color: 'white', height: 20, width: 20 }}/> :
        <KeyboardArrowRight
          style={{ paddingRight: 3, marginRight: 0,
            color: 'white', height: 20, width: 20 }}/>}
    </ListItemIcon>];
  }
}

export default connect(mapStateToProps)(ListButtons);
