import React from 'react';
import store from '../store';
import { data as historyCat } from '@lba-dev/package.local-globals/historyCat';
import { moment } from '@lba-dev/package.local-globals/moment';
import classnames from 'classnames';
import {
  HighlightOff,
  AddCircle,
  ArrowDropUp,
  ArrowDropDown
} from '@mui/icons-material';
import handlerFilter from '../components/List/data/handlerFilterColumns';
import filesize from 'filesize';
import previewInNewTab, { base64ToPdfElement } from './previewInNewTab';
import {
  data as docStatus,
  DOCREF,
  DOCENC,
  DOCVAL,
} from '@lba-dev/package.local-globals/docStatus';
import {
  DBANQ,
  DKBIS,
  DSTRAI,
  DURSSA,
  DASSU,
  DCNI,
} from '@lba-dev/package.local-globals/docTypes';
import {
  calculFourniture,
  paymentDefault,
  getArtisanRemun,
  getMontant,
} from '@lba-dev/package.local-globals/comptaCalcules';
import { R_POR } from '@lba-dev/package.local-globals/remStatus';
import { CHIEF, ADMIN } from '@lba-dev/package.local-globals/permissions';
import { List } from 'immutable';
import { services } from '@lba-dev/package.local-globals/getDataById';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import {
  checkIfMobileNumber
} from '@lba-dev/package.local-globals/verifyNumber';


const priceFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
});

const style = {
  doc: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    margin: 10,
    padding: '2px 20px 2px 20px',
    borderRadius: 5,
    fontSize: 14,
    color: 'white',
  },
  textChipsDoc: {
    marginLeft: '15px',
  },
};

export const MAX_AUDIO_SIZE = filesize(16000000, { exponent: 2 });
export const MAX_FILES_SIZE = filesize(50000000, { exponent: 2 });
export const MAX_FILES = 20;

export const getSize = (files = []) =>
  filesize(
    files.reduce((a, v) => a + v.size, 0),
    { exponent: 2 }
  );

export const isMaxSizeOk = (files, max = MAX_FILES_SIZE) => {
  const ret = Number.parseFloat(getSize(files));
  max = Number.parseFloat(max, 10);
  return ret >= 0 && ret < max;
};

export const isMaxFileOk = (nb, max = MAX_FILES) => nb >= 0 && nb <= max;

export const deepCopy = obj => {
  let copy;
  if (obj instanceof Array) {
    copy = [...obj];
    copy.forEach((e, i) => {
      copy[i] = deepCopy(e);
    });
  } else if (typeof obj === 'object') {
    copy = { ...obj };
    Object.keys(obj).forEach(key => {
      obj[key] = deepCopy(obj[key]);
    });
  }
  return copy || obj;
};

export function cacheDisabled(type) {
  const obj = store.getState().config.find(t => t.type === type);
  return obj ? !obj.text : false;
}

export function getDataInObject(element, path, defaultValue = undefined) {
  if (!Array.isArray(path)) {
    if (typeof path !== 'string') {
      return defaultValue;
    }
    return typeof element[path] !== 'undefined' ? element[path] : defaultValue;
  }
  let i = 0;

  //eslint-disable-next-line
  while (path[i] && element && (element = element[path[i++]]));
  return typeof element !== 'undefined' ? element : defaultValue;
}

export function getAliasFromPhone(phone, def) {
  const user = store
    .getState()
    .users.find(
      (e) =>
        e.act &&
        (e.ligne === phone ||
          e.ligneService === phone ||
          e.multiLignes.includes(phone))
    );
  return user
    ? `${user.alias.charAt(0).toUpperCase() + user.alias.slice(1)
    } | ${services[user.service].name}`
    : def || 'indisponible';
}

export function getUsersByService(service) {
  return store.getState().users.filter(u => u.act && u.service === service);
}

export function getGrandCompteName(_id, def) {
  const gc = store
    .getState()
    .grandCompte.find(e => e.get('_id').toLowerCase() === _id.toLowerCase());
  return gc ? gc.get('companyName') : def || 'automatique';
}

export function getRemunerationArtisan(inter) {
  return (
    inter.remunerationArtisan ||
    (inter.currentArtisan || {}).remuneration ||
    {}
  ).status === R_POR
    ? inter.finalPrice
    : inter.priceArtisan;
}

export function getGrandCompteMarge(inter) {
  const { lba, artisan } = calculFourniture(inter.supplies || []);
  const price = inter.finalPrice;
  const paiement = paymentDefault({
    rem: inter.currentArtisan ?
      getArtisanRemun(inter, inter.currentArtisan) : null,
    bp: getRemunerationArtisan(inter),
    f: { f: lba, r: artisan },
    finalPrice: inter.finalPrice,
    categorie: inter.categorie,
    loginAjoutIntervention: inter.login.ajout
  }
  );
  return (price
    ? (price - (lba + (getMontant(paiement, false) || 0))) / 100
    : 0
  ).toFixed(2);
}

export function getGrandCompteMargePercent(inter) {
  const price = getGrandCompteMarge(inter);
  const priceArtisan = inter.finalPrice;
  return priceArtisan ? (price / (priceArtisan / 10000)).toFixed(0) : 0;
}

export function getLogin(_id, def, users) {
  const user = (users || store.getState().users).find(e => e._id === _id);
  return user ? user.login : (def !== undefined && 'automatique') || '';
}

export function loginToId(login) {
  const user = store.getState().users.find(e => e.login === login);
  return user ? user._id : null;
}

export function getAlias(_id, def) {
  const user = store.getState().users.find(e => e._id === _id);
  return user ? `${user.alias.charAt(0).toUpperCase()
    + user.alias.slice(1)
  } | ${services[user.service].name}` : def || '';
}

export function getCustomFromUser(_id, field) {
  const user = store.getState().users.find(e => e._id === _id);
  return user ? user[field] : '';
}

export function getUser(_id) {
  return store.getState().users.find(e => e._id === _id);
}

export function getType(i) {
  const type = historyCat.find(e => e._id === i);
  return type ? type.name : '';
}

export function getMomentOrDate(date) {
  return `${moment(date).format('L')} à
    ${moment(date).format('HH[h]mm')}`;
}

export function divide(first, second, fixed = 0) {
  return Number(first / second || 0).toFixed(fixed);
}

export function formatPrice(number) {
  return priceFormatter.format(number || 0);
}

const findElem = (arr, val) => arr.find(e => e._id === val) || {};
export const checkDocument = (
  dropbox = [],
  doc,
  { verifRIB, newBic = '', newIban = '' }
) => {
  if (dropbox.some(e => doc.type === e.type && e.status === DOCENC) ||
    (doc.type === DBANQ && verifRIB === false &&
      (newIban.length || newBic.length))) {
    return (findElem(docStatus, DOCENC).color || {})[500];
  } else if (
    dropbox.some(e => doc.type === e.type && e.status === DOCVAL)
  ) {
    return (findElem(docStatus, DOCVAL).color || {})[500];
  }
  return (findElem(docStatus, DOCREF).color || {})[500];
};

const document = [
  { type: DSTRAI, title: 'Contrat' },
  { type: DKBIS, title: 'KBIS' },
  { type: DCNI, title: 'CNI' },
  { type: DBANQ, title: 'RIB' },
  { type: DURSSA, title: 'URSSAF' },
  { type: DASSU, title: 'Assurance' },
];

export const GetDoc = ({ artisan, dropbox, handleGetDoc }) => (
  <div style={{ marginTop: 5 }}>
    {document.map((doc, i) => {
      const color = checkDocument(dropbox, doc, artisan);
      const icon =
        doc.title === 'Assurance' && artisan.noAssurance ? (
          <HighlightOff />
        ) : (
          handleGetDoc && <AddCircle />
        );
      return (
        <div
          key={i}
          style={{ ...style.doc, ...{ backgroundColor: color } }}
          data-title={doc.title}
          onClick={e => (handleGetDoc ? handleGetDoc(e) : e)}
        >
          <span>{icon}</span>
          <span style={style.textChipsDoc}>{doc.title}</span>
        </div>
      );
    })}
  </div>
);

export const showFilter = (elem, classes, handlerFilterByColumns, name) => {
  if (handlerFilter[name] && handlerFilter[name][elem]) {
    return (
      <span
        value={elem}
        className={classes.posBtn}
        onClick={handlerFilterByColumns}
      >
        <ArrowDropUp
          className={classnames(classes.btnFilter, classes.arrowUp)}
        />
        <ArrowDropDown
          className={classnames(classes.btnFilter, classes.arrowDown)}
        />
      </span>
    );
  }
};

export const openPDF = async (data, title) =>
  previewInNewTab(await base64ToPdfElement(data), title);

export const formatNumberPhone = number =>
  number.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');

export const checkPhoneNumber = (value) => checkIfMobileNumber(value);

export const throwIt = e => {
  throw e;
};

export const checkExpiry = e => e.cardInfo?.expiry && moment().isBefore({
  M: e.cardInfo.expiry.substring(e.cardInfo.expiry.search('^0') + 1, 2) - 1,
  Y: e.cardInfo.expiry.substring(3)
});

export const getEquipes = ({ user, users = [] }) => {
  const usersArr = List.isList(users) ? users.toJS() : users;
  const userTeam = user.equipe && user.equipe.ids.length
    ? [user, ...usersArr.filter(u => user.equipe.ids.includes(u._id))]
    : [user];
  if (permit(user, { key: 'commercialAdminAcces' })) {
    return usersArr;
  }
  else if (user.permission > ADMIN && user.permission <= CHIEF) {
    return userTeam;
  }
  return [user];
};

export const firstCallDiff = (firstCall, ajout) => {
  const diff = moment(firstCall).diff(moment(ajout), 'minutes');
  if (diff < 15) {
    return '-15 min';
  } else if (diff >= 15 && diff < 30) {
    return '30 min';
  } else if (diff >= 30 && diff < 60) {
    return 'Dans l\'heure';
  }
  return 'Hors delais';
};

export const firstCallFilters = (value) => {
  const ranges = {
    '-15 min': { max: 15 },
    '30 min': { min: 15, max: 30 },
    'Dans l\'heure': { min: 30, max: 60 },
    'Hors delais': { min: 60 }
  };
  const range = ranges[
    Object.keys(ranges).find(e => new RegExp(value, 'i').test(e))
  ];
  return {
    'date.firstCall': { $exists: true },
    ...(range && !range.min
      ? {
        $expr: {
          $lt: [
            { '$subtract': ['$date.firstCall', '$date.ajout'] },
            1000 * 60 * range.max
          ]
        }
      }
      : {}
    ),
    ...(range && !range.max
      ? {
        $expr: {
          $gt: [
            { '$subtract': ['$date.firstCall', '$date.ajout'] },
            1000 * 60 * range.min
          ]
        }
      }
      : {}
    ),
    ...(range && range.max && range.min
      ? {
        $expr: {
          $and: [
            {
              $lt: [
                { '$subtract': ['$date.firstCall', '$date.ajout'] },
                1000 * 60 * range.max
              ],
            },
            {
              $gt: [
                { '$subtract': ['$date.firstCall', '$date.ajout'] },
                1000 * 60 * range.min
              ]
            }
          ]
        }
      }
      : {}
    ),
  };
};

/**
 * @param {string} str
 */
export function getCapitalLetters(str) {
  return str.match(/[A-Z]/g).join('');
}

export const artisanKeys = (user) => permit(
  user, { key: 'displayArtisanCompanyName' })
  ? ['currentArtisan.companyName', '']
  : ['currentArtisan.name', 'currentArtisan.firstname'];

export function getEvaluationByRating(rating) {
  if (typeof rating === 'string') {
    if (rating.includes('/')) {
      const rate = rating.split('/')[0];
      rating = isNaN(rate) ? -1 : +rate;
    } else {
      rating = isNaN(rating) ? -1 : +rating;
    }
  }

  if (rating >= 0 && rating <= 2) {
    return {
      eval: `Jamais (${rating})`,
      color: 'error'
    };
  } else if (rating > 2 && rating < 4) {
    return {
      eval: `Parfois (${rating})`,
      color: 'warning'
    };
  } else if (rating >= 4 && rating <= 5) {
    return {
      eval: `Toujours (${rating})`,
      color: 'success'
    };
  }
  return {
    eval: 'Non évalué',
    color: 'info'
  };

}
