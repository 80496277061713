import React from 'react';
import { connect } from 'react-redux';

import { permit } from '@lba-dev/package.local-globals/restrictions';

const globalRestrictions = user => {
  const cond = !permit(user, { key: 'accesExterne' });
  return {
    MenuBar: {
      props: () => ({
        disableAudioSearch: cond,
        disableListes: cond,
      }),
    },
    DisplaySavIntervention: {
      ChooseCategorie: {
        hide: cond,
      },
      Gmap: {
        hide: cond,
      },
      InterList: {
        hide: cond,
      },
      DisplayComponent: {
        hide: cond,
      },
      InfoIntervention: {
        DateSelector: {
          InformationsArtisan: {
            props: (props) => ({
              disabled: props.disabled || cond,
            }),
          },
          CustomCheckbox: {
            props: (props) => ({
              disabled: props.disabled || cond,
            }),
          },
          DatePicker: {
            props: (props) => ({
              disabled: props.disabled || cond,
            }),
          },
        },
        CustomTextField: {
          props: (props) => ({
            disabled: props.disabled || cond,
          }),
        },
        CustomCheckbox: {
          props: (props) => ({
            disabled: props.disabled || cond,
          }),
        },
        'MoneyInput.Prix Annoncé HT': {
          props: (props) => ({
            disabled: props.disabled || cond,
          }),
        },
        'MoneyInput.  ': {
          props: (props) => ({
            disabled: props.disabled || cond,
          }),
        },
        'MoneyInput.Prix validé par l\'artisan H.T': {
          props: (props) => ({
            disabled: props.disabled || cond,
          }),
        },
        'MoneyInput.Prix Final': {
          props: (props) => ({
            disabled: props.disabled || cond,
          }),
        },
        CustomSwitch: {
          props: (props) => ({
            disabled: props.disabled || cond,
          }),
        },
        PaymentMethod: {
          CustomSelect: {
            props: (props) => ({
              disabled: props.disabled || cond,
            }),
          },
        },
        RibClient: {
          hide: cond,
        },
        props: (props) => ({
          disabled: props.disabled || cond,
        }),
      },
      Product: {
        ProductFooter: {
          CustomButton: {
            hide: cond,
          },
          'CustomButton.0': {
            hide: false,
          },
          'CustomButton.1': {
            hide: false,
          },
          'CustomButton.2': {
            hide: false,
          },
          TooltipIconButton: {
            hide: cond,
          },
          'TooltipIconButton.0': {
            hide: false,
          },
        },
      },
      InterventionHeader: {
        CustomTypography: {
          hide: cond,
        },
        CustomSelect: {
          hide: cond,
        },
      },
    },
    AjoutIntervention: {
      ChooseCategorie: {
        hide: cond,
      },
      Gmap: {
        hide: cond,
      },
      InterList: {
        hide: cond,
      },
      DisplayComponent: {
        hide: cond,
      },
      InfoIntervention: {
        DateSelector: {
          InformationsArtisan: {
            props: (props) => ({
              disabled: props.disabled || cond,
            }),
          },
          CustomCheckbox: {
            props: (props) => ({
              disabled: props.disabled || cond,
            }),
          },
          DatePicker: {
            props: (props) => ({
              disabled: props.disabled || cond,
            }),
          },
        },
        CustomTextField: {
          props: (props) => ({
            disabled: props.disabled || cond,
          }),
        },
        CustomCheckbox: {
          props: (props) => ({
            disabled: props.disabled || cond,
          }),
        },
        'MoneyInput.Prix Annoncé HT': {
          props: (props) => ({
            disabled: props.disabled || cond,
          }),
        },
        'MoneyInput.  ': {
          props: (props) => ({
            disabled: props.disabled || cond,
          }),
        },
        'MoneyInput.Prix validé par l\'artisan H.T': {
          props: (props) => ({
            disabled: props.disabled || cond,
          }),
        },
        'MoneyInput.Prix Final': {
          props: (props) => ({
            disabled: props.disabled || cond,
          }),
        },
        CustomSwitch: {
          props: (props) => ({
            disabled: props.disabled || cond,
          }),
        },
        PaymentMethod: {
          CustomSelect: {
            props: (props) => ({
              disabled: props.disabled || cond,
            }),
          },
        },
        RibClient: {
          hide: !permit(user, { key: 'ribDisplay' }),
        },
        PaymentMethodsComponent: {
          hide: !permit(user, { key: 'ribDisplay' }),
        },
        props: (props) => ({
          disabled: props.disabled || cond,
        }),
      },
      NotationIntervention: {
        hide: !permit(user, { key: 'notation' }),
      },
      Product: {
        ProductFooter: {
          CustomButton: {
            hide: cond,
          },
          'CustomButton.0': {
            hide: false,
          },
          'CustomButton.1': {
            hide: false,
          },
          'CustomButton.2': {
            hide: false,
          },
          TooltipIconButton: {
            hide: cond,
          },
          'TooltipIconButton.0': {
            hide: false,
          },
        },
      },
      InterventionHeader: {
        CustomTypography: {
          hide: false,
        },
      },
      RecouvrementActions: {
        hide: cond,
      },
    },
    StatsComptabilite: {
      hide: cond
    },
    PaymentAnnexes: {
      hide: cond
    }
  };
};

const mapStateToProps = ({ users, userId }) => ({
  _wDUser: users.find(u => u._id === userId)
});

const defaultPriorityKeys = [
  'displaykey',
  'title',
  'label',
  'name'
];

const defaultGenerateComposedName = (name, keys, props) => {
  for (let i = 0; i < keys.length; i++) {
    const value = props[keys[i]];
    if (
      value !== undefined
      && value !== null
      && value !== false
    ) {
      return `${name}.${value}`;
    }
  }
  return name;
};

const obtainRestrictions = (restrictions, name, composedName, props) => {
  let obj = restrictions && (restrictions[composedName] || restrictions[name]);

  if (!obj) {
    const restrictions = globalRestrictions(props.user);

    obj = restrictions[composedName] || restrictions[name] || {};
  }
  return typeof obj === 'function' ? obj(props) : obj;
};

function withDisplaying(options = {}) {
  const {
    generateComposedName = defaultGenerateComposedName,
    keys = defaultPriorityKeys,
    name = ''
  } = options;
  const wrapWithDisplaying = function (WrappedComponent) {
    const _class = connect(mapStateToProps, {});

    _class.displayName = name || WrappedComponent.displayName;
    const WithDisplaying = function ({ restrictions, _wDUser, ...props }) {
      if (!_wDUser) {
        return null;
      }
      const {
        props: restrictionsProps = f => f,
        hide,
        ...restrictedComponents
      } = obtainRestrictions(
        restrictions,
        _class.displayName,
        generateComposedName(_class.displayName, keys, props),
        { user: _wDUser, ...props }
      );

      return hide ? null : (
        <WrappedComponent
          {...props}
          {...restrictionsProps(props)}
          restrictions={restrictedComponents}
        />
      );
    };

    return _class(WithDisplaying);
  };

  return wrapWithDisplaying;
}

export default withDisplaying;
