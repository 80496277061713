import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import Picker from 'emoji-picker-react';

import Toolbar from '@mui/material/Toolbar';
import LinearProgress from '@mui/material/LinearProgress';
import { Menu } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { SUGG_SMS } from '@lba-dev/package.local-globals/configTypes';
import { attachFile } from '@lba-dev/package.local-globals/utils';

import notifSystem from '../../../../../notifSystem';
import SuggestionsBar from '../SuggestionsBar';
import api from '../../../../../api';
import InputChat from './InputChat';
import { removeDivs } from '../../../constant';
import {
  isMaxFileOk, isMaxSizeOk,
  MAX_FILES, MAX_FILES_SIZE
} from '../../../../../utils/function';
import { Map } from 'immutable';
import { checkWP } from '../../../../../actions/conversations';

const mapStateToProps = ( { users, config, userId } ) => ({
  user: users.find(u => u._id === userId),
  users,
  config
});

const styles = (theme) => ({
  toolbar: {
    marginBottom: 2,
    border: '1px solid rgba(0,0,0,0.125)',
    borderRadius: 5,
    padding: '8px 16px',
  },
  linearDiv: {
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: -1,
  },
  linear: {
    height: 1,
  },
  textField: {
    width: '100%',
    '& #editableinput': {
      whiteSpace: 'pre-wrap',
      width: '100%',
      height: 70,
      overflow: 'auto',
      outline: '0px solid transparent'
    },
    '& #editableinput>span::before': {
      content: '" "'
    },

  },
  uploadInput: {
    display: 'none'
  },
  audioBox: {
    display: 'flex',
    width: 368,
  },
  audioBoxHidden: {
    width: 0,
  },
  inputBox: {
    position: 'relative',
    width: '100%',
  },
  containerPaper: {
    position: 'absolute',
    width: '100%',
    bottom: '100%',
    minHeight: 50,
    overflow: 'hidden',
    maxHeight: 250,
    '& > ul': {
      overflow: 'auto',
      height: '90%',
      maxHeight: 203,
      paddingLeft: 0,
      margin: 0
    },
  },
  numberSelect: {
    '&::before': {
      borderWidth: '0px'
    },
    '& > div': {
      padding: '0px!important',
      lineHeight: 'normal',
      minHeight: 'auto!important'
    },
  },
  numberSelectIcon: {
    display: 'none'
  },
  selectValueNumber: {
    color: theme.palette.primary.main
  },
  serviceDiv: {
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    whiteSpace: 'nowrap',
    overflow: 'auto',
    boxShadow: theme.shadows[3],
    backgroundColor: '#fff'
  },
  fileAvatar: {
    width: 70,
    height: 70,
    borderRadius: 5,
    color: '#000',
    opacity: 0.4
  },
  fileDeleteIcon: {
    position: 'absolute',
    right: 18,
    top: 24
  }
});

const checkNumber = (number, dataObject) =>
  (dataObject.tels || []).find(e => e.tel === number) ? number : '';

class ChatInput extends PureComponent {
  constructor(props) {
    super(props);
    const lastElem = (props.data.last() || new Map()).toJS();
    const lastNumber = (props.selectedNumber ||
      (lastElem.sender ? lastElem.to : lastElem.from) || '')
      .replace('+33', '0');

    this.state = {
      input: '',
      file: null,
      sending: false,
      showEmoji: false,
      valueTab: 0,
      activeSmsSugestion: props.config.filter(u => u.type === SUGG_SMS
      && u.enable === true),
      showSmsSuggest: false,
      anchorEl: null,
      audioFile: null,
      selectedNumber: checkNumber(lastNumber, props.dataObject),
      hasWhatsapp: !!props.hasWhatsapp
    };
  }

  componentDidMount(){
    document.addEventListener('keydown', this.keydownHandler);
  }
  componentWillUnmount(){
    document.removeEventListener('keydown', this.keydownHandler);
  }

  keydownHandler = (e) => {
    if (this.state.input && e.key === 'Enter' && e.ctrlKey) {
      this.send();
    }
  };

  setInput = (value, f = f => f) => {
    this.setState({ input: value, showSmsSuggest: false }, f);
  }

  onEmojiClick = (event, emojiObject) => {
    this.setState({ input: this.state.input + emojiObject.emoji });
  };

  send = () => {
    try {
      const {
        input, file, audioFile, selectedNumber, hasWhatsapp
      } = this.state;
      const {
        name: collection, id, user,
        filters, scroll, newContact
      } = this.props;
      if (!(input.trim() || file || audioFile)) {
        notifSystem.error('Erreur', 'Veuillez écrire un message');
      }
      const message = audioFile ? '' : `${input}${
        user ? `\n\n${user.alias}` : ''
      }`;
      const uploadedFile = file;
      this.setState(
        {
          input: '',
          file: null,
          sending: true,
          audioFile: null,
        },
        () => {
          api.one('sms', id)
            .custom('send')
            .post({
              message: removeDivs(message),
              collection,
              uploadedFile,
              newContact,
              filters: JSON.stringify(filters),
              hasWhatsapp,
              selectedNumber,
              audioFile,
            }).then(() => {
              scroll();
            })
            .catch(e => notifSystem.error(e.name, e.response ?
              e.response.data : e.message))
            .finally(() => this.setState({ sending: false }));
        }
      );
    } catch (error) {
      notifSystem.error(error.name, error.response ?
        error.response.data : error.message);
    }
  }

  handleFile = (f) => {
    if (!f) {
      this.setState({ file: null });
      return;
    }
    attachFile(f.target.files[0])
      .then(file => {
        if (!isMaxSizeOk([file], MAX_FILES_SIZE)) {
          return notifSystem.error(
            'Erreur',
            'La taille totale dépasse la limite autorisée'
          );
        }
        if (!isMaxFileOk([file].length, MAX_FILES)) {
          return notifSystem.error(
            'Erreur',
            'Le nombre de fichiers dépasse la limite autorisée'
          );
        }
        this.setState({ file });
        f.target.value = null;
      });
  }


  displaySuggestion = () => {
    const showSmsSuggest = this.state.showSmsSuggest;
    this.setState({ showSmsSuggest: !showSmsSuggest });
    this.props.scroll();
  };

  removeFile = () => this.setState({ file: null })

  showEmojiPicker = (e) =>
    this.setState({ anchorEl: e.currentTarget, showEmoji: true })

  handleAudio = (audio) => {
    this.setState({ audioFile: audio });
  }

  setSelectedNumber = (number) => {
    this.setState({
      selectedNumber: number
    }, async () => {
      const hasWhatsapp = await checkWP(number);
      this.setState({ hasWhatsapp });
    });
  }

  render() {
    const { classes, name, users, dataObject } = this.props;
    const {
      showEmoji,
      activeSmsSugestion,
      showSmsSuggest,
      anchorEl,
      input,
      file,
      selectedNumber,
      audioFile,
      hasWhatsapp
    } = this.state;

    return [
      showSmsSuggest && (
        <SuggestionsBar
          handleOnClick={this.setInput}
          suggestionsSms={activeSmsSugestion}
        />
      ),
      this.state.sending && (
        <div key={1} className={classes.linearDiv}>
          <LinearProgress className={classes.linear} />
        </div>
      ),
      <Toolbar key={2} className={classes.toolbar}>
        <InputChat
          {
            ...{
              value: input,
              handleAudio: this.handleAudio,
              audioFile,
              classes,
              name,
              hasWhatsapp,
              showSmsSuggest,
              file,
              handleFile: this.handleFile,
              displaySuggestion: this.displaySuggestion,
              removeFile: this.removeFile,
              send: this.send,
              showEmojiPicker: this.showEmojiPicker,
              setInput: this.setInput,
              users,
              dataObject,
              setSelectedNumber: this.setSelectedNumber,
              selectedNumber
            }
          }
        />
      </Toolbar>,
      <Menu
        key={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={showEmoji}
        onClose={() => this.setState({ showEmoji: false })}
      >
        <Picker onEmojiClick={this.onEmojiClick} />
      </Menu>,
    ];
  }
}
export default connect(mapStateToProps)(
  withStyles(ChatInput, styles)
);
