import { S_RH } from '@lba-dev/package.local-globals/services';

import {
  ADMIN, NOT_CONFIRMED
} from '@lba-dev/package.local-globals/permissions';
import componentLoader from '../../componentLoader';

const ListHoldays = componentLoader(() =>
  import(
    /* webpackChunkName: "ListHoldays" */
    '../../views/ListHoldays'
  )
);

export default [
  {
    path: '/users/holidays',
    component: ListHoldays,
    restrictions: ({ service }) =>
      ({
        [S_RH]: NOT_CONFIRMED,
      }[service] || ADMIN),
  },
];
