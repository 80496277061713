import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const styles = {
  root: {
    marginBottom: 5,
    flexGrow: 1,
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  FormControlLabel: {
    padding: '0px 10px',
    width: '90%'
  },
  ListItem: {
    backgroundColor: '#8080805c'
  },
};

class ArrayFilter extends PureComponent {

  state = {
    openFiltre: true
  }

  handleClickFiltre = () => {
    this.setState(state => ({ openFiltre: !state.openFiltre }));
  };

  render() {
    const {
      classes,
      data,
      setArrayFilter,
      globalData,
      label,
      field
    } = this.props;
    const {
      openFiltre
    } = this.state;
    return (
      <List
        component="nav"
        className={classes.root}>
        <ListItem button onClick={this.handleClickFiltre}
          className={classes.ListItem}>
          <ListItemText inset primary={label} />
          {openFiltre ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse className={classes.FormControlLabel}
          in={openFiltre} timeout="auto" unmountOnExit>
          {globalData.map((el, i) => (
            <FormControlLabel
              key={i}
              className={classes.FormControlLabel}
              control={
                <Checkbox
                  onClick={() => setArrayFilter(field, el._id)}
                  checked={data.includes(el._id)}
                  value={el.name}
                />
              } label={el.name} />
          )
          )}
        </Collapse>
      </List>
    );
  }
}

export default withStyles(ArrayFilter, styles);
