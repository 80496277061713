import React, { PureComponent } from 'react';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Money from '../../utils/Money';
import MoneyInput from '../CustomInputs/MoneyInput';
import CustomSelect from '../CustomInputs/CustomSelect';
import { TVA } from '@lba-dev/package.local-globals/comptaCalcules';

const setHT = (ttc, tva) => Math.round(ttc / (1 + (tva / 100)));

class ValidationFacture extends PureComponent {

  setTTC = (value) => {
    if (typeof this.props.setData === 'function') {
      let reglement = this.props.reglement;
      reglement = reglement
        .set('ttc', value)
        .set('ht', setHT(value, reglement.get('tva')));
      this.props.setData('reglement', reglement);
    }
  }

  setTVA = (value) => {
    if (typeof this.props.setData === 'function') {
      let reglement = this.props.reglement;
      reglement = reglement.set('tva', value)
        .set('ht', setHT(reglement.get('ttc'), value));
      this.props.setData('reglement', reglement);
    }
  }
  setData = (path, value) => {
    if (typeof this.props.setData === 'function') {
      this.props.setData('reglement', this.props.reglement.set(path, value));
    }
  }

  render() {
    const { data, reglement, justDisplay } = this.props;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">VALIDATION FACTURE</Typography>
          <Typography variant="caption">
            {`Prix final ${Money.toString(data.get('finalPrice') || 0)} €`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <MoneyInput
            label="Montant TTC"
            type="number"
            defaultValue={reglement.get('ttc')}
            path={['ttc']}
            disabled={justDisplay || false}
            setData={(p, v) => {
              this.setTTC(v);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomSelect
            path={['tva']}
            value={reglement.get('tva') || 0}
            label="TVA"
            setData={(p, v) => {
              this.setTVA(v);
            }}
            fullWidth
            disabled={justDisplay || false}
          >
            {TVA.map(e => (
              <MenuItem key={e.number} value={e.number}>
                TVA {e.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </Grid>
        <Grid item xs={4}>
          <MoneyInput
            label="Montant HT"
            type="number"
            defaultValue={reglement.get('ht')}
            path={['']}
            disabled={justDisplay || true}
            setData={() => null}
          />
        </Grid>
      </Grid>
    );
  }
}

export default ValidationFacture;
