import React from 'react';
import PropTypes from 'prop-types';

import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const ListFooter = ({
  count,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  handleChangeRowsPerPage,
  handleChangePage,
}) => (
  <TableFooter>
    <TableRow>
      <TablePagination
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        rowsPerPageOptions={rowsPerPageOptions}
        labelRowsPerPage={'Lignes par page:'}
        onRowsPerPageChange={({ target: { value } }) =>
          handleChangeRowsPerPage(value)
        }
        onPageChange={(e, page) => handleChangePage(page)}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count}`
        }
      />
    </TableRow>
  </TableFooter>
);

ListFooter.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.array,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired
};

ListFooter.defaultProps = {
  count: 0,
  page: 0,
  rowsPerPage: 10,
  rowsPerPageOptions: [10, 25, 50, 100],
  handleChangeRowsPerPage: f => f,
  handleChangePage: f => f
};

export default ListFooter;
