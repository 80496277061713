/* eslint-disable max-lines */
import {
  List,
  AttachMoney,
  VisibilityOff,
  Visibility,
  Update,
  Lock,
  Person,
  Gavel,
  VerifiedUser,
  Whatshot,
  Star,
  MonetizationOn,
  Business,
  RateReview,
  CardTravel,
  Autorenew,
  ThumbsUpDown,
  Clear,
  Blinds
} from '@mui/icons-material';
import { monthToString } from '../../../utils/date';
import { S_JUR } from '@lba-dev/package.local-globals/services';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import {
  CC_CLOT, CM_CLOT, dataC, dataM
} from '@lba-dev/package.local-globals/caldeoType';
import savIntervention from './savIntervention';
import { listByUsers } from './functions';

const baseUrl = '/intervention/recouvrement/';
const baseParams = 'login-recouvrement';

export default [
  {
    name: 'Contentieux Client',
    icon: CardTravel,
    childs: [
      {
        name: 'Recouvrement Client',
        icon: Gavel,
        childs: [
          {
            name: 'Non Pris',
            icon: VisibilityOff,
            childs: [
              {
                name: 'Tout',
                icon: List,
                count: true,
                link: '/intervention/recouvrement/clientNpris',
              },
              {
                name: 'Date définie par le client',
                icon: List,
                count: true,
                link: '/intervention/recouvrement/clientNprisQuality',
              },
              {
                name: 'Date non définie',
                icon: List,
                count: true,
                link: '/intervention/recouvrement/clientNprisQualityNoContact',
              },
              {
                name: 'Retard d\'envoi',
                icon: List,
                count: true,
                link: '/intervention/recouvrement/clientNprisRE',
              },
              {
                name: 'Facture non reçue',
                icon: List,
                count: true,
                link: '/intervention/recouvrement/clientNprisFNR',
              },
            ],
          },
          {
            name: 'Prise en Charge',
            icon: Visibility,
            childs: [
              {
                name: 'Tout',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/clientPris',
              },
              {
                name: 'Date définie par le client',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/clientQuality',
              },
              {
                name: 'Date non définie',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/clientQualityNoContact',
              },
              {
                name: 'Retard d\'envoi',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/clientRetardEnvoi',
              },
              {
                name: 'Chèque perdu',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/clientChequePerdu',
              },
              {
                name: 'Chèque sans provision',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/clientChequeSansProvision',
              },
              {
                name: 'Pas de contact',
                link: '/intervention/recouvrement/clientPasDeContact',
                count: true,
                icon: List,
              },
              {
                name: 'Facture non reçue',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/clientFactureNonRecue',
              },
            ],
          },
          {
            name: 'Relances',
            count: true,
            icon: Update,
            link: '/intervention/recouvrement/clientRelances',
          },
          {
            name: 'Résolues',
            count: true,
            icon: VerifiedUser,
            link: '/intervention/recouvrement/clientResolues',
          },
          {
            name: 'Pertes',
            count: true,
            icon: Lock,
            link: '/intervention/recouvrement/clientPertes',
          },
          {
            name: 'A Reprendre',
            icon: Autorenew,
            count: true,
            link: '/intervention/recouvrement/clientAReprendre',
          },
          {
            name: 'Résolutions à vérifier',
            icon: ThumbsUpDown,
            count: true,
            link: '/intervention/recouvrement/resolutionsAVerifRecouvrement',
          },
          {
            name: 'Résolutions refusées',
            icon: Clear,
            count: true,
            link: (e) =>
              permit(e, { key: 'preResolution' })
                ? '/intervention/recouvrement/mesResolutionsRef'
                : '/intervention/recouvrement/mesResolutionsRef/' +
                  `login-preResolutionRecouvrement=${e.login}=${e._id}`,
          },
          {
            name: 'Pertes à vérifier',
            icon: ThumbsUpDown,
            count: true,
            link: '/intervention/recouvrement/pertesAVerifRecouvrement',
          },
          {
            name: 'Pertes refusées',
            icon: Clear,
            count: true,
            link: (e) =>
              permit(e, { key: 'preResolution' })
                ? '/intervention/recouvrement/mesPertesRef'
                : '/intervention/recouvrement/mesPertesRef/' +
                  `login-preResolutionRecouvrement=${e.login}=${e._id}`,
          },
          listByUsers({
            name: 'recouvrements',
            array: [
              {
                name: 'Non pris',
                link: 'mesClientNonPris',
                params: 'login-attributionRecouvrement',
              },
              { name: 'En cours', link: 'mesClientEnCours' },
              { name: 'En SAV', link: 'mesClientSAV' },
              { name: 'Pertes', link: 'mesClientPertes' },
              { name: 'Résolues', link: 'mesClientResolues' },
              { name: 'Injonction de payer', link: 'mesClientInjonction' },
              { name: 'Huissier', link: 'mesClientHuissier' },
            ],
            service: S_JUR,
            baseUrl,
            baseParams,
          }),
        ],
      },
      {
        name: 'Litige Client',
        icon: Whatshot,
        childs: [
          {
            name: 'Non Pris',
            count: true,
            icon: VisibilityOff,
            link: '/intervention/recouvrement/litigeClientNpris',
          },
          {
            name: 'Pris En Charge',
            icon: Visibility,
            childs: [
              {
                name: 'Tout',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/litigeClientPris',
              },
              {
                name: 'Avec Reglement',
                icon: List,
                childs: [
                  {
                    name: 'Tout',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigeClientAvecRegl',
                  },
                  {
                    name: 'Qualité',
                    count: true,
                    icon: List,
                    link:
                      '/intervention/recouvrement/litigeClientAvecReglQualite',
                  },
                  {
                    name: 'Tarif',
                    count: true,
                    icon: List,
                    link:
                      '/intervention/recouvrement/litigeClientAvecReglTarif',
                  },
                  {
                    name: 'Pas de devis',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigeClientAvecReglPD',
                  },
                  {
                    name: 'Risque d\'opposition',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigeClientAvecReglRO',
                  },
                ],
              },
              {
                name: 'Sans Reglement',
                icon: List,
                childs: [
                  {
                    name: 'Tout',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigeClientSansRegl',
                  },
                  {
                    name: 'Qualité',
                    count: true,
                    icon: List,
                    link:
                      '/intervention/recouvrement/litigeClientSansReglQualite',
                  },
                  {
                    name: 'Tarif',
                    count: true,
                    icon: List,
                    link:
                      '/intervention/recouvrement/litigeClientSansReglTarif',
                  },
                  {
                    name: 'Pas de devis',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigeClientSansReglPD',
                  },
                  {
                    name: 'Opposition',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigeClientSansReglOP',
                  },
                ],
              },
            ],
          },
          {
            name: 'Résolues',
            count: true,
            icon: VerifiedUser,
            link: '/intervention/recouvrement/litigeClientResolues',
          },
          {
            name: 'Pertes',
            count: true,
            icon: Lock,
            link: '/intervention/recouvrement/litigePertes',
          },
          {
            name: 'A Reprendre',
            icon: Autorenew,
            count: true,
            link: '/intervention/recouvrement/litigeAReprendre',
          },
          {
            name: 'Résolutions à vérifier',
            icon: ThumbsUpDown,
            count: true,
            link: (e) =>
              permit(e, { key: 'preResolution' })
                ? '/intervention/recouvrement/resolutionsAVerifLitige'
                : '/intervention/recouvrement/resolutionsAVerifLitige/' +
                  `login-preResolutionRecouvrement=${e.login}=${e._id}`,
          },
          {
            name: 'Résolutions refusées',
            icon: Clear,
            count: true,
            link: (e) =>
              permit(e, { key: 'preResolution' })
                ? '/intervention/recouvrement/litigeMesResolutionsRef'
                : '/intervention/recouvrement/litigeMesResolutionsRef/' +
                  `login-preResolutionRecouvrement=${e.login}=${e._id}`,
          },
          {
            name: 'Pertes à vérifier',
            icon: ThumbsUpDown,
            count: true,
            link: (e) =>
              permit(e, { key: 'preResolution' })
                ? '/intervention/recouvrement/perteAVerifLitige'
                : '/intervention/recouvrement/perteAVerifLitige/' +
                  `login-preResolutionRecouvrement=${e.login}=${e._id}`,
          },
          {
            name: 'Pertes refusées',
            icon: Clear,
            count: true,
            link: (e) =>
              permit(e, { key: 'preResolution' })
                ? '/intervention/recouvrement/litigeMesPertesRef'
                : '/intervention/recouvrement/litigeMesPertesRef/' +
                  `login-preResolutionRecouvrement=${e.login}=${e._id}`,
          },
          listByUsers({
            name: 'litiges',
            array: [
              {
                name: 'Non pris',
                link: 'litigeMesClientNonPris',
                params: 'login-attributionRecouvrement',
              },
              {
                name: 'En cours',
                childs: [
                  {
                    name: 'Sans reglement',
                    link: 'litigeMesClientSansReglement',
                  },
                  {
                    name: 'Avec reglement',
                    link: 'litigeMesClientAvecReglement',
                  },
                ],
              },
              { name: 'En SAV', link: 'litigeMesClientSAV' },
              { name: 'Pertes', link: 'litigeMesClientPertes' },
              { name: 'Résolues', link: 'litigeMesClientResolues' },
            ],
            service: S_JUR,
            baseUrl,
            baseParams,
          }),
          {
            name: 'Intervention -300 €',
            count: true,
            icon: List,
            link: '/intervention/recouvrement/litigeClient300',
          },
        ],
      },
      {
        name: 'Procédures judiciaires',
        icon: Blinds,
        childs: [
          {
            name: 'Non Pris',
            count: true,
            icon: VisibilityOff,
            link: '/intervention/recouvrement/pjNpris',
          },
          {
            name: 'Pris En Charge',
            icon: Visibility,
            childs: [
              {
                name: 'Tout',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/pjPris',
              },
              {
                name: 'Assignation',
                icon: List,
                link: '/intervention/recouvrement/pjPrisAssignation',
              },
              {
                name: 'Injonctions',
                icon: List,
                link: '/intervention/recouvrement/pjPrisInonction',
              },
              {
                name: 'Expertises',
                icon: List,
                link: '/intervention/recouvrement/pjPrisExpertise',
              },
            ],
          },
          {
            name: 'Résolues',
            count: true,
            icon: VerifiedUser,
            link: '/intervention/recouvrement/pjResolues',
          },
          {
            name: 'Pertes',
            count: true,
            icon: Lock,
            link: '/intervention/recouvrement/pjPertes',
          },
          {
            name: 'A Reprendre',
            icon: Autorenew,
            count: true,
            link: '/intervention/recouvrement/pjAReprendre',
          },
          {
            name: 'Résolutions à vérifier',
            icon: ThumbsUpDown,
            count: true,
            link: (e) =>
              permit(e, { key: 'preResolution' })
                ? '/intervention/recouvrement/pjResolutionsAVerif'
                : '/intervention/recouvrement/pjResolutionsAVerif/' +
                `login-preResolutionRecouvrement=${e.login}=${e._id}`,
          },
          {
            name: 'Résolutions refusées',
            icon: Clear,
            count: true,
            link: (e) =>
              permit(e, { key: 'preResolution' })
                ? '/intervention/recouvrement/pjMesResolutionsRef'
                : '/intervention/recouvrement/pjMesResolutionsRef/' +
                `login-preResolutionRecouvrement=${e.login}=${e._id}`,
          },
          {
            name: 'Pertes à vérifier',
            icon: ThumbsUpDown,
            count: true,
            link: (e) =>
              permit(e, { key: 'preResolution' })
                ? '/intervention/recouvrement/pjPerteAVerif'
                : '/intervention/recouvrement/pjPerteAVerif/' +
                `login-preResolutionRecouvrement=${e.login}=${e._id}`,
          },
          {
            name: 'Pertes refusées',
            icon: Clear,
            count: true,
            link: (e) =>
              permit(e, { key: 'preResolution' })
                ? '/intervention/recouvrement/pjMesPertesRef'
                : '/intervention/recouvrement/pjMesPertesRef/' +
                `login-preResolutionRecouvrement=${e.login}=${e._id}`,
          },
          listByUsers({
            name: 'Procédures judiciaires',
            array: [
              {
                name: 'Non pris',
                link: 'pjMesClientNonPris',
                params: 'login-attributionRecouvrement',
              },
              {
                name: 'En cours',
                childs: [
                  {
                    name: 'Assignation',
                    link: 'pjMesClientAssignation',
                  },
                  {
                    name: 'Injonctions',
                    link: 'pjMesClientInjonction',
                  },
                  {
                    name: 'Expertises',
                    link: 'pjMesClientExpertise',
                  },
                ],
              },
              { name: 'Pertes', link: 'pjMesClientPertes' },
              { name: 'Résolues', link: 'pjMesClientResolues' },
            ],
            service: S_JUR,
            baseUrl,
            baseParams,
          }),
        ],
      },
      {
        name: 'Recouvrement Client Encaissement',
        icon: MonetizationOn,
        childs: [
          {
            name: 'Tout',
            icon: List,
            count: true,
            link: '/intervention/recouvrement/clientReglementAll',
          },
          ...[...Array(12).keys()].map((i) => ({
            name: monthToString(new Date(), -i),
            icon: List,
            count: true,
            link: `/intervention/recouvrement/clientReglement${monthToString(
              new Date(),
              -i
            )
              .replace(/\..+/, '')
              .capitalize()}`,
          })),
        ],
      },
      {
        name: 'Encaissement Litige',
        icon: MonetizationOn,
        childs: [
          {
            name: 'Tout',
            icon: List,
            count: true,
            link: '/intervention/recouvrement/litigeClientReglementAll',
          },
          ...[...Array(12).keys()].map((i) => ({
            name: monthToString(new Date(), -i),
            icon: List,
            count: true,
            link: `/intervention/recouvrement/litigeClientReglement${
              monthToString(
                new Date(),
                -i
              )
                .replace(/\..+/, '')
                .capitalize()}`,
          })),
        ],
      },
    ],
  },
  {
    name: 'Satisfaction client',
    icon: RateReview,
    childs: [
      {
        name: 'Qualité (new)',
        icon: Star,
        childs: [
          {
            name: 'À noter',
            icon: List,
            childs: [
              {
                name: '1 à 3 étoiles',
                icon: List,
                count: true,
                link: '/intervention/quality/qualityInterventionLowStars',
              },
              {
                name: '< 350',
                count: true,
                icon: List,
                link: '/intervention/quality/qualityInterventionLowPrice',
              },
              {
                name: 'BTOB & BTOBTOC',
                count: true,
                icon: List,
                link: '/intervention/quality/qualityInterventionGc',
              },
              {
                name: '> 350 ou avec devis',
                count: true,
                icon: List,
                link: '/intervention/quality/qualityInterventionHighPrice',
              },
              {
                name: '4 à 5 étoiles',
                count: true,
                icon: List,
                link: '/intervention/quality/qualityInterventionHighStars',
              },
            ],
          },
          {
            name: 'Payeur à contacter',
            icon: List,
            childs: [
              {
                name: 'Tous',
                count: true,
                icon: List,
                link: '/intervention/quality/bc',
              },
              {
                name: '1- Société',
                count: true,
                icon: List,
                link: '/intervention/quality/bcSoc',
              },
              {
                name: '2 - Curatelle',
                count: true,
                icon: List,
                link: '/intervention/quality/bcCur',
              },
              {
                name: '3 - Autre',
                count: true,
                icon: List,
                link: '/intervention/quality/bcAutre',
              },
              {
                name: '4 - Agence immobilière',
                count: true,
                icon: List,
                link: '/intervention/quality/bcAgim',
              },
              {
                name: '5 - Propriétaire',
                count: true,
                icon: List,
                link: '/intervention/quality/bcProp',
              },
            ],
          },
          {
            name: 'Notées',
            icon: List,
            count: true,
            link: '/intervention/quality/n',
          },
          {
            name: 'Pas de notation',
            count: true,
            icon: List,
            link: '/intervention/quality/inr',
          },
          {
            name: 'Mes notations',
            count: true,
            icon: List,
            link: (e) =>
              '/intervention/quality/w/' +
              `notation-client-userId=${e.login}=${e._id}`,
          },
        ],
      },
      {
        name: 'Aide d\'etat / financement',
        icon: AttachMoney,
        childs: [
          {
            name: 'Crédit d’impôt et aides',
            icon: Business,
            childs: [
              {
                name: 'Non pris',
                count: true,
                icon: List,
                link: '/intervention/list/cald',
              },
              {
                name: 'Prise en charge caldeo',
                icon: List,
                childs: [
                  {
                    name: 'Tout',
                    count: true,
                    icon: List,
                    link: '/intervention/aide/caldPris',
                  },
                  ...dataC
                    .filter((e) => e._id !== CC_CLOT)
                    .map((e) => ({
                      name: e.listTitle,
                      count: true,
                      icon: List,
                      link: `/intervention/aide/cald${e.shortName}`,
                    })),
                ],
              },
              {
                name: 'Prise en charge MPR',
                icon: List,
                childs: [
                  {
                    name: 'Tout',
                    count: true,
                    icon: List,
                    link: '/intervention/aide/mprPris',
                  },
                  ...dataM
                    .filter((e) => e._id !== CM_CLOT)
                    .map((e) => ({
                      name: e.listTitle,
                      count: true,
                      icon: List,
                      link: `/intervention/aide/cald${e.shortName}`,
                    })),
                ],
              },
              {
                name: 'Clôturés',
                icon: List,
                childs: [
                  ...dataC
                    .filter((e) => e._id === CC_CLOT)
                    .map((e) => ({
                      name: e.listTitle,
                      count: true,
                      icon: List,
                      link: `/intervention/aide/cald${e.shortName}`,
                    }))
                    .concat(
                      dataM
                        .filter((e) => e._id === CM_CLOT)
                        .map((e) => ({
                          name: e.listTitle,
                          count: true,
                          icon: List,
                          link: `/intervention/aide/cald${e.shortName}`,
                        }))
                    ),
                ],
              },
              {
                name: 'Solde caldeo',
                count: true,
                icon: List,
                link: '/intervention/aide/sCald',
              },
            ],
          },
          {
            name: 'Domofinance',
            icon: Business,
            childs: [
              {
                name: 'Tout',
                count: true,
                icon: List,
                link: '/intervention/domo/allDomo',
              },
              {
                name: 'Non payes',
                count: true,
                icon: List,
                link: '/intervention/domo/domoNPaid',
              },
              {
                name: 'Payes',
                count: true,
                icon: List,
                link: '/intervention/domo/domoPaid',
              },
            ],
          },
        ],
      },
      {
        name: 'SAV',
        icon: List,
        childs: savIntervention,
      },
    ],
  },
  {
    name: 'Recouvrement Artisan',
    icon: Person,
    count: true,
    link: '/intervention/recouvrement/recouvrementArtisan',
  },
  {
    name: 'Rib à valider (clients)',
    count: true,
    icon: List,
    link: '/intervention/list/ribClientVal',
  },
  {
    name: 'Liste de chèque sans os',
    icon: List,
    link: '/compta/listRglmtNoOs',
  },
  {
    name: 'Utilisateurs',
    icon: Person,
    link: '/config/users/service',
  },
];
