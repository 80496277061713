import React from 'react';
import {
  Box,
  Divider,
  Paper,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ContactContainer from './ContactContainer';
import ConversationContainer from './ConversationContainer';
import SMSTypeList from './SMSTypeList';

const useStyles = makeStyles()((theme) => ({
  paper: {
    height: '100%',
    display: 'flex',
  },
  chatContainer: {
    height: 'calc(100% - 56px)',
    display: 'flex'
  },
  listM: {
    height: '100%',
    paddingBottom: 8,
  },
  chatGrid: {
    height: '100%',
    flex: 1,
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[50]
  },
}));

const Content = ({
  setSmsType, smsType, notif, user,
  lastMessages, setLastMessages, selected, setSelected,
  loading, setUser, loadMore
}) => {
  const { classes } = useStyles();
  return <Box component={Paper} className={classes.paper}>
    <SMSTypeList
      setSmsType={setSmsType}
      smsType={smsType}
      notif={notif}
      user={user}
    />
    <Box sx={{ width: '100%' }}>
      <Box>
        <Typography
          variant='h5'
          fontWieght='bold'
          sx={{ m: 1.5 }}
          children="Messages"
        />
      </Box>
      <Divider />
      <Box className={classes.chatContainer}>
        <Box item xs={4} className={classes.listM}>
          <ContactContainer
            lastMessages={lastMessages[smsType]}
            selected={selected}
            setSelected={setSelected}
            classes={classes}
            loading={loading[smsType]}
            user={user}
            setSmsType={setSmsType}
            smsType={smsType}
            setUser={setUser}
            loadMore={loadMore}
          />
        </Box>
        <Box item xs={8} className={classes.chatGrid}>
          <ConversationContainer
            smsType={smsType}
            selected={selected}
            setSelected={setSelected}
            setLastMessages={setLastMessages}
          />
        </Box>
      </Box>
    </Box>
  </Box>;
};

export default Content;
