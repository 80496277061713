import { connect } from 'react-redux';
import { compose } from 'redux';
import { withAPISubscription } from '../../../hoc';
import { getSimpleSignalements } from '../../../actions/signalements';
import { R_NO, D_REF } from '@lba-dev/package.local-globals/recStatus';
import { getSpecificKeys } from '../../Utils/manageData';
import { formatHistory } from '../../Utils/FormatHistory';
import { getClientNumbers } from '../../../utils/call';
import { getCalls } from '../../../actions/inter';
import { permit } from '@lba-dev/package.local-globals/restrictions';

const mapStateToProps = (
  { users },
  {
    elem,
    signalements = [],
    recouvrement,
  }
) =>
  formatHistory({
    users,
    elem,
    recouvrement,
    signalements,
  });

const fetch = (api, { elem, name = '', user }) => {
  const {
    appelsArtisan
  } = getSpecificKeys({ name, id: elem.id, demandeId: elem?.demande?.id });
  const promies = [
    {
      name: 'signalements',
      promise:
        !isNaN(elem.id) ? () =>
          getSimpleSignalements({ 'intervention.id': elem.id }).then((e) => ({
            data: e.body().map((e) => e.data()),
            name: 'signalements',
          })) : null,
    },
    {
      name: 'appels',
      promise: () => api.appels
        .getAll({
          query: JSON.stringify({
            ...appelsArtisan,
            companyName: { $exists: true },
          }),
          sort: { date: -1 }
        })
        .then((e) => ({
          data: e.body().map((e) => e.data()),
          name: 'appels',
        })),
    },
    permit(user, { key: 'accesExterne' }) && {
      name: 'records',
      promise: () => getCalls({
        numbers: getClientNumbers(elem),
        user,
        display: 100
      })
        .then((data) => ({
          data,
          name: 'records',
        })),
    },
    name !== 'savInterventions' && {
      name: 'recouvrement',
      promise:
        ![R_NO, D_REF].includes(elem.recovery.status) &&
        !isNaN(elem.id) ?
          () => api.recouvrements
            .getAll({
              query: JSON.stringify({
                id: elem.id,
              })
            })
            .then(e => e.body().map((e) => e.data()))
            .then((e) => ({
              data: Array.isArray(e) ? e[0] : null,
              name: 'recouvrement',
            })) : null,
    },
    {
      name: 'sms',
      promise: () => api.sms
        .getAll({
          query: JSON.stringify({
            'intervention': elem._id,
            'artisan': { '$exists': false },
            'prosp': { '$exists': false }
          }),
          sort: { date: -1 }
        })
        .then((e) => ({
          data: e.body().map((e) => e.data()),
          name: 'sms',
        })),
    },
  ];
  return Promise.all(
    promies.reduce(
      (acc, curr) => (
        typeof curr.promise === 'function' ? acc.concat(curr.promise()) : acc),
      []
    )
  ).then((results) =>
    results.reduce(
      (v, res) => ({
        ...v,
        [res.name]: res.data,
      }),
      {}
    )
  );
};

const shouldReload = (props, prevProps) => (props.elem.id !== prevProps.elem.id)
  || (props.name !== prevProps.name);

export default compose(
  withAPISubscription(fetch, null, {
    default: {
      signalements: [],
      appels: [],
      records: [],
      recouvrement: null,
    },
    deverse: true,
    shouldReload,
  }),
  connect(mapStateToProps)
);
