import React from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../../../actions/dialog';
import Content from './Content';

const setPaymentState = setDialogContentProp('state');
const setLoadingPayment = setDialogContentProp('setLoadingPayment');
const initStateAgain = setDialogContentProp('initState');
const setPass = setDialogContentProp('setPass');

const mapStateToProps = ({
  dialog: {
    contentProps
  },
  userId, users
}) => ({
  ...contentProps,
  user: users.find((u) => u._id === userId),
  users,
});

const mapDispatchToProps = {
  setPaymentState,
  initStateAgain,
  setLoadingPayment,
  setPass,
};

const DialogPaymentCB = (props) =>
  <Content {...props} />;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogPaymentCB);
