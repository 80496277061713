import { Add } from '@mui/icons-material';
import {
  List, ListItem, ListItemIcon, ListItemText, Popover
} from '@mui/material';
import React from 'react';

const AddPopover = ({ anchorEl, setAnchorEl, navigate, tels }) =>
  <Popover
    id="artisanTels"
    open={!!anchorEl}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    sx={{ left: 20 }}
    onClose={() => {
      setAnchorEl(null);
    }}
  >
    <List>
      {['Devis', 'Intervention'].map((type, i) =>
        <ListItem key={i} onClick={() => {
          navigate(`/${type.toLowerCase()}`, {
            state: {
              ...((tels || [])[0]?.tel ? {
                state: {
                  chatClient: {
                    client: { tel1: tels[0].tel },
                  }
                }
              } : {}),
            }
          });
          setAnchorEl(null);
        }} button dense>
          <ListItemIcon style={{ minWidth: 38 }}>
            <Add />
          </ListItemIcon>
          <ListItemText primary={type} />
        </ListItem>)}
    </List>
  </Popover>;

export default AddPopover;
