import {
  EQUIPES_FETCH
} from '../constants/ActionTypes';

const initialState = [];
export default (state = initialState, action) => {
  switch (action.type) {
    case EQUIPES_FETCH:
      return action.equipes;
    default:
      return state;
  }
};
