import {
  USER_ID_FETCH
} from '../constants/ActionTypes';

export function loadUserId(userId) {
  return {
    type: USER_ID_FETCH,
    userId
  };
}
