import React from 'react';
import {
  moment,
  formatDate,
} from '@lba-dev/package.local-globals/moment';
import { connect } from 'react-redux';

import TextField from '@mui/material/TextField';

import { setDialogContentProp } from '../../actions/dialog';

const setDate = setDialogContentProp('date');
const setCommentaire = setDialogContentProp('commentaire')

const mapStateToProps = ({
  dialog: {
    contentProps: {
      date = new Date(),
      commentaire = '',
      withComment
    },
  },
  userId
}) => ({
  userId,
  date,
  commentaire,
  withComment
});


const mapDispatchToProps = {
  setDate: setDate,
  setCommentaire: setCommentaire
};

const ReminderDialog = ({
  date,
  setDate,
  commentaire,
  setCommentaire,
  withComment
}) => (
  <>
    <TextField
      variant="standard"
      id="datetime-local"
      label=""
      value={formatDate(date, moment.HTML5_FMT.DATETIME_LOCAL)}
      type="datetime-local"
      InputLabelProps={{ shrink: true }}
      onChange={(e) => setDate(e.target.value)} />
    <br/>
    <br/>

    {withComment ?
      <TextField
        variant="standard"
        id="commentaire"
        label="Commentaire"
        value={commentaire}
        type="text"
        InputLabelProps={{ shrink: true }}
        onChange={(e) => setCommentaire(e.target.value)} /> :
      null}
  </>
);

export default connect(mapStateToProps, mapDispatchToProps)(ReminderDialog);
