import React, { useEffect, useState } from 'react';
import { withStyles } from 'tss-react/mui';
import Tooltip from '@mui/material/Tooltip';

const styles = {
  progressVisualFull: {
    display: 'flex',
    height: 20,
    margin: '20px 0',
  },
  progressVisualPart: {
    transition: 'width 2s',
  },
  divTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '105%'
  }
};

const ProgressLine = ({
  backgroundColor = '#e5e5e5',
  visualParts = [
    {
      percentage: '0%',
      color: 'white',
    },
  ],
  classes,
}) => {
  const [widths, setWidths] = useState(visualParts.map(() => 0));

  useEffect(() => {
    requestAnimationFrame(() => {
      setWidths(visualParts.map((item) => item.percentage));
    });
  }, [visualParts]);

  return (
    <>
      <Tooltip disableInteractive
        title={visualParts.map((e, i) => (
          <div key={i} className={classes.divTitle}>
            <div
              style={{
                width: 7,
                height: 7,
                backgroundColor: e.color,
                margin: '1%',
              }}
            />
            {e.title}
          </div>
        ))}
      >
        <div
          className={classes.progressVisualFull}
          style={{
            backgroundColor,
          }}
        >
          {visualParts.map((item, index) => (
            <div
              key={index}
              style={{
                width: `${widths[index]}%`,
                backgroundColor: item.color,
              }}
              className={classes.progressVisualPart}
            />
          ))}
        </div>
      </Tooltip>
    </>
  );
};

export default withStyles(ProgressLine, styles);
