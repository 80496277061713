import api from '../api';

export const uploadFile = ({
  type,
  fileName,
  file,
  id,
  underscoreId,
  path,
  categories
}) =>
  api.dropbox
    .custom('upload')
    .post({
      type,
      fileName,
      file,
      baseName: `${type}-${id}`,
      underscoreId,
      path,
      categories
    });
