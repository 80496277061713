import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { pourcent } from '@lba-dev/package.local-globals/AdvPaymentConstants';
import { Map } from 'immutable';
import { withStyles } from 'tss-react/mui';
import Collapse from '@mui/material/Collapse';

const styles = {
  card: {
    overflow: 'auto',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-around',
  },
};

const Content = ({
  advancePayment,
  setPourcent,
  setMail,
  classes,
}) => (
  <Card elevation={0} className={classes.card}>
    <CardContent>
      <div>
        <div className={classes.box}>
          <Select
            value={advancePayment.get('pourcent', 0) || 0}
            onChange={e =>
              setPourcent(['advPayment', 'pourcent'], Number(e.target.value))
            }
          >
            {pourcent.map((value, index) => (
              <MenuItem key={index} value={index}>
                {value}%
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </CardContent>
    {
      <Collapse timeout={600}
        in={advancePayment.get('pourcent', 0) !== 0}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                id="textarea"
                multiline
                value={advancePayment.get('mail', '').replace(/<br>/g, '\n')}
                onContextMenu={e => {
                  e.stopPropagation();
                }}
                InputProps={{
                  style: styles.TextInput,
                }}
                rows={18}
                onChange={e => setMail(e.target.value)}
                label="Corps du mail" />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    }
  </Card>
);


Content.defaultProps = {
  advancePayment: new Map()
};

export default withStyles(Content, styles);
