import {
  LOGIN,
  LOGOUT
} from '../constants/ActionTypes';

const initialState = {};
export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        token: action.token,
        decodedToken: action.decodedToken,
        isRemotely: action.isRemotely,
      };
    case LOGOUT:
      return {};
    default:
      return state;
  }
};
