import React from 'react';
import {
  RESOLU_DATAS,
  T_LIT,
  T_REC,
} from '@lba-dev/package.local-globals/ResoluStatus';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Causes from './Causes';

const Resulo = ({
  recouvrement,
  setData,
  encaissement = 0,
  decaissement = 0,
}) => {
  const conditionMontant = recouvrement.montantEncaisse >=
    Math.trunc((encaissement - decaissement) / 100);
  return (
    <React.Fragment>
      <Grid item xs={6}>
        <TextField
          variant="standard"
          disabled={true}
          type="number"
          label="Montant prise en charge"
          fullWidth
          value={
            recouvrement.priseEnCharge ? recouvrement.priseEnCharge.montant : ''
          }
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="standard"
          type="number"
          label="Montant effectivement encaissé"
          onChange={(e) =>
            setData(
              ['montantEncaisse'],
              e.target.value ? Number(e.target.value) : ''
            )
          }
          fullWidth
          error={conditionMontant}
          value={recouvrement.montantEncaisse}
          helperText={conditionMontant
            ? 'Le montant de résolution ne correspond pas au montant encaissé'
            : ''
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Causes
          fetchData={RESOLU_DATAS.filter((e) =>
            e.type.includes(recouvrement.isLitige ? T_REC : T_LIT)
          )}
          isPris={recouvrement.isPris}
          handleChange={(e) =>
            setData(['resuloStatus'], Number(e.target.value))}
          value={recouvrement.resuloStatus}
        />
      </Grid>
    </React.Fragment>
  );
};

export default Resulo;
