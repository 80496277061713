import React from 'react';
import Chip from '@mui/material/Chip';
import { Done } from '@mui/icons-material';
import CardContent from '@mui/material/CardContent';

const Chips = ({ data, color }) =>
  <CardContent>{data.map((value, index) =>
    <Chip style={{ marginTop: 5, marginRight: 5 }}
      key={index}
      label={value}
      clickable
      color={color}
      deleteIcon={<Done />}
    />)}</CardContent>;

export default (Chips);
