export default {
  button: {
    padding: '4px 6px',
    fontSize: '13px'
  },
  flex: {
    display: 'flex'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
  },
  drawerPaper: {
    position: 'relative',
  },
  verticalAlign: {
    display: 'table',
    height: 60,
    overflow: 'hidden'
  },
  verticalAlignItem: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  root: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  fullWidth: {
    width: '100%'
  },
  title: {
    margin: 10,
    marginLeft: 30
  },
  subTitle: {
    margin: 10,
    marginLeft: 30,
    color: 'grey'
  },
  datesPanel: {
    position: 'absolute',
    right: 0,
    top: 80
  },
  divider: {
    backgroundColor: 'grey',
    height: 20,
    width: 1,
    marginRight: 10,
    marginLeft: 10
  },
  dateSettings: {
    marginRight: 10,
    color: 'grey',
    cursor: 'hand'
  },
  rightMenu: {
    width: 260,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  displayNone: {
    display: 'none'
  },
  loadingDiv: {
    minHeight: '28em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};
