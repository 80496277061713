import {
  Build,
  Assessment,
  PhoneMissed,
  EventNote,
  List
} from '@mui/icons-material';
export default [
  {
    name: 'Utilisateurs',
    icon: Build,
    link: '/config/users',
  },
  {
    name: 'Appels non transformés',
    icon: PhoneMissed,
    link: '/cti/transformCall',
  },
  {
    name: 'Gestion des congés',
    icon: EventNote,
    link: '/users/holidays'
  },
  {
    name: 'Interventions annulés',
    icon: List,
    link: '/intervention/list/ann'
  },
  {
    name: 'Statistique',
    link: '/statistiques/',
    icon: Assessment,
  },
];
