import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import {
  data as chauffageType
} from '@lba-dev/package.local-globals/chauffageType';
import { moment } from '@lba-dev/package.local-globals/moment';

import { permit } from '@lba-dev/package.local-globals/restrictions';
import { C_CHA, C_PAC } from '@lba-dev/package.local-globals/comboType';
import {
  data as ageHouses
} from '@lba-dev/package.local-globals/caldeoAgeHouse';
import {
  data as ownerProfiles
} from '@lba-dev/package.local-globals/caldeoOwnerProfile';

import CustomTextField from '../../CustomInputs/CustomTextField';
import CustomSelect from '../../CustomInputs/CustomSelect';
import MoneyInput from '../../CustomInputs/MoneyInput';
import DatePicker from '../../DatePicker';
import { withMediaQuery } from '../../../hoc';
import CustomCheckbox from '../../CustomInputs/CustomCheckbox';
import { Typography } from '@mui/material';

const helpers = {
  [C_CHA]: {
    cee: '',
    mpr: 'Montant compris entre 650 € et 1 500 €',
  },
  [C_PAC]: {
    cee: '',
    mpr: 'Montant compris entre 1 500 € et 5 000 €',
  },
};

const renderList = t => t.map(v =>
  <MenuItem
    key={v._id}
    value={v._id.toString()}
    children={v.model ? [v.name, v.model].join(' - ') : v.name}
  />);

const items = t => t.map((v, i) =>
  <MenuItem disabled={i === 0} key={v} value={v} children={v} />);

const genAideHelperText = (helps = []) =>
  helps.map((e, i) =>
    <Typography key={i} sx={{ textWrap: 'nowrap' }}
    >{e.title}: {e.amount_info}</Typography>)

const CaldeoData = ({
  setData,
  merge,
  paths,
  errors,
  user,
  isUp,
  simulationHelps = []
}) => {
  const helperObj = helpers[merge.getIn(paths.comboType, C_CHA)] || {};
  const maPrimeHelpsText = simulationHelps.filter(e => e.isAnah)
  const ceeHelpsText = simulationHelps.filter(e => !e.isAnah)
  return <>
    {permit(user, { key: 'caldeoSpecial' } ) ? <>
      <Grid item xs={6}>
        <DatePicker
          before={true}
          label="Date du devis"
          startDate={moment(merge.getIn(paths.devisDate,
            merge.getIn(['date', 'envoi']) ||
          merge.getIn(['date', 'ajout']))).toDate()}
          onChange={(v) => setData(paths.devisDate, v.toDate())}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          before={true}
          label="Date de la facture"
          startDate={moment(merge.getIn(paths.factureDate, moment(
            merge.getIn(paths.devisDate) ||
          merge.getIn(['date', 'envoi']) ||
          merge.getIn(['date', 'ajout'])).add(3, 'days'))).toDate()}
          onChange={(v) => setData(paths.factureDate, v.toDate())}
        />
      </Grid>
    </> : null}
    <CustomSelect
      grid
      xs={12}
      sm={6}
      label='Energie de chauffage avant travaux'
      value={merge.getIn(['combination', 'oldChaudiere', 'type'])}
      path={paths.oldChaudiereType}
      setData={(p, v) => setData(p, +v)}
      children={renderList(chauffageType)}
    />
    <CustomSelect
      grid
      xs={12}
      sm={6}
      label="Vous êtes :"
      requiredText={true}
      value={merge.get('ownerProfile', 0)}
      path={paths.ownerProfile}
      setData={(p, v) => setData(p, +v)}
      error={!!errors.find(({ path, error }) =>
        path.includes(paths.ownerProfile[0]) &&
        error)}
      children={renderList(ownerProfiles)}
    />
    <CustomSelect
      grid
      xs={12}
      sm={6}
      label="Date de la construction du logement à rénover"
      value={merge.get('ageHouse', 0)}
      path={paths.ageHouse}
      setData={(p, v) => setData(p, +v)}
      error={!!errors.find(({ path, error }) =>
        path.includes(paths.ageHouse[0]) &&
        error)}
      children={renderList(ageHouses)}
      requiredText={true}
    />
    <CustomSelect
      grid
      xs={12}
      sm={6}
      label="Type de l'habitat à rénover ?"
      value={merge.get('houseType', '')}
      path={paths.houseType}
      setData={setData}
      error={!!errors.find(({ path, error }) =>
        path.includes(paths.houseType[0]) &&
        error)}
      requiredText={true}
      children={
        items(['Type de logement', 'Maison', 'Copropriété', 'Appartement'])
      }
    />
    <CustomTextField
      grid
      xs={12}
      sm={6}
      label="Nb de personne à charge"
      value={merge.get('dependent', '')}
      path={paths.dependent}
      type="number"
      error={!!errors.find(({ path, error }) =>
        path.includes(paths.dependent[0]) &&
        error)}
      setData={setData}
      requiredText={true}
    />
    <MoneyInput
      grid
      xs={12}
      sm={6}
      label="Revenu fiscal de référence du loyer"
      defaultValue={merge.get('houseTotalRevenues', '')}
      path={paths.houseTotalRevenues}
      type="number"
      error={!!errors.find(({ path, error }) =>
        path.includes(paths.houseTotalRevenues[0]) &&
        error)}
      setData={setData}
      requiredText={true}
    />
    <CustomTextField
      grid
      xs={12}
      sm={6}
      label="Surface totale chauffée: en m²"
      value={merge.get('surface', '')}
      path={paths.surface}
      type="number"
      error={!!errors.find(({ path, error }) =>
        path.includes(paths.surface[0]) &&
        error)}
      setData={setData}
    />
    <CustomTextField
      grid
      xs={12}
      sm={6}
      label="Code Postal"
      value={merge.getIn(['client', 'address', 'zipcode'])}
      path={paths.zipCode}
      disabled
      error={!!errors.find(({ path, error }) =>
        path.includes(paths.zipCode[0]) &&
        error)}
      setData={setData}
    />
    <Grid display={{ xs: 'none', sm: 'block' }} sm={6} />
    <Grid sm={12}>
      <Typography children={'Type de chaudiere pour les aides :'} />
    </Grid>
    <CustomCheckbox
      grid
      xs={12}
      sm={6}
      label={'Pompe à chaleur air/eau'}
      path={paths.pompeAChaleurSimulation}
      setData={setData}
      checked={merge.getIn(paths.pompeAChaleurSimulation)}
      error={!!errors.find(({ path, error }) =>
        path.includes(paths.pompeAChaleurSimulation[0]) &&
        error)}
    />
    <CustomCheckbox
      xs={12}
      sm={6}
      path={paths.chauffeEauThermSimulation}
      label={'Chauffe-eau thermodynamique'}
      setData={setData}
      checked={merge.getIn(paths.chauffeEauThermSimulation)}
      error={!!errors.find(({ path, error }) =>
        path.includes(paths.chauffeEauThermSimulation[0]) &&
      error)}
    />
    <MoneyInput grid xs={12} sm={6}
      label="Montant des CEE"
      defaultValue={merge.get('ceeAmount', null)}
      path={paths.ceeAmount}
      error={!!errors.find(({ path, error }) =>
        path.includes(paths.ceeAmount[0]) &&
        error)}
      helperText={genAideHelperText(ceeHelpsText) || helperObj.cee || ''}
      setData={setData}
      requiredText={true}
    />
    <MoneyInput grid xs={12} sm={6}
      label="Montant Ma Prime rénov"
      defaultValue={merge.get('anahAmount', '')}
      path={paths.anahAmount}
      helperText={genAideHelperText(maPrimeHelpsText) || helperObj.mpr || ''}
      setData={setData}
      requiredText={true}
    />
    {isUp.xs &&
      <Grid item sm={6} children='&nbsp;' />
    }
  </>;
};

export default withMediaQuery()(CaldeoData);
