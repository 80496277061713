import React from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { makeStyles } from 'tss-react/mui';
import { isMaxSizeOk, MAX_AUDIO_SIZE } from '../../utils/function';
import notifSystem from '../../notifSystem';

const useStyles = makeStyles()({
  recordingDiv: {
    borderRadius: 30,
    backgroundColor: 'transparent',
    boxShadow: 'unset',
    maxWidth: 200,
    border: '1px solid #cecece',
    padding: '4px 8px'
  },
  recordingButton: {
    backgroundColor: 'transparent',
    boxShadow: 'unset',
    borderRadius: 20,
  },
  saveButton: {
    display: 'none'
  },
  pauseButton: {
    height: 12,
  },
  discardButton: {
    height: 12,
  },
});

export const blobToBase64 = (blob) => {
  if (!isMaxSizeOk([blob], MAX_AUDIO_SIZE)) {
    notifSystem.error(
      'Erreur',
      'La taille de l\'audio dépasse la limite autorisée'
    );
    return null;
  }
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

const VoiceRecorder = ({ addAudio }) => {
  const { classes } = useStyles();
  const recorderControls = useAudioRecorder();
  const addAudioElement = async (blob) => {
    const data = await blobToBase64(blob);
    addAudio(data, blob);
  };

  return <AudioRecorder
    classes={{
      AudioRecorderClass: recorderControls.isRecording ?
        classes.recordingDiv : classes.recordingButton,
      AudioRecorderStartSaveClass: recorderControls.isRecording ?
        classes.saveButton : '',
      AudioRecorderPauseResumeClass: classes.pauseButton,
      AudioRecorderDiscardClass: classes.discardButton
    }}
    onRecordingComplete={(blob) => addAudioElement(blob)}
    recorderControls={recorderControls}
  />;
};

export default VoiceRecorder;
