import { groupBy } from '@lba-dev/package.local-globals/functions';
import {
  clientToString,
} from './functions';

const getDevisPrice = (devis) => {
  const sum = ((devis.products && devis.products.list &&
    devis.products.list.reduce((p, c) => {
      const t = c.pu || 0;
      return p + (t * c.qt);
    }, 0)) / 100).toFixed(2);
  const tva = (devis.products && devis.products.tva) || 10;
  const tvaPrice = (sum * (tva / 100)).toFixed(2);
  const ttc = (sum * ((tva / 100) + 1)).toFixed(2);
  return ((sum && tva && ttc) ?
    `Montant H.T : ${sum}\n` +
    `TVA (${tva}%) : ${tvaPrice}\n` +
    `Montant TTC : ${ttc}\n` : '');
};

export const generateSmsDevis = (devis, user) => {
  const login = (user && user.alias) || '';
  const tva = (devis.products && devis.products.tva) || 10;
  const num = user && user.ligne.replace(/\+33/, '0').match(/.{2}/g).join('.');
  const groupByProducts = Object.keys(groupBy(
    devis.products.list, 'comboIndex'));
  if (groupByProducts.length > 1) {
    return devis.client && (
      `${clientToString(devis.client)}\n\n` +
    'Voici les plusieurs propositions correspondant ' +
    'à ce que nous avons vu ensemble  :\n\n' +
      `${groupByProducts.map(e =>
        `Devis ${devis.id || '{id}'}-${+e + 1}\n\n${getDevisPrice({
          ...devis,
          products: {
            ...devis.products,
            list: devis.products.list.filter(s => s.comboIndex === +e)
          }
        })}\n/lien/\n\n`).join('')}\n` +
      'Merci de répondre "Bon pour accord" pour accepter le devis' +
    `${tva <= 10
      ? ' et l\'attestation de TVA'
      : ''}.\n\n` +
      `${login} : ${num || ''}\n`);
  }
  return devis.client && (
    `${clientToString(devis.client)}\n\n` +
  'Voici un récapitulatif du devis :\n' +
    `Devis ${devis.id || '{id}'}\n\n` +
    '/lien/\n' +
    'Cliquez sur ce lien pour visualiser le devis.\n\n' +
    `${getDevisPrice(devis)}` +
    'Merci de répondre "Bon pour accord" pour accepter le devis' +
    `${tva <= 10
      ? ' et l\'attestation de TVA'
      : ''}.\n\n` +
    `${login} : ${num || ''}\n`);
};
