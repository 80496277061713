import React from 'react';
import CardContent from '@mui/material/CardContent';
import { AddCircle, AddCircleOutline, Timer } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { S_STATUS } from '../Dialogs/Prosp';
import { connect } from 'react-redux';

const mapStateToProps = ({ confProsp }) => ({
  artisanDispo: confProsp.find((e) => e.get('name') === 'Intéressé'),
});

const DetailActions = ({
  openDialog,
  updateStateP,
  classes,
  setStatus,
  artisanDispo,
}) => (
  <CardContent className={classes.DetailAction}>
    <Button
      className={classes.DetailActionLarge}
      onClick={() =>
        setStatus(
          {
            ref: artisanDispo.get('id'),
          },
          S_STATUS,
          openDialog
        )
      }
      variant="outlined"
    >
      <AddCircle />
      ARTISAN DISPONIBLE
    </Button>
    <Button
      className={classes.DetailActionB}
      onClick={openDialog}
      variant="outlined"
    >
      <Timer />
      Rappel/Absence
    </Button>
    <Button
      className={classes.DetailActionB}
      onClick={() => updateStateP('openComment', true)}
      variant="outlined"
    >
      <AddCircleOutline />
      Commentaire
    </Button>
  </CardContent>
);

export default connect(mapStateToProps)(DetailActions);
