import api from '../api';
import notifSystem from '../notifSystem';

export const getTransactions = (interId) =>
  api.transactions.getAll({
    query: JSON.stringify({
      id: interId,
    })
  }).then(e => e.body().map(s => s.data()));

export const updateTransactions = (newData) => {
  const { _id, ...data } = newData;
  return api.transactions
    .patch(_id, data)
    .catch((e) => notifSystem.error('Erreur', e.message));
};
