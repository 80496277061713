import React from 'react';
import { List } from 'immutable';

import { withStyles } from 'tss-react/mui';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import { Save, Close } from '@mui/icons-material';

const styles = {
  CardHeader: {
    background: 'white',
    borderRadius: '0px !important',
    margin: '0 0 -5 0 !important',
  },
};

const DetailCardHeader = ({ classes, edit, validate, removeTarget, merge }) => (
  <Card className={classes.CardHeader}>
    <CardHeader
      style={{ maxHeight: 100 }}
      action={
        <div>
          <IconButton
            color="secondary"
            disabled={!edit}
            onClick={validate}
            size="large"
          >
            <Save />
          </IconButton>
          <IconButton onClick={removeTarget} size="large">
            <Close />
          </IconButton>
        </div>
      }
      title={`${merge.getIn(['elem', 'obj', 'title'], '').substr(0, 50)}
      ${merge.getIn(['elem', 'obj', 'title'], '').length > 50 ? '[...]' : ''}`}
      subheader={
        merge.getIn(['elem', 'obj', 'noms'], new List()).size > 2
          ? `${merge
            .getIn(['elem', 'obj', 'noms'], new List())
            .slice(0, 2)
            .join(' , ')} [...]`
          : merge.getIn(['elem', 'obj', 'noms'], new List()).join(' , ')
      }
    />
  </Card>
);

export default withStyles(DetailCardHeader, styles);
