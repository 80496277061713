import React, { Component } from 'react';
import classNames from 'classnames';

import { withStyles } from 'tss-react/mui';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Card from '@mui/material/Card';
import { ExpandMore } from '@mui/icons-material';

const styles = theme => ({
  flexBox: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  expand: {
    marginLeft: 10,
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

class CollapsedCard extends Component {
  state = { open: this.props.defaultOpen || false }

  toggleOpen = () => this.setState(({ open }) => ({ open: !open }))

  render() {
    const { open } = this.state;
    const {
      classes,
      action,
      title,
      children,
      ...props
    } = this.props;

    return (
      <Card className={classes.card} {...props}>
        <CardHeader
          titleTypographyProps={{ variant: 'subtitle1', align: 'left' }}
          title={title}
          action={
            <div className={classes.flexBox}>
              {action}
              <IconButton
                className={classNames(classes.expand, {
                  [classes.expandOpen]: open,
                })}
                onClick={this.toggleOpen}
                aria-expanded={open}
                children={<ExpandMore />}
                size="large" />
            </div>
          }
        />
        <Collapse in={open} timeout={500} children={children} />
      </Card>
    );
  }
}

export default withStyles(CollapsedCard, styles);
