import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ContextMenuTrigger } from '@naumandev/react-context-menu';
import ListContextMenu from '../List/ListContextMenu';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import TableCollapse from '../TableCollapse';
import { shallowEqual } from 'react-redux';

const defaultDisplayer = children =>
  <Typography
    align="center"
    variant="caption"
    style={{ color: 'black', fontSize: 14 }}
    children={children}
  />;

const Cell = ({ children, ...props }) =>
  <TableCell {...props}>
    <Typography component="div" align="center" children={children} />
  </TableCell>;

class ListRow extends Component {
  shouldComponentUpdate({ elem, ...nextProps }) {
    const {
      elem: oldElem,
      ...props
    } = this.props;

    return !shallowEqual(elem, oldElem)
      || !shallowEqual(nextProps, props);
  }

  render() {
    const {
      styles,
      actions,
      users,
      user,
      RowContent,
      elem,
      colSpan,
      rowElem,
      keys,
      open,
      displayers,
      handleClickRow,
      reloadCurrent,
      launchRequest,
      setDialog
    } = this.props;
    const row = keys.map(key =>
      <Cell key={key}>
        {(displayers.default || defaultDisplayer)(key in displayers ?
          displayers[key](elem[key], { setDialog }) : elem[key]
        ) || ''}
      </Cell>
    );
    const collapse = !!RowContent && <TableCollapse
      colSpan={colSpan}
      in={open}
      transitionDuration="auto"
      unmountOnExit
    >
      <RowContent
        user={user}
        users={users}
        elem={rowElem || elem}
        reloadCurrent={reloadCurrent}
        launchRequest={launchRequest}
        setDialog={setDialog}
      />
    </TableCollapse>;

    return actions
      ? <React.Fragment>
        <ContextMenuTrigger
          id={elem._id}
          renderTag={props =>
            <TableRow
              {...props}
              className="cellPadding"
              style={styles ? styles({ elem, user }).listRow : {}}
              onClick={() => handleClickRow(elem._id)}
            />}
          holdToDisplay={-1}
          collect={() => ({ elem })}
          children={row}
        />
        {collapse}
        <ListContextMenu
          actions={actions}
          selected={elem}
          reloadCurrent={reloadCurrent}
          launchRequest={launchRequest}
          setDialog={setDialog}
          idMenu={elem._id}
        />
      </React.Fragment>
      : <React.Fragment>
        <TableRow
          className="cellPadding"
          style={styles ? styles({ elem, user }).listRow : {}}
          onClick={() => handleClickRow(elem._id)}
          children={row}
        />
        {collapse}
      </React.Fragment>;
  }
}

ListRow.propTypes = {
  elem: PropTypes.object.isRequired,
  keys: PropTypes.array.isRequired,
  open: PropTypes.bool,
  handlers: PropTypes.object,
  handleClickRow: PropTypes.func,
  displayers: PropTypes.object,
};

ListRow.defaultProps = {
  elem: {},
  keys: [],
  handlers: {},
  displayers: {},
  handleClickRow: f => f
};

export default ListRow;
