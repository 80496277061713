import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Causes from './Causes';
import Typography from '@mui/material/Typography';
import RecouvrementFooter from './RecouvrementFooter';
import {
  SOCIETE,
  AGENCE_IMMO,
  GRAND_COMPTE,
} from '@lba-dev/package.local-globals/statusClient';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { Person, Domain, CheckCircle, HighlightOff } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { CauseData } from '@lba-dev/package.local-globals/lCauses';
import { data } from '@lba-dev/package.local-globals/rCauses';
import notifSystem from '../../../notifSystem';
import PriseEnChargeInput from './PriseEnChargeInput';
import { RT_CLI } from '@lba-dev/package.local-globals/rTypes';


const styles = (theme) => ({
  center: {
    textAlign: 'left',
  },
  chip: {
    marginLeft: theme.spacing(1 / 2),
  },
});
class PriseEnChargeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recouvrement: props.defaultText.recouvrement,
      priseEnCharge: {},
      loading: false,
    };
    this.handleCauseRecouvrement = this.handleCauseRecouvrement.bind(this);
    this.setData = this.setData.bind(this);
    this.cancel = this.cancel.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.setPriseEnCharge = this.setPriseEnCharge.bind(this);
  }

  cancel() {
    if (typeof this.props.callback === 'function') {
      this.props.callback(null);
    }
  }

  onValidate(bool) {
    const { recouvrement } = this.state;
    if (typeof this.props.callback === 'function') {
      if (
        (!recouvrement.cause && recouvrement.type === RT_CLI) ||
        !recouvrement.priseEnCharge.montant) {
        return notifSystem.error(
          'Erreur',
          'Merci de mettre une cause et un montant'
        );
      }
      this.props.callback({
        recouvrement: this.state.recouvrement,
        priseDeContact: bool,
      });
    }
  }

  componentDidMount() {
    const inter = this.props.defaultText;
    let priseEnCharge =
      inter && inter.recouvrement && inter.recouvrement.priseEnCharge
        ? inter.recouvrement.priseEnCharge
        : {
          isSociety:
              inter &&
              ((inter.billing &&
                inter.billing.clientStatus ===
                  (SOCIETE || GRAND_COMPTE || AGENCE_IMMO)) ||
                (inter.client && inter.client.civility === 'Société')),
          isDevisSigned: false,
          montant: (inter.finalPrice / 100).toString(),
        };
    let recouvrement = this.state.recouvrement;
    recouvrement.priseEnCharge = priseEnCharge;
    this.setState({ recouvrement });
  }

  handleCauseRecouvrement(e) {
    let { recouvrement } = this.state;
    recouvrement.cause = Number(e.target.value);
    this.setState({ recouvrement });
  }

  setData(path, value) {
    let { recouvrement } = this.state;
    if (path.includes('montant')) {
      recouvrement.priseEnCharge.montant = value;
    } else {
      recouvrement[path] = value;
    }
    this.setState({ recouvrement });
  }

  setPriseEnCharge(path) {
    let { recouvrement } = this.state;
    recouvrement.priseEnCharge[path] = !recouvrement.priseEnCharge[path];
    this.setState({ recouvrement });
  }

  render() {
    const { open, classes } = this.props;
    const { loading, recouvrement } = this.state;
    const { priseEnCharge = {} } = recouvrement;
    return (
      <Dialog open={open}>
        <DialogTitle>
          <Typography className={classes.center} variant="h6">
            Prise en charge
          </Typography>
        </DialogTitle>
        {recouvrement.type === RT_CLI &&
          <DialogContent>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={3}>
                <Chip
                  avatar={
                    <Avatar>
                      {priseEnCharge.isSociety ? <Domain /> : <Person />}
                    </Avatar>
                  }
                  color="primary"
                  label={priseEnCharge.isSociety ? 'Société' : 'Particulier'}
                  className={classes.chip}
                  onClick={() => this.setPriseEnCharge('isSociety')}
                />
              </Grid>
              <Grid item xs={3}>
                <Chip
                  avatar={
                    <Avatar>
                      {priseEnCharge.isDevisSigned ? (
                        <CheckCircle />
                      ) : (
                        <HighlightOff />
                      )}
                    </Avatar>
                  }
                  color="primary"
                  label={
                    priseEnCharge.isDevisSigned ? 'Devis: Oui' : 'Devis: Non'
                  }
                  className={classes.chip}
                  onClick={() => this.setPriseEnCharge('isDevisSigned')}
                />
              </Grid>
            </Grid>
          </DialogContent>
        }
        <DialogContent>
          {recouvrement.type === RT_CLI && [
            <Button
              key='1'
              variant="outlined"
              color="secondary"
              fullWidth
              className={classes.chip}
            >
              {recouvrement.isLitige
                ? 'Cause du litige'
                : 'Cause du recouvrement'}
            </Button>,
            <Causes
              key='2'
              isPris={recouvrement.isPris}
              fetchData={recouvrement.isLitige ? CauseData : data}
              handleChange={this.handleCauseRecouvrement}
              value={recouvrement.cause}
            />]}
          <Grid container>
            <Grid item xs={12}>
              <PriseEnChargeInput
                classes={classes}
                label={
                  recouvrement.isLitige
                    ? 'Montant du litige'
                    : 'Montant du recouvrement'
                }
                priseEnChargeMontant={priseEnCharge.montant}
                setData={this.setData}
                path={['montant']}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <RecouvrementFooter
          key="footer"
          send={this.onValidate}
          cancel={this.cancel}
          loading={loading}
        />
      </Dialog>
    );
  }
}
PriseEnChargeDialog.defaultProps = {
  validateText: 'Valider',
  cancelText: 'Annuler',
  maxWidth: 'sm',
  fullWidth: false,
};

export default withStyles(PriseEnChargeDialog, styles);
