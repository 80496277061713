import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';
import AudioPlayer from '../../Calls/AudioPlayer';

const styles = {
  line: {
    lineHeight: 'normal',
  },
  a: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '-webkit-link',
  },
  timelineItem: {
    position: 'inherit',
  },
  chip: {
    marginTop: 10,
    marginBottom: 10,
  },
  TimelineOp: {
    maxWidth: '30%',
  },
};

const DemandeMenuInfo = ({
  date,
  user,
  type,
  osId,
  devisId,
  classes,
  obj,
  text
}) => (
  !!date && (
    <TimelineItem className={classes.timelineItem}>
      <TimelineOppositeContent className={classes.TimelineOp}>
        <Typography color="textSecondary">{date}</Typography>
        <Typography color="textSecondary">{user || ''}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="body1" className={classes.lineHeight}>
          {`${text} `}
          {osId && (
            <Link to={`/intervention/${osId}`}>{osId}</Link>
          )}
          {!osId && devisId && (
            <Link to={`/devis/${devisId}`}>{devisId}</Link>
          )}
        </Typography>
        {(type === 'Appel' && obj && (
          <AudioPlayer
            noPadding
            showTranscription={false}
            value={obj.url}
            transcription={obj.transcription}
            processingTextChatgpt={obj.processingTextChatgpt}
            transcriptorEngine={obj.transcriptorEngine}
            processingTextEngine={obj.processingTextEngine}
            processingTextChatgptQuality={obj.processingTextChatgptQuality}
            processingTextEngineQuality={obj.processingTextEngineQuality}
            url={obj.url}
            duration={obj.duration}
          />
        ))}
      </TimelineContent>
    </TimelineItem>
  )
);

export default withStyles(DemandeMenuInfo, styles);
