import React from 'react';

import IconButton from '@mui/material/IconButton';
import {
  Avatar, Collapse, InputAdornment, Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import {
  Close, Send, PictureAsPdf, InsertDriveFile
} from '@mui/icons-material';
import AudioPlayer from '../../../../Calls/AudioPlayer';
import VoiceRecorder from '../../../../Utils/VoiceRecorder';

const AdornmentActions = ({
  file, classes, removeFile, hasWhatsapp,
  audioFile, handleAudioFile, send, value
}) => <InputAdornment>
  {file ? <Box style={{ position: 'relative' }}>
    <Avatar variant='square'
      className={classes.fileAvatar}
      children={
        file.type.includes('pdf') ? <PictureAsPdf /> :
          <InsertDriveFile />}
      src={!file.type.includes('pdf') ?
        `data:${file.type};base64, ${file.data}` : ''}
    />
    <Tooltip placement="top" title='Supprimer'>
      <IconButton size='small'
        onClick={removeFile}
        className={classes.fileDeleteIcon}
      >
        <Close fontSize='small' />
      </IconButton>
    </Tooltip>
  </Box> : ''}
  {hasWhatsapp ? <Collapse
    in={!!audioFile}
    orientation='horizontal'
    style={{ width: audioFile }}
    classes={{
      root: classes.audioBox,
      horizontal: classes.audioBox,
      entered: classes.audioBox,
      hidden: classes.audioBoxHidden
    }}>
    {audioFile && <AudioPlayer
      url={audioFile}
      noCopyIcon
    />}
    <Box display='flex' sx={{ alignItems: 'center' }}>
      <IconButton
        size="small"
        color='secondary'
        onClick={() => handleAudioFile(null)}
        children={<Close />}
      />
    </Box>
  </Collapse> : null}
  {hasWhatsapp && !audioFile ? <VoiceRecorder
    addAudio={(blob) => handleAudioFile(blob)}
  /> : null}
  <IconButton
    color="primary"
    onClick={send}
    disabled={!(audioFile || file || (value || '').trim())}
    size="small"
    variant='filled'
    children={<Send fontSize='small' />}
  />
</InputAdornment>

export default AdornmentActions;
