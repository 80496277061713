import React from 'react';
import Grid from '@mui/material/Grid';
import { setDialogContentProp } from '../../../actions/dialog';
import store from '../../../store';
import CustomTextField from '../../CustomInputs/CustomTextField';

const setPanne = setDialogContentProp('panneComment');

const changePanneComment = (panneComment, value) =>
  store.dispatch(setPanne({ ...panneComment, comment: value }));


const ReasonInput = ({ panneComment = {} }) => (
  <Grid container justifyContent="center" spacing={2} >
    <Grid item xs={8}>
      <CustomTextField
        texttransform="none"
        rows={3}
        label="Commentaire..."
        value={panneComment.comment}
        fullWidth
        setData={(p, v) => changePanneComment(panneComment, v)}
      />
    </Grid>
  </Grid>
);
export default ReasonInput;
