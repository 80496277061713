import React from 'react';
import { connect } from 'react-redux';
import PickCallType from './PickCallType';
import PickDateInter from './PickDateInter';
import ReasonInput from './ReasonInput';
import { DECALAGE, AUTRE } from '@lba-dev/package.local-globals/callTypes';

const mapStateToProps = ({
  dialog: {
    contentProps: { call, step, dateInter, panneComment }
  }
}) => ({ call, step, dateInter, panneComment });

const CallTypesDialog = ({ step, call, dateInter, panneComment }) => (
  <React.Fragment>
    {step === 0 ? <PickCallType call={call} /> : ''}
    {step === DECALAGE ? <PickDateInter date={new Date(dateInter)} /> : ''}
    {step === AUTRE ? <ReasonInput panneComment={panneComment} /> : ''}
  </React.Fragment>
);
export default connect(mapStateToProps)(CallTypesDialog);
