import React, { Component } from 'react';
import { connect } from 'react-redux';
import { call } from '../../utils/call';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import { setDialogContentProp } from '../../actions/dialog';
import { remunerationIcons } from '../../utils/IconsDisplay';
import { withStyles } from 'tss-react/mui';
import CustomSelect from '../CustomInputs/CustomSelect';
import { R_POR, R_FOR } from '@lba-dev/package.local-globals/remStatus';
import { artisanAStatus } from '@lba-dev/package.local-globals/getDataById';

const filters = (remuneration = {}) =>
  [
    {
      name: '30% - 39%',
      filter:
        remuneration.status === R_POR &&
        remuneration.pourcentage.labor >= 30 &&
        remuneration.pourcentage.labor < 40,
    },
    {
      name: '40% - 49%',
      filter:
        remuneration.status === R_POR &&
        remuneration.pourcentage.labor >= 40 &&
        remuneration.pourcentage.labor < 50,
    },
    {
      name: '+50%',
      filter:
        remuneration.status === R_POR &&
        remuneration.pourcentage.labor >= 50,
    },
    {
      name: 'Forfait',
      filter: remuneration.status === R_FOR,
    },
  ];

const styles = {
  switch: { display: 'flex' },
  dialogTitle: {
    padding: '0 0 16px',
    width: '100%'
  },
  switchRight: {
    textAlign: 'right'
  },
};

const setCheckedArtisans = setDialogContentProp('checkedArtisans');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      formatedArtisans,
      checkedArtisans
    },
  },
}) => ({
  formatedArtisans,
  checkedArtisans
});

const mapDispatchToProps = {
  setCheckedArtisans
};

class PropositionArtisans extends Component {
  state = {
    formatedArtisans: this.props.formatedArtisans,
    filterIds: [0, 1, 2, 3],
    checkArtisan: this.props.formatedArtisans
      .map((e) => ({
        dis: e.dis,
        _id: e.obj._id,
        name: e.obj.name,
        firstname: e.obj.firstname,
        companyName: e.obj.companyName,
      })),
  };

  componentDidMount() {
    this.props.setCheckedArtisans(this.state.checkArtisan);
  }

  filterByRemun(value) {
    const { setCheckedArtisans } = this.props;
    const { formatedArtisans } = this.state;
    const filtered = formatedArtisans
      .filter(
        (e) =>
          value.some(s => filters(e.obj.remuneration)[s].filter)
      )
      .map((e) => ({
        dis: e.dis,
        _id: e.obj._id,
        name: e.obj.name,
        firstname: e.obj.firstname,
        companyName: e.obj.companyName,
      }));
    this.setState({ filterIds: value, checkArtisan: filtered }, () =>
      setCheckedArtisans(filtered)
    );
  }

  updateCheckedArtisan = (value) => () => {
    const checkArtisan = [...this.state.checkArtisan]
      .toggleValue(value, (e) => e._id === value._id);
    this.setState({ checkArtisan });
    this.props.setCheckedArtisans(checkArtisan);
  };

  render() {
    const { classes } = this.props;
    const {
      formatedArtisans, checkArtisan, filterIds
    } = this.state;
    return <>
      <DialogTitle className={classes.dialogTitle}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={8}
            children="Proposer l'intervention à ces artisans:"
          />
          <Grid item xs={2} className={classes.switchRight}>
            <CustomSelect
              label="Remuneration"
              value={filterIds}
              path={['']}
              renderValue={() => 'remunerations...'}
              setData={(p, v) => this.filterByRemun(v)}
              multiple
            >
              {filters().map((e, i) => (
                <MenuItem key={i} value={i}>
                  <Checkbox checked={filterIds.includes(i)} />
                  {e.name}
                </MenuItem>
              ))}
            </CustomSelect>
          </Grid>
        </Grid>
      </DialogTitle>
      {formatedArtisans.map((e, i) => (
        <Tooltip disableInteractive title="Appeler" key={i}>
          <Grid
            container
            spacing={4}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={1}>
              <Checkbox
                checked={!!checkArtisan.find(c => c._id === e.obj._id)}
                value={e.obj._id}
                onChange={this.updateCheckedArtisan({
                  dis: e.dis,
                  _id: e.obj._id,
                  name: e.obj.name,
                  firstname: e.obj.firstname,
                  companyName: e.obj.companyName,
                })}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                onClick={() => call(e.obj.tel1)}
                variant="subtitle1"
              >
                {`${e.obj.companyName} (${Math.round(
                  e.dis
                )} km)`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              children={
                <Typography
                  variant="subtitle2"
                  style={{
                    color:
                      artisanAStatus[e.obj.status].color['500'],
                    textTransform: 'capitalize',
                  }}
                  children={artisanAStatus[e.obj.status].name}
                />
              }
            />
            <Grid
              item
              xs={2}
              children={remunerationIcons[
                e.obj.remuneration.status
              ](e.obj.remuneration)}
            />
          </Grid>
        </Tooltip>
      ))}
    </>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(PropositionArtisans, styles)
);
