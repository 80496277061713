import React, { useState } from 'react';
import CustomTextField from '../../CustomInputs/CustomTextField';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import {
  listMailsClient,
  listMailsSST
} from '@lba-dev/package.local-globals/listMails';
import Attachments from '../../Utils/Attachments';
import { makeStyles } from 'tss-react/mui';
import { SAV_ENC } from '@lba-dev/package.local-globals/SAVStatus';
import { getSmsEnvoiSAV } from '../../../actions/priseDeContact';


const useStyles = makeStyles()({
  margin: { marginTop: 20 }
});

const MailComponent = (props) => {
  const {
    updateSAVData,
    mail,
    mailSST,
    savInter,
    artisanSelected,
    date,
    infoDescSAV,
    user
  } = props;
  const { classes } = useStyles();
  const [tab, setTab] = useState([mail.name, mailSST.name].findIndex(e => e));
  const ssTMails = listMailsSST({
    savInter, artisan: artisanSelected, date, savDesc: infoDescSAV.desc, user
  }).filter(e => !e.hide);

  return (
    <Grid container justifyContent="center" align="center" spacing={2}>
      {
        [
          {
            list: listMailsClient(savInter, date, user),
            key: 'mail',
            title: 'Mails Client',
            tab: 0,
          },
          { list: ssTMails, key: 'mailSST', title: 'Mails SST', tab: 1 },
        ].map((l, i) => (
          <Grid key={i} item xs={6}>
            <Typography children={l.title} color="primary" variant="h6" />
            <FormControl component="fieldset">
              <RadioGroup
                value={l.list.findIndex(e => e.name === props[l.key].name)}
                onChange={(_, i) => {
                  const { status, nature, ...mail } = l.list[i];
                  updateSAVData(l.key, mail);
                  setTab(l.tab);
                  if (status) {
                    updateSAVData('status', status);
                    if (status === SAV_ENC) {
                      updateSAVData('sms', getSmsEnvoiSAV(savInter));
                    }
                    if (nature !== undefined) {
                      updateSAVData('nature', nature);
                    }
                  }
                }}
              >
                {l.list.map((e, i) =>
                  <FormControlLabel
                    key={e._id}
                    value={i}
                    label={e.name}
                    control={<Radio />}
                    disabled={e.disabled}
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
        ))
      }
      {mail.name || mailSST.name
        ? <Grid item xs={12}>
          <Paper square>
            <Tabs centered value={tab} onChange={(e, v) => setTab(v)} >
              {mail.name && <Tab
                key="mail_client"
                value={0}
                label="MAIL CLIENT"
              />}
              {mailSST.name && <Tab
                key="mail_sst"
                value={1}
                label="MAIL SST"
              />}
            </Tabs>
          </Paper>
        </Grid>
        : ''}
      {~tab
        ? [
          { ...mail, key: 'mail', attachmentKey: 'attachments' },
          { ...mailSST, key: 'mailSST', attachmentKey: 'attachmentsSST' }
        ].map((e, i) => (
          e.name ?
            <Grid key={i} item xs={12}
              container justifyContent="center" align="center" spacing={2}
            >
              <Grid item xs={12} className={classes.margin}>
                <Button variant="outlined" color="secondary" fullWidth>
                  Mail ({e.name})
                </Button>
              </Grid>
              <CustomTextField className={classes.margin}
                grid xs={12} texttransform='none'
                rows={1}
                label="Destinataire"
                value={e.desMail || ''}
                setData={(p, v) => updateSAVData(e.key, { ...e, desMail: v })}
              />
              <CustomTextField className={classes.margin}
                grid xs={12}
                texttransform='none'
                rows={1}
                label="Objet"
                value={e.subject || ''}
                setData={(p, v) => updateSAVData(e.key, { ...e, subject: v })}
              />
              <CustomTextField
                grid xs={12}
                texttransform='none'
                rows={15}
                label="Corps du Mail"
                value={e.text || ''}
                setData={(p, v) => updateSAVData(e.key, { ...e, text: v })}
              />
              <Grid item xs={12} style={{ 'minHeight': '100px' }}>
                <Attachments
                  data={props[e.attachmentKey]}
                  onAdd={(f, files) => updateSAVData(e.attachmentKey, files)}
                  onDelete={(f, i, files) =>
                    updateSAVData(e.attachmentKey, files)}
                />
              </Grid>
            </Grid>
            : ''
        ))[tab]
        : ''}
    </Grid>
  );
};

export default MailComponent;
