import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from 'tss-react/mui';
import blue from '@mui/material/colors/blue';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Checkbox } from '@mui/material';

const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    padding: theme.spacing(6),
    height: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'grab',
    flexDirection: 'column',
  },
  textLimit: {
    width: '100%',
    color: '#ffffff'
  }
});

const CardItems = ({
  display, name, path, array, classes, setData,
  withCheckbox, isSelected, handleCheckbox
}) =>
  array.map((item, i) => (
    <Grid
      key={i}
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      onClick={() => setData(path, item[name])}
    >
      <Paper
        className={classes.root}
        style={{ backgroundColor: item.color ? item.color[500] : blue[500] }}
        elevation={4}
      >
        <Typography
          className={classes.textLimit}
          variant="h4"
          component="h4"
          children={item[display]}
        />
        {withCheckbox && withCheckbox(item) ?
          <Checkbox
            edge="end"
            checked={isSelected(item)}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleCheckbox(item);
            }}
            disableRipple
          /> : ''}
      </Paper>
    </Grid>
  ));

CardItems.propTypes = {
  setData: PropTypes.func.isRequired,
  display: PropTypes.string.isRequired,
  path: PropTypes.string,
  array: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(CardItems, styles);
