import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../../actions/dialog';
import SavInfo from './SavInfo';
import MailComponent from './MailComponent';
import InfoSAV from '../../InfoIntervention/InfoSAV';
import store from '../../../store';
import { fromJS } from 'immutable';
import PickArtisan from './PickArtisan';
import api from '../../../api';
import Typography from '@mui/material/Typography';
import { steps } from '../../../actions/priseDeContact';
import ChangeSSTMail from './ChangeSSTMail';
import { compose } from 'redux';
import { withMediaQuery } from '../../../hoc';
const mapStateToProps = ({
  users,
  userId,
  dialog: {
    contentProps: {
      savInter,
      nature,
      catSAV,
      status,
      comment,
      stepper,
      mail,
      mailSST,
      infoDescSAV,
      changementSSTMail,
      date,
      attachments,
      attachmentsSST,
    }
  },
}) => ({
  user: users.find(u => u._id === userId),
  savInter,
  nature,
  status,
  catSAV,
  comment,
  stepper,
  mail,
  mailSST,
  infoDescSAV,
  date,
  attachments,
  attachmentsSST,
  changementSSTMail
});
class PriseDeContactDialogSAV extends PureComponent {
  state = {
    artisanSelected: {}
  }

  getArtisan = (artisan) => api.artisans
    .get(artisan)
    .then(res => this.setState(
      { artisanSelected: res.body().data() },
      () => this.updateSAVData('artisanSelected', res.body().data())
    ))

  componentDidMount() {
    const { savInter } = this.props;
    this.getArtisan(savInter.artisan);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.savInter.artisan !== this.props.savInter.artisan) {
      this.getArtisan(this.props.savInter.artisan);
    }
  }

  updateSAVData = (p, v) =>
    !Array.isArray(p)
      ? store.dispatch(setDialogContentProp(p)(v))
      : store.dispatch(setDialogContentProp(p[0])({
        ...this.props[p[0]],
        [p[1]]: v
      }));

  render() {
    const {
      savInter,
      nature,
      catSAV,
      comment,
      status,
      stepper,
      mail,
      mailSST,
      changementSSTMail,
      user,
      infoDescSAV,
      date,
      attachments,
      attachmentsSST,
      isUp
    } = this.props;

    return <React.Fragment>
      {stepper === steps.INFO &&
        <SavInfo
          date={date.savDate}
          user={user}
          nature={nature}
          catSAV={catSAV}
          comment={comment}
          status={status}
          savInter={savInter}
          updateSAVData={this.updateSAVData}
        />}
      {stepper === steps.MAIL &&
        <MailComponent
          mail={mail}
          mailSST={mailSST}
          updateSAVData={this.updateSAVData}
          artisanSelected={this.state.artisanSelected}
          savInter={savInter}
          infoDescSAV={infoDescSAV}
          date={date.savDate}
          attachments={attachments}
          attachmentsSST={attachmentsSST}
          user={user}
        />}
      {stepper === steps.RDV &&
        <InfoSAV
          isSAV
          setData={this.updateSAVData}
          infoDesc={fromJS(infoDescSAV)}
          paymentMethod={infoDescSAV.paymentMethod}
          user={user}
          date={date.savDate}
          isUp={isUp}
        />}
      {stepper === steps.SST &&
        <PickArtisan
          savInter={savInter}
          setData={this.updateSAVData}
        />}
      {stepper === steps.DEM &&
        <Typography variant="subtitle2">
        Etes vous sur de vouloir envoyer cette intervention SAV dans le market ?
        </Typography>
      }
      {stepper === steps.CSSTMAIL &&
        <ChangeSSTMail
          changementSSTMail={changementSSTMail}
          savInter={savInter}
          date={date.savDate}
          artisan={this.state.artisanSelected}
          updateSAVData={this.updateSAVData}
          user={user}
        />
      }
    </React.Fragment>;
  }

}

export default compose(
  connect(mapStateToProps),
  withMediaQuery()
)(PriseDeContactDialogSAV);
