import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from 'react-redux';
import { closeDialog } from '../../actions/dialog';
import { withStyles } from 'tss-react/mui';

const mapStateToProps = ({
  dialog: {
    contentProps: {
      callback
    },
  },
}) => ({
  callback
});

const mapDispatchToProps = {
  closeDialog: closeDialog
};

const mergeProps = (state, dispatchers, ownProps) => ({
  ...state,
  ...dispatchers,
  ...ownProps,
  callback: () => {
    state.callback(dispatchers.closeDialog);
  }
});

const styles = {
  container: {
    textAlign: 'center'
  }
};

const LoadingDialog = ({ classes }) =>
  <div className={classes.container}>
    <CircularProgress size={40} />
  </div>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(LoadingDialog, styles));
