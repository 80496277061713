import React from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../../actions/dialog';
import Generate2FA from './generate2FA';
import Validate2FA from './validate2FA';

const setCode = setDialogContentProp('authCode');

const mapDispatchToProps = { setCode };

const mapStateToProps = ({
  dialog: {
    contentProps: { userId, step, authCode, error },
    actions,
  },
}) => ({ actions, userId, step, authCode, error });

const TwoFactorAuthDialog = ({
  userId,
  step,
  authCode,
  error,
  setCode,
  actions,
}) => (
  <React.Fragment>
    {!step
      ? <Generate2FA userId={userId} />
      : <Validate2FA
        authCode={authCode}
        setCode={setCode}
        error={error}
        actions={actions}
      />
    }
  </React.Fragment>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoFactorAuthDialog);
