import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Map } from 'immutable';
import { withStyles } from 'tss-react/mui';
import Collapse from '@mui/material/Collapse';

const styles = {
  card: {
    overflow: 'auto',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-around',
  },
};
const getTtc = (finalPrice, tva) => Math.trunc(
  (finalPrice / 100) * (1 + (tva / 100)) * (60 / 100)
);

const Content = ({
  advancePayment,
  setPourcent,
  setMail,
  classes,
  finalPrice,
  tva
}) => (
  <Card elevation={0} className={classes.card}>
    <CardContent>
      <div className={classes.box}>
        <TextField
          variant="standard"
          error={advancePayment.get('montant') > getTtc(finalPrice, tva)}
          helperText={
            advancePayment.get('montant') > getTtc(finalPrice, tva) &&
                'Le montant dois être inférieur ou égale à 60%'
          }
          id="advancePayment"
          value={advancePayment.get('montant', 0)}
          onContextMenu={e => {
            e.stopPropagation();
          }}
          style={{ marginLeft: 5 }}
          onChange={e =>
            setPourcent(['advPayment', 'montant'], Number(e.target.value))
          }
          label="Accompte" />
      </div>
    </CardContent>
    {
      <Collapse timeout={600}
        in={advancePayment.get('montant', 0) !== 0}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                id="textarea"
                multiline
                value={advancePayment.get('mail', '').replace(/<br>/g, '\n')}
                onContextMenu={e => {
                  e.stopPropagation();
                }}
                InputProps={{
                  style: styles.TextInput,
                }}
                rows={18}
                onChange={e => setMail(e.target.value)}
                label="Corps du mail" />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    }
  </Card>
);


Content.defaultProps = {
  advancePayment: new Map()
};

export default withStyles(Content, styles);
