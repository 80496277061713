import {
  EQUIPES_FETCH
} from '../constants/ActionTypes';

export function loadEquipes(equipes) {
  return {
    type: EQUIPES_FETCH,
    equipes
  };
}
