import Immutable from 'immutable';

import { Description, Grade } from '@mui/icons-material';

import {
  R_NO,
  D_REF,
} from '@lba-dev/package.local-globals/recStatus';
import { S_JUR, S_SATCL } from '@lba-dev/package.local-globals/services';
import { permit } from '@lba-dev/package.local-globals/restrictions';

import {
  priseEnCharge,
  priseDeContact,
  recouvrementStatus,
  deletePriseDeContact,
  middlewareRecouvrement,
} from '../../../actions/recouvrement';

export default [
  {
    name: 'Prise en charge client',
    icon: Description,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        Immutable.List.isList(props.users) &&
        props.selected &&
        (user.service === S_JUR || user.service === S_SATCL) &&
        !props.selected.login.recouvrement
      );
    },
    action: (...arg) => middlewareRecouvrement(...arg, priseEnCharge),
  },
  {
    name: 'Reprise en charge',
    icon: Description,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        Immutable.List.isList(props.users) &&
        props.selected &&
        permit(user, { key: 'recOnly' }) &&
        ![R_NO].includes(props.selected.recovery.status) &&
        props.selected.login.recouvrement
      );
    },
    action: (...arg) => middlewareRecouvrement(...arg, priseEnCharge),
  },
  {
    name: 'Prise de contact',
    icon: Description,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        Immutable.List.isList(props.users) &&
        props.selected &&
        permit(user, { key: 'menuPriseDeContact' }) &&
        props.selected.login.recouvrement
      );
    },
    action: (...arg) => middlewareRecouvrement(...arg, priseDeContact),
  },
  {
    name: 'Status recouvrement',
    icon: Grade,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        Immutable.List.isList(props.users) &&
        props.selected &&
        permit(user, { key: 'menuStatusRecouvrement' }) &&
        (props.selected.login.recouvrement ||
          props.selected.recovery.status === D_REF)
      );
    },
    action: (...arg) => middlewareRecouvrement(...arg, recouvrementStatus),
  },
  {
    name: 'Enlever de recouvrement/litige',
    icon: Description,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        Immutable.List.isList(props.users) &&
        props.selected &&
        permit(user, { key: 'enleverDeRecouvrment' }) &&
        props.selected.recovery.status !== R_NO
      );
    },
    action: (...arg) => middlewareRecouvrement(...arg, deletePriseDeContact),
  },
];
