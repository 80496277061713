import { I_VRF } from '@lba-dev/package.local-globals/iStatus';
import api from '../api';

import {
  GRAND_COMPTE_FETCH,
  GRAND_COMPTE_UPDATE
} from '../constants/ActionTypes';
import notifSystem from '../notifSystem';
import DbWsAction from './models/DbWsAction';

const action = new DbWsAction(
  GRAND_COMPTE_FETCH,
  GRAND_COMPTE_UPDATE
);

export const fetch = action.fetch;
export const update = action.update;

export const getGCBillList = (apiSubscription, filter) =>
  apiSubscription.interventions
    .getAll({
      query: JSON.stringify({
        status: I_VRF,
        ...filter,
      })
    });

export const sendForgetPass = (username) =>
  api.grandCompte
    .custom('forgetPassword')
    .post({ username })
    .catch((e) => notifSystem.error('Erreur system', (e.message || e)));
