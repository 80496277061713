import {
  USERS_FETCH,
  USER_UPDATE,
} from '../constants/ActionTypes';
import { List } from 'immutable';

const initialState = [];
export default (state = initialState, action) => {
  switch (action.type) {
    case USERS_FETCH:
      return new List(action.users);
    case USER_UPDATE:
      return state.map(u =>
        u._id === action.payload._id
          ? action.payload
          : u
      );
    default:
      return state;
  }
};
