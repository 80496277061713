import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import Button from '@mui/material/Button';

import MinCustomDialog from './MinCustomDialog';
import yellow from '@mui/material/colors/yellow';
import { Star } from '@mui/icons-material';

const mapStateToProps = state => ({
  userId: state.userId
});

class RatingDialog extends PureComponent {
  state = {
    rating: 0
  };

  sendRating = () => {
    this.props.callback(this.state.rating);
  }

  changeRating = (i) => this.setState({ rating: i + 1 });


  render() {
    const { rating } = this.state;
    const { open, ratingNumber, defaultText } = this.props;
    return (
      <MinCustomDialog
        open={open}
        title={defaultText.title}
        actions={[
          <Button key={2} color="primary" onClick={this.sendRating}>
            Valider
          </Button>
        ]}
        middle
      >
        <div style={{ textAlign: 'center' }}>
          {[...Array(ratingNumber)].map((e, i) => (
            <Star key={i} onClick={() => this.changeRating(i)}
              style={{ color: yellow[rating > i ? 600 : 0], fontSize: 40 }} />
          ))}
        </div>
      </MinCustomDialog>
    );
  }
}

RatingDialog.propTypes = {
  callback: PropTypes.func.isRequired,
  defaultText: PropTypes.object,
  open: PropTypes.bool,
  ratingNumber: PropTypes.number
};


RatingDialog.defaultProps = {
  callback: e => e,
  defaultText: {
    title: 'Rating title'
  },
  ratingNumber: 3,
  open: true
};


export default connect(mapStateToProps)(RatingDialog);
