import {
  ADMIN,
  NOT_CONFIRMED,
  SENIOR,
  HEADCHIEF,
  CHIEF,
} from '@lba-dev/package.local-globals/permissions';
import { S_RH } from '@lba-dev/package.local-globals/services';
import componentLoader from '../../componentLoader';

const SuppliesInvoicesManager = componentLoader(() =>
  import(
    /* webpackChunkName: "SuppliesInvoices" */
    '../../views/SuppliesInvoices'
  )
);

const GrandCompteListManager = componentLoader(() =>
  import(
    /* webpackChunkName: "GrandCompteListManager" */
    '../../views/GrandCompte'
  )
);

const SmsManagement = componentLoader(() =>
  import(
    /* webpackChunkName: "SmsManagement" */
    '../../components/Sms/management'
  )
);

const IpManager = componentLoader(() =>
  import(
    /* webpackChunkName: "IpManager" */
    '../../components/TableConfigs/IpManager'
  )
);

const FournisseursManager = componentLoader(() =>
  import(
    /* webpackChunkName: "FournisseursManager" */
    '../../components/TableConfigs/FournisseursManager'
  )
);


const CallNotTransform = componentLoader(() =>
  import(
    /* webpackChunkName: "CallNotTransform" */
    '../../components/TableConfigs/CallNotTransform'
  )
);

const CommissionsGlobals = componentLoader(() =>
  import(
    /* webpackChunkName: "CommissionsGlobals" */
    '../../views/Commissions/Globals'
  )
);

const StatsList = componentLoader(() =>
  import(
    /* webpackChunkName: "StatsList" */
    '../../components/Stats/common/stats/StatsList'
  )
);

const UtilsSms = componentLoader(() =>
  import(
    /* webpackChunkName: "UtilsSms" */
    '../../views/UtilsSms')
);

const ConfigTables = componentLoader(() =>
  import(
    /* webpackChunkName: "ConfigTables" */
    '../../views/ConfigTables'
  )
);

const ConfigUsers = componentLoader(() =>
  import(
    /* webpackChunkName: "ConfigUsers" */
    '../../views/ConfigUsers'
  )
);

const ConfigCombos = componentLoader(() =>
  import(
    /* webpackChunkName: "ConfigCombos" */
    '../../views/ConfigCombos'
  )
);

const ConfigMetiersSteps = componentLoader(() =>
  import(
    /* webpackChunkName: "ConfigMetiersSteps" */
    '../../views/ConfigMetiersSteps'
  )
);


const ConfigMetiersDevis = componentLoader(() =>
  import(
    /* webpackChunkName: "ConfigMetiersDevis" */
    '../../views/ConfigMetiersDevis'
  )
);


const SmsCampagne = componentLoader(() =>
  import(
    /* webpackChunkName: "SmsCampagne" */
    '../../components/Sms/SmsCampagne/index'
  )
);

const ConfigGeneral = componentLoader(() =>
  import(
    /* webpackChunkName: "ConfigGeneral" */
    '../../views/ConfigGeneral'
  )
);

const FournisseursCSV = componentLoader(() =>
  import(
    /* webpackChunkName: "FournisseursCSV" */
    '../../views/FournisseursCSV'
  )
);


const UsersService = componentLoader(() =>
  import(
    /* webpackChunkName: "UsersService" */
    '../../views/UsersService'
  )
);

const UpdateLignes = componentLoader(() =>
  import(
    /* webpackChunkName: "UpdateLignes" */
    '../../views/UpdateLignes/index'
  )
);

const Mms = componentLoader(() => import(
  /* webpackChunkName: "Mms" */
  '../../views/Mms'
)
);

const PromptConfig = componentLoader(() => import(
  '../../components/Prompt'
)
);

const BlackListSource = componentLoader(() => import(
  '../../views/BlacklistSource'
)
);


export default [
  {
    path: '/suppliesInvoice',
    component: SuppliesInvoicesManager,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/config/grandCompte',
    component: GrandCompteListManager,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/config/table/:type',
    component: ConfigTables,
    restrictions: () => SENIOR,
  },
  {
    path: '/config/combinations',
    component: ConfigCombos,
    restrictions: () => ADMIN,
  },
  {
    path: '/config/configMetiersSteps',
    component: ConfigMetiersSteps,
    restrictions: () => ADMIN,
  },
  {
    path: '/config/configMetiersDevis',
    component: ConfigMetiersDevis,
    restrictions: () => ADMIN
  },
  {
    path: '/config/users/service',
    component: UsersService,
    restrictions: () => CHIEF,
  },
  {
    path: '/config/users',
    component: ConfigUsers,
    restrictions: ({ service }) =>
      ({
        [S_RH]: NOT_CONFIRMED,
      }[service] || ADMIN),
  },
  {
    path: '/config/commissions',
    component: CommissionsGlobals,
    restrictions: () => ADMIN,
  },
  {
    path: '/sms/edit',
    component: UtilsSms,
    restrictions: () => CHIEF,
  },
  {
    path: '/config/sms',
    component: SmsManagement,
    restrictions: () => ADMIN,
  },
  {
    path: '/config/ip',
    component: IpManager,
    restrictions: () => ADMIN,
  },
  {
    path: '/config/fournisseursManager',
    component: FournisseursManager,
    restrictions: () => ADMIN,
  },
  {
    path: '/config/callNotTransform',
    component: CallNotTransform,
    restrictions: () => ADMIN,
  },
  {
    path: '/sms/campagnes',
    component: SmsCampagne,
    restrictions: () => CHIEF,
  },
  {
    path: '/statistiques/',
    component: StatsList,
    restrictions: () => HEADCHIEF,
  },
  {
    path: '/config/general',
    component: ConfigGeneral,
    restrictions: () => ADMIN,
  },
  {
    path: '/config/fournisseursCSV',
    component: FournisseursCSV,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/config/updateLignes',
    component: UpdateLignes,
    restrictions: () => ADMIN,
  },
  {
    path: '/mms/:query',
    component: Mms,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/mms',
    component: Mms,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/prompt',
    component: PromptConfig,
    restrictions: () => ADMIN,
  },
  {
    path: '/config/blackListSource',
    component: BlackListSource,
    restrictions: () => ADMIN,
  }
];
