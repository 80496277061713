import React from 'react';
import Grid from '@mui/material/Grid';
import fetchText from '../fetchText';

export default ({ proposition: { comments, cancelRaison } }, classes) =>
  <Grid
    key={'Artisan updates a prog'}
    container
    item
    xs={8}
    spacing={3}
    children={[
      {
        data: {
          title: 'Raison d\'annulation : ',
          text: cancelRaison || 'raison non trouvé',
        },
      },
      ...(comments
        ? [{
          data: {
            title: 'Remarque : ',
            text: comments || '',
          },
        }]
        : []),
    ].map(fetchText(classes))}
  />;
