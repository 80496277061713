import React from 'react';
import CardContent from '@mui/material/CardContent';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Money from '../../../utils/Money';

const DisplayInfos = ({
  shift,
  budget,
  withSupplies,
  description,
}) => (
  <CardContent>
    <Paper>
      <Table >
        <TableBody>
          {[
            { label: 'Décalage possible ?', value: shift },
            { label: 'Budget', value: `${Money.toString(budget || 0, true)}` },
            {
              label: 'Fournitures',
              value: withSupplies ? 'Avec fourniture' : 'Sans fourniture'
            },
            {
              label: 'Info complémentaire',
              value: description || 'Pas de description'
            },
          ].map((row, index) =>
            <TableRow key={index}>
              <TableCell component="th" scope="row">{row.label}</TableCell>
              <TableCell align="right">{row.value}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  </CardContent>
);

export default DisplayInfos;
