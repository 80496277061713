import React, { useEffect, useRef, useState } from 'react';
import CustomMaterialTable from '../../CustomMaterialTable';
import { MSQ_PDR } from '@lba-dev/package.local-globals/questionTypeMSteps';
import { CloudUpload, Done } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import Buttons from '../../Buttons';
import {
  addCsv,
  addQuestion,
  changedData,
  deleteQuestion,
  getQuestions
} from './functions';
import notifSystem from '../../../notifSystem';
import { columns } from './columns';
import { Icon } from '@mui/material';
import store from '../../../store';
import { setDialog, setDialogContentProp } from '../../../actions/dialog';
import { genRandomId } from '../../../utils/number';

const empty = {
  ref: '',
  question: '',
  questionType: MSQ_PDR,
  multipleChoice: false,
  responses: []
};

export const questionSteps = {
  ANSWERS: 0,
  SUB_QUESTIONS: 1,
  ALT_ANSWERS: 2
};

const Questions = () => {
  const [data, setData] = useState([]);
  const [original, setOriginal] = useState([]);
  const tableRef = useRef(null);

  const onUpdate = (res, notif = false) => {
    [setData, setOriginal].forEach(f => f(res));
    if (notif) {
      notifSystem.success('Succès', 'Vos informations ont bien été ajoutées');
    }
  };

  const updateIds = (res) => {
    const d = data.map((e, i) => {
      const _id = res?.find(e => e.index === i)?._id;
      return {
        ...e,
        ...(_id ? { _id } : {})
      };
    });
    onUpdate(d, true);
  };

  useEffect(() => {
    getQuestions().then(onUpdate);
  }, []);

  const setDataInput = ([path, value], obj) => {
    const index = obj.tableData.index;
    const newObj = { ...data[index], [path]: value };
    const newData = [
      ...data.slice(0, index),
      newObj,
      ...data.slice(index + 1),
    ];
    setData(newData);
    return value;
  };

  const openResponsesDialog = (rowData) => store.dispatch(
    setDialog({
      name: 'AddNewResponses',
      open: true,
      hideClose: true,
      dialogProps: {
        title: rowData.question,
        middle: true,
        middleAll: true,
        maxWidth: 'lg'
      },
      contentProps: {
        rowData,
        responses: rowData.responses,
        allQuestions: data,
        setDataInput,
        stepper: questionSteps.ANSWERS
      },
      actions: [
        {
          children: 'Fermer',
          color: 'secondary',
          onClick: ({ stepper }, close) => {
            if (stepper === questionSteps.ANSWERS) {
              setDataInput(['responses', rowData.responses], rowData);
              return close();
            }
            return store.dispatch(
              setDialogContentProp('stepper')(questionSteps.ANSWERS)
            );
          },
        },
        {
          children: 'Valider',
          color: 'primary',
          onClick: ({ stepper }, close) =>
            stepper === questionSteps.ANSWERS
              ? close()
              : store.dispatch(
                setDialogContentProp('stepper')(questionSteps.ANSWERS)
              ),
        },
      ],
    })
  );

  const send = () => {
    const d = changedData(data, original);
    if (!d.length) {
      return notifSystem.error(
        'Erreur',
        'Un ou plusieurs champs semblent vides'
      );
    }
    return addQuestion(d).then(updateIds);
  };

  const buttons = [{
    name: 'Enregistrer',
    icon: Done,
    palette: green,
    condition: () => (true),
    handler: send
  }];

  const add = () => {
    const newElem = { ...empty, ref: genRandomId('Q') };
    const n = [newElem, ...data];
    setData(n);
  };

  const deleteItem = ({ _id, ref }) => {
    if (_id) {
      return deleteQuestion(_id)
        .then(() => setData(data.filter(e => e._id !== _id)));
    }
    return new Promise((resolve) => {
      setData(data.filter(e => e.ref !== ref));
      resolve();
    });
  };

  const handleChangeData = (dataObj, obj) =>
    new Promise((resolve) => {
      const index = obj.tableData.index;
      const newData = [
        ...data.slice(0, index),
        dataObj,
        ...data.slice(index + 1),
      ];
      setData(newData);
      return resolve();
    });

  return [
    <Buttons key={0} buttons={buttons} />,
    <CustomMaterialTable
      key={1}
      tableRef={tableRef}
      columns={columns}
      title="Liste Questions"
      data={data}
      editable={{
        onRowUpdate: handleChangeData,
        onRowDelete: deleteItem,
      }}
      actions={[
        {
          icon: 'add',
          tooltip: 'Ajouter une question',
          isFreeAction: true,
          onClick: add
        },
        {
          icon: () => (
            <Icon aria-label="upload picture" component="label">
              <input
                hidden
                type="file"
                onChange={(f) => addCsv(f, data, setData)}
              />
              <CloudUpload />
            </Icon>
          ),
          tooltip: 'Importer des questions',
          isFreeAction: true,
        },
      ]}
      options={{
        search: true,
        actionsColumnIndex: -1,
        pageSize: 50,
        pageSizeOptions: [25, 50, 100],
        tableLayout: 'fixed'
      }}
      onRowClick={(_, rowData) => openResponsesDialog(rowData)}
    />
  ];
};

export default (Questions);
