import React from 'react';
import { withStyles } from 'tss-react/mui';
import Iframe from 'react-iframe';
import MinCustomDialog from '../Dialogs/MinCustomDialog';
import Grid from '@mui/material/Grid';

const styles = {
  source: {
    textAlign: 'center',
    padding: 10
  }
};

const DialogIframs = ({ openIfram, urls, updateState, classes }) => (
  <MinCustomDialog
    open={openIfram}
    onClose={() => updateState('openIfram', false)}
    maxWidth={false}
    middle
  >
    <Grid container>
      {urls.map( (e, i) =>
        <Grid item sm={6} key ={i} >
          <Iframe
            url={e.get('name')}
            width="100%"
            height="400px"
            display="initial"
            position="relative"
            allowFullScreen={true}
          />
          <div className={classes.source} >
            { e.get('source')}
          </div>
        </Grid>)
      }
    </Grid>
  </MinCustomDialog>
);

export default withStyles(DialogIframs, styles);
