import React, { Component } from 'react';

import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { data as categories } from '@lba-dev/package.local-globals/categories';

import CardItems from './CancelDialog/CardItems';
import McDoStepper from './McDoStepper';
import { connect } from 'react-redux';
import api from '../../api';
import { closeDialog } from '../../actions/dialog';
import { getLastOrder } from '../../actions/configurateur';

const CATEGORY = 1;
const SCAT = 2;
const LITEM = 3;

const styles = {
  input: {
    padding: '16px',
    paddingBottom: 0,
  },
};

const finalKeys = {
  combinations: 'isProduct',
  metiersSteps: 'isFinalStep',
  metiersDevis: 'isFinalStep',
};

const getData = (dataType, filter) =>
  api[dataType]
    .getAll({
      ...filter,
      withOutCache: true,
    })
    .then((res) => res.body().map((e) => e.data && e.data()))
    .then((data) =>
      data.map((e) => ({
        ...e,
        data: e,
      }))
    );

const getLastElementId = (dataType) =>
  getData(dataType, { limit: 1 })
    .then((e) => e[0].id)
    .catch(() => 0);

const copyElem = (dataType, data) => api[dataType].post([data]);

const duplicate = ({ dataType, ...update }) =>
  api[dataType].custom('duplicate').post(update);

const mapStateToProps = ({
  dialog: {
    contentProps: { dataType, elem, onlyFinalChild, copyFunc, cb, copyFrom },
  },
}) => ({
  dataType,
  elem,
  onlyFinalChild,
  copyFunc,
  finalKeyName: finalKeys[dataType],
  cb,
  copyFrom
});

const mapDispatchToProps = {
  closeDialog: closeDialog,
};

const mergeProps = (state, dispatchers, ownProps) => ({
  ...state,
  ...dispatchers,
  ...ownProps,
  callback: () => {
    dispatchers.closeDialog();
    if (state.cb) {
      state.cb();
    }
  },
});

class DialogConfigurateurCopy extends Component {
  state = {
    item: {},
    data: [],
    originalData: [],
    categorie: null,
    arrayIndex: [],
    currentStep: CATEGORY,
    checkedItems: [],
  };

  copy = (multi) => {
    const { dataType, elem, callback, copyFunc, copyFrom } = this.props;
    const { data, originalData, arrayIndex, categorie, item, checkedItems } =
      this.state;
    if (copyFunc && typeof copyFunc === 'function') {
      return copyFunc(multi ? checkedItems : item, callback);
    }
    const lastElem = originalData
      .find(e => e.id === arrayIndex[arrayIndex.length - 1]);
    const refs = arrayIndex.reduce(
      (acc, v) =>
        `${acc} ${acc ? '/' : ''} ${
          (data.find((e) => e.id === v) || {}).name || ''
        }`,
      ''
    );
    const path = this.state.data.filter(e => arrayIndex.includes(e.id));
    const image = path.map(e =>
      Array.isArray(e.image) ? ((e.image[0] || {}).link || '') : e.image
    );
    const createdFrom = path.reduce((acc, curr, i) =>
      curr ? acc.concat({ id: curr.id, name: curr.name, image: image[i] }) : acc
    , []).reverse();
    const newRef = arrayIndex.length ? arrayIndex[arrayIndex.length - 1] : null;
    const orders = originalData
      .filter((e) => newRef && 'order' in e &&
      (e.categories || [e.categorie]).includes(categorie)
      )
      .sort((e, v) => e.order - v.order);
    const order = orders.length ? orders[orders.length - 1].order + 1 : 1
    const lastElemOrder = (getLastOrder(
      data.filter((e) => newRef === e.ref &&
      (e.categories || [e.categorie]).includes(categorie)
      ),
      categorie
    ) + 1) || order;
    const elemOrders = (elem.orders || [])
      .updateOrInsert({
        categorie,
        order: lastElemOrder
      }, (e) => e.categorie === categorie)
    getLastElementId(dataType).then((count) => {
      copyElem(dataType, {
        ...elem,
        categorie: categorie,
        categories: lastElem?.categories || [categorie],
        _id: undefined,
        isMaster: undefined,
        masterId: undefined,
        path: refs,
        name: `${elem.name || ''} (copie)`,
        id: count + 1,
        modified: true,
        ref: arrayIndex.length ? arrayIndex[arrayIndex.length - 1] : null,
        createdFrom,
        order,
        orders: elemOrders,
        ...(elem.isMaster
          ? {
            masterId: elem._id,
          }
          : {}),
      })
        .then(() => duplicate({
          dataType,
          id: elem.id,
          newId: count + 1,
          copyFrom,
          categorie
        }))
        .then(() => callback());
    });
  };

  close = () => this.props.callback();

  generateDialogProps = (step) => {
    const item = this.state.item;
    switch (step) {
      case CATEGORY:
        return {
          title: 'Choisissez une catégorie',
        };
      case SCAT:
        return {
          title: 'Choisissez un sous-categorie',
        };
      case LITEM:
        return {
          title: item.name,
        };
    }
  };

  setStep = async (item, setData) => {
    const { dataType, finalKeyName, onlyFinalChild } = this.props;
    const { currentStep, categorie, data, originalData, arrayIndex } =
      this.state;
    let newData = [...data];
    let original = [...originalData];
    if (currentStep === CATEGORY) {
      original = await getData(dataType, {
        query: JSON.stringify({ categorie: item._id }),
        field: JSON.stringify({
          name: 1,
          id: 1,
          _id: 1,
          ref: 1,
          categorie: 1,
          categories: 1,
          questions: 1,
          [finalKeyName]: 1,
          order: 1,
          image: 1,
          orders: 1
        }),
      });
      newData = original.filter((e) => onlyFinalChild || !e[finalKeyName]);
    }
    const update = categorie
      ? {
        item,
        arrayIndex: arrayIndex.concat(item.id),
      }
      : {
        categorie: item._id,
        data: newData.filter((e) => onlyFinalChild || !e[finalKeyName]),
        originalData: original,
      };
    this.setState(update, () => {
      newData = newData.filter(
        (e) =>
          e.ref === (currentStep === CATEGORY ? null : item.id) &&
          (onlyFinalChild || !e[finalKeyName])
      );
      if (newData.length) {
        this.setState({ currentStep: SCAT });
        setData(newData, SCAT);
      } else {
        this.setState({ currentStep: LITEM });
        setData(newData, LITEM);
      }
    });
  };

  handleCheckbox = (item) => {
    this.setState(({ checkedItems }) => ({
      checkedItems: checkedItems.toggleValue(item, (e) => e._id === item._id),
    }));
  };

  generateContent = (currentData, currentStep, setData) => {
    const { categorie, checkedItems } = this.state;
    const { finalKeyName, onlyFinalChild } = this.props;
    switch (currentStep) {
      case CATEGORY:
      case SCAT:
        return (
          <CardItems
            name="data"
            display="name"
            setData={(d, signal) => this.setStep(signal, setData)}
            array={currentData}
            isSelected={(item) =>
              !!checkedItems.find((e) => e._id === item._id)
            }
            handleCheckbox={this.handleCheckbox}
            withCheckbox={(item) =>
              currentStep !== CATEGORY && onlyFinalChild && item[finalKeyName]
            }
          />
        );
      case LITEM:
        return (
          <Box
            width="100%"
            border="1px dashed #000"
            m={2}
            p={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="subtitle2"
              children={
                'Cliquer sur \'copier ici\' pour mettre dans cette categorie'
              }
            />
          </Box>
        );
      default:
        if (!categorie) {
          setData(
            categories.map((e) => ({ ...e, id: e._id, data: e, ref: null })),
            CATEGORY
          );
        }
        return null;
    }
  };

  onRewind = (history) => {
    const lastElem = history[history.length - 1] || {};
    this.setState(({ categorie, arrayIndex }) => ({
      currentStep: lastElem.step || CATEGORY,
      categorie: lastElem.step === CATEGORY ? null : categorie,
      arrayIndex: arrayIndex.slice(0, -1),
    }));
  };

  render() {
    const onlyFinalChild = this.props.onlyFinalChild;
    const { categorie, currentStep, checkedItems } = this.state;
    return (
      <McDoStepper
        onClose={this.close}
        generateContent={(...args) => (
          <Grid container spacing={2} justifyContent="center">
            {this.generateContent(...args)}
          </Grid>
        )}
        customHeader={() =>
          categorie &&
          [
            onlyFinalChild && currentStep === LITEM && checkedItems.length && (
              <Typography
                variant="overline"
                children="⚠️ la séléctionner sera perdu ⚠️"
              />
            ),
            (!onlyFinalChild ||
              currentStep === LITEM ||
              (onlyFinalChild &&
                currentStep === SCAT &&
                checkedItems.length)) && (
              <Button
                color="primary"
                children={
                  onlyFinalChild && currentStep === SCAT
                    ? 'Copier'
                    : 'Copier ici'
                }
                variant="outlined"
                onClick={() =>
                  this.copy(onlyFinalChild && currentStep === SCAT)
                }
              />
            ),
          ].filter(Boolean)
        }
        generateDialogProps={this.generateDialogProps}
        onRewind={this.onRewind}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(
  withStyles(DialogConfigurateurCopy, styles)
);
