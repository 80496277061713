import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { Avatar, Chip, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Call, EventBusy } from '@mui/icons-material';
import green from '@mui/material/colors/green';

import { moment } from '@lba-dev/package.local-globals/moment';
import { DLUSER } from '@lba-dev/package.local-globals/docTypes';
import getDataById from '@lba-dev/package.local-globals/getDataById';
import { SMSType } from '@lba-dev/package.local-globals/smsType';

import { stringAvatar } from '../../../constant';
import MessageBubble from './MessageBubble';
import { red } from '@mui/material/colors';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';

const useStyles = makeStyles()({
  messageContainer: {
    display: 'flex',
    paddingTop: 8,
  },
  message: {
    margin: 8,
    wordWrap: 'break-word',
  },
  avatarDiv: {
    margin: 5,
  },
});

const iconBySmsType = {
  [SMSType.CALLOUT]: {
    Icon: Call,
    color: green[500],
  },
  [SMSType.CALLIN]: {
    Icon: PhoneCallbackIcon,
    color: green[500],
  },
  [SMSType.ABSENCE]: {
    Icon: EventBusy,
    color: red[500],
  },
};

const ChatMessage = ({
  message, mUser, isSameDay, ...props
}) => {
  const transmitter = message.get('transmitter');
  const sender = message.get('sender');
  const { classes } = useStyles();
  const date = new Date(message.get('date'));
  const service = mUser && (getDataById.services[mUser.service] || {}).name;
  const nameChat = mUser && sender ?
    `${mUser.alias.capitalize()}${
      service ? ` | ${service.capitalize()}` : ''}` :
    (transmitter?.split(' ').map(e => e.capitalize()).join(' ') ||
      (sender ? 'Non trouvé' : ''));
  const image = !sender ?
    message.getIn(['artisanObject', 'logo']) :
    mUser?.dropbox?.find(e => e.type === DLUSER)?.link;
  const isAuto = message.get('isAuto') || message.get('transmitter') === 'AUTO';
  const avatarProps = stringAvatar(nameChat, nameChat.length, {
    isChat: true,
    contactColor: message.get('color'),
    sender,
    transmitter,
    isAuto,
  });
  const typeIcon = iconBySmsType[message.get('type')];
  return (
    <div
      className={classes.message}
      style={sender ? { textAlign: 'right' } : {}}
    >
      <Divider>
        {!isSameDay && (
          <Chip
            size="small"
            sx={{ color: 'primary.main', fontWeight: '700', fontSize: 10 }}
            label={moment(date).format('DD MMMM')}
          />
        )}
      </Divider>
      {[
        SMSType.CALLOUT, SMSType.CALLIN, SMSType.ABSENCE
      ].includes(message.get('type')) && (
        <Box sx={{ textAlign: 'center', py: 2 }}>
          <Chip
            size="small"
            variant="outlined"
            style={{ borderRadius: 8 }}
            avatar={<typeIcon.Icon
              fontSize="small"
              style={{ color: typeIcon.color }}
            />}
            label={<Typography
              variant='caption'
              style={{ color: 'rgba(0,0,0, 0.80)' }}
              children={message.getIn(['data', 'message'])}
            />}
            className={classes.chip}
          />
        </Box>
      )}
      {[SMSType.SMSOUT, SMSType.SMSIN].includes(message.get('type')) && (
        <>
          <div className={classes.messageContainer}>
            <div className={classes.avatarDiv}>
              <Box>
                <Avatar
                  src={image || ''}
                  alt="avatar"
                  {...avatarProps}
                  sx={{
                    ...avatarProps.sx,
                    bgcolor: image ? '' : avatarProps.sx.bgcolor,
                  }}
                />
              </Box>
            </div>
            <MessageBubble
              message={message}
              mUser={mUser}
              nameChat={nameChat}
              transmitter={transmitter}
              {...props}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ChatMessage;
