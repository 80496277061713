import React from 'react';

import { red, green } from '@mui/material/colors';
import { PersonPin } from '@mui/icons-material';
import { withStyles } from 'tss-react/mui';


const styles = () => ({
  active: {
    color: green[500],
  },
  desactive: {
    color: red[500],
  },
});

export default withStyles(({ user, classes }) => (
  <PersonPin
    className={user.isActiveSession ? classes.active : classes.desactive}
  />
), styles);
