import { createTheme } from '@mui/material/styles';
import red from '@mui/material/colors/red';
import blue from '@mui/material/colors/blue';
import pink from '@mui/material/colors/pink';
import { green } from '@mui/material/colors';

export const primary = {
  50: '#f2f9e6',
  100: '#e0f1c2',
  200: '#cbe899',
  300: '#b6df70',
  400: '#a6d851',
  500: '#96d132',
  600: '#8ecc2d',
  700: '#83c626',
  800: '#79c01f',
  900: '#68b513',
  A100: '#f2ffe5',
  A200: '#d8ffb3',
  A400: '#beff80',
  A700: '#83c626',
  'contrastDefaultColor': 'dark',
};

export const secondary = {
  50: '#ede6f9',
  100: '#d2c0f0',
  200: '#b497e6',
  300: '#966ddc',
  400: '#804dd5',
  500: '#692ecd',
  600: '#6129c8',
  700: '#5623c1',
  800: '#4c1dba',
  900: '#3b12ae',
  A100: '#e6dfff',
  A200: '#beacff',
  A400: '#9779ff',
  A700: '#835fff',
  'contrastDefaultColor': 'light',
};

let theme = createTheme();
const spacing = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

// - This function is a temporary fix for a spacing bug in MUI5 Grid
// that add padding in top and left only.
// - This function return 9 object for the 9 Mui5 spacing Global class's.
// - It return a padding and a margin similar to MUI4 default spacing value's
// for each spacing Global class.
// - This function is temporary, please remove it once the issue is solved.
const adaptedCssForGrid = () => spacing
  .reduce((acc, curr) => ({
    ...acc,
    [`spacing-xs-${curr}`]: {
      margin: theme.spacing(-(curr / 2)),
      '> .MuiGrid-item': {
        padding: theme.spacing(curr / 2)
      }
    },
  }), {});


export default createTheme({
  typography: {
    fontFamily: [
      'Rubik',
      'sans-serif',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: blue[500]
    },
    secondary: {
      main: pink[500]
    },
    valid: green[500],
    error: red,
    sideBar: '#333'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'unset',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'inherit'
      },
      styleOverrides: {
        root: {
          fontFamily: [
            'Rubik',
            'sans-serif',
          ].join(','),
          letterSpacing: 'normal',
        },
        text: {
          padding: '8px 16px'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontSize: 21
        },
        caption: {
          color: 'rgba(0, 0, 0, 0.54)'
        },
        body1: {
          fontSize: 14
        },
        body2: {
          fontSize: 16
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: 48
        },
        head: {
          height: 56
        }
      },
    },
    MuiListItem: {
      styleOverrides: {
        gutters: {
          padding: '12px 24px',
        }
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 13,
          lineHeight: 'normal',
          padding: '0 7px',
          '&:last-child': {
            padding: '0 12px'
          }
        },
        head: {
          padding: '0 12px'
        }
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0
        },
        primary: {
          fontSize: 14
        }
      },
    },
    MuiInputBase: {
      styleOverrides: {
        inputMultiline: {
          height: '100%'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0 24px 24px'
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary'
      }
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary'
      }
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary'
      }
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'secondary',
        textColor: 'inherit'
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiGrid: {
      styleOverrides: adaptedCssForGrid(),
    },
    MuiInputLabel: {
      defaultProps: {
        variant: 'standard'
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave'
      }
    }
  }
});
