import React, { PureComponent } from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from 'tss-react/mui';
import {
  Replay as ReplayIcon,
  Description as ExportCSV,
} from '@mui/icons-material';

import ButtonTimeout from '../../ButtonTimeout';
import PropTypes from 'prop-types';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import Typography from '@mui/material/Typography';

const styles = {
  toolbar: {
    justifyContent: 'flex-end',
    flex: '1 1 100%'
  },
  title: {
    flex: 'auto'
  }
};

class Header extends PureComponent {
  reload = () => {
    const { updateTable, display, filter, page } = this.props;
    updateTable(display, page, filter);
  }

  render() {
    const {
      classes,
      user,
      getCSV,
      title
    } = this.props;
    return (
      <Toolbar className={classes.toolbar}>
        {title ?
          <Typography
            variant="h6"
            component="h3"
            className={classes.title}
            children={title}
          /> :
          null}
        {user && permit(user, { key: 'exportListCSV' }) &&
          <Tooltip disableInteractive title="Export CSV">
            <ButtonTimeout ButtonComp={IconButton} timeout={5}
              onClick={() => getCSV()}
              aria-label="export CSV"
            >
              <ExportCSV />
            </ButtonTimeout>
          </Tooltip>
        }
        <Tooltip disableInteractive title="Recharger">
          <ButtonTimeout ButtonComp={IconButton} timeout={5}
            onClick={this.reload} aria-label="Recharger">
            <ReplayIcon />
          </ButtonTimeout>
        </Tooltip>
      </Toolbar>
    );
  }
}

Header.propTypes = {
  updateTable: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  display: PropTypes.number.isRequired
};

export default withStyles(Header, styles);
