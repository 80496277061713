import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import DisplaySignalements, { GenCommentItem } from './DisplaySignalements';
import { withStyles } from 'tss-react/mui';
import { withExpansionPanel } from '../../../hoc';

const styles = {
  commentList: {
    maxHeight: 235,
    overflow: 'auto'
  }
};

const ExpansDisplaySignalements = withExpansionPanel(DisplaySignalements);
const ExpansComments = withExpansionPanel(
  withStyles(({ classes, comments }) =>
    <List className={classes.commentList}>
      {comments.map(({ commentValue, userId, date }, i) =>
        <GenCommentItem
          key={i}
          comment={commentValue}
          user={userId}
          date={date}
        />)}
    </List>, styles));


const DialogInfoCompta = ({ callback, open, defaultText }) => {
  const { comments, signalements } = defaultText;
  return (
    <Dialog onEscapeKeyDown={callback} open={open} maxWidth="md">
      <DialogTitle>Informations comptabilité</DialogTitle>
      <DialogContent>
        {comments && comments.length ?
          <ExpansComments
            expanded
            comments={comments}
            expansionTitle="Commentaires"
          /> : ''}
        {signalements && signalements.length ?
          <ExpansDisplaySignalements
            expansionTitle="Signalements"
            expanded
            signalements={signalements} /> : ''}
      </DialogContent>
      <DialogActions>
        <Button onClick={callback} color="primary">
        Vu
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(DialogInfoCompta, styles);
