import React from 'react';
import CardContent from '@mui/material/CardContent';
import { AddCircle, AddCircleOutline, Timer, Sms } from '@mui/icons-material';

import Button from '@mui/material/Button';

const DetailActions = ({
  addArtisan,
  openDialog,
  updateState,
  updateStateP,
  classes,
}) => (
  <CardContent className={classes.DetailAction}>
    <Button
      className={classes.DetailActionB}
      onClick={addArtisan}
      variant="outlined"
    >
      <AddCircle />
      Ajouter l'artisan
    </Button>
    <Button
      className={classes.DetailActionB}
      onClick={openDialog}
      variant="outlined"
    >
      <Timer />
      Rappel/Absence
    </Button>
    <Button
      className={classes.DetailActionB}
      onClick={() => updateState('openSms', true)}
      variant="outlined"
    >
      <Sms />
      SMS
    </Button>
    <Button
      className={classes.DetailActionB}
      onClick={() => updateStateP('openComment', true)}
      variant="outlined"
    >
      <AddCircleOutline />
      Commentaire
    </Button>
  </CardContent>
);

export default DetailActions;
