import api from '../api';
import notifSystem from '../notifSystem';

export const getSimpleSignalements = (filter = {}) =>
  api.signalements.getAll({
    filter: JSON.stringify(filter)
  });

export const getAllSignalements = (filter = {}, {
  page = 0,
  rowsPerPage = 10,
  sortBy = 'date.creation',
  order = 1
} = {}) =>
  api.signalements.get('resources', {
    filter: JSON.stringify(filter),
    page: page + 1,
    rowsPerPage,
    sortBy,
    order
  })
    .then(res => res.body().data());

export const addSignalement = (comment, signal, intervention, artisan) => {
  const obj = {
    comment: comment.trim(),
    signal
  };

  if (artisan) {
    obj.artisan = artisan;
  }
  if (intervention) {
    obj.intervention = intervention;
  }
  return api.signalements
    .post(obj)
    .then((res) => {
      notifSystem.success('Opération réussie',
        'Le signalement a bien été émis');
      return res.body().data();
    }
    )
    .catch((e) => notifSystem.error(e.name, e.response ? e.response.data : e));
};

export const addSignalementFrom = (
  id,
  comment,
  status,
  { signal, description, intervention }
) =>
  api.signalements
    .custom(`comment/${id}`)
    .patch({
      comment,
      status,
      signal,
      description,
      intervention,
    })
    .then((res) => res.body().data());

export const setManagerSignalement = (id, userId) =>
  api.signalements.custom(`manage/${id}`).patch({
    userId
  });

export const removeFromMySignalements = (signalId, userId) =>
  api.signalements.custom(`remove/${signalId}`).patch({
    userId
  })
    .then(() => notifSystem.success('Succès', 'Supprimé de vos signalements'))
    .catch(() => notifSystem.error('Erreur',
      'Impossible de le supprimer de vos signalements'));

export const commentSignalement = (id, comment, status) =>
  api.signalements.custom(`comment/${id}`).patch({
    comment,
    status
  }).then(res => res.body().data());

export const inform = (id, notification, mail) =>
  api.signalements.custom(`inform/${id}`).patch({
    notification,
    mail
  }).then(res => res.body().data());

export const addReminder = (id, comment, date) =>
  api
    .signalements
    .custom(`addReminder/${id}`)
    .post({
      comment,
      date
    });
