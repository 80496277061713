import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';

import {
  data as paymentMethods,
  AUCUN,
} from '@lba-dev/package.local-globals/paymentMethods';
import CustomSelect from '../CustomInputs/CustomSelect';
import { withDisplaying } from '../../hoc';
import { I_DVG } from '@lba-dev/package.local-globals/iStatus';

const paths = {
  paymentMethod: ['paymentMethod'],
  creditCardNumber: ['creditCardNumber'],
  paymentMethodSAV: ['infoDescSAV', 'paymentMethod'],
  creditCardNumberSAV: ['infoDescSAV', 'creditCardNumber'],
};

class PaymentMethod extends PureComponent {
  constructor() {
    super();
    this.setData = this.setData.bind(this);
  }
  setData(path, value) {
    const { isSAV } = this.props;
    if (value !== 0) {
      this.props.setData(
        paths[isSAV ? 'creditCardNumberSAV' : 'creditCardNumber'],
        ''
      );
    }
    this.props.setData(path, value);
  }
  render() {
    const {
      paymentMethod,
      onSitePayment,
      xs,
      isSAV,
      restrictions,
      interStatus,
      ...props
    } = this.props;

    return (
      <CustomSelect
        grid
        xs={xs || 12}
        path={paths[isSAV ? 'paymentMethodSAV' : 'paymentMethod']}
        value={
          paymentMethod !== AUCUN || paymentMethod === null
            ? paymentMethod
            : AUCUN
        }
        label="Mode de Reglement"
        style={
          onSitePayment && interStatus !== I_DVG && paymentMethod === AUCUN
            ? { color: 'red' }
            : null
        }
        setData={this.setData}
        restrictions={restrictions}
        {...props}
      >
        {paymentMethods.map((e) => (
          <MenuItem
            disabled={e.disabled && e.disabled(interStatus)}
            key={e._id}
            value={e._id}
          >
            {e.fullName}
          </MenuItem>
        ))}
      </CustomSelect>
    );
  }
}
PaymentMethod.propTypes = {
  civility: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired,
};

PaymentMethod.defaultProps = {
  civility: '',
};

PaymentMethod.displayName = 'PaymentMethod';

export default withDisplaying()(PaymentMethod);
