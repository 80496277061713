import React, { PureComponent } from 'react';
import CollapseArrow from '../../CollapseArrow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { withStyles } from 'tss-react/mui';
import { withMediaQuery } from '../../../hoc';

const styles = {
  toggle: {
    width: 30,
    height: 30,
  }
};

class ToogleDesc extends PureComponent {
  render() {
    const { open, toogle, classes } = this.props;
    return (
      <TableCell
        padding="checkbox"
      >
        <IconButton
          onClick={toogle}
          className={this.props.isDown.md ? classes.toggle : null}
          size="large">
          <CollapseArrow open={open} />
        </IconButton>
      </TableCell>
    );
  }
}

export default withMediaQuery()(withStyles(ToogleDesc, styles));
