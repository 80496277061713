import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import CustomTextField from '../CustomInputs/CustomTextField';

const paths = {
  road: ['address', 'road'],
  number: ['address', 'number'],
  zipcode: ['address', 'zipcode'],
  city: ['address', 'city'],
};

class Address extends PureComponent {
  render() {
    const { number, road, zipcode, city, setData } = this.props;
    return (
      <Grid container spacing={3}>
        <CustomTextField
          grid
          xs={12}
          sm={1}
          lg={1}
          capsLocked
          label="Numéro"
          value={number}
          path={paths.number}
          setData={setData}
        />
        <CustomTextField
          grid
          xs={12}
          sm={6}
          lg={5}
          capsLocked
          label="Address"
          value={road}
          path={paths.road}
          setData={setData}
        />
        <CustomTextField
          grid
          xs={12}
          sm={2}
          lg={2}
          capsLocked
          label="Code Postal"
          value={zipcode}
          path={paths.zipcode}
          setData={setData}
        />
        <CustomTextField
          grid
          xs={12}
          sm={3}
          lg={3}
          capsLocked
          label="Ville"
          value={city}
          path={paths.city}
          setData={setData}
        />
      </Grid>
    );
  }
}

Address.propTypes = {
  number: PropTypes.string.isRequired,
  road: PropTypes.string.isRequired,
  zipcode: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired
};

Address.defaultProps = {
  number: '',
  road: '',
  zipcode: '',
  city: ''
};

export default Address;
