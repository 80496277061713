import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import {
  TurnedIn,
  FolderShared
} from '@mui/icons-material';
import { connect } from 'react-redux';
import { data } from '@lba-dev/package.local-globals/SAVStatus';
import { green } from '@mui/material/colors';

const mapStateToProps = ({ users }, { userSAV }) => {
  const userObj = userSAV && users.find(e => e._id === userSAV);
  return {
    loginSAV: userObj && userObj.login
  };
};

const SAVIcon = ({ statusSAV, loginSAV }) => {
  const status = data.find(c => c._id === statusSAV);
  return ([
    statusSAV && <Tooltip disableInteractive
      key={'statusSAV'}
      title={<span>Status: {status.name || 'untrouvable'}</span>}
      children={<TurnedIn style={{ color: status.color[500], fontSize: 20 }} />}
    />,
    loginSAV && <Tooltip disableInteractive key={'loginSAV'}
      title={<span>Dossier suivi par: {loginSAV || 'untrouvable'}</span>}
      children={<FolderShared
        style={{ color: green[500], fontSize: 25 }} />}
    />
  ]);
};

export default connect(mapStateToProps)(SAVIcon);
