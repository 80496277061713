import React from 'react';
import { getPhoneNumber } from '@lba-dev/package.local-globals/smsFunctions';
import { Box } from '@mui/system';
import { ListItemText, MenuItem, Select, Tooltip } from '@mui/material';
import { checkPhoneNumber } from '../../../../../utils/function';

const getDefaultNumber = (data) => getPhoneNumber(data || {});

const TelSelect = ({
  classes, selectedNumber, setSelectedNumber,
  dataObject
}) => {
  const actualNumber = selectedNumber || getDefaultNumber(dataObject) || '';
  const tels = dataObject?.tels || [];

  return tels.length ?
    <Box id="phones" display='flex' alignItems='center'>
      <Select
        onChange={(e) => setSelectedNumber(e.target.value)}
        defaultValue={actualNumber}
        renderValue={(v) => {
          const elem = tels.find(e => e.tel === v) ||
            { tel: actualNumber, title: actualNumber };
          return elem ? <Tooltip title={elem.tel}>
            <span>
          Envoyer vers&nbsp;
              <span
                className={classes.selectValueNumber}
                children={elem.title} />
            </span>
          </Tooltip> : '';
        }}
        variant='standard'
        className={classes.numberSelect}
        classes={{
          icon: classes.numberSelectIcon
        }}
        style={{
          fontSize: 14
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          }
        }}
      >
        {tels.filter(e => checkPhoneNumber(e.tel))
          .map((t, i) =>
            <MenuItem
              key={i}
              value={t.tel}
              selected={actualNumber === t.tel}
            >
              <ListItemText
                primary={t.title}
                secondary={t.tel}
              />
            </MenuItem>)}
      </Select>
    </Box> : '';
};

export default TelSelect;
