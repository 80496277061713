import React from 'react';
import { Chip, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  chip: {
    width: '100%',
    minHeight: 32,
    height: 'auto',
    textAlign: 'center',
    '& > span': {
      whiteSpace: 'normal',
    }
  },
});

const OldQuestion = ({ metiersSteps }) => {
  const { classes } = useStyles();
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={11}>
        <Typography
          variant="subtitle1"
          children={metiersSteps.name}
        />
      </Grid>
      <Grid item xs={11}>
        <Typography
          variant="subtitle1"
          children="Questions pour le client"
        />
      </Grid>
      <Grid item xs={10}>
        <Grid container spacing={0.5}>
          {
            Object
              .entries(metiersSteps.answers || [])
              .map(([question, answer], i) => <>
                <Grid key={0} item xs={8}>
                  <Typography children={`${i + 1}. ${question}`} />
                </Grid>,
                <Grid key={1} item xs={2}>
                  <Chip
                    className={classes.chip}
                    label={answer}
                    variant="outlined"
                    color="primary"
                  />
                </Grid>
              </>)
          }
        </Grid>
      </Grid>
    </Grid>
  );};

export default OldQuestion;
