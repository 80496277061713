import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {
  data, OCCUPE, TROP_CHER, TRANSFERER
} from '@lba-dev/package.local-globals/statusProsp';
import CardContent from '@mui/material/CardContent';
import notifSystem from '../../notifSystem';

const styles = {
  formControl: {
    minWidth: 120,
    width: 400
  }
};

class Status extends PureComponent {
  updateData = (path, value) => {
    const { setData, updateState, openDialog, validate } = this.props;
    setData(path, value, () => {
      updateState('askStatus', false);
      validate();
    });
    if (value === OCCUPE) {
      openDialog();
    }
    else if (value === TROP_CHER) {
      notifSystem.info('Information', 'Veuillez ajouter un forfait');
    }
  };

  render() {
    const { classes, merge, updateState, askStatus } = this.props;
    return (
      <CardContent>
        <FormControl id="selectStatus" className={classes.formControl}>
          <InputLabel>Résumé de l'appel</InputLabel>
          <Select
            disabled={merge.getIn(['elem', 'obj', 'status']) === TRANSFERER}
            open={askStatus}
            onClose={() => updateState('askStatus', false)}
            onOpen={() => updateState('askStatus', true)}
            value={merge.getIn(['elem', 'obj', 'status'])}
            inputProps={{
              name: 'Résumé de l\'appel',
            }}
          >
            {data
              .filter((el) => el.show)
              .map((el, i) => (
                <MenuItem
                  key={i}
                  value={el._id}
                  onClick={() =>
                    this.updateData(
                      ['elem', 'obj', 'status'],
                      el._id
                    )
                  }
                >
                  {el.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </CardContent>
    );
  }
}

export default withStyles(Status, styles);
