import React from 'react';
import {
  Box,
  Collapse,
  Paper,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import SMSTypeList from './SMSTypeList';
import ContactContainer from './ContactContainer';
import ConversationContainer from './ConversationContainer';

const useStyles = makeStyles()((theme, { contactListPanelWidth }) => ({
  paper: {
    height: '100%',
    display: 'flex',
    flex: 'initial',
    boxShadow: theme.shadows[2],
    zIndex: theme.zIndex.modal - 10,
    position: 'relative',
  },
  contactList: {
    height: '100%',
    paddingBottom: 8,
    backgroundColor: theme.palette.common.white
  },
  conversationGrid: {
    height: '100%',
    flex: 1,
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[50]
  },
  convCollapse: {
    height: '100%',
    position: 'absolute',
    right: `${+contactListPanelWidth + 57}px`,
    boxShadow: theme.shadows[1],
  },
  collapseDiv: {
    height: '100%',
  }
}));

const DashChat = ({
  setSmsType, smsType, notif, user,
  lastMessages, setLastMessages, selected, setSelected,
  loading, setUser, loadMore
}) => {
  let contactListConfig = user?.chatConfig?.contactList || {};
  let contactListPanelWidth = (contactListConfig?.width || '')
    .replace('px', '') || 350;
  const { classes } = useStyles({
    contactListPanelWidth
  });

  const displayContainer = !!(selected && Object.keys(selected).length);
  return <Box component={Paper} className={classes.paper}>
    <Collapse
      orientation={'horizontal'}
      in={displayContainer}
      id='convCollapse'
      style={{
        right: `${!smsType ? 57 : +contactListPanelWidth + 57}px`
      }}
      easing={{
        enter: 500,
        exit: 700
      }}
      classes={{ root: classes.convCollapse }}>
      <Box item xs={8} className={classes.conversationGrid}>
        <ConversationContainer
          smsType={smsType}
          selected={selected}
          setSelected={setSelected}
          setLastMessages={setLastMessages}
          isDash
          user={user}
        />
      </Box>
    </Collapse>
    <Collapse
      orientation={'horizontal'}
      in={!!smsType}
      id='contactCollapse'
      addEndListener={(e) => {
        const newWidth = e.style.width.replace('px', '');
        let buttons = document.getElementById('buttons');
        if (buttons) {
          buttons.style.right = `${+newWidth + 57 + 15}px`;
        }
      }}
      classes={{ root: classes.collapseDiv }}>
      <Box item xs={4} className={classes.contactList}>
        <ContactContainer
          lastMessages={lastMessages[smsType] || []}
          selected={selected}
          setSelected={setSelected}
          classes={classes}
          loading={loading[smsType]}
          user={user}
          setSmsType={setSmsType}
          smsType={smsType}
          setUser={setUser}
          loadMore={loadMore}
          isDash
        />
      </Box>
    </Collapse>
    <SMSTypeList
      setSmsType={setSmsType}
      smsType={smsType}
      notif={notif}
      user={user}
      isDash
    />
  </Box>;
};

export default DashChat;
