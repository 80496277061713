import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import api from '../../api';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@mui/lab';
import { moment } from '@lba-dev/package.local-globals/moment';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  HourglassEmpty,
  HourglassFull,
  Pause,
  PlayArrow,
  Update
} from '@mui/icons-material';
import { getAlias } from '../../utils/function';

const mapStateToProps = ({ users }) => ({ users });

const Div = styled('div')(() => ({
  overflow: 'auto',
  maxHeight: '300px',
}));
const DemarchageHistory = ({ id }) => {
  const [demarchageHistory, setDemarchageHistory] = useState([]);

  useEffect(() => {
    api.history
      .getAll({ name: 'osId', id, type: 'DEMARCHAGE' })
      .then(res => setDemarchageHistory(
        res.body().map(e => e.data())
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .map(e => ({
            ...e,
            date: moment(e.date).format('L [à] HH:mm'),
            user: getAlias(e.userId)
          }))
      ));
  }, []);

  const getIcon = (text) => {
    const obj = {
      'Début compteur': <HourglassFull fontSize='small' />,
      'Prolongation': <Update fontSize='small'/>,
      'Pause': <Pause fontSize='small' />,
      'Fin du temps de demarchage': <HourglassEmpty fontSize='small' />,
      'Reprise démarchage': <PlayArrow fontSize='small' />,
    };
    return obj[Object.keys(obj).find((e) => text.includes(e))] || '';
  };

  return (
    <Grid item xs={12}>
      {!!demarchageHistory.length && [
        <Typography key={0} variant="h6" children="Historique Demarchage" />,
        <Div key={1}>
          {demarchageHistory.map(({ date, user, text }, i) => (
            <Timeline key={i} >
              <TimelineItem>
                <TimelineOppositeContent>
                  <Typography color="textSecondary">{date}</Typography>
                  <Typography
                    color="textSecondary"
                    children={user || 'Automatique'}
                  />
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot children={getIcon(text)}/>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant="body1" children={text} />
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          ))}
        </Div>
      ]}
    </Grid>
  );
};

export default connect(mapStateToProps)(DemarchageHistory);
