import { setDialog, setDialogContentProp } from '../../actions/dialog';
import api from '../../api';
import notifSystem from '../../notifSystem';
import store from '../../store';
import { call as callFunc } from '../../utils/call';
import { DECALAGE, AUTRE } from '@lba-dev/package.local-globals/callTypes';

const updateDate = (id, date, close, cb) => {
  api.interventions
    .patch(id, { date: { intervention: date } })
    .then(() => {
      close();
      if (cb) {
        cb();
      }
      notifSystem.success(
        'Opération réussie', 'La date d\'intervention a été modifiée'
      );
    })
    .catch(() => notifSystem.error(
      'Erreur',
      'L\'action n\'a pas pu être réalisée'
    ));
};

const sendPanneComment = (panneComment, close) =>
  api.interventions
    .custom('updateAudio')
    .post({
      name: 'panne',
      panneComment: panneComment.comment,
      url: panneComment.url
    })
    .then(() => {
      close();
      notifSystem.success(
        'Opération réussie', 'La date d\'intervention a été modifiée'
      );
    })
    .catch(() => notifSystem.error(
      'Erreur',
      'L\'action n\'a pas pu être réalisée'
    ));


export function openCallTypes(call, inter) {
  const dateInter = inter.date.intervention;
  store.dispatch(
    setDialog({
      hideClose: true,
      dialogProps: {
        middleAll: true,
        maxWidth: 'md',
        title: 'Quel était le résultat de cet appel ?',
        fullScreenOn: false,
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
      },
      contentProps: {
        call,
        step: 0,
        dateInter,
        panneComment: { comment: '', url: '' }
      },
      name: 'CallTypesDialog',
      open: true,
      actions: [
        {
          children: 'Retour',
          color: 'secondary',
          hideButton: ({ step }) => !step,
          onClick: () => store.dispatch(setDialogContentProp('step')(0))
        },
        {
          children: 'Valider',
          color: 'primary',
          hideButton: ({ step }) => !step,
          onClick: ({ step, dateInter, panneComment }, close) => {
            if (step === DECALAGE) {
              return updateDate(inter._id, dateInter, close);
            }
            else if (step === AUTRE) {
              if (!panneComment.url || !panneComment.comment.trim()) {
                return notifSystem.error(
                  'Erreur',
                  'Veuillez saisir la raison de la panne'
                );
              }
              return sendPanneComment(panneComment, close);
            }
          }
        },
        {
          children: 'Valider et appeler le client',
          color: 'primary',
          hideButton: ({ step }) => ![DECALAGE].includes(step),
          onClick: (data, close) => {
            updateDate(inter._id, data.dateInter, close, () => {
              callFunc(inter.client.tel1);
            });
          }
        },
      ],
    })
  );
}

export function sendAudioPlayed(call) {
  return api.interventions
    .custom('updateAudio')
    .post({
      name: 'played',
      url: call.urlEnregistrement,
      played: call.played
    });
}

export function sendCallTypes(type, url) {
  return api.interventions
    .custom('updateAudio')
    .post({ name: 'type', url, type });
}
