export const displayDocument = (pdf, custom, isURL) => {
  if (isURL) {
    return window.open(pdf, '_blank');
  }
  const isImage = pdf.includes('image');
  return window.open('').document.write(`
        <html style="
        background-color: ${isImage ? '#242424' : 'rgb(204, 204, 204);'}
        ">
          <body style="
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            right: 0;
            overflow: hidden;
          ">
            <embed
              width="${isImage ? '' : '100%'}"
              height="${isImage ? '' : '100%'}"
              style="${isImage ? 'min-width: 200px' : ''}"
              src="${!custom ? 'data:application/pdf;base64,' : ''}${pdf}"
            ></embed>
          </body>
        </html>`);
};
