import React from 'react';
import { createRoot } from 'react-dom/client';
import registerServiceWorker from './registerServiceWorker';
import App from './App';

class LBAV3 extends HTMLElement {
  constructor() {
    super()
    this.app = createRoot(this)
  }
  connectedCallback() {
    this.app.render(<App />);
  }

  disconnectedCallback() {
    this.app.unmount();
  }
}

customElements.define('lbav3-app', LBAV3);

registerServiceWorker();
