import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Send, Close } from '@mui/icons-material';
import { withStyles } from 'tss-react/mui';
import { PropTypes } from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import iphoneX from '@public/iphoneX-mockup.png';
import {
  SMS,
  WHATSAPPBUSINESS,
  data as smsMode,
} from '@lba-dev/package.local-globals/smsMode';
import { Grid, FormControlLabel, Switch } from '@mui/material';

const styles = {
  papere: {
    height: 630,
    width: 300,
    backgroundImage: `url("${iphoneX}")`,
    backgroundColor: 'transparent',
    backgroundSize: 'cover',
    boxShadow: 'none',
    backgroundRepeat: 'no-repeat',
  },
  content: {
    padding: '74px 48px 8px 39px !important',
    height: 400,
    overflow: 'hidden',
  },
  contentDevis: {
    padding: '74px 48px 8px 39px !important',
    height: 380,
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  red: {
    color: '#fff',
    backgroundColor: '#f44336',
  },
  redDevis: {
    color: '#fff',
    backgroundColor: '#f44336',
    marginLeft: '0% !important',
  },
  green: {
    color: '#fff',
    backgroundColor: '#4caf50',
  },
  icon: {
    marginLeft: '5px',
  }
};

class IphoneDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: (props.defaultText && props.defaultText.text) ||
      props.defaultText,
      switchEnabled: (props.defaultText && !!props.defaultText.offlineText)
    };
    this.setTextValue = this.setTextValue.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onReject = this.onReject.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.open !== nextProps.open ||
      this.state !== nextState ||
      this.props.defaultText !== nextProps.defaultText ||
      this.props.callback !== nextProps.callback
    );
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.defaultText !== nextProps.defaultText) {
      const text = (nextProps.defaultText && nextProps.defaultText.text) ||
        nextProps.defaultText;
      this.setState({
        value: text
      });
    }
  }

  setTextValue(e) {
    this.setState({
      value: e.target.value
    });
  }
  onValidate(mode) {
    const { callback, defaultText } = this.props;
    if (typeof callback === 'function') {
      callback(this.state.value, {
        mode,
        withMail: (defaultText && defaultText.withMail)
      });
    }
  }
  onReject() {
    if (typeof this.props.callback === 'function') {
      this.props.callback(null, {});
    }
  }

  handleSwitch() {
    const { defaultText } = this.props;
    if (defaultText && (defaultText.offlineText || defaultText.text)) {
      this.setState({
        switchEnabled: !this.state.switchEnabled,
        value:
        this.state.switchEnabled ? defaultText.offlineText : defaultText.text
      });
    }
  }

  render() {
    const {
      open,
      name,
      disagreeText,
      agreeText,
      maxWidth,
      fullWidth,
      classes,
      defaultText
    } = this.props;
    const {
      devisSms,
      disabled,
      offlineText
    } = defaultText;
    const { value, switchEnabled } = this.state;
    return (
      <Dialog
        onEscapeKeyDown={this.onReject}
        open={open}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        PaperProps={{ style: styles.papere }}>
        <div>
          {name && <DialogTitle>{name}</DialogTitle>}
          <DialogContent
            className={devisSms ? classes.contentDevis : classes.content}
          >
            {offlineText ? <FormControlLabel
              control={
                <Switch
                  value={switchEnabled}
                  checked={switchEnabled}
                  name="offlineText"
                  color="primary"
                  onChange={this.handleSwitch}
                />
              }
            /> : ''}
            <TextField
              variant="standard"
              disabled={disabled}
              fullWidth
              onChange={this.setTextValue}
              value={value}
              multiline={true}
              rows={10}
              InputProps={{ style: { height: '100%' } }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{ style: { height: '100%', fontSize: 14 } }} />
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Grid
              container
              sx={{ maxWidth: '80%' }}
              spacing={0.5}
              direction="row"
              justifyContent="space-evenly"
              alignItems="flex-end"
            >
              {devisSms ? (
                smsMode
                  .filter((e) => [SMS, WHATSAPPBUSINESS,
                  ].includes(e._id))
                  .map((mode, i) => (
                    <Grid item xs={12} key={i} >
                      <Button
                        fullWidth
                        key={i}
                        onClick={() => this.onValidate(mode._id)}
                        size="small"
                        variant="contained"
                        className={classes.green}
                      >
                        {mode.name}
                        <mode.icon className={classes.icon} />
                      </Button>
                    </Grid>
                  ))
              ) : (
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    onClick={this.onValidate}
                    size="small"
                    variant="contained"
                    className={classes.green}
                  >
                    {agreeText}
                    <Send className={classes.icon} />
                  </Button>
                </Grid>
              )}
              <Grid
                item xs={12}
              >
                <Button
                  fullWidth
                  onClick={this.onReject}
                  size="small"
                  variant="contained"
                  className={devisSms ? classes.redDevis : classes.red}
                >
                  {disagreeText}
                  <Close className={classes.icon} />
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

IphoneDialog.defaultProps = {
  agreeText: 'Envoyer',
  disagreeText: 'Annuler',
  maxWidth: 'sm',
  fullWidth: false
};
IphoneDialog.propTypes = {
  callback: PropTypes.func.isRequired,
  defaultText: PropTypes.string
};

export default withStyles(IphoneDialog, styles);
