import React from 'react';
import getDataById from '@lba-dev/package.local-globals/getDataById';
import { Link, Tooltip } from '@mui/material';
import { genButton, genChip } from '../../../utils/chips';
import { getLogin } from '../../../utils/function';
import Money from '../../../utils/Money';
import { OpenInNew } from '@mui/icons-material';
import { getDateOrTime } from '../../../utils/date';

export const columns = [
  {
    title: 'Id',
    field: 'id',
    cellStyle: { fontWeight: 'bold' },
    render: (row) => <Tooltip title="Ouvrir l'OS">
      <Link
        color="inherit"
        underline="none"
        style={{ display: 'flex', alignItems: 'center' }}
        target='_blank'
        href={`/intervention/${row.id}`}
      >
        {row.id} <OpenInNew
          style={{ marginLeft: 4 }}
          fontSize='small' />
      </Link>
    </Tooltip>
  },
  {
    title: 'Telepro',
    render: (row) => getLogin(row.login.ajout, 'indisponible').toUpperCase()
  },
  {
    title: 'Cat.',
    render: (row) => {
      const catData = getDataById.categories[row.categorie];
      return genButton(catData);
    }
  },
  {
    title: 'Ajout',
    render: (row) => getDateOrTime(row.date.ajout)
  },
  {
    title: 'Client',
    render: (row) => row.client.clientName
  },
  {
    title: 'Ville',
    render: (row) => row.client.fullCity
  },
  {
    title: 'Prix',
    render: (row) => Money.toString(row.finalPrice, true)
  },
  {
    title: 'Status',
    render: (row) => {
      const statusData = getDataById.intervention[row.status];
      return genChip(statusData);
    }
  },
];
