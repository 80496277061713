import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import DatePicker from '../DatePicker';
import { setDialogContentProp } from '../../actions/dialog';
import MoneyInput from '../CustomInputs/MoneyInput';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
const setRemiseDate = setDialogContentProp('remiseDate');
const setCommission = setDialogContentProp('commission');

const mapStateToProps = ({
  dialog: {
    contentProps: { montant, remiseDate, commission, isLydia, count },
  },
}) => ({
  montant,
  remiseDate,
  commission,
  isLydia,
  count,
});

const mapDispatchToProps = {
  setRemiseDate,
  setCommission,
};

class RemiseDialog extends PureComponent {
  render() {
    const {
      montant,
      commission,
      isLydia,
      count,
      remiseDate,
      setRemiseDate,
      setCommission,
    } = this.props;

    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Button
            fullWidth
            disableFocus
            disableFocusRipple
            disableRipple
            variant="outlined"
          >
            {`Nombres: ${count}`}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            disableFocus
            disableFocusRipple
            disableRipple
            fullWidth
            variant="outlined"
          >
            {`Montant remise: ${montant / 100}€`}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            disabled={!isLydia}
            before={true}
            label={'Date remise'}
            startDate={remiseDate}
            onChange={(v) => setRemiseDate(v.toDate())}
          />
        </Grid>
        <Grid item xs={12}>
          <MoneyInput
            disabled={!isLydia}
            label="Commission €"
            variant="outlined"
            value={commission}
            path={['Commission']}
            setData={(p, v) => setCommission(v)}
          />
        </Grid>
      </Grid>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemiseDialog);
