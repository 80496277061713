import { I_APR } from '@lba-dev/package.local-globals/iStatus';
import { List } from 'immutable';

export const modif = 1;
export const artisanChange = 2;
export const confirm = 3;
export const custom = 4;
export const multiReglement = 5;
export const validationRemise = 6;

export const objectDialog = {
  [modif]: ({ setDialog }) => ({
    title:
      'Vous ne pouvez pas modifier l\'artisan ' +
      'car l\'intervention est vérifiée ou a déjà été payée',
    subtitle:
      'Merci de contacter un chef de service ' +
      'pour effectuer la modification',
    callBack: () => setDialog(null, false, null, null),
    confirmBox: null,
    closeBox: 'Ok',
  }),
  [artisanChange]: ({ returnTo, updateData, save, setDialog }) => ({
    title:
      'Etes-vous sur de vouloir changer d\'artisan ' +
      'pour cette intervention ?',
    subtitle:
      'Si vous effectuez cette opération l\'intervention ' +
      'passera au statut "À Programmer"',
    callBack: (action) =>
      action === true
        ? updateData(['status'], I_APR, () => save(returnTo))
        : setDialog(null, false, null, null),
  }),
  [confirm]: ({ setDialog, cb, title, actionTitle }) => ({
    callBack: (checked, enable) =>
      setDialog({
        name: 'ConfirmDialog',
        open: true,
        dialogProps: {
          title: title || 'Etes-vous sûr de vouloir faire la validation ?',
          middle: true,
        },
        contentProps: {
          defaultText: {
            subtitle: ' ',
          },
        },
        actions: [
          {
            children: actionTitle || 'Valider',
            onClick: (props, close) => cb(props, close, checked, enable),
          },
        ],
      }),
  }),
  [custom]: ({ setDialog, cb, hasTextField = false, warningMessage = '' }) => ({
    callBack: () =>
      setDialog({
        name: 'ConfirmDialog',
        open: true,
        dialogProps: {
          title: 'Etes-vous sur de vouloir effectuer cette action ?',
          middle: true,
        },
        contentProps: {
          defaultText: {
            ...(hasTextField
              ? { subtitle: 'Saisir votre motif' }
              : { subtitle: ' ' }),
            hasTextField,
            warningMessage,
          },
        },
        actions: [
          {
            children: 'Valider',
            onClick: cb,
          },
        ],
      }),
  }),
  [multiReglement]: ({ setDialog, data, cb }) => ({
    callBack: () =>
      setDialog({
        name: 'ReglementNonIdentDialog',
        open: true,
        dialogProps: {
          title: 'Encaissement multi',
          middle: true,
          maxWidth: 'lg',
        },
        contentProps: {
          withOsField: true,
          encaissements: new List([data]),
          data: { id: data.id },
        },
        actions: [
          {
            children: 'Valider',
            onClick: cb,
          },
        ],
      }),
  }),
  [validationRemise]: ({ setDialog, cb }) => ({
    callBack: (param) =>
      setDialog({
        name: 'ValidationRemiseDialog',
        open: true,
        dialogProps: {
          title: 'Etes-vous sur de vouloir effectuer cette action ?',
          middle: true,
        },
        contentProps: {
          validationBq: 0,
        },
        actions: [
          {
            children: 'Valider',
            onClick: (props, close) => cb(props, close, param),
          },
        ],
      }),
  }),
};
