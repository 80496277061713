import React, { PureComponent } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

class Footer extends PureComponent {
  render() {
    const { send, cancel, addFile, loading } = this.props;
    return (
      <DialogActions>
        <input
          type="file"
          style={{ display: 'none' }}
          id="CloudUpload"
          onChange={addFile}>
        </input>
        <label htmlFor="CloudUpload">
          <Button color="inherit" component="span">
            Ajouter fichier
          </Button>
        </label>
        <Button onClick={cancel} color="secondary">
            Annuler
        </Button>
        <Button
          disabled={loading}
          onClick={send} color="primary">
          {loading && <CircularProgress
            size={24}
          />}
            Valider
        </Button>
      </DialogActions>
    );
  }
}

export default Footer;
