import React, { Component } from 'react';
import DOMPurify from 'dompurify';
import {
  formatHTMLToValue,
  formatTextToHTML,
  handlePastEvent
} from '../../../constant';

const placeCaretAtEnd = (el) => {
  el.focus();
  if (typeof window.getSelection !== 'undefined'
          && typeof document.createRange !== 'undefined') {
    let range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    let sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange !== 'undefined') {
    let textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
};

class TagInput extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange = () => {
    let html = this.inputRef.current.innerHTML;
    if (this.props.onChange && html !== this.lastHtml) {
      html = html.replace(/<br>|<div><br\/><\/div>/g, '\n');
      this.props.onChange({
        target: {
          value: formatHTMLToValue(html)
        }
      });
    }
    this.lastHtml = html;
  };

  componentDidMount() {
    handlePastEvent(this.handleChange);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value &&
        this.inputRef && this.inputRef.current) {
      const html = this.inputRef.current.innerHTML;
      this.inputRef.current.innerHTML = html
        .replace(/class="tagperson"/g, 'contenteditable="false" class="tagperson"');
      placeCaretAtEnd(this.inputRef.current);
    }
  }

  focus() {
    this.inputRef.current.focus();
  }

  render() {
    const { value, ...rest } = this.props;
    delete rest.type;
    delete rest.onChange;
    delete rest.onBlur;
    delete rest.onAnimationStart;
    const displayV = formatTextToHTML(value);
    return (
      <div
        {...rest}
        id="editableinput"
        contentEditable={true}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(displayV) }}
        ref={this.inputRef}
        onInput={this.handleChange}
        onBlur={this.handleChange}
        role="textbox"
        aria-label="Envoyez un message"
        placeholder="Envoyez un message"
      />
    );
  }
}

export default TagInput;
