import React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DialogTabs from './DialogTabs';
import InfoCombo from './InfoCombo';
import CaldeoData from './CaldeoData';
import Domofinance from '../DomofinanceDialog/Content';
import CaldeoMPR from './CaldeoMPR';
import { handlePreviewCaldeo } from '../../../actions/caldeo';
import CustomLoadingButton from '../../Utils/CustomLoadingButton';
import { Link } from '@mui/material';

const simulatorData = {
  link: 'https://france-renov.gouv.fr/aides/simulation#/',
  label: 'www.france-renov.gouv.fr',
};

const Components = ({
  tabValue,
  setData,
  paths,
  merge,
  errors,
  parentState,
  user,
  setLoading,
}) => [
  <CaldeoData
    key={0}
    merge={merge}
    paths={paths}
    setData={setData}
    errors={errors}
    user={user}
    {...parentState}
  />,
  <Domofinance
    key={1}
    merge={merge}
    setData={setData}
    errors={errors}
    paths={paths}
    user={user}
    isCaldeo
  />,
  <InfoCombo
    key={2}
    merge={merge}
    setData={setData}
    paths={paths}
  />,
  <CaldeoMPR
    key={3}
    merge={merge}
    setData={setData}
    setLoading={setLoading}
    {...parentState}
  />,
][tabValue];

const SimulatorErrorGrid = ({ simulationError }) =>
  simulationError ? <Grid item xs={12}>
    <Typography variant="body2" align="center" color='error'>
      {'⚠️ Aucune aide trouvé ⚠️'}
    </Typography>
    <Typography variant="body1" align="center">
      {'Vous pouvez refaire la simuation sur '}
      <Link
        target="_blank"
        href={simulatorData.link}
        children={simulatorData.label}
      />
    </Typography>
  </Grid> : null;

const handlePreview = (merge, choosenChaudiere, setLoadingPreview) => {
  setLoadingPreview(true);
  handlePreviewCaldeo(merge, choosenChaudiere)
    .finally(() => setLoadingPreview(false));
};

const Content = ({
  setData,
  tabValue,
  handleTabChange,
  paths,
  merge,
  errors,
  user,
  parentState = {},
  setLoading,
  setLoadingPreview,
  previewLoading,
  type,
  getSimulationResult,
}) => {
  const comboType = merge.getIn(['combination', 'comboType']);
  return (
    <div>
      <DialogTabs
        errors={errors}
        user={user}
        tabValue={tabValue}
        id={merge.get('id')}
        handleTabChange={handleTabChange}
      />
      <Grid container spacing={2}>
        <Components
          errors={errors}
          paths={paths}
          tabValue={tabValue}
          setData={setData}
          merge={merge}
          parentState={parentState}
          user={user}
          setLoading={setLoading}
        />
        <Grid item xs={12} sm={12}>
          {tabValue !== 3 && <CustomLoadingButton
            fullWidth
            loading={previewLoading}
            color="secondary"
            onClick={() =>
              handlePreview(
                merge,
                parentState.choosenChaudiere,
                setLoadingPreview
              )
            }
            variant="contained"
            children="visualiser le devis"
            disabled={type === 'devis' && !merge.get('id')}
          />}
        </Grid>
        {tabValue === 0 && (
          <>
            <Grid item xs={12} sm={12}>
              <CustomLoadingButton
                fullWidth
                color="primary"
                variant="outlined"
                loading={parentState.simulationLoading}
                children={'Simulateur des aides'}
                onClick={() => getSimulationResult()}
              />
            </Grid>
            <SimulatorErrorGrid
              simulationError={parentState.simulationError}
              comboType={comboType}
            />
          </>
        )}
      </Grid>
    </div>
  );
};

export default Content;
