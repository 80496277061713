import {
  POINTS_FETCH
} from '../constants/ActionTypes';

export function loadPoints(points) {
  return {
    type: POINTS_FETCH,
    points
  };
}
