import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { ExpandMore } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { Collapse } from '@mui/material';
import { Box } from '@mui/system';

import MessageContent from './MessageContent';
import MessageFooter from './MessageFooter';
import { DisplayName, RightIcons } from './MessageHeader';

const useStyles = makeStyles()((theme, { expanded }) => ({
  bubble: {
    padding: '0 15px 10px',
    borderRadius: 5,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  senderName: {
    whiteSpace: 'break-spaces',
    marginBottom: 0
  },
  addButtonDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  smsIcon: {
    border: '1px solid #000',
    color: '#000',
    borderRadius: 4,
    fontSize: 8,
    margin: 2,
    width: 20,
    textAlign: 'center'
  },
  done: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  expandIconBox: {
    cursor: 'pointer'
  },
  expandIcon: {
    transform: expanded ? 'rotate(180deg)' : '',
  }
}));

const MessageBubble = ({
  message, selectArtisan, transmitter, nameChat,
  redirect, handleExpand, dataObject, actualUser
}) => {
  const expanded = message.get('expanded', false);
  const sender = message.get('sender');
  const { classes } = useStyles({ expanded });
  const isAuto = message.get('isAuto') || transmitter === 'AUTO';
  const canSelect = !message.get('sender') &&
    message.get('intervention') &&
    message.get('msgType') === 'Accept';
  const type = (message.has('artisan') && 'artisan') ||
    (message.has('intervention') && 'intervention') ||
      (message.has('devis') && 'devis');
  return (
    <div className={classes.bubble}>
      <Box display='flex' height={40} flexWrap='wrap'
        justifyContent='space-between' alignItems='center'>
        <Box display='flex' sx={{
          width: `calc(100% - ${canSelect ? 67 : 20}px)`
        }}>
          <DisplayName
            {...{
              sender, classes, message,
              redirect, nameChat,
              isAuto, dataObject, type
            }}
          />
          <Box flex={1} className={classes.expandIconBox}
            onClick={() => handleExpand(message.get('_id'))}>
            <Tooltip title={!expanded ? 'Ouvrir' : 'Fermer'}>
              <ExpandMore className={classes.expandIcon} />
            </Tooltip>
          </Box>
        </Box>
        <RightIcons
          classes={classes}
          selectArtisan={selectArtisan}
          message={message}
          canSelect={canSelect}
        />
      </Box>
      <Collapse in={expanded}>
        <MessageContent
          classes={classes}
          message={message}
          dataObject={dataObject}
          user={actualUser}
          type={type}
        />
        <MessageFooter classes={classes} message={message} />
      </Collapse>
    </div>

  );
};

export default MessageBubble;
