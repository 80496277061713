import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import MinCustomDialog from './MinCustomDialog';
import Button from '@mui/material/Button';

const styles = {
  divContainer: {
    width: '100%'
  },
  subject: {
    margin: '5% 0'
  }
};

class MailDialogs extends Component {
  state = {
    body: this.props.defaultText.body,
    subject: this.props.defaultText.subject,
  };

  updateState = (name, value) => {
    this.setState({ [name]: value });
  }

  render() {
    const {
      callback,
      defaultText: { title, checked = [] },
      classes,
      children,
    } = this.props;
    const { body, subject } = this.state;
    return (
      <MinCustomDialog
        middle
        maxWidth="md"
        open
        title={title}
        onClose={() => callback(false)}
        actions={[
          <Button key={2} color="secondary" onClick={() => callback(false)}>
            Fermer
          </Button>,
          <Button
            key={1}
            color="primary"
            onClick={() => callback(true, body, subject, checked)}
          >
            Envoyer
          </Button>,
        ]}
      >
        <div className={classes.divContainer}>
          <TextField
            variant="standard"
            className={classes.subject}
            fullWidth
            onChange={e => this.updateState('subject', e.target.value)}
            value={subject}
            label="Objet du mail" />
          <TextField
            variant="standard"
            fullWidth
            onChange={e => this.updateState('body', e.target.value)}
            value={body}
            multiline={true}
            maxRows={40}
            label="Corps du mail" />
        </div>
        {children}
      </MinCustomDialog>
    );
  }
}

MailDialogs.propTypes = {
  callback: PropTypes.func.isRequired,
  defaultText: PropTypes.object
};

export default withStyles(MailDialogs, styles);
