import {
  CONFPROSP_FETCH
} from '../constants/ActionTypes';
import DbWsAction from './models/DbWsAction';

const action = new DbWsAction(
  CONFPROSP_FETCH
);

export const fetch = action.fetch;
