import React, { PureComponent } from 'react';
import Dialog from '@mui/material/Dialog';
import Content from './Content';

class DialogEnvoiInter extends PureComponent {
  render() {
    const { open, defaultText, callback } = this.props;
    return (
      <Dialog open={open} onClose={this.onClose}
        maxWidth="md" fullWidth>
        <Content data={defaultText.data} callback={callback} />
      </Dialog>);
  }
}

export default (DialogEnvoiInter);
