import React, { Component } from 'react';

import formatProject from '@lba-dev/package.local-globals/formatProject';

import api from '../../api';
import List from './List';
import ListRow from './ListRow';
import notifSystem from '../../notifSystem';

class ListLogic extends Component {
  state = {
    display: this.props.count || 25,
    sort: this.props.sort,
    page: 0,
    list: [],
    table: this.props.table,
    tableData: this.props.tableData,
    keys: this.props.keys,
    checkType: this.props.checkType,
    functionType: this.props.functionType,
    filter: {},
  };

  componentDidMount() {
    this.load(this.props.filter);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.filter !== this.props.filter ||
      JSON.stringify(nextState) !== JSON.stringify(this.state) ||
      JSON.stringify(nextProps.selectedForCampaign) !==
      JSON.stringify(this.props.selectedForCampaign)
    );
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { display, filter, sort } = this.state;
    let newState = {};
    if (
      JSON.stringify(nextProps.filter) !== JSON.stringify(this.props.filter)
    ) {
      let query = {};
      if (nextProps.filter && Object.keys(nextProps.filter).length > 0) {
        query = nextProps.filter;
      }
      newState = {
        ...newState,
        page: 0,
        filter: query,
      };
    }
    if (nextProps.count !== this.props.count) {
      newState = {
        ...newState,
        page: 0,
        display: nextProps.count,
      };
    }
    if (JSON.stringify(nextProps.sort) !== JSON.stringify(this.props.sort)) {
      newState = {
        ...newState,
        sort: nextProps.sort,
      };
    }
    if (Object.keys(newState).length !== 0) {
      this.setState({
        ...newState,
        ...(await this.updateTableData(
          {
            display: newState.display || display,
            filter: newState.filter || filter,
            sort: newState.sort || sort,
            page: 0
          },
          true
        )),
      });
    }
  }


  loadSeg = async (display, page, filter, sort) => {
    const {
      collection,
      keys,
      project = keys,
      formatItem,
      customReq,
    } = this.props;
    const query = {
      method: 'seg',
      display: display,
      page: page,
      query: JSON.stringify(filter),
      sort: sort || { id: -1 },
    };

    if (project) {
      query.field = JSON.stringify(formatProject(project));
    }
    let req = api[collection].getAll;
    if (customReq) {
      req = customReq;
    }
    const resp = await req(query);
    return resp.statusCode() === 204 ? [] : resp
      .body()
      .map((e) =>
        typeof formatItem === 'function' ? formatItem(e.data()) : e.data()
      );
  };

  updateTableData = async ({ display, page, filter, sort }, reloadC) => {
    let count = this.state.count;
    const newFilter = filter ? filter : this.state.filter;
    const newSort = sort ? sort : this.state.sort;
    let list = await this.loadSeg(
      display,
      page,
      newFilter,
      newFilter && Object.keys(newFilter).length ? newSort : { _id: -1 },
    );
    if (reloadC) {
      count = await this.loadCount(newFilter);
    }
    list = !list.length ? null : list;
    return {
      display,
      page,
      list,
      filter,
      count,
    };
  };

  updateTable = async (display, page, filter, reloadC) => {
    const { sort } = this.state;
    this.setState(
      await this.updateTableData(
        {
          display,
          page,
          filter,
          sort,
        },
        reloadC
      )
    );
  };

  updateUnique = async (id) => {
    const { filter } = this.state;
    try {
      const list = [...this.state.list];
      const oldElement = list.find((elem) => elem.id === id || elem._id === id);
      const element =
        oldElement &&
        (await api.interventions
          .getAll({
            query: JSON.stringify({ ...filter, _id: oldElement._id }),
            limit: 1,
          })
          .then((e) => e.body().map((e) => e.data())[0]));
      if (element) {
        if (
          oldElement &&
          JSON.stringify(oldElement) !== JSON.stringify(element)
        ) {
          this.setState({
            list: list.map((elem) =>
              element._id === elem._id ? element : elem
            ),
          });
        }
      } else if (oldElement) {
        this.load(filter);
      }
    } catch (e) {
      notifSystem.error('Erreur', e.message);
    }
  };

  loadCount = async (filter) => {
    const { collection } = this.props;
    const res = await api[collection].get('', {
      call: 'countDocuments',
      query: JSON.stringify(filter),
    });
    return res.statusCode() === 200 ? res.body().data() : 0;
  };

  load = async (filter) => {
    const { display, page, sort } = this.state;
    const list = await this.loadSeg(
      display,
      page,
      filter || {},
      Object.keys(filter || {}).length ? sort : { _id: -1 }
    );

    this.setState({
      list: list.length ? list : null,
      count: await this.loadCount(filter ? filter : {}),
      filter,
    });
  };

  render() {
    const {
      name,
      listen,
      handlerFilterByColumns,
      filteredByColumn,
      actions,
      menu,
      small = false,
      setSelectedForCampaign,
      selectedForCampaign
    } = this.props;

    return (
      <List
        filteredByColumn={filteredByColumn}
        handlerFilterByColumns={handlerFilterByColumns}
        name={name}
        actions={actions}
        menu={menu}
        row={ListRow}
        listen={listen}
        updateUnique={this.updateUnique}
        updateTable={this.updateTable}
        small={small}
        parent={this.state}
        setSelectedForCampaign={setSelectedForCampaign}
        selectedForCampaign={selectedForCampaign}
      />
    );
  }
}

export default ListLogic;
