import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import CustomCheckbox from '../../CustomInputs/CustomCheckbox';

const styles = {
  root: {
    flexGrow: 1,
    marginBottom: 5,
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  },
  ListItem: {
    backgroundColor: '#8080805c',
  },
  button: {
    margin: 2,
    color: 'white'
  },
  Collapse: {
    textAlign: 'center'
  }
};

class ShowPrice extends PureComponent {
  state = {
    open: true
  }

  openCollapse = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { classes, handleChangePrice, showPrice } = this.props;
    return (
      <List
        component="nav"
        className={classes.root}>
        <ListItem button onClick={this.openCollapse}
          className={classes.ListItem} >
          <ListItemText inset primary="Prix" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
          className={classes.Collapse}
          in={this.state.open} timeout="auto" unmountOnExit
        >
          <CustomCheckbox
            label="Afficher prix"
            checked={!!showPrice}
            path="showPrice"
            setData={(p, v) => handleChangePrice(v)}
          />
        </Collapse>
      </List>
    );
  }
}

export default withStyles(ShowPrice, styles);
