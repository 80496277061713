import { ringover } from './keys';
import notifSystem from '../notifSystem';

const detectExtension = () => new Promise(
  (resolve) => {
    let img;
    img = new Image();
    img.src = `chrome-extension://${ringover}/assets/img/lesbonsartisansdef.png`;
    img.onload = function() {
      return resolve(true);
    };
    img.onerror = function() {
      return resolve(false);
    };
  }
);

export const call = async number => {
  const hasExtension = await detectExtension();
  if (hasExtension) {
    if ((window.chrome || {}).runtime) {
      return window.chrome.runtime.sendMessage(ringover, {
        tel: number,
      });
    }
  } else {
    if (!localStorage.getItem('firstCall')) {
      localStorage.setItem('firstCall', true);
      return window.open(`callto:${number}`);
    }
    const popUp = window.open(
      `callto:${number}`,
      'callTo',
      'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,' +
        `left=${screen.width * 2}, top=10000, width=10, height=10, visible=none`
    );
    if (popUp) {
      popUp.document
        .write('<script>setTimeout(() => window.close(), 500)</script>');
    } else {
      notifSystem.error('Message', 'Action appel erreur');
    }
  }
};


export const getClientNumbers = (elem, field = 'client') => {
  if (elem.billing) {
    field = ['client', 'billing'];
    return field.map(elemField => ['tel1', 'tel2', 'tel3']
      .filter(e => elem[elemField][e])
      .map(tel => elem[elemField][tel]));
  }
  return ['tel1', 'tel2', 'tel3']
    .filter(e => elem[field][e])
    .map(tel => elem[field][tel]);
};
