import React from 'react';
import { setAssurance } from '../../actions/artisan';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import stopPropagation from '../../utils/stopPropagation';
import { connect } from 'react-redux';


const mapStateToProps = ({ userId, users }) => ({
  user: users.find(e => e._id === userId),
});

export default connect(mapStateToProps)(({
  user,
  artisan: { _id, noAssurance = false },
  setData = null,
}) =>
  permit(user, { key: 'partenariat' }) && (
    <FormControlLabel
      control={
        <span
          onClick={stopPropagation(() =>
            setData ? setData() : setAssurance(_id, !noAssurance)
          )}
          children={
            <Switch
              checked={noAssurance}
              color={noAssurance ? 'secondary' : 'primary'}
            />
          }
        />
      }
      label={
        'Le sst n\'a pas d\'assurance'
      }
    />
  ));
