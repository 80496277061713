import React, { Component } from 'react';

import { withStyles } from 'tss-react/mui';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import {
  Close,
  ChevronLeft
} from '@mui/icons-material';

import MinCustomDialog from './MinCustomDialog';

const styles = {
  inline: {
    display: 'inline',
    marginLeft: 10
  }
};

const defaultState = ({ data = null, step = null }) =>
  data
    ? { history: [{ data, step }] }
    : { history: [] };

class McDoStepper extends Component {
  state = defaultState(this.props)

  setData = (data, step) => this.setState(({ history }) => ({
    history: history.concat({ data, step })
  }))

  rewind = () => this.setState(({ history }) => ({
    history: history.slice(0, -1)
  }), () => this.props.onRewind &&
    this.props.onRewind(this.state.history))

  renderHeader = title => {
    const { history } = this.state;
    const { classes, onClose } = this.props;

    return history.length > 1 || onClose
      ? <React.Fragment>
        {history.length > 1
          ? <IconButton onClick={this.rewind} children={<ChevronLeft />}
            size="large" />
          : <IconButton onClick={onClose} children={<Close />}
            size="large" />}
        <div className={classes.inline} children={title} />
      </React.Fragment>
      : title;
  }

  render() {
    const { history } = this.state;
    const { data, step } = history[history.length - 1] || {};
    const props = this.props.generateDialogProps(step) || {};
    const {
      generateContent,
      dialog = true,
      customHeader = e => e,
      children = generateContent(data, step, this.setData),
      title = this.renderHeader(props.title)
    } = this.props;

    return dialog
      ? <MinCustomDialog
        middle
        maxWidth="lg"
        open
        {...props}
        customHeader={customHeader && customHeader(this.setData)}
        title={title}
        children={children}
      />
      : <Card>
        <CardHeader title={title} />
        <CardContent children={children} />
      </Card>;
  }
}

export default withStyles(McDoStepper, styles);
