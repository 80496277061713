import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classnames from 'classnames';

import { withStyles } from 'tss-react/mui';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import Divider from '@mui/material/Divider';
import {
  Phone, Search, LockOpen, Lock, Menu, MoreVert, Mms
} from '@mui/icons-material';

import company from '@lba-dev/package.local-globals/company';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import { SHOW_ENR } from '@lba-dev/package.local-globals/configTypes';

import { withDisplaying, withMediaQuery } from '../../../hoc';
import api from '../../../api';
import { logout } from '../../../actions/auth';
import notifSystem from '../../../notifSystem';
import SearchNumber from '../../../views/Cti/Components/Dialog/SearchNumber';
import NotificationContainer from '../../../containers/NotificationContainer';
import Selects from './Selects';
import IconsConnect from '../../Utils/IconsConnect';
import {
  PHONE_REGEX, NUMBER_REGEX
} from '@lba-dev/package.local-globals/regexps';
import ws from '../../../ws';
import { S_INT, S_LEAD } from '@lba-dev/package.local-globals/services';
import NewVersionButton from './NewVersionButton';
import withRouter from '../../../hoc/withRouter';

const styles = theme => ({
  companyName: {
    minWidth: 172,
    marginRight: 20,
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      minWidth: 50,

    },
  },
  selectsDiv: {
    flex: 1,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
      flex: 'initial',
      textAlign: 'center'
    }
  },
  marginLogin: {
    margin: '0 10px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '15px auto 5px'
    }
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  searchInput: {
    borderRadius: 30,
    background: 'rgba(255,255,255,.2)',
    padding: '0 12px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 20px 10px'
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'contents',
    },
  },
  sectionDesktopWithoutApp: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    },
  },
  sectionMobile: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
  },
  mobileMenu: {
    position: 'absolute',
    display: 'grid',
    width: '100%',
    top: '56px',
    zIndex: 3,
    backgroundColor: '#2196f3',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  divider: {
    marginTop: 10
  },
  phoneButton: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  appBarPos: {
    position: 'relative'
  },
  flexJBetween: {
    justifyContent: 'space-between',
  },
});

const mapStateToProps = ({
  users,
  userId,
  config,
  connect: {
    isActiveSession
  },
}) => ({
  user: users.find(u => u._id === userId),
  showRecords: config.some(c => c.type === SHOW_ENR),
  isActiveSession,
});

const mapDispatchToProps = dispatch => ({
  logout: (user) => dispatch(logout(ws, user))
});

export class MenuBar extends PureComponent {
  state = {
    isMobileMenuOpen: false,
    search: true,
    openDialog: false,
    input: '',
  }

  updateSearch = event => {
    const { search } = this.state;
    const { user, navigate } = this.props;
    const input = event.target.value;
    if (event.key === 'Enter' && input.length >= 3) {
      if (!search) {
        return PHONE_REGEX.test(input.replace(/ /g, '')) ?
          this.setState({ openDialog: true }) :
          notifSystem.error(
            'Erreur',
            'Seuls les numéros sont acceptés pour la recherche d\'audio'
          );
      }
      return permit(user, { key: 'accesExterne' }) || NUMBER_REGEX.test(input.replace(/ /g, ''))
        ? navigate(`/search/${input}`)
        : notifSystem.error(
          'Erreur',
          'Seuls les numéros sont acceptés pour la recherche d\'audio'
        );
    }
  }

  handleMobileMenu = () =>
    this.setState(({ isMobileMenuOpen }) => ({
      isMobileMenuOpen: !isMobileMenuOpen
    }))

  handleChangeSearch = () =>
    this.setState(({ search }) => ({ search: !search }));

  handleChange = event =>
    this.setState({
      [event.target.name]: event.target.value,
      openDialog: false
    })

  handleClickMms = () => {
    this.props.navigate(`/mms/recipient=${this.props.user.login}`);
  }

  toggleAudio = () => api
    .config
    .custom('updateConfig')
    .post({ type: SHOW_ENR }).then(() => notifSystem.success(
      'Opération réussie',
      'Interface mise à jour'
    ))
    .catch(() => notifSystem.error(
      'Erreur',
      'Impossible de mettre à jour l\'interface'
    ));

  closeDialog = () => this.setState({ openDialog: false })

  renderDesk = () => {
    const {
      classes,
      user,
      logout,
      disableAudioSearch,
      disableListes,
      showRecords,
      isActiveSession,
      appBar,
    } = this.props;
    const { search } = this.state;
    return (
      <div className={appBar ?
        classes.sectionDesktop :
        classes.sectionDesktopWithoutApp
      }>
        {appBar && [
          <div key="select" className={classes.selectsDiv}>
            {!disableListes && [
              <Selects
                key={0}
                dataType="interData"
                type="Inter"
                path="intervention"
              />,
              <Selects
                key={1}
                dataType="devisData"
                type="Devis"
                path="devis"
              />,
            ]}
          </div>,
          <NewVersionButton key="newVersionButton" />,
          <Input
            placeholder={search ? 'Taper votre recherche' : 'Chercher un audio'}
            disableUnderline
            color="primary"
            onKeyPress={this.updateSearch}
            className={classes.searchInput}
            onChange={this.handleChange}
            inputProps={{ name: 'input' }}
            key={'searchInput'}
          />,
          disableAudioSearch ? (
            <Search
              key="searchIcon"
              className={classnames(classes.phoneButton)}
            />
          ) : (
            <IconButton
              onClick={this.handleChangeSearch}
              className={classes.phoneButton}
              children={search ? <Phone /> : <Search />}
              key="searchIcon"
              size="large" />
          ),
          <NotificationContainer type={['internal', 'popUp']} key="internal" />,
          <NotificationContainer type="mail" key="mail" />,
          <NotificationContainer type="email" key="email" />,
          <NotificationContainer type="sms" key="sms" />,
          [S_INT, S_LEAD].includes(user.service) && <IconButton
            onClick={this.handleClickMms}
            className={classes.phoneButton}
            children={ <Mms />}
            key="mmsIcon"
            size="large" />,
          permit(user, { key: 'toggleAudio' }) && (
            <IconButton
              key="toggleAudio"
              onClick={this.toggleAudio}
              className={classes.phoneButton}
              size="large">
              {showRecords ? <LockOpen /> : <Lock />}
            </IconButton>
          ),
        ]}
        {user && [
          <Typography
            key={'login'}
            color="inherit"
            className={classes.marginLogin}
            children={user.login}
          />,
          <IconsConnect key={'icon'} user={{ isActiveSession }} />,
        ]}
        <Button color="inherit" onClick={() => logout(user)} children="Logout"/>
      </div>
    );
  }

  renderMobile = () => {
    const { classes, user, logout, disableListes } = this.props;
    return (
      <div className={classes.mobileMenu}>
        <Divider />
        <Input
          placeholder="Rechercher"
          disableUnderline
          color="inherit"
          onKeyPress={this.updateSearch}
          className={classes.searchInput}
          onChange={this.handleChange}
          inputProps={{ name: 'input' }}
        />
        <Divider />
        <div className={classes.selectsDiv}>
          {!disableListes && [
            <Selects
              key={0}
              dataType='interData'
              type='Inter'
              path='intervention'
            />,
            <Selects
              key={1}
              dataType='devisData'
              type='Devis'
              path='devis'
            />
          ]}
        </div>
        <Divider className={classes.divider} />
        {user && (
          <Typography
            color="inherit"
            className={classes.marginLogin}
            children={user.login}
          />
        )}
        <Button
          color="inherit"
          onClick={() => logout(user)}
          children="Logout"
        />
      </div>
    );
  }

  render() {
    const {
      isMobileMenuOpen,
      openDialog,
      input
    } = this.state;
    const {
      classes,
      toogle,
      appBar
    } = this.props;
    const isDesk = this.props.isUp.md;
    const isMobile = this.props.isDown.sm;
    return (
      <React.Fragment>
        <AppBar
          position="static"
          className={classes.appBarPos}
          enableColorOnDark
        >
          <Toolbar className={classes.flexJBetween}>
            {appBar && (
              <IconButton
                className={classes.menuButton}
                onClick={toogle}
                color="inherit"
                aria-label="Menu"
                size="large"
              >
                <Menu />
              </IconButton>
            )}
            <Link className={classes.link} to={'/dashboard'}>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.companyName}
              >
                {isMobile ? (
                  'LBA'
                ) : (
                  company.name
                )}
              </Typography>
            </Link>
            {this.renderDesk()}
            {appBar && (
              <div className={classes.sectionMobile}>
                <NotificationContainer type="internal" />
                <NotificationContainer type="mail" />
                <NotificationContainer type="sms" />
                <NotificationContainer type="email" />
                <IconButton
                  aria-haspopup="true"
                  onClick={this.handleMobileMenu}
                  color="inherit"
                  size="large"
                >
                  <MoreVert />
                </IconButton>
              </div>
            )}
          </Toolbar>
          {!isDesk && isMobileMenuOpen && this.renderMobile()}
        </AppBar>

        {openDialog && (
          <SearchNumber
            open={openDialog}
            closeDisagreeListener={this.closeDialog}
            number={input.replace(/ /g, '')}
          />
        )}
      </React.Fragment>
    );
  }
}

MenuBar.displayName = 'MenuBar';

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withMediaQuery(),
  withDisplaying()
)(withStyles(MenuBar, styles));
