import api from '../api';
import { finalKeysFields } from '../constants/configurateur';
import store from '../store';
import { setDialog } from './dialog';

export const finalKeys = {
  combinations: 'isProduct',
  metiersSteps: 'isFinalStep',
  metiersDevis: 'isFinalStep',
};

export function copyItem(contentProps) {
  return store.dispatch(
    setDialog({
      name: 'DialogConfigurateurCopy',
      open: true,
      hideClose: true,
      dialogProps: { maxWidth: 'lg' },
      contentProps: contentProps,
    })
  );
}

const getFields = (dataType) =>
  finalKeys[dataType] && finalKeysFields[dataType];

export function dupliqFieldsDialog({ elem, dataType, cb, copyFrom }) {
  const originalFields = getFields(dataType);
  const fields = originalFields && originalFields.filter(Boolean);
  if (!elem[finalKeys[dataType]]) {
    return copyItem({ elem, dataType, cb, copyFrom });
  }
  return store.dispatch(
    setDialog({
      name: 'DupliqItemsDialog',
      open: true,
      hideClose: true,
      dialogProps: {
        maxWidth: 'sm',
        title: 'Elements à copier'
      },
      contentProps: {
        dataType,
        data: originalFields,
        fields: fields,
        checkedFields: originalFields.map(b => b.key),
      },
      actions: [
        {
          children: 'Annuler',
          color: 'secondary',
          onClick: (data, close) => close()
        },
        {
          children: 'Valider',
          color: 'primary',
          onClick: ({
            fields,
            checkedFields = fields.map(b => b.key)
          }) => {
            const data = Object.keys(elem).filter(e =>
              !fields.map(b => b.key).includes(e))
              .reduce((a, b) => ({ ...a, [b]: elem[b] }),
                checkedFields.reduce((n, c) => ({ ...n, [c]: elem[c] }), {}));
            copyItem({
              elem: data,
              dataType,
              cb,
              copyFrom
            });
          }
        },
      ],
    })
  );
}

export async function copyMasterFields({ data, send, dataType }) {
  const ids = data.map((e) => e._id);
  const elements = await api[dataType]
    .getAll({
      query: JSON.stringify({ masterId: { $in: ids } }),
      field: JSON.stringify({
        masterId: 1,
        _id: 1,
        name: 1,
        categorie: 1,
        path: 1,
      }),
    })
    .then((e) => e.body().map((e) => e.data()));
  if (!elements.length) {
    return send();
  }

  data = data.map((d) => ({
    ...d,
    childs: elements.filter((e) => e.masterId === d._id),
  }));

  return store.dispatch(
    setDialog({
      name: 'CopyMasterDialog',
      open: true,
      hideClose: true,
      dialogProps: {
        maxWidth: 'sm',
        title: 'Les combos affectés',
      },
      contentProps: {
        data,
        checkedCombos: elements.map((e) => e._id),
      },
      actions: [
        {
          children: 'Annuler',
          color: 'secondary',
          onClick: (data, close) => close(),
        },
        {
          children: 'Enregistrer',
          color: 'primary',
          onClick: ({ checkedCombos = elements.map((e) => e._id) }, close) => {
            send(checkedCombos);
            close();
          },
        },
      ],
    })
  );
}

export const getOrder = (orders, job) =>
  (orders || []).find(o => o.categorie === job)?.order;

export const getOrderCat = (elem, jobTab) => {
  let orderCat = jobTab;
  if (elem.ref !== null && (elem.categories || [])[0] !== jobTab) {
    orderCat = elem.categorie;
  }
  return orderCat;
}

export const getLastOrder = (data, jobTab) => {
  data = data.sort((a, b) => {
    const orderCat = getOrderCat(b, jobTab);
    const aa = getOrder(a.orders, orderCat);
    const bb = getOrder(b.orders, orderCat);
    return aa - bb;
  });
  const lastElem = data[data.length - 1];
  const orderCat = lastElem && getOrderCat(lastElem, jobTab);

  return lastElem ? getOrder(lastElem?.orders, orderCat) : data.length;
}

const getNewOrderValue = (jobTab, orders, newOrder) =>
  orders.updateOrInsert({
    categorie: jobTab,
    order: newOrder
  }, e => e.categorie === jobTab);

export const getOrderWithIndex = (elemIndex, actualCat, data, type) => {
  const elem = data[elemIndex];
  const catOrder = elem.orders.find(e => e.categorie === actualCat)?.order
    || (elemIndex + 1);

  if (type === 'next' && elemIndex < data.length - 1) {
    const nextElem = data[elemIndex + 1];
    const prevElem = data[elemIndex - 1];
    const prevOrder = prevElem && (prevElem.orders
      .find(e => e.categorie === actualCat)?.order || (catOrder - 1));
    return prevElem && prevOrder + 1 !== catOrder ? {
      elem: {
        order: getNewOrderValue(actualCat, elem.orders, prevOrder + 2),
        orders: elem.orders,
        id: elem.id
      },
      otherElem: {
        order: getNewOrderValue(actualCat, nextElem.orders, prevOrder + 1),
        id: nextElem.id
      }
    } : {
      elem: {
        order: getNewOrderValue(actualCat, elem.orders, catOrder + 1),
        id: elem.id
      },
      otherElem: {
        order: getNewOrderValue(actualCat, nextElem.orders, catOrder),
        id: nextElem.id
      }
    }
  } else if (type === 'prev' && elemIndex > 0) {
    const prevElem = data[elemIndex - 1];
    const prevOrder = prevElem.orders
      .find(e => e.categorie === actualCat)?.order || (catOrder - 1);
    return {
      elem: {
        order: getNewOrderValue(actualCat, elem.orders, prevOrder),
        id: elem.id
      },
      otherElem: {
        order: getNewOrderValue(actualCat, prevElem.orders, prevOrder + 1),
        id: prevElem.id
      }
    }
  }
}
