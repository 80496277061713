import React from 'react';
import { connect } from 'react-redux';

import Grid from '@mui/material/Grid';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import { setDialogContentProp, closeDialog } from '../../../actions/dialog';
import Content from './Content';
import green from '@mui/material/colors/green';
import CircularProgress from '@mui/material/CircularProgress';
import { H_CTC } from '@lba-dev/package.local-globals/lateReasons';

const styles = {
  center: {
    textAlign: 'center',
  },
  margin: {
    margin: '10px 0 20px'
  },
};

const setPrice = (price) =>
  setDialogContentProp('price')(Number(price.toFixed(0)));
const setPaymentChoice = setDialogContentProp('paymentChoice');
const setDateIntervention = setDialogContentProp('dateIntervention');
const setRepairType = setDialogContentProp('repairType');

const toggleTva = setDialogContentProp('applyTva');
const setAdditionnalInfos = setDialogContentProp('additionnalInfos');
const setPaymentReceived = setDialogContentProp('paymentReceived');
const setAttachments = setDialogContentProp('attachments');
const setLateReason = setDialogContentProp('lateReason');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      showOnlyPrice,
      price,
      tva,
      applyTva,
      paymentChoice,
      additionnalInfos,
      callbackNoPayment,
      noPaymentReceived,
      paymentReceived = false,
      attachments,
      loading = false,
      productsSum,
      lateReason = H_CTC,
      dateIntervention,
      repairType = 1,
    },
  },
}) => ({
  showOnlyPrice,
  price,
  applyTva,
  tva,
  paymentChoice,
  additionnalInfos,
  callbackNoPayment,
  noPaymentReceived,
  paymentReceived,
  attachments,
  loading,
  productsSum,
  lateReason,
  dateIntervention,
  repairType,
});

const mapDispatchToProps = {
  setPrice,
  setPaymentChoice,
  setDateIntervention,
  setRepairType,
  toggleTva,
  setAdditionnalInfos,
  setPaymentReceived,
  setLateReason,
  addFile: (f, files) => setAttachments(files),
  deleteFile: (f, i, files) => setAttachments(files),
  closeDialog: closeDialog,
};

const mergeProps = (state, dispatchers) => ({
  ...state,
  ...dispatchers,
  toggleTva: () => {
    const { applyTva, price, tva } = state;
    const rate = 1 + (tva / 100);

    dispatchers.toggleTva(!applyTva);
    dispatchers.setPrice(applyTva ? price / rate : price * rate);
  },
  callbackNoPayment: (noPayment) => {
    if (noPayment) {
      state.callbackNoPayment();
      dispatchers.closeDialog();
    }
  },
});

const CompleteVerif = ({
  callbackNoPayment,
  classes,
  noPaymentReceived,
  paymentReceived,
  loading,
  ...props
}) => loading ? <CircularProgress size={24} /> : (
  <Grid container spacing={1} alignItems="center">
    {!noPaymentReceived && !paymentReceived ? (
      <>
        <Grid xs={12} className={`${classes.center} ${classes.margin}`}>
          <Button
            color="secondary"
            variant="outlined"
            fullWidth
            children={
              'Le travail a été effectué, ' +
              'mais le règlement n\'a pas été récupéré'
            }
            onClick={() => callbackNoPayment(true)}
          />
        </Grid>
        <Grid xs={12} className={classes.center}>
          <Button
            color="inherit"
            variant="outlined"
            style={{ color: green[500], borderColor: green[500] }}
            fullWidth
            children={
              'Le travail a été effectué, ' +
              'et l\'artisan a récupéré le règlement'
            }
            onClick={() => props.setPaymentReceived(true)}
          />
        </Grid>
      </>
    ) : (
      <Content {...props} />
    )}
  </Grid>
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(CompleteVerif, styles));
