import React, { Component } from 'react';
import CommentList from './CommentList';
import ActivityList from './ActivityList';
import CallList from './CallList';
import Grid from '@mui/material/Grid';

export const Types = {
  Comments: 0,
  Activities: 1,
  Calls: 2,
};


const Data = [
  { title: 'Commentaires', content: CommentList },
  { title: '', content: ActivityList },
  { title: '', content: CallList }
];

class ListInfo extends Component {

  render() {
    const {
      type,
      artisanId,
      markerList,
      setComment
    } = this.props;
    const Content = Data[type].content;
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          {Data[type].title}
        </Grid>
        <Content
          setComment={setComment}
          artisanId={artisanId}
          markerList={markerList}/>
      </Grid>
    );
  }
}

export default ListInfo;
