import React, { useRef, useLayoutEffect, useMemo } from 'react';

const withKeepScrollPosition = WrappedComponent => (props) => {
  const containerRef = useRef(null);
  const previousScrollPosition = useRef(0);

  useMemo(() => {
    if (containerRef?.current) {
      const container = containerRef?.current;
      previousScrollPosition.current =
        container?.scrollHeight - container?.scrollTop;
    }
  }, [[...props.data]]);

  useLayoutEffect(() => {
    if (containerRef?.current) {
      const container = containerRef?.current || {};
      container.scrollTop =
        container?.scrollHeight - previousScrollPosition.current;
    }
  }, [[...props.data]]);

  return <WrappedComponent
    {...props}
    containerRef={containerRef}
  />;
};

export default withKeepScrollPosition;
