import {
  PRODUCTS_FETCH,
  PRODUCTS_UPDATE
} from '../constants/ActionTypes';
import DbWsAction from './models/DbWsAction';
import { setDialog } from './dialog';
import notifSystem from '../notifSystem';
import Money from '../utils/Money';
import api from '../api';

const action = new DbWsAction(
  PRODUCTS_FETCH,
  PRODUCTS_UPDATE
);

export const fetch = action.fetch;
export const update = action.update;

export const modifyPrice = (product, cb) =>
  setDialog({
    name: 'InputDialog',
    open: true,
    hideClose: true,
    dialogProps: {
      title: 'Modification de prix',
      middle: true,
      fullScreenOn: false,
    },
    contentProps: {
      subTitle: `Le montant saisi doit être supérieur de ${Money.toString(
        product.get('pu', 0),
        true
      )}`,
    },
    actions: [
      {
        children: 'Valider',
        color: 'primary',
        onClick: (validate, close) => {
          if (validate.price && validate.price > product.get('pu')) {
            cb(validate);
            return close();
          }
          return notifSystem.error('Erreur', 'Le montant saisi est incorrect');
        },
      },
    ],
  });


export const increasePrices = async (data) => {
  try {
    await api.config.custom('increasePrices').post(data);
    notifSystem.success('Message', 'Prix mis à jour');
  } catch (error) {
    notifSystem.error('Message', 'Les prix n\'ont pas pu être mis à jour');
  }
};
