import { modelMarkers } from './fragments';

export default {
  statusModal: false,
  viewModal: false,
  artisans: modelMarkers,
  interventions: [],
  cities: [],
  citiesFiltered: [],
  columns: [],
  markers: modelMarkers,
  filter: {
    dept: '',
    city: '',
    zipcode: '',
    categorie: '',
    dateStart: new Date(),
    dateEnd: new Date(),
  },
  resultFilteredTab: [],
  addresstoInput: {
    city: {},
    distance: '15'
  },
  modalList: {
    data: [],
    statusModalViewListTab: false,
  },
  currentCityEdited: {},
  updateDistCity: '',
  markerShowToMap: [],
  listSpecificArtisans: [],
  stateLoading: true,
  expanded: '',
  filterByColumn: {
    status: false,
    currentCol: '',
    styl: ''
  },
  listActivitiesArtisan: []
};
