import notifStatus from './constants/notifStatus';

class Notifs {
  constructor() {
    this.count = 0;
    this.subscriber = null;
    this.notifs = [];
  }
  critical(title, msg, duration) {
    return this.send({ title, msg, type: notifStatus.critical, duration });
  }
  error(title, msg, duration) {
    return this.send({ title, msg, type: notifStatus.error, duration });
  }
  warning(title, msg, duration) {
    return this.send({ title, msg, type: notifStatus.warning, duration });
  }
  success(title, msg, duration) {
    return this.send({ title, msg, type: notifStatus.success, duration });
  }
  info(title, msg, duration) {
    return this.send({ title, msg, type: notifStatus.info, duration });
  }
  sms(title, msg, duration, data) {
    return this.send({ title, msg, type: notifStatus.sms, duration, ...data });
  }
  email(title, msg, duration, data) {
    return this.send({
      title, msg, type: notifStatus.email, duration, ...data
    });
  }
  popUp(title, msg, duration, data) {
    return this.send({
      title, msg, type: notifStatus.popUp, duration, ...data
    });
  }
  subscribe(cb) {
    if (typeof cb !== 'function') {
      throw new Error('Not a function');
    }
    if (this.subscriber !== null) {
      throw new Error('Someone has already subscribe to notifications');
    }
    this.subscriber = cb;
    return () => {
      this.subscriber = null;
    };
  }
  send(notif) {
    const finalNotif = { ...notif, id: this.count++ };
    this.notifs.push(finalNotif);
    this.subscriber();
    return finalNotif;
  }
  consume() {
    return this.notifs.shift();
  }
}

export default new Notifs();
