import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { createPortal } from 'react-dom';
import DOMPurify from 'dompurify';

function isHTML(str) {
  let doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

const useStyles = makeStyles()({
  iframe: {
    overflow: 'auto',
    maxHeight: '300px',
    minHeight: '350px',
    width: '100%',
  },
});

const IFrame = ({ children, iframStyle }) => {
  const [contentRef, setContentRef] = useState(null);
  const { classes } = useStyles();


  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe
      {...(iframStyle ? { style: iframStyle } : { className: classes.iframe })}
      ref={setContentRef}
      frameBorder="0"
    >
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};

export default ({ htmlBody, ...props }) =>
  isHTML(htmlBody) ? (
    <IFrame {...props}>
      <div
        style={props.divStyle}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(htmlBody),
        }}
      />
    </IFrame>) : htmlBody;
