import store from '../../store';
import { PE_ENC } from '@lba-dev/package.local-globals/paidStatusEncaissements';

export const regDefault = (id, tva, artisan, date) => ({
  id,
  ttc: 0,
  tva,
  ht: 0,
  date: new Date(),
  status: 1,
  artisan,
  dateInterAjout: new Date(date)
});

export const lineDefault = props =>
  props && props.encaissement
    ? props
    : {
      ...props,
      encaissement: new Date(),
      numChq: '',
      multis: [],
      saisie: new Date(),
      mode: 'chq',
      montant: 0,
      userId: store.getState().userId,
      type: 2,
    };

export const decaissementDefault = props => ({
  numChq: '',
  saisie: new Date(),
  mode: 'chq',
  montant: 0,
  motif: 0,
  userId: store.getState().userId,
  type: 1,
  isFlesh: false,
  encaissement: new Date(),
  paidStatus: PE_ENC,
  multis: [],
  ...props,
});

export const checkInfo = lines =>
  lines.every(e => e.numChq.length) && (
    !lines.filter(e => e.multis.length).size ||
    lines.filter(e => e.multis.length)
      .every(line =>
        line.montant === line.multis.reduce((acc, v) => acc + v.montant, 0) &&
        line.multis.map(i => i.id).includes(line.id)
      )
  );

export const checkDecaissement = decaissement =>
  !(decaissement || []).find(e => e.motif === 0 || e.montant <= 0) &&
  !decaissement.every(
    e => (e.multis || []).length
      ? e.montant !== e.multis.reduce((i, v) => i + v.montant, 0) ||
        !e.multis.map(i => i.id).includes(e.id)
      : e.montant < 0
  );
