import api from '../api';
import { getSpecificKeys } from '../components/Utils/manageData';

export const getHistories = ({ name, elem }) => {
  const { history } = getSpecificKeys({ name, id: elem.id });
  return api.history.getAll({
    name: history.name,
    id: elem[history.id || 'id'],
    limit: 100,
    skip: 0,
  }).then((d) => d.body().map((e) => e.data()));
};
