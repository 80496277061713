import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const styles = {
  label: {
    textAlign: 'center'
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  }
};

const MinCustomForm = ({
  classes,
  title,
  type,
  value,
  handleChange,
  inline,
  options,
}) => {
  const onChange = e => {
    let v = e.target.value;

    if (type === 'boolean') {
      v = !!Number(v);
    } else if (type === 'number') {
      v = Number(v);
    }
    handleChange(e, v);
  };

  const formatValue = value => {
    if (type === 'boolean') {
      return value ? '1' : '0';
    }
    if (type === 'number') {
      return value === undefined ? '0' : `${value}`;
    }
    return value;
  };

  return (
    <FormControl className={classes.formControl}>
      {title && <FormLabel className={classes.label} children={title} />}
      <RadioGroup
        value={formatValue(value)}
        onChange={onChange}
        className={inline ? classes.group : ''}
      >
        {options.map((p, i) => (
          <FormControlLabel
            key={i}
            {...p}
            value={formatValue(p.value)}
            control={<Radio color="primary" />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

MinCustomForm.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  inline: PropTypes.bool
};

MinCustomForm.defaultProps = {
  title: 'Faites un choix',
  value: null,
  type: 'string',
  handleChange: e => e,
  options: [],
  inline: false
};

export default withStyles(MinCustomForm, styles);
