import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from 'tss-react/mui';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { getAlias } from '../../utils/function';

import { formatDate } from '../../views/Modifications/formatters';

const mapStateToProps = ({ grandCompte }) => ({
  grandCompte
});

const styles = theme => ({
  primaryTypoStyle: {
    marginBottom: 2,
    fontWeight: 400,
    fontSize: '0.80rem',
  },
  secondaryTypoStyle: {
    [theme.breakpoints.down('sm')]: {
      width: '40%'
    },
    paddingTop: 2,
    fontWeight: 250,
    fontSize: '0.85rem',
    lineHeight: 1.2,
    wordBreak: 'break-word'
  },
  list: {
    padding: 0
  },
  item: {
    padding: '6px 12px'
  },
  commentList: {
    width: '80%',
    margin: '20px auto',
    maxHeight: 200,
    overflow: 'hidden auto',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2),'
      + '0px 1px 1px 0px rgba(0, 0, 0, 0.01),'
      + '0px 1px 1px -1px rgba(0, 0, 0, 0.03)'
  }
});

class CommentList extends Component {
  constructor(props) {
    const { grandCompte } = props;
    const findGrandCompte = userId => {
      const gc = grandCompte.find(g => g.get('_id') === userId);
      return (gc && gc.get('companyName', gc.get('name'))) ||
        'Introuvable';
    };
    super(props);
    this.state = {
      formatDescription: comment => (
        comment.description ||
          `${getAlias(
            comment.userId,
            findGrandCompte(comment.userId)
          )}${comment.date ? ` - ${formatDate(comment.date)}` : ''}`
      )
    };
  }

  render() {
    const {
      classes,
      comments = [],
      empty = 'Pas de commentaires',
      commentKey = 'comment'
    } = this.props;
    return (
      <Card className={classes.commentList}>
        <List className={classes.list}>
          {comments.length ? comments.map((c, i) => {
            const text = `${c[commentKey]} ${
              (!!c.reminderDate && `Relance: ${c.reminderDate}`) || ''
            }`;
            return (
              <ListItem key={i} className={classes.item} divider>
                <ListItemText
                  primary={this.state.formatDescription(c)}
                  secondary={typeof text === 'string' ? text.reactify() : text}
                  primaryTypographyProps={{
                    color: 'textSecondary',
                    className: classes.primaryTypoStyle,
                  }}
                  secondaryTypographyProps={{
                    color: 'textPrimary',
                    className: classes.secondaryTypoStyle,
                  }}
                />
                {!!c.title && <Typography children={c.title} />}
              </ListItem>
            );
          }) :
            <ListItem className={classes.item}>
              <ListItemText
                secondary={empty}
                secondaryTypographyProps={{
                  color: 'textPrimary',
                  className: classes.secondaryTypoStyle
                }}
              />
            </ListItem>}
        </List>
      </Card>
    );
  }
}

export default connect(mapStateToProps)(withStyles(CommentList, styles));
