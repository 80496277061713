import React from 'react';

import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import UIList from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import { Clear } from '@mui/icons-material';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import { getService } from '@lba-dev/package.local-globals/services';
import { purple } from '@mui/material/colors';
import { getAlias } from '../../utils/function';
import FormatedComments from './FormatedComments';

const styles = {
  UIList: {
    minHeight: 35,
    maxHeight: 321,
    overflow: 'auto'
  },
  ListItem: {
    minHeight: 15,
    padding: '5px 20px'
  },
  ListItemInfo: {
    color: 'grey',
    fontSize: 14,
    whiteSpace: 'nowrap'
  },
  ListItemCom: {
    color: 'black',
    fontSize: 14,
    paddingLeft: 5
  },
  icon: {
    width: 20,
    height: 20,
    padding: 0
  },
  input: {
    padding: 10
  }
};

const CommentsList = ({
  classes,
  comments,
  userId,
  users = [],
  setComment = e => e,
  setOnChange = e => e,
  confirmBox,
  hideInput,
  customPadding = '5px 20px',
  formatComments
}) => {
  const user = users.find(e => e._id === userId);
  return <>
    <UIList className={classes.UIList}>
      {comments && comments.map((e, id) => {
        const service = getService(users, e.get('userId'));
        const userLogin = getAlias(e.get('userId'));
        const title = `${new Date(e.get('date')).toLocaleString('fr-FR')} -
        ${userLogin ? userLogin : e.get('fullName')}`;
        const commentValue = e.get('commentValue');
        return (
          <ListItem key={id}
            className={classes.ListItem}
            style={{ padding: customPadding }}
            divider>
            {confirmBox ? <Grid>
              <IconButton
                disabled={!confirmBox || (user &&
                  (user._id !== e.get('userId') &&
                  !permit(user, { key: 'deleteComments' })))}
                style={{ color: user && (user._id !== e.get('userId') &&
                  !permit(user, { key: 'deleteComments' }))
                  ? 'gray' : 'red' }}
                className={classes.icon}
                aria-label='Clear'
                onClick={() => confirmBox(e.get('userId'),
                  e.get('date'), commentValue)}
                size="large">
                <Clear className={classes.icon}/>
              </IconButton>
            </Grid> : null}
            {formatComments ?
              <FormatedComments
                comment={commentValue}
                service={service}
                title={title}
              /> :
              <>
                <Grid>
                  <ListItemText
                    className={classes.ListItemInfo}
                    style={{
                      color: service ? service.color[500] : purple[500]
                    }}
                    disableTypography={true}
                    primary={title}
                  />
                </Grid>
                <Grid>
                  <ListItemText
                    className={classes.ListItemCom}
                    disableTypography={true}
                    primary={commentValue}
                  />
                </Grid>
              </>
            }
          </ListItem>
        );
      })
      }
    </UIList>
    {!hideInput ? <Grid>
      <Input
        disabled={typeof setComment !== 'function'}
        className={classes.input}
        disableUnderline
        multiline
        placeholder="Commentaire..."
        fullWidth
        onKeyPress={e => setComment(e)}
        onChange={e => setOnChange(e)}
        onContextMenu={e => e.stopPropagation()}
      />
    </Grid> : null}
  </>;
};

export default withStyles(CommentsList, styles);
