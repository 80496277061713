import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MinCustomDialog from './MinCustomDialog';
import Typography from '@mui/material/Typography';
import notifSystem from '../../notifSystem';
import api from '../../api';
import { CircularProgress } from '@mui/material';
import interFilters from '@lba-dev/package.local-globals/Filters/InterFilters';

const mapStateToProps = (state) => {
  const user = state.users.find(e => e._id === state.userId);
  return ({
    user,
    users: state.users
  })
};

class DialogSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      step: 0,
      loading: true,
      comments: ((props.defaultText || {}).data || []).reverse(),
    };
  }

  setTextValue = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  onValidate = () => {
    if (typeof this.props.callback === 'function') {
      this.props.callback(this.state.value);
    }
  };
  onReject = (value) => {
    if (typeof this.props.callback === 'function') {
      this.props.callback(value);
    }
  };

  componentDidMount() {
    this.getCounter();
  }

  getCounter = async () => {
    const {
      users,
      user,
    } = this.props;
    try {
      const filter = interFilters(users)['countSupport'];
      let points = 0, permission = false;
      if (filter) {
        const counter = await api.interventions.getAll({
          query: JSON.stringify({
            ...filter.match,
            [filter.group]: user._id
          }),
          call: 'countDocuments'
        }).then(e => e.body().data());
        points = counter ? counter : 0;
        permission = points < user.support;
      }

      this.setState({
        step: permission ? 1 : 2,
        points,
      })
    } catch (error) {
      notifSystem.error('Erreur', 'Une erreur est survenue.')
    }
  }

  steps = {
    0: {
      actions: [
        {
          title: 'Annuler',
          onClick: () => this.onReject(null),
          color: 'secondary',
        },
      ],
      title: 'Chargement en cours...',
      Component: () => <CircularProgress size={70} color="secondary" />
    },
    1: {
      actions: [
        {
          title: 'Annuler',
          onClick: () => this.onReject(null),
          color: 'secondary',
        },
        {
          title: 'Non',
          onClick: () => this.onReject(true),
          color: 'secondary',
        },
        {
          title: 'Valider',
          onClick: () =>
            !this.state.value
              ? notifSystem.error('Erreur', 'Veuillez saisir un texte.')
              : this.onValidate(),
          color: 'primary',
        },
      ],
      title: 'Y a t-il précision à apporter a votre intervention ?',
      Component: () => {
        const points = this.state.points;
        const user = this.props.user
        return [
          <Typography
            key="1"
            variant="subtitle1"
            children={`Il vous reste ${(user.support || 0) - points
            } points aujourd'hui`
            }
          />,
          <TextField
            variant="standard"
            key="2"
            fullWidth
            onChange={this.setTextValue}
            value={this.state.value}
            multiline={true}
            maxRows={10}
          />,
        ];
      }
    },
    2: {
      actions: [
        {
          title: 'OK',
          onClick: () => this.onReject(null),
          color: 'secondary',
        },
      ],
      title: 'Message',
      Component: () =>
        'Vous avez atteint votre limite de crédit pour aujourd\'hui.',
    },
  };

  render() {
    const { step: stateStep } = this.state;
    const { Component, title, actions } = this.steps[stateStep];
    return (
      <MinCustomDialog
        middle
        open
        title={title}
        actions={actions.map(({ title, onClick, color }, i) => (
          <Button key={i} onClick={onClick} color={color}>
            {title}
          </Button>
        ))}
      >
        {
          Component &&
          <Component />
        }
      </MinCustomDialog>
    );
  }
}

DialogSupport.propTypes = {
  callback: PropTypes.func.isRequired,
  defaultText: PropTypes.object,
};

export default connect(mapStateToProps)(DialogSupport);
