import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { withStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import notifSystem from '../notifSystem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, IconButton, InputAdornment, Link } from '@mui/material';
import {
  userLogin,
  twoFactorAuthDialogDialog } from '../actions/users';
import { login as loginAuth } from '../actions/auth'
import store from '../store';
import { popupNewSession } from '../utils/manageCookies';

const styles = {};

styles.login = {
  'margin-top': '30%',
  padding: 20,
  textAlign: 'right',
};
styles.img = {
  position: 'fixed',
  maxWidth: 300,
  top: 25,
  left: 50,
};
styles.displayInput = {
  width: '45%',
  margin: '0 2.5%',
};
styles.marginTopLeft = {
  float: 'left',
};


function Login({ classes, forgetPasswordMode }) {

  const initState = {
    username: '',
    password: '',
    showpw: false,
    disabledLoginBtn: false
  };

  const [state, setState] = useState(initState);
  const [showpw, setShowpw] = useState(false)
  const [disabledLoginBtn, setDisableLoginBtn] = useState(false)


  const handleSucessfulLogin = (res) => {
    const {
      token,
      isRemotely,
      isMaster,
      _id,
      has2FA,
      hasSecret,
      newCookie
    } = res
    const cb = (token) => {
      if (newCookie){
        popupNewSession();
      }
      store.dispatch(loginAuth(token, isRemotely));
    };
    if (!isMaster && has2FA) {
      twoFactorAuthDialogDialog({ _id, hasSecret, isRemotely }, cb);
    } else {
      cb(token);
    }
    setState(initState)
  }

  const login = async (e) => {
    try {
      e.preventDefault();
      setDisableLoginBtn(true)

      const { username, password } = state;

      if ((username && password)) {
        const { finishLogin, data } = await userLogin(username, password)
        return finishLogin
          ? handleSucessfulLogin(data)
          : null
      }

      notifSystem.error(
        'Champs non remplis',
        'Les champs demandés ne sont pas remplis.'
      );

    }

    finally {
      setDisableLoginBtn(false)
    }
  };


  return (
    <Grid className={classes.login}>
      <form
        onSubmit={e => login(e) }
      >
        <Box sx={{
          mb: '20px',
          display: 'flex',
          justifyContent: 'center'
        }}>
          <TextField
            variant="standard"
            label="Nom d'utilisateur"
            value={state.username}
            className={classes.displayInput}
            onChange={(e) => setState({
              ...state,
              username: e.target.value.toLowerCase()
            })} />
          <TextField
            variant="standard"
            autoComplete="off"
            label="Mot de passe"
            type="text"
            displ
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {setShowpw(!showpw)}}
                    edge="end" color="primary">
                    {showpw ? <VisibilityOffIcon/>
                      : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
              sx: !showpw ?
                { '-webkit-text-security': 'disc' } : null
            }}
            value={state.password}
            className={classes.displayInput}
            onChange={(e) =>
              setState({ ...state, password: e.target.value })} />
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <Button
            sx={{ ml: 2.5 }}
            variant="contained"
            color="primary"
            className={classes.marginTop}
            type={'submit'}
            disabled={disabledLoginBtn}
          >
                Connexion
          </Button>
          <Link sx={{ mt: -3, mr: 2, textDecoration: 'none' }}
            component="button"
            variant="body2"
            onClick={forgetPasswordMode}
          >
                  Mot de passe oublié ?
          </Link>
        </Box>
      </form>
    </Grid>
  );
}

export default withStyles(Login, styles);
