import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import DateSelector from './DateSelector';
import CustomTextField from '../CustomInputs/CustomTextField';
import MoneyInput from '../CustomInputs/MoneyInput';
import CustomSwitch from '../CustomInputs/CustomSwitch';
import PaymentMethod from './PaymentMethod';
import CreditCardInfo from './CreditCardInfo';
import paths from './paths';

export default ({
  setData,
  infoDesc,
  paymentMethod,
  user,
  isSAV,
  date,
  isUp
}) => (
  <Card className="cards">
    <CardContent>
      <Typography variant="h5">Informations SAV</Typography>
    </CardContent>
    <CardContent sx={{ mb: '150px' }}>
      <Grid container spacing={3}>
        <DateSelector
          dateLabel={date ? 'Date d\'intervention' : 'Date non défini'}
          date={date}
          setData={setData}
          user={user}
          isSAV={isSAV}
          paths={paths}
          md={3}
          xs={6}
        />
        {isUp.sm &&
          <Grid item sm={6} />
        }
        <CustomTextField
          grid
          xs={12}
          md={6}
          label="Description"
          multiline
          rowsMax={3}
          value={infoDesc.get('desc', '')}
          path={paths.descSAV}
          setData={setData}
        />
        <CustomTextField
          grid
          xs={12}
          md={6}
          label="Consignes"
          multiline
          rowsMax={3}
          value={infoDesc.get('consignes', '')}
          path={paths.consignesSAV}
          setData={setData}
          maxLength="150"
        />
        <MoneyInput
          grid
          xs={12}
          sm={2}
          label={'Rémunération SAV'}
          type="number"
          defaultValue={infoDesc.get('SAVRemunPrice', 0)}
          path={paths.SAVRemunPrice}
          setData={setData}
          disabled={!infoDesc.get('SAVRemun', false)}
        />
        <MoneyInput
          grid
          xs={12}
          sm={2}
          label={'Montant Impacté'}
          type="number"
          defaultValue={infoDesc.get('SAVImpactPrice', 0)}
          path={paths.SAVImpactPrice}
          setData={setData}
          disabled={!infoDesc.get('SAVRemun', false) ||
            !infoDesc.get('SAVRemunPrice')}
        />
        <CustomSwitch
          grid
          xs={6}
          sm={2}
          checked={infoDesc.get('SAVRemun', false)}
          path={paths.SAVRemun}
          setData={setData}
          label={infoDesc.get('SAVRemun', '') ?
            'SAV rémunéré' : 'Non rémunéré'}
        />
        <MoneyInput
          grid
          xs={12}
          sm={3}
          label={'Facturation SAV'}
          type="number"
          defaultValue={infoDesc.get('SAVFactPrice', 0)}
          path={paths.SAVFactPrice}
          setData={setData}
          disabled={!infoDesc.get('SAVFact', false)}
        />
        <CustomSwitch
          grid
          xs={6}
          sm={3}
          checked={infoDesc.get('SAVFact', false)}
          path={paths.SAVFact}
          setData={setData}
          label={infoDesc.get('SAVFact', false) ?
            'SAV facturé' : 'Non facturé'}
        />
        <PaymentMethod
          xs={4}
          user={user}
          paymentMethod={infoDesc.get('paymentMethod', -1)}
          setData={setData}
          isSAV={isSAV}
        />
        {paymentMethod === 0 && (
          <Grid item xs={12} sm={12}>
            <CreditCardInfo
              setData={setData}
              creditCard={infoDesc.get('creditCard', new Map())}
              paths={paths.creditCardSAV}
            />
          </Grid>
        )}
      </Grid>
    </CardContent>
  </Card>
);
