import React, { Component } from 'react';
import List from '@mui/material/List';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { REASON_CALL } from '@lba-dev/package.local-globals/configTypes';
import RadioGroup from '@mui/material/RadioGroup';
import { setDialogContentProp } from '../../actions/dialog';
import { connect } from 'react-redux';
import CustomTextField from '../CustomInputs/CustomTextField';
const setComment = setDialogContentProp('comment');
const setReason = setDialogContentProp('reason');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      comment,
      reason,
    },
  },
  config,
}) => ({
  comment,
  reason,
  config
});

const mapDispatchToProps = { setComment, setReason };

class ReasonCallDialog extends Component {
  state = {
    reasons: this.props.config
      .filter(e => e.type === REASON_CALL).map(e => e.text)
  }
  render() {
    const {
      comment,
      reason,
      setComment,
      setReason
    } = this.props;
    return (
      <Grid container spacing={1} justifyContent="center" >
        <List>
          <FormControl component="fieldset">
            <RadioGroup value={reason}
              onChange={(_, val) => setReason(val)}>
              {this.state.reasons.map((e, i) =>
                <FormControlLabel
                  key={i} value={e} label={e} control={<Radio />}
                />
              )}
            </RadioGroup>
          </FormControl>
        </List>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            align="center"
            children="Ajouter un commentaire"
          />
        </Grid>
        <Grid item xs={10}>
          <CustomTextField
            label="...commentaire"
            value={comment}
            setData={(p, v) => setComment(v)}
            InputProps={{ disableUnderline: true }}
            rows={3}
            multiline
            rowsMax={10}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReasonCallDialog);
