import {
  NOT_CONFIRMED
} from '@lba-dev/package.local-globals/permissions';
import componentLoader from '../../componentLoader';

const ListIntervention = componentLoader(() =>
  import(
    /* webpackChunkName: "ListInterventionQuality" */
    '../../views/List/ListIntervention'
  )
);

export default [
  {
    path: '/quality/list/:filter/:query',
    component: ListIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/quality/list/:filter',
    component: ListIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
];
