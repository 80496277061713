import { useEffect, useCallback } from 'react';
import ws from '../../ws';

const ManageTabs = ({ user_id }) => {
  const ongletID = `${new Date().getTime()}-${Math.random()
    .toString(36).substring(2, 9)}`;

  const manageTabs = useCallback((action) => {
    let arrayTabs = JSON.parse(localStorage.getItem('tabs')) || [];

    if (action === 'add') {
      arrayTabs = arrayTabs.map(tab => ({ ...tab, active: false }));
      arrayTabs.push({ id: ongletID, active: true });
    } else if (action === 'delete') {
      arrayTabs = arrayTabs.filter(tab => tab.id !== ongletID);
      if (!arrayTabs.some((tab) => tab.active)) {
        arrayTabs[arrayTabs.length - 1].active = true;
      }
    }

    localStorage.setItem('tabs', JSON.stringify(arrayTabs));
  }, []);

  useEffect(() => {
    manageTabs('add');

    const deleteActiveTab = () => manageTabs('delete');

    window.addEventListener('unload', deleteActiveTab);

    ws.on(`current_contact_call_${user_id}`, data => {
      if (JSON.parse(localStorage.getItem('tabs'))
        .find(tab => tab.active && tab.id === ongletID)) {
        window.open(data.page, '_blank');
      }
    });

    return () => {
      window.removeEventListener('unload', deleteActiveTab);
    }

  }, []);

  return null;
};

export default ManageTabs;
