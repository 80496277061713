import React from 'react';
import { List } from 'immutable';

import { withStyles } from 'tss-react/mui';
import {
  DialogHist,
  DialogIframs,
  DialogForDate,
  DialogAppel
} from './index';
import SMSDialog from '../../components/Dialogs/SMSDialog';

const styles = {
  button: {
    marginLeft: 14
  }
};

const Dialogs = ({ state, merge, updateState, optionRappel,
  changeTime, artisanMissing, classes, callFunc }) => {
  const { time, open, openHist, hist, openIfram,
    openSms, appels, openAppel, callObj } = state;
  return (
    <React.Fragment>
      <DialogHist
        openHist={openHist}
        hist={hist}
        reminders={
          merge.getIn(['elem', 'obj', 'reminders'], new List())}
        updateState={updateState}
        appels={appels}
      />
      <DialogIframs
        openIfram={openIfram}
        urls={merge.getIn(['elem', 'obj', 'url'], [])}
        updateState={updateState}
      />
      <DialogForDate
        open={open}
        classes={classes}
        optionRappel={optionRappel}
        artisanMissing={artisanMissing}
        changeTime={changeTime}
        time={time}
        updateState={updateState}
      />
      <SMSDialog
        open={openSms}
        defaultText={{
          prospId: merge.getIn(['elem', 'obj', '_id'])
        }}
        callback={() => updateState('openSms', false)}
      />
      <DialogAppel
        openAppel={openAppel}
        updateState={updateState}
        callFunc={callFunc}
        callObj={callObj}
      />
    </React.Fragment>
  );
};

export default withStyles(Dialogs, styles);
