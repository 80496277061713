/* eslint-disable max-lines */
import {
  AttachMoney,
  Build,
  CalendarToday,
  Event,
  List,
  LocalShipping,
  Map,
  Work,
  Assessment,
  Search,
  AssignmentLate,
  Archive,
  Person,
  Share,
  Settings,
  Block,
  Send,
  AccessTime,
  Beenhere,
  Assignment,
  AssignmentReturned,
  ContactMail,
  InsertDriveFile,
  ListAlt,
  AccountBalance,
} from '@mui/icons-material';
import { getUserTeam } from '../../../utils/teams';
import { permit } from '@lba-dev/package.local-globals/restrictions';

export default [
  {
    name: 'Liste Artisans',
    icon: List,
    link: '/artisan/list',
  },
  {
    name: 'Interventions',
    icon: LocalShipping,
    childs: [
      {
        name: 'Démarchage',
        icon: AssignmentReturned,
        childs: [
          {
            name: 'Market',
            icon: AccountBalance,
            link: '/partenariat/demarchMarket',
          },
          {
            name: 'Stats market',
            icon: AccountBalance,
            link: '/partenariat/market/smar',
          },
        ],
      },
      {
        name: 'Interventions à envoyer',
        icon: Send,
        childs: [
          {
            name: 'News et Pot',
            icon: List,
            count: true,
            link: '/intervention/list/newapr',
          },
          {
            name: 'News et Pot clm/ch',
            icon: List,
            count: true,
            link: '/intervention/list/newaprclmch',
          },
          {
            name: 'News et Pot serr',
            icon: List,
            count: true,
            link: '/intervention/list/newaprserr',
          },
          {
            name: 'Form',
            icon: List,
            count: true,
            link: '/intervention/list/forapr',
          },
          {
            name: 'Inactif.',
            icon: List,
            count: true,
            link: '/intervention/list/iacapr',
          },
        ],
      },
      {
        name: 'Interventions en cours',
        icon: AccessTime,
        childs: [
          {
            name: 'News',
            icon: List,
            count: true,
            link: '/intervention/list/paenc',
          },
          {
            name: 'Mes Inter Env.',
            icon: List,
            link: (e) =>
              `/intervention/list/paenv/login-envoi=${
                e.login}=${e._id}&login-firstEnvoi=${e.login}=${e._id}`,
          },
        ],
      },
      {
        name: 'Verifs News',
        icon: Beenhere,
        count: true,
        link: '/intervention/list/pavrf',
      },
    ],
  },
  {
    name: 'Mise à jour du partenaire',
    icon: Assignment,
    childs: [
      {
        name: 'En attente (général)',
        count: false,
        icon: List,
        link: () => '/intervention/list/propositionAv',
      },
      {
        name: 'En attente',
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/propositionAv/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Validées',
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/propositionVal/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Refusées',
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/propositionRef/login-ajout=${e.login}=${e._id}`,
      },
    ],
  },
  {
    name: 'Candidats',
    icon: Person,
    childs: [
      {
        name: 'Liste',
        icon: List,
        childs: [
          {
            name: 'Tous',
            icon: List,
            count: true,
            link: (e, users) => {
              const team = getUserTeam(users, e._id);
              return !permit(e) && team
                ? `/candidat/list/all_${team._id}`
                : '/candidat/list';
            },
          },
          {
            name: 'En cours',
            icon: List,
            count: true,
            link: (e, users) => {
              const team = getUserTeam(users, e._id);
              return !permit(e) && team
                ? `/candidat/list/canEnC_${team._id}`
                : '/candidat/list/canEnC';
            },
          },
          {
            name: 'Non traités',
            icon: List,
            count: true,
            link: (e, users) => {
              const team = getUserTeam(users, e._id);
              return !permit(e) && team
                ? `/candidat/list/canNoT_${team._id}`
                : '/candidat/list/canNoT';
            },
          },
          {
            name: 'Refusés',
            icon: List,
            count: true,
            link: (e, users) => {
              const team = getUserTeam(users, e._id);
              return !permit(e) && team
                ? `/candidat/list/canRef_${team._id}`
                : '/candidat/list/canRef';
            },
          },
          {
            name: 'Refusés Prov',
            icon: List,
            count: true,
            link: (e, users) => {
              const team = getUserTeam(users, e._id);
              return !permit(e) && team
                ? `/candidat/list/refPro_${team._id}`
                : '/candidat/list/refPro';
            },
          },
          {
            name: 'Candidats transférés',
            icon: List,
            count: true,
            link: (e, users) => {
              const team = getUserTeam(users, e._id);
              return !permit(e) && team
                ? `/artisan/list/traCan_${team._id}`
                : '/artisan/list/traCan';
            },
          },
        ],
      },
      {
        name: 'Interventions à env.',
        icon: List,
        count: true,
        link: '/intervention/list/canEnv',
      },
      {
        name: 'Toutes',
        icon: List,
        link: '/intervention/list/canAll',
      },
      {
        name: 'Outils',
        icon: Settings,
        childs: [
          {
            name: 'Sous Statut',
            icon: Build,
            link: '/config/table/confcand',
          },
          {
            name: 'Source',
            icon: Share,
            link: '/candidat/config/table/candidatSource',
          },
        ],
      },
      {
        name: 'Statistiques',
        icon: Assessment,
        link: '/partenariat/candidat/statistiques',
      },
    ],
  },
  {
    name: 'Management',
    icon: Work,
    childs: [
      {
        name: 'Débrief SST',
        icon: List,
        count: true,
        link: (e) => `/artisan/list/debr/login-debrief=${e.login}=${e._id}`,
      },
      {
        name: 'Mes Relances',
        icon: LocalShipping,
        childs: [
          {
            name: 'Règlements',
            icon: AttachMoney,
            count: true,
            link: (e) => `/intervention/relance/vrfnor/login-
            envoi=${e.login}=${e._id}&login-firstEnvoi=${e.login}=${e._id}`,
          },
          {
            name: 'Dossier SST',
            icon: Person,
            count: true,
            link: (e) =>
              `/artisan/relance/incomplete/login-ajout=${
                e.login}=${e._id}&login-firstEnvoi=${e.login}=${e._id}`,
          },
          {
            name: 'Interventions à vérifier',
            icon: List,
            count: true,
            link: (e) =>
              `/intervention/relance/avrnor/login-envoi=${e.login}=${e._id}`,
          },
        ],
      },
      {
        name: 'Relance chèques',
        icon: Event,
        count: true,
        link: '/intervention/list/chq',
      },
      {
        name: 'Demande facturier',
        icon: CalendarToday,
        count: true,
        link: '/artisan/list/needFacturier',
      },
      {
        name: 'Document à valider',
        icon: InsertDriveFile,
        count: true,
        link: '/artisan/list/docEnc',
      },
      {
        name: 'Archive provisoire',
        icon: Archive,
        count: true,
        link: '/artisan/list/arcpro',
      },
      {
        name: 'Artisans',
        icon: Person,
        childs: [
          {
            name: 'Sous-tutelle',
            icon: List,
            count: true,
            link: '/artisan/list/tutel',
          },
          {
            name: 'Star',
            icon: List,
            count: true,
            link: '/artisan/list/star',
          },
          {
            name: 'Actifs',
            icon: List,
            count: true,
            link: '/artisan/list/act',
          },
          {
            name: 'Inactifs',
            icon: List,
            count: true,
            link: '/artisan/list/inact',
          },
          {
            name: 'Archivés',
            icon: List,
            count: true,
            link: '/artisan/list/arc',
          },
          {
            name: 'Quarantaine',
            icon: List,
            count: true,
            link: '/artisan/list/qua',
          },
          {
            name: 'Bloqués',
            icon: Block,
            count: true,
            link: '/artisan/list/blockArtisans',
          },
        ],
      },
    ],
  },
  {
    name: 'Utilitaire',
    icon: Build,
    childs: [
      {
        name: 'Etat Réseau',
        icon: Map,
        link: '/partenariat/etatreseau',
      },
      {
        name: 'Etat Réseau 2',
        icon: Map,
        link: '/partenariat/etatreseau2',
      },
      {
        name: 'Commissions',
        icon: AttachMoney,
        link: '/partenariat/commissions',
      },
      {
        name: 'Campagne',
        icon: ContactMail,
        childs: [
          {
            name: 'Campagne',
            icon: Send,
            link: '/artisan/utilsAssets',
          },
          {
            name: 'Statistiques',
            icon: Assessment,
            link: '/sms/campagnes',
          },
        ],
      },
      {
        name: 'Rib à valider',
        icon: AssignmentLate,
        link: '/artisan/list/ribNoValide',
      },
      {
        name: 'RIB manquants',
        icon: AssignmentLate,
        link: '/artisan/list/paidnoRib',
      },
      {
        name: 'Statistiques',
        icon: Assessment,
        link: '/partenariat/statistiques',
      },
      {
        name: 'Maps',
        icon: Map,
        link: '/partenariat/maps/',
      },
      {
        name: 'Connexion artisans',
        icon: ListAlt,
        link: '/partenariat/artisanConnection',
      },
      {
        name: 'Map équipe',
        icon: ListAlt,
        link: '/partenariat/MapEquipe',
      },
    ],
  },
  {
    name: 'Prospection',
    icon: Work,
    childs: [
      {
        name: 'Moteur de recherche',
        icon: Search,
        link: '/prosp/list',
      },
    ],
  },
];
