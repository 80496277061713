import api from '../api';
import {
  METIERSSTEPS_FETCH,
  METIERSSTEPS_UPDATE
} from '../constants/ActionTypes';
import store from '../store';
import { setDialog } from './dialog';
import DbWsAction from './models/DbWsAction';
import notifSystem from '../notifSystem';

const action = new DbWsAction(
  METIERSSTEPS_FETCH,
  METIERSSTEPS_UPDATE
);

export const fetch = action.fetch;
export const update = action.update;

export const displayMetierDialog = ({ title = '', maxWidth = 'lg', content }) =>
  store.dispatch(
    setDialog({
      name: 'CustomDialog',
      open: true,
      dialogProps: {
        title,
        maxWidth: maxWidth,
      },
      contentProps: {
        content,
        boxProps: {
          width: '100%'
        }
      },
    })
  );

export const getElementFromApi = (id, collection) =>
  api[collection]
    .getAll({
      query: JSON.stringify({ id }),
    })
    .then((e) => e.body().map((e) => e.data())[0]);


export const copyQuestions = (newQuestions, { setParentData }) =>
  store.dispatch(
    setDialog({
      name: 'DialogConfigurateurCopy',
      open: true,
      hideClose: true,
      dialogProps: { maxWidth: 'lg' },
      contentProps: {
        onlyFinalChild: true,
        dataType: 'metiersSteps',
        copyFunc: (item, callback) => {
          const items = Array.isArray(item) ? item : [item];
          Promise.all(items.map(e => {
            const questions = [...e.questions || []]
              .concat(newQuestions);

            return api.metiersSteps.patch(e._id, {
              questions
            });
          })).then(() => {
            items.forEach(e => {
              if (setParentData && e._id) {
                const questions = [...e.questions || []]
                  .concat(newQuestions);
                setParentData(e._id, { questions });
              }
            });
            notifSystem.success('Operation réussi',
              'Les questions ont bien été copiés');
          }).catch(() => {
            notifSystem.error('Erreur system',
              'Il y a eu une erreur lors de la copie');
          }).finally(() => {
            if (callback) {
              callback();
            }
          });
        }
      },
    })
  );
