import { R_FOR, R_POR } from '@lba-dev/package.local-globals/remStatus';
import { moment } from '@lba-dev/package.local-globals/moment';
import {
  addressToString,
  clientToString,
  complementAdress,
  dateToString,
} from './functions';
import { getPrice } from '@lba-dev/package.local-globals/formatPrice';
import {
  externeNumber,
  numAssistance,
} from '@lba-dev/package.local-globals/numbers';
import {
  paymentMethods,
  services
} from '@lba-dev/package.local-globals/getDataById';
import { getFinanceConsignes } from '../../utils/financement';


export const genInterestSmsInter = (inter, user) => {
  const login = (user && user.alias) || '';
  const description = inter.infoDesc.desc;
  const clientAddress = inter.client.address;
  const serviceData = services[user.service];

  return (
    'Bonjour, ARTISAN_NAME\n' +
    'J\'ai besoin de vos services pour une intervention autour de ' +
    `${clientAddress.city} ${clientAddress.zipcode} ` +
    '(Distance DIS).\n' +
    `Voici quelques infos : ${description}.\n\n` +
    'Cliquez sur le lien suivant pour avoir plus de détails : \n' +
    '__LINK_WEBSITE_PRO__\n\n' +
    `${login} : ${serviceData.name}\n`
  );
};

const paymentOnSite = (inter, remunInter) =>
  `${
    inter.onSitePayment
      ? (paymentMethods[inter.paymentMethod] &&
          paymentMethods[inter.paymentMethod].sms &&
          paymentMethods[inter.paymentMethod].sms(inter, remunInter)) ||
        ''
      : 'Vous ne devez pas récupérer le règlement du client. \n\n'
  }`;

export const generateOfflineSmsInter = (inter, user, os, artisan) => {
  const date = new Date(inter.date.intervention);
  const maxHour = inter.infoDesc.maxHour
    ? new Date(inter.infoDesc.maxHour)
    : moment(date.getTime()).add(2, 'hours').toDate();
  const client = inter.client;
  const address = client.address;
  const login = (user && user.alias) || '';
  const num = user && user.ligne.replace(/\+33/, '0').match(/.{2}/g).join('.');
  const { name, number } = numAssistance(user);
  const idInter = inter.id || '{id}';
  const dateString = inter.definDClient
    ? '(horaire à définir avec le client)'
    : dateToString(date, inter.flexHour, maxHour, inter.definHClient);
  const remunInter = inter.remunerationArtisan || artisan.remuneration || {};
  let message =
    `OS ${idInter}\n` +
    `${dateString}\n` +
    `${clientToString(client)}\n` +
    `${addressToString(address)}\n` +
    `${complementAdress(client)}` +
    `Tel client : ${externeNumber(inter)} (Taper le code OS: ${idInter}` +
    ' et #)\n' +
    `${
      inter.infoDesc && inter.infoDesc.desc
        ? inter.infoDesc.desc
        : 'Description : Aucune'
    }\n`;
  if (remunInter.status === R_FOR) {
    message += getFinanceConsignes(inter);
    message +=
      'Consignes : \n' +
      '-Ne pas parler tarif avec le client\n' +
      '-Nous prévenir si dépassement du budget\n' +
      '-Présentez vous en tant que société mandaté par Les Bons Artisans\n\n';
  } else {
    message += `${
      inter.infoDesc && inter.infoDesc.context
        ? `Remarque : ${inter.infoDesc.context}\n`
        : ''
    }`;
    message += `${
      inter.infoDesc && inter.infoDesc.consignes
        ? `Consignes : ${inter.infoDesc.consignes}\n`
        : ''
    }`;
    message += `${
      inter.infoDesc && inter.infoDesc.infoClient
        ? `Information client : \n${inter.infoDesc.infoClient}\n`
        : ''
    }`;
    message += `${
      inter.infoDesc && inter.infoDesc.infoTech
        ? `Information technique : \n${inter.infoDesc.infoTech}\n`
        : ''
    }`;
    message += `${
      inter.infoDesc && inter.infoDesc.toBeSelledOnSite
        ? `\nÀ vendre sur place : \n${inter.infoDesc.toBeSelledOnSite}\n\n`
        : ''
    }`;
  }
  message += `${paymentOnSite(inter, remunInter)}`;
  if (os === true) {
    if (!inter.hidePrice) {
      message +=
        `Prix annoncé HT : ${getPrice(inter)}` +
        `TVA: +${inter.products ? inter.products.tva : 10}%\n` +
        `Prix annoncé TTC : ${getPrice(inter, true)}\n`;
      if (remunInter.status === R_POR) {
        message += `Rémunération prévue: ${remunInter.labor}%\n\n`;
      } else if (remunInter.status === R_FOR) {
        message += `Budget Maximum de votre prestation: ${getPrice(
          inter,
          false,
          true
        )}\n\n`;
      }
    } else if (remunInter.status === R_FOR) {
      message += `${`Budget Maximum de votre prestation: ${getPrice(
        inter,
        false,
        true
      )}`}\n\n`;
    }
    message += `${login} : ${num}\n${name} : ${number}`;
    return message;
  }
};
