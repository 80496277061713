export const getTeam = (data, users, listen = 'artisans') => {
  let dataAreas;
  if (listen === 'artisans') {
    dataAreas = data.billingAddress.zipcode;
  } else if (listen === 'candidats') {
    dataAreas = data.address.zipcode;
  } else {
    dataAreas = data.client.address.zipcode;
  }
  const chief =
    (dataAreas &&
      users.find((u) =>
        u.areas && u.areas.includes(dataAreas.slice(0, 2)))) || {};
  return {
    login: chief.login || 'Non defini',
    equipe: chief.equipe || {},
  };
};


export const getUserTeam = (users = [], userId) =>
  users.find(
    (e) =>
      (e._id === userId && e.areas?.length) || e.equipe?.ids?.includes(userId)
  );
