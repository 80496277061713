import React from 'react';
import { connect } from 'react-redux';
import DevisList from '../../List/Devis/DevisList';
import { List as listData } from '../../../utils/List/ListeDevis';

const mapStateToProps = ({
  users,
  userId,
  dialog: {
    contentProps: {
      filter,
      service,
      sort = { id: -1 },
      count = 10,
      handlerFilterByColumns,
      filteredByColumn = false,
      displayName = 'default',
    },
  },
}) => ({
  filter,
  service,
  sort,
  handlerFilterByColumns,
  filteredByColumn,
  count,
  displayName,
  users,
  user: users.find((s) => s._id === userId),
});

const getListAttribute = ({ displayName, user, users }) => {
  const list = listData({ user, users });
  return list[displayName]['dataFormat'];
};

const InterListDialog = ({
  filter,
  service,
  sort,
  count,
  handlerFilterByColumns,
  filteredByColumn,
  displayName,
  user,
  users,
}) => (
  <DevisList
    small
    filter={filter}
    count={count}
    filteredByColumn={filteredByColumn}
    service={service}
    sort={sort}
    handlerFilterByColumns={handlerFilterByColumns}
    list={getListAttribute({ displayName, user, users })}
  />
);
export default connect(mapStateToProps)(InterListDialog);
