import { NOT_CONFIRMED } from '@lba-dev/package.local-globals/permissions';

import componentLoader from '../../componentLoader';

const ListAchat = componentLoader(() =>
  import(
    /* webpackChunkName: "ListAchat" */
    '../../views/List/ListAchat'
  )
);

const ListFournitures = componentLoader(() =>
  import(
    /* webpackChunkName: "ListFournitures" */
    '../../views/List/ListFournitures'
  )
);

const SuppliesInvoicesManager = componentLoader(() =>
  import(
    /* webpackChunkName: "SuppliesInvoices" */
    '../../views/SuppliesInvoices'
  )
);

const ListInvoice = componentLoader(() =>
  import(
    /* webpackChunkName: "ListInvoice" */
    '../../components/List/SuppliesInvoices/ListInvoice'
  )
);


export default [
  {
    path: '/fournitures/list',
    component: ListFournitures,
    restrictions: () => NOT_CONFIRMED
  },
  {
    path: '/suppliesInvoice/:fournisseur/:importName',
    component: ListInvoice,
    restrictions: () => NOT_CONFIRMED
  },
  {
    path: '/suppliesInvoice',
    component: SuppliesInvoicesManager,
    restrictions: () => NOT_CONFIRMED
  },
  {
    path: '/achat/list/:filter/:query',
    component: ListAchat,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/achat/list/:filter',
    component: ListAchat,
    restrictions: () => NOT_CONFIRMED,
  },
];
