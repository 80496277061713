import React, { useEffect, useState } from 'react';
import { List as listData } from '../../../utils/List/ListeInterventionsSav';
import { callBackFunction } from '../../Utils/ListFunctions';
import collections from '../../../constants/collections';
import ListLogic from '../ListLogic';
import { useSelector } from 'react-redux';
import SAVActions from './SAVActions';
import InterMenu from '../Intervention/InterMenu';

const functionType = [
  {
    type: 'categorie',
    callback: callBackFunction['categorie'],
  },
  {
    type: 'savStatus',
    callback: callBackFunction['savStatus'],
  },
];

const SAVList = (props) => {
  const {
    name = collections.savInterventions.capitalize(),
    listen = collections.savInterventions,
    handlerFilterByColumns,
    filteredByColumn = true,
    sort,
    filterName,
    count,
    filter: filterProps,
    list
  } = props;

  const [filter, setFilter] = useState(filterProps);
  const [display] = useState(count || 25);
  const user = useSelector(({ users, userId }) =>
    users.find(e => e._id === userId));
  const newListData = (list || listData({ user: user }).default.dataFormat);

  useEffect(() => {
    setFilter(filterProps);
  }, [filterProps]);

  if (filterName !== 'default' && Object.keys(filter).length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      <ListLogic
        {...newListData}
        handlerFilterByColumns={handlerFilterByColumns}
        project={[]}
        functionType={functionType}
        filteredByColumn={filteredByColumn}
        name={name}
        actions={SAVActions}
        menu={InterMenu}
        collection={listen}
        listen={listen}
        filter={filter}
        count={display}
        sort={sort}
      />
    </React.Fragment>
  );
};

export default SAVList;

