import {
  SHOW_MARKERS_COLUMN_SELECTED,
  setMarkerToMap
} from '../actions/etatReseau';

const showMarkersColumnSelected = (store) => next => (action) => {
  switch (action.type) {
    case SHOW_MARKERS_COLUMN_SELECTED: {
      const columns = store.getState().etatReseau.columns;
      const cities = store.getState().etatReseau.resultFilteredTab;
      store.dispatch(setMarkerToMap(
        cities.map(c => c.artisans).reduce((a, v) => {
          columns.forEach(k => {
            a = [...a, ...v[k]];
          });
          return a;
        }, [])
      ));
    }
  }
  next(action);
};

export default showMarkersColumnSelected;

