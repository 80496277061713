/* eslint-disable no-mixed-operators */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../../actions/dialog';
import CustomMaterialTable from '../../CustomMaterialTable';
import { Button, Grid, Tab, Tabs, TextField, Typography } from '@mui/material';
import VoiceRecorder from '../../Utils/VoiceRecorder';
import AudioPlayer from '../../Calls/AudioPlayer';
import LoadingComponentWithButton from '../LoadingComponent';
import {
  deleteAudio,
  listenAudio,
  addAudio,
  handleRowClick,
  handleUsersRowClick
} from './functions';

const setCallsRingover = setDialogContentProp('callsRingover');
const setSelected = setDialogContentProp('selected');
const setAudio = setDialogContentProp('audio');
const setTab = setDialogContentProp('tab');
const setSelectedUsers = setDialogContentProp('selectedUsers');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      callsRingover,
      selected = {},
      audio = null,
      tab = 0,
      users = [],
      selectedUsers = [],
    } }
}) => ({
  callsRingover,
  selected,
  audio,
  tab,
  users,
  selectedUsers,
});

const mapDispatchToProps = {
  setCallsRingover,
  setSelected,
  setAudio,
  setTab,
  setSelectedUsers
};

const DialogRingoverCampaign = ({
  callsRingover = [],
  setSelected,
  selected = {},
  audio = null,
  setAudio,
  setTab,
  tab = 0,
  users = [],
  selectedUsers = [],
  setSelectedUsers,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [audioName, setAudioName] = useState('');
  const [idListener, setIdListener] = useState(null);
  const [audioToListen, setAudioToListen] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSelectedAudio = () => {
    setSelected(selected.isRecorded ? {} : {
      name: audioName,
      audio: audio,
      isRecorded: true,
    });
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        centered
        onChange={handleChangeTab}
      >
        <Tab
          title='Sons Ringover'
          label='Campagne Vocal'
          value={0}
        />
        <Tab
          title='Sons Personnels'
          label='Campagne Appel'
          value={1}
        >
        </Tab>
      </Tabs>
      {tab === 0 && (
        <Grid
          padding={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} style={{ marginBottom: 20 }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <Grid item>

                <VoiceRecorder
                  addAudio={(base64, blob) => addAudio(base64,
                    blob,
                    setAudio,
                    setSelected
                  )} />
              </Grid>
              <Grid item>
                <TextField
                  label="Nom de l'audio"
                  variant="outlined"
                  value={audioName || ''}
                  onChange={(e) => setAudioName(e.target.value)}
                />
              </Grid>
              {
                audio &&
        <>
          <Button onClick={onSelectedAudio}
            variant="contained"
            color="secondary"
            style={{ marginLeft: 15, height: 40 }}
          >
            {selected.isRecorded ?
              'Désélectionner cet audio' :
              'Sélectionner cet audio'
            }
          </Button>
        </>
              }
            </Grid>
          </Grid>
          <CustomMaterialTable
            columns={[
              { title: 'Nom',
                field: 'name',
                render: ({ name }) => <Typography>{name}</Typography>
              },
              {
                title: 'Audio',
                field: 'path',
                render: rowData => (
                  <>
                    <LoadingComponentWithButton
                      onClick={() => listenAudio({
                        ...rowData,
                        setLoading,
                        setIdListener,
                        setAudioToListen
                      })}
                      style={{ marginLeft: 15, height: 40, width: 150 }}
                      variant="contained"
                      color="primary"
                      loading={loading && rowData.id === idListener}
                      textButton={'Ecouter'}
                      componentToDisplay={
                        rowData.id === idListener && audioToListen ? (
                          <AudioPlayer
                            autoPlay={true}
                            url={`data:audio/mp3;base64,${audioToListen}`}
                          />
                        ) : null
                      }
                    />
                    <Button
                      onClick={() => deleteAudio(rowData.id)}
                      variant="contained"
                      color="secondary"
                      style={{ marginLeft: 15, height: 40 }}
                    >
              Supprimer
                    </Button>
                  </>
                )
              }
            ]}
            data={callsRingover}
            title="Sons Ringover"
            options={{
              search: false,
              paging: true,
              pageSize: rowsPerPage,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 25, 50, 100],
              selection: true,
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              selectionProps: rowData => ({
                checked: selected.id === rowData.id,
              }),
            }}
            onChangeRowsPerPage={(pageSize) => setRowsPerPage(pageSize)}
            onSelectionChange={(_, rowData) => {
              handleRowClick(_, rowData, setSelected, selected);
            }}
          />
        </Grid>
      )}
      {tab === 1 && (
        <Grid
          padding={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <CustomMaterialTable
            columns={[
              { title: 'Login',
                field: 'login',
                render: ({ login }) => <Typography>{login}</Typography>
              },
              { title: 'TelephoneId',
                field: 'telephoneId',
                render: ({ telephoneId }) =>
                  <Typography>{telephoneId}</Typography>
              }
            ]}
            data={users.sort((a) => {
              if (selectedUsers.some(e => e._id === a._id)) {
                return -1;
              }
              return 1;
            })}
            title="Utilisateurs"
            options={{
              search: true,
              paging: true,
              pageSize: rowsPerPage,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 25, 50, 100],
              selection: true,
              showSelectAllCheckbox: true,
              selectionProps: rowData => ({
                checked: selectedUsers.some(e => e._id === rowData._id),
              }),
            }}
            onChangeRowsPerPage={(pageSize) => setRowsPerPage(pageSize)}
            onSelectionChange={(_, rowData) => {
              handleUsersRowClick(_,
                rowData,
                setSelectedUsers,
                selectedUsers
              );
            }}
          />

        </Grid>
      )}
    </>
  );
};

export default connect(mapStateToProps,
  mapDispatchToProps)(DialogRingoverCampaign);
