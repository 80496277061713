import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { withStyles } from 'tss-react/mui';

import { RT_CLI, RT_PJ } from '@lba-dev/package.local-globals/rTypes';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import { S_COMPTA, S_RH } from '@lba-dev/package.local-globals/services';

import { Menubar, Sidebar } from '../Dashboard';

import BottomSideBarStats from './sidebar/bottom/SideBarStat';

import notifSystem from '../../notifSystem';
import { addRecouvrement } from '../../actions/recouvrement';
import { openInterDemande } from '../../actions/inter';
import { Map } from 'immutable';
import store from '../../store';
import { withMediaQuery } from '../../hoc';
import ChatPanel from './ChatPanel';
import ws from '../../ws';
import { logoutUserCookie } from '../../utils/manageCookies';
import withRouter from '../../hoc/withRouter';
import { Outlet } from 'react-router';
import ManageTabs from '../ManageTabs';

const fun = {
  '/litige': (props) =>
    addRecouvrement(props, {
      type: RT_CLI,
      isLitige: true,
      title: 'litige',
    }),
  '/recouvrementClient': (props) =>
    addRecouvrement(props, {
      type: RT_CLI,
      isLitige: false,
      title: 'recouvrement Client',
    }),
  '/proceduresJudiciaires': (props) =>
    addRecouvrement(props, {
      type: RT_PJ,
      isLitige: false,
      title: 'Procédures Judiciaires',
    }),
  '/demandes': () =>
    store.dispatch(
      openInterDemande(new Map(), false, {
        source: 'Call Center',
        sChoice: 'DEMANDE INTERVENTION',
      })
    )
};

const mapStateToProps = ({ userId, users }) => ({
  user: users.find((u) => u._id === userId),
});

const styles = (theme) => ({
  elem: {
    position: 'absolute',
    top: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      top: 48,
    },
    [theme.breakpoints.up('sm')]: {
      top: 64,
    },
    bottom: 0,
    overflowY: 'overlay',
    overflowX: 'hidden',
  },
  sidebar: {
    backgroundColor: theme.palette.sideBar,
    zIndex: 3,
    width: 250,
    transition: 'left 0.3s',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 0,
    },
  },
  main: {
    left: 0,
    right: 0,
    backgroundColor: 'lightgray',
    transition: 'ease 0.3s',
    position: 'relative',
    width: '100%',
    display: 'flex',
  },
  SideBarStats: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  sideBarStatsBottomStyle: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 40,
    },
  },
  body: {
    width: '100%',
    height: '100%',
    padding: 16,
    paddingBottom: 0,
    boxSizing: 'border-box',
    margin: 'auto',
    flex: 'auto',
    position: 'relative',
    overflow: 'auto'
  },
  conversation: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    margin: 'auto',
  }
});

class Dash extends PureComponent {
  constructor(props) {
    super(props);
    const user = props?.auth?.decodedToken;
    this.state = {
      open: [S_COMPTA, S_RH].includes(user && user.service),
      dialogOpen: false,
      search: true,
      input: ''
    };
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.isBetween !== this.props.isBetween) {
      return { open: !this.state.open };
    }
    return null;
  }

  componentDidMount() {
    const { auth } = this.props;
    if (auth && auth.decodedToken) {
      ws.on(`update_cookie_${auth.decodedToken._id}`, (data) => {
        logoutUserCookie(data.cookie, auth.decodedToken);
      });
    }

    document.addEventListener('keydown', this.handleEscapeKey);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscapeKey);
  }

  openStats = () => this.setState((pS) => ({ openStats: !pS.openStats }));

  handleEscapeKey = (e) => e.key === 'Esc' && this.toggle();

  setDialog = (dialog, open, text, cb) =>
    this.setState({
      dialog,
      dialogOpen: open,
      dialogText: text,
      dialogCb: cb,
    });

  handleClickAccept = () => this.setState({ renderCgv: 2 });

  toggle = () => this.setState(({ open }) => ({ open: !open }));

  detectMobileToTablet = (open) => {
    const width = this.props.isDown.sm;
    const kind = !open ? '-' : '';
    const size = width ? '100%' : '250px';

    return `${kind}${size}`;
  };

  updateSearch = (event) => {
    const { search } = this.state;
    const { value } = event.target;

    if (event.key === 'Enter' && value.length >= 3) {
      if (!search) {
        if (new RegExp('^\\d+$').test(value)) {
          this.setState({ openDialog: true });
        } else {
          notifSystem.error(
            'Erreur',
            'Seuls les numéros sont acceptés pour la recherche d\'audio'
          );
        }
      } else {
        this.props.navigate(`/search/${event.target.value}`);
      }
    }
  };

  handlerMobileOpenSideBar = () => this.props.isDown.sm && this.toggle();

  handlerCloseSideBarClickLogo = () => this.setState({ open: false });

  render() {
    const { classes, navigate, user } = this.props;
    const { open, dialogOpen, dialogCb, dialogText } = this.state;
    const sideBarStatsBottom =
      user && permit(user, { key: 'sideBarStatsBottom' });
    const sideBar = user && permit(user, { key: 'sideBar' });
    const appBar = user && permit(user, { key: 'appBar' });
    const isChat = window.name === 'chat';

    return !isChat
      ? <>
        <Menubar
          open
          appBar={appBar}
          toogle={this.toggle}
          handlerCloseSideBarClickLogo={this.handlerCloseSideBarClickLogo}
        />
        {appBar && sideBar && (
          <div
            style={{
              left: open ? 0 : this.detectMobileToTablet(open),
            }}
            className={classNames(
              classes.sidebar,
              classes.elem,
              sideBarStatsBottom ? classes.sideBarStatsBottomStyle : ''
            )}
          >
            <Sidebar
              setDialog={(name) => {
                fun[name]({ setDialog: this.setDialog, user });
              }}
              navigate={navigate}
              handlerMobileOpenSideBar={this.handlerMobileOpenSideBar}
            />
          </div>
        )}
        <div
          className={classNames(
            classes.main,
            sideBarStatsBottom ? classes.sideBarStatsBottomStyle : ''
          )}
          id="maindiv"
          style={{
            left: sideBar && open ? this.detectMobileToTablet(open) : 0,
            width: `calc(100% - ${sideBar && open ? 250 : 0}px)`,
            height: `calc(100% - ${sideBarStatsBottom ? 104 : 64}px)`
          }}
        >
          {user && <div
            className={!isChat ? classes.body : classes.conversation}
          >
            <Outlet />
            <ManageTabs user_id={user._id} />
          </div>}
          {user && <ChatPanel />}
        </div>
        {this.props.isUp.sm && sideBarStatsBottom && (
          <BottomSideBarStats
            openStats={this.openStats}
            service={user.service}
          />
        )}
        {dialogOpen && (
          <this.state.dialog
            open={dialogOpen}
            callback={dialogCb}
            defaultText={dialogText}
          />
        )}
      </>
      : <div id="maindiv">
        <Outlet />
      </div>;
  }
}

export default compose(
  withMediaQuery(),
  connect(mapStateToProps),
  withRouter
)(withStyles(Dash, styles))
