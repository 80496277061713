import React from 'react';
import { connect } from 'react-redux';
import DetailItem from './DetailItem';
import DetailItemExtren from './DetailItemExtren';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import {
  PAS_DE_TEMPS,
  PAS_LE_TEMPS_POUR_DAUTRES_CLIENTS,
  blocked,
  data as statusData,
} from '@lba-dev/package.local-globals/statusProsp';
import { List } from 'immutable';
import store from '../../store';
import { setDialog } from '../../actions/dialog';

const mapStateToProps = ({ users, userId }) => ({
  user: users.find(e => e._id === userId)
});

const DetailItemComponent = ({ user, ...props }) => {
  const commentCallback = (message, openDialog) => {
    store.dispatch(setDialog());
    props.updateState('askStatus', false);

    if (!message) {
      return;
    }
    const { merge, data, filter, validate, interId } = props;
    let path = ['elem', 'obj', 'status'];
    let value = message.status;
    if (interId) {
      value = merge.getIn(['elem', 'obj', 'inters'], new List()).map((e) => {
        if (e.get('id', 0) === +data.get('interSearch', 0)) {
          e = e.set(
            data.getIn(['filters', filter, 'dispoStatus'])
              ? 'dispoStatus'
              : 'status',
            message.status
          );
        }
        return e;
      });
      path = ['elem', 'obj', 'inters'];
    }
    props.setData(['elem', 'obj', 'dates', 'statusChange'], new Date());
    props.setData(path, value, () => {
      if (blocked.includes(message.status)) {
        props.setData(['elem', 'obj', 'status'], message.status, validate);
      } else {
        validate();
      }
      if (
        [PAS_DE_TEMPS, PAS_LE_TEMPS_POUR_DAUTRES_CLIENTS].includes(
          message.status
        )
      ) {
        openDialog();
      }
    });
  };

  let status = props.merge.getIn(['elem', 'obj', 'status']);
  if (props.interId) {
    const inter = props.merge
      .getIn(['elem', 'obj', 'inters'], new List())
      .find((e) => e.get('id', 0) === +props.data.get('interSearch', 0));
    if (inter) {
      status =
        (props.data.getIn(['filters', props.filter, 'dispoStatus']) &&
          inter.get('dispoStatus')) ||
        inter.get('status');
    }
  }
  const setStatus = (query, step, openDialog) => {
    store.dispatch(
      setDialog({
        name: 'Prosp',
        open: true,
        dialogProps: {
          maxWidth: 'md',
        },
        contentProps: {
          filters: query,
          step,
          callback: (message) => commentCallback(message, openDialog),
        },
      })
    );
  };
  const setNote = (value = '') => {
    const { merge, data } = props;
    const valueToSet = merge.getIn(['elem', 'obj', 'inters'], new List())
      .map((e) => {
        if (e.get('id', 0) === +data.get('interSearch', 0)) {
          e = e.set('note', value);
        }
        return e;
      });
    props.setData(['elem', 'obj', 'inters'], valueToSet);
  };
  const statusItem = statusData.find((e) => e._id === status) || {};
  const communProps = {
    commentCallback,
    statusItem,
    setStatus,
    setNote,
  };
  return permit(user, { key: 'accesExterne' }) ? (
    <DetailItem {...props} {...communProps} />
  ) : (
    <DetailItemExtren {...props} {...communProps} />
  );
};

export default connect(mapStateToProps)(DetailItemComponent);
