import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { fromJS } from 'immutable';
import Grid from '@mui/material/Grid';
import Comments from '../../Comments';
import { C_COMPTA } from '@lba-dev/package.local-globals/commentTypes';
import PaymentTable from './PaymentTable';
import { setDialogContentProp } from '../../../actions/dialog';

const setComments = setDialogContentProp('comments');

const styles = {
  div: {
    width: '100%'
  },
};

const mapStateToProps = ({
  dialog: {
    contentProps: { id, _id, intervention },
  },
  users,
  userId,
}) => ({
  comments: intervention.comments || [],
  intervention,
  id,
  _id,
  userId,
  users,
  user: users.find(e => e._id === userId && e)
});
const mapDispatchToProps = {
  setComments,
};

const PaymentAnnexesDialog = ({
  classes,
  users,
  userId,
  user,
  comments,
  setComments,
  intervention
}) => (
  <div className={classes.div}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PaymentTable
          intervention={intervention}
          userId={userId}
          users={users}
          user={user}
        />
      </Grid>
      <Grid key={1} item xs={12} >
        <Comments
          id={intervention.id}
          interId={intervention._id}
          users={users}
          userId={userId}
          comments={fromJS(comments)}
          type={C_COMPTA}
          setData={(p, v) => setComments(v)}
        />
      </Grid>
    </Grid>
  </div>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(PaymentAnnexesDialog, styles));
