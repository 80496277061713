import React, { Component } from 'react';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';

class TableFoot extends Component {
  constructor() {
    super();

    this.updateRows = this.updateRows.bind(this);
    this.updatePage = this.updatePage.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return (nextProps.page !== this.props.page ||
      nextProps.count !== this.props.count ||
      nextProps.display !== this.props.display);
  }

  updateRows(event) {
    this.props.updateRows(event);
  }

  updatePage(event, page) {
    this.props.updatePage(event, page);
  }

  render() {
    const {
      page,
      count,
      display,
    } = this.props;

    return (
      <TableFooter>
        <TableRow>
          <TablePagination
            count={count || 0}
            rowsPerPage={display}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage={'Lignes par page:'}
            onRowsPerPageChange={this.updateRows}
            onPageChange={this.updatePage}
            page={page}
            labelDisplayedRows={
              ({ from, to, count }) => `${from}-${to} de ${count}`
            }
          />
        </TableRow>
      </TableFooter>
    );
  }
}

export default TableFoot;
