
/**
 * stopPropagation is a decorator for event-binded functions
 * in case you need to stop the propagation of the DOM event
 *
 * @param {Function} func - The wrapped function
 * @returns {Function}
 */
export default (func = f => f) => e => {
  e.stopPropagation();
  e.preventDefault();
  func(e);
};
