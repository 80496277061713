import { Map, List } from 'immutable';
import { PRODUCTS_FETCH } from '../constants/ActionTypes';

const initialState = {
  tree: new Map(),
  array: new List(),
};
export default (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS_FETCH: {
      return {
        array: action.payload,
        tree: action.payload
          .groupBy((e) => e.get('cat'))
          .map((a) => a.groupBy((e) => e.get('sCat'))),
      };
    }
    default:
      return state;
  }
};
