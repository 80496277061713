import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../actions/dialog';
import { Grid, TextField } from '@mui/material';
const setSent = setDialogContentProp('sent');
const setToken = setDialogContentProp('token');
const setErrorMessage = setDialogContentProp('errorMessage');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      sent,
      token = '',
      errorMessage = {
        error: false,
        message: '',
      }
    },
  },
}) => ({
  sent,
  token,
  errorMessage
});

const mapDispatchToProps = {
  setSent,
  setToken,
  setErrorMessage
};

class SendTokenDialog extends Component {

  handleToken = (value) => {
    const { setToken } = this.props;
    setToken(value);
  };

  render() {
    const {
      sent,
      token,
      errorMessage
    } = this.props;
    return (
      <Grid container>
        {
          sent &&
          <>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                value={token}
                onChange={(e) => {
                  this.handleToken(e.target.value);
                }}
                error={errorMessage.error}
                helperText={errorMessage.message}
                placeholder="Entrer le code"

              />
            </Grid>
          </>
        }
      </Grid>
    );}

}

export default connect(mapStateToProps, mapDispatchToProps)(SendTokenDialog);
