import {
  FOURNISSEURS_FETCH
} from '../constants/ActionTypes';

export default (state = [], action) => {
  switch (action.type) {
    case FOURNISSEURS_FETCH: {
      return action.payload;
    }
    default:
      return state;
  }
};
