import {
  data as financement,
} from '@lba-dev/package.local-globals/domofinance';
import { calculateAllPrices } from '@lba-dev/package.local-globals/formatPrice';
import notifSystem from '../notifSystem';
export const getRange = (price) => financement
  .find(e => price >= e.min && price <= e.max) || {};

export const getInterest = (m, d, c) => {
  if (m === 0 || d === 0 || c === 0) {
    return 0;
  }
  return ((m * d) - c);
};

/**
 * calculate monthly payment domofinance
 * @param {Number} a - loan price
 * @param {Number} d - loan duration
 * @param {Number} t - loan costs rate
 * @returns monthly payment domomfinance
 */
export const getMonthly = (a, d, t) => {
  a = !isNaN(a) ? a : 0;
  t = !isNaN(t) ? ((t / 100) / 12) : 0;
  if (t === 0 || a === 0) {
    return 0;
  }
  return ((a * t) / (1 - Math.pow(1 + t, -d))).toFixed(0);
};


export const initDomofinance = (setData, all) => {
  [
    'amount',
    'domofinance',
    'monthly',
    'interest',
    'contribution',
  ].forEach(e => {
    setData(['domofinance', e], 0);
  });
  if (all) {
    setData(['domofinance', 'report'], false);
    setData(['domofinance', 'modified'], false);
  }
};

/**
 * Calculate monthly payment and interest for domofinance
 * @param {Object} data - domofinance data
 * @param {Number} data.duration - loan duration
 * @param {Number} data.amount - loan amount
 * @param {Number} data.taux - loan costs rate
 * @param {Function} data.setData
 */
export const handleDomofinance = ({
  duration = 0, amount = 0,
  setData, taux
}) => {
  let monthly = 0, interest = 0;
  if (taux) {
    monthly = getMonthly(amount, duration, taux);
    interest = getInterest(monthly, duration, amount);
  }
  setData(['domofinance', 'monthly'], Number(monthly));
  setData(['domofinance', 'interest'], Number((interest || 0).toFixed(0)));
};

/**
 * Calculate auto domofinance with merge
 * @param {*} merge - data (devis/intervention)
 * @param {*} setData - function update merge
 * @param {*} newPrice - finalPrice if calculateAllPrices return 0
 */
export const calculateDomofinance = (merge, setData, newPrice) => {
  try {
    const products = merge.getIn(['products']).toJS();
    const prices = calculateAllPrices(products, newPrice);
    const financement = (prices.ttc * 100) -
      ((merge.get('ceeAmount') || 0) + (merge.get('anahAmount') || 0));
    const amount = financement > 0 ?
      Number((financement).toFixed(0)) -
    merge.get(['domofinance', 'contribution'], 0) : 0;
    const { defaultValue = 0, taux = 0 } = getRange(amount / 100) || {};
    return setData(['domofinance', 'amount'], amount, () => {
      setData(['domofinance', 'duration'], defaultValue);
      handleDomofinance({
        duration: defaultValue,
        amount, setData, taux
      });
    });
  } catch (e) {
    notifSystem.error('Erreur domofinance', e.message);
  }
};


export const getFinanceConsignes = (data) =>
  data.isCaldeo
    ? 'Obligatoire: Prendre des photos AVANT/PENDANT/APRES ' +
    'l\'instalation pour le dossier \n\n'
    : '';
