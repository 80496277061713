import componentLoader from '../../componentLoader';

import {
  NOT_CONFIRMED,
  USER,
} from '@lba-dev/package.local-globals/permissions';

import compta from './compta';
import rh from './rh';
import cti from './cti';
import btob from './btob';
import devis from './devis';
import intervention from './intervention';
import partenariat from './partenariat';
import quality from './quality';
import savIntervention from './savIntervention';
import utils from './utils';
import achat from './achat';
import conversation from './conversation';

const Stats = componentLoader(() =>
  import(
    /* webpackChunkName: "Stats" */
    '../../views/Stats'
  )
);

const ReportPage = componentLoader(() =>
  import(
    /* webpackChunkName: "ReportPage" */
    '../../views/ReportPage'
  )
);

const Search = componentLoader(() =>
  import(
    /* webpackChunkName: "Search" */
    '../../views/Search'
  )
);


export default [
  ...intervention,
  ...quality,
  ...devis,
  ...partenariat,
  ...compta,
  ...savIntervention,
  ...btob,
  ...rh,
  ...cti,
  ...achat,
  ...utils,
  ...conversation,
  {
    path: '/signalements',
    component: ReportPage,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/search/:text',
    component: Search,
    restrictions: () => USER,
  },
  {
    path: '*',
    component: Stats,
    restrictions: () => USER,
  },
];
