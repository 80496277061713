import React, { Component } from 'react';

import Button from '@mui/material/Button';
import Input from '@mui/material/Input';

import MinCustomDialog from '../MinCustomDialog';
import notifSystem from '../../../notifSystem';

class CommentDialog extends Component {
  state = {
    text: '',
    loading: false
  }

  actions = [
    {
      title: 'Envoyer',
      onClick: this.sendComment
    },
    {
      title: 'Fermer',
      onClick: this.props.callbacks.close
    }
  ]

  sendComment = () => {
    const { text } = this.state;
    const { callbacks } = this.props;

    if (!text || !(typeof text === 'string')) {
      return;
    }
    this.setState((s) => ({ ...s, loading: true }));
    callbacks.comment(text)
      .then(() => {
        callbacks.close();
        notifSystem.success(
          'Signalement',
          'Le commentaire a bien été enregistré'
        );
        if (typeof callbacks.reload === 'function') {
          callbacks.reload();
        }
      }).catch(e => notifSystem.error(
        'Signalement',
        (e.response && e.response.data) || e.message
      ))
      .finally(() => this.setState((s) => ({ ...s, loading: false })));
  }

  handleChangeText = text =>
    this.setState({
      text
    })

  render() {
    const {
      label = 'Commenter le signalement'
    } = this.props;

    return <MinCustomDialog
      middle
      open
      title={label}
      actions={
        this.actions
          .map(({ title, onClick }, i) =>
            <Button
              disabled={this.state.loading}
              key={i}
              onClick={onClick || this.sendComment}
            >
              {title}
            </Button>
          )}
    >
      <Input
        fullWidth
        placeholder="Commenter..."
        onContextMenu={(e) => {
          e.stopPropagation();
        }}
        disableUnderline={true}
        onChange={e => this.handleChangeText(e.target.value)}
        multiline={true}
        rows={10}/>
    </MinCustomDialog>;
  }
}

export default CommentDialog;
