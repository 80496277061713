import {
  data as demandeStatus
} from '@lba-dev/package.local-globals/demandeStatus';
import {
  data as categories
} from '@lba-dev/package.local-globals/categories';
import { S_LEAD, S_INT } from '@lba-dev/package.local-globals/services';
import { ADMIN } from '@lba-dev/package.local-globals/permissions';

import { Types } from '../../utils/List/Types';
import { callBackFunction } from '../../components/Utils/ListFunctions';

export const listData = ({ user, users }) => {
  switch (user.service) {
    case S_LEAD:
    case S_INT: {
      const columns = {
        table: {
          '': Types.SelectForCampaign,
          Source: Types.Text,
          Telepro: Types.Text,
          Categorie: Types.DemandeCat,
          Client: Types.DemandeClient,
          Date: Types.Date,
          Appel: Types.Date,
          S_Choix: Types.Text,
          Choix: Types.Text,
          'Code postal': Types.DemandeZipCode,
          ...(
            user.permission === ADMIN ||
            user.service === S_LEAD ?
              { Téléphone: Types.Text } : {}
          ),
          Statut: Types.Rectangle,

        },
        tableData: [
          { class: '' },
          { class: '' },
          { class: 'cat', list: users, key: 'login' },
          { class: 'cat', list: categories },
          { class: 'th' },
          { class: '' },
          { class: '' },
          { class: '' },
          { class: '' },
          { class: '' },
          ...(user.permission === ADMIN ||
            user.service === S_LEAD ? [{ class: '' }] : []),
          { class: '', list: demandeStatus },
        ],
        keys: [
          '',
          'source',
          'login.ajout',
          'categorie',
          [
            'client.civility', 'client.name',
            'client.firstname', 'client.fullName'
          ],
          'date.ajout',
          'date.firstCall',
          'choice',
          'sChoice',
          'client.zipCode',
          ...(user.permission === ADMIN ||
            user.service === S_LEAD ? ['client.tel1'] : []),
          'status',
        ],
        checkType: [
          '',
          'source',
          'users',
          'categorie',
          ['client.civility', 'client.name',
            'client.firstname', 'client.fullName'],
          'date',
          'firstCall',
          'choice',
          'sChoice',
          'client.zipCode',
          ...(user.permission === ADMIN ||
            user.service === S_LEAD ? ['client.tel1'] : []),
          'dStatus',

        ],
      };
      return columns;
    }
    default: {
      return {
        table: {
          '': Types.SelectForCampaign,
          Source: Types.Text,
          Telepro: Types.Text,
          Categorie: Types.DemandeCat,
          Client: Types.DemandeClient,
          Date: Types.Date,
          Appel: Types.Date,
          S_Choix: Types.Text,
          Choix: Types.Text,
          'Code postal': Types.DemandeZipCode,
          Statut: Types.Rectangle,

        },
        tableData: [
          { class: '' },
          { class: '' },
          { class: 'cat', list: users, key: 'login' },
          { class: 'cat', list: categories },
          { class: 'th' },
          { class: '' },
          { class: '' },
          { class: '' },
          { class: '' },
          { class: '' },
          { class: '', list: demandeStatus },
        ],
        keys: [
          '',
          'source',
          'login.ajout',
          'categorie',
          ['client.civility', 'client.name',
            'client.firstname', 'client.fullName'],
          'date.ajout',
          'date.firstCall',
          'choice',
          'sChoice',
          'client.zipCode',
          'status',

        ],
        checkType: [
          '',
          'source',
          'users',
          'categorie',
          ['client.civility', 'client.name',
            'client.firstname', 'client.fullName'],
          'date',
          'firstCall',
          'choice',
          'sChoice',
          'client.zipCode',
          'dStatus',
        ],
      };
    }
  }
}

export const functionType = [
  {
    type: 'source',
    callback: callBackFunction['source'],
  },
  {
    type: 'categorie',
    callback: callBackFunction['categorie'],
  },
  {
    type: 'dStatus',
    callback: callBackFunction['dStatus'],
  },
  {
    type: 'firstCall',
    callback: callBackFunction['firstCall'],
  },
  {
    type: 'choice',
    callback: callBackFunction['choice'],
  },
  {
    type: 'sChoice',
    callback: callBackFunction['sChoice'],
  },
  {
    type: 'client.zipCode',
    callback: callBackFunction['clientZipCode'],
  }
];

export const format = (demande) => ({
  ...demande,
  id: demande._id,
  externalId: demande.id
});

export const project = [
  '_id',
  'comments',
  'messages.dumb',
  'artisans.status',
  'called',
  'groups',
  'intervention',
  'devis',
  'login',
  'source',
  'client',
  'date',
  'choice',
  'sChoice',
  'status',
  'reminders',
  'createdFrom',
  'artisan',
  'billing',
  'products',
  'onSitePayment',
  'description',
  'dropbox',
  'categorie',
  'consignes',
  'context',
  'flexHour',
  'flexDate',
  'definHClient',
  'definDClient',
  'infoDesc',
  'pdfName',
  'finalPrice',
  'id',
  'statusSignature',
  'currentArtisan',
  'raisonRefus',
  'takeOver',
];
