import React, { PureComponent } from 'react';
import {
  data as pmData,
  C_NO,
  C_CHQ,
} from '@lba-dev/package.local-globals/cotePaymentMeans';
import MinCustomDialog from '../MinCustomDialog';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';
import { getTime, generateTime } from '../../../utils/date';
import MinCustomForm from '../../Utils/MinCustomForm';
import DatePicker from '../../DatePicker';
import TimePicker from '../../TimePicker';
import notifSystem from '../../../notifSystem';
import MenuItem from '@mui/material/MenuItem';
import CustomTextField from '../../CustomInputs/CustomTextField';
import CustomSelect from '../../CustomInputs/CustomSelect';
import MoneyInput from '../../CustomInputs/MoneyInput';

const styles = {
  dateContainer: {
    margin: 20,
  },
  formControl: {
    margin: 15,
  },
};

const shapes = ['Carré', 'Rectangle', 'Rond', 'Demi-lune'];

const types = [
  'VERRE CLAIR (SIMPLE)',
  'VERRE FEUILLETÉ - VITRINE',
  'VERRE TREMPÉ',
  'DOUBLE VITRAGE',
  'TRIPLE VITRAGE',
  'VERRE CATHÉDRALE',
  'VITRAGE',
  'MIROIRS',
];

const retrievedOptions = [
  { value: '1', label: 'Oui' },
  { value: '0', label: 'Non' },
];

const dialogErrors = ({
  dimension, pattern, shape,
  type, price,
  measurePerformedPayment,
  retrieved,
  date,
}) => [
  {
    error: !Object.values(dimension).every(e => e > 0),
    message: 'Veuillez indiquer les dimension du verre',
  },
  { error: !pattern, message: 'Veuillez indiquer le type de vitrage' },
  { error: !shape, message: 'Veuillez indiquer la forme du vitrage' },
  {
    error: !type,
    message: 'Veuillez indiquer la typologie exact du verre',
  },
  { error: !price, message: 'Veuillez indiquer le prix max du SST' },
  {
    error: !pmData.some((d) => d._id === measurePerformedPayment),
    message: 'Le moyen de paiement indiqué n\'existe pas',
    title: 'Prise de côte',
  },
  {
    error: retrieved && measurePerformedPayment === C_NO,
    title: 'Prise de côte',
    message: 'Les données fournies semblent incohérentes',
  },
  {
    error: !date || new Date() > date,
    title: 'Prise de côte',
    message: 'La nouvelle date d\'intervention donnée n\'est plus valide',
  },
];

const paymentMeanOptions = pmData.reduce((a, v) => {
  if (v._id !== C_NO) {
    a.push({
      value: v._id.toString(),
      label: v.name,
    });
  }
  return a;
}, []);

export class PriseDeCoteDialog extends PureComponent {
  state = {
    retrieved: +this.props.retrieved,
    measurePerformedPayment: this.props.measurePerformedPayment,
    date: generateTime(this.props.date),
    dimension: { width: 0, height: 0 }
  };

  validate = () => {
    const { callback } = this.props;
    if (!callback || typeof callback.send !== 'function') {
      return;
    }

    const error = dialogErrors(this.state).find((e) => e.error);
    if (error) {
      notifSystem.error(error.title || 'Erreur', error.message);
      return false;
    }
    if (callback && typeof callback.send === 'function') {
      callback
        .send(this.state)
        .then(() => {
          notifSystem.success(
            'Prise de côte',
            'Les modifications ont bien été enregistrées'
          );
        })
        .catch((e) =>
          notifSystem.error('Prise de côte', e.response.data || e.message)
        );
    }
  };

  handleChangeForm = (field) => (e) => {
    const value = Number(e.target.value);
    this.setState({ [field]: value });
  };

  handleChangeRetrieved = (e) => {
    this.setState({
      retrieved: Number(e.target.value),
      measurePerformedPayment: +e.target.value ? C_CHQ : C_NO,
    });
  };

  setDate = (date) => {
    this.setState({ date });
    if (typeof this.props.setDate === 'function') {
      this.props.setDate(date);
    }
  };

  handleChangeDate = (value) => this.setDate(generateTime(value));

  handleChangeDimension = (value, side) => {
    this.setState(prevState => ({
      dimension: {
        ...prevState.dimension,
        [side]: +value
      }
    }));
  }

  handleChange = (name, value) =>
    this.setState({
      [name]: value,
    });

  formatAndSetDateFromTime = (value) => {
    const [hour, minutes] = value.split(':');
    const date = new Date(this.state.date);

    date.setHours(
      Number.parseInt(hour, 10),
      Number.parseInt(minutes, 10),
      0,
      0
    );
    this.setDate(date);
  };

  render() {
    const {
      retrieved,
      measurePerformedPayment,
      date,
      shape, type, price, pattern,
    } = this.state;
    const { defaultText, callback, classes } = this.props;
    return (
      <MinCustomDialog
        open
        title={defaultText.title}
        middle
        fullWidth
        actions={[
          <Button
            key={1}
            color="secondary"
            onClick={callback.close}
            children="Fermer"
          />,
          <Button
            key={3}
            color="primary"
            onClick={this.validate}
            children="Valider"
          />
        ]}
      >
        {
          <Grid container>
            <Grid item xs={6}>
              <MinCustomForm
                classes={{ formControl: classes.formControl }}
                title="L'acompte a-t-il été récupéré ?"
                value={retrieved.toString()}
                handleChange={this.handleChangeRetrieved}
                options={retrievedOptions}
                inline
              />
            </Grid>
            <Grid item xs={6}>
              {!!retrieved && (
                <MinCustomForm
                  classes={{ formControl: classes.formControl }}
                  title="Acompte récupéré par"
                  value={measurePerformedPayment.toString()}
                  handleChange={
                    this.handleChangeForm('measurePerformedPayment')
                  }
                  options={paymentMeanOptions}
                  inline
                />
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <CustomTextField
                  label="Longeur (mm)"
                  helperText="Dimension du verre"
                  setData={(p, v) => this.handleChangeDimension(v, 'width')}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  label="Hauteur (mm)"
                  setData={(p, v) => this.handleChangeDimension(v, 'height')}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  label="4/16/4 etc."
                  helperText="Type de verre"
                  setData={(p, v) => this.handleChange('pattern', v)}
                  value={pattern || ''}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomSelect
                  label="Forme du vitrage"
                  grid
                  value={shape}
                  path={['']}
                  setData={(p, v) => this.handleChange('shape', v)}
                >
                  {shapes.map((e, i) => (
                    <MenuItem key={i} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>
              <Grid item xs={4}>
                <CustomSelect
                  label="Typologie du vitrage"
                  grid
                  value={type}
                  path={['']}
                  setData={(p, v) => this.handleChange('type', v)}
                >
                  {types.map((e, i) => (
                    <MenuItem key={i} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>
              <Grid item xs={4}>
                <MoneyInput
                  label="Prix max HT du SST"
                  defaultValue={price || 0}
                  path={['']}
                  setData={(p, v) => this.handleChange('price', v)}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  label="Nouvelle date d'intervention"
                  startDate={date}
                  onChange={(c) => this.handleChangeDate(c.toDate())}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  label="Heure d'intervention"
                  name="date"
                  value={getTime(date)}
                  onChange={(e) =>
                    this.formatAndSetDateFromTime(e.target.value)
                  }
                  labelRemoveSpace={true}
                  size="100%"
                />
              </Grid>
            </Grid>
          </Grid>
        }
      </MinCustomDialog>
    );
  }
}

PriseDeCoteDialog.defaultProps = {
  retrieved: 1,
  measurePerformedPayment: C_CHQ,
  date: new Date(),
};

export default withStyles(PriseDeCoteDialog, styles);
