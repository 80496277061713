import React from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import { Check, ExpandMore } from '@mui/icons-material';
import { moment } from '@lba-dev/package.local-globals/moment';

const DisplayMail = ({ classes, email, index, reply }) => (
  <React.Fragment>
    <AccordionSummary
      expandIcon={<ExpandMore />}
      aria-controls={`panel-${index}`}
      id={`"header-${index}"`}
      onClick={() => reply(email)}
    >
      <Grid container>
        <Grid item className={classes.icons}>
          <Typography
            children={`Objet: ${email.subject}`}
            variant="subtitle2"
          />
        </Grid>
        {email.login.answered &&
          <Grid item style={{ padding: '0 10px' }} className={classes.icons}>
            <Tooltip disableInteractive title="Vous avez répondu depuis la v3">
              <Check color="primary" />
            </Tooltip>
          </Grid>}
        <Grid item className={`${classes.icons} ${classes.alignRight}`}>
          <Typography
            children={`Reçu le ${
              moment(email.date.envoi).format('LL à HH[h]mm')
            }`}
            variant="caption" color="textSecondary"
          />
        </Grid>
        <Grid item xs={12} className={classes.email}>
          <Typography
            children={`De: ${email.from}`}
            variant="caption" color="textSecondary"
          />
        </Grid>
        <Grid item xs={12} className={classes.email}>
          <Typography
            children={`À: ${email.to}`}
            variant="caption" color="textSecondary"
          />
        </Grid>
      </Grid>
    </AccordionSummary>
  </React.Fragment>
);

export default DisplayMail;
