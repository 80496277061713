/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { withStyles } from 'tss-react/mui';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';

const styles = {
  line: {
    lineHeight: 'normal',
  },
  a: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '-webkit-link',
  },
};

const DevisMenuInfo = ({
  type,
  date,
  user,
  obj,
  message,
  text,
  classes,
}) =>
  !!date && (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography color="textSecondary">{date}</Typography>
        <Typography color="textSecondary">{user}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Typography
          variant="body1"
          style={obj && obj.style ? obj.style : {}}
          className={classes.lineHeight}
        >{
            `${type || ''}${message || text ? ` : ${message || text}` : ''}`}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );

export default withStyles(DevisMenuInfo, styles);
