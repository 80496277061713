import {
  METIERSDEVIS_FETCH,
  METIERSDEVIS_UPDATE
} from '../constants/ActionTypes';
import DbWsAction from './models/DbWsAction';

const action = new DbWsAction(
  METIERSDEVIS_FETCH,
  METIERSDEVIS_UPDATE
);

export const fetch = action.fetch;
export const update = action.update;

export * from './communMetiersActions';
