import React, { PureComponent } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
class Footer extends PureComponent {
  render() {
    const { send, cancel, loading } = this.props;
    return (
      <DialogActions>
        <Button
          component="span"
          disabled={loading}
          onClick={() => send(true)}
          style={{ color: green[500] }}
        >
          {loading && <CircularProgress
            size={24}
          />}
            Valider et prise de contact
        </Button>
        <Button onClick={cancel} color="secondary">
            Annuler
        </Button>
        <Button
          disabled={loading}
          onClick={() => send(false)} color="primary">
          {loading && <CircularProgress
            size={24}
          />}
            Valider
        </Button>
      </DialogActions>
    );
  }
}

export default Footer;
