import React, { PureComponent } from 'react';
import { Send } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import CustomTextField from '../../../components/CustomInputs/CustomTextField';
import { SUGG_MAIL } from '@lba-dev/package.local-globals/configTypes';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
const mapStateToProps = ({ config = [] }) => ({
  config,
});

class ReplyComponent extends PureComponent {
  state = {
    mailSuggestion: this.props.config.filter((e) => e.type === SUGG_MAIL),
  };
  render() {
    const {
      state: { recipient, subject, body },
      updateData,
      classes,
      send,
    } = this.props;
    const { mailSuggestion = [] } = this.state;
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            grid
            key={0}
            xs={12}
            texttransform="none"
            label="Destinataire"
            path="recipient"
            value={recipient}
            setData={(p, v) => updateData(p, v)}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            grid
            key={1}
            xs={12}
            texttransform="none"
            label="Objet"
            value={subject}
            path="subject"
            setData={(p, v) => updateData(p, v)}
          />
        </Grid>
        <Grid item xs={12}>
          {mailSuggestion.map((e, i) => (
            <Tooltip key={i} disableInteractive title={e.text}>
              <Button
                className={classes.mailSuggest}
                children={<span>{e.text}</span>}
                variant="outlined"
                color="primary"
                onClick={() => updateData(['body'], e.text)}
              />
            </Tooltip>
          ))}
        </Grid>
        <Grid item xs={11}>
          <CustomTextField
            key={2}
            grid
            xs={12}
            texttransform="none"
            label="Message"
            placeholder="Votre réponse..."
            multiline
            rows={6}
            path="body"
            value={body}
            rowsMax={8}
            setData={(p, v) => updateData(p, v)}
          />
        </Grid>
        <Grid item xs={1} className={classes.send}>
          <Tooltip disableInteractive title="Envoyer">
            <Fab color="primary" onClick={() => send()}>
              <Send />
            </Fab>
          </Tooltip>
        </Grid>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(ReplyComponent);
