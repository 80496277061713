import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import { LocationOn, Search } from '@mui/icons-material';
import CustomAddress from '../CustomInputs/CustomAddress';
import classNames from 'classnames';

class CustumHeader extends Component {
  state = {
    showSearchbar: false,
    location: false,
    searchIcon: false,
  };

  LocationClick = () => {
    this.setState(state => ({
      showSearchbar: !state.location,
      location: !state.location,
      searchIcon: false,
    }));
  };

  SearchClick = () => {
    this.setState(state => ({
      showSearchbar: !state.searchIcon,
      searchIcon: !state.searchIcon,
      location: false,
    }));
  };

  KeyPress = e => {
    if (e.key === 'Enter') {
      this.props.updateData('searchKey', e.target.value);
    }
  };

  render() {
    const { showSearchbar, location, searchIcon } = this.state;
    const {
      address,
      setAddress,
      updateAddress,
      classes,
      isInterSearch
    } = this.props;
    return (
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        className={classes.GridContainer}
      >
        <Grid item xs={11}>
          <Zoom
            in={showSearchbar}
            style={{ transitionDelay: showSearchbar ? 200 : 0 }}
          >
            <div>
              {location && (
                <CustomAddress
                  updateAddress={updateAddress}
                  setAddress={setAddress}
                  address={address}
                  placeholder="Adresse prosp"
                  adresseText={`MapInput ${classes.AdresseText}`}
                />
              )}
              <TextField
                variant="standard"
                fullWidth
                onKeyDown={this.KeyPress}
                style={{ display: searchIcon ? 'block' : 'none' }}
                placeholder={
                  'Nom , prénom , email , téléphone, Numéro d\'intervention'
                }
                InputProps={{
                  disableUnderline: true,
                }}
                className={classNames(`MapInput ${classes.SearchText}`)} />
            </div>
          </Zoom>
        </Grid>
        <Grid xs={1} item>
          <Fab variant="extended" aria-label="Search">
            {!isInterSearch && <LocationOn
              style={
                location
                  ? { fontSize: 35, color: '#2196f3', marginRight: 10 }
                  : { fontSize: 25, marginRight: 10 }
              }
              onClick={this.LocationClick}
            />
            }
            <Search
              style={
                searchIcon
                  ? { fontSize: 35, color: '#2196f3' }
                  : { fontSize: 25 }
              }
              onClick={this.SearchClick}
            />
          </Fab>
        </Grid>
      </Grid>
    );
  }
}

const styles = {
  GridContainer: {
    width: '30%',
    position: 'absolute',
    zIndex: 10,
    right: 150,
    top: 34,
  },
  AdresseText: {
    background: '#e0e0e0',
    paddingTop: 16,
    paddingRight: 51,
    paddingBottom: 16,
    paddingInlineStart: 16,
    borderRadius: 6,
  },
  SearchText: {
    background: '#e0e0e0',
    paddingTop: 16,
    paddingRight: 51,
    paddingBottom: 16,
    paddingInlineStart: 16,
    borderRadius: 6,
  },
};

CustumHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(CustumHeader, styles);
