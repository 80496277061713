import {
  generateInstructionsSMS
} from '@lba-dev/package.local-globals/smsConstants';
import {
  COMP,
  generateDefaultReminderMail,
} from '@lba-dev/package.local-globals/dossier';
import { data } from '@lba-dev/package.local-globals/historyCat';

import { TextDialog } from '../components/Dialogs';
import DialogsFacturier from '../components/Dialogs/historyFacturier';
import SignalSST from '../components/Dialogs/SignalSST';
import ArchiveSST from '../components/Dialogs/ArchiveSST';
import ReminderDialog from '../components/Dialogs/Relance/ReminderDialog';
import ConfirmBox from '../components/Dialogs/ConfirmBox';
import IphoneDialog from '../components/Dialogs/IphoneDialog';
import DocumentsDialog from '../components/Dialogs/Relance/DocumentsDialog';
import api from '../api';
import notifSystem from '../notifSystem';
import { addSignalement } from './signalements';
import { changeStatus } from '@lba-dev/package.local-globals/TypeFacturier';
import { createReminder, sendReminder } from './artisan';
import { call } from '../utils/call';
import store from '../store';
import { setDialog } from './dialog';

export function signalement(props, artisan) {
  function commentCallback(message) {
    props.setDialog(null, false, '', null);
    if (message) {
      addSignalement(message.comment, message._id, null, artisan.id);
    }
  }
  props.setDialog(
    SignalSST,
    true,
    { ...artisan, signalType: 2 },
    commentCallback
  );
}

export function archive(props, artisan) {
  function commentCallback(message) {
    props.setDialog(null, false, '', null);
    if (message) {
      api
        .one('artisans', artisan.id)
        .custom('archive')
        .post({
          motif: `${message.motif}: ${message.comment}`,
        })
        .then(() =>
          notifSystem.success('Opération réussie', 'Artisans archivé')
        )
        .catch((e) => notifSystem.error(e.name, e.message));
    }
  }
  props.setDialog(ArchiveSST, true, { ...artisan }, commentCallback);
}

export function utilsAssets({ filters, ...data }, cb) {
  api.artisans
    .custom('assetSend')
    .post({
      query: JSON.stringify(filters),
      ...data,
    })
    .then(() => {
      if (cb) {
        cb();
      }
      notifSystem.success('Opération réussie', 'Informations envoyées');
    })
    .catch((e) =>
      notifSystem.error(e.name, e.response ? e.response.data : e.message)
    );
}

export function utilsAssetsCount({ filters, collec }, cb) {
  const newFilters = JSON.parse(JSON.stringify(filters));
  if (Object.keys(newFilters).length) {
    return api[collec]
      .getAll({
        query: JSON.stringify(newFilters),
        call: 'countDocuments',
      })
      .then((e) => {
        if (cb) {
          cb(e.body().data());
        }
      })
      .catch((e) => {
        notifSystem.error(e.name, e.response ? e.response.data : e.message);
        return 0;
      });
  }
  return cb(0);
}

export function comment(props, inter, cb) {
  function commentCallback(message, type) {
    props.setDialog(null, false, '', null);
    if (message) {
      api.artisans.custom('comments')
        .post({
          _id: props.selected._id,
          type,
          commentValue: message,
          date: new Date().getTime(),
        })
        .then(() => {
          notifSystem.success('Opération réussie', 'Commentaire envoyé');
          if (cb) {
            cb();
          }
        })
        .catch((e) =>
          notifSystem.error(e.name, e.response ? e.response.data : e)
        );
    }
  }
  props.setDialog(
    TextDialog,
    true,
    { type: 'comments', data: (props.selected || {}).comments },
    commentCallback
  );
}

export function tutelle(props, artisan, out = false) {
  props.setDialog(
    ConfirmBox,
    true,
    {
      title: `Etes-vous sûr de vouloir le ${
        out ? 'sortir' : 'mettre'
      } sous Tutelle ?`,
      confirmBox: 'Oui',
      closeBox: 'Annuler',
    },
    (confirmed) => {
      if (confirmed) {
        api
          .one('artisans', artisan.id)
          .custom('tutelle')
          .post()
          .then(() =>
            notifSystem.success(
              'Opération réussie',
              out ? 'Artisans mis sous tutelle' : 'Artisan sorti de tutelle'
            )
          )
          .catch(() =>
            notifSystem.error('Erreur', 'L\'action n\' pas pu être réalisée')
          );
      }
      props.setDialog(null, false, null, null);
    }
  );
}

export function facturierArtisan(props, artisan, type) {
  const element = data.find((elem) => elem._id === changeStatus[type]);
  props.setDialog(
    ConfirmBox,
    true,
    {
      title: `Etes-vous sûr de vouloir faire une ${element.name} ?`,
      confirmBox: 'Oui',
      closeBox: 'Annuler',
    },
    (confirmed) => {
      if (confirmed) {
        api
          .one('artisans', artisan.id)
          .custom('demandeDocs')
          .post({ type })
          .then(() =>
            notifSystem.success(
              'Opération réussie',
              `Votre demande de ${element.name} a été effectuée`
            )
          )
          .catch((e) =>
            notifSystem.error(e.name, e.response ? e.response.data : e)
          );
      }
      props.setDialog(null, false, null, null);
    }
  );
}

export function historyPackArtisan(props, artisan) {
  function historyPackCallback() {
    props.setDialog(null, false, '', null);
  }
  props.setDialog(DialogsFacturier, true, artisan, historyPackCallback);
}

const callAction = async ({ intervention, artisan, prosp }, number) => {
  let res = '';
  let docKey = '';
  if (intervention) {
    docKey = typeof intervention.get('id') === 'string' ?
      'savId' : 'interventionId';
  }
  res = await api.appels.custom('add').post({
    ...(intervention || artisan
      ? { artisanId: artisan.get('_id') || intervention.get('artisan') }
      : {}),
    ...(intervention && artisan && intervention.hasIn(['demande', 'id'])
      ? { demandeId: intervention.getIn(['demande', 'id']) }
      : {}),
    ...(intervention ? { [docKey]: intervention.get('id') } : {}),
    ...(prosp ? { prospId: prosp.get('_id') } : {}),
    ...(artisan && artisan.get('companyName')
      ? { companyName: artisan.get('companyName') }
      : {}),
    number,
  });
  res = res.body().data().id;
  call(number);
  return res;
};

export async function appel(props, number) {
  try {
    if (props.artisan) {
      const lastCalls = await api.records
        .custom('getLastCalls')
        .get({
          artisanId: props.artisan.get('_id'),
          ...(props.intervention ? {
            interventionId: props.intervention.get('id')
          } : {}),
        })
        .then((res) => res.body().data());
      if (lastCalls.client.length || lastCalls.artisan.length) {
        return store.dispatch(
          setDialog({
            dialogProps: {
              middleAll: true,
              title: 'Derniers appels',
            },
            contentProps: { lastCalls },
            name: 'LastCallsDialog',
            open: true,
            actions: [
              {
                children: 'Appeler',
                color: 'primary',
                onClick: (_, close) => {
                  callAction(props, number);
                  return close();
                },
              },
            ],
          })
        );
      }
    }
    return callAction(props, number);
  } catch (e) {
    notifSystem.error(e.name, e.message);
  }
}

export function unarchive(props, artisan, cb) {
  function comment(message) {
    props.setDialog(null, false, '', null);
    if (!message) {
      return false;
    }
    if (cb) {
      return cb(message);
    }
    api
      .one('artisans', artisan._id)
      .custom('unarchive')
      .post({ comment: message })
      .then(() => {
        notifSystem.success('Opération réussie', 'Suppression Archivage');
      })
      .catch((e) => notifSystem.error(e.name, e.message));
  }
  props.setDialog(
    TextDialog,
    true,
    {
      type: '',
      title: props.text || 'Veuillez ajouter un commentaire'
    },
    comment);
}

export function absences(props, artisan) {
  const callback = () => {
    store.dispatch(setDialog({}));
  }
  return store.dispatch(setDialog({
    name: 'AbsencesDialog',
    open: true,
    hideClose: true,
    dialogProps: {
      maxWidth: 'md',
      title: `Absences de l'artisan
        ${artisan.companyName ||
          `${artisan.firstname || ''}
        ${artisan.name || ''}`}`
    },
    contentProps: {
      callback,
      artisan
    },
    actions: [
      {
        children: 'fermer',
        color: 'secondary',
        onClick: (_, close) => close(),
      }
    ]
  }));
}

export function sendInstructions({ setDialog, user }, artisan) {
  setDialog(
    IphoneDialog,
    true,
    {
      text: generateInstructionsSMS(artisan, user),
    },
    (message) => {
      setDialog(null, false, '', null);
      if (!message || typeof message !== 'string') {
        return;
      }
      api.artisans
        .custom(`sendInstructions/${artisan._id}`)
        .post({ message })
        .then(() =>
          notifSystem.success(
            'Opération réussie',
            'L\'artisan a bien reçu les instructions'
          )
        )
        .catch((e) => notifSystem.error(e.name, e.response ?
          e.response.data : e));
    }
  );
}

export function permanentArchive(props, artisan) {
  api.artisans
    .custom(`${artisan.id}/permanentlyArchive`)
    .patch()
    .then(() =>
      notifSystem.success(
        'Archivage',
        'L\'artisan a bien été archivé définitivement'
      )
    )
    .catch(() =>
      notifSystem.error(
        'Erreur',
        'L\'artisan n\'a pas pû être archivé définitivement'
      )
    );
}

export const callReminderDialog = ({ setDialog }, artisan) => {
  if (artisan.dropbox && artisan._dossier === COMP) {
    return notifSystem.error(
      'Relance documents',
      'L\'artisan possède un dossier complet'
    );
  }
  setDialog(
    ReminderDialog,
    true,
    {
      title: `Envoyer un mail de relance à ${artisan.companyName}`,
      defaultValue: generateDefaultReminderMail(),
      googleCalendarTitle: `Relance concernant le dossier de ${
        artisan.companyName} (SST n°${artisan.id})`,
    },
    {
      close: () => setDialog(null, false, null, null),
      send: async (comment, date, historyComment) =>
        Promise.all([
          await sendReminder(artisan.id, comment, historyComment),
          await createReminder(artisan.id, comment, date, historyComment),
        ]),
    }
  );
};

export const callDocumentsDialog = ({ setDialog }, artisan) =>
  setDialog(
    DocumentsDialog,
    true,
    {
      _id: artisan._id,
      id: artisan.id,
      name: artisan.companyName,
      dropbox: artisan.dropbox,
    },
    {
      close: () => setDialog(null, false, null, null),
      send: (dropbox) => api.artisans.patch(artisan._id, { dropbox }),
    }
  );

export function takeChargeDialog(props, id, cb) {
  props.setDialog(
    ConfirmBox,
    true,
    {
      title: 'Etes-vous sûr ?',
    },
    (confirmed) => {
      if (confirmed) {
        api.artisans
          .custom(`${id}/takeCharge`)
          .post({})
          .then(() => {
            if (cb) {
              cb();
            }
            notifSystem.success(
              'Appropriation',
              'Votre demande a bien été prise en compte.'
            );
          })
          .catch(() =>
            notifSystem.error(
              'Appropriation',
              'Votre demande n\'a pas été prise en compte.'
            )
          );
      }
      props.setDialog(null, false, null, null);
    }
  );
}
