import collections from './collections';

export const chatCollections = [
  collections.sms,
];


export default [
  collections.interventions,
  collections.devis,
  collections.artisans,
  collections.recouvrements,
  collections.savInterventions,
  collections.signalements,
  collections.btobs,
  collections.candidats,
  collections.grandCompte,
  collections.invoices,
  collections.demandes,
  collections.users,
  collections.reglements,
  collections.paiements,
  collections.encaissements,
  collections.sms,
  collections.mailReceived,
  collections.points,
  collections.suppliesInvoices,
  collections.absences
];
