import React from 'react';
import { connect } from 'react-redux';
import DemandeList from '../../List/Demande/DemandeList';

const mapStateToProps = ({
  users,
  userId,
  dialog: {
    contentProps: {
      filter,
      service,
      sort = { id: -1 },
      count = 10,
      handlerFilterByColumns,
      filteredByColumn = false,
      displayName = 'default'
    },
  },
}) => ({
  filter,
  service,
  sort,
  handlerFilterByColumns,
  filteredByColumn,
  count,
  displayName,
  users,
  user: users.find(s => s._id === userId)
});

const DemandeListDialog = ({
  filter,
  service,
  sort,
  count,
  handlerFilterByColumns,
  filteredByColumn,
}) => (
  <DemandeList
    small
    filter={filter}
    count={count}
    filteredByColumn={filteredByColumn}
    service={service}
    sort={sort}
    handlerFilterByColumns={handlerFilterByColumns}
  />
);
export default connect(mapStateToProps)(DemandeListDialog);
