import {
  AttachMoney,
  LibraryBooks,
  Person,
  LocalOffer,
  List,
  LibraryAddCheck,
  Update,
  Archive,
  Assessment,
  DateRange,
  AccountBalance,
  MoneyOff,
} from '@mui/icons-material';
import Build from '@mui/icons-material/Build';

export default [
  {
    name: 'Client',
    icon: Person,
    childs: [
      {
        name: 'Remise de Chèques',
        icon: LibraryBooks,
        link: '/compta/remiseChq',
      },
      {
        name: 'Liste de remise',
        icon: List,
        link: '/compta/listRemiseCheque',
      },
      {
        name: 'Liste de chèque sans os',
        icon: List,
        link: '/compta/listRglmtNoOs',
      },
      {
        name: 'Avoirs',
        icon: LocalOffer,
        link: '/compta/listAvoir',
      },
      {
        name: 'Gestion Grand Comptes',
        icon: AttachMoney,
        link: '/config/grandCompte',
      },
    ],
  },
  {
    name: 'Artisans',
    icon: LibraryBooks,
    childs: [
      {
        name: 'Intervenant à payer',
        icon: AttachMoney,
        link: '/compta/listInterventionApayer',
      },
      {
        name: 'Relance Fournitures',
        icon: DateRange,
        link: '/compta/relFour',
      },
      {
        name: 'List Paiement Att.',
        icon: AccountBalance,
        link: '/compta/lpa',
      },
      {
        name: 'Interventions en caution',
        icon: List,
        link: '/intervention/list/caution',
      },
      {
        name: 'Interventions en dette',
        icon: MoneyOff,
        link: '/intervention/list/dette',
      },
    ],
  },
  {
    name: 'Charges annexes',
    icon: LibraryAddCheck,
    childs: [
      {
        name: 'Pré-validation',
        icon: List,
        link: '/compta/listPaymentAnnexes/prevalidation',
      },
      {
        name: 'En cours',
        icon: Update,
        link: '/compta/listPaymentAnnexes',
      },
      {
        name: 'Archive',
        icon: Archive,
        link: '/compta/listPaymentAnnexes/archives',
      },
      {
        name: 'Statistiques',
        icon: Assessment,
        link: '/compta/statPaymentAnnexes'
      }
    ],
  },
  {
    name: 'Archives',
    icon: LocalOffer,
    childs: [
      {
        name: 'Archives Reglements Clt.',
        icon: LibraryBooks,
        link: '/compta/archivesReglement',
      },
      {
        name: 'Archives Paiement Sst.',
        icon: LibraryBooks,
        link: '/compta/archivesPaiements',
      },
      {
        name: 'Archives Avoirs',
        icon: LibraryBooks,
        link: '/compta/archivesAvoirs',
      },
      {
        name: 'Archives Remises',
        icon: LibraryBooks,
        link: '/compta/archivesRemises',
      },
    ],
  },
  {
    name: 'Import encaissement',
    icon: Build,
    link: '/compta/importencaissement',
  },
];
