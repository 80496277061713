import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { withStyles } from 'tss-react/mui';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import company from '@lba-dev/package.local-globals/company';

import { logout } from '../../actions/auth';

const styles = theme => ({
  companyName: {
    minWidth: 172,
    marginRight: 20,
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      minWidth: 50
    },
  },
  flex: {
    flex: 1,
  },
  AppBarPos: {
    position: 'relative',
    zIndex: 1,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  headerDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

export class MenuBar extends PureComponent {
  state = {};

  render() {
    const { classes, logout } = this.props;

    return (
      <AppBar position="static" className={classes.AppBarPos}
        enableColorOnDark>
        <Toolbar className={classes.headerDiv}>
          <Link className={classes.link} to="/dashboard">
            <Typography
              variant="h6"
              color="inherit"
              className={classes.companyName}
              children={company.name}
            />
          </Link>
          <Button
            color="inherit"
            onClick={logout}
            children="Logout"
          />
        </Toolbar>
      </AppBar>
    );
  }
}

export default connect(null, { logout })(withStyles(MenuBar, styles));
