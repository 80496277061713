import React from 'react';

import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import getDataById from '@lba-dev/package.local-globals/getDataById';
import { CompTER, CompRPROG, CompANN } from './components/';
import styles from './styles';
import MenuHeader from './MenuHeader';
import { withDisplaying } from '../../../hoc';
import { P_NONE } from '@lba-dev/package.local-globals/isValidStatus';

const components = [CompTER, CompRPROG, CompANN];

const PropositionMenu = ({
  elem, classes, setProposition, restrictions
}) => {
  const proposition = elem.proposition || {};
  const status = getDataById['propositionInter'][proposition.status] || {};
  const statusColor = status.color && status.color['500'];

  const setData = (p, v) => {
    setProposition({ ...elem, proposition: { ...proposition, [p]: v } });
  };
  return (
    <React.Fragment>
      <Grid container spacing={4} className={classes.padding}>
        <MenuHeader
          statusColor={statusColor}
          elem={elem}
          status={status}
        />
        {status._id && proposition &&
          components[status._id - 1](elem, classes, {
            setData,
            restrictions,
            showInput: !!setProposition && proposition.isValid === P_NONE
          })}
      </Grid>
    </React.Fragment>
  );
};

export default withDisplaying()(withStyles(PropositionMenu, styles));
