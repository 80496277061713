import React from 'react';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import { setDialogContentProp } from '../../actions/dialog';
import CustomTextField from '../CustomInputs/CustomTextField';
import Grid from '@mui/material/Grid';
const setMotifCancel = setDialogContentProp('motifCancel');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      defaultText,
      motifCancel,
    },
  },
}) => ({
  defaultText,
  motifCancel,
});


const mapDispatchToProps = {
  setMotifCancel
};

const ConfirmDialog = ({
  defaultText = { subtitle: '', warningMessage: '' },
  motifCancel,
  setMotifCancel
}) => <Grid container justifyContent="center">
  <Grid item xs={12}>
    {defaultText.warningMessage && defaultText.warningMessage.length
      ? <Typography
        align="center"
        variant="subtitle2"
        children={`⚠️⚠️ ${defaultText.warningMessage} ⚠️⚠️`}
      />
      : ''}
  </Grid>
  <Grid item xs={12}>
    {!!defaultText.subtitle &&
      defaultText.subtitle.split('\n')
        .map((e, i) => <Typography key={i} align="center" children={e} />)}
  </Grid>
  <Grid item xs={12} paddingTop={2}>
    {defaultText.contentText && defaultText.contentText.length
      ? <Typography
        align="center"
        variant="subtitle2"
        children={`${defaultText.contentText}`}
      />
      : ''}
  </Grid>
  <Grid item xs={12}>
    {defaultText.listText && defaultText.listText.length
      ? defaultText.listText.filter(str =>
        str.trim().length > 0).map((text, key) => (
        <Typography
          align="justify"
          variant="subtitle2"
          children={`${text}`}
          key={key}
        />
      ))
      : ''}
  </Grid>
  <Grid item xs={10}>
    {defaultText.hasTextField &&
      <CustomTextField
        grid xs={12}
        label={defaultText.label || 'Motif*'}
        value={motifCancel}
        setData={(p, v) => setMotifCancel(v)}
        {...(defaultText.textFieldProps || {})}
      />}
  </Grid>
</Grid>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmDialog);
