import React from 'react';

import Grid from '@mui/material/Grid';
import Calls from './Calls';
import { cacheDisabled } from '../../utils/function';
import { SHOW_ENR } from '@lba-dev/package.local-globals/configTypes';
import { Typography } from '@mui/material';

const CallToAndBack = ({ sm, inter, elem, classes }) =>
  !cacheDisabled(SHOW_ENR) && (
    <Grid item xs={12} sm={sm || 4}>
      <Typography key={1} variant="h6" className={classes.title}>
        CALL TO & CALL BACK
      </Typography>
      <Calls
        sm={12}
        elem={elem}
        keyUrl="urlEnregistrement"
        inter={inter}
      />
    </Grid>
  );


export default CallToAndBack;
