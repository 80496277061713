import {
  ADD_SHOW_COLUMN_MARKER,
  setColumnsShowMarkers
} from '../actions/etatReseau';

const addShowColumnMarker = (store) => next => (action) => {
  switch (action.type) {
    case ADD_SHOW_COLUMN_MARKER: {
      const columns = store.getState().etatReseau.columns;
      if (columns.includes(action.column.split('_')[2])) {
        columns.splice(columns.indexOf(action.column.split('_')[2]), 1);
      } else {
        columns.push(action.column.split('_')[2]);
      }
      store.dispatch(setColumnsShowMarkers(columns));
    }
  }
  next(action);
};

export default addShowColumnMarker;
