import React, { useState } from 'react';
import DevisActions from './DevisActions';
import DevisMenu from './DevisMenu';
import { callBackFunction } from '../../Utils/ListFunctions';
import { List as listData } from '../../../utils/List/ListeDevis';
import collections from '../../../constants/collections';
import ListLogic from '../ListLogic';

const functionType = [
  {
    type: 'categorie',
    callback: callBackFunction['categorie'],
  },
];

const DevisList = (props) => {
  const {

    name = collections.devis.capitalize(),
    listen = collections.devis,
    handlerFilterByColumns,
    filteredByColumn = true,
    sort,
    filterName,
    count,
    filter,
    list
  } = props;

  const [display] = useState(count || 25);

  const newListData = (list || listData().default.dataFormat);

  if (filterName !== 'default' && Object.keys(filter).length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <ListLogic
        {...newListData}
        functionType={functionType}
        project={[]}
        name={name}
        actions={DevisActions}
        collection={listen}
        filteredByColumn={filteredByColumn}
        handlerFilterByColumns={handlerFilterByColumns}
        listen={listen}
        filter={filter}
        count={display}
        sort={sort}
        menu={DevisMenu}
      />
    </React.Fragment>
  );
};

export default DevisList;
