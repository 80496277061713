import React from 'react';
import MinCustomDialog from '../Dialogs/MinCustomDialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const DialogForDate = ({ open, time, changeTime,
  optionRappel, updateState, artisanMissing, classes }) => (
  <MinCustomDialog
    open={open}
    onClose={() => updateState('open', false)}
    maxWidth={'xs'}
    title="Prochaine disponibilité"
    actions={[<Button className={classes.button}
      onClick={artisanMissing} key={1}
      size="small" color="secondary">
      Absence
    </Button>, <Button className={classes.button} onClick={optionRappel} key={1}
      size="small" color="primary">
      Rappel
    </Button>]}
    middle
  >
    <div style={{ padding: 30 }}>
      <form noValidate>
        <TextField
          variant="standard"
          value={time}
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={changeTime} />
      </form>
    </div>
  </MinCustomDialog>
);

export default (DialogForDate);
