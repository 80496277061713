import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { Add } from '@mui/icons-material';
import green from '@mui/material/colors/green';
import { List, fromJS } from 'immutable';
import { AddressProsp } from '.';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  textField: {
    width: 180,
    marginRight: 10
  },
  bootstrapRoot: {
    padding: 0,
    'label + &': {
    }
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: 'white',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  },
  bootstrapFormLabel: {
    fontSize: 18
  },
  divContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  divAdd: {
    width: '100%',
  },
  fabGreen: {
    width: '10%',
    marginTop: 40,
    minWidth: 0,
    color: theme.palette.common.white,
    backgroundColor: green[500],
  },
  fabGreen2: {
    width: '10%',
    minWidth: 0,
    color: theme.palette.common.white,
    backgroundColor: green[500],
  },
  container: { position: 'relative' }
});

const initForfait = {
  name: '',
  number: ''
};

class InfoArtisanPlus extends PureComponent {

  state = {
    forfait: initForfait
  };

  onChangeForfait = name => event => {
    let { forfait } = this.state;
    this.setState({ forfait: {
      ...forfait,
      [name]: event.target.type === 'number' ?
        parseInt(event.target.value, 10) || 0 :
        event.target.value
    } });
  };

  addForfait = () => {
    const { merge, setData } = this.props;
    const { forfait } = this.state;
    let forfaits = merge.getIn(['elem', 'obj', 'forfaits'], new List());
    forfaits = forfaits.push(fromJS(forfait));
    this.setState({ forfait: initForfait });
    setData( ['elem', 'obj', 'forfaits'], forfaits);
  };

  setData = path => event => {
    this.props.setData(path,
      event.target.type === 'number' ? parseInt(event.target.value, 10) || 0 :
        event.target.value);
  };

  getTextField = () => {
    const { merge } = this.props;
    const ArrayTextField = [];
    merge.getIn(['elem', 'obj', 'forfaits'], [])
      .forEach((d, i) => ArrayTextField.push({
        path: ['elem', 'obj', 'forfaits', i, 'name'],
        width: 200,
        type: 'text',
        placeholder: 'forfait',
        index: i,
        disabled: true
      },
      {
        path: ['elem', 'obj', 'forfaits', i, 'number'],
        width: 150,
        type: 'number',
        placeholder: 'number',
        index: i,
        disabled: true
      }));
    ArrayTextField.push(
      { path: ['elem', 'obj', 'tauxHoraire'], width: 180,
        type: 'number', placeholder: 'Taux horaire', disabled: false },
      { path: ['elem', 'obj', 'deplacement'], width: 180,
        type: 'number', placeholder: 'Déplacement', disabled: false },
    );
    return ArrayTextField;
  };

  render() {
    const { classes, merge, setData } = this.props;
    const { forfait } = this.state;
    const ArrayTextField = this.getTextField();
    const InputLabelProps = {
      shrink: true,
      className: classes.bootstrapFormLabel
    };
    const InputProps = {
      disableUnderline: true,
      classes: {
        root: classes.bootstrapRoot,
        input: classes.bootstrapInput
      }
    };
    return (
      <CardContent className={classes.divContainer}>
        <div className={classes.divAdd}>
          <TextField
            variant="standard"
            style={{ width: '50%' }}
            placeholder={'Prestation'}
            className={classes.textField}
            value={forfait.name}
            margin="normal"
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.bootstrapRoot,
                input: classes.bootstrapInput
              }
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.bootstrapFormLabel
            }}
            onChange={this.onChangeForfait('name')} />
          <TextField
            variant="standard"
            style={{ width: '30%' }}
            placeholder={'forfait'}
            className={classes.textField}
            value={forfait.number}
            type="number"
            margin="normal"
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.bootstrapRoot,
                input: classes.bootstrapInput
              }
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.bootstrapFormLabel
            }}
            onChange={this.onChangeForfait('number')} />
          <Tooltip disableInteractive title="Ajouter un forfait">
            <Fab
              color="inherit"
              size="small"
              onClick={() => this.addForfait()}
              className={classes.fabGreen2}
              children={<Add />}
            />
          </Tooltip>
        </div>
        {
          ArrayTextField.map((el, i) => (
            <div key={i}
              style={{ width: el.width }}>
              <TextField
                variant="standard"
                disabled={el.disabled}
                style={{ width: el.width }}
                placeholder={el.placeholder}
                className={classes.textField}
                value={el.type === 'number' ?
                  String(merge.getIn(el.path, '') || '').replace(/ /g, '') :
                  String(merge.getIn(el.path, '') || '') }
                margin="normal"
                type={el.type}
                InputProps={InputProps}
                InputLabelProps={InputLabelProps}
                onChange={this.setData(el.path)} />
            </div>
          ))
        }
        <AddressProsp
          merge={merge}
          setData={setData}
          path={['elem', 'obj', 'address']}
        />
      </CardContent>
    );
  }
}


InfoArtisanPlus.propTypes = {
  classes: PropTypes.object.isRequired,
  setData: PropTypes.func,
};

export default withStyles(InfoArtisanPlus, styles);
