import notifSystem from '../notifSystem';
import Grid from '@mui/material/Grid';
import store from '../store';
import { setDialog } from './dialog';
import { fromJS, Map } from 'immutable';
import React from 'react';
import api from '../api';
import DisplayMetier from '../components/MetiersSteps/DisplayMetier';
import OldQuestion from '../components/MetiersSteps/DisplayMetier/OldQuestion';

export const displayMetierDialog = ({ title = '', maxWidth = 'lg', content }) =>
  store.dispatch(
    setDialog({
      name: 'CustomDialog',
      open: true,
      dialogProps: {
        title,
        maxWidth: maxWidth,
      },
      contentProps: {
        content,
        boxProps: {
          width: '100%',
        },
      },
    })
  );

export const getElementFromApi = (id, collection) =>
  api[collection]
    .getAll({
      query: JSON.stringify({ id }),
    })
    .then((e) => e.body().map((e) => e.data())[0]);

export const displayMetierInList = async (metiersSteps = {}, collection) => {
  const element = await getElementFromApi(metiersSteps.id, collection);
  const answers = fromJS(metiersSteps.answers || new Map());
  const suggestions = fromJS(metiersSteps.suggestions || new Map());
  const infoComps = fromJS(metiersSteps.infoComps || new Map());
  if (metiersSteps.id && element) {
    displayMetierDialog({
      title: 'Récapitulatif de votre intervention',
      maxWidth: 'xl',
      content: <Grid container spacing={2}>
        <DisplayMetier
          element={element}
          answers={answers}
          suggestions={suggestions}
          infoComps={infoComps}
          setAnswers={(e) => e}
        />
      </Grid>
    });
  } else {
    displayMetierDialog({
      title: 'Récapitulatif de votre intervention',
      maxWidth: 'xl',
      content: <OldQuestion metiersSteps={metiersSteps} />
    });
  }
};

export const copyItems = (
  newItems,
  { setParentData, variable = 'questions', dataType = 'metiersSteps' }
) =>
  store.dispatch(
    setDialog({
      name: 'DialogConfigurateurCopy',
      open: true,
      hideClose: true,
      dialogProps: { maxWidth: 'lg' },
      contentProps: {
        onlyFinalChild: true,
        dataType,
        copyFunc: (item, callback) => {
          const items = Array.isArray(item) ? item : [item];
          Promise.all(
            items.map((e) => {
              const data = [...(e[variable] || [])].concat(newItems);

              return api[dataType].patch(e._id, {
                [variable]: data,
              });
            })
          )
            .then(() => {
              items.forEach((e) => {
                if (setParentData && e._id) {
                  const data = [...(e[variable] || [])].concat(newItems);
                  setParentData(e._id, { [variable]: data });
                }
              });
              notifSystem.success(
                'Operation réussi',
                `Les ${variable} ont bien été copiés`
              );
            })
            .catch(() => {
              notifSystem.error(
                'Erreur system',
                'Il y a eu une erreur lors de la copie'
              );
            })
            .finally(() => {
              if (callback) {
                callback();
              }
            });
        },
      },
    })
  );

export const getSearch = ({ collection, query, sort, limit, match }) =>
  api[collection]
    .custom('search')
    .get({
      query,
      page: 0,
      display: 4,
      sort,
      match,
      limit
    })
    .then(res => res.body().map(e => e.data()));
