import React from 'react';

import SecondaryContent from './SecondaryContent';
import ItemText from './ItemText';

const ItemDetails = (props) => <>
  <ItemText {...props} />
  <SecondaryContent {...props} />
</>;

export default ItemDetails;
