import React from 'react';

import { withStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';

import CommentList from './CommentList';

const styles = {
  commentList: {
    margin: 0,
    width: '100%'
  },
  container: {
    margin: 'auto',
    width: '95%'
  },
  inputContainer: {
    padding: 12,
    borderBottom: '1px solid #e5e5e5'
  }
};

const CommentListWithInput = ({
  classes,
  className = classes.container,
  action = f => f,
  ...props
}) => {
  const onEnter = e => {
    if (e.key === 'Enter') {
      action(e.target.value);
      e.target.value = '';
    }
  };

  return (
    <div className={className}>
      <CommentList classes={{ commentList: classes.commentList }} {...props} />
      <div className={classes.inputContainer}>
        <TextField
          variant="standard"
          onKeyDown={onEnter}
          placeholder="Entrez un commentaire..."
          fullWidth
          rows={1} />
      </div>
    </div>
  );
};
export default withStyles(CommentListWithInput, styles);
