import collections from '../constants/collections';
import notifSystem from '../notifSystem';
import {
  MAIL_REGEX
} from '@lba-dev/package.local-globals/regexps';
import {
  checkIsValidNumber
} from '@lba-dev/package.local-globals/verifyNumber';

export const checkInfosClient = (keys = [], names = []) => (content = {}) =>
  keys.reduce((a, v, i) => {
    const data = content[v.key];
    if (v.keys) {
      if (data && data.length) {
        data.forEach(elem => {
          a.push(
            ...checkInfosClient(
              v.keys,
              names[i]
            )(elem)
          );
        });
      } else {
        a.push(
          ...checkInfosClient(
            v.keys,
            names[i]
          )(data)
        );
      }
    } else if (v.values) {
      if (!v.values.includes(data)) {
        a.push(names[i]);
      }
    } else if (!content[v]) {
      a.push(names[i]);
    }
    return a;
  }, []);

export const verifyClientInfos = (data = {}) => {
  let fieldNames = ['name', 'firstname'];
  let displayNames = ['Nom', 'Prénom'];
  if (data.client && data.client.grandCompte) {
    fieldNames = ['name'];
    displayNames = ['Nom'];
  } else if (data.client && data.client.civility === 'Société') {
    fieldNames = ['society'];
    displayNames = ['Société'];
  }
  const errors = checkInfosClient(
    [
      'civility',
      ...fieldNames,
      'tel1',
      {
        key: 'address',
        keys: ['city', 'number', 'road', 'zipcode'],
      },
    ],
    [
      'Civilité',
      ...displayNames,
      'Téléphone 1',
      ['Ville', 'Numéro', 'Adresse', 'Code postal'],
    ]
  )(data.client);

  if (errors.length) {
    notifSystem.error(
      'Informations client',
      `${errors.join(', ')} incomplets`
    );
    return false;
  }
  return true;
};

export const checkPhoneAndMail = (fields = [[], []]) => (content = {}) => {
  const checkFormat = [
    (d) => checkIsValidNumber(d),
    (d) => new RegExp(MAIL_REGEX).test(d)
  ];

  return fields.map((e, i) => e.reduce((a, k) => {
    if (content[k] && !checkFormat[i](content[k])) {
      a.push(content[k]);
    }
    return a;
  }, []));
};

export const commonCheckPhoneAndMail = checkPhoneAndMail([
  ['tel1', 'tel2', 'tel3'],
  ['email1', 'email2']
]);

/**
 * check if phone and email are not empty and in correct format
 *
 * @param {Object} data - object of data to check
 * @param {*} type - collections
 * @returns Boolean
 */
export const verifyPhoneAndMail = (
  data = {}, type = collections.interventions
) => {
  let errors = [];
  if ([collections.interventions, collections.devis].includes(type)) {
    const clientErrors = commonCheckPhoneAndMail(data.client);
    errors = [...clientErrors[0], ...clientErrors[1]];
    if (!data.onSitePayment) {
      const billingErrors = commonCheckPhoneAndMail(data.billing);
      errors = errors.concat([...billingErrors[0], ...billingErrors[1]]);
    }
  } else {
    const companyErrors = commonCheckPhoneAndMail(data);
    errors = errors.concat([...companyErrors[0], ...companyErrors[1]]);
  }
  if (errors.length) {
    notifSystem.error(
      'Téléphones et e-mails',
      `${errors.join(', ')} à modifier`
    );
    return false;
  }
  return true;
};
