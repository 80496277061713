import React from 'react';
import Grid from '@mui/material/Grid';
import DatePicker from '../../DatePicker';
import TimePicker from '../../TimePicker';
import { getTime } from '../../../utils/date';
import { setDialogContentProp } from '../../../actions/dialog';
import store from '../../../store';
import { moment } from '@lba-dev/package.local-globals/moment';

const setDate = setDialogContentProp('dateInter');

const changeHour = (value, date) => {
  const [hour, minute] = value.split(':');
  const newDate = moment(date).set({ hour, minute }).toDate();
  store.dispatch(setDate(newDate));
};

const PickDateInter = ({ date }) => (
  <Grid container
    justifyContent="center"
    spacing={2}
    style={{ minHeight: 320 }}
  >
    <Grid item xs={4}>
      <DatePicker
        label="Nouvelle date d'intervention"
        startDate={date}
        onChange={v => store.dispatch(setDate(v.toDate()))}
      />
    </Grid>
    <Grid item xs={4}>
      <TimePicker
        label="Heure d'intervention"
        name="date"
        value={getTime(date)}
        onChange={(e) => changeHour(e.target.value, date)}
        labelRemoveSpace={true}
        size="100%"
      />
    </Grid>
  </Grid>
);
export default PickDateInter;
