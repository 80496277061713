import React from 'react';
import { PropTypes } from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { withStyles } from 'tss-react/mui';
import { Close, ChevronLeft } from '@mui/icons-material';

const styles = () => ({
  button: {
    float: 'right',
  },
  buttonLeft: {
    float: 'left',
  },
});
const StepsHeaders = [
  'Choisissez une raison d\'annulation',
  'Voulez vous prévenir l\'artisan par sms et mail de l\'annulation ?',
];
const Header = ({ classes, callback, steps, returnAction, isAnother }) => (
  <Grid container spacing={1} alignItems="center">
    <Grid item xs={1}>
      {!!steps && (
        <IconButton
          className={classes.buttonLeft}
          onClick={returnAction}
          size="large"
        >
          <ChevronLeft />
        </IconButton>
      )}
    </Grid>
    <Grid item xs={10}>
      <Typography align="left" variant="h6">
        {isAnother
          ? StepsHeaders[steps]
          : 'Merci de préciser la raison d\'annulation'}
      </Typography>
    </Grid>
    <Grid item xs={1}>
      <IconButton
        className={classes.button}
        onClick={() => callback(null)}
        size="large"
      >
        <Close />
      </IconButton>
    </Grid>
  </Grid>
);

Header.propTypes = {
  returnAction: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  steps: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  isAnother: PropTypes.bool.isRequired,
};
export default withStyles(Header, styles);
