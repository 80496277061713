
export const getSpecificKeys = ({ name, id, demandeId = '' }) => ({
  appels: {
    string: 'savId',
    number: 'interventionId',
  }[typeof id],
  idADemarcher: {
    string: 'savId',
    number: 'interventionId',
  }[typeof id],
  history: {
    name: {
      Devis: 'devisId',
      Interventions: 'osId',
      SavInterventions: 'osId',
    }[name?.toFirstUpperCase()],
    id: {
      Devis: 'id',
      Interventions: 'id',
      SavInterventions: 'interId',
    }[name?.toFirstUpperCase()],
  },
  appelsArtisan: {
    $or: [
      { [typeof id === 'string' ? 'savId' : 'interventionId']: id },
      ...(demandeId ? [{ demandeId, 'data.choice': { $exists: false } }] : []),
    ],
  },
});
