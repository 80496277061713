import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from 'tss-react/mui';

import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import api from '../../../api';
import Calls from '../../Calls/Calls';
import menuContainer from '../Intervention/interMenuContainer';
import InterMenuInfo from '../Intervention/InterMenuInfo';
import Comments from '../../Comments';
import { C_ACHAT, C_SAV } from '@lba-dev/package.local-globals/commentTypes';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import { fromJS } from 'immutable';
import HistoryTimeLine from '../../InterHistory/HistoryTimeLine';
import Money from '../../../utils/Money';
import withRouter from '../../../hoc/withRouter';
import {
  A_DEM,
  A_DEMC,
  A_DEMT,
  data
} from '@lba-dev/package.local-globals/statusAchat';

const getStatus = (query) => ({
  SSTDemWarranty: A_DEM,
  SSTDemCWarranty: A_DEMC,
  SSTDemTWarranty: A_DEMT,
})[query];

const styles = {
  padding: {
    padding: '12px',
  },
  lineHeight: {
    lineHeight: 'normal',
    overflowWrap: 'break-word',
  },
  title: {
    marginBottom: 4,
    textTransform: 'uppercase',
    fontSize: '1rem',
  },
  a: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  timeLine: {
    margin: 0,
    padding: '6px 0px',
  },
  timeLineLimit: {
    maxHeight: 300,
    overflow: 'auto',
  },
  table: {
    border: '1px solid #e0e0e0',
    backgroundColor: 'rgb(245, 245, 245)',
  },
};

class AchatMenu extends Component {
  state = {
    open: false,
    data: '',
  };

  getDevis = async (id) => {
    const res = await api.devis.get(id);

    this.setState({
      open: true,
      data: res.body().data(),
    });
  };

  render() {
    const {
      elem = {},
      appels = [],
      users,
      userId,
      user,
      signalements,
      classes,
      params: { filter }
    } = this.props;
    const warranty = elem?.supplies?.reduce((arr, curr) => {
      if (curr.warranty) {
        curr.warranty.map(e => arr.push({ ...e, pro: curr.pro }));
      }
      return arr;
    }, []).filter(e => e.status === getStatus(filter));

    return (
      <React.Fragment>
        <Grid key="achatMenu" container spacing={4} className={classes.padding}>
          {!!signalements.length && (
            <Grid item xs={12} md={4}>
              <Typography key={5} variant="h6" className={classes.title}>
                Signalements
              </Typography>
              {signalements.map((data, i) => (
                <InterMenuInfo key={i} {...data} />
              ))}
            </Grid>
          )}
          {<Grid item xs={12} md={4} >
            <Typography key={5} variant="h6" className={classes.title}>
              Commentaires achat
            </Typography>
            <Comments
              users={users}
              userId={userId}
              id={elem.id}
              interId={elem._id}
              elevation={null}
              title=''
              comments={fromJS(elem?.comments?.filter(e => e.type === C_ACHAT)
                .reverse().slice(0, 4).reverse())}
              hideDelete={!permit(user, { key: 'deleteComments' })}
              type={C_ACHAT}
            />
          </Grid>}
          {<Grid item xs={12} md={4} >
            <Typography key={5} variant="h6" className={classes.title}>
             Commentaires SAV
            </Typography>
            <Comments
              users={users}
              userId={userId}
              id={elem.id}
              interId={elem._id}
              elevation={null}
              title=''
              comments={fromJS(elem?.comments?.filter(e => e.type === C_SAV)
                .reverse().slice(0, 4).reverse())}
              hideDelete={!permit(user, { key: 'deleteComments' })}
              type={C_SAV}
            />
          </Grid>}
          <Grid item xs={12} md={4}>
            {elem.date &&
              elem.login && [
              <Typography key={5} variant="h6" className={classes.title}>
                  Historique
              </Typography>,
              <HistoryTimeLine key={6} elem={elem} name={'Interventions'} />
            ]}
          </Grid>
          <Calls
            elem={appels}
            title="Appels vers l'artisan"
            keyUrl="url"
          />
          {!elem.signalAchat && <Grid item xs={12} md={4}>
            <Typography variant="h6" className={classes.title}>
                Informations Fournitures
            </Typography>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {[
                    'Fournisseurs',
                    'N° Facture',
                    'Montant avoir attendu',
                    'Status'
                  ].map(title => <TableCell
                    key={title}
                    align="center"
                    children={title}
                  />)
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {warranty.map((e, i) => (
                  <TableRow key={i}>
                    <TableCell key={'supplier'} align="center"
                      children={e.pro || 'EDISON'}
                    />
                    <TableCell key={'billNumber'} align="center"
                      children={(e.billNumber).toUpperCase()}
                    />
                    <TableCell key={'price'} align="center"
                      children={Money.toString(e.price, '€')}
                    />
                    <TableCell align="center">
                      {data.find(s => s._id === e.status)?.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>}
        </Grid>
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  menuContainer,
)(withStyles(AchatMenu, styles));
