import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { KeyboardBackspace } from '@mui/icons-material';
import { withMediaQuery } from '../../hoc';

const styles = theme => ({
  content: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    margin: theme.spacing(1),
  },
  dialogtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

const MinCustomDialog = ({
  title,
  children,
  actions,
  middle,
  middleAll,
  classes,
  dialogClasses,
  returnButton,
  fullScreenOn,
  customHeader,
  dividers = false,
  isDown,
  ...props
}) => (
  <Dialog
    fullScreen={!!fullScreenOn && isDown[fullScreenOn]}
    {...props}
    classes={dialogClasses}
  >
    {title ? (
      <DialogTitle
        className={classes.dialogtitle}
        style={middleAll ? { justifyContent: 'space-around' } : null}>
        <div>
          {returnButton && returnButton.visibility ? (
            <IconButton className={classes.button} size="large">
              <KeyboardBackspace onClick={() => returnButton.func()} />
            </IconButton>
          ) : (
            ''
          )}
          <Typography variant="h6" align="center">
            {title}
          </Typography>
        </div>
        {customHeader ? customHeader : ''}
      </DialogTitle>
    ) : (
      ''
    )}
    {Array.isArray(children) ? (
      children.map((e, i) => (
        <DialogContent
          key={i}
          className={middleAll || middle ? classes.content : null}
          children={e}
          dividers={dividers}
        />
      ))
    ) : (
      <DialogContent
        className={middleAll || middle ? classes.content : null}
        children={children}
        dividers={dividers}
      />
    )}
    <DialogActions children={actions} />
  </Dialog>
);

MinCustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  children: PropTypes.element,
  actions: PropTypes.array.isRequired,
  middle: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  fullScreenOn: PropTypes.string
};

MinCustomDialog.defaultProps = {
  open: false,
  maxWidth: 'sm',
  fullScreenOn: 'md',
  title: '',
  children: <div>Pas de contenu</div>,
  actions: [],
  middle: false,
  fullWidth: true,
  onClose: f => f
};

export default withMediaQuery()(withStyles(MinCustomDialog, styles));
