import React, { memo, useCallback, useMemo, useState } from 'react';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import {
  Box, Grid, IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import AudioPlayer from '../../Calls/AudioPlayer';

/**
 * DISPLAY DEVIS SMART CALLBACK HISTORY
 * @param {{
 * histories: { url: string; toNumbers: string[], createdAt: string }[]
 * }} props
 * @returns {JSX.Element}
 */
function DevisSmartCallbackHistory({ histories }) {
  const [currentHistory, setCurrentHistory] = useState(histories[0]);

  const currentIndexHistory = useMemo(
    () => histories.findIndex(h => h.url === currentHistory.url),
    [currentHistory, histories]
  );

  const isAtTheEndOfList = useMemo(() => {
    if (currentIndexHistory < 0) {
      setCurrentHistory(histories.at(-1));
      return true;
    }

    return currentIndexHistory === histories.length - 1;
  }, [histories, currentIndexHistory]);

  const isAtTheStartOfList = useMemo(() => {
    if (currentIndexHistory < 0) {
      setCurrentHistory(histories[0]);
      return true;
    }

    return currentIndexHistory === 0;
  }, [histories, currentIndexHistory]);

  const nextHistory = useCallback(() => {
    if (isAtTheEndOfList) {
      return;
    }

    setCurrentHistory(histories[currentIndexHistory + 1]);
  }, [isAtTheEndOfList, currentIndexHistory, histories]);

  const prevHistory = useCallback(() => {
    if (isAtTheStartOfList) {
      return;
    }

    setCurrentHistory(histories[currentIndexHistory - 1]);
  }, [isAtTheStartOfList, currentIndexHistory, histories]);

  return (
    <Grid item xs={12} md={4}>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography
          variant="h6"
          style={{
            marginBottom: 4,
            textTransform: 'uppercase',
            fontSize: '1rem',
            fontWeight: 'bold'
          }}
        >
          Historique relance intelligente &nbsp;{
            currentIndexHistory + 1
          } / {histories.length}
        </Typography>
        <Box spacing={2}>
          <Tooltip title='Précédent'>
            <IconButton
              disabled={isAtTheStartOfList}
              onClick={prevHistory}
            >
              <ArrowLeft fontSize='1rem' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Suivant'>
            <IconButton disabled={isAtTheEndOfList} onClick={nextHistory}>
              <ArrowRight fontSize='1rem' />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Paper key={9}>
        <AudioPlayer url={currentHistory.url} />
      </Paper>
    </Grid>
  )
}

export default memo(DevisSmartCallbackHistory);
