import React from 'react';

import Typography from '@mui/material/Typography';
import {
  Done, DoneAll, AccessTime,
  SmsFailed, People
} from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { green, grey } from '@mui/material/colors';

import { moment } from '@lba-dev/package.local-globals/moment';
import {
  data as smsStatus,
  NOT_SEND,
  SEND,
  FAILED,
  PENDING
} from '@lba-dev/package.local-globals/smsStatus';
import {
  WHATSUP,
  WHATSAPPBUSINESS
} from '@lba-dev/package.local-globals/smsMode';
import { List } from 'immutable';

const IconDisplay = ({ message, classes }) => {
  const status = smsStatus.find((e) => e._id === message.get('status'));
  let Icon = {
      [NOT_SEND]: SmsFailed,
      [SEND]: Done,
      [FAILED]: SmsFailed,
      [PENDING]: AccessTime,
    }[message.get('status') || NOT_SEND],
    color = status?.color[700],
    name = status?.name;
  if ([WHATSAPPBUSINESS, WHATSUP].includes(message.get('mode'))) {
    if (message.get('status') === SEND) {
      Icon = DoneAll;
      color = grey[700];
      name = 'Reçu';
    } else if (message.get('status') === PENDING) {
      Icon = Done;
      color = grey[700];
      name = 'Envoyé';
    }
    if (message.get('dateRead')) {
      color = green[700];
      name = 'Vu';
    }
  }
  return Icon ? (
    <Tooltip disableInteractive title={`Status: ${name}`}>
      <Icon fontSize="small" className={classes.sendIcon} style={{ color }} />
    </Tooltip>
  ) : '';
};


const MessageFooter = ({ classes, message }) => {
  const sender = message.get('sender');
  const date = new Date(message.get('date'));
  const dateEnvoi = new Date(message.get('dateEnvoi', date));
  const format = moment(date).isSame(moment(), 'day') ? 'HH:mm' :
    ((moment(date).isSame(moment(), 'year') && 'D MMM HH:mm') || 'LLL');
  const seenBy = message.get('seenBy', new List()).toJS();
  const displayDateEnvoi = moment(dateEnvoi).format(format);
  return <>
    {sender &&
    <div className={classes.done}>
      <Typography
        align="right"
        className={classes.senderName}
        variant="caption"
        noWrap
      >
        {displayDateEnvoi}
      </Typography>
    </div>
    }
    <div className={classes.done}>
      {sender && <IconDisplay message={message} classes={classes} />}
      {!sender &&
      seenBy.length ?
        <Tooltip title={seenBy.map(e => e.login).join(', ')}>
          <People fontSize='small' color='action' />
        </Tooltip> : ''}
    </div>
  </>;
};

export default MessageFooter;
