import {
  COUNTER_FETCH
} from '../constants/ActionTypes';

const initialState = {};
export default (state = initialState, action) => {
  switch (action.type) {
    case COUNTER_FETCH:
      return action.counter;
    default:
      return state;
  }
};
