import { logout } from '../actions/auth';
import store from '../store';
import ws from '../ws';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { setDialog } from '../actions/dialog';

export async function getFingerprint() {
  const result = await FingerprintJS.load().then(fp => fp.get());
  const fingerprint = {
    userAgent: navigator.userAgent,
    vendor: result.components.vendor.value,
    plugins: result.components.plugins.value,
    date: new Date()
  };
  return fingerprint;
}

export const getCookie = (name) => {
  const value = `; ${window.document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
};

export const logoutUserCookie = (cookie, user) => {
  if (cookie === getCookie('sessionID')){
    store.dispatch(logout(ws, user, false));
  }
};

export const popupNewSession = () => {
  store.dispatch(setDialog({
    name: 'ConfirmDialog',
    open: true,
    hideClose: true,
    dialogProps: {
      title: 'Nouvelle Session',
      middleAll: true,
    },
    contentProps: {
      defaultText: {
        subtitle: 'Cher utilisateur, veuillez prendre note que vous' +
        ' venez de vous connecter et que votre session' +
        ' précédente a été déconnectée.'
      }
    },
    actions: [
      {
        children: 'J\'ai Compris',
        color: 'primary',
        onClick: (_, close) => close()
      }
    ],
  }));
};
