import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { List as ListM } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import { Sms } from '@mui/icons-material';
import { withStyles } from 'tss-react/mui';
import { red } from '@mui/material/colors';

import { moment } from '@lba-dev/package.local-globals/moment';

import store from '../../store';
import { fetch as fetchNotifications } from '../../actions/notifications';
import notifSystem from '../../notifSystem';
import api from '../../api';
import ChatContainer from
  '../Conversations/ConversationContainer/ChatContainer';
import WhenElementIsVisible from '../Utils/WhenElementIsVisible';

const types = [
  { name: 'artisan',
    id: 'artisanId',
    filters: (id) => ({ _id: id })
  },
  { name: 'intervention',
    id: 'interId',
    filters: (id) => ({ _id: id }) },
  { name: 'devis',
    id: 'devisId',
    filters: (id) => ({ _id: id }) },
  { name: 'prosp',
    id: 'prospId',
    filters: (id) => ({ _id: id }) },
  { name: 'demande',
    id: 'demandeId',
    filters: (id) => ({ _id: id }) },
];

const styles = {
  dialogContent: {
    maxHeight: 650,
    height: 650,
  },
  fullHeight: {
    height: '100%'
  },
  listM: {
    borderRight: '1px #00000045 solid',
    height: '100%',
    overflow: 'auto',
  },
  chatDiv: {
    height: '100%',
    maxHeight: 'unset',
    overflow: 'hidden',
    '& .suggestionContainer': {
      bottom: 82,
      width: '100%',
      position: 'absolute',
      overflow: 'auto hidden'
    },
    '& .suggestionItem': {
      whiteSpace: 'normal'
    }
  },
};

const getDefaultState = ({ defaultText, notifications }) => {
  const data = types.find((e) => defaultText[e.id]) || {};
  const id = defaultText[data.id];
  return {
    type: data.id || '',
    id,
    name: data.name || '',
    index: 10,
    filters: data.filters(id),
    notifications: notifications
      .filter((n) => n.type === 'sms')
      .sortBy((n) => n.date)
      .reverse()
      .groupBy((x) => x.link)
      .reduce(
        (a, c) =>
          a.concat(
            c
              .sortBy((n) => !n.click)
              .sortBy((n) => n.date)
              .reverse()
              .get(0)
          ),
        []
      ),
  };
};

class SMSDialog extends PureComponent {
  state = getDefaultState(this.props);

  handleChange = (event, type) => {
    const { defaultText } = this.props;
    if (defaultText[type]) {
      this.setState({ type });
    }
  };

  getDate = (n) => {
    const format = moment(n.date).isSame(moment(), 'day')
      ? 'HH:mm'
      : (moment(n.date).isSame(moment(), 'year') && 'D MMM') || 'LLL';
    return moment(n.date).format(format);
  };

  handleClickSms = (notification) => {
    const { updateNotifications } = this.props;
    const { _id, click } = notification;
    const type = types.find((e) => notification[e.name]);
    this.setState({
      id: notification[type.name],
      name: type.name,
      type: type?.id,
      filters: type.filters(notification[type.name])
    });
    if (!click) {
      updateNotifications(_id);
    }
  };

  loadMore = () => new Promise((resolve) => {
    const { index } = this.state;
    this.setState({ index: index + 10 }, () => resolve());
  });

  render() {
    const { open, callback, classes, user } = this.props;
    const { type, id, name, index, notifications, filters } = this.state;
    return (
      <Dialog open={open} maxWidth="lg" fullWidth>
        <DialogTitle>Conversation SMS</DialogTitle>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          centered
          value={type}
          onChange={this.handleChange}
        >
          {types.map((e, i) =>
            <Tab label={e.tabTitle || e.name} key={i} value={e.id} />
          )}
        </Tabs>
        <DialogContent className={classes.dialogContent}>
          <Grid container className={classes.fullHeight}>
            <Grid item xs={4} className={classes.listM}>
              <WhenElementIsVisible
                hasMore={notifications.length > index}
                onLoadMore={this.loadMore}
                classes={classes}
              >
                <ListM>
                  {notifications.slice(0, index).map((n, i) => (
                    <ListItem
                      button
                      key={i}
                      onClick={() => this.handleClickSms(n)}
                      style={
                        id === n[name]
                          ? {
                            background: '#e6e6e6',
                            margin: '5px 0px',
                            borderRadius: '10px',
                          }
                          : {}
                      }
                    >
                      <ListItemAvatar>
                        <Avatar
                          style={
                            n.click
                              ? {}
                              : {
                                background: red[500],
                              }
                          }
                        >
                          <Sms />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={n.text.replace('a envoyé un SMS', '')}
                        secondary={this.getDate(n)}
                      />
                    </ListItem>
                  ))}
                </ListM>
              </WhenElementIsVisible>
            </Grid>
            <Grid item xs={8} className={classes.fullHeight}>
              <ChatContainer
                name={name} id={id}
                user={user}
                classes={{
                  chatDiv: classes.chatDiv
                }}
                filters={filters}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={callback}>Fermer</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ notifications, users, userId }) => ({
  notifications,
  user: users.find(e => e._id === userId)
});

const mapDispatchToProps = (dispatch) => ({
  updateNotifications: (notificationId) => {
    api.notifications
      .patch(notificationId, { click: true })
      .then(() => {
        const notifications = store.getState().notifications.map((n) => ({
          ...n,
          click: notificationId === n._id ? true : n.click,
        }));
        dispatch(fetchNotifications(notifications));
      })
      .catch(() =>
        notifSystem.error(
          'Notification',
          'Les notifications n\'ont pas pu être mises à jour'
        )
      );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(SMSDialog, styles)
);
