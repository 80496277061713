import {
  Autocomplete,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';

const AltAnswers = ({ response, questions, setDataInput }) => {

  const suggestions = questions.reduce((acc, curr) => {
    const responses = curr.responses
      .map(e => ({ ...e, question: curr.question }));
    return acc.concat(responses);
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={2}>
        <Typography
          children={response.response}
          align="center"
          variant="subtitle1"
          sx={{ textDecoration: 'underline' }}
        />
      </Grid>
      <Grid item xs={8}>
        <Autocomplete
          multiple
          id="tags-standard"
          options={suggestions}
          groupBy={(option) => option.question}
          getOptionLabel={(option) => option.response}
          defaultValue={response.altAnswers}
          value={response.altAnswers}
          onChange={(event, newValues, reason, { option }) => {
            if (reason === 'removeOption') {
              const altAnswers = newValues
                .map(e => ({ response: e.response, refTag: e.refTag }));
              const r = { ...response, altAnswers };
              setDataInput(r);
            }
            else if (reason === 'selectOption') {
              const obj = { response: option.response, refTag: option.refTag };
              const altAnswers = (response.altAnswers || [])
                .updateOrInsert(obj, e => e.refTag === option.refTag);
              const r = { ...response, altAnswers };
              setDataInput(r);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Tags"
              placeholder="Rechercher un tag"
            />
          )}
        />
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
};

export default AltAnswers;
