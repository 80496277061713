import {
  NOT_CONFIRMED,
} from '@lba-dev/package.local-globals/permissions';

import componentLoader from '../../componentLoader';

const Conversations = componentLoader(() =>
  import(
    /* webpackChunkName: "ConversationView" */
    '../../views/ConversationView')
);

export default [
  {
    path: '/conversations',
    component: Conversations,
    restrictions: () => NOT_CONFIRMED,
  },
];
