import React from 'react';
import { connect } from 'react-redux';

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import { setDialogContentProp } from '../../actions/dialog';
import { handleCSVFile } from '../../utils/uploadFunctions';
import CustomMaterialTable from '../CustomMaterialTable';
import { AddBox } from '@mui/icons-material';
import { monthsNumber } from '../../utils/date';
import CustomSelect from '../CustomInputs/CustomSelect';
import { moment } from '@lba-dev/package.local-globals/moment';

const styles = (theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
});

const setData = setDialogContentProp('data');
const setMonth = setDialogContentProp('month');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      requiredKeys,
      frenchKeys,
      keys,
      data,
      month,
      displayMonths,
      helperText,
      selectedYear
    }
  }
}) => ({
  requiredKeys,
  frenchKeys,
  keys,
  data,
  month,
  displayMonths,
  helperText,
  selectedYear
});

const mapDispatchToProps = {
  setData,
  setMonth
};

const handleFile = (props) => (f) =>
  handleCSVFile(props, f);

const DialogUploadCSV = ({
  classes,
  data = [],
  month,
  selectedYear = moment().year(),
  setMonth,
  displayMonths,
  helperText,
  ...props
}) => <div>
  <Grid container spacing={2} justifyContent="center">
    {displayMonths ? <CustomSelect
      grid
      xs={6}
      label={'Sélectionner le mois'}
      setData={(p, v) => {
        setMonth(v);
        if (data && data.length) {
          props.setData(data.map(e => ({ ...e, date: v })));
        }
      }}
      value={month ? moment(month).toISOString() : ''}
      children={monthsNumber.map((e) =>
        <MenuItem
          key={e}
          value={moment()
            .year(selectedYear)
            .month(e - 1)
            .startOf('month')
            .hour(12)
            .minute(0)
            .toISOString()}
          children={moment().month(e - 1).format('MMMM')}
        />
      )}
    /> : ''}
    <Grid item xs={12}>
      <Typography variant='caption' align='center' component='div'>
        Les colonnes attendu: {props.requiredKeys.join(', ')}</Typography>
      {helperText ?
        <Typography
          variant='caption'
          align='center'
          component='div'
          children={helperText}
        /> : null}
    </Grid>
    <Grid item xs={12}>
      {!displayMonths || month ? <CustomMaterialTable
        columns={Object.keys(data[0] || {})
          .map((e, i) => ({
            title: e, field: e,
            render: (v) =>
              props.keys[i] && props.keys[i].customRender ?
                props.keys[i].customRender(v[e])
                : v[e]
          }))}
        data={data}
        title="Visualisation des données"
        options={{
          pageSize: 10
        }}
        actions={[
          {
            icon: () => <div>
              <input
                accept=".xlsx, .xls, .csv"
                className={classes.input}
                id="icon-button-file"
                multiple
                type="file"
                onChange={handleFile({ ...props, month })}
              />
              <label htmlFor="icon-button-file">
                <Button variant="outlined" startIcon={<AddBox />}
                  component="span">Importer</Button>
              </label>
            </div>,
            tooltip: 'Import CSV',
            isFreeAction: true
          }
        ]}
      /> : <Typography
        align='center'
        variant='subtitle1'
        children="Veuillez sélectionner un mois"
      />}
    </Grid>
  </Grid>
</div>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(DialogUploadCSV, styles));
