import {
  blue,
  deepPurple,
  green,
  indigo,
  orange,
  red,
  deepOrange,
  yellow,
  teal,
  amber,
  purple,
  lightBlue
} from '@mui/material/colors';

export default ({ statsCalls, openInter, CA }) =>
  statsCalls && statsCalls.missedCalls && statsCalls.calls
    ? [
      {
        title: 'Appels totals',
        color: green[500],
        stats: statsCalls.calls.length,
        openUniques: statsCalls.calls,
      },
      {
        title: 'Appels uniques',
        color: orange[500],
        stats: statsCalls.uniqueCalls,
        openUniques: statsCalls.calls,
      },
      {
        title: 'Appels uniques full',
        color: deepOrange[500],
        stats: statsCalls.missedCallsFull,
        openUniques: [
          ...statsCalls.calls,
          ...statsCalls.missedCalls
        ],
      },
      {
        title: 'Appels nouveaux clients',
        color: teal[500],
        stats: statsCalls.newCustomers.length
      },
      {
        title: 'Appels manqués',
        color: red[700],
        stats: statsCalls.missedCalls.length,
        openUniques: statsCalls.missedCalls,
      },
      {
        title: 'Appels manqués uniques',
        color: statsCalls.missedCalls.some(e => e.calledManually)
          ? lightBlue[500]
          : amber[700],
        stats: [
          ...new Set(statsCalls.missedCalls
            .map(e => e.record.callerIdNumber))
        ].length,
        openUniques: statsCalls.missedCalls,
        isMissedCalls: true
      },
      {
        title: 'Interventions ajoutées',
        color: blue[700],
        stats: statsCalls.interventionsAdded,
        handleOpen: openInter,
      },
      {
        title: 'Appels mis en attente',
        color: purple[700],
        stats: statsCalls.callWaiting.length,
        openUniques: statsCalls.callWaiting
      },
      {
        title: 'Ventes manquées',
        color: purple[700],
        stats: statsCalls.callToBeListen.length,
        openUniques: statsCalls.callToBeListen
      },
      {
        title: 'Interventions en cours',
        color: yellow[700],
        stats: statsCalls.interventionEnCours,
        handleOpen: openInter,
      },
      {
        title: 'Interventions a prog',
        color: blue[500],
        stats: statsCalls.interventionAProg,
        handleOpen: openInter,
      },
      {
        title: 'Interventions démarchées et envoyées',
        color: indigo[700],
        stats: statsCalls.demarchageSended,
        handleOpen: openInter,
      },
      {
        title: 'Panier moyen',
        color: deepPurple[700],
        stats: statsCalls.panierMoyen.toFixed(4),
      },
      {
        title: 'Chiffre d\'affaire',
        stats: CA,
        color: indigo[700],
      },
      {
        title: 'Google local manqués',
        color: purple[800],
        stats: statsCalls.missedGoogleLocal.length,
        openUniques: statsCalls.missedGoogleLocal
      },
    ]
    : [];
