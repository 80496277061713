import { OPEN_CHATPANEL } from '../constants/ActionTypes';

const initialState = {
  open: false,
  id: null,
  chatType: null,
  selectedNumber: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CHATPANEL:
      return {
        ...state,
        open: true,
        id: action.id,
        chatType: action.chatType,
        selectedNumber: action.selectedNumber
      };
    default:
      return state;
  }
};
