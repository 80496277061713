import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { tries } from '@lba-dev/package.local-globals/triesProsp';

const styles = {
  root: {
    flexGrow: 1,
    marginBottom: 5,
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  },
  ListItem: {
    backgroundColor: '#8080805c',
  },
  Collapse: {
    textAlign: 'center'
  }
};

class Tries extends PureComponent {
  state = {
    open: true
  }

  openCollapse = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { classes, data, updateData } = this.props;
    return (
      <List
        component="nav"
        className={classes.root}>
        <ListItem button onClick={this.openCollapse}
          className={classes.ListItem}>
          <ListItemText inset primary="Tries" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {tries.map(e => (
              <ListItem key={e._id} button
                style = {data.get('tries') === e._id ? {
                  background: '#2196f3ad', color: 'white'
                } : { background: 'white', color: 'black' } }
                onClick={() => updateData('tries',
                  data.get('tries', 0) === e._id ? false : e._id)}>
                {e.name}
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
    );
  }
}

Tries.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func
};

export default withStyles(Tries, styles);
