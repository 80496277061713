import React from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../../actions/dialog';
import { questionSteps } from '../../TableConfigs/Questions';
import { QuestionTabs } from '../../TableConfigs/Questions/tabs';
import AltAnswers from './altAnswers';
import SubQuestions from './subQuestions';

const setResponses = setDialogContentProp('responses');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      responses,
      rowData,
      allQuestions,
      setDataInput,
      selectedResponse,
      stepper,
    }
  }
}) => ({
  responses,
  rowData,
  allQuestions,
  setDataInput,
  selectedResponse,
  stepper,
});

const mapDispatchToProps = {
  setResponses
};

const AddNewResponses = ({
  responses,
  rowData,
  allQuestions,
  selectedResponse,
  setDataInput,
  setResponses,
  stepper
}) => {
  const response = responses[selectedResponse];
  return (
    <React.Fragment>
      {stepper === questionSteps.ANSWERS &&
      <QuestionTabs
        dataType="questions"
        question={rowData}
        responses={responses}
        classes={{}}
        setDataInput={(...arg) => {
          const nResponses = setDataInput(arg, rowData);
          setResponses(nResponses);
        }}
      />
      }
      {stepper === questionSteps.ALT_ANSWERS &&
        <AltAnswers
          response={response}
          questions={
            responses.map(e => (e.subQuestions || [])).flat().filter(Boolean)
          }
          setDataInput={(res) => {
            const index = responses
              .findIndex(e => e.refTag === response.refTag);
            const newData = [
              ...responses.slice(0, index),
              res,
              ...responses.slice(index + 1),
            ];
            const nResponses = setDataInput(['responses', newData], rowData);
            setResponses(nResponses);
          }}
        />
      }
      {stepper === questionSteps.SUB_QUESTIONS &&
        <SubQuestions
          response={response}
          questions={[...allQuestions]}
          setDataInput={(res) => {
            const index = responses
              .findIndex(e => e.refTag === response.refTag);
            const newData = [
              ...responses.slice(0, index),
              res,
              ...responses.slice(index + 1),
            ];
            const nResponses = setDataInput(['responses', newData], rowData);
            setResponses(nResponses);
          }}
        />
      }
    </React.Fragment>
  );};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewResponses);
