import { moment } from '@lba-dev/package.local-globals/moment';

import { generateDocSMS } from '@lba-dev/package.local-globals/smsConstants';
import {
  A_ACT,
  A_IAC,
  A_CAN,
  A_POT
} from '@lba-dev/package.local-globals/aStatus';
import company from '@lba-dev/package.local-globals/company';
import { S_ART } from '@lba-dev/package.local-globals/typesConstants';
import ReminderHistoryDialog
  from '../components/Dialogs/Relance/ReminderHistoryDialog';
import IphoneDialog from '../components/Dialogs/IphoneDialog';
import api from '../api';
import notifSystem from '../notifSystem';
import { sendInterestSMSArray, sendSmsBackArtisan } from './inter';
import { genInterestSmsInter } from '../constants/sms/smsInter';
import store from '../../src/store';
import { closeDialog, setDialog } from './dialog';
import { getSimpleSignalements } from './signalements';
import { addSignalement } from '../actions/signalements';
import { P_UPLOAD } from '@lba-dev/package.local-globals/nPages';

export * from './artisanContext';

const format = (reminders, users) =>
  reminders
    .sort((e, v) => new Date(v.date) - new Date(e.date) )
    .map(r => {
      const user = users.find(u => u._id === r.user);
      return {
        description: `${
          moment(r.date).format('L')} - ${
          user ? user.login : ''}${
          r.date ? moment(r.date).format(
            '[ - Prochaine relance: ] L [à] HH:mm'
          ) : ''
        }`,
        comment: r.comment,
      };
    });

const mapper = {
  [A_ACT]: 'Actifs',
  [A_CAN]: 'Candidats',
  [A_POT]: 'Potentiels',
  [A_IAC]: 'Inactifs'
};

export const formatTooltipArtisan = artisans =>
  Object.entries(
    artisans.reduce((a, v) => {
      const name = mapper[v.status];
      if (name) {
        if (!a[name]) {
          a[name] = 0;
        }
        a[name]++;
      }
      return a;
    }, {})
  )
    .reduce((a, [k, v]) => `${a ? `${a}, ` : ''}${k}: ${v}`, '');

export function sendDemarcheZoneAction(
  coordinates, category, address, interId
) {
  if (!coordinates || !category || !address) {
    return notifSystem.error(
      'Erreur',
      `Pas de ${!category ? 'catégorie' : ''}${
        category && coordinates ? ' et ' : ''
      }${!coordinates ? 'coordonnées' : ''} pour la zone de démarchage`
    );
  }
  api.cities
    .custom('demarchage')
    .post({
      address: `${address.get('number', '')} ${address.get(
        'road',
        ''
      )}, ${address.get('zipcode', '')} ${address.get('city', '')}`,
      zipcode: address.get('zipcode', ''),
      coordinates,
      category,
      interId
    })
    .then(() =>
      notifSystem.success(
        'Démarchage',
        'Demande de démarchage sur cette zone effectuée'
      )
    )
    .catch(() =>
      notifSystem.error('Erreur', 'Problème lors de la demande de démarchage')
    );
}

export const createReminder = (
  artisanId, comment = '', date, historyComment = '') =>
  api.artisans.custom(`addReminder/${artisanId}`).post({
    comment: comment.trim(),
    historyComment: historyComment.trim(),
    date,
  });

export const sendReminder = (artisanId, message, comment) =>
  api.artisans.custom(`relance/${artisanId}`).post({ message, comment });

export const getMessageHistorique = (id, regex) =>
  api.sms.getAll({
    query: JSON.stringify(
      { artisan: id, text: { $regex: regex } })
  }).then(e => e.body().map(e => e.data()));

export const callReminderHistoryDialog = ({ setDialog, users }, artisan) =>
  getMessageHistorique(artisan._id, /artisanUpload|\/documents/)
    .then(data => {
      const messages = (artisan.reminders || [])
        .concat(data.map(e =>
          ({
            comment: e.text,
            user: e.senderId,
            date: e.date
          })));
      setDialog(
        ReminderHistoryDialog,
        true,
        {
          reminders: format(messages, users),
          date: artisan.date.reminder,
          name: artisan.companyName
        },
        {
          close: () => setDialog(null, false, null, null),
        }
      );
    }
    );

export const sendSMS = ({ setDialog, selected }) => {
  setDialog(
    IphoneDialog,
    true,
    {
      text: generateDocSMS(selected),
    },
    message => sendSmsBackArtisan(setDialog,
      { artisanId: selected._id,
        routeId: P_UPLOAD,
        message
      })
  );
};

export const getSMSContrat = (user) => {
  const alias = (user && user.alias) || '';
  const num =
    user && user.ligne
      ? user.ligne.replace(/\+33/, '0').match(/.{2}/g).join('.')
      : company.lines.telSupport;
  return (
    'Bonjour,\n' +
    'Voici le lien permettant d\'accéder au contrat de partenariat.\n' +
    'Ce contrat permet de cadrer notre future relation de travail, et ' +
    'ne vous oblige en aucun cas à accepter nos interventions. \n\n' +
    '__LINK__\n\n' +
    'N\'hésitez pas à me contacter si vous avez des questions.\n\n' +
    `${alias} : ${num}\n\n` +
    'Les Bons Artisans'
  );
};

export function sendInterProposition({
  checkArtisan, intervention, cb, user, message
}) {
  try {
    if (intervention) {
      sendInterestSMSArray(checkArtisan, intervention, message, {
        user,
        cb
      });
    }
  } catch (e) {
    notifSystem.error(e.name, e.response ? e.response.data : e.message);
  }
}

export const sendSmsPropositionWithoutDilog = ({
  intervention,
  user,
  checkArtisan,
  cb,
}) => {
  const message = genInterestSmsInter(intervention, user);
  return sendInterProposition({
    checkArtisan,
    intervention,
    cb: () => {
      cb();
      if (intervention._id) {
        api.interventions.patch(intervention._id, {
          block: {
            propositionArtisanControle: true,
          },
        });
      }
    },
    user,
    message,
  });
};

export const sendSmsProposition = (
  {
    inter,
    checkArtisan,
    updateCheckArtisan,
    user,
    setDialog,
    save,
    updateData
  }) => {
  setDialog(
    IphoneDialog,
    true,
    genInterestSmsInter(inter, user),
    (message) => {
      if (message) {
        updateData(['block', 'propositionArtisan'], true, async () => {
          const intervention = await save(false);
          sendInterProposition({ checkArtisan, intervention,
            cb: () => updateCheckArtisan([]),
            user,
            message
          });
          if (!intervention || !Object.keys(intervention || {}).length) {
            updateData(['block', 'propositionArtisan'], false);
          } else {
            api.artisans.custom('sendPropositionMail').post({
              query: {
                checkArtisan,
                inter: intervention,
              },
            });
          }
        });
      }
      setDialog(null, false, '', null);
    }
  );
};

export const sendSmsPropositionToAll = (
  {
    formatedArtisans,
    user,
    updateCheckArtisan,
    setDialog: setDialogFromProps,
    save,
    updateData,
    inter
  }) => {
  store.dispatch(
    setDialog({
      name: 'PropositionArtisans',
      open: true,
      dialogProps: {
        maxWidth: 'md',
      },
      contentProps: {
        formatedArtisans,
        checkedArtisans: [],
      },
      actions: [
        {
          children: 'Envoyer',
          color: 'primary',
          onClick: (data, close) =>
            close() &&
            sendSmsProposition(
              {
                inter,
                save,
                checkArtisan: data.checkedArtisans,
                updateCheckArtisan,
                user,
                setDialog: setDialogFromProps,
                updateData
              }),
        },
      ],
    })
  );
};

export const fetchDropboxFiles = (id, collection) =>
  api[collection].get(id, {
    field: JSON.stringify({ dropbox: 1 })
  }).then(res =>
    [...new Set(((res.body() || {}).data() || {}).dropbox || [])]
  );

export const setAssurance = (id, value = false) => {
  api.artisans.patch(id, {
    noAssurance: value
  }).then(() => notifSystem.success('Opération réussie',
    'Votre demande a bien été prise en compte'))
    .catch(() => notifSystem.error('Opération échoué',
      'Votre demande n\'a bien pas été prise en compte'));
};

export const unselectArtisan = (_id, isSav = false) =>
  () => api
    .one('interventions', _id)
    .custom('artisan')
    .post({
      collection: isSav ? 'savInterventions' : 'interventions',
    });

export const addAuthorisedArtisan = (artisan, id) =>
  api.one('interventions', id)
    .custom('addAuthorisedArtisan')
    .post({ artisan })
    .then((e) => e.body().data());

export const hideFromMissingRib = (artisanId) => {
  try {
    api.artisans
      .patch(
        `${artisanId}`,
        { RIBgerer: true }
      );
    notifSystem.success(
      'L\'artisan a bien ete supprime'
    );
  } catch (e) {
    notifSystem.error(
      'L\'artisan n\'a pas pu etre supprime'
    );
  }
};

export const sendAccessMailPreMarket = (chiefId) => {
  api.artisans.custom('sendAccessMailPreMarket')
    .post({
      chiefId
    });
};

export const marketWarnDialog = (chiefId, cb) => {
  store.dispatch(
    setDialog({
      name: 'PreMarketWarnDialog',
      open: true,
      hideClose: true,
      dialogProps: {
        maxWidth: 'md',
        title: 'Attention!'
      },
      actions: [
        {
          children: 'Annuler',
          color: 'secondary',
          onClick: (data, close) => close()
        },
        {
          children: 'Confirmer',
          color: 'primary',
          hideButton: (data) => !data.accepted,
          onClick: (data, close) => {
            sendAccessMailPreMarket(chiefId);
            cb();
            close();
          }
        },
      ],
    })
  );
};

export const getArtisanSignalement = (artisanId) =>
  getSimpleSignalements({ 'artisan._id': artisanId })
    .then((e) =>
      e.body().map((e) => e.data())
    )
    .catch(() =>
      notifSystem.error('Erreur',
        'Les signalements n\'ont pas pu étre recupéré')
    );

export const openArtisanSignalements = (artisan) =>
  store.dispatch(
    setDialog({
      name: 'SignalSST',
      open: true,
      dialogProps: {
        maxWidth: 'md',
        title: 'Signalements',
      },
      contentProps: {
        defaultText: {
          ...artisan,
          signalType: S_ART,
        },
        callback: (message) => {
          if (message) {
            addSignalement(message.comment, message._id, null, artisan._id);
          }
          store.dispatch(closeDialog());
        }
      },
    })
  );
