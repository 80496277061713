import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import { withDisplaying } from '../../hoc';
import { DisplayText } from './customDefault';
import { InputAdornment } from '@mui/material';
import { Euro } from '@mui/icons-material';

const RELATIVE_NUMBER_REGEX = /^-?(\d+)(\.\d{1,2})?$/;
const POSITIVE_NUMBER_REGEX = /^(\d+)(\.\d{1,2})?$/;
const getDefaultValue = (v) => (v ? (v / 100).toString(10) : '0.00');

class MoneyInput extends PureComponent {
  state = {
    error: false,
    value: getDefaultValue(this.props.defaultValue),
    editable: false,
    autoFocus: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      const update = {
        value: getDefaultValue(this.props.defaultValue),
      };
      if (!this.props.defaultValue) {
        update.editable = true;
      }
      this.setState(update);
    }
  }

  onChange = (e) => {
    const { setData, path, canBeNegative, elemPos } = this.props;
    const { error } = this.state;
    let { value } = e.target;
    const valid = (canBeNegative
      ? RELATIVE_NUMBER_REGEX
      : POSITIVE_NUMBER_REGEX
    ).test(value);

    this.setState(error === valid ? { error: !valid, value } : { value });
    if (setData && valid) {
      return elemPos >= 0
        ? setData(path, Math.round(Number.parseFloat(value * 100)), elemPos)
        : setData(path, Math.round(Number.parseFloat(value * 100)));
    }
  };

  handleEditable = (value) => {
    this.setState({ editable: value, autoFocus: true });
  }

  render() {
    const {
      xs,
      sm,
      md,
      lg,
      xl,
      grid,
      classGrid,
      requiredText,
      displayText,
      ...props
    } = this.props;
    const { value, error, editable, autoFocus } = this.state;
    delete props.defaultValue;
    delete props.setData;
    delete props.path;
    delete props.canBeNegative;
    const input = displayText && !editable ? (
      <DisplayText
        value={value}
        required={requiredText}
        label={props.label}
        margin={props.margin}
        handleEditable={this.handleEditable}
        disabled={props.disabled}
      />
    ) : (
      <TextField
        variant="standard"
        autoFocus={autoFocus}
        {...props}
        value={value}
        error={error || props.error}
        type="number"
        onWheel={(e) => e.target.blur()}
        InputProps={{
          ...props.InputProps,
          ...(props.moneyAdornment
            ? {
              startAdornment:
                <InputAdornment position="start">
                  <Euro fontSize="small" />
                </InputAdornment>
            } : {}),
          inputProps: { step: '0.01', min: '0' },
        }}
        fullWidth
        onChange={this.onChange}
        required={requiredText}
      />
    );
    if (grid) {
      return (
        <Grid
          item
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          xl={xl}
          className={classGrid}
        >
          {input}
        </Grid>
      );
    }
    return input;
  }
}

MoneyInput.propTypes = {
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  setData: PropTypes.func.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.number,
  classGrid: PropTypes.string,
};

MoneyInput.displayName = 'MoneyInput';

export default withDisplaying()(MoneyInput);
