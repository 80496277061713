import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Box } from '@mui/material';

const WhenElementIsVisible = ({
  children,
  onLoadMore,
  hasMore,
  classes,
  threshold = 1,
  onTop = false,
  divProps = {},
  withLoader = true,
}) => {
  const observerRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const style = hasMore ? { height: 100, m: 2 } : {};
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isLoading && hasMore) {
          setIsLoading(true);
          onLoadMore().then(() => setIsLoading(false));
        }
      },
      { threshold }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [onLoadMore, hasMore]);
  return (
    <div className={classes?.overflow} {...divProps}>
      {!onTop && children}
      <div ref={observerRef}>
        <Box sx={{ ...style, display: 'flex', justifyContent: 'center' }}>
          {withLoader && isLoading && <CircularProgress />}
        </Box>
      </div>
      {onTop && children}
    </div>
  );
};

export default WhenElementIsVisible;
