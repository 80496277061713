import api from '../api';
import notifSystem from '../notifSystem';
import Money from '../utils/Money';
import { createXLSX } from './exportFunctions';
import { R_DATAS } from '@lba-dev/package.local-globals/rStatus';
import {
  P_FRCO
} from '@lba-dev/package.local-globals/PaymentCategories';

export const getCount = (filter) =>
  api.paymentsAnnexe.getAll({
    query: JSON.stringify(filter),
    call: 'countDocuments',
  });


export const getPaymentAnnexe = (filterData) => (query) =>
  new Promise((resolve) =>
  {
    if (filterData) {
      const { users, isArchive, ...filter } = filterData;
      if ((query.search || '').length > 3) {
        filter.id = query.search;
      }
      return Promise.all([
        api.paymentsAnnexe.getAll({
          query: JSON.stringify(filter),
          display: query.pageSize,
          page: query.page,
          sort: { '_id': -1 }
        }),
        (!isArchive || query.page === 0) && getCount(filter),
      ])
        .then(([data, count]) => [
          data.body().map((e) => e.data()),
          count && count.body().data(),
        ])
        .then(([result, newTCount]) => {
          resolve({
            data: result.map((p) => ({
              ...p,
              login: {
                ajout: users.find((e) => e._id === p.login.ajout)?.login
              },
            })),
            page: query.page,
            display: query.pageSize,
            totalCount: newTCount || query.totalCount,
          });
        })
        .catch((e) => notifSystem.error('Erreur', e.message));
    }
    return resolve({
      data: [],
      page: 0,
      display: 0,
      totalCount: 0,
      inter: []
    });
  }
  );

export const addPaymentAnnexe = (data) =>
  api.paymentsAnnexe
    .post(data)
    .then((e) => e.body().data())
    .catch(() =>
      notifSystem.error('Erreur', 'Veuillez remplir tous les champs')
    );

export const updatePaymentAnnexe = (newData) => {
  const { _id, ...data } = newData;
  data.login.ajout = undefined;
  return api.paymentsAnnexe
    .patch(_id, data)
    .catch((e) => notifSystem.error('Erreur', e.message));
};

export const deletePaymentAnnexe = (oldData) =>
  api.paymentsAnnexe
    .delete(oldData._id)
    .catch((e) => notifSystem.error('Erreur', e.message));

export const sendMailPaiement = (data) => {
  api.paymentsAnnexe
    .custom('sendMail')
    .post(data)
    .catch((e) => notifSystem.error('Erreur', e.message));};

const resolveKey = (path, obj = {}, separator = '.') => {
  const properties = path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

export const exportPaymentAnnexe = (filter, headers) => {
  headers = [...headers, {
    title: 'Mt des frais',
    field: 'frais'
  }, {
    title: 'Note',
    field: 'recStatus',
    exportValue: ({ recStatus, type }) => {
      const statusElem = R_DATAS
        .find(e => e._id === recStatus);
      return type === P_FRCO && statusElem ?
        `Litige ${statusElem.name.toLowerCase()}` : '';
    },
  }];
  api.paymentsAnnexe.custom('/getCSVData').get({
    query: JSON.stringify(filter)
  })
    .then(e => e.body().map(e => e.data()))
    .then(data => {
      if (data.length) {
        data = data.map(d => headers.filter(e =>
          !e.hideOnExport || !e.hideOnExport(d)).reduce((acc, h) => {
          acc[h.title] = h.exportValue ? h.exportValue(d.elem) :
            resolveKey(h.field, d.elem, '.') || '';
          if (d.elem.type === P_FRCO && h.title === 'Mt des frais') {
            acc[h.title] = Money.toString(d.frais);
          }
          return acc;
        }, {}));
        createXLSX(data, headers.map(e => e.title));
      }
    });
};
