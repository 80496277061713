import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import CustomTextField from '../CustomInputs/CustomTextField';

const paths = {
  email1: ['email1'],
  email2: ['email2']
};

class Email extends PureComponent {
  render() {
    const { setData, email1, email2 } = this.props;
    return (
      <Grid container spacing={3}>
        <CustomTextField grid xs={12} sm={6} capsLocked
          label="Email"
          value={email1}
          path={paths.email1}
          setData={setData}
        />
        <CustomTextField grid xs={12} sm={6} capsLocked
          label="Email n°2"
          value={email2}
          path={paths.email2}
          setData={setData}
        />
      </Grid>
    );
  }
}

Email.propTypes = {
  email1: PropTypes.string.isRequired,
  email2: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired
};

Email.defaultProps = {
  email1: '',
  email2: ''
};

export default Email;
