import React from 'react';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { withStyles } from 'tss-react/mui';
import Autocomplete from '@mui/material/Autocomplete';
import { data as dataServices } from '@lba-dev/package.local-globals/services';
import { setDialogContentProp } from '../../actions/dialog';
const setUserSelected = setDialogContentProp('userSelected');

const styles = () => ({
  textStyle: {
    alignSelf: 'center',
    textAlign: 'center'
  },
  padding: {
    paddingTop: 10
  },
  group: {
    display: 'block',
    textAlign: 'center'
  }
});

const mapStateToProps = ({
  dialog: {
    contentProps: { field, filterUsers },
  },
  users,
}) => ({
  field,
  users: users.filter(e => filterUsers ? filterUsers.includes(e.service) : e ),
});

const mapDispatchToProps = {
  setUserSelected
};

const renderGroup = (params, classes) => [
  <Typography
    className={classes.group}
    key={params.key}
    variant="button"
    children={dataServices.find(e => params.group === e._id).name}
  />,
  params.children,
];

const AssignDialog = ({
  field, classes, users, setUserSelected
}) => (
  <Grid container className={classes.padding}>
    <Grid item xs={2} className={classes.textStyle}>
      <Typography
        variant="subtitle1"
        children={users.find(u => u._id === field)?.login || 'Introuvable'}
      />
    </Grid>
    <Grid item xs={10}>
      <Autocomplete
        options={
          users.filter(e => e.act).toJS().sort((a, b) => a.service - b.service)
        }
        getOptionLabel={(user) => user.login}
        disableListWrap
        onInputChange={
          (p, v) => setUserSelected(users.find(u => u.login === v))
        }
        groupBy={(option) => option.service}
        renderGroup={(params) => renderGroup(params, classes)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={'Rechercher un utilisateur'}
            variant="outlined"
          />)}
      />
    </Grid>
  </Grid>
);

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(AssignDialog, styles)
)
