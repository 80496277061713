import React, { useState } from 'react';

import DOMPurify from 'dompurify';
import { fromJS } from 'immutable';

import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

import AudioPlayer from '../../../../Calls/AudioPlayer';
import { displayDocument } from '../../../../../utils/windowOpenFunction';
import { IconButton, Tooltip } from '@mui/material';
import { FileDownloadOutlined, OpenInNew, PostAdd } from '@mui/icons-material';
import { formatTextToHTML } from '../../../constant';
import {
  addPhotoToInterOrDevis, artisanInterListDialog
} from '../../../../../actions/conversations';
import collections from '../../../../../constants/collections';

const styles = {
  mms: {
    borderRadius: 5,
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer'
  },
  audio: {
    minWidth: 350
  },
};

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

const formatUrlInText = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return formatTextToHTML(text).replace(urlRegex, (url) =>
    `<a target="_blank" href="${url}">${url}</a>`);
};

const getDocument = (message) => {
  if (message.get('urlFile') && message.get('urlFile').size) {
    return message.get('urlFile');
  }
  let link = message.get('document') || message.get('path') || '';
  return link && fromJS([{
    link: link || '',
    type: link.includes('base64') ?
      ((link.split(';')[0] || '').split('/') || [])[1] : '',
    text: message.get('text') || ''
  }]);
};

const DocumentContainer = ({
  artisan, text, link, type, children, user, dataObject, collection
}) => {
  const [fileAdded, setFileAdded] = useState(false);
  let downloadLink = type ? link : '';
  if (link.includes('dropbox')) {
    downloadLink = link.replace('dl=0', '');
    downloadLink = `${downloadLink}&dl=1`;
  }
  return <Box display="flex" alignItems="center">
    <div>{children}</div>
    <Box display='flex' flexDirection='column'>
      {downloadLink ? <IconButton component='a'
        {...(type ? { download:
          text.includes(`.${type}`) ? text : `file.${type}` } : {})}
        target={!downloadLink.includes('dropbox') ? '_blank' : ''}
        href={downloadLink}>
        <FileDownloadOutlined />
      </IconButton> : ''}
      <IconButton onClick={() => displayDocument(link,
        link.includes('base64'),
        link.includes('http'))}>
        <OpenInNew />
      </IconButton>
      {(artisan || dataObject._id) &&
      link.includes('dropbox') ? <Tooltip title={artisan ?
          'Ajouter a un OS' :
          `Ajouter ${collection === 'devis' ? 'au devis' : 'à l\'OS'}`}>
          <IconButton color={artisan ? 'primary' : 'secondary'}
            disabled={fileAdded}
            onClick={() =>
              artisan ?
                artisanInterListDialog({ fileLink: link, artisan, user })
                : addPhotoToInterOrDevis({
                  fileLink: link, user,
                  docId: dataObject._id,
                  collection: collection === 'intervention' ?
                    collections.interventions : collections.devis,
                  dropbox: dataObject.dropbox
                }).then((added) => setFileAdded(!!added))
            }>
            <PostAdd />
          </IconButton>
        </Tooltip> : null}
    </Box>
  </Box>;
};

const displayMessage = ({
  classes, message, user, dataObject, type: collection
}) => {
  const documents = getDocument(message);
  const messageType = [
    {
      messageCondition: !message.get('audio') && !!documents && documents.size,
      component: [
        message.get('text') ?
          <>
            {message
              .get('text')
              .split(/\n/g)
              .map((e, key) => (
                <Typography
                  className={classes.senderName}
                  variant="body1"
                  noWrap
                  key={key}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(formatUrlInText(e))
                  }}
                />
              ))}
          </> : '',
        ...(documents ? documents.map((e, i) =>
          <DocumentContainer key={i}
            artisan={message.get('artisan')}
            text={e.get('text')}
            type={e.get('type')}
            link={e.get('link')}
            collection={collection}
            user={user}
            dataObject={dataObject}>
            <embed
              width="180"
              height="210"
              style={{
                overflow: 'hidden',
                opacity: (e.get('link') || '').includes('.mp4') ? 0.8 : 0.6
              }}
              src={e.get('link')}>
            </embed>
          </DocumentContainer>) : [])]
    },
    {
      messageCondition:
        message.get('text') && (!documents || !documents.size),
      component:
        message.get('text') && message
          .get('text')
          .split(/\n/g)
          .map((e, key) => (
            <Typography
              className={classes.senderName}
              variant="body1"
              noWrap
              key={key}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(formatUrlInText(e || '&nbsp;'))
              }}
            />
          ))
    },
    {
      messageCondition:
        !!message.get('audio'),
      component:
        <div className={classes.audio} key='audio'>
          <AudioPlayer noPadding url={message.get('audio')}/>
        </div>,
    }
  ];
  return messageType.filter((e) => e.messageCondition);
};

const ChatMessage = (props) => {
  const generateMessageContent = displayMessage(props);
  return (
    <Box position="relative">
      {generateMessageContent &&
            generateMessageContent.map(e => e.component)}
    </Box>
  );
};

export default withStyles(ChatMessage, styles);
