import React from 'react';

import Grid from '@mui/material/Grid';
import { purple } from '@mui/material/colors';
import { Typography } from '@mui/material';


const FormatedComments = ({
  comment,
  service,
  title
}) =>
  <Grid>
    <Grid
      container
      justifyContent="space-between"
      alignContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography
          style={{
            color: service ? service.color[500] : purple[500]
          }}
          children={title}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          color="textSecondary"
          children={comment}
        />
      </Grid>
    </Grid>
  </Grid>;

export default FormatedComments;
