import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import api from '../../../api';

const Generate2FA = ({ userId }) => {
  const [qr, setQr] = useState('');

  const generateSecret = async () => {
    const res = await api
      .all(`generateSecret/${userId}`)
      .post();
    const data = res.body().data();
    setQr(data.qrcode);
  };

  useEffect(() => {
    generateSecret();
  }, []);

  return (
    <div>
      {qr ? (
        <Grid container align="center">
          <Grid item xs={12}>
            <img src={qr} alt="qrcode" />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              children={`Veuillez scanner le qr code sur l'application 
              Google Authenticator`}
            />
          </Grid>
        </Grid>
      ) : ''}
    </div>
  );
};

export default Generate2FA;
