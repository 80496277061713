import React from 'react';
import { withStyles } from 'tss-react/mui';
import TabsWithCounter from '../../Utils/TabsWithCounter';
import { permit } from '@lba-dev/package.local-globals/restrictions';
const styles = {
  tabs: {
    marginBottom: 10
  }
};

const filterErrors = (arr, name) => arr.filter(e =>
  e.path.includes(name) ||
  (!['combination', 'domofinance'].some(c => e.path[0].includes(c)))
).length || '';

const DialogTabs = ({ tabValue, handleTabChange, classes, errors, user, id }) =>
  <TabsWithCounter
    value={tabValue}
    centered
    noAppBar
    textColor="primary"
    className={classes.tabs}
    onChange={handleTabChange}
    values={[
      { name: 'Aides', counter: filterErrors(errors) },
      { name: 'Domofinance', counter: filterErrors(errors, 'domofinance') },
      { name: 'Combo' },
      (permit(user, { key: 'caldeoSpecial' }) && id ?
        { name: 'Ma Prime' } : null),
    ].filter(Boolean)}
  />;

export default withStyles(DialogTabs, styles);
