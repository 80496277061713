import React from 'react';
import { connect } from 'react-redux';
import Absences from '../Absences';

const mapStateToProps = ({
  dialog: {
    contentProps: {
      artisan,
      callback
    },
  },
}) => ({
  artisan,
  callback
});

const AbsencesDialog = ({ callback, artisan }) =>
  (
    <Absences
      callback={callback}
      artisanId={artisan._id}
    />
  );

export default connect(mapStateToProps)(AbsencesDialog);
