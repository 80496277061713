import React, { PureComponent } from 'react';
import MailDialog from './MailDialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { withStyles } from 'tss-react/mui';

const styles = () => ({
  paper: {
    width: '100%',
    paddingTop: 20,
    overflow: 'auto',
    textAlign: 'center'
  },
});

class RelanceComptaDialog extends PureComponent {
  state = { checked: [] };

  handleToggle = value => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({
      checked: newChecked,
    });
  };

  render() {
    const {
      defaultText: { interventions },
      classes
    } = this.props;
    const checked = this.state.checked;
    return (
      <MailDialog
        {...{
          ...this.props,
          defaultText: {
            ...this.props.defaultText,
            checked,
          },
        }}
        children={
          <Card className={classes.paper}>
            <Typography
              variant="subtitle1"
              align="center"
              children={'Interventions à envoyer'}
            />
            {interventions.map(e => (
              <FormControlLabel
                key={e._id}
                control={
                  <Checkbox
                    onChange={this.handleToggle(e._id)}
                    checked={checked.indexOf(e._id) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                }
                label={e.id}
              />
            ))}
          </Card>
        }
      />
    );
  }
}
export default withStyles(RelanceComptaDialog, styles);
