import { datadogRum } from '@datadog/browser-rum';

// eslint-disable-next-line no-undef
const version = process.env.PACKAGE_VERSION;
export const start = () =>
  datadogRum.init({
    applicationId: 'e8e9dc14-3e49-4ca0-9c60-ad987210b46b',
    clientToken: 'pub5173e78e7839c800755afd1e0282c741',
    sampleRate: 100,
    trackInteractions: true,
    replaySampleRate: 100,
    env: process.env.NODE_ENV || 'develop',
    defaultPrivacyLevel: 'allow',
    service: 'v3front',
    version: `v${version}` || 'v1.0.0'
  });

export const addUserSession = (user) => {
  datadogRum.setUser({
    id: user._id,
    name: user.login,
    ...(user.fingerprint ?
      { userAgent: user.fingerprint?.userAgent } : {}),
  });
  datadogRum.startSessionReplayRecording();
};

export const addAction = (name, context) => {
  if (name && context) {
    datadogRum.addAction(name, context);
  }
};

export const stopSessionReplayRecording = () =>
  datadogRum.stopSessionReplayRecording();
