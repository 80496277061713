import Immutable from 'immutable';

import {
  AssignmentInd,
  House,
  Fireplace,
  DoneAll,
  Remove,
} from '@mui/icons-material';

import {
  CC_CLOT,
  CM_CLOT,
  dataC,
  dataM,
} from '@lba-dev/package.local-globals/caldeoType';

import {
  C_MPR,
  C_CALD,
  data as caldeoStatus,
} from '@lba-dev/package.local-globals/caldeoStatus';
import { C_CHA } from '@lba-dev/package.local-globals/comboType';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import {
  changeStatusMiddleware,
  priseEnChargeCaldeo,
  changeType,
  dialogDeleteCaldeo,
} from '../../../actions/caldeo';

const defaultCheck = (props) => {
  const user = props.users.find((e) => e._id === props.userId);
  return (
    Immutable.List.isList(props.users) &&
    props.selected &&
    props.selected.isCaldeo &&
    permit(user, {
      key: 'caldeoAction',
    })
  );
};

export default [
  {
    name: 'Prise en charge',
    icon: AssignmentInd,
    customVisible: (elem, props) =>
      defaultCheck(props) &&
      !props.selected.caldeoStatus &&
      !props.selected.login.caldeoPrisEnCharge,
    action: (props) =>
      !props.selected.ceeAmount &&
      props.selected.combination.comboType === C_CHA
        ? priseEnChargeCaldeo(props, C_MPR)
        : changeStatusMiddleware(props, {
          field: 'caldeoStatus',
          buttons: caldeoStatus,
          cb: priseEnChargeCaldeo,
          withComment: false,
        }),
  },
  {
    name: 'Changer de status caldeo',
    icon: Fireplace,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        defaultCheck(props, user) &&
        props.selected.caldeoStatus &&
        (props.selected.ceeAmount || props.selected.caldeoStatus === C_CALD) &&
        (props.selected.mprType !== CM_CLOT ||
          permit(user, { key: 'caldeoStatus' })) &&
        props.selected.login.caldeoPrisEnCharge
      );
    },
    action: (props) =>
      changeStatusMiddleware(props, {
        title: 'Changer de status Caldeo seul',
        field: 'caldeoType',
        buttons: dataC.filter((e) => e._id !== CC_CLOT),
        status: props.selected.caldeoType,
        cb: changeType,
      }),
  },
  {
    name: 'Changer de status MPR',
    icon: House,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        defaultCheck(props) &&
        props.selected.caldeoStatus === C_MPR &&
        (props.selected.mprType !== CM_CLOT ||
          permit(user, { key: 'caldeoStatus' })) &&
        props.selected.login.caldeoPrisEnCharge
      );
    },
    action: (props) =>
      changeStatusMiddleware(props, {
        title: 'Changer le status MPR',
        field: 'mprType',
        buttons: dataM.filter((e) => e._id !== CM_CLOT),
        status: props.selected.mprType,
        cb: changeType,
      }),
  },
  {
    name: 'Cloturé le dossier',
    icon: DoneAll,
    customVisible: (elem, props) =>
      defaultCheck(props) &&
      ((props.selected.caldeoType !== CC_CLOT && props.selected.ceeAmount) ||
        (props.selected.caldeoStatus === C_MPR &&
          props.selected.mprType !== CM_CLOT)) &&
      props.selected.login.caldeoPrisEnCharge,
    action: (props) =>
      changeStatusMiddleware(props, {
        title: 'Raison pour cloturer',
        field: 'caldeoType',
        buttons:
          props.selected.caldeoStatus === C_MPR
            ? [
              (props.selected.ceeAmount ||
                  props.selected.caldeoStatus === C_CALD) && {
                ...dataC.find((e) => e._id === CC_CLOT),
                field: 'caldeoType',
                name: 'Caldeo',
              },
              {
                ...dataM.find((e) => e._id === CM_CLOT),
                field: 'mprType',
                name: 'Ma prime',
              },
            ].filter(Boolean)
            : [],
        justify: 'center',
        multiSelect: true,
        status:
          props.selected.caldeoStatus !== C_MPR
            ? CC_CLOT
            : [
              props.selected.mprType,
              (props.selected.ceeAmount ||
                  props.selected.caldeoStatus === C_CALD) &&
                  props.selected.caldeoType,
            ].filter(Boolean),
        update: {
          'date': {
            resolutionCaldeo: new Date(),
          },
          'login': {
            resolutionCaldeo: props.user._id
          }
        },
        cb: changeType,
      }),
  },
  {
    name: 'Supprimer du caldeo',
    icon: Remove,
    customVisible: (elem, props) =>
      defaultCheck(props) && !props.selected.login.caldeoPrisEnCharge,
    action: dialogDeleteCaldeo,
  },
];
