import React from 'react';
import Typography from '@mui/material/Typography';
import Money from '../../../../utils/Money';

const PaymentContent = ({ finalPrice, tva, advancePaymentMontant = 0 }) => (
  <Typography variant="h6">
    Le client recevra un mail et un sms pour payer le montant de {
      Money.toString(
        Math.round(finalPrice * ((tva / 100) + 1)) - advancePaymentMontant, true
      )}
  </Typography>
);
export default PaymentContent;
