import React from 'react';

import Grid from '@mui/material/Grid';
import { Typography } from '../../helpers';
import ShowMore from '../ShowMore';
import {
  MSQ_DET,
  MSQ_UT,
  MSQ_CL
} from '@lba-dev/package.local-globals/questionTypeMSteps';
import { withStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import {
  AnswerBoolean,
  AnswerDuo,
  FreeTextField,
  GenDropdown,
  GenPopperImage,
  GenQuestion,
  GenSuggest,
  reOrderResp
} from './components';
import { Divider } from '@mui/material';

const styles = {
  gridContainer: {
    margin: 0,
  },
  chip: {
    fontSize: 10,
    width: '100%',
    height: 20,
    padding: 0,
    marginRight: 4,
    borderRadius: 4
  },
  chipDropDown: {
    fontSize: 10,
    height: 20,
    padding: 0,
    marginRight: 4,
    borderRadius: 4
  },
  subQuestions: {
    whiteSpace: 'nowrap',
    fontSize: 15,
    lineHeight: '2',
  },
  acInput: {
    height: 20,
  },
  aCExpanded: {
    minWidth: 200,
  },
  multiACExpanded: {
    minWidth: 240,
  },
};

const tempHide = true;

const ClientHelps = ({
  element: { attachements, audio, ...element },
  answers,
  infoComps,
  setAnswers,
  restart,
  classes,
  ...rest
}) => {
  const user = useSelector(({ users, userId }) =>
    users.find(e => e._id === userId)
  );
  const newAnswers = answers;

  const countImportant = (element.questions || []).reduce((acc, curr) => {
    acc +=
      [MSQ_DET, MSQ_UT].includes(curr.questionType) &&
      (curr.responses || []).length &&
      curr.responses.some((s) => s.important) ? 1 : 0;
    return acc;
  }, 0);

  return (
    <Grid container spacing={2}>
      {!tempHide && [
        <Grid key={0} item xs={8}>
          <Typography variant="h6" component="h5" children={'Bon à savoir'} />
        </Grid>,
        <Grid key={1} item xs={4}>
          <ShowMore
            element={{ attachements }}
            text={element.help}
            title="Bon à savoir"
          />
        </Grid>
      ]}
      <Grid item xs={12}>
        <Typography
          variant="h6"
          component="h5"
          children="Questions pour le client"
        />
        {(element.questions || []).map((e, i) => {
          const responses = e.responses.filter(e => e.response);
          const subQuestions = responses
            ?.find(r => r.response === newAnswers.get(e.question))
            ?.subQuestions;
          return <>
            <Grid
              key={i}
              className={classes.gridContainer}
              container
              spacing={1}
              alignItems="center"
            >
              <GenQuestion
                index={i}
                responses={responses}
                question={e}
                {...rest}
              />
              {
                [MSQ_DET, MSQ_UT].includes(e.questionType) &&
              (responses || []).length &&
              responses.some((s) => s.important) &&
              <Typography children="*" color="red"/>
              }
              <AnswerBoolean
                question={e}
                newAnswers={newAnswers}
                classes={classes}
                restart={restart}
                setAnswers={setAnswers}
              />
              {[MSQ_DET, MSQ_UT].includes(e.questionType) &&
              (responses || []).length
                ? [
                  ...(responses.length <= 2
                    ? responses.map((r, i) =>
                      <AnswerDuo
                        key={i}
                        question={e}
                        newAnswers={newAnswers}
                        classes={classes}
                        restart={restart}
                        setAnswers={setAnswers}
                        countImportant={countImportant}
                        allResponses={responses}
                        response={r}
                      />
                    ) : [
                      <Grid item key={0}>
                        <GenDropdown
                          responses={reOrderResp(responses, e)}
                          newAnswers={newAnswers}
                          elem={e}
                          classes={classes}
                          setAnswers={setAnswers}
                          metierElement={element}
                          user={user}
                          restart={restart}
                          countImportant={countImportant}
                          question={e}
                        />
                      </Grid>
                    ]),
                  <GenPopperImage
                    key={1}
                    responses={responses}
                    newAnswers={newAnswers}
                    question={e}
                  />,
                  <GenSuggest
                    key={2}
                    responses={responses}
                    setAnswers={setAnswers}
                    infoComps={infoComps}
                    newAnswers={newAnswers}
                    restart={restart}
                    countImportant={countImportant}
                    question={e}
                    metierElement={element}
                    user={user}
                    text="informations complémentaires"
                    {...rest}
                  />
                ] : ''}
              {subQuestions?.length
                ? subQuestions.map((s, i) =>
                  <Grid key={i} container alignItems="center"
                    columnSpacing={1}>
                    <Grid item xs={1} />
                    <Grid item>
                      <Typography
                        className={classes.subQuestions}
                        children={`${i + 1}. ${s.question}`}
                      />
                    </Grid>

                    <Grid item>
                      <GenDropdown
                        responses={reOrderResp(s.responses, s)}
                        newAnswers={newAnswers}
                        elem={s}
                        classes={classes}
                        setAnswers={setAnswers}
                        metierElement={element}
                        user={user}
                        restart={restart}
                        countImportant={countImportant}
                        subQuestions={subQuestions}
                        question={e}
                        isSub
                      />
                    </Grid>
                    <GenSuggest
                      responses={s.responses}
                      setAnswers={setAnswers}
                      infoComps={infoComps}
                      newAnswers={newAnswers}
                      restart={restart}
                      countImportant={countImportant}
                      question={s}
                      metierElement={element}
                      text="informations complémentaires"
                      user={user}
                      {...rest}
                    />
                  </Grid>
                ) : ''}
              {e.questionType === MSQ_CL &&
              <FreeTextField
                question={e}
                setAnswers={setAnswers}
                restart={restart}
                answers={newAnswers}
              />}
            </Grid>
            <Divider />
          </>;
        })}
      </Grid>
      <Grid item xs={8}>
        <Typography variant="h6" component="h5" children="Discours commercial"/>
      </Grid>
      {!tempHide && <Grid item xs={4}>
        <ShowMore
          element={{ audio }}
          text={element.mailText}
          title="Discours commercial"
        />
      </Grid>}
      <Grid item xs={10}>
        <Typography children={element.mailText} />
      </Grid>
    </Grid>
  );
};

export default withStyles(ClientHelps, styles);
