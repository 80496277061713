import React from 'react';
import Button from '@mui/material/Button';
import MinCustomDialog from '../../Dialogs/MinCustomDialog';
import { data as categories } from '@lba-dev/package.local-globals/categories';
import { withStyles } from 'tss-react/mui';

const DialogAdd = ({ openAdd, updateState, addCat, classes }) => (
  <MinCustomDialog
    open={openAdd}
    maxWidth={'xs'}
    title="Ajouter une categorie"
    onClose={() => updateState('openAdd', false)}
    middle
  >
    <div className={classes.container}>
      {categories.map((e, i) => (
        <Button key={i} variant="contained"
          style={{ backgroundColor: e.color[500] }}
          onClick = { () => addCat(e.name) }
          className={classes.button}
        >{e.name}
        </Button>
      ))}
    </div>
  </MinCustomDialog>
);

const styles = {
  button: {
    marginRight: 3,
    marginBottom: 3,
    color: 'white',
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  }
};

export default withStyles(DialogAdd, styles);
