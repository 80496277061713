import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import { indigo } from '@mui/material/colors';
import { withStyles } from 'tss-react/mui';

import CardItems from './CancelDialog/CardItems';
import McDoStepper from './McDoStepper';
import { connect } from 'react-redux';

export const STATUS = 1;
export const S_STATUS = 2;

const styles = {
  input: {
    padding: '16px',
    paddingBottom: 0
  }
};

const mapStateToProps = ({
  confProsp,
  dialog: {
    contentProps: { defaultText, callback, filters, step },
  },
}) => ({
  confProsp,
  defaultText,
  callback,
  step,
  filters,
});

const loadconfProsp = (filter, confProsp) =>
  confProsp
    .filter(data => Object.keys(filter).every(key => filter[key] === data[key]))
    .map(data => ({
      name: data.name,
      color: indigo,
      ref: data.ref,
      data,
    }));

class Prosp extends Component {
  state = {
    value: '',
    data: null,
    conf: null,
  };

  componentDidMount() {
    const {
      confProsp,
      filters = { ref: null },
      step = STATUS
    } = this.props;
    let newconfProsp = confProsp ? confProsp.toJS() : [];
    this.setState({
      data: loadconfProsp(filters, newconfProsp),
      step,
    });
  }

  send = () => {
    const { conf } = this.state;

    this.props.callback({
      _id: conf._id,
      status: conf.status
    });
  };

  close = () => this.props.callback();

  generateDialogProps = step => {
    switch (step) {
      case STATUS:
        return {
          title: '',
        };
      case S_STATUS:
        return {
          title: '',
        };
    }
  };

  setStep = (conf, setData) => {
    let {
      confProsp,
    } = this.props;

    confProsp = confProsp ? confProsp.toJS() : [];
    this.setState({ conf }, () => {
      const data = loadconfProsp(
        { ref: conf.id },
        confProsp
      );
      if (!data.length) {
        this.send();
      } else {
        setData(data, S_STATUS);
      }
    });
  };

  generateContent = (currentData, currentStep, setData) => {
    const { data } = this.state;
    switch (currentStep) {
      case STATUS:
        return (
          <CardItems
            name="data"
            display="name"
            setData={(d, conf) => this.setStep(conf, setData)}
            array={currentData}
          />
        );
      case S_STATUS:
        return (
          <CardItems
            name="data"
            display="name"
            setData={(d, conf) => this.setStep(conf, setData)}
            array={currentData}
          />
        );
      default:
        if (data) {
          setData(data, STATUS);
        }
        return null;
    }
  };

  render() {
    return (
      <McDoStepper
        onClose={this.close}
        generateContent={(...args) => (
          <Grid container spacing={2} justifyContent="center">
            {this.generateContent(...args)}
          </Grid>
        )}
        generateDialogProps={this.generateDialogProps}
      />
    );
  }
}

export default connect(mapStateToProps)(withStyles(Prosp, styles));
