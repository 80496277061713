import React from 'react';
import CustomSelect from '../../CustomInputs/CustomSelect';
import CustomTextField from '../../CustomInputs/CustomTextField';
import {
  data as questType
} from '@lba-dev/package.local-globals/questionTypeMSteps';
import { MenuItem, Typography } from '@mui/material';

const formatStr = (str = '') => str
  .toLowerCase()
  .deaccentize()
  .replaceAll('-', ' ')
  .trim();

export const columns = [
  {
    title: 'Catégorie',
    field: 'cat',
    render: (row) => <Typography children={row.cat} />,
    editComponent: (props) => (
      <CustomTextField
        path={['cat']}
        texttransform='none'
        value={props.rowData.cat || ''}
        setData={(p, v) => props.onChange(v)}
        fullWidth
        type="string"
      />
    ),
  },
  {
    title: 'Questions pour le client',
    field: 'question',
    width: '30%',
    render: (row) => <Typography children={row.question} />,
    customFilterAndSearch: (value, row) => [
      formatStr(row.cat),
      formatStr(row.question)
    ].some(e => e.includes(formatStr(value))),
    editComponent: (props) => (
      <CustomTextField
        path={['question']}
        texttransform='none'
        value={props.rowData.question || ''}
        setData={(p, v) => props.onChange(v)}
        fullWidth
        type="string"
      />
    ),
  },
  {
    field: 'questionType',
    title: 'Type',
    render: (row) => <Typography children={
      row ? questType.find((e) => e._id === row.questionType).name : ''}
    />,
    customFilterAndSearch: (value, row) =>
      row.responses.some(e =>
        formatStr(`${e.response} ${e.refTag}`).includes(formatStr(value))
      ),
    editComponent: (props) => (
      <CustomSelect
        path={['']}
        value={props.rowData.questionType}
        setData={(p, v) => props.onChange(v)}
        fullWidth
      >
        {questType.map((e) =>
          <MenuItem key={e} value={e._id} children={e.name} />
        )}
      </CustomSelect>
    ),
  },
  {
    title: 'Ref',
    field: 'ref',
    render: (row) => <Typography children={row.ref} />,
    editComponent: (props) => (
      <CustomTextField
        path={['ref']}
        value={props.rowData.ref || ''}
        setData={(p, v) => props.onChange(v)}
        fullWidth
        type="string"
      />
    ),
  },
  {
    title: 'Choix multiple',
    field: 'multipleChoice',
    render: (row) => <Typography children={
      row.multipleChoice ? 'Multiple' : 'Unique'
    } />,
    width: '10%',
    editComponent: (props) => (
      <CustomSelect
        path={['multipleChoice']}
        value={props.rowData.multipleChoice}
        setData={(p, v) => props.onChange(v)}
        fullWidth
      >
        {[
          { name: 'Choix Unique', value: false },
          { name: 'Choix multiple', value: true }
        ].map((e) =>
          <MenuItem key={e} value={e.value} children={e.name} />
        )}
      </CustomSelect>
    ),
  },
];
