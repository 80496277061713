import getDataById from '@lba-dev/package.local-globals/getDataById';
import {
  I_AVR, I_ANN, I_VRF
} from '@lba-dev/package.local-globals/iStatus';
import { C_VOL } from '@lba-dev/package.local-globals/typesCancels';

import { amber, deepPurple, grey } from '@mui/material/colors';
import collections from '../../constants/collections';

export const changeStatusColor = (data, type) =>
  ({
    [collections.interventions]: {
      ...getDataById.intervention,
      [I_AVR]: {
        ...getDataById.intervention[I_AVR],
        color:
          (data.noPaymentReceived && amber) ||
          getDataById.intervention[I_AVR].color,
      },
      [I_VRF]: {
        ...getDataById.intervention[I_VRF],
        color:
          (data.isProforma && grey) || getDataById.intervention[I_VRF].color,
        name:
          (data.isProforma && 'A Vérifier') ||
          getDataById.intervention[I_VRF].name,
      },
      [I_ANN]: {
        ...getDataById.intervention[I_ANN],
        color:
          data.typeCancel === C_VOL
            ? deepPurple
            : getDataById.intervention[I_ANN].color,
      },
    },
    [collections.recouvrements]: {
      ...getDataById.intervention,
      [I_AVR]: {
        ...getDataById.intervention[I_AVR],
        color:
          (data.noPaymentReceived && amber) ||
          getDataById.intervention[I_AVR].color,
      },
      [I_VRF]: {
        ...getDataById.intervention[I_VRF],
        color:
          (data.isProforma && grey) || getDataById.intervention[I_VRF].color,
        name:
          (data.isProforma && 'A Vérifier') ||
          getDataById.intervention[I_VRF].name,
      },
      [I_ANN]: {
        ...getDataById.intervention[I_ANN],
        color:
          data.typeCancel === C_VOL
            ? deepPurple
            : getDataById.intervention[I_ANN].color,
      },
    },
  }[type] || getDataById[type]);
