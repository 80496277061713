import React from 'react';
import Typography from '@mui/material/Typography';
import { RecordVoiceOver } from '@mui/icons-material';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
const styles = {
  conversation: {
    padding: 16,
    overflow: 'auto',
    maxHeight: '300px'
  },
  speakerOne: {
    color: 'rgb(0, 31, 100)'
  },
  speakerTwo: {
    color: 'rgb(110, 9, 20)'
  },
  flip: {
    transform: 'scaleX(-1)'
  },
};

const AudioDiscussion = ({ classes, transcription, blurry }) => (
  <Grid container item className={classes.conversation}>
    {transcription.split('\n').map((t, i) => i % 2 === 0 ? [
      <Grid key={0} item xs={1}>
        <RecordVoiceOver color="primary"/>
      </Grid>,
      <Grid key={1} item xs={9}>
        <Typography
          children={t} variant="caption"
          className={`${classes.speakerOne} ${blurry}`}
        />
      </Grid>,
      <Grid key={2} item xs={2}></Grid>,
    ] : [
      <Grid key={0} item xs={2}></Grid>,
      <Grid key={1} item xs={9}>
        <Typography
          children={t} variant="caption"
          className={`${classes.speakerTwo} ${blurry}`}
        />
      </Grid>,
      <Grid key={2} item xs={1}>
        <RecordVoiceOver
          className={classes.flip}
          color="secondary"
        />
      </Grid>
    ])}
  </Grid>
);

export default withStyles(AudioDiscussion, styles);
