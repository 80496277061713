import React, { PureComponent } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Map, fromJS } from 'immutable';
import Name from './Name';
import Tels from './Tels';
import Email from './Email';
import Address from './Address';
import StatusClient from './StatusClient';
import AddressAutoComplete from './AddressAutoComplete';
import AddressComplements from './AddressComplements';
import CompanyName from './CompanyName';
import { moment } from '@lba-dev/package.local-globals/moment';
import {
  SOCIETE,
  AGENCE_IMMO,
  CURATELLE,
  GRAND_COMPTE
} from '@lba-dev/package.local-globals/statusClient';

moment.locale('fr');

export class BillingInformation extends PureComponent {
  state = { address: '' };

  setAddress = (address) => this.setState({ address })

  updateAddress = (place, cb) => {
    if (!place.geometry) {
      return false;
    }
    const { housenumber, street, postcode, city } = place.properties;
    this.props.setData(['address'], fromJS({
      number: housenumber ? housenumber.toUpperCase() : '',
      road: street ? street.toUpperCase() : '',
      zipcode: postcode ? postcode.toUpperCase() : '',
      city: city ? city.toUpperCase() : '',
      location: place.geometry
    }), cb);
  }

  render() {
    const {
      disableElevation = false,
      data,
      dates,
      setData,
      parentSetData,
      dropboxFacture,
      remindFacture,
      client,
      copyInfoClient = true
    } = this.props;
    const { address } = this.state;
    return (
      <Card
        elevation={disableElevation ? 0 : 1}
        className="cards"
        style={{ position: 'relative' }}
      >
        <CardContent>
          <Typography variant="h5">
          Coordonnées pour la facturation
          </Typography>

          {dates && !dates.get('envoiFacture') &&
            <Typography variant="body1">
              Facture à envoyer
            </Typography>}
          {dates && dates.get('envoiFacture') &&
            <Typography>
              Envoyée le {moment(dates.get('envoiFacture'))
                .format('Do MMMM YYYY [à] h:mm:ss')}
            </Typography>}
        </CardContent>
        <CardContent>
          <AddressAutoComplete
            updateAddress={this.updateAddress}
            setAddress={this.setAddress}
            address={address}
          />
          <StatusClient
            client={client}
            clientStatus={data.get('clientStatus', 0)}
            grandCompte={data.get('grandCompte', 0)}
            civility={data.get('civility', '')}
            setData={setData}
            dropboxFacture={dropboxFacture}
            remindFacture={remindFacture}
            parentSetData={parentSetData}
            copyInfoClient={copyInfoClient}
          />
          {
            [SOCIETE, AGENCE_IMMO, CURATELLE, GRAND_COMPTE].includes(
              data.get('clientStatus', 0)
            ) &&
            <CompanyName
              companyName={data.get('companyName', '')}
              setData={setData}
            />
          }
          <Name
            firstname={data.get('firstname')}
            name={data.get('name')}
            setData={setData}
          />
          <Tels
            tel1={data.get('tel1')}
            tel2={data.get('tel2')}
            tel3={data.get('tel3')}
            setData={setData}
          />
          <Email
            email1={data.get('email1')}
            email2={data.get('email2')}
            setData={setData}
          />
          <Address
            number={data.getIn(['address', 'number'])}
            road={data.getIn(['address', 'road'])}
            zipcode={data.getIn(['address', 'zipcode'])}
            city={data.getIn(['address', 'city'])}
            setData={setData}
          />
          <AddressComplements
            floor={data.get('floor', '')}
            code={data.get('code', '')}
            building={data.get('building', '')}
            compl={data.get('compl', '')}
            setData={setData}
          />
        </CardContent>
      </Card>
    );
  }
}
BillingInformation.defaultProps = {
  status: true,
  data: new Map()
};

BillingInformation.propTypes = {
  status: PropTypes.bool,
  data: PropTypes.instanceOf(Map),
  setData: PropTypes.func
};

export default BillingInformation;
