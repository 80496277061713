import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material';

const styles = (theme) => ({
  list: {
    display: 'flex',
    padding: 9,
    width: '100%',
    justifyContent: 'space-around',
  },
  listName: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    cursor: 'pointer',
    paddingTop: '6px',
    paddingBottom: '6px',
    backgroundColor: '#F5F5F5',
    '&:hover': {
      backgroundColor: '#DCDCDC',
    },
  },
  buttonMessage: {
    cursor: 'pointer',
    height: '100%',
    fontSize: 10,
    whiteSpace: 'break-spaces'
  },
  arrowRight: {
    right: 0,
    top: 2,
  },
  arrowLeft: {
    left: 0,
    top: 2,
  },
  textButton: {
    fontSize: '12px',
  },
  gridText: {
    textAlign: 'center',
  },
  suggestionsMessages: {
    position: 'absolute',
    bottom: '100%',
    background: alpha(theme.palette.grey[100], 0.8),
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5
  }
});

class SuggestionsBar extends Component {
  state = {
    showButton: false,
    suggestionsSMS: [],
    indexStats: 0
  };

  handleBefore = () =>
    this.setState(prev => ({ indexStats: prev.indexStats - 1 }));

  handleNext = () =>
    this.setState(prev => ({ indexStats: prev.indexStats + 1 }));

  handleMessages = (message, index) => {
    const { handleOnClick, classes } = this.props;
    if (message) {
      return (
        <Grid key={index} item xs={4} className={classes.gridText}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleOnClick(message.text)}
            className={classes.buttonMessage}
          >
            {message.text}
          </Button>
        </Grid>
      );
    }
  }

  render() {
    const {
      classes,
      suggestionsSms
    } = this.props;
    const { indexStats } = this.state;
    return (
      <div className={classes.suggestionsMessages}>
        <div className={classes.listName}>
          {indexStats !== 0 &&
            <NavigateBefore
              className={classNames(classes.button, classes.arrowLeft)}
              onClick={() => indexStats >= 1 && this.handleBefore()}
            />
          }
          <div className={classes.list}>
            <Grid
              container
              justifyContent="center"
              alignContent='center'
              alignItems='center'
              spacing={2}
            >
              {new Array(3)
                .fill(0)
                .map((e, i) =>
                  this.handleMessages(
                    suggestionsSms[indexStats + i],
                    i
                  )
                )}
            </Grid>
          </div>
          {indexStats !== (suggestionsSms.length % 6) &&
          <NavigateNext
            className={classNames(classes.button, classes.arrowRight)}
            onClick={() => suggestionsSms.length >
                indexStats && this.handleNext()}
          />
          }
        </div>
      </div>
    );
  }
}

export default withStyles(SuggestionsBar, styles);
