import React, { Component } from 'react';

import { withStyles } from 'tss-react/mui';

import { List as UIList } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import api from '../../api';
import { withDisplaying } from '../../hoc';
import LineFormat, { LineTypes } from '../ListInfo/LineFormat';
import { getSimpleSignalements } from '../../actions/signalements';

const styles = {
  padding: {
    padding: 30,
  },
  root: {
    padding: 8,
    margin: 8,
  },
  progress: {
    width: 500,
    height: 500,
    margin: 'auto',
  },
  emptyText: {
    fontSize: 17,
    textAlign: 'center',
  },
  buttonAdd: {
    textAlign: 'center',
  },
  cardSize: {
    width: '100%',
    maxHeight: 565,
    overflow: 'auto',
    overflowX: 'hidden',
  },
};

const sortSingalement = (e, v) => {
  if (e.date.modification) {
    return new Date(e.date.modification) - new Date(v.date.modification);
  }
  return new Date(e.date.creation) - new Date(v.date.creation);
};
class ReportList extends Component {
  constructor() {
    super();
    this.state = {
      activity: null,
      index: 10,
    };
  }

  filteredByCreateDate(reportList) {
    reportList.forEach(e => {
      e.date = !e.date ? e.createdAt : e.date;
    });
    return reportList.sort((a, c) => new Date(c.date) - new Date(a.date));
  }

  loadResources = (searchObjectId, search, filter, callback) => {
    if (searchObjectId) {
      if (search === 'artisanId') {
        this.setState({ loading: true });
        return api.history
          .get('getActivities', {
            artisanId: searchObjectId,
            ...(filter ? { filter } : {}),
          })
          .then(history => {
            const res = history.body().data();
            let activity = [];
            const { categories, historyType } = this.props;
            const transformer = {
              signalements: LineTypes.Signalement,
              absence: LineTypes.Absence,
              history: LineTypes.Modification,
            };
            const dataSelector = {
              [LineTypes.Signalement]: elem => new Date(elem.date.creation),
              [LineTypes.Absence]: elem => new Date(elem.date),
              [LineTypes.Modification]: elem => new Date(elem.date),
            };

            Object.keys(res).forEach(key => {
              res[key].forEach(e =>
                activity.push({ ...e, type: transformer[key] })
              );
            });
            if (categories) {
              activity = activity.filter(elem =>
                categories.includes(elem.categorie)
              );
            }

            if (historyType && historyType.length) {
              activity = activity.filter(elem =>
                historyType.includes(elem.type)
              );
            }

            this.setState({
              activity: activity.sort(
                (a, b) => dataSelector[b.type](b) - dataSelector[a.type](a)
              ),
              loading: false,
            });
          });
      }
      getSimpleSignalements({
        [search]: searchObjectId,
      }).then(res => {
        let reports = [];
        if (res.body().length > 0) {
          reports = res
            .body()
            .map(e => e.data())
            .sort(sortSingalement);
        }
        this.setState({
          loading: false,
          activity: reports.map(e => ({
            ...e,
            type: LineTypes.Signalement,
          })),
        });
        if (callback && typeof callback === 'function') {
          callback(this.state.activity);
        }
      });
    }
  };

  componentDidMount() {
    const {
      search,
      reportListener,
      didAdd,
      searchObjectId,
      filter = null,
      callback
    } = this.props;
    this.loadResources(searchObjectId, search, filter, callback);
    if (didAdd === true) {
      reportListener(false);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { callback } = this.props;
    if (this.props.searchObjectId !== nextProps.searchObjectId) {
      this.loadResources(
        nextProps.searchObjectId,
        nextProps.search,
        callback);
      if (nextProps.didAdd === true) {
        this.props.reportListener(false);
      }
    } else if (this.props.filter !== nextProps.filter) {
      this.loadResources(
        nextProps.searchObjectId,
        nextProps.search,
        nextProps.filter,
        callback
      );
    } else if (this.props.didAdd !== nextProps.didAdd) {
      this.loadResources(
        nextProps.searchObjectId,
        nextProps.search,
        null,
        callback);
    }
  }

  setIndex = () => this.setState({ index: this.state.index + 20 });

  render() {
    let { activity, loading = false, index } = this.state;
    const { classes, elevation, callback } = this.props;
    if (!this.props.searchObjectId) {
      return null;
    }
    activity = this.filteredByCreateDate(activity || []);
    return (
      <Card elevation={elevation} className={`cards ${classes.cardSize}`}>
        <Grid
          container
          item={true}
          className={classes.padding}
          justifyContent="center"
        >
          {(loading &&
            <div>
              <CircularProgress className={classes.progress} size={60} />
            </div>
          ) || (
            activity && activity.length ? (
              <Grid key="listSignalements" item xs={11} sm={11}>
                <UIList>
                  {activity.slice(0, index).map((e, i) => (
                    <Paper
                      key={i}
                      className={classes.root}
                      elevation={elevation}
                    >
                      <LineFormat
                        type={1}
                        launchRequest={() =>
                          this.loadResources(
                            this.props.searchObjectId,
                            this.props.search,
                            this.props.filter,
                            callback
                          )
                        }
                        id={i}
                        info={e}
                      />
                    </Paper>
                  ))}
                  {activity.length >= index && (
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={this.setIndex}
                      children="Voir plus"
                    />
                  )}
                </UIList>
              </Grid>
            ) : (
              <Typography
                variant="caption"
                className={classes.emptyText}
                key="raisonCancel"
              >
                  Pas d'historique
              </Typography>
            )
          )}
        </Grid>
      </Card>
    );
  }
}

ReportList.displayName = 'ReportList';

export default withDisplaying()(withStyles(ReportList, styles));
