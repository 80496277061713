import React from 'react';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
const genChip = (elem, short, len, emptyCancel) => elem && elem.color &&
  <Chip
    key={elem.name}
    label={(short && elem.shortName) || elem.name}
    style={{
      backgroundColor: elem.color['500'],
      width: (short && 'unset') || (len ? '100%' : '100px'),
      minWidth: (short && '60px') || len ? '25%' : '',
      maxWidth: len ? `${100 / len}%` : '',
      color:
        elem.color.contrastDefaultColor === 'light' || emptyCancel
          ? 'white'
          : 'black',
      display: 'inline-flex',
    }}
  />;

function genButton(elem, short) {
  return (
    elem && (
      <Button
        key={elem.name}
        style={{
          backgroundColor: elem.color[elem.name === 'neutre' ? '600' : '500'],
          maxWidth: short ? '30%' : '',
          color: 'white',
          fontSize: '11px',
          margin: '0.6%',
          width: short ? '' : '100%',
          minWidth: 40,
        }}
      >
        {short && elem.shortName ? elem.shortName : elem.name}
      </Button>
    )
  );
}

export {
  genChip,
  genButton
};
