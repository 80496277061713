import React, { Component } from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { withStyles } from 'tss-react/mui';
import { Star } from '@mui/icons-material';
import { Badge, Chip, FormControl, MenuItem, Select } from '@mui/material';
import Input from '@mui/material/Input';
import { showFilter } from '../../../utils/function';
import styles from './styles';
import { withMediaQuery } from '../../../hoc';

class TableHeader extends Component {

  shouldComponentUpdate(nextProps) {
    return (JSON.stringify(nextProps.keys) !==
      JSON.stringify(this.props.keys) ||
      JSON.stringify(nextProps.table) !== JSON.stringify(this.props.table) ||
      nextProps.isDown.md !== this.props.isDown.md);
  }

  /**
   * @param {{
   * starColor: string,
   * color: string,
   * [textColor]: string,
   * label: string
   * }} param
   */
  genSelectWithBadge = (param) => {
    const styles = {
      backgroundColor: param.color,
      color: param.textColor ?? 'white'
    }
    return param.starColor ? <Badge badgeContent={
      <Star fontSize='7px' style={{ color: param.starColor }} />
    }>
      <Chip
        sx={styles}
        label={param.label}
      />
    </Badge> : <Chip
      sx={styles}
      label={param.label}
    />
  }

  genFilterField = (elem, index) => {
    elem = typeof elem === 'object' ? JSON.stringify(elem) : elem;
    const criteria = this.props.tableData[index];

    const Cell = ({ children }) => <TableCell
      key={elem}
      className={this.props.classes.input}
    >
      {children}
    </TableCell>;

    switch (criteria.type ?? 'input') {
      case 'select':
        return (<Cell>
          <FormControl variant='standard' sx={{ m: 1, minWidth: 150 }}>
            <Select
              name={elem}
              onChange={this.props.updateFilter}
              label={elem}
            >
              <MenuItem value={null}>
                {criteria.chip ?
                  <Chip label='TOUT' /> : 'TOUT'
                }
              </MenuItem>
              {
                criteria.data.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {
                      criteria.chip ?
                        this.genSelectWithBadge({
                          starColor: item.star,
                          color: item.color['600'],
                          label: item.label,
                          textColor: item.textColor
                        }) : item.label
                    }
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Cell>)
      default:
        return (<Cell>
          <Input
            fullWidth
            onKeyPress={this.props.updateFilter}
            name={elem}
          />
        </Cell>);
    }
  }

  render() {
    const {
      table,
      keys,
      setFilter,
      classes,
      handlerFilterByColumns,
      name,
      filteredByColumn
    } = this.props;
    const isMobile = this.props.isDown.md;
    return (
      <TableHead>
        <TableRow className={classes.ArrowHeader}>
          {Object.keys(table).map(elem => (
            <TableCell
              variant="head"
              className={classes.masterDiv}
              padding='checkbox'
              key={elem}
            >
              {elem}
              {filteredByColumn &&
                showFilter(elem, classes, handlerFilterByColumns, name)
              }
            </TableCell>
          ))}
        </TableRow>
        {
          setFilter && !isMobile && <TableRow>
            {keys.map(this.genFilterField)}
          </TableRow>
        }
      </TableHead>
    );
  }
}

export default withMediaQuery()(withStyles(TableHeader, styles));
