import { LoadingButton } from '@mui/lab';
import React from 'react';

const LoadingComponentWithButton = ({
  loading,
  textButton,
  componentToDisplay,
  style,
  onClick,
  loadingIndicator = 'Chargement...',
  icon = null,
  endIcon = null,
  loadingPosition = '',
  variant = 'contained',
  color = 'primary',
  size = 'medium',
  disabled = false,
  fullWidth = false,
  ariaLabel = '',
}) => (
  <>
    {componentToDisplay ? (
      componentToDisplay
    ) : (
      <LoadingButton
        style={style}
        variant={variant}
        color={color}
        size={size}
        disabled={disabled}
        fullWidth={fullWidth}
        aria-label={ariaLabel}
        loading={loading}
        onClick={onClick}
        startIcon={icon ? icon : null}
        endIcon={endIcon ? endIcon : null}
        loadingPosition={loadingPosition}
      >
        {loading ? loadingIndicator : textButton}
      </LoadingButton>
    )}
  </>
);

export default LoadingComponentWithButton;
