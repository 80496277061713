import React from 'react';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';

import MinCustomDialog from '../MinCustomDialog';
import CommentList from '../../List/CommentList';

const mapStateToProps = ({ users }) => ({ users });

const ReminderHistoryDialog = ({ callback, defaultText }) =>
  <MinCustomDialog
    middle
    open
    title={`Historique de relances de ${defaultText.name}`}
    actions={[
      <Button
        key={0}
        onClick={callback.close}
        children="Fermer"
      />
    ]}
  >
    <CommentList
      comments={defaultText.reminders}
      empty="Pas de relances effectuées"
    />
  </MinCustomDialog>;

export default connect(mapStateToProps)(ReminderHistoryDialog);
