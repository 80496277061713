import React from 'react';

import CustomTextField from './CustomInputs/CustomTextField';

const Comment = (props) => {
  const {
    value,
    setData,
    ...otherProps
  } = props;

  return (
    <CustomTextField
      grid
      sm={12}
      texttransform="none"
      rows={8}
      label="Commentaire..."
      value={value || ''}
      setData={setData}
      path={['commentaireCompta']}
      { ...otherProps }
    />
  );
};

export default Comment;
