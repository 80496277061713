/* eslint-disable max-lines */
import React, { PureComponent } from 'react';
import { List, Map, fromJS } from 'immutable';

import { withStyles } from 'tss-react/mui';
import Link from '@mui/material/Link';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { moment } from '@lba-dev/package.local-globals/moment';
import {
  P_TYPE_RECALL,
  P_TYPE_ABS
} from '@lba-dev/package.local-globals/prospRememberStatus';
import {
  data as categories,
} from '@lba-dev/package.local-globals/categories';
import { locationSearch } from '@lba-dev/package.local-globals/searchProsp';
import { data as docTypes } from '@lba-dev/package.local-globals/docTypes';

import { ListAlt } from '@mui/icons-material';

import {
  TableRepres,
  Horaires,
  Chips,
  Categories,
  InfoArtisan,
  InfoArtisanPlus,
  SourcesImg,
  DetailActions,
  DetailCardHeader,
  Dialogs,
  Status,
} from './index';
import api from '../../api';
import notifSystem from '../../notifSystem';
import DisplayNote from './DisplayNote';

const styles = theme => ({
  categories: {
    background: '#80808042',
    borderRadius: 5,
    width: '85%',
    margin: 'auto',
    align: 'center',
  },
  valider: {
    width: '100%',
    background: '#45a245',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: 200,
    left: 800,
  },
  cardScroll: {
    overflowY: 'auto',
    height: window.innerHeight - 200,
    borderRadius: '0px !important',
    boxShadow: 'none',
  },
  DetailAction: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  DetailActionB: {
    marginTop: 5,
    minWidth: 190
  },
  DetailActionLarge: {
    marginTop: 5,
    minWidth: 400
  },
  docs: {
    display: 'block',
    padding: '2px 10px'
  },
  padding: {
    padding: 16
  },
  centerDiv: {
    textAlign: 'center',
    marginBottom: 15
  }
});

class DetailItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      seeMore: false,
      hideDetail: true,
      open: false,
      time: '2019-05-24T18:00',
      openGalerie: false,
      openHist: false,
      openIfram: false,
      openSms: false,
      hist: [],
      _id: props.merge && props.merge.getIn(['elem', 'obj', '_id'])
    };
  }

  updateState = (name, value, cb = null) => {
    this.setState({ [name]: value }, cb);
  };

  changeTime = e => {
    this.setState({ time: e.target.value });
  };

  openDialog = () => {
    this.setState({ open: true, time: moment().format('YYYY-MM-DDT18:00') });
  };

  optionRappel = () => {
    const { merge, userId, setData, validate } = this.props;
    const { time } = this.state;
    const text = 'Rappel pour un Prosp';
    const startDate = moment(time).format('YYYYMMDDTHHmmss');
    const endDate = moment(time)
      .add(15, 'minutes')
      .format('YYYYMMDDTHHmmss');
    const url = `${window.location.origin}${
      window.location.pathname
    }?searchKey=${merge.getIn(['elem', 'obj', 'title'], '')}`;
    const details = `Rappeler l'artisan ${merge.getIn(
      ['elem', 'obj', 'title'],
      ''
    )} \n ${url}`;
    const googleCalendarUrl = `http://www.google.com/calendar/event?action=TEMPLATE&text=${text}&dates=${startDate}/${endDate}&details=${details}&location=Paris`;
    const reminders =
    merge.getIn(['elem', 'obj', 'reminders'], new List());
    this.setState({ open: false }, () => setData(
      ['elem', 'obj', 'reminders'],
      reminders.push(
        fromJS({
          userId,
          date: {
            reminder:
              moment(time).format(), creation: moment().format()
          },
          type: P_TYPE_RECALL
        }),
      ),
      validate
    ));

    window.open(googleCalendarUrl);
  };

  artisanMissing = () => {
    const { setData, userId, merge } = this.props;
    const { time } = this.state;
    const startDate = moment(time).toDate();
    const absences = merge.getIn(['elem', 'obj', 'reminders'], new List());
    this.setState({ open: false }, () => setData(['elem', 'obj', 'reminders'],
      absences.push(
        fromJS({
          userId,
          date: { reminder: startDate, creation: moment().format() },
          type: P_TYPE_ABS
        })
      )
    ));
  };

  addArtisan = () => {
    const { merge, data } = this.props;
    const catsId = (
      merge.getIn(['elem', 'obj', 'categorie'], new List()) || new List()
    ).map(value => categories.find(e => e.name === value)._id);
    const key = data.get('typeSearch', 0) === locationSearch ?
      'mapAddress' : 'searchKey';
    const value = data.get('typeSearch', 0) === locationSearch ?
      JSON.stringify(data.get('mapAddress').toJS())
      : data.getIn(['searchKey']);
    const addr = merge.getIn(['elem', 'obj', 'address'], new List());
    const address = addr.find(e => e.get('source') === 'pagespro')
      || addr.find(e => e.get('source') === 'societe.com')
      || addr.find(e => e.get('source') === 'opendatasoft')
      || new Map();
    const location = address.get('location', '');
    this.props.navigate('/artisan', {
      state: {
        copy: {
          companyName: merge.getIn(['elem', 'obj', 'title'], ''),
          prospId: merge.getIn(['elem', 'obj', '_id']),
          tel1: merge.getIn(['elem', 'obj', 'tels', '0'], ''),
          categories: catsId.toJS(),
          siret: merge.getIn(['elem', 'obj', 'etablissement', 'SIRET'])
        || merge.getIn(['elem', 'obj', 'entreprise', 'SIREN'], ''),
          key,
          email1: merge.getIn(['elem', 'obj', 'email'], ''),
          CPlusNumber: merge.getIn(['elem', 'obj', 'CPlusNumber']),
          QPacNumber: merge.getIn(['elem', 'obj', 'QPacNumber']),
          RGENumber: merge.getIn(['elem', 'obj', 'RGENumber']),
          date: merge.getIn(['elem', 'obj', 'date'], new Map()).toJS(),
          value,
          source: 'prosp',
          creationYear: moment(
            merge.getIn(
              ['elem', 'obj', 'entreprise', 'Date de création'],
              '1-1-1950'
            ),
            'DD-MM-YYYY'
          ).year(),
          ...(location && address.get('postcode', null) ? {
            address: [{
              number: address.get('housenumber', ''),
              road: address.get('street', ''),
              city: address.get('city', ''),
              zipcode: address.get('postcode', ''),
              location: location.toJS(),
            }],
            billingAddress: {
              number: address.get('housenumber', ''),
              road: address.get('street', ''),
              city: address.get('city', ''),
              zipcode: address.get('postcode', ''),
              location: location.toJS(),
            }
          } : {})
        }
      }
    });
  };

  showHist = async(merge) => {
    const prospId = merge.getIn(['elem', 'obj', '_id']);
    const dataHist = await api.history
      .getAll({ id: prospId, name: 'prospId' })
      .catch(() =>
        notifSystem.error('Erreur', 'Error lors du get sur history')
      );
    const dataAppels = await api.appels.getAll({
      query: JSON.stringify({ prospId })
    }).catch(() => notifSystem.error(
      'Erreur',
      'Les appels n\'ont pas pu être récupérés'
    ));
    const hist = dataHist.body().map(e => e.data());
    const appels = dataAppels.body().map(e => e.data())
      .sort((a, b) => new Date(b.date) - new Date(a.date));
    this.setState({
      openHist: true,
      hist,
      appels
    });
  };

  showAppelWarn = async (e, phoneIndex) => {
    const prospId = this.props.merge.getIn(['elem', 'obj', '_id']);
    const dataAppels = await api.appels.getAll({
      query: JSON.stringify({
        date: { $gt: moment().startOf('day').toDate() },
        prospId }),
      field: JSON.stringify({ _id: 1 }),
      limit: 1
    }).catch(() => notifSystem.error(
      'Erreur',
      'Les appels n\'ont pas pu être récupérés'
    ));
    const appels = dataAppels.body().map(e => e.data());
    return appels && !!appels.length ? this.setState({
      openAppel: true,
      callObj: { e, phoneIndex }
    }) : this.props.callFunc(e, phoneIndex);
  };

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.merge.getIn(['elem', 'obj', '_id'])
    !== prevState._id) {
      return {
        seeMore: false,
        _id: nextProps.merge.getIn(['elem', 'obj', '_id'])
      };
    }
    return null;
  }

  render() {
    const {
      updateState,
      classes,
      merge,
      validate,
      call,
      callFunc,
      edit,
      isAdmin,
      setData,
      removeTarget,
      interId,
      askStatus,
      statusItem,
      setStatus,
      setNote,
    } = this.props;
    const { seeMore } = this.state;
    const arrayTextField = () => {
      const { merge, isAdmin, call } = this.props;
      const ArrayTextField = [
        {
          path: ['elem', 'obj', 'nomRepresentant'],
          type: 'text',
          placeholder: 'Nom Représentant',
          disabled: false,
          width: 180,
        },
        {
          path: ['elem', 'obj', 'prenom'],
          type: 'text',
          placeholder: 'Prénom',
          disabled: false,
          width: 180,
        },
        {
          path: ['elem', 'obj', 'email'],
          type: 'text',
          placeholder: 'email',
          disabled: false,
          width: 180,
        },
        {
          path: ['elem', 'obj', 'entreprise', 'SIREN'],
          type: 'number',
          placeholder: 'SIRET',
          disabled: false,
          width: 180,
        },
      ];

      merge.getIn(['elem', 'obj', 'deletedTels'], new List()).forEach((d, i) =>
        ArrayTextField.push({
          checked: false,
          path: ['elem', 'obj', 'deletedTels', i],
          width: 180,
          type: 'password',
          placeholder: `tel ${i} ...`,
          index: i,
          disabled: true,
        })
      );
      merge.getIn(['elem', 'obj', 'tels'], new List()).forEach((d, i) =>
        ArrayTextField.push({
          checked: true,
          path: ['elem', 'obj', 'tels', i],
          width: 180,
          type: !call && !isAdmin ? 'password' : 'number',
          placeholder: `tel ${i} ...`,
          index: i,
          disabled: !call && !isAdmin,
        })
      );
      return ArrayTextField;
    };

    if (!!interId && askStatus) {
      setStatus(undefined, undefined, this.openDialog);
    }
    const interObj = merge.getIn(['elem', 'obj', 'inters'], new List())
      .find(e => e.get('id') === interId);
    const duration = interObj && interObj.get('duration') &&
      moment.duration(interObj.get('duration'), 'seconds');

    return (
      !!merge.size && (
        <React.Fragment>
          <Dialogs
            state={this.state}
            merge={merge}
            updateState={this.updateState}
            optionRappel={this.optionRappel}
            artisanMissing={this.artisanMissing}
            changeTime={this.changeTime}
            callFunc={callFunc}
          />
          <DetailCardHeader
            edit={edit}
            removeTarget={removeTarget}
            merge={merge}
            validate={validate}
          />
          <Card className={classes.cardScroll}>
            <pre>
              <strong>
                {interObj
                  ? `  ${interObj
                    .get('distance', 0)
                    .toFixed(interObj.get('distance', 0) < 1 ? 1 : 0)} km`
                  : ''}
                {duration
                  ? `  (${duration.hours()} h ${duration.minutes()} min)`
                  : ''}
              </strong>
            </pre>
            <SourcesImg
              merge={merge}
              updateState={this.updateState}
              seeMore={seeMore}
            />
            <DetailActions
              addArtisan={this.addArtisan}
              openDialog={this.openDialog}
              updateState={this.updateState}
              updateStateP={updateState}
              classes={classes}
            />
            {!interId ? (
              <Status
                openDialog={this.openDialog}
                merge={merge}
                setData={setData}
                call={call}
                askStatus={askStatus}
                updateState={updateState}
                validate={validate}
              />
            ) : (
              statusItem && (
                <div className={classes.centerDiv}>
                  <Button
                    onClick={() =>
                      setStatus(undefined, undefined, this.openDialog)
                    }
                    variant="outlined"
                    style={{
                      color: statusItem.color[500],
                      borderColor: statusItem.color[500],
                    }}
                  >
                    {statusItem.name}
                  </Button>
                </div>
              )
            )}
            {interObj && <DisplayNote
              setNote={setNote}
              interObj={interObj}
              validate={validate}
            />}
            <Chips
              data={
                merge.getIn(['elem', 'obj', 'subategorie'], new List()) ||
                new List()
              }
              color="primary"
            />
            <InfoArtisan
              isAdmin={isAdmin}
              merge={merge}
              setData={setData}
              call={call}
              callFunc={this.showAppelWarn}
              updateState={this.updateState}
              arrayTextField={arrayTextField}
            />
            <div className={classes.centerDiv}>
              <Button onClick={() => this.showHist(merge)} variant="outlined">
                <ListAlt />
                Historique
              </Button>
            </div>
            <Categories
              merge={merge}
              setData={setData}
              path={['elem', 'obj', 'categorie']}
            />
            <InfoArtisanPlus merge={merge} setData={setData} />
            <CardContent>
              <Typography component="p" style={{ marginTop: 8 }}>
                {merge.getIn(['elem', 'obj', 'description'])}
              </Typography>
            </CardContent>
            <Chips
              data={
                merge.getIn(['elem', 'obj', 'prestations'], new List()) ||
                new List()
              }
            />
            <TableRepres tab={merge.getIn(['elem', 'obj', 'entreprise'])} />
            <TableRepres tab={merge.getIn(['elem', 'obj', 'etablissement'])} />
            {merge.getIn(['elem', 'obj', 'dropbox'])?.size > 0 && (
              <CardContent>
                <Paper className={classes.padding}>
                  <Typography
                    children="Documents validés sur la v3"
                    variant="subtitle2"
                  />
                  {merge
                    .getIn(['elem', 'obj', 'dropbox'])
                    .toJS()
                    .map((e, i) => (
                      <Link
                        key={i}
                        className={classes.docs}
                        target="_blank"
                        href={e.link}
                        children={docTypes.find((d) => d._id === e.type).name}
                      />
                    ))}
                </Paper>
              </CardContent>
            )}
            <Horaires merge={merge} />
          </Card>
        </React.Fragment>
      )
    );
  }
}

export default withStyles(DetailItem, styles);
