import {
  CALL_EDIT_CITY,
  getEditCity,
  CALL_UPDATE_CITY,
  getNewListCities
} from '../actions/etatReseau';
import notifSystem from '../notifSystem';
import api from '../api';

const editCity = (store) => next => (action) => {
  switch (action.type) {
    case CALL_EDIT_CITY: {
      const cities = store.getState().etatReseau.cities;
      const result = cities.find(city => city._id === action.currentCity);
      store.dispatch(getEditCity(result));
      break;
    }
    case CALL_UPDATE_CITY: {
      const id = store.getState().etatReseau.currentCityEdited._id;
      const distance = store.getState().etatReseau.updateDistCity;
      api.all('cities').custom('modifyCity').post({ id, distance }).then(() => {
        notifSystem.success('Opération réussie',
          'La ville a bien été modifiée !');
        store.dispatch(getNewListCities());
      }).catch(() =>
        notifSystem.error('Erreur',
          'erreur sur la modification de votre ville'));
      break;
    }
    default:
      break;
  }
  next(action);
};

export default editCity;
