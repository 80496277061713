import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { Grid, MenuItem } from '@mui/material';
import CustomSelect from '../CustomInputs/CustomSelect';
import { connect } from 'react-redux';
import api from '../../api';
import { onDownload, downloadFromBase64 } from '../../utils/download';
import notifSystem from '../../notifSystem';

class ArchiveReglementCentrDialog extends Component {
  state = {
    year: 2022,
  };

  handleClick = () => {
    this.props.callback();
  };

  getExport = (name) => {
    const { year } = this.state;
    if (year === 2022) {
      api.reglements.custom(`getExport/${name}`)
        .get()
        .then(e => e.body().data())
        .then(({ file }) => {
          if (name === 'csv') {
            onDownload(
              {
                contents: file,
                filename: `Centralisation-${year}`,
              },
              name
            );
          } else if (name === 'pdf') {
            downloadFromBase64(
              {
                content: file,
                fileName: `Centralisation-${year}`,
              },
              name
            );
          }
          notifSystem.success(`${name}`, 'Téléchargement réussi');
        }).catch(() => {
          notifSystem.success(`${name}`, 'Echec du téléchargement');
        });
    }
    this.props.callback();
  };

  render() {
    const { year } = this.state;
    return (
      <Grid container spacing={2} justifyContent="space-between">
        <CustomSelect
          grid
          xs={12}
          name="Année"
          value={year}
          path={[]}
          setData={(p, v) => this.setState({ year: v })}
        >
          {[2022, 2023].map((e, i) => (
            <MenuItem disabled={e === 2023} key={i} value={e}>
              {e}
            </MenuItem>
          ))}
        </CustomSelect>
        <Grid item xs={4}>
          <Button variant="outlined" onClick={() => this.getExport('csv')}>
            Export CSV
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button variant="outlined" onClick={() => this.getExport('pdf')}>
            Export PDF
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ dialog: { contentProps } }, props) => ({
  ...(contentProps || props),
});

export default connect(mapStateToProps)(ArchiveReglementCentrDialog);
