import React from 'react';
import { Grid, Typography } from '@mui/material';
import TimePicker from '../../TimePicker';
import CustomCheckbox from '../../CustomInputs/CustomCheckbox';
import { moment } from '@lba-dev/package.local-globals/moment';
import { makeStyles } from 'tss-react/mui';

const weekDays = [
  { day: 'Lundi', value: 64 },
  { day: 'Mardi', value: 32 },
  { day: 'Mercredi', value: 16 },
  { day: 'Jeudi', value: 8 },
  { day: 'Vendredi', value: 4 },
  { day: 'Samedi', value: 2 },
  { day: 'Dimanche', value: 1 },
];

const formatDate = (int) =>
  moment().startOf('day').add(int, 'minutes').format('HH[:]mm');

const useStyles = makeStyles()({
  title: { padding: '32px 0 0 0!important' }
});

const NukiEdit = ({ nuki, setTime, calcAllowedWeekDays }) => {
  const { classes } = useStyles();
  return Object.keys(nuki).length
    ? (
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid item xs={12} className={classes.title}>
          <Typography children="NUKI" variant="subtitle2" align="center" />
        </Grid>
        <Grid item xs={4}>
          <TimePicker
            label={'Heure d\'arrivée'}
            name="allowedFromTime"
            value={formatDate(nuki.allowedFromTime)}
            onChange={setTime}
            labelRemoveSpace={true}
            size="100%"
          />
        </Grid>
        <Grid item xs={4}>
          <TimePicker
            label={'Heure de départ'}
            name="allowedUntilTime"
            value={formatDate(nuki.allowedUntilTime)}
            onChange={setTime}
            labelRemoveSpace={true}
            size="100%"
          />
        </Grid>
        <Grid item xs={9} align="center">
          {weekDays.map((e, i) => (
            <CustomCheckbox
              key={i}
              label={e.day}
              checked={nuki.allowedWeekDays.includes(e.value)}
              path={e.value}
              setData={calcAllowedWeekDays}
            />
          ))}
        </Grid>
      </Grid>
    )
    : '';
};

export default NukiEdit;
