import { List } from '@mui/icons-material';
import { CHIEF } from '@lba-dev/package.local-globals/permissions';

const getChild = ({ array, name, user, baseUrl, baseParams }) =>
  array.map(({ link, name: nameOfLink, childs, params }) => ({
    name: `${name} ${nameOfLink}`,
    icon: List,
    ...(childs
      ? {
        childs: getChild({
          array: childs,
          name: '', user,
          baseUrl,
          baseParams
        })
      }
      : {
        count: true,
        link: `${baseUrl}${link}/${params ?
          params : baseParams}=${user.login}=${user._id}`,
      }
    ),
  }));

export const listByUsers = ({
  name,
  array,
  link,
  service,
  baseUrl,
  baseParams
}) => ({
  name: `Mes ${name}`,
  icon: List,
  childs: ({ users, user }) =>
    user.permission <= CHIEF &&
      user.service === service
      ? users
        .filter(e =>
          e.act &&
          e.service === service &&
          e.permission >= user.permission
        )
        .map(e => ({
          name: e._id === user._id ? `Mes ${name}` : `${name} de ${e.login}`,
          icon: List,
          ...(link
            ? {
              count: true,
              link: `${baseUrl}${link.link}/${baseParams}=${e.login}=${e._id}`
            }
            : {
              childs: getChild({
                array,
                name: e._id === user._id ? '' : e.login,
                user: e,
                baseUrl,
                baseParams
              })
            }
          )
        }))
      : getChild({
        array: array || [link],
        name: '',
        user,
        baseUrl,
        baseParams
      }),
});

export const listOrLinkByUser = ({
  user, service, users, name, baseParams, baseUrl, link, icon
}) => ({
  name,
  icon,
  ...(user.permission < CHIEF && user.service === service
    ? {
      childs: users
        .filter(e =>
          e.act && e.service === service && e.permission >= user.permission)
        .sort((a) => a._id === user._id ? -1 : 0)
        .map(e => ({
          name: e._id === user._id ? `Mes ${name}` : `${name} de ${e.login}`,
          icon: List,
          count: true,
          link: `${baseUrl}${link}/${baseParams}=${e.login}=${e._id}`
        }))
    } : {
      count: true,
      link: (user) =>
        `${baseUrl}${link}/${baseParams}=${user.login}=${user._id}`
    }
  )
});
