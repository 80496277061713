import React from 'react';

import {
  Avatar, ListItemAvatar, Tooltip
} from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((_theme, { isSearch }) => ({
  avatar: {
    minWidth: 42,
    height: !isSearch ? 50 : '',
  },
}));

const ItemAvatar = ({
  elem, isSearch, info, ...avatarProps
}) => {
  const { classes } = useStyles({
    isSearch,
  });
  const name = elem.companyName || '';
  return <ListItemAvatar className={classes.avatar}>
    <Tooltip title={name} position='top'
      disableFocusListener={true}
      disableHoverListener={true}
      disableInteractive={true}
      disableTouchListener={true}
    >
      <Avatar
        src={info.logo || ''}
        {...avatarProps}
        sx={{
          ...avatarProps.sx,
          bgcolor: info.logo ? 'grey' : avatarProps.sx.bgcolor
        }}
      />
    </Tooltip>
  </ListItemAvatar>;
};

export default ItemAvatar;
