import {
  ADMIN,
  NOT_CONFIRMED,
} from '@lba-dev/package.local-globals/permissions';
import { S_RH } from '@lba-dev/package.local-globals/services';
import componentLoader from '../../componentLoader';

const InterventionService = componentLoader(() =>
  import(
    /* webpackChunkName: "InterventionService" */
    '../../views/Cti/Components/InterventionService'
  )
);

const StatsAgents = componentLoader(() =>
  import(
    /* webpackChunkName: "StatsAgents" */
    '../../views/Cti/StatsAgents'
  )
);

const StatsServices = componentLoader(() =>
  import(
    /* webpackChunkName: "StatsServices" */
    '../../views/Cti/StatsServices'
  )
);

const SearchNumber = componentLoader(() =>
  import(
    /* webpackChunkName: "Search" */
    '../../views/Cti/Search')
);

const MapCalls = componentLoader(() =>
  import(
    /* webpackChunkName: "MapCalls" */
    '../../views/Cti/MapCalls'
  )
);

const UpdateOVH = componentLoader(() =>
  import(
    /* webpackChunkName: "UpdateOVH" */
    '../../views/Cti/UpdateOVH'
  )
);

const UpdateGLS = componentLoader(() =>
  import(
    /* webpackChunkName: "UpdateGLS" */
    '../../views/Cti/UpdateGLS'
  )
);

const ListTransfromCall = componentLoader(() =>
  import(
    /* webpackChunkName: "ListTransfromCall" */
    '../../views/Cti/ListTransfromCall'
  )
);

const ListUnansweredCalls = componentLoader(() =>
  import(
    /* webpackChunkName: "ListUnansweredCalls" */
    '../../views/Cti/ListUnansweredCalls'
  )
);

export default [
  {
    path: '/cti/interService',
    component: InterventionService,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/cti/statsAgent',
    component: StatsAgents,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/cti/statsService',
    component: StatsServices,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/cti/mapCalls',
    component: MapCalls,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/cti/search',
    component: SearchNumber,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/cti/updateOVH',
    component: UpdateOVH,
    restrictions: () => ADMIN,
  },
  {
    path: '/cti/updateGLS',
    component: UpdateGLS,
    restrictions: () => ADMIN,
  },
  {
    path: '/cti/transformCall',
    component: ListTransfromCall,
    restrictions: ({ service }) =>
      ({
        [S_RH]: NOT_CONFIRMED,
      }[service] || ADMIN),
  },
  {
    path: '/cti/unansweredCalls',
    component: ListUnansweredCalls,
    restrictions: () => ADMIN,
  },
];
