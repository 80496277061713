import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import {
  NEU, data as cData
} from '@lba-dev/package.local-globals/commentsConstants';
import MinCustomDialog from './MinCustomDialog';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
const mapStateToProps = state => ({
  users: state.users,
});

const styles = {
  listContainer: {
    maxHeight: 630,
    overflow: 'auto',
  },
  list: {
    width: '100%',
  },
  listItem: {
    width: '100%',
    padding: '5px',
    overflowWrap: 'break-word',
  },
  ContainerTextField: {
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-end',
  },
  menuItem: {
    justifyContent: 'space-between',
  },
  icon: {
    fontSize: 17,
    paddingLeft: 5,
    paddingTop: 4,
  },
  linearDiv: {
    position: 'absolute',
    width: '95%',
  },
  linear: {
    height: 1,
  },
};

class TextDialogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      opinion: NEU,
      comments: ((props.defaultText || {}).data || []).reverse(),
      isLoading: false,
    };
  }

  setTextValue = e => {
    this.setState({
      value: e.target.value,
    });
  };

  loading = () => {
    this.setState({
      isLoading: true,
    });
    setTimeout(() => this.setState({ isLoading: false }), 3000);
  };

  onValidate = () => {
    if (typeof this.props.callback === 'function') {
      if (this.props.defaultText.type === 'comments') {
        this.loading();
        this.props.callback(this.state.value, this.state.opinion);
      } else {
        this.props.callback(this.state.value);
      }
    }
  };
  onReject = () => {
    if (typeof this.props.callback === 'function') {
      this.props.callback(null);
    }
  };

  actions = [
    {
      title: 'Annuler',
      onClick: this.onReject,
      color: 'secondary',
    },
    {
      title: 'Valider',
      onClick: this.onValidate,
      color: 'primary',
    },
  ];

  renderComments = ({ users, isInter }, classes) => (
    <Grid container spacing={2}>
      <Grid container item xs={12} className={classes.listContainer}>
        <List className={classes.list}>
          {this.state.comments.map((e, i) => {
            const user = users && users.find(user => user._id === e.userId);
            const userLogin =
              (user && user.login) || (!e.comptaStatus && 'Introuvable');
            const date = e.date && new Date(e.date).toLocaleString('fr-FR');
            const item = !isInter && cData.find(item => item._id === e.type);
            return (
              item && (
                <ListItem key={i} className={classes.listItem} divider>
                  <ListItemText
                    primary={e.commentValue || e.comments}
                    primaryTypographyProps={{
                      style: { color: e.color },
                    }}
                    secondary={`${date ? `${date} - ` : ''}${userLogin || ''}`}
                  />
                  {item.Icon ? (
                    <ListItemIcon>
                      <Tooltip disableInteractive title={item.name}>
                        <item.Icon fontSize="small" />
                      </Tooltip>
                    </ListItemIcon>
                  ) : (
                    ''
                  )}
                </ListItem>
              )
            );
          })}
        </List>
      </Grid>
      <Grid item xs={isInter ? 12 : 9} className={classes.ContainerTextField}>
        <TextField
          variant="standard"
          placeholder="...commentaire"
          fullWidth
          onChange={this.setTextValue}
          value={this.state.value}
          multiline
          maxRows={10}
          InputProps={{ disableUnderline: true }} />
        {this.state.isLoading && (
          <div className={classes.linearDiv}>
            <LinearProgress className={classes.linear} />
          </div>
        )}
      </Grid>
      {!isInter ? (
        <Grid item xs={3}>
          <Select
            value={this.state.opinion}
            name={'setOpinion'}
            onChange={event => {
              this.setState({
                opinion: event.target.value,
              });
            }}
          >
            {cData.map(
              (e, i) =>
                e.icon &&
                <MenuItem key={i} value={e._id} className={classes.menuItem}>
                  {e.name}
                  <e.icon
                    className={classes.icon}
                    style={{ color: e.color[500] }}
                  />
                </MenuItem>
            )}
          </Select>
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );

  render() {
    const {
      defaultText: { type, title, isInter },
      classes,
      users,
    } = this.props;
    return (
      <MinCustomDialog
        middle
        open
        title={(type === 'comments' && 'Commentaires') || title}
        actions={this.actions.map(({ title, onClick, color }, i) => (
          <Button key={i} onClick={onClick} color={color}>
            {title}
          </Button>
        ))}
      >
        {type === 'comments' ? (
          this.renderComments({ users, isInter }, classes)
        ) : (
          <TextField
            variant="standard"
            fullWidth
            onChange={this.setTextValue}
            value={this.state.value}
            multiline={true}
            maxRows={10} />
        )}
      </MinCustomDialog>
    );
  }
}

TextDialogs.propTypes = {
  callback: PropTypes.func.isRequired,
  defaultText: PropTypes.object,
};

export default connect(mapStateToProps)(withStyles(TextDialogs, styles));
