import { site } from '../constants/config';
import { needToComment } from '@lba-dev/package.local-globals/demandeStatus';
import api from '../api';
import notifSystem from '../notifSystem';
import store from '../store';
import { setDialog, setDialogComment } from './dialog';
import { call } from '../utils/call';
import IphoneDialog from '../components/Dialogs/IphoneDialog';
import SMSDialog from '../components/Dialogs/SMSDialog';
import ConfirmBox from '../components/Dialogs/ConfirmBox';
import { moment } from '@lba-dev/package.local-globals/moment';
import { createGoogleCalendar } from './googleCalendar';
import {
  smsReminderDemande
} from '@lba-dev/package.local-globals/demandeTemplate';
import copyFromDemande from
  '@lba-dev/package.local-globals/copyData/copyFromDemande';
import { rappelContent } from '../components/List/RappelContent';

const relanceClient = (
  { demandeId, clientTel, clientName, smsContent, clientEmail },
  mode = 'sms',
  notif = true
) => {
  const action = mode === 'sms' ? 'SMS' : 'Mail';
  return api.demandes
    .custom(`relance/${demandeId}`)
    .post({
      id: demandeId,
      clientTel,
      clientName,
      smsContent,
      mode,
      clientEmail,
    })
    .then(() =>
      notif &&
      notifSystem.success(`${action} envoyé`, `Le ${action} a bien été envoyé`)
    )
    .catch(() =>
      notif &&
      notifSystem.error('Erreur', `Le ${action} n'a pas pû être envoyé`)
    );
};

export const changeStatus = (elem, status, userId) =>
  api
    .demandes
    .patch(elem._id, {
      status, login: {
        ...(!elem?.login?.ajout ? { ajout: userId } : {}),
        modification: userId
      }
    }
    )
    .then(() => notifSystem.success(
      'Opération réussie',
      'Le statut a bien été modifié'
    ))
    .catch(() => notifSystem.error(
      'Erreur',
      'Le statut n\'a pas pû être modifié'
    ));

export const commentDemande = (id, commentValue) =>
  api
    .demandes
    .custom(`comment/${id}`)
    .patch({ commentValue })
    .then(() => notifSystem.success(
      'Opération réussie',
      'Le commentaire a bien été ajouté'
    ))
    .catch(() => notifSystem.error(
      'Erreur',
      'Le commentaire n\'a pas pû être ajouté'
    ));

const changeRefusReason = ({ id, selected, userId, status, raisonRefus }) =>
  api.demandes.patch(
    id,
    { status, raisonRefus, login: {
      ...(!selected?.login?.ajout ? { ajout: userId } : {}),
      modification: userId,
      refus: userId
    },
    date: {
      refus: new Date()
    },
    }
  ).then(() => notifSystem.success(
    'Opération réussie',
    'Le statut a bien été modifié'
  )).catch(() => notifSystem.error(
    'Erreur',
    'Le statut n\'a pas pû être modifié'
  ));

const dialogRaison = (cb, contentProps, dialogProps = {}) =>
  store.dispatch(
    setDialogComment(
      ({ text }, close) => {
        if (!text || (text && !text.trim())) {
          return notifSystem.error('Erreur', 'Refus vide');
        }
        if (cb) {
          cb(text);
        }
        close();
      },
      contentProps,
      dialogProps,
      'Ajouter'
    )
  );

export const displayRelanceDialog = (mode = 'sms', args = {}) => ({
  setDialog: setDialogFromProps,
  selected,
  user,
  selectedForCampaign = []
}) => {
  const smsContent = smsReminderDemande(selected, user);
  const selecteds = selectedForCampaign?.length ?
    selectedForCampaign : [selected];

  return mode === 'sms'
    ? setDialogFromProps(IphoneDialog, true, { text: smsContent },
      (message) => {
        if (message) {
          selecteds.forEach((selected, i) => {
            relanceClient({
              demandeId: selected._id,
              clientTel: selected.client.tel1,
              clientName: selected.client.fullName,
              smsContent: message
            }, 'sms', i === selecteds.length - 1);
          });
        }
        setDialogFromProps(null, false, null, null);
      })
    : dialogRaison(
      (text) => {
        if (args.raisonRefus) {
          changeRefusReason({
            id: selected._id,
            selected,
            userId: user._id,
            ...args
          });
        }
        selecteds.forEach((selected, i) => {
          if (selected.client && selected.client.email1) {
            relanceClient({
              demandeId: selected._id,
              clientEmail: selected.client.email1,
              clientName: selected.client.fullName,
              smsContent: text
            }, 'mail', i === selecteds.length - 1);
          }
        });
      },
      {
        text: smsContent,
        placeholder: 'Entrez le mail de relance'
      },
      { title: 'Mail de relance' }
    );
};

export const callChangeStatus = (status, raisonRefus, mode = 'sms') =>
  ({ setDialog, selected, userId, user }) => {
    if (mode === 'mail') {
      if (selected.client.email1) {
        return displayRelanceDialog(mode, { status, raisonRefus })({
          setDialog,
          selected,
          user
        });
      }
    }
    else if (!raisonRefus && needToComment.includes(status)) {
      return dialogRaison(
        (text) => changeRefusReason({
          id: selected._id,
          userId,
          status,
          raisonRefus: text
        }),
        { placeholder: 'Entrez la raison du refus...' },
      );
    }
    return changeRefusReason({ id: selected._id, userId, status, raisonRefus });
  };


export function callAppel({ selected, user }) {
  Promise.all([
    api.appels.custom('add').post({
      demandeId: selected._id,
      number: selected.client.tel1,
      companyName: `${selected.client.name || ''} ${
        selected.client.firstname || ''}`,
      data: {
        userLogin: user.login,
        userLigne: user.ligne,
        userAlias: user.alias,
        userEmail: user.email,
        choice: selected.choice,
        source: selected.source,
        status: selected.status,
        externalId: selected.externalId,
      },
    }),
    !selected.date.firstCall &&
      api.demandes.patch(selected._id, { date: { firstCall: new Date() } }),
  ])
    .then(() => call(selected.client.tel1))
    .catch((e) => notifSystem.error(e.name, e.message));
}

export const callAction = ({ selected }) => {
  api.appels.custom('add').post({
    demandeId: selected._id,
    number: selected.currentArtisan.telArtisan,
    artisanId: selected.artisan,
    companyName: selected.currentArtisan.companyName
  })
    .then(() => call(selected.currentArtisan.telArtisan))
    .catch((e) => notifSystem.error(e.name, e.message));
};

export function cancelDemande({ selected, ...props }) {
  function cancelCallback(obj) {
    props.setDialog(null, false, '', null);
    if (obj) {
      api.demandes
        .delete(selected._id)
        .then(() => notifSystem.success(
          'Opération réussie',
          'La demande a bien été supprimée'
        ))
        .catch(() => notifSystem.error(
          'Erreur',
          'La demande n\'a pas pû être supprimée'
        ));
    }
  }

  props.setDialog(
    ConfirmBox,
    true,
    {
      title: 'Etes-vous sûr de vouloir supprimer la demande ?',
      confirmBox: 'Oui',
      closeBox: 'Annuler',
    },
    cancelCallback
  );
}

export const transferTo = (redirectTo, newTab = false) => ({
  selected,
  navigate
}) => {
  const idExist = ((selected || {})[redirectTo] || {}).id;
  if (idExist) {
    if (!newTab) {
      return navigate(`/${redirectTo}/${idExist}`);
    }
    return window.open(`/${redirectTo}/${idExist}`);

  }
  const name = {
    intervention: 'Inter',
    devis: 'Devis'
  };
  const data = copyFromDemande(selected, redirectTo)
  if (!newTab) {
    return navigate(`/${redirectTo}`, {
      state: {
        market: data
      },
    });
  }
  localStorage.setItem(
    `tmpData${name[redirectTo]}_new`,
    JSON.stringify(data)
  );
  return window.open(redirectTo);
};

export function showSMS(props, demande) {
  props.setDialog(SMSDialog, true, { demandeId: demande.id }, () =>
    props.setDialog(null, false, '', null)
  );
}

export function getAllSourceOfDemande() {
  return api.demandes
    .custom('sources')
    .get()
    .then((e) => e.body().data().resultat)
    .catch(() => {
      notifSystem.error('Erreur', 'Récupération des sources');
      return [];
    });
}

export const createReminder = (demandeId, comment, date) =>
  api.demandes.custom(`addReminder/${demandeId}`).post({
    comment: comment.trim(),
    date,
  });

export const reminderAction = status => ({
  selected,
  userId
}) => {
  const title = `Rappel
  ${selected.client.firstname} ${selected.client.name} - ${selected.choice}`;
  store.dispatch(setDialog({
    name: 'ReminderDialog',
    open: true,
    dialogProps: {
      title
    },
    contentProps: {
      withComment: true
    },
    actions: [
      {
        children: 'Enregistrer',
        color: 'primary',
        onClick: ({ date = new Date(), commentaire }, close) => {
          createGoogleCalendar(
            date,
            title,

            rappelContent(
              {
                number: selected.client.number,
                road: selected.client.road,
                zipCode: selected.client.zipCode,
                city: selected.client.city,
                firstname: selected.client.firstname,
                name: selected.client.name,
                tel: selected.client.tel || selected.client.tel1,
                email: selected.client.email || selected.client.email1,
                date: selected.date.ajout,
                lien: `${site
                }/demandes/list/all?query="{"client.tel1":"${selected
                  .client.tel1}"}"`,
                commentaire: commentaire,
                description: selected.description
              }
            ));
          Promise.all([
            createReminder(
              selected._id,
              `Rappeler le ${moment(date).format('LLL')}`,
              new Date(date).getTime()
            ),
            changeStatus(selected, status, userId, date)
          ]);
          close();
        },
      }
    ]
  }));
};

export const sendGCDemandeSign = (interId) => {
  api.demandes.custom('demandeSign').post({ interId })
    .then(e => (e.statusCode() === 202) ?
      notifSystem.warning('⚠ Document signé',
        'Le client a déjà signé le document') :
      notifSystem.success('Demande signature',
        'La demande a bien été envoyé')
    );
};

export const sendMail = (demande, userSelected) => api.demandes
  .custom(`sendMail/${demande._id}`)
  .post({ userSelected })
  .then(() => notifSystem.success('Mail envoyé', 'Le Mail a bien été envoyé'))
  .catch(() => notifSystem.error('Erreur', 'Le Mail n\'a pas pû être envoyé'));

export const sendNoResponseMail = (demande) => api.demandes
  .custom('sendNoResponseMail')
  .post({ demande })
  .then(() => notifSystem.success('Mail envoyé', 'Le Mail a bien été envoyé'))
  .catch(() => notifSystem.error('Erreur', 'Le Mail n\'a pas pû être envoyé'));

export const getStats = (route, {
  usersSelected = [], checkedSources = [], ...params
}) => api.stats
  .custom(route)
  .get({ ...params, checkedSources, usersSelected })
  .then((response) => response.body().data().result)
  .catch(() => {
    notifSystem.error('Erreur', 'Impossible de charger les statistiques');
    return [];
  });

export const accepteEDIMission = (id) => api.interventions.patch(id, {
  sendAcceptationEDI: true
})
  .then(() =>
    notifSystem.success('Message', 'La mission a bien été acceptée'))
  .catch(() =>
    notifSystem.error('Erreur', 'La mission n\'a pas pû être acceptée'));

export const generateCampaignsToSend = ({ selectedForCampaign, selected }) =>
  (selectedForCampaign.length ? selectedForCampaign : [selected]).map(e => ({
    phoneNumber: e.client.tel1,
    campaignName: e.client.name ?
      `Campagne pour la demande de ${e.client.name} ${e.client.firstname}` :
      `Campagne pour la demande du ${e.client.tel1}`,
    historyInfo: { demandeId: e._id }
  }));
