import { addAction } from './datadog';
import { permit } from '@lba-dev/package.local-globals/restrictions';

export default (user) => {
  if (!permit(user)) {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
    document.onkeydown = function (e) {
      if (process.env.NODE_ENV === 'production') {
        if (e.keyCode === 123) {
          addAction('Open inspect', { value: e.keyCode });
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
          addAction('Open inspect', { value: e.keyCode });
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
          addAction('Open inspect', { value: e.keyCode });
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
          addAction('Open inspect', { value: e.keyCode });
          return false;
        }
        if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
          addAction('Open inspect', { value: e.keyCode });
          return false;
        }
      }
    };
    window.matchMedia('print').addListener((mql) => {
      addAction('Open print', { value: mql.matches });
    });

    document.body.addEventListener('contextmenu', (event) => {
      addAction('Right click', { value: event.target.value });
    });
    document.body.addEventListener('copy', (event) => {
      addAction('Copy action', { value: event.target.value });
    });
    document.body.addEventListener('cut', (event) => {
      addAction('Cut action', { value: event.target.value });
    });
    document.body.addEventListener('paste', (event) => {
      addAction('Paste action', { value: event.target.value });
    });
    document.body.addEventListener('select', (event) => {
      addAction('Select action', { value: event.target.value });
    });
  }
};
