import { connect } from 'react-redux';

import MinCustomForm from '../Utils/MinCustomForm';
import { setDialogContentProp } from '../../actions/dialog';

const setValue = setDialogContentProp('value');

const mapStateToProps = ({ dialog: { contentProps } }) => contentProps;

const mapDispatchToProps = {
  handleChange: e => setValue(e.target.value)
};

export default connect(mapStateToProps, mapDispatchToProps)(MinCustomForm);
