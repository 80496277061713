import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import categories from '@lba-dev/package.local-globals/categories';
import { grey } from '@mui/material/colors';

const styles = {
  root: {
    flexGrow: 1,
    marginBottom: 5,
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  },
  ListItem: {
    backgroundColor: '#8080805c',
  },
  button: {
    margin: 2,
    color: 'white'
  },
  Collapse: {
    textAlign: 'center'
  }
};

class CateogriesFiltres extends PureComponent {
  state = {
    open: true
  }

  openCollapse = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { classes, choosenCats, setArrayFilter } = this.props;
    return (
      <List
        component="nav"
        className={classes.root}>
        <ListItem button onClick={this.openCollapse}
          className={classes.ListItem} >
          <ListItemText inset primary="Métiers" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse className={classes.Collapse}
          in={this.state.open} timeout="auto" unmountOnExit>
          {categories.data.map(e => (
            <Button
              onClick={() => setArrayFilter('categorie', e.name)}
              key={e._id}
              variant="contained"
              className={ classes.button }
              style={{
                backgroundColor:
                choosenCats.includes(e.name) ? e.color[500] : grey[500]
              }}
            >{e.shortName}
            </Button>
          ))}
        </Collapse>
      </List>
    );
  }
}

export default withStyles(CateogriesFiltres, styles);
