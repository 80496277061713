import React from 'react';
import PropTypes from 'prop-types';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import CustomTextField from '../CustomInputs/CustomTextField';
import DatePicker from '../DatePicker';
import { withStyles } from 'tss-react/mui';

const displaySearchInput = (value) => {
  if (value === 'Date') {
    return <DatePicker />;
  }
  return <CustomTextField
    value="test"
  />;
};

const styles = {
  cell: {
    display: 'flex',
    justifyContent: 'center'
  }
};

const Cell = ({ children, ...props }) =>
  <TableCell align="center" {...props}>
    {children}
  </TableCell>;

const TitleCell = withStyles(({
  classes,
  column,
  sortBy,
  keyName,
  handleChangeSortBy,
  order
}) =>
  <TableCell>
    <div className={classes.cell}>
      {typeof column === 'string' ? <Typography
        variant="body1"
        align="center"
        onClick={() => handleChangeSortBy(keyName)}>
        {column}
      </Typography> : <div style={{ textAlign: 'center' }}>{column}</div>}
      <Tooltip disableInteractive
        title="Trier"
        placement={'bottom-end'}
        enterDelay={300}
      >
        <TableSortLabel
          onClick={() => handleChangeSortBy(keyName)}
          active={sortBy === keyName}
          direction={order}
        />
      </Tooltip>
    </div>
  </TableCell>, styles);

const ListHeader = ({
  columns,
  formattedKeys,
  keys,
  filter,
  fields,
  sortBy,
  ascOrder,
  displayers,
  headers,
  launchRequest,
  handleChangeSortBy,
  handleChangeFilter,
  handleChangeField
}) =>
  <TableHead>
    <TableRow className={'cellPadding'}>
      {columns.map((column, i) =>
        <TitleCell
          key={column}
          column={formattedKeys[i] in headers ?
            headers[formattedKeys[i]](
              fields[formattedKeys[i]],
              handleChangeField(formattedKeys[i])
            ) : column}
          keyName={keys[i]}
          sortBy={sortBy}
          order={ascOrder ? 'asc' : 'desc'}
          handleChangeSortBy={handleChangeSortBy}
        />
      )}
    </TableRow>
    {!!filter && <TableRow className={'cellPadding'}>
      {formattedKeys.map(key =>
        <Cell key={key}>
          {(key in displayers ? displayers[key] : displaySearchInput)(
            filter[key],
            handleChangeFilter(key),
            launchRequest
          )}
        </Cell>
      )}
    </TableRow>}
  </TableHead>;

ListHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  formattedKeys: PropTypes.array,
  keys: PropTypes.array,
  sortBy: PropTypes.string,
  ascOrder: PropTypes.bool,
  displayers: PropTypes.object,
  launchRequest: PropTypes.func,
  handleSortBy: PropTypes.func,
  handleChangeFilter: PropTypes.func
};

ListHeader.defaultProps = {
  columns: [],
  formattedKeys: [],
  keys: [],
  ascOrder: true,
  displayers: {},
  launchRequest: f => f,
  handleSortBy: f => f,
  handleChangeFilter: f => f
};


export default ListHeader;
