import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Divider, ListItemButton, alpha
} from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { blue, blueGrey, grey } from '@mui/material/colors';


import {
  genConversationInfo,
  getPinned,
  smsTypes, stringAvatar
} from '../../../constant';
import ItemAvatar from './ItemAvatar';
import ItemDetails from './ItemDetails';
import { mapStateToProps } from '../../../../../hoc/withUserTab/functions';
import { withDisplaying, withMediaQuery } from '../../../../../hoc';

const useStyles = makeStyles()(
  (_theme, { selected, isSearch, isPinned }, classes) => ({
    listButton: {
      '&:hover': {
        [`& .${classes.pinBox}`]: {
          display: 'block'
        },
        [`& .${classes.emptyBox}`]: {
          display: 'none'
        },
        ...(selected ? { backgroundColor: alpha(blueGrey['A100'], 0.8) } : {})
      },
      ...(selected ? { backgroundColor: alpha(blueGrey['A100'], 0.5) } : {}),
      height: 80
    },
    pinBox: {
      display: (isPinned && (isSearch ? 'flex' : 'block')) || 'none',
      ...(isSearch ? { justifyContent: 'center' } : {}),
      textAlign: 'right',
      paddingTop: 5
    },
    emptyBox: {
      display: isSearch || isPinned ? 'none' : 'block',
      padding: 4
    },
    pinned: {
      fontSize: 16,
      paddingBottom: 0
    },
    dateModeBox: {
      backgroundColor: alpha(grey[50], 0.8),
      borderRadius: 15,
      padding: 0,
      paddingLeft: 4
    },
    secondaryAction: {
      textAlign: 'right',
      height: !isSearch ? 62 : '',
    },
    dateString: {
      paddingRight: 2
    },
    notifCircle: {
      fontSize: 10,
      backgroundColor: blue['800'],
      height: 20,
      width: 20,
      float: 'right'
    },
  }));

const ContactItem = ({
  elem, selected, setSelected, user,
  isSearch, smsType, pinConversation, index, setSmsType, isDown
}) => {
  const pinned = getPinned(smsType, user.pinnedConversations);
  const isPinned = pinned.find(e => e._id === elem._id);

  const { classes } = useStyles({
    selected: selected._id === elem._id,
    isPinned, isSearch
  });
  const name = elem.companyName || '';
  const typeData = smsTypes.find(e =>
    isSearch ? e.collection === elem.collection :
      e.type === smsType);
  const data = elem?.dataObject;
  const info = (data && typeData && genConversationInfo(elem, typeData)) || {};
  const { color, ...avatarProps } = stringAvatar(name, index);

  const handleClick = async (elem) => {
    await setSelected({ ...elem, color });
    if (isDown.md){
      setSmsType(null)
    }
  }

  return <>
    <ListItemButton
      autoFocus={selected._id === elem._id}
      onClick={() => {
        handleClick(elem)
      }}
      className={classes.listButton}
    >
      <ItemAvatar
        info={info}
        elem={elem}
        isSearch={isSearch}
        smsType={smsType}
        {...avatarProps}
      />
      <ItemDetails
        {...{
          elem, selected, user, typeData,
          isSearch, smsType, pinConversation, index,
          info, classes, isPinned
        }}
      />
    </ListItemButton>
    <Divider />
  </>;
};

export default compose(
  connect(mapStateToProps),
  withMediaQuery(),
  withDisplaying()
)(ContactItem);
