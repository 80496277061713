import React from 'react';

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { withStyles } from 'tss-react/mui';
import collections from '../../constants/collections';

const styles = {
  title: {
    marginBottom: 4,
    textTransform: 'uppercase',
    fontSize: '1rem',
    marginRight: 4
  },
};

const transformer = {
  [collections.interventions]: {
    name: 'Intervention',
    link: ({ id }) => `intervention/${id}`,
    linkText: ({ id }) => id
  },
  [collections.devis]: {
    name: 'Devis',
    link: ({ id }) => `devis/${id}`,
    linkText: ({ id }) => id
  },
  [collections.demandes]: {
    name: '',
    link: ({ _id }) => `/demandes/list/all?query="{"_id":"${_id}"}"`,
    linkText: () => 'Demande'
  },
};

const formatPathMessage = (elem) => {
  const data = transformer[elem.collection];
  return data && <>
    {data.name || ''}
    {data.name ? ' n° ' : ''}
    <a href={data.link(elem)}>{data.linkText(elem)}</a>
    {elem.duplicate ? ' - Dupliqué' : ''}
  </>;
};

const DisplayPath = ({ classes, path, from }) => (
  <React.Fragment>
    <Typography
      variant="h6"
      className={classes.title}
      children={`Parcours ${transformer[from].name}`}
    />
    {path.length ?
      <Breadcrumbs aria-label="breadcrumb">
        {path.map((e) => (
          <Typography key={e.id}>
            {formatPathMessage(e)}
          </Typography>
        ))}
      </Breadcrumbs> : ''
    }
  </React.Fragment>
);

export default withStyles(DisplayPath, styles);

