/**
 * remove data from local storage data array: savedInter/savedDevis
 * @param {String} index - array listIndex
 * @param {String} type - type of Data either inter or devis
 */
export const removeLStorageDataByIndex = (index, type) => {
  localStorage.removeItem(`tmpData${type}`);
  if (localStorage.getItem(`saved${type}`)) {
    const savedData = JSON.parse(localStorage.getItem(`saved${type}`));
    localStorage.setItem(
      `saved${type}`,
      JSON.stringify(savedData.filter(s => s.listIndex !== index))
    );
    localStorage.removeItem(`selected${type}`);
  }
};

/**
 * create array list of data by type and inserts tmp data in it
 * @param {String} type - type of Data either inter or devis
 */
export const getLStorageData = (type) => {
  const data = JSON.parse(localStorage.getItem(`tmpData${type}`));
  try {
    if (
      localStorage.getItem(`tmpData${type}`) &&
      Object.keys(data.client || {}).length &&
      !data._id
    ) {
      const lastTmp = JSON.parse(localStorage.getItem(`tmpData${type}`));
      lastTmp.listIndex = lastTmp.listIndex || +new Date();
      if (localStorage.getItem(`saved${type}`)) {
        const savedData = JSON.parse(localStorage.getItem(`saved${type}`));
        const index = savedData.findIndex(
          (e) => e.listIndex === lastTmp.listIndex
        );
        if (!~index) {
          if (savedData.length < 5) {
            localStorage.setItem(
              `saved${type}`,
              JSON.stringify([...new Set([...savedData]), lastTmp])
            );
            return localStorage.removeItem(`tmpData${type}`);
          }
          localStorage.setItem(
            `saved${type}`,
            JSON.stringify([...new Set([...savedData.slice(1)]), lastTmp])
          );
          return localStorage.removeItem(`tmpData${type}`);
        }
        savedData[index] = lastTmp;
        return localStorage.setItem(`saved${type}`, JSON.stringify(savedData));
      }
      localStorage.setItem(`saved${type}`, JSON.stringify([lastTmp]));
      return localStorage.removeItem(`tmpData${type}`);
    } else if (localStorage.getItem(`tmpData${type}`)) {
      return localStorage.removeItem(`tmpData${type}`);
    }
  } catch (error) {
    localStorage.removeItem(`tmpData${type}`);
    localStorage.removeItem(`tmpData${type}_new`);
    return localStorage.removeItem(`saved${type}`);
  }
};

/**
 * returns object corresponding to inter or devis taken
 * from local storage's list: savedInter/savedDevis
 * @param {String} index - array index => selectedInter/selectedDevis
 * @param {String} type - type of Data either inter or devis
 */
export const getLStorageDataByIndex = (index, type) => {
  if (localStorage.getItem(`saved${type}`)) {
    const savedData = JSON.parse(localStorage.getItem(`saved${type}`));
    return savedData[Number(index)];
  }
};

