import React, { Component } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import MenuItem from '@mui/material/MenuItem';

import {
  S_ART, S_INE, S_INT
} from '@lba-dev/package.local-globals/typesConstants';

import MinCustomDialog from '../MinCustomDialog';
import notifSystem from '../../../notifSystem';
import { getSignals } from '../../../actions/signals';
import AutoCompleteSearchBar from '../../Utils/AutoCompleteSearchBar';
import { connect } from 'react-redux';

const type = (signals, elem) => signals.toJS()
  .find(e => e.name === elem.name).type;

const mapStateToProps = ({ signals }, { elem }) => ({
  signals,
  elem
});

const TextInput = ({ disableUnderline = true,
  className, label, handleChange, rows }) =>
  <Input
    fullWidth
    className={className}
    placeholder={label}
    onContextMenu={(e) => {
      e.stopPropagation();
    }}
    disableUnderline={disableUnderline}
    onChange={e => handleChange(e.target.value)}
    multiline={rows !== 1}
    rows={rows}
  />;

const styles = {
  newSinglGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  card: {
    width: '100%',
    overflow: 'auto',
  }
};

class MailDialog extends Component {
  originalSignals = []
  signals = {}

  state = {
    text: '',
    description: '',
    name: '',
    interventionId: '',
    signals: type(this.props.signals, this.props.elem) === S_INT
      ? this.props.signals.toJS().filter(s => s.ref)
      : this.props.signals.toJS()
        .filter(s => type(this.props.signals, this.props.elem) === S_ART
          ? S_ART === s.type && s.ref
          : S_INE === s.type && s.ref)
  }

  actions = [
    {
      title: 'Résoudre',
      onClick: () => this.sendComment(true)
    },
    {
      title: 'Commenter',
      onClick: () => this.sendComment()
    },
    {
      title: 'Fermer',
      onClick: this.props.callbacks.close
    }
  ]

  componentDidMount() {
    getSignals()
      .then(signals => {
        this.originalSignals = signals;
        this.signals = signals.groupBy('subType');
        this.setState({ name: '' });
      })
      .catch(() => notifSystem.error(
        'Erreur',
        'Les signalements n\'ont pas pû être récupérés'
      ));
  }

  sendComment = resolve => {
    const { name, interventionId } = this.state;
    const text = this.state.text.trim();
    const description = this.state.description.trim();
    const { callbacks } = this.props;
    const signal = this.originalSignals.find(s => s.name === name);

    if (!signal) {
      return notifSystem.error(
        'Erreur',
        'Pas de signalement indiqué'
      );
    }
    if (!text) {
      return notifSystem.error(
        'Erreur',
        'Veuillez remplir le commentaire'
      );
    }
    if (!description) {
      return notifSystem.error(
        'Erreur',
        'Veuillez remplir la description'
      );
    }
    callbacks
      .comment({ text, description }, signal._id, resolve, interventionId)
      .then(() => {
        callbacks.close();
        notifSystem.success(
          'Signalement',
          'Le signalement a bien été émis'
        );
        if (typeof callbacks.reloadAll === 'function') {
          callbacks.reloadAll();
        }
      }).catch(e => notifSystem.error(
        'Signalement',
        e.response.data || e.message
      ));
  }

  generateOptionGroup = ([name, category]) => {
    const { elem } = this.props;
    const { interventionId } = this.state;
    const signalements = category.filter(s =>
      elem.osId
        || (interventionId && !isNaN(interventionId))
        || s.type === S_ART
    );

    return signalements.length ?
      [<MenuItem key={name} value='' disabled>
        {name}
      </MenuItem>,
      signalements.map((s, i) =>
        <MenuItem key={i} value={s.name}>
          <Typography>
            {s.name}
          </Typography>
        </MenuItem>
      )] : null;
  }

  handleChange = field => value =>
    this.setState({
      [field]: value
    })

  render() {
    const {
      elem,
      classes
    } = this.props;
    const {
      name,
      signals
    } = this.state;

    return <MinCustomDialog
      middle
      open
      title="Créer un signalemement"
      actions={
        this.actions
          .map(({ title, onClick }, i) =>
            <Button
              key={i}
              onClick={onClick || this.sendComment}
            >
              {title}
            </Button>
          )}
    >
      <Card className={classes.card}>
        <CardContent>
          <TextInput
            label="Commenter le signalement"
            handleChange={this.handleChange('text')}
            rows={10}
          />
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <AutoCompleteSearchBar
            placeholder="Rechercher un signalement"
            defaultValue={name}
            getSuggestions={(v) => new Promise((resolve) => {
              const array = [];

              if (v.length > 3) {
                let realSignals = signals.filter(s =>
                  !signals.some(si => si.ref === s.id));
                array.push(...realSignals.filter(s => ['name', 'path']
                  .some(key => s[key].toLowerCase()
                    .includes(v.toLowerCase()))));
              }

              resolve(array);
            }).then(a => a)}
            displaySuggestion={suggestion =>
              `${suggestion.name} ${suggestion.path}`}
            onValidate={suggestion =>
              this.handleChange('name')(suggestion.name)}
          />
          <div className={classes.newSinglGrid}>
            <Typography style={{ width: '100%' }} variant="body1">
              Emettre un nouveau signalement
            </Typography>
            <TextInput
              label={elem.osId || 'Lier à un OS'}
              handleChange={this.handleChange('interventionId')}
              rows={1}
              disableUnderline={false}
            />
          </div>
          <TextInput
            label="Description"
            handleChange={this.handleChange('description')}
            rows={10}
          />
        </CardContent>
      </Card>
    </MinCustomDialog>;
  }
}

export default connect(mapStateToProps)(withStyles(MailDialog, styles));


