import React from 'react';
import { columns } from './constants';
import { withStyles } from 'tss-react/mui';
import CustomMaterialTable from '../CustomMaterialTable';
import {
  PAN_VAL,
  PAN_REF
} from '@lba-dev/package.local-globals/paymentAnnexeStatus';
import { green, red } from '@mui/material/colors';

const styles = {
  table: {
    '&td': {
      backgroundColor: '#e67300',
    },
  },
};

const backgroundColors = (status) =>
  ({
    [PAN_VAL]: green[100],
    [PAN_REF]: red[100],
  }[status] || null);

const TransactionsTable = ({ options, classes, ...props }) => (
  <>
    <CustomMaterialTable
      columns={columns()}
      className={classes.table}
      {...props}
      options={{
        search: false,
        headerStyle: {
          borderColor: '#000',
        },
        rowStyle: (rowData) => ({
          backgroundColor: backgroundColors(rowData.status),
        }),
        ...options,
      }}
    />
  </>
);

export default withStyles(TransactionsTable, styles);
