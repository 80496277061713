import api from '../api';

import {
  SIGNALS_FETCH,
  SIGNALS_UPDATE
} from '../constants/ActionTypes';
import DbWsAction from './models/DbWsAction';

const action = new DbWsAction(
  SIGNALS_FETCH,
  SIGNALS_UPDATE
);

export const fetch = action.fetch;
export const update = action.update;

export const getSignals = filter =>
  api.signals.getAll(filter).then(res => res.body().map(e => e.data()));
