import React from 'react';
import { connect } from 'react-redux';
import CustomMaterialTable from '../CustomMaterialTable';

const mapStateToProps = ({
  dialog: {
    contentProps: {
      users,
      data
    },
  },
}) => ({
  users,
  data
});

const getPercent = (data, field) => {
  const fields = Object.keys(data[field]);
  const total = data[field][fields[0]] + data[field][fields[1]];
  const percent = (data[field][fields[0]] / total * 100).toFixed(2);
  return percent;
};

const getUserLogin = (data, users) => users
  .find(u => u._id === data._id)?.login || '';

const columns = (users) => [
  {
    title: 'Login',
    editable: 'never',
    render: (data) => data._id ? getUserLogin(data, users) : ''
  },
  {
    title: 'CA Perdu',
    editable: 'never',
    customSort: (a, b) => (b?.decoteCA?.CAPerdu || 0) - (
      a?.decoteCA?.CAPerdu || 0),
    render: (data) => `${
      data?.decoteCA?.CAPerdu || 0} (${getPercent(data, 'decoteCA')
    } %)`
  },
  {
    title: 'CA Potentiel',
    editable: 'never',
    customSort: (a, b) => (b?.decoteCA?.CAPotentiel || 0) - (
      a?.decoteCA?.CAPotentiel || 0),
    render: (data) => `${
      data?.decoteCA?.CAPotentiel || 0} (${(100 - getPercent(data, 'decoteCA'))
      .toFixed(2)} %)`
  },
  {
    title: 'I perdu',
    customSort: (a, b) => (b?.decoteI?.IPerdu || 0) - (
      a?.decoteI?.IPerdu || 0),
    editable: 'never',
    render: (data) => `${
      data?.decoteI?.IPerdu || 0} (${getPercent(data, 'decoteI')
    } %)`
  },
  {
    title: 'I potentiel',
    editable: 'never',
    customSort: (a, b) => (b?.decoteI?.IPotentiel || 0) - (
      a?.decoteI?.IPotentiel || 0),
    render: (data) => `${
      data?.decoteI?.IPotentiel || 0} (${(100 - getPercent(data, 'decoteI'))
      .toFixed(2)} %)`
  },
];

const DataUserDialog = ({ data, users }) => (
  <CustomMaterialTable
    columns={columns(users)}
    data={data}
    options={{
      toolbar: false,
      search: false,
      sorting: true
    }}
  />
);

export default connect(
  mapStateToProps)(DataUserDialog);
