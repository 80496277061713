import {
  CONFIG_FETCH
} from '../constants/ActionTypes';

export default (state = [], action) => {
  switch (action.type) {
    case CONFIG_FETCH: {
      return action.payload;
    }
    default:
      return state;
  }
};
