import React, { useEffect, useState } from 'react';


const ButtonTimeout = ({
  timeout,
  ButtonComp,
  onClick,
  children,
  ...props
}) => {

  const [disabled, setDisabled] = useState(false);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (timer !== null) {
      return () => {
        clearTimeout(timer);
      };
    }
  }, [timer]);

  useEffect(() => {
    if (disabled) {
      setTimeout(() => {
        setDisabled(false);
        setTimer(null);
        clearTimeout(timer);
      }, timeout * 1000);
    }
  }, [disabled]);

  const onClickTimeout = (e) => {
    e.preventDefault();
    setDisabled(true);

    setTimer(setTimeout(() => {
      setDisabled(false);
      setTimer(null);
    }, timeout * 1000));
    return onClick();
  };

  return (
    <ButtonComp
      onClick={onClickTimeout}
      disabled={disabled}
      {...props}>
      {children}
    </ButtonComp>
  );
};

export default ButtonTimeout;
