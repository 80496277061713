import React from 'react';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import { withStyles } from 'tss-react/mui';
import Calls from '../Calls/Calls';

const styles = {
  caption: { marginLeft: 50 }
};


const mapStateToProps = ({
  dialog: { contentProps: { lastCalls } },
  users,
  userId,
}) => ({
  lastCalls,
  user: users.find(u => u._id === userId)
});

const LastCallsDialog = ({ lastCalls }) => (
  <Grid container spacing={2}>
    {lastCalls.client
      ? <Calls
        sm={12}
        elem={lastCalls.client}
        title="CLIENT"
        keyUrl="ringover"
      />
      : ''}
    {lastCalls.artisan
      ? <Calls
        sm={12}
        elem={lastCalls.artisan}
        title="ARTISAN"
        keyUrl="lastCalls"
      />
      : ''}
  </Grid>
);

export default connect(mapStateToProps)(withStyles(LastCallsDialog, styles))
