class Csv {
  constructor(csv) {
    this.csv = typeof csv === 'string' ? Csv.parseCsv(csv) : csv;
  }
  toString() {
    let str = '';
    for (let i = 0; i < this.csv.length; i++) {
      if (i !== 0) {
        str += '\r\n';
      }
      for (let j = 0; j < this.csv[i].length; j++) {
        if (j !== 0) {
          str += ',';
        }
        str.replace('"', '""');
        str += this.csv[i][j].match(/,|"/)
          ? `"${this.csv[i][j]}"`
          : this.csv[i][j];
      }
    }
    return str;
  }
  toArray() {
    return this.csv;
  }
  toObjectList() {
    let objList = [];
    const names = this.getHeader();
    for (let i = 1; i < this.csv.length; i++) {
      let obj = {};
      for (let j = 0; j < this.csv[i].length; j++) {
        obj[names[j]] = this.csv[i][j];
      }
      objList.push(obj);
    }
    return objList;
  }
  getHeader() {
    return this.toArray().slice(0, 1)[0];
  }
  toArrayWithoutHeader() {
    return this.toArray().slice(1);
  }

  static parseCsv(CSV_string, delimiter) {
    delimiter = delimiter || ',';

    let pattern = new RegExp(
      `(\\${delimiter}|\\r?\\n|\\r|^)` +
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        `([^"\\${delimiter}\\r\\n]*))`,
      'gi'
    );

    let rows = [[]];
    let matches = false;
    while ((matches = pattern.exec(CSV_string))) {
      let matched_delimiter = matches[1];
      if (matched_delimiter.length && matched_delimiter !== delimiter) {
        rows.push([]);
      }
      let matched_value;
      if (matches[2]) {
        matched_value = matches[2].replace(new RegExp('""', 'g'), '"');
      } else {
        matched_value = matches[3];
      }
      rows[rows.length - 1].push(matched_value);
    }
    return rows;
  }
}

export default Csv;
