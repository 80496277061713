import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { moment } from '@lba-dev/package.local-globals/moment';
import classNames from 'classnames';
import { List } from 'immutable';
import { withStyles } from 'tss-react/mui';
import CardActions from '@mui/material/CardActions';
import Fab from '@mui/material/Fab';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  DeleteForever,
  Smartphone,
  Star,
  Call
} from '@mui/icons-material';
import { TEL_REGEX } from '@lba-dev/package.local-globals/regexps';
import red from '@mui/material/colors/red';
import { data as statusData } from '@lba-dev/package.local-globals/statusProsp';
import {
  P_TYPE_RECALL,
  P_TYPE_ABS
} from '@lba-dev/package.local-globals/prospRememberStatus';
import IconsDisplay from './IconsDisplay';

const styles = {
  card: {
    marginBottom: 5,
    '&:hover': {
      boxShadow: `1px 1px 2px 1px ${'#9E9E9E'}`
    },
    '&:focus': {
      boxShadow: `-1px 3px 5px 0px ${'#270909f7'}`,
      width: '99% !important',
      marginLeft: '1%'
    },
    '&:active': {
      boxShadow: `-1px 3px 5px 0px ${'#270909f7'}`,
      width: '99% !important',
      marginLeft: '1%'
    }
  },
  focused: {
    boxShadow: `-1px 3px 5px 0px ${'#270909f7'} !important`,
    width: '99% !important',
    marginLeft: '1%'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  button: {
    padding: '9px 7px !important',
    fontSize: '0.6em !important',
    background: '#008000ba'
  },
  counter: {
    padding: '12px',
    textAlign: 'center',
    display: 'flex',
    'justifyContent': 'space-between'
  },
  IconsContainer: {
    'display': 'flex',
    'justifyContent': 'space-between'
  }
};

class ListeItems extends PureComponent {
  state = {
    all: false,
    open: false
  }

  itemRefs = [];

  select = id => e => {
    let { selectedItems, updateState } = this.props;
    if (e.target.checked && !selectedItems.includes(id)) {
      selectedItems.push(id);
    } else {
      this.setState({ all: false });
      selectedItems.splice(selectedItems.indexOf(id), 1);
    }
    updateState('selectedItems', selectedItems);
  }

  selectAll = () => {
    const { all } = this.state;
    const { markerList, updateState } = this.props;
    const selectedItems = !all ?
      markerList.map(el => el.getIn(['obj', '_id'])).toJS() : [];
    updateState('selectedItems', selectedItems);
    this.setState({ all: !all });
  }

  scrollTo = id =>
    ~id && this.itemRefs[id].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest'
    });

  indispoExiste = (elem) => {
    const res = elem
      .getIn(['obj', 'reminders'], new List())
      .sortBy(r => r.getIn(['date', 'reminder']))
      .reverse()
      .find(e => {
        const type = e.get('type');

        return (type === P_TYPE_ABS || type === P_TYPE_RECALL)
          && moment(e.getIn(['date', 'reminder'])).diff(moment()) > 0;
      });
    return res
      ? moment(res.getIn(['date', 'reminder']))
        .format(`[${res.get('type') === P_TYPE_ABS
          ? 'Occupé jusqu\'au'
          : 'Rappel prévu le'
        }] DD/MM/YYYY [à] HH:mm`)
      : false;
  }

  callPhone = (event, elem, index) => {
    const { call, setTarget, merge, isCall } = this.props;
    const tels = elem.getIn(['obj', 'tels'], new List()).toJS();
    let phoneIndex = 0;
    tels.forEach((element, i) => {
      if ((TEL_REGEX).test(element)) {
        phoneIndex = i;
      }
    });
    if (merge.get('index') !== index) {
      setTarget(index, true);
    }
    if (merge.get('index') === index || !isCall) {
      call(elem, phoneIndex);
    }
  }

  render() {
    const { all } = this.state;
    const {
      classes,
      setTarget,
      markerList,
      merge,
      count,
      updateState,
      selectedItems,
      isAdmin,
      data,
      isInterSearch,
      filter,
    } = this.props;
    if (merge.get('index', undefined)) {
      this.scrollTo(merge.get('index'));
    }
    return (
      <div>
        <div className={classes.counter}>
          {isAdmin && <Fab
            color="inherit"
            size="small"
            onClick={() => updateState('openDelete', true)}
            className={classes.fabGreen}
            disabled={!selectedItems.length}
            children={<DeleteForever />}
          />}
          <div style={{ padding: '10px 0px' }}>
          ( {count} résultats )
          </div>
          {isAdmin && <Fab
            color="inherit"
            size="small"
            onClick={() => this.selectAll()}
            className={classes.fabGreen}
            children={all ? <CheckBox /> : <CheckBoxOutlineBlank />}
          />}
        </div>
        {markerList.map((elem, index) => {
          const unavailableSentence = this.indispoExiste(elem);

          let status = elem.getIn(['obj', 'status']);
          let dis = elem.get('dis');
          if (isInterSearch) {
            const inter = elem.getIn(['obj', 'inters'], new List())
              .find(e => e.get('id', 0) === +data.get('interSearch', 0)).toJS();
            if (inter) {
              status =
                (data.getIn(['filters', filter, 'dispoStatus']) &&
                inter.dispoStatus) || inter.status;
            }
            dis = inter.distance || dis;
          }
          const statusItem = statusData
            .find(e => e._id === status) || {};
          return (
            <div ref={el => (this.itemRefs[index] = el)}
              key={elem.getIn(['obj', 'value'])}>
              <Card
                className={classNames(classes.card, {
                  [classes.focused]: index === merge.get('index')
                })}
                style={{
                  background: elem.getIn(['obj', 'disable'], false)
                    ? red[100] : 'white'
                }}
              >
                <CardContent
                  onClick={() => setTarget(index)}
                >
                  <div className={classes.IconsContainer}>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      {dis.toFixed(2)} KM
                    </Typography>
                    {Object.keys(IconsDisplay)
                      .map((e, i) => IconsDisplay[e](elem, i))}
                    {isAdmin && (
                      <Checkbox
                        color="primary"
                        checked={selectedItems.includes(
                          elem.getIn(['obj', '_id'])
                        )}
                        onChange={this.select(elem.getIn(['obj', '_id']))}
                      />
                    )}
                  </div>
                  <Typography variant="h6">
                    {elem.getIn(['obj', 'title'])}
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <div>
                      <Notation elem={elem} />
                    </div>
                    <NbAvis elem={elem} />
                  </div>
                  <Typography className={classes.pos} color="textSecondary">
                    {elem.getIn(['obj', 'categorie']).join(', ')}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" variant="outlined"
                    style={{ color: statusItem.color ?
                      statusItem.color[500] : 'white' }}
                  >
                    {unavailableSentence || statusItem.name}
                  </Button>
                  {!isInterSearch ?
                    <Button
                      style={{
                        display:
                        elem
                          .getIn(['obj', 'tels'], new List())
                          .some(element => (TEL_REGEX).test(element)) ||
                        'none',
                      }}
                      size="small"
                      variant="contained"
                      color="secondary"
                    >
                      <Smartphone/>
                    </Button> :
                    <Button
                      size="small"
                      color="primary"
                      onClick={(event) => this.callPhone(event, elem, index)}
                    >
                      <Call/>
                    </Button>
                  }
                </CardActions>
              </Card>
            </div>
          );
        })}
      </div>
    );
  }
}

ListeItems.propTypes = {
  classes: PropTypes.object.isRequired,
  setTarget: PropTypes.func,
  call: PropTypes.func,
  merge: PropTypes.object.isRequired,
  markerList: PropTypes.object.isRequired,
  setData: PropTypes.func
};

const NbAvis = ({ elem }) => {
  if (elem.getIn(['obj', 'nbAvis'])) {
    const nbAvis = String(elem.getIn(['obj', 'nbAvis']));
    return <div style={{ fontSize: 18 }}>
      {`( ${nbAvis.replace('(', '').replace(')', '')} )`}
    </div >
  }
  return null;
};

const Notation = ({ elem }) =>
  Array(Array(parseInt((elem.getIn(['obj', 'note'], 0) || 0), 10)))
    .map((e, i) => <Star key={i} />);

export default withStyles(ListeItems, styles);
