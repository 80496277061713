const Money = {};

Money.getCentPart = (money) => (Math.abs(money % 100));
Money.getEuroPart = (money) => ((money - (Money.getCentPart(money))) / 100);
Money.toString = (money, getSymbol) => (
  Number.isNaN(money) ?
    `NaN${getSymbol ? ' €' : ''}` :
    `${money < 0 ? '-' : ''}` +
    `${Money.getEuroPart(Math.floor(money < 0 ? money * -1 : money))}` +
    `.${Money.getCentPart(Math.floor(money < 0 ? money * -1 : money))
      .toString().padStart(2, '0')}` +
    `${getSymbol ? ' €' : ''}`
);

export default Money;
