/**
 * Compare function based on language-sensitive string comparison
 * for sort usecase
 * @param {*} a value A to compare
 * @param {*} b value B to compare
 * @returns {number}
 * @description You can provide this to the sort method
 * @example
 * array.sort(sortedValues)
 * // or
 * array.sort((a,b) => sortedValues(a,b))
 */
export function sortedValues(a, b){
  return new Intl.Collator('fr',
    { sensitivity: 'base', numeric: true }).compare(a, b)
}
