import React, { PureComponent } from 'react';
import { ChevronRight } from '@mui/icons-material';
import { withStyles } from 'tss-react/mui';

const styles = {
  rowTransition: {
    transition: 'transform .2s'
  }
};

class CollapseArrow extends PureComponent {
  render() {
    const { open, classes } = this.props;
    return (
      <ChevronRight className={classes.rowTransition}
        style={{ transform: `rotate(${open ? 90 : 0}deg)` }}
      />
    );
  }
}

export default withStyles(CollapseArrow, styles);
