import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { Add, Smartphone, Call } from '@mui/icons-material';
import green from '@mui/material/colors/green';
import { List } from 'immutable';
import Switch from '@mui/material/Switch';
import AddressProsp from './AddressProsp';
import { TEL_REGEX } from '@lba-dev/package.local-globals/regexps';

const styles = theme => ({
  actions: {
    display: 'flex',
  },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  categories: {
    background: '#80808042',
    borderRadius: 5,
    width: '85%',
    margin: 'auto',
    align: 'center'
  },
  textField: {
    width: 180,
    marginRight: 10
  },
  bootstrapRoot: {
    padding: 0,
    'label + &': {
    }
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: 'white',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  },
  bootstrapFormLabel: {
    fontSize: 18
  },
  fabGreen: {
    width: '10%',
    minWidth: 0,
    color: theme.palette.common.white,
    backgroundColor: green[500],
  },
  divContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  divAdd: {
    width: '100%',
  },
  align: {
    'align-items': 'center'
  },
});

class InfoArtisan extends PureComponent {
  state = {
    newTel: ''
  }

  setData = path => event => {
    const { merge } = this.props;
    if (Array.isArray(path) && path.includes('tels')) {
      let tels = merge.getIn(['elem', 'obj', 'tels'], new List());
      tels = tels.set(path[path.length - 1], event.target.value);
      this.props.setData(['elem', 'obj', 'tels'], tels);
    } else {
      this.props.setData(path, event.target.value);
    }
  };

  disableTel = (index, addTel, removeTel) => {
    const { merge, setData } = this.props;
    let deletedTels = merge.getIn(['elem', 'obj', addTel], new List());
    deletedTels = deletedTels.push(
      merge.getIn(['elem', 'obj', removeTel, index]));
    setData(['elem', 'obj', removeTel],
      merge.getIn(['elem', 'obj', removeTel],
        new List()).filter((adresse, i) => i !== index ),
      () => setData(['elem', 'obj', addTel], deletedTels) );
  }

  addTel = () => {
    const { merge, setData } = this.props;
    const { newTel } = this.state;
    if (newTel !== '') {
      let tels = merge.getIn(['elem', 'obj', 'tels'], new List());
      tels = tels.push(newTel);
      this.setState({ newTel: '' },
        () => setData( ['elem', 'obj', 'tels'], tels));
    }
  }

  onChangeTel = event => {
    this.setState({ newTel: event.target.value });
  }


  handleChange = index => event => {
    this.disableTel(index, event.target.checked ?
      'tels' : 'deletedTels', event.target.checked ? 'deletedTels' : 'tels');
  };

  render() {
    const { classes, merge, callFunc, arrayTextField } = this.props;
    const { newTel } = this.state;
    const fields = arrayTextField();
    return (
      <CardContent className={classes.divContainer}>
        {fields.map((el, index) => (
          <div
            key={index}
            style={{ width: !isNaN(el.index) ? '100%' : el.width }}
          >
            <Grid key={index} container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  disabled={el.disabled}
                  style={{ width: el.width }}
                  placeholder={el.placeholder}
                  className={classes.textField}
                  value={
                    el.type === 'number'
                      ? String(merge.getIn(el.path, '') || '').replace(/ /g, '')
                      : merge.getIn(el.path, '') || ''
                  }
                  margin="normal"
                  type={el.type}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: classes.bootstrapRoot,
                      input: classes.bootstrapInput,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.bootstrapFormLabel,
                  }}
                  onChange={this.setData(el.path)} />
              </Grid>
              {!isNaN(el.index) && (
                <Grid item xs={2}>
                  <Switch
                    checked={el.checked}
                    onChange={this.handleChange(el.index)}
                    value={el.index}
                    color="primary"
                  />
                </Grid>
              )}
              {!isNaN(el.index) && el.checked &&
              (<Grid item xs={2}>
                <Tooltip disableInteractive title="Appeler ce numéro">
                  <Button
                    aria-label="Add"
                    mini
                    className={classes.removeButton}
                    onClick={() => callFunc(merge.get('elem'), el.index)}
                    color="primary"
                    children={<Call />}
                  />
                </Tooltip>
              </Grid>)}
              { !isNaN(el.index)
              && (TEL_REGEX).test(
                merge.getIn(['elem', 'obj', 'tels', el.index], ''))
              && ( <Grid item xs={2}><Button mini>
                <Smartphone color="secondary" style={{ fontSize: 20 }}/>
              </Button></Grid>)}
            </Grid>
          </div>
        ))
        }
        {fields.some(e => e.path.includes('tels')) &&
          <div className={classes.divAdd}>
            <TextField
              variant="standard"
              style={{ width: '85%' }}
              placeholder={'nouveau numéro ...'}
              className={classes.textField}
              value={newTel}
              margin="normal"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.bootstrapRoot,
                  input: classes.bootstrapInput,
                },
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.bootstrapFormLabel,
              }}
              onChange={this.onChangeTel} />
            <Tooltip disableInteractive title="Ajouter un numéro">
              <Fab
                color="inherit"
                size="small"
                onClick={() => this.addTel()}
                className={classes.fabGreen}
                children={<Add />}
              />
            </Tooltip>
          </div>}
        <AddressProsp
          merge={merge}
          setData={this.props.setData}
          path={['elem', 'obj', 'address']}
        />
      </CardContent>
    );
  }
}

export default withStyles(InfoArtisan, styles);
