import api from '../../../api';
import notifSystem from '../../../notifSystem';
import { Csv } from '../../../utils/Csv';
import {
  data as respType
} from '@lba-dev/package.local-globals/responseTypeMSteps';
import { MSQ_DET } from '@lba-dev/package.local-globals/questionTypeMSteps';
import { genRandomId } from '../../../utils/number';

export const getQuestions = () =>
  api.questions.getAll()
    .then((data) => data.body().map((e) => e.data()))
    .catch((e) => notifSystem.error('Erreur', e.message));

export const addQuestion = (data) =>
  api.questions.post(data)
    .then((data) => data.body().map((e) => e.data()))
    .catch((e) => notifSystem.error('Erreur', e.message));

export const deleteQuestion = (questionId) =>
  api.questions.delete(questionId)
    .then(() =>
      notifSystem.success('Opération réussie',
        'La question a bien été supprimée')
    )
    .catch(() =>
      notifSystem.error('Erreur system', 'La question n\'a pas été supprimée')
    );

export const changedData = (stateData, original) =>
  stateData.reduce((acc, curr, i) =>
    JSON.stringify(curr) !== JSON.stringify(original[i])
      ? acc.concat(curr)
      : acc
  , []);

const keys = {
  'Catégorie': 'cat',
  'Type': 'type',
  'Ref Question': 'ref',
  'Question': 'question',
  'Réponse': 'response',
  'Type de choix': 'multipleChoice',
  'Sélection': 'responseType',
  'Tag': 'tag',
  'Ref Tag': 'refTag',
  'Trier A-Z': 'sorted',
};

const convertKeys = (array, newNames) => {
  const compare = Object.keys(keys);
  const arr = array.reduce((acc, curr) => {
    let formatedObj = compare
      .reduce((a, c) => ({ ...a, [newNames[c]]: curr[c] }), {});
    if (formatedObj.ref) {
      return acc.concat(formatedObj);
    }
    else if (formatedObj.type === 'Question') {
      formatedObj = { ...formatedObj, ref: genRandomId('Q') };
      return acc.concat(formatedObj);
    }
    else if (formatedObj.type === 'Réponse') {
      const question = acc[acc.length - 1];
      formatedObj = { ...formatedObj, ref: question.ref };
      return acc.concat(formatedObj);
    }
    return acc;
  }, []);

  return arr;
};

const formatQuestion = (arr, oldQuestion) => {
  const questionData = {};
  const q = arr.find(e => e.type === 'Question');
  const responses = arr
    .filter(e => e.type === 'Réponse')
    .map((e, i) => ({
      ...(oldQuestion ? oldQuestion.responses[i] : {}),
      response: e.response,
      responseType: respType?.find(r => r.importText === e.responseType)?._id,
      tag: e.tag === 'Oui',
      refTag: e.refTag ? e.refTag : genRandomId('T')
    }));
  if (q) {
    questionData.question = q.question;
    questionData.cat = q.cat;
    questionData.multipleChoice = q.multipleChoice === 'Choix multiple';
    questionData.sorted = q.sorted === 'Oui';
    questionData.questionType = MSQ_DET;
    questionData.ref = q.ref;
    questionData.responses = responses;
    return questionData;
  }
};

export const addCsv = (f, dataArray, setData) => {
  const file = f.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = e => {
      if (e.target.readyState === FileReader.DONE && e.target.result) {
        const res = new Csv(e.target.result);
        let objList = res.toObjectList();
        objList = convertKeys(objList, keys);
        const groupBy = objList.groupBy('ref');
        let data = dataArray;
        Object.values(groupBy).forEach(arr => {
          const qData = formatQuestion(arr);
          if (qData) {
            if (data.some(e => e.ref && e.ref === qData.ref)) {
              const oldQuestion = data.find(e => e.ref === qData.ref);
              const newQuestion = formatQuestion(arr, oldQuestion);
              const i = data.findIndex(e => e.ref === qData.ref);
              data = [
                ...data.slice(0, i),
                { ...data[i], ...newQuestion },
                ...data.slice(i + 1),
              ];
            } else {
              data = data.concat(qData);
            }
          }
        });
        setData(data);
      }
    };
    reader.readAsText(file);
    f.target.value = '';
  }
};
