import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function useIsUp() {
  const theme = useTheme();
  return ['xs', 'sm', 'md', 'lg', 'xl']
    .reduce((acc, curr) => ({
      ...acc,
      [curr]: useMediaQuery(theme.breakpoints.up(curr))
    }), {});
}

function useIsDown() {
  const theme = useTheme();
  return ['xs', 'sm', 'md', 'lg', 'xl']
    .reduce((acc, curr) => ({
      ...acc,
      [curr]: useMediaQuery(theme.breakpoints.down(curr))
    }), {});
}

function useBreakpoint() {
  switch (true) {
    case window.screen.width < 1920 && window.screen.width >= 1280:
      return 'lg';
    case window.screen.width < 1280 && window.screen.width >= 960:
      return 'md';
    case window.screen.width < 960 && window.screen.width >= 600:
      return 'sm';
    case window.screen.width < 600 && window.screen.width >= 0:
      return 'xs';
    default:
      return 'xl';
  }
}

function useIsWidthBetween(start, end) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between(start, end));
}

const withMediaQuery = (params = {}) => WrappedComponent => props => {
  const parameter = { start: 'xs', end: 'lg', ...params };
  const isUp = useIsUp();
  const isDown = useIsDown();
  const breakpoint = useBreakpoint();
  const isBetween = useIsWidthBetween(parameter.start, parameter.end);
  return (
    <WrappedComponent {...props}
      isUp={isUp}
      isDown={isDown}
      isBetween={isBetween}
      breakpoint={breakpoint} />
  );
};
export default withMediaQuery;
