import React, { Component } from 'react';
import { compose } from 'redux';
import { moment } from '@lba-dev/package.local-globals/moment';
import { withStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {
  PROPRIETAIRE,
  AUTRE,
  data as statusClient,
} from '@lba-dev/package.local-globals/statusClient';
import { data as iStatus } from '@lba-dev/package.local-globals/iStatus';
import api from '../../../api';
import menuContainer from './interMenuContainer';
import InterMenuInfo from './InterMenuInfo';
import RecouvrementInfo from '../Recouvrement/RecouvrementInfo';
import constants from '../../../constants/collections';
import PropositionMenu from '../Proposition/PropositionMenu';
import MenuHeader from '../Proposition/MenuHeader';
import DisplayPath from '../DisplayPath';
import { openPreview } from '../../../actions/general';
import CommentsList from '../../Comments/CommentsList';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import DemarchageHistory from '../../Menus/DemarchageHistory';
import DisplayInfos from '../../Dialogs/PmarInfosDialog/DisplayInfos';
import { getPrice } from '@lba-dev/package.local-globals/formatPrice';
import { C_SAV } from '@lba-dev/package.local-globals/commentTypes';
import HistoryTimeLine from '../../InterHistory/HistoryTimeLine';
import { CB } from '@lba-dev/package.local-globals/paymentMethods';
import { formatHistory } from '../../Utils/FormatHistory';
import withRouter from '../../../hoc/withRouter';
import CallToAndBack from '../../Calls/CallToAndBack';
import Calls from '../../Calls/Calls';
import CallLogInter from '../../Calls/CallLogInter';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import { getClientNumbers } from '../../../utils/call';

const styles = {
  padding: {
    padding: '12px',
  },
  lineHeight: {
    lineHeight: 'normal',
    overflowWrap: 'break-word',
  },
  title: {
    marginBottom: 4,
    textTransform: 'uppercase',
    fontSize: '1rem',
  },
  a: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  timeLine: {
    margin: 0,
    padding: '6px 0px',
  },
  timeLineLimit: {
    maxHeight: 300,
    overflow: 'auto',
  },
};

const mapStateToProps = ({
  users,
  userId,
}) => ({
  userId,
  users,
  user: users.find(e => e._id === userId)
});

const CommentMenuInter = ({ comments, classes, users, user }) => (
  <Grid item xs={12} md={4} >
    {[
      <Typography
        variant="h6"
        className={classes.title}
        key="commentaire"
      >
        {'Commentaires'}
      </Typography>,
      <CommentsList
        key={1}
        users={users}
        userId={user._id}
        comments={fromJS(comments)}
        hideInput
        customPadding='5px 0px'
        formatComments={true}
      />,
    ]}
  </Grid>
);

const statusClientName = (id) =>
  (statusClient.find((e) => e._id === id) || {}).name;

class InterMenu extends Component {
  state = {
    open: false,
    data: '',
    signalements: this.props.signalements,
  };

  getDevis = async (id) => {
    const res = await api.devis.get(id);
    this.setState(
      { data: res.body().data() },
      () => openPreview({
        method: 'completeFac',
        info: { type: 'facture', noCondition: true },
        data: { devis: this.state.data }
      }, `Devis n°${id || ''}`)
    );
  }

  setSignalements = (index, value) => {
    const { signalements } = this.state;
    const { signalements: nexSignalement } = formatHistory({
      signalements: value,
    });
    signalements[index] = nexSignalement[0];
    this.setState({
      signalements
    });
  };

  render() {
    const {
      elem = {},
      appels = [],
      records = [],
      sms = [],
      users,
      userId,
      classes,
      params,
      showProposition,
      recouvrement,
      setProposition
    } = this.props;
    const { signalements } = this.state;
    const user = users.find(e => e._id === userId);
    const { client, billing } = elem;
    const rdv = new Date(elem.date.intervention);
    const statusColor = (iStatus.find((e) => e._id === elem.status) || {})
      .color;
    const isProposition =
      showProposition ||
      (params && (params.filter || '').includes('proposition'));
    const listenName = (this.props.listen || '').toFirstUpperCase();
    return (
      <React.Fragment>
        {isProposition && [
          <PropositionMenu
            key={1}
            elem={elem}
            setProposition={setProposition}
          />,
          <MenuHeader
            key={2}
            statusColor={grey[500]}
            customTitle="information sur l'intervention"
          />,
        ]}
        <Grid key="interMenu" container spacing={4} className={classes.padding}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className={classes.title}>
              Coordonnées client
            </Typography>
            <Typography variant="body1">
              {client.civility}{' '}
              {client.civility === 'Société'
                ? client.society ||
                  `${client.name || ''} ${client.firstname || ''}`
                : `${client.name || ''} ${client.firstname || ''}`}
            </Typography>
            <Typography variant="body1">
              {client.address.number} {client.address.road}
            </Typography>
            <Typography variant="body1" style={{ marginBottom: 3 }}>
              {client.address.zipcode} {client.address.city}
            </Typography>
            <Typography variant="body1">
              <b>Description:</b>
            </Typography>
            <Typography variant="body1">{elem.infoDesc.desc}</Typography>
            {elem.infoDesc.context && (
              <Typography variant="body1">
                <b>Context:</b> {elem.infoDesc.context}
              </Typography>
            )}
            {elem.infoDesc.consignes && (
              <Typography variant="body1">
                <b>Consignes:</b> {elem.infoDesc.consignes}
              </Typography>
            )}
            {elem.infoDesc.toBeSelledOnSite && (
              <Typography variant="body1">
                <b>À vendre sur place:</b> <br /> {
                  elem.infoDesc.toBeSelledOnSite.split('\n').map(e =>
                    [e, <br key={'br'} />]
                  )}
              </Typography>
            )}
            <Typography variant="body1">
              <b>Prix annoncé: {
                `${getPrice(elem)}`}</b>
              {`${
                elem.paymentMethod === CB
                  ? '(Règlement par CB)'
                  : (elem.onSitePayment && '(Règlement sur place)') ||
                    '(Règlement par facture)'
              }`}
            </Typography>
            <Typography
              variant="body1"
              style={{
                color: statusColor ? statusColor[500] : null,
              }}
            >
              Rendez-vous prévu le {moment(rdv).format('LLL')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            {elem.date &&
              elem.login && listenName && [
              <Typography key={5} variant="h6" className={classes.title}>
                  Historique
              </Typography>,
              <HistoryTimeLine
                key={6}
                elem={elem}
                appels={appels}
                records={records}
                sms={sms}
                name={listenName}
              />,
            ]}
          </Grid>
          {elem.comments && elem.comments.length > 0 && (
            <CommentMenuInter
              comments={
                isNaN(elem.id)
                  ? elem.comments.filter((c) => c.type === C_SAV)
                  : elem.comments
              }
              classes={classes}
              users={users}
              user={user}
            />
          )}
          {!elem.onSitePayment && (
            <Grid item xs={12} md={4}>
              {billing &&
                billing.name && [
                <Typography key={1} variant="h6" className={classes.title}>
                    Facturation
                </Typography>,
                <Typography
                  key={2}
                  variant="body1"
                  className={classes.lineHeight}
                >
                  {billing.clientStatus &&
                    ![PROPRIETAIRE, AUTRE].includes(billing.clientStatus) &&
                    billing.companyName
                    ? `${statusClientName(billing.clientStatus)} ${
                      billing.companyName
                    }`
                    : `${billing.civility || ''} ${billing.name || ''} ${
                      billing.firstname || ''
                    }`}
                </Typography>,
                <Typography
                  key={3}
                  variant="body1"
                  className={classes.lineHeight}
                >
                  {billing.address ? billing.address.number : ''} &nbsp;
                  {billing.address ? billing.address.road : ''} &nbsp;
                </Typography>,
                <Typography
                  key={4}
                  variant="body1"
                  className={classes.lineHeight}
                >
                  {billing.address ? billing.address.zipcode : ''} &nbsp;
                  {billing.address ? billing.address.city : ''} &nbsp;
                </Typography>,
              ]}
            </Grid>
          )}
          {elem.communcations && (
            <CallToAndBack
              elem={elem.communcations}
              keyUrl="urlEnregistrement"
              inter={elem}
              classes={classes}
            />
          )}
          {!!appels.length && appels && (
            <Calls
              elem={appels}
              title="Appels vers l'artisan"
              keyUrl="url"
            />
          )}
          {recouvrement && (
            <RecouvrementInfo
              elem={elem}
              recouvrement={recouvrement}
              classes={classes}
            />
          )}
          {permit(user, { key: 'accesExterne' }) &&
            <CallLogInter
              keyUrl="inter"
              numbers={getClientNumbers(elem)}
            />}
          {!!signalements.length && (
            <Grid item xs={12} md={4}>
              <Typography key={5} variant="h6" className={classes.title}>
                Signalements
              </Typography>
              <div className={classes.timeLineLimit}>
                {signalements.map((data, i) => (
                  <InterMenuInfo
                    {...data}
                    key={i}
                    showComments
                    setSignalements={(v) => this.setSignalements(i, v)}
                  />
                ))}
              </div>
            </Grid>
          )}
          {elem.demarchageInfos && (
            <Grid item xs={12} md={4}>
              <Typography key={5} variant="h6" className={classes.title}>
                Consignes démarchage
              </Typography>
              <DisplayInfos
                shift={elem.demarchageInfos.shift}
                budget={elem.demarchageInfos.budget}
                withSupplies={elem.demarchageInfos.withSupplies}
                description={elem.demarchageInfos.description}
              />
            </Grid>
          )}
          {elem.createdFrom && elem.createdFrom.path ? (
            <Grid
              item
              xs={12}
              md={4}
              children={
                <DisplayPath
                  from={constants.interventions}
                  path={elem.createdFrom.path}
                />
              }
            />
          ) : null}
          <Grid item xs={12} md={4}>
            {location.pathname.includes('demarchMarket') &&
              <DemarchageHistory id={elem.id} />}
          </Grid>

        </Grid>
      </React.Fragment>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  menuContainer,
)(withStyles(InterMenu, styles));
