import api from './api';
import { loadUsers, updateUser } from './actions/users';
import { fetch as fetchFournisseurs } from './actions/fournisseurs';
import { loadCounter } from './actions/counter';
import { loadUserId } from './actions/userId';
import { loadEquipes } from './actions/equipes';
import { loadPoints } from './actions/points';
import {
  fetch as fetchNotifications,
  removeNotifications
} from './actions/notifications';
import { fetch as fetchProducts } from './actions/products';
import { fetch as fetchGrandComptes } from './actions/grandComptes';
import { fetch as fetchCombos } from './actions/combos';
import { fetch as fetchAnnulations } from './actions/annulations';
import { fetch as fetcConfig } from './actions/config';
import { fetch as fetchMetiersSteps } from './actions/metiersSteps';
import { fetch as fetchMetiersDevis } from './actions/metiersDevis';
import { fetch as fetchQuestions } from './actions/questions';
import { fetch as fetchSignals } from './actions/signals';
import { fetch as fetchConfCand } from './actions/confCand';
import { fetch as fetchConfProsp } from './actions/confProsp';
import { fetch as fetchRaisonsArchiveSst } from './actions/raisonsArchiveSst';
import { List, fromJS } from 'immutable';
import store from './store';

import { permit } from '@lba-dev/package.local-globals/restrictions';
import { S_INT, S_LEAD } from '@lba-dev/package.local-globals/services';

import { openChatPanel } from './actions/chatPanel';

const getUser = (users, userId) => users.find((e) => e._id === userId);

const initUsers = (store, socket) => {
  socket.on('update_users', (data) => {
    api.users.get(data).then((res) => {
      const user = res.body().data();
      store.dispatch(updateUser(user));
    });
  });
  return api.users.getAll({}).then((res) => {
    const users = res.body().map((e) => e.data());
    store.dispatch(loadUsers(users));
    return new List(users);
  });
};

const initUserId = (userId) => {
  store.dispatch(loadUserId(userId));
};

const initCount = (store) => {
  api.counter.get('').then((res) => {
    store.dispatch(loadCounter(res.body().data()));
  });
};

export const initPoints = (store, socket, user) => {
  if ([S_INT, S_LEAD].includes(user.service)) {
    api.points.getAll().then((res) => {
      store.dispatch(loadPoints(res.body().map((e) => e.data())));
    });
  }
};

const initCombos = (store, user) => {
  if (permit(user, { key: 'retrieveCombinations' })) {
    const update = () => {
      api.combos.getAll({}).then((res) => {
        const combos = fromJS(res.body().map((e) => e.data()));
        store.dispatch(fetchCombos(combos));
      });
    };
    update();
  }
};

const initAnnulations = (store, socket, user) => {
  if (permit(user, { key: 'retrieveAnnulation' })) {
    const update = (data) => {
      store.dispatch(fetchAnnulations(data));
    };
    socket.on('annulations', update);
    api.annulations.getAll({}).then((res) => {
      update(res.body().map((e) => e.data()));
    });
  }
};

const initProducts = (store) => {
  const update = () => {
    api.products.getAll({}).then((res) => {
      const data = res.body().map((e) => e.data());
      store.dispatch(fetchProducts(fromJS(data)));
    });
  };
  update();
};


const initGrandCompte = (store, socket, user) => {
  if (permit(user, { key: 'retrieveGrandCompte' })) {
    const update = (data) => {
      const grandComptes = fromJS(data);
      store.dispatch(fetchGrandComptes(grandComptes));
    };
    socket.on('grandCompte', update);
    api.grandCompte.getAll({}).then((res) => {
      const data = res.body().map((e) => e.data());
      update(data);
    });
  }
};

const initEquipes = (store, user) => {
  if (permit(user, { key: 'retrieveEquipe' })) {
    api
      .one('equipes', '')
      .get()
      .then((res) => {
        store.dispatch(loadEquipes(res.body().data()));
      });
  }
};

const initSignals = (store) => {
  const update = (data) => {
    const signals = fromJS(data);
    store.dispatch(fetchSignals(signals));
  };
  api.signals.getAll({}).then((res) => {
    const data = res.body().map((e) => e.data());
    update(data);
  });
};

const initRaisonsArchiveSst = (store) => {
  const update = (data) => {
    const raisonsArchiveSst = fromJS(data);
    store.dispatch(fetchRaisonsArchiveSst(raisonsArchiveSst));
  };
  api.raisonsArchiveSst.getAll({}).then((res) => {
    const data = res.body().map((e) => e.data());
    update(data);
  });
};

const initFournisseurs = (store) => {
  const update = (data) => {
    store.dispatch(fetchFournisseurs(data));
  };
  api.configFournisseurs.getAll({}).then((res) => {
    const data = res.body().map((e) => e.data());
    update(data);
  });
};

const initConfCand = (store) => {
  const update = (data) => {
    const confCand = fromJS(data);
    store.dispatch(fetchConfCand(confCand));
  };
  api.confCand.getAll({}).then((res) => {
    const data = res.body().map((e) => e.data());
    update(data);
  });
};

const initConfProsp = (store) => {
  const update = (data) => {
    const confProsp = fromJS(data);
    store.dispatch(fetchConfProsp(confProsp));
  };
  api.confProsp.getAll({}).then((res) => {
    const data = res.body().map((e) => e.data());
    update(data);
  });
};

const initConfig = (store, socket) => {
  const update = (data) => {
    store.dispatch(fetcConfig(data));
  };
  socket.on('config', update);
  api.config.getAll({}).then((res) => {
    update(res.body().map((e) => e.data()));
  });
};

const initSMetiersSteps = (store) => {
  const update = () => {
    api.metiersSteps.getAll({
      query: JSON.stringify({ ref: null })
    }).then((res) => {
      store.dispatch(fetchMetiersSteps(res.body().map((e) => e.data())));
    });
  };
  update();
};

const initSMetiersDevis = (store) => {
  const update = () => {
    api.metiersDevis.getAll({
      query: JSON.stringify({ ref: null })
    }).then((res) => {
      store.dispatch(fetchMetiersDevis(res.body().map((e) => e.data())));
    });
  };
  update();
};

const initQuestions = (store) => {
  const update = () => {
    api.questions.getAll({}).then((res) => {
      store.dispatch(fetchQuestions(res.body().map((e) => e.data())));
    });
  };
  update();
};
/*
 **  Initializes notifications in store and
 **  waits for notifications update from socket
 */

const initNotifications = (store, userId, ws) => {
  const update = (notification) => {
    if (notification && notification.type === 'sms') {
      const type =
        (notification.artisan && 'artisan') ||
        ((notification.devis || notification.intervention) && 'intervention');
      if (type) {
        store.dispatch(openChatPanel(null, type));
      }
    } else {
      notification.socket = true;
      store.dispatch(fetchNotifications(notification));
    }

  };

  const deleteNotif = ({ notificationDeleted }) => {
    const notifications = new List(
      store.getState().notifications.filter((e) =>
        !notificationDeleted.includes(e._id))
    );
    store.dispatch(removeNotifications(notifications));
  };

  ws.on(`notifications ${userId}`, update);
  ws.on(`delete_notifications ${userId}`, deleteNotif);

  api
    .all(`notifications/${userId}/getUser`)
    .getAll({})
    .then((res) => store.dispatch(
      fetchNotifications(new List(res.body().map((e) => e.data())))
    ));
};

export const setCount = (key, value) => {
  let counter = store.getState().counter;
  counter[key] = value;
  store.dispatch(loadCounter({ ...counter }));
};

export default function initCache(store, userId, ws, isChat) {
  initUserId(userId);
  initUsers(store, ws).then((users) => {
    const user = getUser(users, userId);
    initNotifications(store, userId, ws);
    if (isChat) {
      if (user) {
        initSignals(store, ws, user);
      }
    } else {
      if (user) {
        initCombos(store, ws, user);
        initGrandCompte(store, ws, user);
        initSignals(store, ws, user);
        initConfCand(store, ws, user);
        initConfProsp(store, ws, user);
        initAnnulations(store, ws, user);
        initEquipes(store, user);
        initFournisseurs(store, user);
        initPoints(store, ws, user);
        initSMetiersSteps(store, ws, user);
        initSMetiersDevis(store);
        initQuestions(store);
      }
      initCount(store);
      initProducts(store, ws);
      initConfig(store, ws);
      initRaisonsArchiveSst(store);
    }
  });
}
