import React from 'react';
import MoneyInput from '../../CustomInputs/MoneyInput';

export default ({
  classes,
  priseEnChargeMontant,
  label,
  setData,
  disabled,
  path,
}) => (
  <MoneyInput
    className={classes.chip}
    label={label}
    defaultValue={(+priseEnChargeMontant || 0) * 100}
    path={path}
    type={'number'}
    customTimeOut={0}
    setData={(p, v) => setData(p, (v / 100).toFixed(2))}
    disabled={disabled}
  />
);
