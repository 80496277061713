import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

import home from '@public/home.jpg';
import lesbonsartisansdef from '@public/lesbonsartisansdef.png';


import { login as loginAction } from '../actions/auth';
import Login from '../components/Login';
import { withMediaQuery } from '../hoc';
import withRouter from '../hoc/withRouter';
import LoginResetPassword from '../components/LoginResetPassword';

const styles = {
  main: {
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    zIndex: 300,
    transition: 'opacity 0.5s',
  },
  center: {
    margin: 'auto',
  },
  img: {
    width: '50%',
    height: '100%',
    'background-image': `url("${home}")`,
    'background-size': 'cover',
    'background-repeat': 'non-repeat',
    'background-position': 'right',
  },
  logo: {
    position: 'fixed',
    maxWidth: 300,
    top: 25,
    left: 50,
  }
};

function LoginView({ classes, isUp }) {
  const [state, setState] = useState({
    forgotPassword: false,
    password: '',
    resetPasswordDisabled: false,
  });


  const forgetPasswordMode = () => {
    setState({ ...state, forgotPassword: !state?.forgotPassword });
  };

  const resetPasswordTimer = () => {
    setState({ ...state, resetPasswordDisabled: true });
    setTimeout(() =>
      setState({ ...state, resetPasswordDisabled: false }), 60000);
  }


  return (
    <Grid container className={classes.main}>
      <img
        src={lesbonsartisansdef}
        alt="logo"
        className={classes.logo}
        width={'100%'}
      />
      <Grid className={classes.center} item xs={12} md={12} lg={5}>
        {state.forgotPassword
          ? <LoginResetPassword
            forgetPasswordMode={forgetPasswordMode}
            resetPasswordTimer={resetPasswordTimer}
            resetPasswordDisabled={state.resetPasswordDisabled}
            classes={classes}
          />
          :
          <Login
            classes={classes}
            forgetPasswordMode={forgetPasswordMode}
          />}
      </Grid>
      {isUp.md ?
        <div src={home} alt="logo" className={classes.img} /> : ''}
    </Grid>

  );
}


const mapDispatchToProps = dispatch => ({
  login: (token, isRemotely) => dispatch(loginAction(token, isRemotely)),
});

export default compose(
  withMediaQuery(),
  connect(null, mapDispatchToProps),
  withRouter,
)(withStyles( LoginView, styles));
