import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/system';
import { Tab, Tabs } from '@mui/material';

import InterList from '../List/Intervention/InterList';
import { List as listData } from '../../utils/List/ListeInterventions';
import MapList from '../List/Map/Map';
import collections from '../../constants/collections';


const mapStateToProps = ({
  users,
  userId,
  dialog: {
    contentProps: {
      filter,
      service,
      sort = { id: -1 },
      count = 10,
      handlerFilterByColumns,
      filteredByColumn = false,
      displayName = 'default'
    },
  },
}) => ({
  filter,
  service,
  sort,
  handlerFilterByColumns,
  filteredByColumn,
  count,
  displayName,
  users,
  user: users.find(s => s._id === userId)
});


const getListAttribute = ({
  displayName,
  user,
  users,
}) => {
  const list = listData({ user, users });
  return list[displayName]['dataFormat'];
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

const InterListWithMapDialog = ({
  filter,
  service,
  sort,
  count,
  handlerFilterByColumns,
  filteredByColumn,
  displayName,
  user,
  users
}) => {
  const [tab, setTab] = useState(0);
  const handleTabChange = (e, newValue) => {
    setTab(newValue);
  };
  return <Box>
    <Tabs value={tab} onChange={handleTabChange} centered>
      <Tab value={0} label="Map" />
      <Tab value={1} label="Liste" />
    </Tabs>
    <TabPanel value={tab} index={1}>
      <InterList
        small
        filter={filter}
        count={count}
        filteredByColumn={filteredByColumn}
        service={service}
        sort={sort}
        handlerFilterByColumns={handlerFilterByColumns}
        list={getListAttribute({ displayName, user, users })}
      />
    </TabPanel>
    <TabPanel value={tab} index={0}>
      <MapList
        filter={filter}
        hideFilter
        collection={collections.interventions}
      />
    </TabPanel>
  </Box>;
};

export default connect(mapStateToProps)(InterListWithMapDialog);
