import {
  CALL_PREPARE_ADD_CITY,
  callPrepareGetCities
} from '../actions/etatReseau';
import notifSystem from '../notifSystem';
import api from '../api';

const addCity = (store) => next => async (action) => {
  switch (action.type) {
    case CALL_PREPARE_ADD_CITY: {
      const dataCity = store.getState().etatReseau.addresstoInput;
      if (Object.keys(dataCity.city).length || dataCity.city !== 'undefined' ) {
        try {
          const { number, city, zipcode, lnglat } = dataCity.city;
          await api.all('cities')
            .post({
              department: number,
              city,
              zipcode,
              distance: dataCity.distance,
              location: lnglat
            });
          notifSystem.success('Opération réussie',
            'Votre ville a été enregistrée');
          await store.dispatch(callPrepareGetCities());
        } catch (e) {
          notifSystem.error(e.name, e.message);
        }
      } else {
        notifSystem.error('Formulaire incomplet',
          'Vous devez remplir tous les champs');
      }
    }
  }
  next(action);
};

export default addCity;
