import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';


const TextFormat = ({
  classes,
  data: { title = '', text = '', smallText = '' } = {},
  options: { xs, sm, md } = { md: 6, xs: 12, sm: 12 },
  childrens,
}) => (
  <Grid item xs={xs} sm={sm} md={md}>
    {title ?
      <Typography
        variant="subtitle1"
        className={classes.keys}
        children={title}
      /> : ''}
    {text && (
      <Typography
        variant="button"
        align="center"
        children={[
          text,
          ' ',
          smallText && <Typography variant="caption" children={smallText} />,
        ]}
      />
    )}
    {childrens}
  </Grid>
);

export default classes => (e, i) =>
  e && <TextFormat classes={classes} key={i} {...e} />;
