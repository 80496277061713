import React from 'react';

import { connect } from 'react-redux';
import Box from '@mui/material/Box';

const mapStateToProps = ({
  dialog: {
    contentProps: {
      content,
      boxProps
    },
  },
}) => ({
  content,
  boxProps
});

const CustomDialog = ({ content, boxProps }) =>
  <Box {...boxProps}>
    {content}
  </Box>;

export default connect(mapStateToProps)(CustomDialog);
