import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import CustomTextField from '../CustomInputs/CustomTextField';

import {
  permit
} from '@lba-dev/package.local-globals/restrictions';

class CreditCardInfo extends Component {
  state = {
    date: this.props.creditCard.get('expireDate', ''),
    firstData: this.props.creditCard,
    dateError: false,
  };

  setDate = (path, value) => {
    const date = new Date(value.replace('/', '/01/'));
    let err = false;
    if (!isNaN(date.valueOf())) {
      this.props.setData(path, value);
    } else {
      err = true;
    }
    this.setState({ dateError: err, date: value });
  }
  createPath = (path) => {
    const paths = this.props.paths;
    return [...paths, path];
  }

  render() {
    const { setData, creditCard, user } = this.props;
    const { dateError, firstData } = this.state;
    return (
      <Grid container spacing={3}>
        <CustomTextField
          grid
          xs={12}
          sm
          multiline={false}
          type={firstData.get('number') && !permit(user,
            { key: 'canSeeCardNumber' })
            ? 'password'
            : 'string'}
          label="Numéro de la carte"
          value={creditCard.get('number', '')}
          path={this.createPath('number')}
          setData={setData}
        />
        <CustomTextField
          grid
          xs={12}
          sm
          multiline={false}
          type={
            firstData.get('securityCode') && !permit(user,
              { key: 'canSeeCardNumber' })
              ? 'password'
              : 'string'
          }
          label="Code de sécurité"
          value={creditCard.get('securityCode', '')}
          path={this.createPath('securityCode')}
          rowsMax={1}
          maxLength="4"
          setData={setData}
        />
        <CustomTextField
          grid
          xs={12}
          sm
          multiline={false}
          type={
            firstData.get('expireDate') && !permit(user,
              { key: 'canSeeCardNumber' }) ? 'password' : 'string'
          }
          label="Date d'expiration"
          value={creditCard.get('expireDate', '')}
          path={this.createPath('expireDate', '')}
          setData={this.setDate}
          mask="test"
          error={dateError}
        />
      </Grid>
    );
  }
}

CreditCardInfo.propTypes = {
  civility: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired
};

CreditCardInfo.defaultProps = {
  civility: ''
};

export default CreditCardInfo;
