import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  genFournisseurList
} from '@lba-dev/package.local-globals/configFournisseursTypes';

import MenuItem from '@mui/material/MenuItem';
import CustomSelect from '../../CustomInputs/CustomSelect';
import { connect } from 'react-redux';

const path = ['pro'];

const mapStateToProps = ({ fournisseurs = [] }) => ({
  fournisseurs,
});

class Fournisseurs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      original: [],
      fournisseurs: props.fournisseurs.filter(e => e.enabled)
    };
  }

  componentDidMount() {
    if (this.state.fournisseurs.length) {
      this.formatFournisseurList();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fournisseurs.length !== this.props.fournisseurs.length) {
      this.formatFournisseurList();
    }
  }

  formatFournisseurList = () => {
    const fournisseurs = genFournisseurList(this.state.fournisseurs);
    this.setState({
      original: fournisseurs,
    });
  };

  setData = (path, value) => {
    this.props.setData(path, value);
  }

  render() {
    const { pro, disabled, propsSelect = {} } = this.props;
    const { original } = this.state;

    return (
      <CustomSelect
        path={path}
        value={pro}
        renderValue={() => pro}
        setData={this.setData}
        disabled={disabled}
        {...propsSelect}
      >
        {original.map((e, i) =>
          e.options.map((name, key) => (<MenuItem disabled={e.title === name}
            key={`${i}_${key}`} value={name}>{name}</MenuItem>)
          )).filter(Boolean)
        }
      </CustomSelect>
    );
  }
}

Fournisseurs.propTypes = {
  setData: PropTypes.func.isRequired
};


export default connect(mapStateToProps)(Fournisseurs);
