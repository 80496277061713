import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import PaymentArtisan from '../Payment/PaymentArtisan';
import Comments from '../Comments';
import Grid from '@mui/material/Grid';
import { fromJS } from 'immutable';
import Supplies from '../Supplies';
import store from '../../store';
import api from '../../api';
import notifSystem from '../../notifSystem';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { saveInterPaiement } from '../../actions/compta';

const mapStateToProps = (state) => ({
  userId: state.userId,
  users: state.users,
});

const styles = {
  divLoading: {
    height: 880,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

class PaymentDialog extends Component {
  state = {
    data: {},
    save: false,
    openSecDialog: false,
    open: false,
    visible: false,
    updateIntervention: false,
    loading: true,
  };
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.open !== nextProps.open ||
      this.state !== nextState ||
      this.props.callback !== nextProps.callback ||
      this.props.defaultText !== nextProps.defaultText ||
      JSON.stringify(this.state.data) !== JSON.stringify(nextState.date)
    );
  }
  componentDidMount() {
    const { data, artisan } = this.props.defaultText;
    this.setState({
      data,
      artisan,
      statusRemuneration:
          (data.remunerationArtisan || {}).status ||
          (artisan && artisan.remuneration && artisan.remuneration.status)
    });
  }

  static getDerivedStateFromProps(props) {
    return {
      open: props.open,
    };
  }

  onValidate = () => {
    if (typeof this.props.callback === 'function') {
      this.setState({
        save: true,
        open: true,
      });
    }
  };
  onReject = () => {
    if (typeof this.props.callback === 'function') {
      this.props.callback(false);
    }
  };
  setInter = (path, value, cb = f => f) => {
    let data = { ...this.state.data };
    data[path] = value;
    this.setState({ data, updateIntervention: true }, cb);
  };

  confiltCheck = (message, obj) => {
    if (message === 'Conflict') {
      this.setState({
        openSecDialog: true,
        save: false,
        obj,
        visible: false,
      });
    } else {
      this.setState({ visible: false });
      notifSystem.error('Erreur', 'Le paiement n\'a pas été enregistrée');
    }
  };

  sendPayement = async (methods, ...params) => {
    try {
      const { updateIntervention } = this.state;
      this.setState({ visible: true });
      await api.paiements[methods](...params);
      if (updateIntervention) {
        await saveInterPaiement(this.state.data);
      }
      this.props.callback(false);
      notifSystem.success('Message', 'Informations sauvegardées');
    } catch (e) {
      return this.confiltCheck(e.message, {
        ...(params[1] || params[0]),
        _id: params[1] ? params[0] : null,
      });
    }
  };

  setRem = (e) => {
    this.setState({
      statusRemuneration: e.target.value,
    });
  };

  changeMethods = (modeToCheck) => {
    const { obj } = this.state;
    const toSend = {
      ...obj,
      _id: undefined,
      modeToCheck,
    };
    if (obj._id) {
      return this.sendPayement('patch', obj._id, toSend);
    }
    this.sendPayement('post', toSend);
  };

  finishLoading = () => {
    this.setState({ loading: false });
  }

  render() {
    const {
      data,
      artisan,
      save,
      visible,
      openSecDialog,
      statusRemuneration,
      loading
    } = this.state;
    const { users, userId, open } = this.props;
    return (
      <Dialog open={open} maxWidth={openSecDialog ? 'sm' : 'md'} fullWidth>
        {visible ? (
          <div style={styles.divLoading}>
            <CircularProgress size={100} />
            <Typography variant="h6" children="Chargement en cours..." />
          </div>
        ) : (
          [
            openSecDialog ? (
              <React.Fragment>
                <DialogTitle children="Attention" />
                <DialogContent>
                  Vous ne pouvez pas payer un artisan par cheque et par virement
                  en meme temps.
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => this.changeMethods('cheque')}
                    color="primary"
                    children="Je passe tout par chèque"
                  />
                  <Button
                    onClick={() => this.changeMethods('virement')}
                    color="primary"
                    autoFocus
                    children="Je passe tout par virement"
                  />
                  <Button
                    onClick={this.onReject}
                    color="primary"
                    autoFocus
                    children="Annuler"
                  />
                </DialogActions>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} style={{ marginBottom: '30px' }}>
                      <PaymentArtisan
                        finishLoading={this.finishLoading}
                        data={data}
                        artisan={artisan}
                        save={save}
                        statusRemuneration={statusRemuneration}
                        sendPayement={this.sendPayement}
                        updatePayement={this.updatePayement}
                        setRem={this.setRem}
                        callback={this.props.callback}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Comments
                        users={users}
                        userId={userId}
                        id={data.interId || data.id}
                        interId={data.interId || data._id}
                        comments={fromJS(data.comments)}
                        setData={(c, v) => {
                          if (v !== null) {
                            let data = this.state.data;
                            data.comments = v.toJS();
                            this.setState({ data });
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Supplies
                        merge={fromJS(data)}
                        setData={this.setInter}
                        tva={artisan && artisan.tva}
                        value={fromJS(data.supplies)}
                        user={store
                          .getState()
                          .users.find((e) => e._id === store.getState().userId)}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.onReject}
                    color="primary"
                    children="Annuler"
                  />
                  <LoadingButton
                    loading={loading}
                    disabled={this.state.visible}
                    onClick={this.onValidate}
                    color="primary"
                    children="Valider"
                  >
                    Valider
                  </LoadingButton>
                </DialogActions>
              </React.Fragment>
            ),
          ]
        )}
      </Dialog>
    );
  }
}

PaymentDialog.propTypes = {
  callback: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(PaymentDialog);
