import {
  COUNTER_FETCH
} from '../constants/ActionTypes';

export function loadCounter(counter) {
  return {
    type: COUNTER_FETCH,
    counter
  };
}
