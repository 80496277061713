/* eslint-disable max-lines */
import aSubStatus from '@lba-dev/package.local-globals/aSubStatus';
import cSAV from '@lba-dev/package.local-globals/cSAV';
import categories from '@lba-dev/package.local-globals/categories';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import constants from '../../constants/collections';
import { data as savStatus } from '@lba-dev/package.local-globals/SAVStatus';
import { Types } from './Types';

export const List = ({ user }) => {
  const list = {
    default: {
      dataFormat: {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Artisan: Types.artisanRef,
          Prevu: Types.Text,
          Prix: Types.Money,
          'Cat. du SAV': Types.Chip,
          Status: Types.Chip,
        },
        tableData: [
          { class: 'th' },
          { class: '', key: 'login' },
          { class: '', list: categories.data },
          { class: 'reduce' },
          { class: '' },
          { class: '', name: 'ville' },
          {
            class: 'th',
            name: 'artisan',
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
          },
          { class: 'reduce' },
          { class: '', key: 'onSitePayment' },
          { class: 'th', list: cSAV.data },
          { class: '', list: savStatus },
        ],
        keys: [
          'id',
          'login.ajout',
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          permit(user, { key: 'displayArtisanCompanyName' })
            ? ['currentArtisan.companyName']
            : ['currentArtisan.name', 'currentArtisan.firstname'],
          'date.savDate',
          'announcedPrice',
          'catSAV',
          'status',
        ],
        checkType: [
          'number',
          'users',
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          ['currentArtisan.name', 'currentArtisan.firstname'],
          'date',
          'prix',
          'catSAV',
          'status',
        ],
      },
      listen: constants.savInterventions,
    },
  };

  return list;
};
