import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import EditableList from '../EditableList';

const GetSubRow = ({ elem }) => (
  <Grid container justifyContent="center" >
    {elem.responses.map((e, i) => (
      <Grid key={i} item xs={8}>
        <Typography children={` - ${e.response}`} />
      </Grid>
    ))}
  </Grid>
);

const SubQuestions = ({ response, questions, setDataInput }) => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    spacing={2}
  >
    <Grid item xs={2}>
      <Typography
        children={response.response}
        align="center"
        variant="subtitle1"
        sx={{ textDecoration: 'underline' }}
      />
    </Grid>
    <Grid item xs={12}>
      <EditableList
        title={'Questions pour le client'}
        dataType={'subQuestions'}
        headers={[
          {
            key: 'open',
            type: 'iconButton',
            data: {
              FirstIcon: KeyboardArrowDown,
              SecondIcon: KeyboardArrowRight,
            },
            cellProps: { style: { width: 15 } },
            defaultValue: false
          },
          {
            key: '_id',
            type: 'selectQuestion',
            placeholder: 'Question... ?',
            cellProps: { style: { width: 550 } },
            data: { questions, addQuestion: false },
          },
        ]}
        data={response.subQuestions || []}
        setData={(data) => {
          const subQuestions = data.map(e => ({
            ref: e.ref || '',
            question: e.question || '',
            _id: e._id || '',
            responses: e.responses || [],
            open: e.open
          }));
          const r = { ...response, subQuestions };
          setDataInput(r);
        }}
        withExpansion
        SubRow={GetSubRow}
      />
    </Grid>
  </Grid>
);

export default SubQuestions;
