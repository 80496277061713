import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { Types } from './ListInfo';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import ReportTab from '../ReportList/ReportTab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { withStyles } from 'tss-react/mui';
import { data as historyCat } from '@lba-dev/package.local-globals/historyCat';
import AudioPlayer from '../Calls/AudioPlayer';
import { getAlias, cacheDisabled } from '../../utils/function';
import { SHOW_ENR } from '@lba-dev/package.local-globals/configTypes';

export const LineTypes = {
  Signalement: 1,
  Modification: 2,
  Absence: 3,
};

const styles = {
  right: {
    textAlign: 'right',
  },
  typo: {
    fontWeight: 'bold',
  },
  align: {
    textAlign: 'center',
  },
  textField: {
    width: 400,
  },
  tableRowBorder: {
    borderLeftWidth: 10,
    borderLeftColor: 'yellow',
    borderLeftStyle: 'solid',
  },
  tableRowSolved: {
    borderLeftWidth: 10,
    borderLeftColor: 'green',
    borderLeftStyle: 'solid',
  },
  tableRowNotSolved: {
    borderLeftWidth: 10,
    borderLeftColor: 'red',
    borderLeftStyle: 'solid',
  },
  tableRowNeutral: {
    borderLeftWidth: 10,
    borderLeftColor: 'orange',
    borderLeftStyle: 'solid',
  },
  tableRowPrioritaire: {
    borderLeftWidth: 10,
    borderLeftColor: 'blue',
    borderLeftStyle: 'solid',
  },
  activityLabel: {
    color: 'black',
    padding: 0,
  },
  activityComment: {
    color: 'grey',
    padding: 0,
    fontStyle: 'italic',
  },
};

const mapStateToProps = ({ users, userId }) => ({
  users,
  user: users.find(e => e._id === userId),
});

const callText = (id, isSAV) => id ?
  `depuis ${isSAV ? 'le dossier sav' : 'l\'intervention'} n°${id}`
  : '';

class LineFormat extends Component {
  state = {
    users: this.props.users,
    com: (this.props.info && this.props.info.comment) || '',
  };

  setCom = event => {
    this.setState({
      com: event.target.value,
    });
  };

  genAct = () => {
    const { info, id, classes, launchRequest } = this.props;
    let text = '';
    if (info.type === LineTypes.Signalement) {
      return (
        <Table>
          <TableBody>
            <ReportTab launchRequest={launchRequest} report={info} key={id} />
          </TableBody>
        </Table>
      );
    } else if (info.type === LineTypes.Modification) {
      text = info.text ? info.text.split('\n') : '';
    } else if (info.type === LineTypes.Absence) {
      text = `Absence du ${new Date(info.from).toLocaleString(
        'fr-FR'
      )} au ${new Date(info.to).toLocaleString('fr-FR')} :
       '${info.reason || 'Aucune raison'}'`;
    }
    const categorie = historyCat.find(e => e._id === info.categorie);
    const categorieName = categorie ? categorie.name : '';
    const comment = info.comment || '';
    return (
      <Table>
        <TableBody>
          <TableRow className={classes.tableRowBorder} key={this.props.id}>
            <TableCell style={{ width: '20%' }} component="th" scope="row">
              <ListItemText
                className={classes.activityLabel}
                disableTypography={true}
                primary={`${new Date(info.date).toLocaleString(
                  'fr-FR'
                )} - ${getAlias(info.userId)}`}
              />
            </TableCell>
            <TableCell style={{ width: 90 }} component="th" scope="row">
              <ListItemText
                className={classes.activityLabel}
                disableTypography={true}
                primary={categorieName}
              />
            </TableCell>
            <TableCell>
              <ListItemText
                className={classes.activityLabel}
                disableTypography={true}
              />
              {
                Array.isArray(text) ?
                  text.map((e, i) => <p key={i}>{e}</p>) :
                  text
              }
              <ListItemText
                className={classes.activityComment}
                disableTypography={true}
                primary={comment.length ? `~ Commentaire: ${comment}` : ''}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  genCalls = () => {
    const { info, setComment, classes } = this.props;
    const { com } = this.state;
    return (
      <Table>
        <TableBody>
          <TableRow className={classes.tableRowBorder} key={this.props.id}>
            <TableCell padding={'checkbox'} style={{ width: '26.3%' }}>
              <ListItemText
                style={{ color: 'grey' }}
                disableTypography={true}
                primary={`${
                  info.direction === 'inbound'
                    ? 'L\'artisan a contacté'
                    : 'L\'artisan a été contacté par'
                } ${getAlias(info.userId)} ${
                  callText(info.savId || info.interventionId, !!info.savId)} 
                le ${new Date(info.date).toLocaleString('fr-FR')}`}
              />
            </TableCell>
            <TableCell padding={'checkbox'} style={{ width: 350 }}>
              <TextField
                variant="standard"
                fullWidth
                multiline={true}
                placeholder="Commentaire"
                defaultValue={info.comment}
                onChange={this.setCom}
                disabled={info.locked}
                rows={3} />
            </TableCell>
            {!cacheDisabled(SHOW_ENR) && info.url && (
              <TableCell padding={'checkbox'} style={{ minWidth: 300 }}>
                <AudioPlayer url={info.url} duration={info.duration} />
              </TableCell>
            )}
            <TableCell padding={'checkbox'} style={{ width: '10%' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setComment(com, info._id)}
              >
                CONFIRMER
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  genComs() {
    const { info, classes } = this.props;
    return (
      <Table>
        <TableBody>
          <TableRow
            className={
              [
                classes.tableRowNotSolved,
                classes.tableRowNeutral,
                classes.tableRowSolved,
                classes.tableRowPrioritaire,
              ][info.type + 1]
            }
            key={this.props.id}
          >
            <TableCell padding={'checkbox'}>
              <ListItemText
                style={{ color: 'grey' }}
                disableTypography={true}
                primary={`${new Date(info.date).toLocaleString(
                  'fr-FR'
                )} - ${getAlias(info.userId)}`}
              />
            </TableCell>
            <TableCell padding={'checkbox'} style={{ width: 560 }}>
              <ListItemText
                style={{ color: 'black' }}
                disableTypography={true}
                primary={info.commentValue}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  render() {
    const { type } = this.props;

    if (type === Types.Activities) {
      return this.genAct();
    }
    if (type === Types.Calls) {
      return this.genCalls();
    }
    return this.genComs();
  }
}

export default connect(mapStateToProps)(withStyles(LineFormat, styles));
