import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import api from '../../../api';
import { A_ARC } from '@lba-dev/package.local-globals/aStatus';
import {
  AS_INDSP,
  AS_QUA
} from '@lba-dev/package.local-globals/aSubStatus';
import notifSystem from '../../../notifSystem';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

import { remunerationIcons } from '../../../utils/IconsDisplay';
import getDataById from '@lba-dev/package.local-globals/getDataById';
import CustomTextField from '../../CustomInputs/CustomTextField';

class PickArtisan extends Component {
  state = {
    artisans: [],
    search: '',
  }

  loadArtisans = async (lat, lng, radius, savInter) => {
    try {
      const artisans = await api.artisans.getAll({
        lat,
        lng,
        radius,
        query: JSON.stringify({
          limit: 50,
          categories: { $elemMatch: { $eq: savInter.categorie } },
          status: { $ne: A_ARC },
          subStatus: { $nin: [AS_INDSP, AS_QUA] }
        }),
      })
        .then(res => res.body().map(e => e.data()));
      this.setState({
        artisans: artisans.map(e => {
          const status = e.obj.status === A_ARC
            ? getDataById.artisanAStatus[e.obj.status]
            : getDataById.artisanSubStatus[e.obj.subStatus];
          return { ...e, status };
        })
      });
    } catch (error) {
      return notifSystem.error(
        'Erreur',
        'La liste d\'artisans n\'a pas été recuperée'
      );
    }
  }
  componentDidMount() {
    const { savInter } = this.props;
    const [lng, lat] = savInter?.client?.address?.location?.coordinates || [];
    this.loadArtisans(lat, lng, 50, savInter);
  }

  searchArtisan(v) {
    this.setState({ search: v });
  }

  render() {
    const { artisans, search } = this.state;
    const { savInter, setData } = this.props;
    return (
      <Grid container justifyContent="center" align="center" spacing={2}>
        <Grid item xs={12}>
          <CustomTextField grid xs={12} sm={6}
            key={1}
            setData={(p, v) => this.searchArtisan(v)}
            id="search"
            label="Filtrer..."
            type="search"
          />
        </Grid>
        {artisans
          .filter(e => search
            ? `${(e.obj.companyName || '').toLowerCase()} ${
              (e.obj.name || '').toLowerCase()} ${(e.obj.firstname || '')
              .toLowerCase()}`.includes(search.toLowerCase())
            : e)
          .map((e, i) => (
            <Grid
              key={i}
              container
              spacing={4}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={1}>
                <Radio
                  checked={e.obj._id === savInter.artisan}
                  onChange={(ev) => {
                    setData(['savInter', 'artisan'], ev.target.value);
                  }}
                  value={e.obj._id}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                >
                  {`${e.obj.companyName} - ${e.obj.name} ${e.obj.firstname
                  } - (${Math.round(e.dis)} km)`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                children={
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: e.status.color['500'],
                      textTransform: 'capitalize',
                    }}
                    children={e.status.name}
                  />
                }
              />
              <Grid
                item
                xs={2}
                children={remunerationIcons[
                  e.obj.remuneration.status
                ](e.obj.remuneration)}
              />
            </Grid>
          ))}
      </Grid>
    );
  }

}


export default PickArtisan;
