import React from 'react';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  }
};

const tableRepres = ({ tab, classes }) => (
  tab && (
    <CardContent><Paper className={classes.root}>
      <Table >
        <TableBody>
          {tab.mapEntries(([ k, v ], i) => [
            <TableRow key={i}>
              <TableCell component="th" scope="row">{k}</TableCell>
              <TableCell align="right">{v}</TableCell>
            </TableRow>]
          )}
        </TableBody>
      </Table>
    </Paper></CardContent>
  )
);

tableRepres.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(tableRepres, styles);
