import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { CateogriesFiltres, ArrayFilter, Tries, Parametres } from './';
import Slide from '@mui/material/Slide';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight
} from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import { data as States } from '@lba-dev/package.local-globals/statusProsp';

import { data as Sources } from '@lba-dev/package.local-globals/sourcesProsp';
import { List } from 'immutable';

const styles = {
  root: {
    marginBottom: 5,
    flexGrow: 1,
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  FormControlLabel: {
    padding: '0px 10px',
    width: '90%',
  },
  ListItem: {
    backgroundColor: '#8080805c',
  },
  Filter: {
    boxShadow: `4px 3px 14px ${'#000000'}`,
    borderRadius: 8,
    overflowY: 'auto',
    backgroundColor: 'white',
    height: window.innerHeight - 290,
    zIndex: 10,
  },
  FilterIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: -18,
    zIndex: 5,
    height: window.innerHeight - 290,
  },
  FilterParent: {
    display: 'flex',
  },
  ListeFilter: {
    width: 250,
    position: 'absolute',
    right: 0,
    top: 92,
    display: 'flex',
  },
  Mobile: {
    padding: '0px 20px',
  },
};

class Filters extends PureComponent {
  render() {
    const {
      classes,
      data,
      updateData,
      setArrayFilter,
      hideFilters,
      updateState,
    } = this.props;
    return hideFilters ? (
      <div
        className={classes.ListeFilter}
        style={{ width: 50, marginRight: -12 }}
      >
        <FilterIcon
          hideFilters={hideFilters}
          updateState={updateState}
          classes={classes}
        />
      </div>
    ) : (
      <Slide
        direction="left"
        in={!hideFilters}
        className={classes.ListeFilter}
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.FilterParent}>
          <FilterIcon
            hideFilters={hideFilters}
            updateState={updateState}
            classes={classes}
          />
          <div className={classes.Filter}>
            <CateogriesFiltres
              setArrayFilter={setArrayFilter}
              choosenCats={data.get('categorie', new List())}
            />
            <ArrayFilter
              label={'STATUS'}
              field={'status'}
              globalData={States}
              setArrayFilter={setArrayFilter}
              data={data.get('status', new List())}
            />
            <ArrayFilter
              label={'SOURCES'}
              field={'source'}
              globalData={Sources}
              setArrayFilter={setArrayFilter}
              data={data.get('source', new List())}
            />
            <Parametres data={data} updateData={updateData} />
            <Tries data={data} updateData={updateData} />
          </div>
        </div>
      </Slide>
    );
  }
}

const FilterIcon = ({ classes, updateState, hideFilters }) => (
  <div className={classes.FilterIcon}>
    <Fab
      onClick={() => updateState('hideFilters', !hideFilters)}
      style={{ backgroundColor: 'white' }}
      size='medium'
    >
      {hideFilters ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
    </Fab>
  </div>
);

Filters.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  setCategorie: PropTypes.func,
  setStatus: PropTypes.func,
  updateState: PropTypes.func,
  hideFilters: PropTypes.bool.isRequired,
};

export default withStyles(Filters, styles);
