import React from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../actions/dialog';
import MoneyInput from '../CustomInputs/MoneyInput';

const setValidationBq = setDialogContentProp('validationBq');

const mapStateToProps = ({
  dialog: {
    contentProps: { validationBq = 0 },
  },
}) => ({
  validationBq,
});

const mapDispatchToProps = {
  setValidationBq,
};

const ValidationRemiseDialog = ({ validationBq, setValidationBq }) => (
  <MoneyInput
    placeholder={'Saisir votre montant'}
    defaultValue={validationBq || null}
    label="Montant*"
    setData={(p, v) => setValidationBq(v)}
  />
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValidationRemiseDialog);
