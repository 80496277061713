import React, { Component } from 'react';
import classnames from 'classnames';

import { withStyles } from 'tss-react/mui';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ExpandMore } from '@mui/icons-material';

import TwoColumnList from './TwoColumnList';

const styles = theme => ({
  flexBox: {
    display: 'flex',
    padding: 5,
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  title: {
    fontWeight: 'bold',
    margin: 'auto 0 auto 5px',
  }
});

class CollapsedTwoColumnList extends Component {
  state = {
    open: false
  }

  toggleOpen = () =>
    this.setState(({ open }) => ({ open: !open }))

  render() {
    const { open } = this.state;
    const {
      classes,
      title = '',
      data = {}
    } = this.props;

    return (
      <div>
        <div className={classes.flexBox}>
          <IconButton
            className={classnames(classes.expand, {
              [classes.expandOpen]: open,
            })}
            onClick={this.toggleOpen}
            aria-expanded={open}
            children={<ExpandMore />}
            size="large" />
          <Typography
            className={classes.title}
            variant="subtitle1"
            children={title}
          />
        </div>
        <Collapse in={open} timeout={500}>
          <TwoColumnList data={data} />
        </Collapse>
      </div>
    );
  }
}

export default withStyles(CollapsedTwoColumnList, styles);
