import {
  data as categoriesData
} from '@lba-dev/package.local-globals/categories';
import { data as paidStatus } from '@lba-dev/package.local-globals/paidStatus';
import { data as AStatus } from '@lba-dev/package.local-globals/aStatus';
import {
  data as ASubStatus,
  AS_QUA,
} from '@lba-dev/package.local-globals/aSubStatus';
import { data as aLevel, NOT_QUA } from '@lba-dev/package.local-globals/aLevel';
import { data as ADossier } from '@lba-dev/package.local-globals/dossier';
import {
  data as IStatus,
  I_AVR,
  I_VRF,
  I_ANN,
} from '@lba-dev/package.local-globals/iStatus';
import {
  data as demandeStatus,
  D_REF,
} from '@lba-dev/package.local-globals/demandeStatus';
import { R_POR, R_FOR } from '@lba-dev/package.local-globals/remStatus';
import store from '../../store';
import { data as SAVStatus } from '@lba-dev/package.local-globals/SAVStatus';
import { firstCallFilters } from '../../utils/function';
import { MIN_DURATION } from '@lba-dev/package.local-globals/minDuration';
import {
  REPONDEUR,
  OK,
  RDVCONFIRM,
  isProblem,
} from '@lba-dev/package.local-globals/callTypes';
import {
  qualityStatus
} from '@lba-dev/package.local-globals/qualityStatus';

const equipeAreas = (name) => (value) => {
  const user = store
    .getState()
    .users.find(
      (e) => e.act && e.login.toUpperCase().match(`^${value.toUpperCase()}`)
    );
  return {
    [name]: {
      $in: user && user.areas ? user.areas.map((e) => `/^${e}/`) : [],
    },
  };
};

export function convertiFilterData(value, data, findField, toReturnField) {
  return data.reduce((v, c) => {
    if (findField.some((e) => new RegExp(`^${value}`, 'gi').test(c[e]))) {
      v.push(c[toReturnField]);
    }
    return v;
  }, []);
}

export const callBackFunction = {
  categorie: (value) => {
    const data = convertiFilterData(
      value,
      categoriesData,
      ['name', 'shortName'],
      '_id'
    );
    return data.length ? { categorie: { $in: data } } : null;
  },
  categories: (value) => {
    const data = convertiFilterData(
      value,
      categoriesData,
      ['name', 'shortName'],
      '_id'
    );
    return data.length ? { categories: { $in: data } } : null;
  },
  paid: (value) => {
    const data = convertiFilterData(
      value,
      paidStatus,
      ['name', 'shortName'],
      '_id'
    );
    return data.length ? { paid: { $in: data } } : null;
  },
  grandCompte: (value, dataToFind) => {
    const data = convertiFilterData(
      value,
      dataToFind,
      ['companyName', 'name', 'firstname'],
      '_id'
    );
    return data.length ? { 'billing.grandCompte': { $in: data } } : null;
  },
  source: (value) => ({
    source: { $regex: `${value.escapeRegExp()}`, $options: 'i' },
  }),
  choice: (value) => ({
    choice: { $regex: `${value.escapeRegExp()}`, $options: 'i' },
  }),
  sChoice: (value) => ({
    sChoice: { $regex: `${value.escapeRegExp()}`, $options: 'i' },
  }),
  companyName: (value) => ({
    companyName: { $regex: `^${value.toUpperCase().escapeRegExp()}` },
  }),
  clientZipCode: (value) => ({
    'client.zipCode': { $regex: `^${value.escapeRegExp()}` },
  }),
  sStatus: (value) => {
    if (isNaN(value)) {
      return {
        'sStatus.path': { $regex: `/${value.escapeRegExp()}`, $options: 'i' },
      };
    }
    const confCand = store
      .getState()
      .confCand.toJS()
      .find((e) => e.id === Number(value) && e.path !== '');
    const path =
      confCand && confCand.path === '/Refusé' ? confCand.name : confCand.path;
    return confCand && { 'sStatus.path': { $regex: `${confCand && path}` } };
  },
  AStatus: (value) => {
    const data = convertiFilterData(
      value,
      AStatus,
      ['name', 'shortName'],
      '_id'
    );
    return data.length ? { status: { $in: data } } : null;
  },
  ASubStatus: (value) => {
    const data = convertiFilterData(
      value,
      ASubStatus,
      ['name', 'shortName'],
      '_id'
    );
    let query = null;
    if (data.length) {
      query = { subStatus: { $in: data } };
      if (data.includes(AS_QUA)) {
        query = { level: { $ne: NOT_QUA } };
      }
    }
    return query;
  },
  AStatusCandidat: (value) => {
    const data = convertiFilterData(
      value,
      AStatus,
      ['name', 'shortName'],
      '_id'
    );
    return data.length ? { 'currentArtisan.status': { $in: data } } : null;
  },
  ASubStatusCandidat: (value) => {
    const data = convertiFilterData(
      value,
      ASubStatus,
      ['name', 'shortName'],
      '_id'
    );
    let query = null;
    if (data.length) {
      query = { 'currentArtisan.subStatus': { $in: data } };
    }
    return query;
  },
  ALevel: (value) => {
    const data = convertiFilterData(value, aLevel);
    return data.length ? { level: data } : null;
  },
  dossier: (value) => {
    const data = ADossier.find((d) =>
      d.name.toLowerCase().startsWith(value.toLowerCase())
    );
    return { _dossier: data._id };
  },
  remuneration: (value) => {
    if (isNaN(value)) {
      if (value === 'POR') {
        return { 'remuneration.status': R_POR };
      } else if (value === 'FOR') {
        return { 'remuneration.status': R_FOR };
      }
    }
    return {
      'remuneration.pourcentage.labor': Number(value),
      'remuneration.status': R_POR,
    };
  },
  iStatus: (value) => {
    IStatus.push({
      _id: IStatus.length + 1,
      value: { isProforma: true, status: I_VRF },
    });
    IStatus.push({
      _id: IStatus.length + 1,
      value: { noPaymentReceived: true, status: I_AVR },
    });
    IStatus.push({
      _id: IStatus.length + 1,
      value: { emptyCancel: true, status: I_ANN },
    });
    const data = IStatus.find((d) => d._id === +value);
    if (data && typeof data.value === 'object') {
      return data.value;
    }
    return value ? { status: +value } : null;
  },
  debriefUser: (value) => {
    const users = store
      .getState()
      .users.filter((e) =>
        e.login.toUpperCase().match(`^${value.toUpperCase().escapeRegExp()}`)
      )
      .map((e) => e._id);
    return {
      'login.debrief': { $in: users.toArray() },
    };
  },

  equipeAreasArtisan: equipeAreas('billingAddress.zipcode'),
  equipeAreasCandidats: equipeAreas('address.zipcode'),
  equipeAreasClient: equipeAreas('client.address.zipcode'),
  savStatus: (value) => {
    const data = convertiFilterData(
      value,
      SAVStatus,
      ['name', 'shortName'],
      '_id'
    );
    return data.length ? { status: { $in: data } } : null;
  },
  dStatus: (value) => {
    const data = convertiFilterData(
      value,
      demandeStatus,
      ['name', 'shortName'],
      '_id'
    );
    if (value) {
      return data && data.length
        ? { status: { $in: data } }
        : { status: D_REF, raisonRefus: { $regex: value, $options: 'i' } };
    }
    return null;
  },
  firstCall: (value) => firstCallFilters(value),
  appel: (value) => ({
    1: {
      'login.miseEnRelation': { $exists: false },
      'communcations.0': { $exists: true },
      'communcations.type': { $exists: true },
      communcations: {
        $not: {
          $elemMatch: {
            duration: { $gt: MIN_DURATION },
            type: { $not: { $all: [OK, RDVCONFIRM] } },
          },
        },
        $elemMatch: {
          type: { $not: { $all: [REPONDEUR] } },
        },
      },
    },
    2: {
      'login.miseEnRelation': { $exists: false },
      communcations: {
        $elemMatch: {
          duration: { $gt: MIN_DURATION },
          type: { $not: { $all: [REPONDEUR] } },
          played: { $not: { $all: [true] } },
        },
      },
    },
    3: {
      'login.miseEnRelation': { $exists: false },
      communcations: {
        $elemMatch: {
          duration: { $gt: MIN_DURATION },
          type: { $in: isProblem },
          played: true,
        },
      },
    },
    4: {
      'login.miseEnRelation': { $exists: false },
      communcations: {
        $not: {
          $elemMatch: {
            duration: { $gt: MIN_DURATION },
            type: { $ne: REPONDEUR },
          },
        },
      },
    },
    5: {
      'login.miseEnRelation': { $exists: false },
      'communcations.0': { $exists: true },
      communcations: {
        $not: { $elemMatch: { type: { $ne: REPONDEUR } } },
      },
    },
    6: {
      'login.miseEnRelation': { $exists: true },
      appel: true,
      'communcations.type': { $exists: true },
      communcations: {
        $not: {
          $elemMatch: {
            duration: { $gt: MIN_DURATION },
            type: { $not: { $all: [OK, RDVCONFIRM] } },
          },
        },
      },
    },
    7: {
      'login.miseEnRelation': { $exists: true },
      communcations: {
        $elemMatch: {
          duration: { $gt: MIN_DURATION },
          type: { $not: { $all: [REPONDEUR] } },
          played: { $not: { $all: [true] } },
        },
      },
    },
    8: {
      'login.miseEnRelation': { $exists: true },
      appel: true,
      communcations: {
        $elemMatch: {
          duration: { $gt: MIN_DURATION },
          type: { $in: isProblem },
          played: true,
        },
      },
    },
    9: {
      'login.miseEnRelation': { $exists: true },
      appel: true,
      communcations: {
        $not: { $elemMatch: { type: { $ne: REPONDEUR } } },
      },
    },
  }[value] || {}),
  service: (value) => ({
    'service.desc': { $regex: value.escapeRegExp(), $options: 'i' },
  }),
  /**
   * @param {string} value
   */
  'quality.rate': (value) => {
    if (isNaN(value)) {
      return null;
    }
    const rateIntervals = qualityStatus.datasets.reduce((acc, { cond }, i) => {
      acc[i + 1] = [cond.min, cond.max];
      return acc;
    }, {});

    const rateInterval = rateIntervals[value];
    return rateInterval ? {
      'quality.rate': { $gte: rateInterval[0], $lte: rateInterval[1] }
    } : null;
  }
};
