import React from 'react';
import Grid from '@mui/material/Grid';
import { moment } from '@lba-dev/package.local-globals/moment';
import fetchText from '../fetchText';

const compRPROG = (
  { proposition: { reprogDate, clientHasInformed } },
  classes
) =>
  <Grid
    key={'Artisan updates a prog'}
    container
    item
    xs={8}
    spacing={3}
    children={[
      reprogDate && {
        data: {
          title: 'Intervention a été reprogrammée',
          text: `à ${moment(reprogDate).format('DD/MM/YY à HH:mm')}`,
        },
      },
      {
        data: {
          title: 'Le client a été informé :',
          text: clientHasInformed ? 'Oui' : 'Non',
        },
      },
    ].map(fetchText(classes))}
  />;

export default compRPROG;
