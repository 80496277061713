import React, { PureComponent } from 'react';
import { moment } from '@lba-dev/package.local-globals/moment';
import { GenTooltipIcon } from '../utils/IconsDisplay';
import { NotificationImportant } from '@mui/icons-material';
import { green, red, orange } from '@mui/material/colors';
import Grid from '@mui/material/Grid';

export default class ChronoLitiges extends PureComponent {
  state = {
    prevTimer: this.props.timer,
    colorTimer: '',
    textTimer: '',
    chrono: 0
  }

  count = 0;

  componentDidUpdate(prevProps) {
    if (this.props.timer !== prevProps.timer) {
      clearInterval(this.count);
      this.count = setInterval(this.addWeekDaysToTimer, 1000);
    }
  }

  componentDidMount() {
    this.count = setInterval(this.addWeekDaysToTimer, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.count);
  }

  colorTimer() {
    const chrono = this.state.chrono;
    if (chrono > 48) {
      return this.setState({
        colorTimer: red,
        textTimer: 'Sous 24h',
      });
    } else if ((chrono >= 24) && (chrono <= 48)) {
      return this.setState({
        colorTimer: orange,
        textTimer: '24h à 48h',
      });
    }
    return this.setState({
      colorTimer: green,
      textTimer: '+48h',
    });
  }

  addWeekDaysToTimer = () => {
    let weekDay = [];
    const initialIsoWeekDay = moment(this.props.timer).isoWeekday();
    const initialDate = moment(this.props.timer).format('HH');
    for (let m = moment(this.props.timer);
      m.isBefore(moment());
      m.add(1, 'days')) {
      let date;
      const momentIsoWeekDay = moment(m.format('YYYY-MM-DD')).isoWeekday();
      const momentDate = moment(m, 'YYYY-MM-DD-HH-mm').format('HH');

      if ((momentIsoWeekDay !== 6 && momentIsoWeekDay !== 7)
      || (initialIsoWeekDay === 5 && initialDate > 17)) {
        if ((initialIsoWeekDay !== 5 && momentIsoWeekDay === 5)
        || (initialIsoWeekDay === 5 && momentDate > 17)) {
          date = moment(m, 'YYYY-MM-DD-HH-mm').hour(17).minutes(0).seconds(0);
        } else if ((initialIsoWeekDay !== 1 && momentIsoWeekDay === 1)
        || (initialIsoWeekDay === 1 && momentDate < 9)) {
          date = moment(m, 'YYYY-MM-DD-HH-mm').hour(9).minutes(0).seconds(0);
        } else {
          date = moment(m, 'YYYY-MM-DD-HH-mm');
        }
        weekDay.push({
          date,
          isoWeekday: moment(m.format('YYYY-MM-DD')).isoWeekday()
        });
      }
    }

    let hours;
    let chrono = weekDay.reduce((acc, curr, indice) => {
      if (indice !== (weekDay.length - 1)) {
        if (curr.isoWeekday === 5) {
          hours = Math.abs(moment(weekDay[indice].date)
            .diff(moment(weekDay[indice].date.hour(17)), 'hours'));
        } else {
          hours = moment(weekDay[indice + 1].date)
            .diff(moment(weekDay[indice].date), 'hours');
        }
      } else if (curr.isoWeekday === 5) {
        hours = moment().diff(moment(weekDay[indice].date), 'hours') - 64;
      } else {
        hours = moment().diff(moment(weekDay[indice].date), 'hours');
      }
      return acc + hours;
    }, 0);
    return this.setState({
      chrono
    }, this.colorTimer);
  }

  render() {
    const { style, timer } = this.props;
    const { colorTimer, textTimer } = this.state;

    return ( <div style={style}>
      {timer &&
      <Grid container xs={12} wrap='nowrap' alignItems='center' >
        <Grid item={5}>
          <GenTooltipIcon
            Icon={NotificationImportant}
            text={textTimer}
            color={colorTimer}
          />
        </Grid>
      </Grid>}
    </div>);
  }
}
