import { List } from 'immutable';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../actions/dialog';
import Encaissement from '../Reglement/Encaissement';
import EncaissementNoOs from '../Reglement/EncaissementNoOs';
import { lineDefault } from '../Reglement/functions';

const setEncaissements = setDialogContentProp('encaissements');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      encaissements = new List(),
      withOsField = false,
      data = null,
    },
  },
}) => ({
  encaissements,
  withOsField,
  data
});

const mapDispatchToProps = {
  setEncaissements,
};


class ReglementNonIdentDialog extends Component {
  setData = (path, data) => {
    this.props.setEncaissements(data);
  };

  render() {
    const props = {
      tva: null,
      data: this.props.data,
      line: lineDefault,
      setData: this.setData,
      encaissements: this.props.encaissements,
      justDisplay: false
    };
    return (
      this.props.withOsField ?
        <Encaissement
          {...props}
          deleteCaissement={(e) => e}
        /> :
        <EncaissementNoOs {...props} />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReglementNonIdentDialog);
