import rootReducer from './reducers';
import addCity from './middlewares/addCity';
import goFiltered from './middlewares/goFiltered';
import addShowColumnMarker from './middlewares/addShowColumnMarker';
import getListCities from './middlewares/getListCities';
import editCity from './middlewares/editCity';
import deleteCity from './middlewares/deleteCity';
import showMarkersColumnSelected from './middlewares/showMarkersColumnSelected';
import loadActivitiesArtisan from './middlewares/loadActivitiesArtisan';
import { login } from './actions/auth';

import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux';

const middlewares = [
  addCity,
  goFiltered,
  addShowColumnMarker,
  getListCities,
  editCity,
  deleteCity,
  showMarkersColumnSelected,
  loadActivitiesArtisan
];

const enhancers = applyMiddleware(...middlewares);

const store = createStore(
  rootReducer,
  compose(enhancers,
    (process.env.NODE_ENV !== 'production' &&
    window.__REDUX_DEVTOOLS_EXTENSION__) ?
      window.__REDUX_DEVTOOLS_EXTENSION__() : (e) => e)
);

const token = window.localStorage.getItem('token');
if (token) {
  store.dispatch(login(token));
}

export default store;
