import React, { useState, useCallback, useEffect } from 'react';

import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  alpha,
  Divider
} from '@mui/material';

import ContactList from './ContactList';
import { blueGrey, red, grey } from '@mui/material/colors';
import {
  Add, Search, DragHandle
} from '@mui/icons-material';
import ContactPopover from './ContactPopover';
import {
  updateUser, updateUserChatConfig
} from '../../../actions/conversations';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { panelWidth, isDash }) => ({
  container: {
    height: '100%',
    position: 'relative',
  },
  titleBox: {
    padding: '0 8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 40,
    position: 'relative',
  },
  listContainer: {
    width: `${(panelWidth || '').replace('px', '') || 350}px`,
    minWidth: 250,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    }),
    [isDash ? 'borderLeft' : 'borderRight']: '1px rgba(0, 0, 0, 0.12) solid',
  },
  draggable: {
    position: 'absolute',
    display: 'flex',
    [isDash ? 'left' : 'right']: isDash ? -4 : -11,
    bottom: 0,
    top: 0,
    cursor: 'col-resize',
    alignItems: 'center'
  },
  dragIcon: {
    transform: 'rotate(90deg)',
    color: grey['A400'],
    marginLeft: -6
  },
  count: {
    fontSize: 12,
    color: 'red',
    padding: '1px 6px',
    borderRadius: '50%',
    backgroundColor: red[100],
    textAlign: 'center'
  },
  searchInput: {
    borderRadius: 25,
    paddingLeft: 4,
    backgroundColor: alpha(blueGrey['A100'], 0.3),
    borderColor: alpha(blueGrey['A100'], 0.5),
    '& input': {
      padding: '4px 14px 4px 0'
    }
  }
}));

const setCListConfig = (user, update) =>
  updateUserChatConfig(user._id, {
    contactList: update
  });

let panelWidth = '350';
const ContactContainer = ({
  lastMessages, selected, setSelected, loading, user,
  smsType, setUser, loadMore, isDash, setSmsType
}) => {
  let contactListConfig = user?.chatConfig?.contactList || {};
  panelWidth = contactListConfig?.width;
  const { classes } = useStyles({ loading, smsType, panelWidth, isDash });
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState('');
  const [anchorContactPopup, setAnchorContactPopup] = useState(null);

  const [initialPos, setInitialPos] = React.useState(null);
  const [initialSize, setInitialSize] = React.useState(null);

  useEffect(() => {
    setContacts(lastMessages);
  }, [lastMessages]);

  const handleSearch = useCallback((nSearch) => {
    const nContacts = [...contacts];
    nSearch = nSearch.toLowerCase();
    if (nSearch !== '' && nSearch.length > 1) {
      const searched = lastMessages.filter(e => e &&
        ((e.message || '').toLowerCase().includes(nSearch) ||
        (e.companyName || '').toLowerCase().includes(nSearch)));
      setContacts(searched);
    } else if (nContacts.length !== lastMessages.length) {
      setContacts(lastMessages);
    }
    setSearch(nSearch);
  }, [search]);

  const resizeDiv = (newWidth) => {
    let resizableElem = document.getElementById('Resizable');
    let convCollapse = document.getElementById('convCollapse');
    let buttons = document.getElementById('buttons');
    if (convCollapse && +newWidth > 250) {
      convCollapse.style.right = `${+newWidth + 57}px`;
    }
    if (buttons && +newWidth > 250) {
      buttons.style.right = `${+newWidth + 57 + 15}px`;
    }
    resizableElem.style.width = `${newWidth}px`;
  };

  const pinConversation = (elem) => {
    const pinned = user.pinnedConversations || [];
    const dataObject = elem.dataObject || {};
    updateUser(user._id, {
      pinnedConversations:
        pinned.toggleValue({
          _id: elem._id,
          smsType,
          type: elem.type,
          companyName: elem.companyName,
          dataObject: {
            id: dataObject.id && +dataObject.id,
            name: dataObject.name || '',
            firstname: dataObject.firstname || '',
            message: elem.message || '',
            status: dataObject.status && +dataObject.status,
            subStatus: dataObject.subStatus && +dataObject.subStatus,
            categories: dataObject.categories || [],
            categorie: dataObject.categorie && +dataObject.categorie,
            tels: dataObject.tels || [],
            finalPrice: dataObject.finalPrice || 0,
          }
        }, (e) => e._id === elem._id)
    }).then((elem) => setUser(elem));
  };

  const initial = (e) => {
    let resizable = document.getElementById('Resizable');

    setInitialPos(e.clientX);
    setInitialSize(resizable.offsetWidth);
  };

  const resize = (e) => {
    let clientX = e.clientX;
    let newPos = parseInt(clientX - initialPos, 10);
    const size = parseInt(initialSize, 10);
    let newWidth = `${size + newPos}`;

    if (isDash) {
      clientX = -1 * e.clientX;
      newPos = parseInt(clientX + initialPos, 10);
      newWidth = `${size + newPos}`;
    }
    if (!isDash || clientX) {
      panelWidth = newWidth;
      resizeDiv(newWidth);
    }
  };

  const dragFinish = () => {
    let resizableElem = document.getElementById('Resizable');
    const elemWidth = (resizableElem.style.width || '').replace('px', '');
    setCListConfig(user, {
      width: +elemWidth < 250 ? '250' : elemWidth
    });
  };
  return <Box display="flex" className={classes.container}>
    <Box id='Resizable' className={classes.listContainer}>
      <Box className={classes.titleBox}>
        <OutlinedInput
          size="small"
          variant='outlined'
          fullWidth
          placeholder='Rechercher'
          onChange={(e) => handleSearch(e.target.value)}
          className={classes.searchInput}
          startAdornment={
            <InputAdornment position="start">
              <Search style={{ color: blueGrey['A100'] }} />
            </InputAdornment>
          }
        />
        <IconButton
          className={classes.searchIcon}
          aria-describedby={'contactSearch'}
          color='secondary'
          onClick={(e) => setAnchorContactPopup(e.target)}
        >
          <Add />
        </IconButton>
        <ContactPopover
          selected={selected}
          setSelected={setSelected}
          smsType={smsType}
          user={user}
          anchorEl={anchorContactPopup}
          setAnchorEl={setAnchorContactPopup}
          classes={classes}
          pinConversation={pinConversation}
          isDash={isDash}
        />
      </Box>
      <Divider />
      <ContactList
        classes={classes}
        user={user}
        selected={selected}
        setSelected={setSelected}
        contacts={contacts}
        loading={loading}
        smsType={smsType}
        setSmsType={setSmsType}
        loadMore={loadMore}
        pinConversation={pinConversation}
        isDash={isDash}
      />
    </Box>
    <Box className={classes.draggable}
      draggable={true}
      onDragStart={initial}
      onDragEnd={dragFinish}
      onDrag={resize}
      children={<DragHandle className={classes.dragIcon} />}
    />
  </Box>;
};

export default ContactContainer;
