import { S_JUR, S_SATCL } from '@lba-dev/package.local-globals/services';
import PriseEnChargeDialog from
  '../components/Dialogs/recouvrement/PriseEnChargeDialog';

import ConfirmBox from '../components/Dialogs/ConfirmBox';
import PriseDeContactDialog
  from '../components/Dialogs/recouvrement/PriseDeContactDialog';
import AddDialog from '../components/Dialogs/AddDialog';
import DialogStatus
  from '../components/Dialogs/recouvrement/DialogStatus';
import notifSystem from '../notifSystem';
import api from '../api';
import {
  MAIL_REGEX
} from '@lba-dev/package.local-globals/regexps';


export const middlewareRecouvrement = async (props, inter, cb) => {
  try {
    inter.recouvrement = await api
      .recouvrements
      .get(inter.id)
      .then(e => e.body().data());
    return cb(props, inter);
  } catch (error) {
    notifSystem.error('Erreur', 'Le dossier n\'a pas pu etre recuperé');
  }
};

const checkMail = str => str !== undefined && str !== ''
  && MAIL_REGEX.test(str);

export async function priseDeContact({ setDialog }, inter) {
  const oldStatus = inter?.recouvrement?.status;
  async function callback(inter, mail) {
    try {
      if (inter === null) {
        setDialog(null, false, '', null);
        return;
      }
      if (mail && mail.text && !checkMail(mail.desMail)) {
        return notifSystem.error('Erreur', 'L\'email saisi est incorrect.');
      }
      const { _id, ...NewRecouvrement } = inter.recouvrement;
      await api.recouvrements.patch(_id, {
        recouvrement: NewRecouvrement,
        oldStatus,
        mail
      });
      if (inter._id) {
        await api.interventions.patch(inter._id, {
          commissionValide: inter.commissionValide
        });
      }
      setDialog(null, false, '', null);
      return notifSystem.success('Succès', 'Prise de contact a été envoyée');
    } catch (e) {
      notifSystem.error(
        'Erreur',
        'La prise de contact n\'a pas été envoyée'
      );
    }
  }
  if (!inter.recouvrement) {
    try {
      inter.recouvrement = await api.recouvrements.get(inter.id)
        .then(e => e.body().data());
      return !inter.recouvrement.isPris ?
        setDialog(PriseEnChargeDialog, true, inter, callback) :
        setDialog(PriseDeContactDialog, true, inter, callback);
    } catch (error) {
      notifSystem.error('Erreur',
        'Prise de contact n\'a pas été envoyée');
    }
  }
  setDialog(PriseDeContactDialog, true, inter, callback);
}


export function priseEnCharge({ setDialog, user }, inter, cb) {
  function callback(obj) {
    setDialog(null, false, '', null);
    if (obj) {
      try {
        obj.recouvrement.isPris = true;
        obj.recouvrement.priseEnCharge = {
          date: new Date(),
          ...obj.recouvrement.priseEnCharge,
          userId: user._id,
        };
        api.recouvrements.patch(obj.recouvrement._id, {
          recouvrement: {
            id: obj.recouvrement.id,
            priseEnCharge: obj.recouvrement.priseEnCharge,
            cause: obj.recouvrement.cause,
            isPris: obj.recouvrement.isPris
          }
        });
        notifSystem.success('Succès prise en charge',
          'Prise en charge envoyée');
        if (cb) {
          cb();
        }
        if (obj.priseDeContact) {
          return priseDeContact(
            { setDialog },
            {
              ...inter,
              recouvrement: { ...inter.recouvrement, ...obj },
            },
            null
          );
        }
      } catch (e) {
        notifSystem.error('Erreur prise en charge',
          'Prise en charge n\'a pas été envoyée');
      }
    }
  }
  setDialog(PriseEnChargeDialog, true, inter, callback);
}

export async function addRecouvrementAPI(
  { setDialog, user },
  { id,
    title,
    type,
    isLitige,
    pris,
    cause = null,
    attributionRecouvrement,
    delay }) {
  try {
    if (id === '') {
      notifSystem.error('Erreur', 'Merci d\'ajouter un numero OS');
    } else if (!id) {
      setDialog(null, false, '', null);
    } else {
      const res = await api.recouvrements
        .custom('addRecouvrement')
        .post({
          id,
          type,
          isLitige,
          cause,
          attributionRecouvrement,
          delay
        });
      const data = res.body().data();

      if (data.error) {
        notifSystem.error('Erreur', 'Numero OS introuvable');
      } else if (data.re.lastErrorObject.updatedExisting) {
        notifSystem.error('Erreur', `${title} a déjà été ajouté`);
      } else {
        setDialog(null, false, '', null);
        notifSystem.success('Succès', `${title} a été ajouté`);
        if (pris && (user.service === S_JUR || user.service === S_SATCL)) {
          return priseEnCharge({ setDialog, user }, {
            ...data.inter,
            recouvrement: data.re.value
          }, null);
        }
      }
    }
  } catch (e) {
    notifSystem.error('Erreur', `${title} n'a pas pu être ajouté`);
  }
}

export function addRecouvrement(
  { setDialog, user },
  { type, isLitige, title }) {
  setDialog(AddDialog, true, { title: `Ajout ${title}` },
    (id = false, pris, attributionRecouvrement, delay) =>
      addRecouvrementAPI({ setDialog, user },
        { id, title, type, isLitige, pris, attributionRecouvrement, delay })
  );
}

export async function recouvrementStatus(props, inter, cb) {
  async function callback(recouvrement) {
    props.setDialog(null, false, '', null);
    if (recouvrement) {
      try {
        await api.recouvrements.patch(recouvrement._id, {
          recouvrement: {
            id: recouvrement.id,
            status: recouvrement.status,
            priseEnCharge: recouvrement.priseEnCharge,
            montantEncaisse: recouvrement.montantEncaisse || 0,
            resuloStatus: recouvrement.resuloStatus,
            isPris: true
          },
        });
        notifSystem.success('Succès',
          'Status modifé');
        if (cb) {
          cb();
        }
      } catch (e) {
        notifSystem.error('Erreur',
          'Status n\'a pas été modifé');
      }
    }
  }
  if (!inter.recouvrement && inter.artisan) {
    inter.recouvrement = await api.recouvrements
      .get(inter.id)
      .then(e => e.body().data());
  }
  props.setDialog(DialogStatus, true, inter, callback);
}

export function deletePriseDeContact(props, inter) {
  props.setDialog(ConfirmBox, true, {
    title: 'Etes-vous sûr de vouloir supprimer le dossier ?',
    confirmBox: 'Oui',
    closeBox: 'Annuler'
  }, confirmed => {
    if (confirmed) {
      api.recouvrements.delete(inter.recouvrement.id).then(() => {
        notifSystem.success('Succès', 'Le dossier a bien été supprimé');
      }).catch(() =>
        notifSystem.error('Erreur', 'Le dossier n\'a été supprimé'));
    }
    props.setDialog(null, false, null, null);
  });
}
