import { S_PART, S_JUR } from '@lba-dev/package.local-globals/services';

import {
  NOT_CONFIRMED,
  CHIEF,
  USER,
  ADMIN,
  HEADCHIEF,
  JUNIOR,
} from '@lba-dev/package.local-globals/permissions';
import componentLoader from '../../componentLoader';

const ListArtisan = componentLoader(() =>
  import(
    /* webpackChunkName: "ListArtisan" */
    '../../views/List/ListArtisan'
  )
);

const ListCandidat = componentLoader(() =>
  import(
    /* webpackChunkName: "ListCandidat" */
    '../../views/List/ListCandidat'
  )
);

const ListRelancesArtisan = componentLoader(() =>
  import(
    /* webpackChunkName: "ListRelancesArtisan" */
    '../../views/List/ListRelancesArtisan'
  )
);


const RecapArtisan = componentLoader(() =>
  import(
    /* webpackChunkName: "RecapArtisan" */
    '../../views/RecapArtisan'
  )
);

const UtilsAssets = componentLoader(() =>
  import(
    /* webpackChunkName: "UtilsAssets" */
    '../../views/UtilsAssets'
  )
);

const AjoutArtisans = componentLoader(() =>
  import(
    /* webpackChunkName: "AjoutArtisans" */
    '../../views/AjoutArtisans'
  )
);

const ListFacturier = componentLoader(() =>
  import(
    /* webpackChunkName: "ListFacturier" */
    '../../views/ListFacturier/ListFacturier'
  )
);

const AjoutCandidat = componentLoader(() =>
  import(
    /* webpackChunkName: "AjoutCandidat" */
    '../../views/AjoutCandidat'
  )
);

const CandidatConfig = componentLoader(() =>
  import(
    /* webpackChunkName: "CandidatConfig" */
    '../../components/CandidatConfig'
  )
);

const EtatReseau = componentLoader(() =>
  import(
    /* webpackChunkName: "EtatReseau" */
    '../../containers/EtatReseau'
  )
);

const EtatReseau2 = componentLoader(() =>
  import(
    /* webpackChunkName: "EtatReseau 2" */
    '../../views/EtatReseau2'
  )
);

const CommissionsPART = componentLoader(() =>
  import(
    /* webpackChunkName: "Partenariats" */
    '../../views/Commissions/Partenariats'
  )
);


const PartenariatStats = componentLoader(() =>
  import(
    /* webpackChunkName: "Partenariat" */
    '../../components/Stats/Partenariat'
  )
);

const MarketInterList = componentLoader(() =>
  import(
    /* webpackChunkName: "ListDemarcharge" */
    '../../components/ListDemarcharge'
  )
);

const ArtisanConnection = componentLoader(() =>
  import(
    /* webpackChunkName: "ArtisanConnection" */
    '../../components/ArtisanConnection'
  )
);

const CandidatStats = componentLoader(() =>
  import(
    /* webpackChunkName: "Candidat" */
    '../../components/Stats/Candidat'
  )
);

const MapStats = componentLoader(() =>
  import(
    /* webpackChunkName: "MapStats" */
    '../../components/Stats/Maps/index'
  )
);

const RechercheAjout = componentLoader(() =>
  import(
    /* webpackChunkName: "RechercheAjout" */
    '../../views/MoteurDeRecherche/RechercheAjout'
  )
);

const MapEquipe = componentLoader(() =>
  import(
    /* webpackChunkName: "MapEquipe" */
    '../../components/MapEquipe'
  )
);

export default [
  {
    path: '/artisan/list/needFacturier',
    component: ListFacturier,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/artisan/utilsAssets',
    component: UtilsAssets,
    restrictions: () => JUNIOR,
  },
  {
    path: '/artisan/list/:filter/:query',
    component: ListArtisan,
    restrictions: ({ service }) =>
      ({
        [S_PART]: NOT_CONFIRMED,
      }[service] || ADMIN),
  },
  {
    path: '/artisan/list/:filter',
    component: ListArtisan,
    restrictions: ({ service }) =>
      ({
        [S_PART]: NOT_CONFIRMED,
      }[service] || ADMIN),
  },
  {
    path: '/artisan/list',
    component: ListArtisan,
    restrictions: ({ service }) =>
      ({
        [S_PART]: NOT_CONFIRMED,
      }[service] || ADMIN),
  },
  {
    path: '/artisan/relance/:filter/:query',
    component: ListRelancesArtisan,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/artisan/:id/recap/:query',
    component: RecapArtisan,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/artisan/:id/recap',
    component: RecapArtisan,
    restrictions: () => USER,
  },
  {
    path: '/artisan/:id',
    component: AjoutArtisans,
    restrictions: ({ service }) =>
      ({
        [S_PART]: NOT_CONFIRMED,
        [S_JUR]: NOT_CONFIRMED,
      }[service] || ADMIN),
  },
  {
    path: '/artisan',
    component: AjoutArtisans,
    restrictions: ({ service }) =>
      ({
        [S_PART]: NOT_CONFIRMED,
        [S_JUR]: NOT_CONFIRMED,
      }[service] || ADMIN),
  },
  {
    path: '/candidat/config/table/:type',
    component: CandidatConfig,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/candidat/list/:filter/:query',
    component: ListCandidat,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/candidat/list/:filter',
    component: ListCandidat,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/candidat/list',
    component: ListCandidat,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/candidat/:id',
    component: AjoutCandidat,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/candidat',
    component: AjoutCandidat,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/partenariat/demarchMarket',
    component: MarketInterList,
    restrictions: () => USER,
  },
  {
    path: '/partenariat/market/:filter',
    component: MarketInterList,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/partenariat/etatreseau',
    component: EtatReseau,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/partenariat/etatreseau2',
    component: EtatReseau2,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/partenariat/commissions',
    component: CommissionsPART,
    restrictions: () => HEADCHIEF,
  },
  {
    path: '/partenariat/statistiques',
    component: PartenariatStats,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/partenariat/maps',
    component: MapStats,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/partenariat/artisanConnection',
    component: ArtisanConnection,
    restrictions: () => CHIEF,
  },
  {
    path: '/partenariat/mapEquipe',
    component: MapEquipe,
    restrictions: () => CHIEF,
  },
  {
    path: '/partenariat/candidat/statistiques',
    component: CandidatStats,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/prosp/list/:filter',
    component: RechercheAjout,
    restrictions: () => USER,
  },
  {
    path: '/prosp/list/',
    component: RechercheAjout,
    restrictions: () => USER,
  },
];
