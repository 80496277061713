import React from 'react';
import { connect } from 'react-redux';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { setDialogContentProp } from '../../actions/dialog';
import { data } from '@lba-dev/package.local-globals/natureSAV';

const setNature = setDialogContentProp('nature');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      nature
    }
  },
}) => ({
  nature
});


const mapDispatchToProps = {
  setNature
};

const PriseEnChargeDialogSAV = ({ nature, setNature }) =>
  <Grid container justifyContent="center" align="center">
    <Grid item xs={12}>
      <Button
        fullWidth
        children="Nature du SAV"
        color="secondary"
        variant="outlined"
      />
    </Grid>
    <Grid item xs={12}>
      <FormControl component="fieldset">
        <RadioGroup
          value={nature}
          onChange={(_, val) => setNature(Number(val))}
        >
          {data.map((e) =>
            <FormControlLabel
              key={e._id}
              value={e._id}
              label={e.name}
              control={<Radio />}
            />
          )}
        </RadioGroup>
      </FormControl>
    </Grid>
  </Grid>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriseEnChargeDialogSAV);
