import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

const Dropdown = ({
  title,
  onChange,
  isSelected = () => false,
  data,
  style,
  idKey = '_id',
  renderKey = 'name',
  renderFun,
  disabled = false,
  multiple = true,
  withSelectAll,
}) => {
  const isAllSelected =
    multiple &&
    data.length === data.filter((d) => isSelected(idKey ? d[idKey] : d)).length;
  const renderCustomKey = (d) => {
    let renderItem = d;
    if (renderFun) {
      renderItem = renderFun(d);
    } else if (renderKey && d[renderKey]) {
      renderItem = d[renderKey];
    }
    return renderItem || (renderKey && d[renderKey]) || d;
  };
  return (
    <Select
      style={style}
      multiple={multiple}
      value={[title]}
      renderValue={(selected) => selected}
      onChange={(e) => onChange(e)}
      disabled={disabled}
    >
      {multiple && withSelectAll && (
        <MenuItem value={!isAllSelected ? 'all' : 'notAll'}>
          <Checkbox checked={isAllSelected} />
          {isAllSelected ? 'Désélectionner' : 'Tout séléctionner'}
        </MenuItem>
      )}
      {data.map((d, i) => (
        <MenuItem
          key={i}
          value={idKey && d ? d[idKey] : d}
        >
          {multiple && <Checkbox checked={isSelected(idKey ? d[idKey] : d)} />}
          {renderCustomKey(d)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default Dropdown;
