import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import { ImageList as GridList } from '@mui/material';

import FullscreenFileDialog from '../Dialogs/FullscreenFileDialog';
import ImageTile from '../Utils/ImageTile';
import api from '../../api';
import notifSystem from '../../notifSystem';

const styles = {
  imageList: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: 200
  }
};

class ImageList extends Component {
  state = {
    dialogData: null,
    loading: false
  }

  container = React.createRef()

  setDialogData = (dialogData = null) => this.setState({ dialogData })

  sendStatus = (fileName, status) => {
    const { id, docType } = this.props;
    this.setState({ loading: true });
    api.artisans.custom(`changeDocStatus/${id}`)
      .post({ status, fileName, docType })
      .then(() => {
        if (this.props.getFile) {
          this.props.getFile();
        }
        this.setState({ loading: false });
        notifSystem.success('Opération réussi',
          'Le status a bien été mis à jour');
      });
  };

  render() {
    const { dialogData } = this.state;
    const {
      data,
      renderTile,
      handleDelete,
      selectedElems = [],
      isSelected,
      cols = this.container
        && this.container.current
        ? parseInt(this.container.current.offsetWidth / 400, 10)
        : 2,
      id,
      classes,
      ...props
    } = this.props;
    return (
      <div ref={this.container}>
        <GridList className={classes.imageList} {...props} cols={cols}>
          {data.map((elem, i) => (
            <ImageTile
              sendStatus={this.sendStatus}
              loading={this.state.loading}
              key={i}
              name={elem.name}
              content={elem.url}
              description={elem.description}
              renderTile={renderTile}
              status={elem.status}
              id={id}
              selected={
                typeof isSelected === 'function'
                  ? isSelected(elem, i)
                  : selectedElems.includes(elem.name)
              }
              handleDelete={handleDelete && (() => handleDelete(elem, i))}
              handleClick={() => this.setDialogData(elem)}
            />
          ))}
        </GridList>
        {!!dialogData && (
          <FullscreenFileDialog
            file={dialogData}
            close={() => this.setDialogData()}
          />
        )}
      </div>
    );
  }
}

export default withStyles(ImageList, styles);
