import React from 'react';
import { withStyles } from 'tss-react/mui';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Cancel } from '@mui/icons-material';
import red from '@mui/material/colors/red';
import { connect } from 'react-redux';

const styles = {
  avatar: {
    backgroundColor: red[100],
    color: red[600],
  },
};

const mapStateToProps = ({
  dialog: {
    contentProps: { inter = [] },
  },
}) => ({
  inter,
});

const DialogCheckList = ({ inter, classes }) => (
  <List>
    {(inter || []).map((e, i) => (
      <ListItem key={i}>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>
            <Cancel />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={e.name} />
      </ListItem>
    ))}
  </List>
);


export default connect(
  mapStateToProps
)(withStyles(DialogCheckList, styles));
