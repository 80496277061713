import {
  ADMIN,
  NOT_CONFIRMED,
  USER,
} from '@lba-dev/package.local-globals/permissions';

import { S_COMPTA, S_JUR } from '@lba-dev/package.local-globals/services';
import componentLoader from '../../componentLoader';

const ListWaitingPayment = componentLoader(() =>
  import(
    /* webpackChunkName: "ListWaitingPayment" */
    '../../views/Compta/ListWaitingPayment'
  )
);

const ListArchivesReglement = componentLoader(() =>
  import(
    /* webpackChunkName: "ListArchivesReglement" */
    '../../views/Compta/ListArchivesReglement'
  )
);

const ListArchivesAvoirs = componentLoader(() =>
  import(
    /* webpackChunkName: "ListArchivesAvoirs" */
    '../../views/Compta/ListArchivesAvoirs'
  )
);

const ListArchivesRemises = componentLoader(() =>
  import(
    /* webpackChunkName: "ListArchivesRemises" */
    '../../views/Compta/ListArchivesRemises'
  )
);

const ListAvoir = componentLoader(() =>
  import(
    /* webpackChunkName: "ListAvoir" */
    '../../views/Compta/ListAvoir'
  )
);

const ListRglmtNoOs = componentLoader(() =>
  import(
    /* webpackChunkName: "ListRglmtNoOs" */
    '../../views/Compta/ListRglmtNoOs'
  )
);

const ListRemiseCheque = componentLoader(() =>
  import(
    /* webpackChunkName: "ListRemiseCheque" */
    '../../views/Compta/ListRemiseCheque'
  )
);

const ListArchivesPaiements = componentLoader(() =>
  import(
    /* webpackChunkName: "ListArchivesPaiements" */
    '../../views/Compta/ListArchivesPaiements'
  )
);

const ListRemiseChq = componentLoader(() =>
  import(
    /* webpackChunkName: "ListRemiseChq" */
    '../../views/Compta/ListRemiseChq'
  )
);


const ListeInterventionApayer = componentLoader(() =>
  import(
    /* webpackChunkName: "ListeInterventionApayer" */
    '../../views/Compta/ListeInterventionApayer'
  )
);


const ListPaymentAnnexes = componentLoader(() =>
  import(
    /* webpackChunkName: "ListPaymentAnnexes" */
    '../../views/Compta/ListPaymentAnnexes'
  )
);


const StatPayAnn = componentLoader(() =>
  import(
    /* webpackChunkName: "StatPayAnn" */
    '../../views/Compta/StatPayAnn'
  )
);


const ImportEncaissementView = componentLoader(() =>
  import(
    /* webpackChunkName: "importencaissement" */
    '../../views/Compta/ImportEncaissementView'
  )
);


export default [
  {
    path: '/compta/lpa/:date',
    component: ListWaitingPayment,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/lpa',
    component: ListWaitingPayment,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/archivesReglement',
    component: ListArchivesReglement,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/archivesAvoirs',
    component: ListArchivesAvoirs,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/archivesRemises',
    component: ListArchivesRemises,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/listAvoir/:date',
    component: ListAvoir,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/listAvoir',
    component: ListAvoir,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/listRglmtNoOs',
    component: ListRglmtNoOs,
    restrictions: ({ service }) =>
      ({
        [S_COMPTA]: NOT_CONFIRMED,
        [S_JUR]: NOT_CONFIRMED,
      }[service] || ADMIN),
  },
  {
    path: '/compta/archivesPaiements',
    component: ListArchivesPaiements,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/remiseChq',
    component: ListRemiseChq,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/listRemiseCheque',
    component: ListRemiseCheque,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/listInterventionApayer',
    component: ListeInterventionApayer,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/relFour',
    component: ListeInterventionApayer,
    restrictions: () => USER,
  },
  {
    path: '/compta/listPaymentAnnexes/:filter',
    component: ListPaymentAnnexes,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/listPaymentAnnexes',
    component: ListPaymentAnnexes,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/statPaymentAnnexes',
    component: StatPayAnn,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/importencaissement',
    component: ImportEncaissementView,
    restrictions: () => NOT_CONFIRMED,
  },
];
