import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { withStyles } from 'tss-react/mui';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Cancel } from '@mui/icons-material';
import { red, blue } from '@mui/material/colors';
import { PropTypes } from 'prop-types';

const styles = {
  secondaryAvatar: {
    backgroundColor: red[100],
    color: red[600],
  },
  primaryAvatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

const DialogList = ({
  callback,
  open,
  defaultText: {
    Icon = Cancel,
    title,
    data = [],
    confirmBox,
    elementCallback = callback,
    color = 'secondary',
  },
  classes,
}) => (
  <Dialog onEscapeKeyDown={callback} open={open} maxWidth={'md'}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <List>
        {data.map((e, i) => (
          <ListItem key={i} onClick={() => elementCallback(e, i)}>
            <ListItemAvatar>
              <Avatar
                className={
                  color === 'primary'
                    ? classes.primaryAvatar
                    : classes.secondaryAvatar
                }
              >
                <Icon />
              </Avatar>
            </ListItemAvatar>
            {typeof e === 'string' ? <ListItemText primary={e} /> : e}
          </ListItem>
        ))}
      </List>
    </DialogContent>
    <DialogActions>
      {confirmBox && (
        <Button onClick={callback} color="primary" children={confirmBox} />
      )}
      <Button onClick={() => callback(false)} color="secondary">
        Annuler
      </Button>
    </DialogActions>
  </Dialog>
);

DialogList.propTypes = {
  callback: PropTypes.func.isRequired,
  defaultText: PropTypes.object,
  open: PropTypes.bool,
};

DialogList.defaultProps = {
  callback: (e) => e,
  defaultText: {
    title: 'List title',
    data: [],
    confirmBox: 'Valider',
  },
  open: true,
};

export default withStyles(DialogList, styles);
