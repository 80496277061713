import { connect } from 'react-redux';
import { moment } from '@lba-dev/package.local-globals/moment';
import { compose } from 'redux';

import { withAPISubscription } from '../../../hoc';
import { getSpecificKeys } from '../../Utils/manageData';

const DATE_AND_LOGIN_REGEX = /(?: le)? le \d.+/;

const mapStateToProps = (
  { users },
  { elem, devisHistories }
) => {
  const findUserLogin = (id) => {
    const user = users.find((u) => u._id === id);

    return user ? user.login : '';
  };

  return {
    users,
    findUserLogin,
    devisHistories: elem
      ? [
        { type: 'Ajouté', key: 'ajout' },
        { type: 'Transféré', key: 'transfer' },
        { type: 'Envoyé', key: 'envoi' },
        { type: 'Annulé', key: 'cancel', message: elem.cancelMsg },
        ...devisHistories.map((e) => ({
          type: e.text.replace(DATE_AND_LOGIN_REGEX, ''),
          user: e.userId || e.artisanId,
          date: e.date,
          obj: e.data,
        })),
      ]
        .filteredMap(
          ({
            type,
            key,
            date = elem.date[key],
            user = elem.login[key],
            sortingDate = date,
            obj = {},
            message,
          }) => {
            user = obj.isNotId ? user : findUserLogin(user);
            if (!user && elem.login.artisan) {
              user = 'automatique';
            }
            return ({
              type,
              sortingDate: new Date(sortingDate),
              date:
                moment(date).format(
                  `L ${
                    obj.clientHour
                      ? '[(heure à définir avec le client)]'
                      : '[à] HH:mm'
                  }`
                ),
              user,
              obj,
              message,
            });
          },
          (e) =>
            e && (e.date || elem.date[e.key]) && (e.user || elem.login[e.key])
        )
        .sortBy('sortingDate')
      : [],
  };
};

const fetch = (api, { elem, name }) => {
  const { history } = getSpecificKeys({ name });
  const promies = [
    { name: 'devisHistories',
      promise: api.history.getAll({
        name: history.name,
        id: elem[history.id || 'id'],
      })
    },
  ];
  return Promise.all(
    promies.reduce(
      (acc, curr) => (curr.promise ? acc.concat(curr.promise) : acc),
      []
    )
  ).then((results) =>
    results.reduce(
      (v, res, index) => ({
        ...v,
        [promies[index].name]: res.body().map((e) => e.data()),
      }),
      {}
    )
  );
};

const shouldReload = (props, prevProps) => props.elem.id !== prevProps.elem.id;

export default compose(
  withAPISubscription(fetch, null, {
    default: {
      devisHistories: [],
    },
    deverse: true,
    shouldReload,
  }),
  connect(mapStateToProps)
);
