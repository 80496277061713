import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import {
  Avatar, Chip,
  ListItemAvatar, ListItemText, MenuItem,
  Paper, Typography
} from '@mui/material';
import { Box } from '@mui/system';
import AutoSuggestInput from '../../../../Utils/AutoSuggestInput';
import { DLUSER } from '@lba-dev/package.local-globals/docTypes';
import { services } from '@lba-dev/package.local-globals/getDataById';
import { data as serviceData } from '@lba-dev/package.local-globals/services';
import { stringAvatar } from '../../../constant';
import TagInput from './TagInput';
import InputActions from './InputActions';
import AdornmentActions from './AdornmentActions';

const REGEXTAG = new RegExp(/(^|\s)(@([a-zA-Z]+)?)/g);

const getUserString = (suggestion) => {
  const service = suggestion && (services[suggestion.service] || {}).name;
  const name = `${suggestion.alias.capitalize()}${
    service ? ` | ${service.capitalize()}` : ''}`;
  return name;
};

const renderSuggestion = (suggestion) => {
  const image = suggestion?.dropbox?.find(e => e.type === DLUSER)?.link;
  const name = getUserString(suggestion);
  const avatarProps = stringAvatar(name.replace('|', ''), name.length);

  return (
    <MenuItem>
      <ListItemAvatar>
        <Avatar
          src={image || ''}
          alt="avatar"
          {...avatarProps}
          sx={{
            ...avatarProps.sx,
            bgcolor: image ? '' : avatarProps.sx.bgcolor,
          }}
        />
      </ListItemAvatar>
      <ListItemText primary={<>{
        name
      }<Typography variant='caption'
        sx={{ textTransform: 'capitalize' }}
        children={` - ${suggestion.login}`} /></>} />
    </MenuItem>
  );
};

const renderSuggestionsContainer = ({
  classes, containerProps, children
}, selectService, matched) => {
  const text = (matched || '').replace('@', '').toLowerCase();
  return <Paper {...containerProps}
    className={children ? classes.containerPaper : {}}
    square
  >
    {children}
    {children ? <div className={classes.serviceDiv}>
      {serviceData.filter(e =>
        e.color && (!text || e.name.toLowerCase().includes(text))
      ).map(e =>
        <Chip
          key={e._id}
          label={e.name}
          variant='outlined'
          onClick={() => selectService(e._id)}
          size="small"
          style={{
            borderColor: e.color[500],
            color: e.color[500],
            margin: 4,
            marginRight: 2
          }}
        />)}
    </div> : null}
  </Paper>;
};

const InputChat = (props) => {
  const {
    users, classes, value, setInput, audioFile,
    handleAudio, handleFile
  } = props;
  const [matched, setMatched] = useState('');
  const getSuggestionValue = (suggestion) => {
    const name = getUserString(suggestion);
    const nVal = value.replace(matched,
      `^#${name};${suggestion._id}#^`
    );
    return nVal;
  };

  const selectService = (sId) => {
    const name = services[sId].name;
    const nVal = value.replace(matched,
      `^#${name};${sId}#^`
    );
    setInput(nVal);
  };


  const handleAudioFile = (data) => {
    setInput('');
    handleFile(null);
    handleAudio(data);
  };

  const getSuggestions = (value) => {
    const inputValue = (value || '').trim().toLowerCase();
    const matchTagReg = REGEXTAG.exec(inputValue) || [];
    const fullTag = matchTagReg[2] || '';
    const tagName = (matchTagReg[3] || '').trim();
    const data = users.toJS();
    const filtered = data.filter((e) => {
      const serviceName = (services[e.service] || {}).name;
      return e.act &&
      (e.alias.toLowerCase().includes(tagName) ||
      serviceName.toLowerCase().includes(tagName) ||
      e.login.toLowerCase().includes(tagName));
    });
    if (fullTag) {
      setMatched(fullTag);
      return tagName ? filtered : data.filter(e => e.act);
    }
    return [];
  };
  return (
    <Box className={classes.inputBox}>
      <AutoSuggestInput
        getSuggestions={getSuggestions}
        getSuggestionValue={getSuggestionValue}
        classes={classes}
        onChange={setInput}
        renderSuggestion={renderSuggestion}
        value={value}
        focusInputOnSuggestionClick={true}
        renderSuggestionsContainer={(props) =>
          renderSuggestionsContainer(
            { ...props, classes }, selectService, matched
          )}
        renderInputComponent={(oProps) => <TextField
          {...oProps}
          multiline
          variant="standard"
          fullWidth
          className={classes.textField}
          placeholder='Message...'
          rows={2}
          maxRows={2}
          InputProps={{
            inputComponent: TagInput,
            disableUnderline: true,
            disabled: !!audioFile,
            classes: { input: classes.input },
            endAdornment: <AdornmentActions
              {...props}
              handleAudioFile={handleAudioFile}
            />
          }}
        />}
      />
      <InputActions {...props} />
    </Box>
  );
};

export default InputChat;
