import {
  Checkbox,
  List, ListItem, ListItemButton, ListItemText, ListSubheader
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/system';
import React from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../actions/dialog';

const setCheckedFields = setDialogContentProp('checkedFields');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      data,
      fields,
      checkedFields,
    }
  }
}) => ({
  data,
  fields,
  checkedFields,
});

const mapDispatchToProps = {
  setCheckedFields
};

const useStyles = makeStyles()({
  itemPadding: { paddingTop: 0, paddingBottom: 0 },
  selectALl: {
    paddingTop: 0,
    paddingBottom: 0,
    '& span': {
      marginRight: 6,
    }
  }
});

const DupliqItemsDialog = ({
  data, fields, checkedFields, setCheckedFields
}) => {
  const { classes } = useStyles();
  const handleFields = (value) => {
    const nSelected = [...checkedFields].toggleValue(value);
    setCheckedFields(nSelected);
  };

  const handleSelectAll = () => {
    const checked = checkedFields.length === fields.length;
    setCheckedFields(checked ? [] : fields);
  };

  return <Box>
    <List>
      <ListItem
        className={classes.itemPadding}
        disablePadding
      >
        <ListItemButton
          divider disableGutters
          className={classes.selectALl}
          onClick={handleSelectAll}>
          <ListItemText primary={checkedFields.length !== fields.length ?
            'Tout séléctionner' : 'Désélectionner'} />
          <Checkbox
            edge="end"
            checked={checkedFields.length === fields.length}
            disableRipple
          />
        </ListItemButton>
      </ListItem>
      {data.map((e, i) => {
        const labelId = `checkbox-list-secondary-label-${i}`;
        if (e.title) {
          return <ListSubheader key={i} children={e.title} />;
        }
        return <ListItem
          key={i}
          className={classes.itemPadding}
          disablePadding
        >
          <ListItemButton
            className={classes.itemPadding}
            onClick={() => handleFields(e.key)}>
            <ListItemText id={labelId} primary={`- ${e.name}`} />
            <Checkbox
              edge="end"
              checked={checkedFields?.includes(e.key)}
              inputProps={{ 'aria-labelledby': labelId }}
              disableRipple
            />
          </ListItemButton>
        </ListItem>;
      })}
    </List>
  </Box>;

};

export default connect(mapStateToProps, mapDispatchToProps)(DupliqItemsDialog);
