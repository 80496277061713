import React from 'react';
import { withStyles } from 'tss-react/mui';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
const styles = {
  Mobile: {
    padding: '0px 20px'
  }
};

const MoveInMaps = ({ move, classes, updateState }) => (
  <FormControlLabel
    className={classes.Mobile}
    control={
      <Checkbox
        onChange={() =>
          updateState('moveInMaps', !move)}
        checked={move}
        color="primary"
        value="moveInMaps"
      />
    } label="Mettre à jour les résulats lors du déplacement de la carte" />);


export default withStyles(MoveInMaps, styles);
