import React from 'react';
import { connect } from 'react-redux';
import {
  AllInbox,
  Mail,
  NotificationImportant,
  Sms,
} from '@mui/icons-material';
import { withStyles } from 'tss-react/mui';
import styles from '../components/UserNotifications/styles';

import api from '../api';
import store from '../store';
import { fetch as fetchNotifications } from '../actions/notifications';
import notifSystem from '../notifSystem';
import NotificationList from '../components/UserNotifications/NotificationList';

const ICON_BY_TYPE = {
  mail: <AllInbox />,
  email: <Mail />,
  internal: <NotificationImportant />,
  sms: <Sms />,
};

const notificationsNotif = {
  notUpdated: () =>
    notifSystem.error(
      'Notification',
      'Les notifications n\'ont pas pu être mises à jour'
    ),
  notDeleted: (multiple = false) =>
    notifSystem.error(
      'Notification',
      multiple
        ? 'Les notifications n\'ont pas pu être supprimées'
        : 'La notification n\'a pas pu être supprimée'
    ),
};

const mapStateToProps = (state, { type }) => {
  const isMultipleType = type instanceof Array;
  return {
    notifications: state.notifications
      .filter((n) => (isMultipleType ? type.includes(n.type) : n.type === type))
      .sortBy((n) => n.date)
      .reverse(),
    icon: ICON_BY_TYPE[isMultipleType ? type[0] : type],
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateNotifications: (notificationIds) => {
    api
      .one('notifications', store.getState().userId)
      .custom('saw')
      .patch(notificationIds)
      .then(() => {
        const notifications = store.getState().notifications.map((n) => ({
          ...n,
          seen: notificationIds.indexOf(n._id) !== -1 ? true : n.seen,
        }));
        dispatch(fetchNotifications(notifications));
      })
      .catch(() => notificationsNotif.notUpdated());
  },
  deleteNotifications: (notificationIds = []) => {
    api
      .all('notifications')
      .custom('delete')
      .delete(notificationIds)
      .catch(() => notificationsNotif.notDeleted(notificationIds.length));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(NotificationList, styles)
);
