import React from 'react';
import { withStyles } from 'tss-react/mui';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Delete } from '@mui/icons-material';
import classnames from 'classnames';

const styles = {
  cardCon: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexDiv: {
    display: 'flex',
  },
  button: {
    marginLeft: 12,
    height: 30,
  },
  Rib: {
    marginRight: 12,
  },
  AccessMapArtisan: {
    color: 'dodgerblue',
    textDecoration: 'underline',
  },
  center: {
    textAlign: 'center',
    marginBottom: 15,
  },
};

const getColor = verifRIB => verifRIB ? green[500] : red[500];

const Header = ({
  data,
  checked,
  deleteCheckTab,
  verifRIB,
  permissions,
  isTable,
  classes,
  title
}) => {
  const addressArtisan = !isTable && data.get('billingAddress');
  return (
    <CardContent>
      {!isTable && (
        <div>
          <Typography variant="h5" className={classes.center}>
            {`${data.get('name')} ${data.get('firstname')} - ${data.get(
              'companyName'
            )}`}
          </Typography>
          <Typography
            variant="body1"
            className={classnames(classes.center, classes.AccessMapArtisan)}
          >
            {`${addressArtisan.get('number')} ${addressArtisan.get('road')}
        , ${addressArtisan.get('zipcode')} ${addressArtisan.get('city')}`}
          </Typography>
        </div>
      )}
      <div className={classes.cardCon}>
        <Typography variant="h5" children={title} />
        <div className={classes.flexDiv}>
          <Button
            style={{
              color: getColor(verifRIB),
              borderColor: getColor(verifRIB),
            }}
            className={classes.Rib}
            color="secondary"
            variant="outlined"
            children={`RIB ${verifRIB ? 'Validé' : 'Non validé'}`}
          />
          {!!checked.length && permissions && (
            <Fab
              size="small"
              onClick={deleteCheckTab}
              className={classes.button}
              color="secondary"
              children={<Delete />}
            />
          )}
        </div>
      </div>
    </CardContent>
  );
};
export default withStyles(Header, styles);
