export const styles = {
  paper: {
    marginBottom: 20,
    padding: 25
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 3
  }
};
