import React from 'react';
import { AS_INDSP } from '@lba-dev/package.local-globals/aSubStatus';
import { call } from '../utils/call';
import Typography from '@mui/material/Typography';
import getDataById from '@lba-dev/package.local-globals/getDataById';
import { A_ARC } from '@lba-dev/package.local-globals/aStatus';
import { getByKey } from '@lba-dev/package.local-globals/aSecurity';
import { NOT_QUA } from '@lba-dev/package.local-globals/aLevel';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
const ItemComponent = ({ element, status }) => (
  <Tooltip disableInteractive
    title="Appeler" onClick={() => call(element.getIn(['obj', 'tel1']))}>
    <Grid container spacing={2}>
      <Grid
        item
        xs={4}
        children={
          <Typography variant="subtitle1">
            {`${element.getIn(['obj', 'title'])} ${Math.round(
              element.getIn(['dis'])
            )} km`}
          </Typography>
        }
      />
      <Grid
        item
        xs={4}
        children={
          <Typography
            variant="subtitle2"
            style={{
              color: status.color['500'],
              textTransform: 'capitalize',
            }}
            children={status.name}
          />
        }
      />
    </Grid>
  </Tooltip>
);

export default (marklist, appels) =>
  marklist.reduce((v, e) => {
    if (
      Math.round(e.get('dis')) <= 30 &&
      e.getIn(['obj', 'subStatus']) !== AS_INDSP &&
      e.getIn(['obj', 'level']) === NOT_QUA &&
      !appels.includes(e.getIn(['obj', '_id'])) &&
      !getByKey({ artisan: e.getIn(['obj']).toJS() })
    ) {
      const status =
        e.getIn(['obj', 'status']) === A_ARC
          ? getDataById.artisanAStatus[e.getIn(['obj', 'status'])]
          : getDataById.artisanSubStatus[e.getIn(['obj', 'subStatus'])];
      v.push(<ItemComponent element={e} status={status} />);
    }
    return v;
  }, []);
