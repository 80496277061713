import { DBANQ } from '@lba-dev/package.local-globals/docTypes';
import { DOCVAL } from '@lba-dev/package.local-globals/docStatus';
import { A_ARC } from '@lba-dev/package.local-globals/aStatus';
import { AS_INDSP } from '@lba-dev/package.local-globals/aSubStatus';
import notifSystem from '../notifSystem';
import { isValidIBAN, isValidBIC } from 'ibantools';

export const checkBankInfo = (merge, current) => {
  const { newIban, newBic, dropbox = [] } = current.toJS();
  const currentNewIban = newIban || newIban === '';
  const currentNewBic = newBic || newBic === '';
  const dropboxType = e => e.get('type') === DBANQ
    && e.get('status') === DOCVAL;
  const ribDoc = merge
    .get('dropbox', dropbox)
    .some(dropboxType);
  const globalNewIban = current.get('newIban', merge.get('iban', ''));
  const globalNewBic = current.get('newBic', merge.get('bic', ''));
  const globalDropbox = current.get('dropbox', merge.get('dropbox'))?.toJS();
  if ((newIban === '' || !newIban)
  && (newBic === '' || !newBic)
  && !dropbox.length) {
    return true;
  }
  else if (
    currentNewIban ||
    currentNewBic ||
     !globalDropbox.length ||
     (ribDoc &&
       !merge.get('newIban') &&
       !merge.get('newBic') &&
       !merge.get('iban') &&
       !merge.get('bic'))
  ) {
    if (
      !isValidIBAN(globalNewIban.toUpperCase())
      || !isValidBIC(globalNewBic.toUpperCase())
       || !globalDropbox.length
      || !ribDoc
    ) {
      notifSystem.error(
        'Erreur',
        'Les informations bancaires sont incomplètes'
      );
      return false;
    }
    return true;
  }
  return true;
};


export const checkForAppelResultDialog = (artisans, target) => {
  const actualValue = artisans.find(e =>
    e.getIn(['obj', '_id']) === target);
  return actualValue && (actualValue.getIn(['obj', 'status']) === A_ARC ||
    actualValue.getIn(['obj', 'subStatus']) === AS_INDSP);
};
