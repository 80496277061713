import React from 'react';
import { List } from 'immutable';

import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import grey from '@mui/material/colors/grey';
import { Close } from '@mui/icons-material';


export const mapStateToProps = (state, ownProps) => {
  const users = ownProps.users
    ? new List(ownProps.users)
    : state.users.filter(u => u.act);
  const {
    classes,
    selectedUsers,
    selectedServices,
    handleChange
  } = ownProps;

  const changeUser = user => {
    const newUsers = selectedUsers.slice(0);
    const services = selectedServices.slice(0);
    const { service } = users.find(u => u._id === user);
    const index = newUsers.findIndex(u => u === user);
    const serviceIndex = services.findIndex(s => s === service);

    if (~index) {
      newUsers.splice(index, 1);
      if (~serviceIndex) {
        services.splice(serviceIndex, 1);
      }
    } else {
      newUsers.push(user);
      if (!~serviceIndex && users.every(u =>
        !(u.service === service && !newUsers.includes(u._id))
      )) {
        services.push(service);
      }
    }
    return {
      userIds: newUsers,
      services
    };
  };

  const changeService = service => {
    const services = selectedServices.slice(0);
    const serviceUsers = users.reduce((a, v) => {
      if (v.service === service) {
        a.push(v._id);
      }
      return a;
    }, []);
    const index = services.findIndex(s => s === service);

    if (~index) {
      services.splice(index, 1);
      return {
        userIds: selectedUsers.filter(u => !serviceUsers.includes(u)),
        services
      };
    }
    services.push(service);
    return {
      userIds: serviceUsers.reduce((a, v) =>
        selectedUsers.includes(v) ? a : a.concat(v),
      selectedUsers),
      services
    };
  };

  const selectUserOrService = value => {
    if (isNaN(value)) {
      return handleChange(changeUser(value));
    }
    handleChange(changeService(value));
  };

  const generateUserList = service => {
    const serviceUsers = users.filter(u => u.act && u.service === service._id);

    return serviceUsers.size ?
      [<MenuItem
        style={{ backgroundColor: grey[500] }}
        key={service._id}
        value={service._id}
        children={service.name}
      />,
      serviceUsers.map(u =>
        <MenuItem
          key={u._id}
          value={u._id}
          children={u.login}
        />
      )] : null;
  };

  const generateChips = (selectedItems, items, color) =>
    items.reduce((a, v) => {
      const handleClick = () => selectUserOrService(v._id);

      if (selectedItems.includes(v._id)) {
        a.push(<Chip
          className={classes.chip}
          color={color}
          key={v._id}
          label={v.login || v.name}
          onClick={handleClick}
          onDelete={handleClick}
          deleteIcon={<Close />}
        />);
      }
      return a;
    }, []);

  return {
    ...ownProps,
    users,
    selectUserOrService,
    generateUserList,
    generateChips
  };
};
