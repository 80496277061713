/* eslint-disable max-lines */
import api from '../api';
import notifSystem from '../notifSystem';
import {
  getMontant,
  paymentDefault,
  getArtisanRemun,
  setFourniture,
  getSpecificPrice,
} from '@lba-dev/package.local-globals/comptaCalcules';
import { P_PAY, P_ENC } from '@lba-dev/package.local-globals/paidStatus';
import { PE_ENC } from '@lba-dev/package.local-globals/paidStatusEncaissements';

import { E_DEC } from '@lba-dev/package.local-globals/encaissementType';
import RelanceComptaDialog from '../components/Dialogs/RelanceComptaDialog';
import { defaultMail } from '@lba-dev/package.local-globals/relanceMailCompta';
import ReglementDialog from '../components/Dialogs/ReglementDialog';
import ConfirmBox from '../components/Dialogs/ConfirmBox';
import comptaReglemAlerts from '../utils/comptaReglemAlerts';
import { setDialog } from './dialog';
import comptaFilters from '../components/ArtisanPayements/ComptaFilters';
import { fromJS } from 'immutable';
import { objectDialog, confirm } from '../utils/DialogsCheck';
import { E_TRA } from '@lba-dev/package.local-globals/encaissementMode';
import { getLogin } from '../utils/function';

export const mapDispatchToProps = {
  setConnectedDialog: setDialog,
};

const artisanProject = {
  companyName: 1,
  status: 1,
  subStatus: 1,
  login: 1,
  firstname: 1,
  name: 1,
  date: 1,
  legalForm: 1,
  remuneration: 1,
  tel1: 1,
  tel2: 1,
  tel3: 1,
  email1: 1,
  email2: 1,
  id: 1,
  level: 1,
};
const interventionsProject = {
  supplies: 1,
  finalPrice: 1,
  priceArtisan: 1,
  id: 1,
  paid: 1,
  login: 1,
  artisan: 1,
  date: 1,
  comments: 1,
  recovery: 1,
  currentSAVStatus: 1,
  isRegle: 1,
  client: 1,
  infoDesc: 1,
  categorie: 1,
  remunerationArtisan: 1,
  dropbox: 1,
  encaissement: 1,
  isCaldeo: 1,
  domofinance: 1
};

const savInterventionsProject = {
  supplies: 1,
  finalPrice: 1,
  priceArtisan: 1,
  id: 1,
  paid: 1,
  login: 1,
  artisan: 1,
  date: 1,
  comments: 1,
  recovery: 1,
  currentSAVStatus: 1,
  isRegle: 1,
  client: 1,
  infoDesc: 1,
  categorie: 1,
  remunerationArtisan: 1,
  dropbox: 1,
  encaissement: 1,
  infoDescSAV: 1,
  interId: 1,
  isCaldeo: 1,
  domofinance: 1
};


const toProject = {
  interventions: interventionsProject,
  savInterventions: savInterventionsProject,
};

export const getListEncaissement = async ({
  endAt,
  startAt,
  mode,
  isArchive,
  type,
  motif,
}) => {
  try {
    const query = {
      isArchive: !!isArchive,
      type,
      mode,
    };
    if (type === E_DEC) {
      query.motif = { $in: motif };
    }
    query.encaissement = {
      $gte: startAt,
      $lte: endAt,
    };
    if (mode === E_TRA) {
      delete query.type;
      delete query.motif;
    }
    return (
      await api.encaissements.getAll({
        query: JSON.stringify(query),
      })
    )
      .body()
      .map((e) => e.data());
  } catch (e) {
    notifSystem.error(e.name, e.message);
    return [];
  }
};

export const getPayement = ({ _id, artisan }) =>
  new Promise((r) =>
    Promise.all([
      api.paiements.getAll({
        query: JSON.stringify({ _id: _id, status: P_PAY }),
      }),
      api.artisans.getAll({
        query: JSON.stringify({ _id: artisan }),
        field: JSON.stringify({
          remuneration: 1,
          verifRIB: 1,
        }),
        limit: 1,
      }),
    ])
      .then(([paiements, artisan]) =>
        r([
          paiements.body().map((e) => e.data()),
          artisan.body().map((e) => e.data()),
        ])
      )
      .catch((e) => notifSystem.error(e.name, e.message))
  );

export const addPayement = (
  paiement,
  paiements,
  {
    artisan,
    id,
    remunerationArtisan,
    currentArtisan: {
      remuneration: { status, pourcentage },
    },
  },
  metode
) =>
  api.paiements[metode]({
    ...paiement,
    ...(paiements.length
      ? {
        firstPayementDate: paiements[0].flushDate,
        lastPayementDate: paiements[paiements.length - 1].flushDate,
      }
      : {}),
    ...(remunerationArtisan || status
      ? {
        remuneration: remunerationArtisan || { ...pourcentage, status },
      }
      : {}),
    ...(paiements.length > 1
      ? {
        lastPayment: Math.trunc(
          getMontant(paiements[paiements.length - 1] || 0)
        ),
      }
      : {}),
    artisanId: artisan,
    id: id,
  });

export const getArtisans = (filter) =>
  new Promise((r) => {
    api.artisans
      .getAll({
        query: JSON.stringify(filter),
        field: JSON.stringify(artisanProject),
      })
      .then((artisans) => {
        r(artisans.body().map((e) => e.data()));
      })
      .catch((e) => notifSystem.error(e.name, e.message));
  });

export const getInterToPaye = (filter, collection) => {
  const params = window.location.pathname.split('/');
  const a = params[params.length - 1];
  const query = comptaFilters(collection)[a].match;
  return api[collection]
    .getAll({
      query: JSON.stringify({
        ...query,
        ...filter,
      }),
      field: JSON.stringify(toProject[collection]),
    })
    .then((collection) => collection.body().map((e) => e.data()))
    .catch((e) => {
      notifSystem.error(e.name, e.message);
      throw e;
    });
};

export const prendreEnCharge = (props, { _id, userId, collection }) => {
  const params = window.location.pathname.split('/');
  const a = params[params.length - 1];
  const paidStatus = comptaFilters(collection)[a].paidStatus;
  return api.paiements
    .custom('prendreEnCharge')
    .post({
      artisanId: _id,
      userId,
      paidStatus,
      collection
    })
    .then(() =>
      notifSystem.success('Message', 'L\'artisan a ete pris en charge')
    )
    .catch((e) => notifSystem.error(e.name, e.message));
};
export const deletePayement = ({ id }) =>
  api.paiements
    .delete(id, {
      status: { $ne: P_PAY },
    })
    .then(() =>
      notifSystem.success('Message', 'Le paiement a bien été dévalidé')
    )
    .catch(() =>
      notifSystem.error('Erreur', 'Le paiement n\'a pas été dévalidé')
    );

export const validePayement = (inter, status = P_ENC) =>
  new Promise((resolve, reject) =>
    getPayement(inter)
      .then(([paiements, [artisan]]) => {
        const remunerationArtisan = getArtisanRemun(inter, artisan);
        const paiementEnCours = paiements.find((e) => e.status === P_ENC);
        const paiement = paymentDefault({
          rem: remunerationArtisan,
          bp: getSpecificPrice({ remunerationArtisan, ...inter }),
          f: setFourniture(inter.supplies || []),
          legacy: paiements.reduce((t, p) =>
            t + getMontant(p) + p.legacy, 0) * -1,
          status,
          finalPrice: inter.finalPrice,
          categorie: inter.categorie,
          loginAjoutIntervention: inter.login.ajout
        });
        if (paiementEnCours) {
          paiement._id = paiementEnCours._id;
        }
        addPayement(
          paiement,
          paiements,
          inter,
          paiementEnCours ? 'patch' : 'post'
        )
          .then(() =>
            resolve(
              notifSystem.success('Message', 'Le paiement a bien été validé')
            )
          )
          .catch((e) => reject(e));
      })
      .catch((e) => reject(e))
  ).catch(() =>
    notifSystem.error('Erreur', 'Le paiement n\'a pas été ajouté')
  );

export const relanceDialog = (props, elem, status) => {
  const { body, subject, comment, filter } = defaultMail(status, elem);
  const interventions = props.artisanInter.filter(filter);
  if (interventions.length) {
    props.setDialog(
      RelanceComptaDialog,
      true,
      {
        title: 'Relance Mail',
        body,
        subject,
        interventions,
      },
      (v, newBody, newSubject, checked) => {
        if (v) {
          api.paiements
            .custom('relanceMail')
            .post({
              body: newBody,
              subject: newSubject,
              data: interventions.filter((e) => checked.includes(e._id)),
              artisan: {
                email1: elem.email1,
                email2: elem.email2,
                subStatus: elem.subStatus,
              },
              comment,
              type: status,
              collection: props.collection
            })
            .then(() =>
              notifSystem.success('Message', 'le mail a bien été envoyé')
            )
            .catch((e) => notifSystem.error(e.name, e.message));
        }
        return props.setDialog(null, false, null, null);
      }
    );
  } else {
    notifSystem.error('Erreur', 'Aucunes interventions trouvées à relancer');
  }
};

export const addEncaissement = async (
  lines,
  userId,
  data,
  mailRefund = false
) => {
  try {
    let promises = [];
    lines.forEach((line) => {
      if (line._id) {
        promises.push(
          () => api.encaissements.patch(line._id, {
            encaissement: line.encaissement,
            id: line.id,
            mode: line.mode,
            montant: line.montant,
            multis: line.multis,
            numChq: line.numChq,
            tva: line.tva,
            userId: line.userId,
            type: line.type,
            motif: line.motif,
            billing: data.get('billing', line.billing) || undefined,
          })
        );
      } else {
        line.userId = userId;
        line.billing = data.get('billing');
        promises.push(() => api.encaissements.post({ ...line, mailRefund }));
      }
    });
    await promises.asyncForEach(async e => {
      await e();
    });
    return true;
  } catch (error) {
    notifSystem.error('Erreur', 'L\'encaissement n\'a pas été enregistré');
    return Promise.reject(error);
  }
};

export const saveInterReglement = async ({ data }) => {
  try {
    await api[data.get('interId') ? 'savInterventions' : 'interventions'].patch(
      data.get('_id'),
      {
        supplies: data.get('supplies') || [],
      }
    );
    return true;
  } catch (error) {
    notifSystem.error('Erreur', 'Les fournitures n\'ont pas été enregistrées');
    throw Error;
  }
};

export const saveInterPaiement = async (data) => {
  try {
    await api[
      data.interId ? 'savInterventions' : 'interventions'
    ].patch(data._id, { supplies: data.supplies || [] });
    return true;
  } catch (error) {
    notifSystem.error('Erreur', 'Les fournitures n\'ont pas été enregistrées');
    throw Error;
  }
};

export const reglementCallback = async ({
  encaissements,
  data,
  userId,
  setDialog,
  mailRefund,
}) => {
  await addEncaissement(encaissements, userId, data, mailRefund)
    .then(() =>
      saveInterReglement({ data })
    ).then(() => {
      setDialog(null, false, {}, null);
      notifSystem.success('Message', 'Informations sauvegardées');
    });
};

export const checkMontant = ({
  selected,
  artisan,
  setDialog,
  obj,
  reglementDialog,
}) => {
  const check = comptaReglemAlerts(obj);
  if (check) {
    return new Promise((res) =>
      setDialog(
        ConfirmBox,
        true,
        {
          title: 'Message',
          subtitle: check.msg,
          confirmBox: 'OUI',
          closeBox: 'MODIFIER',
        },
        (confirm) =>
          confirm
            ? res(reglementCallback({ setDialog, ...obj, artisan }))
            : res(reglementDialog({ setDialog, selected, artisan }))
      )
    );
  }
  return reglementCallback({ setDialog, ...obj, artisan });
};

export const reglementDialog = ({ setDialog, selected, artisan }) =>
  setDialog(ReglementDialog, true, { data: selected, artisan }, (obj) =>
    typeof obj === 'object'
      ? checkMontant({
        selected,
        artisan,
        setDialog,
        obj,
        reglementDialog,
      })
      : setDialog(null, false, {}, null)
  );

export const suppliesDialog = (props, elem) => {
  const defaultText = objectDialog[confirm]({
    setDialog: props.setConnectedDialog,
    cb: async (p, close) => {
      await saveInterPaiement(elem);
      notifSystem.success('Message', 'Informations sauvegardées');
      close();
    },
  });
  props.setConnectedDialog({
    name: 'SuppliesDialog',
    open: true,
    dialogProps: {
      title: 'Ajouter des Fournitures',
      maxWidth: 'lg',
    },
    contentProps: {
      original: fromJS(elem),
      current: elem,
    },
    actions: [
      {
        children: 'Valider',
        onClick: () => defaultText.callBack(),
      },
    ],
  });
};

export const updateReglement = ({ _id, status, numChq, expiry }) =>
  api.encaissements
    .patch(_id, {
      ...(status ? { paidStatus: status } : {}),
      ...(numChq ? { numChq } : {}),
      ...(expiry ? { cardInfo: { expiry: expiry } } : {}),
    })
    .catch((e) => notifSystem.error('Erreur', e.message));

export const getEncaissementsByType = async ({
  type,
  date,
  paidStatus = PE_ENC
}) =>
{
  const res = await api.encaissements.custom('getByType').get({
    type,
    date: date ? new Date(date).getTime() : '',
    paidStatus
  });
  let data = [];
  res
    .body()
    .data()
    .result.map((e) => {
      e.reglements.map((i) => {
        i.loginUser = getLogin(i?.userId);
        data.push(i);
      });
      return e;
    });
  return data;
};
