import { permit } from '@lba-dev/package.local-globals/restrictions';
import store from '../store';

export const aVerifierPoint = (users, userId, points) => {
  const state = store.getState();
  users = users || state.users;
  userId = userId || state.userId;
  points = points || state.points;
  const user = users.find((e) => e._id === userId);
  const point = points.find((e) => e._id === userId);
  return {
    point: Math.trunc(
      point && point.totalDifference ? point.totalDifference : 0
    ),
    condition:
      user.maxV &&
      point &&
      point.totalDifference >= user.maxV &&
      !permit(user, { key: 'addIntervention' }),
    maxV: user.maxV,
  };
};
