import {
  USER
} from '@lba-dev/package.local-globals/permissions';
import componentLoader from '../../componentLoader';

export const ListSAVIntervention = componentLoader(() =>
  import(
    /* webpackChunkName: "ListSAVIntervention" */
    '../../views/List/ListSAVIntervention'
  )
);

export const DisplaySavIntervention = componentLoader(() =>
  import(
    /* webpackChunkName: "DisplaySavIntervention" */
    '../../views/DisplaySavIntervention'
  )
);

export default [
  {
    path: '/savIntervention/list/:filter/:query',
    component: ListSAVIntervention,
    restrictions: () => USER,
  },
  {
    path: '/savIntervention/list/:filter',
    component: ListSAVIntervention,
    restrictions: () => USER,
  },
  {
    path: '/savIntervention/:id',
    component: DisplaySavIntervention,
    restrictions: () => USER,
  },
];
