import React from 'react';

import {
  Badge, Box, IconButton, List, ListItem, Tooltip
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { permit } from '@lba-dev/package.local-globals/restrictions';

import { smsTypes } from './constant';
import whiteLogo from '@public/whiteLogo.png';
import { makeStyles } from 'tss-react/mui';
import ChatButton from '../Dashboard/Menubar/ChatButton';
import { ArrowForward } from '@mui/icons-material';
import { keyframes } from '@emotion/react';

const blinkerAnimation = keyframes`
  50% { opacity: 0 }
`;

const useStyles = makeStyles()((theme, { isDash }) => ({
  typeContainer: {
    [isDash ? 'borderLeft' : 'borderRight']: '1px rgba(0, 0, 0, 0.12) solid',
    backgroundColor: theme.palette.sideBar,
    display: 'flex',
    flexDirection: 'column',
  },
  typeLogoBox: {
    padding: 8,
    display: 'flex',
    justifyContent: 'center',
    height: 35
  },
  bottomBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  typeButton: {
    padding: '4px 8px',
    borderRadius: 4,
    margin: '8px 0',
    cursor: 'pointer'
  },
  typeList: {
    paddingRight: 8,
    paddingLeft: 8,
  },
  blinkBadge: {
    animation: `${blinkerAnimation} 1.5s linear infinite`
  },
  closeIcon: {
    color: '#ddd'
  }
}));

const SMSTypeList = ({ smsType, setSmsType, notif, user, isDash }) => {
  const { classes, cx } = useStyles({ isDash });
  return (
    <Box className={classes.typeContainer}>
      <Box className={classes.typeLogoBox}>
        {isDash && smsType && permit(user, { key: 'closeChat' }) ?
          <IconButton
            onClick={() => setSmsType(null)}
            className={classes.closeIcon}>
            <ArrowForward />
          </IconButton> :
          <img
            src={whiteLogo}
            alt='logo' width='30' height='30' />}
      </Box>
      <Box display='flex' flex={1}>
        <List className={classes.typeList}>
          {smsTypes.filter(e => !e.hidden || !e.hidden(user)).map((e, i) => {
            const selected = e.type === smsType;
            const bgColor = selected ? blue[500] : '';
            const color = selected ? '#fff' : '#ddd';
            return (
              <Tooltip
                key={i}
                title={e.title}
                placement='right'
                leaveTouchDelay={500}>
                <ListItem
                  onClick={() => setSmsType(e.type)}
                  className={classes.typeButton}
                  style={{ backgroundColor: bgColor }}
                >
                  <Badge
                    className={cx(
                      notif.includes(e.type) && !selected &&
                      classes.blinkBadge)}
                    color="secondary"
                    variant="dot"
                    invisible={!notif.includes(e.type)}>
                    <e.icon
                      selected={selected}
                      style={{ color }} />
                  </Badge>
                </ListItem>
              </Tooltip>
            );
          })}
        </List>
      </Box>
      <Box className={classes.bottomBox}>
        {isDash && <ChatButton user={user} />}
      </Box>
    </Box>
  );
};

const areEquals = (pP, nP) => pP.smsType === nP.smsType &&
pP.notif.length === nP.notif.length;

export default React.memo(SMSTypeList, areEquals);
