import api from '../api';
import {
  setDialogContentProp
} from '../actions/dialog';
import store from '../store';

const dispatch = (dispatch) => store.dispatch(dispatch);

const handleTimerDisable = () => {
  let timer = 0;
  const setDisabled = setDialogContentProp('disabled');
  if (typeof timer === 'number') {
    clearTimeout(timer);
  }
  dispatch(setDisabled(true));
  timer = setTimeout(() => dispatch(setDisabled(false)), 120000);
};

export const verifyToken = async (token, getCsv) => {
  const setErrorMessage = setDialogContentProp('errorMessage');
  const setToken = setDialogContentProp('token');
  try {
    const res = await api.sms.custom('csvExportToken').post({
      tokenAction: 'retriveToken', token
    });
    if (res.statusCode() === 200) {
      return getCsv();
    }
  } catch (error) {
    dispatch(setErrorMessage({
      error: true,
      message: error.response.data || error.name,
    }));
  }
  dispatch(setToken(''));
};


export const sendToken = async (sent) => {
  const setErrorMessage = setDialogContentProp('errorMessage');
  const setSent = setDialogContentProp('sent');
  handleTimerDisable();
  if (!sent) {
    dispatch(setSent(true));
  }
  try {
    const res = await api.sms.custom('csvExportToken').post({
      tokenAction: 'createToken'
    });
    if (res.statusCode() === 200) {
      dispatch(setErrorMessage({
        error: false,
      }));
    }
    return true;
  } catch (error) {
    dispatch(setErrorMessage({
      error: false,
      message: ''
    }));
  }
};
