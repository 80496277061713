import React from 'react';
import { List } from 'immutable';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { Clear } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import { withStyles } from 'tss-react/mui';
import {
  getKeysConclusionsByType,
  getObjConclusionsByType,
} from '@lba-dev/package.local-globals/rConclusions';
import Typography from '@mui/material/Typography';

const styles = {
  limitHist: {
    width: '100%',
    minHeight: 100,
    maxHeight: 321,
    overflow: 'auto',
    overflowX: 'hidden',
  },
  title: {
    marginLeft: 20
  }
};
const getUserLogin = (users, userId) => {
  let user = users.find((id) => userId === id._id);
  return user ? user.login : ' ';
};

const Historique = ({ users, deleteComment, title, e, i, classes }) => (
  <div className={classes.limitHist}>
    {e.getIn(['recouvrement', 'historique'], new List()).map((s, index) => (
      <ListItem key={i} style={{ padding: 8 }} divider={true}>
        <Grid
          container
          alignItems="center"
          style={{ textAlign: 'center' }}
          spacing={4}
        >
          {deleteComment && (
            <Grid item xs={1}>
              <IconButton
                style={{ color: 'red' }}
                aria-label="Clear"
                onClick={() => deleteComment(index, i)}
                size="large">
                <Clear style={{ width: 20, height: 20 }} />
              </IconButton>
            </Grid>
          )}
          <Grid item xs={4}>
            <ListItemText
              style={{ color: 'grey', fontSize: title ? 17 : 13 }}
              disableTypography={true}
              primary={`${new Date(s.get('date')).toLocaleString(
                'fr-FR'
              )} - ${getUserLogin(users, s.get('userId'))} `}
            />
          </Grid>
          <Grid item xs={7}>
            <ListItemText
              style={{
                color: 'black',
                fontSize: title ? 17 : 13,
                wordWrap: 'break-word',
                textAlign: 'left',
              }}
              disableTypography={true}
              primary={s.get('comments')}
            />
          </Grid>
          {!title && !!s.get('conclusions', new List()).size && (
            <Grid item xs={6}>
              <ListItemText
                style={{ color: 'black', fontSize: title ? 17 : 13 }}
                disableTypography={true}
                primary={'Conclusions: '}
              />
            </Grid>
          )}
          {!title && !!s.get('conclusions', new List()).size && (
            <Grid item xs={6}>
              <ListItemText
                style={{
                  color: 'black',
                  fontSize: 13,
                  textAlign: 'left',
                }}
                disableTypography={true}
                primary={`${s.get('conclusions', new List()).reduce((v, c) => {
                  const type = e.getIn(['recouvrement', 'type']);
                  let name;
                  const [key = [], obj] = [
                    getKeysConclusionsByType(type),
                    getObjConclusionsByType(type),
                  ];
                  (key || []).some((o) =>
                    ((obj && obj[o]) || []).some((t) => {
                      if (t._id === c) {
                        name = t.name;
                        return true;
                      }
                    })
                  );

                  return `${v ? `${v}, ` : ''}${name}`;
                }, '')}`}
              />
            </Grid>
          )}
        </Grid>
      </ListItem>
    ))}
  </div>
);

const Historiques = ({
  classes,
  users,
  deleteComment,
  inters,
  title
}) =>
  inters.map(
    (e, i) =>
      e.get('checked') &&
      !!e.getIn(['recouvrement', 'historique'], new List()).size && (
        <>
          {title && <Typography className={classes.title}>{title}</Typography>}
          <Historique
            classes={classes}
            title={title}
            users={users}
            deleteComment={deleteComment}
            e={e}
            i={i}
          />
        </>
      )
  );

export default withStyles(Historiques, styles);
