import React from 'react';
import { withStyles } from 'tss-react/mui';
import Fab from '@mui/material/Fab';
import CircularProgress from '@mui/material/CircularProgress';
import { Close, Done } from '@mui/icons-material';
import green from '@mui/material/colors/green';
import { DOCVAL, DOCREF } from '@lba-dev/package.local-globals/docStatus';
import stopPropagation from '../../utils/stopPropagation';

const styles = {
  container: {
    position: 'absolute',
    zIndex: 3,
    top: 5,
    right: 10,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-end'
  }
};


const ValidationButton = ({ classes,
  sendStatus, fileName, loading }) =>
  loading ? (
    <div className={classes.container}>
      <CircularProgress size={30} />
    </div>
  ) : (
    <div className={classes.container}>
      <Fab
        size='small'
        color="primary"
        style={{ backgroundColor: green[500], marginRight: 5 }}
        onClick={stopPropagation(() => sendStatus(fileName, DOCVAL))}
      >
        <Done />
      </Fab>
      <Fab
        size='small'
        color="secondary"
        onClick={stopPropagation(() => sendStatus(fileName, DOCREF))}
      >
        <Close />
      </Fab>
    </div>
  );

export default withStyles(ValidationButton, styles);

