import store from '../../../store';
import { setDialog } from '../../../actions/dialog';
import api from '../../../api';
import notifSystem from '../../../notifSystem';

const deleteAudio = (id) => store.dispatch(
  setDialog({
    name: 'ConfirmDialog',
    open: true,
    dialogProps: {
      title: 'Etes-vous sûr de vouloir supprimer cet audio ?',
    },
    contentProps: {
      defaultText: {
        contentText: 'Cette action est irréversible !',
      }
    },
    hideClose: true,
    actions: [
      {
        children: 'Annuler',
        color: 'secondary',
        onClick: (_, close) => close()
      },
      {
        children: 'Oui',
        color: 'primary',
        onClick: (_, close) => {
          api.ringover.custom('audio').delete({ id })
            .then(() => {
              notifSystem.success('Succès', 'Audio supprimé');
              close();
            })
            .catch(() => {
              notifSystem.error('Erreur', 'Erreur lors de la suppression');
              close();
            });
          close();
        }
      },
    ]
  })
)

const listenAudio = ({
  path,
  id,
  setLoading,
  setIdListener,
  setAudioToListen,
}) => {
  setLoading(true);
  setIdListener(id);
  setAudioToListen(null);
  api.ringover.custom('audio').get({ url: path })
    .then((response) => {
      const data = response.body().data();
      setAudioToListen(data);
    })
    .catch(() => {
      notifSystem
        .error('Erreur', 'Erreur lors de la récupération de l\'audio');
    })
    .finally(() => {
      setLoading(false);
    });
}

const addAudio = (base64, blob = null, setAudio, setSelected) => {
  setAudio(base64);
  setSelected({});
  const url = URL.createObjectURL(blob);
  let audio = document.getElementById('audioPlayerVoiceCampaign');

  if (!audio) {
    audio = document.createElement('audio');
    audio.id = 'audioPlayerVoiceCampaign';
    audio.controls = true;

    const recordingButton = document.querySelector('.audio-recorder');
    const parent = recordingButton.parentNode;
    parent.insertAdjacentElement('afterend', audio);
  }

  audio.src = url;
};

const handleRowClick = (_, rowData, setSelected, selected) => {
  setSelected(selected.id === rowData.id ? {} : rowData);
};

const handleUsersRowClick = (_, rowData, setSelectedUsers, selectedUsers) =>
  setSelectedUsers(selectedUsers.some(e => e._id === rowData._id) ?
    selectedUsers.filter(e => e._id !== rowData._id) :
    [...selectedUsers, rowData]
  );


export {
  deleteAudio,
  listenAudio,
  addAudio,
  handleRowClick,
  handleUsersRowClick
}
