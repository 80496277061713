import React from 'react';

import Grid from '@mui/material/Grid';

const withGrid = params => WrappedComponent => props => (
  <Grid item {...params}>
    <WrappedComponent {...props} />
  </Grid>
);

export default withGrid;
