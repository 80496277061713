import { moment } from '@lba-dev/package.local-globals/moment';

export const addressToString = (address) => {
  const roadNumber = address.number;
  const road = address.road;
  const zipcode = address.zipcode;
  const city = address.city;
  return `${roadNumber} ${road}, ${zipcode} ${city}`;
};
export const complementAdress = (client) => {
  const compl = client.compl ? `Complément d'adresse :\n${client.compl}` : '';
  const floor = client.floor ? `Etage : ${client.floor}, ` : '';
  const code = client.code ? `code : ${client.code}, ` : '';
  const building = client.building ? `bâtiment : ${client.building}` : '';
  let compl2 = floor || code || building ?
    `(${floor + code + building})\n` : '';
  return `${compl}\n${compl2}`;
};
export const clientToString = (client) => {
  const civility = client.civility || '';
  const name = client.name || '';
  const firstname = client.firstname ? ` ${client.firstname}` : '';
  if (client.civility === 'Société') {
    return `${civility}. ${client.society || ''}\n(${name}${firstname})`;
  }
  return `${civility}. ${name}${firstname}`;
};

export const dateToString = (date, flexHour, maxHour, definHClient) => {
  let string = `le ${moment(date).format('dddd DD MMMM YYYY [à] HH[h]mm')}`;
  if (flexHour) {
    string = `le ${moment(date).format('dddd DD MMMM YYYY [entre] HH[h]mm')
    } et ${moment(maxHour).format('HH[h]mm')}`;
  } else if (definHClient) {
    string = `le ${moment(date)
      .format('dddd DD MMMM YYYY[ (l\'heure à définir avec le client)]')}`;
  }
  return string;
};

export function getMomentOrDate(inter) {
  const date = new Date(inter.date.intervention) || '';
  const mDate = moment(date);
  const maxHour = inter.infoDesc.maxHour ?
    new Date(inter.infoDesc.maxHour) :
    moment(date.getTime()).add(2, 'hours').toDate();
  if (!date) {
    return;
  }
  if (inter.definDClient) {
    return '(date à définir avec le client)';
  }
  if (mDate > moment().add(1, 'days').startOf('day') &&
    mDate < moment().add(1, 'days').endOf('day')) {
    return moment(date).format(`[demain ]${
      inter.definHClient
        ? '[(horaire à définir avec le client)]'
        : '[à ]HH:mm'}`
    );
  }
  if ((mDate < moment().subtract(1, 'days')
    || mDate > moment().add(1, 'days')) || inter.flexHour
    || inter.definHClient)
  {
    return dateToString(date, inter.flexHour, maxHour, inter.definHClient);
  }
  moment.updateLocale('fr', {
    relativeTime: {
      s: 'quelques s',
      m: 'une minutes',
      mm: '%d minutes',
      h: '1h00',
      hh: '%dh00',
      d: 'un jour',
      dd: '%d jours',
    }
  });
  return moment(date).fromNow();
}
