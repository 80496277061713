import React from 'react';

import { connect } from 'react-redux';
import SuspenseLoading from '../components/Dashboard/SuspenseLoading';

const mapStateToProps = (state) => ({
  loading: state.loading
});

const LoadingManager = ({ loading }) => (
  loading.open ? <SuspenseLoading message={loading.message} /> : <></>
);

export default connect(mapStateToProps)(LoadingManager);
