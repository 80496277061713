
import { formatDate } from '@lba-dev/package.local-globals/moment';
import company from '@lba-dev/package.local-globals/company';
import { AS } from '@lba-dev/package.local-globals/categories';
import { externeNumber } from '@lba-dev/package.local-globals/numbers';
import { getFinanceConsignes } from '../../../utils/financement';

const asMail = (data) => {
  const artisan = data.artisan || {};
  const intervention = data.inter || {};
  const client = intervention.client || {};
  const address = client.address || {};
  const fullAddr = `${address.number || ''} ${address.road || ''
  }, ${address.zipcode || ''} ${address.city || ''}`;
  return `A l'attention de l'entreprise ${(artisan && artisan.companyName) ||
    artisan.name || ''},\n\n` +
  'A la suite de notre conversation téléphonique, vous trouverez ci-dessous ' +
  'les coordonnées de notre client afin d\'effectuer l\'intervention suivante' +
  ' :\n\n<b>Objet : demande d\'intervention</b>\n' +
  `<b>OS n° ${intervention.id || '{id}'}</b>\n` +
  `<b>${client && client.civility &&
      client.civility.toLowerCase() === 'société' ?
    `${client.civility} ` : ''}${(client && client.name) || ''} ${
    (client && client.firstname) || ''}</b>\n` +
  `<b>Tél. ${externeNumber(intervention)}</b>\n` +
  `<b>${fullAddr}</b>\n\n` +
  `Informations complémentaires : ${
    (intervention.infoDesc && intervention.infoDesc.desc) ||
    ''}\n\n` +
  `${getFinanceConsignes(intervention)}` +
  `L'intervention se déroulera le ${intervention.date &&
    formatDate(intervention.date.intervention, 'L[ vers ]HH:mm')}.\n\n` +
  `Comme convenu avec vous, le budget de cette intervention est de ${
    intervention.priceArtisan ?
      (intervention.priceArtisan / 100).toFixed(2) : '__BUDGET__'} € H.T.\n\n` +
  'Merci de me contacter si ce prix venait à être dépassé ou pour tout souci ' +
  `éventuel au ${company.lines.telSupport}.\n\n` +
  '<b>Nous vous prions de ne remettre aucun document sur place (facture, ' +
  'rapport d\'intervention), nous ferons le nécessaire.\n\n' +
  'Merci de me transmettre ces documents par mail, à la suite de ' +
  'l\'intervention.</b>\n\n' +
  '<p class="red">ATTENTION : ' +
  'Nous vous rappelons que vous intervenez dans le cadre ' +
  'd\'une sous traitance. ' +
  'A cet effet nous vous prions de ne pas aborder la question des tarifs ' +
  'avec notre client, ' +
  'ni d\'indiquer vos prix pour la même prestation.</p>\n\n' +
  'En cas de soucis, merci de nous contacter immédiatement.\n\n' +
  'Pour tout manquement à ces règles, pouvant créer un litige avec ' +
  'notre client, ' +
  'nous nous réservons le droit acquittement en l\'état de votre facture.\n\n' +
  'Vous trouverez au bas de ce mail, les coordonnées de facturation ' +
  'de notre société.\n\n' +
  'Merci pour votre compréhension.\n\n';
};

const artisanForfait = (data) => {
  const artisan = data.artisan || {};
  const intervention = data.inter || {};
  const user = data.user || {};
  const client = intervention.client || {};
  const address = client.address || {};
  const fullAddr = `${address.number || ''} ${address.road || ''
  }, ${address.zipcode || ''} ${address.city || ''}`;
  const interDate = intervention.date.intervention;
  const login = (user && user.alias) || '';
  const num = user && user.ligne.replace(/\+33/, '0').match(/.{2}/g).join('.');
  const priceArtisan = ((intervention.priceArtisan || 0) / 100).toFixed(2);
  return `A l'attention de l'entreprise ${(artisan && artisan.companyName) ||
    artisan.name || ''},\n\n` +
  'A la suite de notre conversation téléphonique, vous trouverez ci-dessous ' +
  'les coordonnées de notre client afin d\'effectuer l\'intervention suivante' +
  ' :\n\n<b>Objet : demande d\'intervention</b>\n' +
  `<b>OS n° ${intervention.id || '{id}'}</b>\n` +
  `<b>${client && client.civility &&
      client.civility.toLowerCase() === 'société' ?
    `${client.civility} ` : ''}${(client && client.name) || ''} ${
    (client && client.firstname) || ''}</b>\n` +
  `<b>Tél. ${externeNumber(intervention)}</b>\n` +
  `<b>${fullAddr}</b>\n\n` +
  `Informations complémentaires : ${
    (intervention.infoDesc && intervention.infoDesc.desc) ||
    ''}\n\n` +
    `${getFinanceConsignes(intervention)}` +
  '- Présentez vous en tant que société mandaté par ' +
    `Les Bons Artisans\n${intervention.smsAxialis ?
      (`Pour contacter le client composez le: ${externeNumber(intervention)} ` +
    `(Tapez le code OS: ${intervention.id || '{id}'} ` +
    'et appuyez sur la touche # )\n')
      : externeNumber(intervention)}` +
  `Un rendez vous a été prévu à la date du ${
    formatDate(interDate, 'DD/MM[ à ]HH[h]mm')}\n\n` +
  ' Nous nous sommes mis d\'accord pour un budget Maximum de prestation de : ' +
  `${priceArtisan} € H.T\n` +
  'Si ce budget vient à être dépassé, ' +
  'merci de nous contacter avant le démarrage des travaux.\n' +
  'Si la nature de la prestation nécessite du matériel supplémentaire, ' +
  'faite nous parvenir votre facture + RIB de la première prestation, ' +
  'ainsi que votre devis pour les travaux à suivre.\n\n' +
  '<p class="red centered bold subTitle">' +
  '<b>Quelques règles à respecter !</b></p>\n\n<b>ATTENTION : </b>' +
  'Nous vous rappelons que vous intervenez dans le cadre ' +
  'd\'une intervention en sous-traitance. A cet effet nous ' +
  'vous prions de ne pas aborder la question des tarifs avec notre client, ' +
  'ni d\'indiquer vos prix pour la même prestation.\n' +
  'En cas de soucis, merci de nous contacter immédiatement.\n' +
  'Pour tout manquement à ces règles, pouvant créer un litige ' +
  'avec notre client, nous nous réservons le droit acquittement ' +
  'en l\'état de votre facture.\n\n' +
  'Vous trouverez dans ce mail, les coordonnées de facturation ' +
  'de notre société.\n\n' +
  'Pour tous renseignements supplémentaires, ' +
  'vous pouvez joindre le commercial chargé de cette intervention :\n\n' +
  `Le service partenariat au ${company.lines.telSupport}\n` +
  `Le commercial ${login} au ${num}`;
};

export const defaultText = (data = {}) => {
  if (data.inter.categorie === AS) {
    return asMail(data);
  }
  return artisanForfait(data);
};
