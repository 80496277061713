import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../../actions/dialog';

import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import api from '../../../api';
import notifSystem from '../../../notifSystem';
import { Button, Skeleton, Typography } from '@mui/material';
import WhenElementIsVisible from '../../Utils/WhenElementIsVisible';

const setAgence = setDialogContentProp('agence');

const mapStateToProps = ({
  dialog: {
    contentProps: { artisan, agence },
  },
}) => ({
  artisan,
  agence,
});

const mapDispatchToProps = {
  setAgence,
};

const getFournisseurs = (artisan, skip) =>
  api.fournisseures
    .getAll({
      lat: artisan.billingAddress.location.coordinates[1],
      lng: artisan.billingAddress.location.coordinates[0],
      radius: 60,
      query: JSON.stringify({ limit: 3, skip, enseigneCommerciale: 'CEDEO' }),
    })
    .then((res) => res.body().map((e) => e.data()));

const DisplayAgencies = ({ artisan, agence, setAgence }) => {
  const [agencies, setAgencies] = useState([]);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);

  const updateFounisseurs = async () => {
    try {
      const newAgencies = await getFournisseurs(artisan, skip);
      setAgencies(agencies.concat(newAgencies));
      if (newAgencies.length === 0) {
        setHasMore(false);
      }
      setLoading(false);
    } catch (error) {
      notifSystem.error('Erreur', error.message);
    }
  };
  useEffect(() => {
    updateFounisseurs();
  }, [skip]);

  if (loading) {
    return [...Array(8)].map((_, i) => (
      <Typography key={i} variant="h3">
        <Skeleton />
      </Typography>
    ));
  }
  return agencies.length ? (
    <List style={{ height: 200, maxHeight: 200, overflow: 'auto' }}>
      <WhenElementIsVisible
        onLoadMore={() => Promise.resolve(setSkip(skip + 1))}
        hasMore={hasMore}
      >
        {agencies.map((e, i) => (
          <ListItem key={i} selected={agence && agence._id === e.obj._id}>
            <Grid container spacing={1} justifyContent="space-around">
              <Grid item xs={8}>
                <ListItemText
                  primary={e.obj.niveauAgence}
                  secondary={
                    e.obj.address ? e.obj.address.formattedAddress : 'N/A'
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  onClick={() => setAgence(e.obj)}
                  color="primary"
                >
                  Choisir
                </Button>
              </Grid>
              <Grid item xs={12}>
                <ListItemText primary={`Distance: ${e.dis.toFixed(2)} km`} />
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </WhenElementIsVisible>
    </List>
  ) : (
    <Typography variant="subtitle1" align="center" sx={{ m: 1 }}>
      Aucune agence trouvée autour
    </Typography>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DisplayAgencies);
