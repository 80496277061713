import React from 'react';
import { PropTypes } from 'prop-types';
import { blue, red } from '@mui/material/colors';
import CardItems from './CardItems';


const smsOrMail = [{
  _id: '1',
  color: blue,
  name: 'SMS & MAIL'
}, {
  _id: '0',
  color: red,
  name: 'NON'
}];

const Content = ({ setData, annulations, steps }) => ([
  <CardItems
    key={0}
    array={annulations}
    name="raison" display='raison' path="value"
    setData={setData} />,
  <CardItems
    key={1}
    array={smsOrMail}
    name="_id" display='name' path="send"
    setData={setData} />,
])[steps];

Content.propTypes = {
  setData: PropTypes.func.isRequired,
  annulations: PropTypes.array.isRequired,
  steps: PropTypes.number.isRequired,
};
export default Content;
