import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import { SHOW_ENR } from '@lba-dev/package.local-globals/configTypes';

import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import { getCalls } from '../../actions/inter';
import AudioPlayer from './AudioPlayer';
import { blue } from '@mui/material/colors';
import { keyUrls } from './FomatText';
import { cacheDisabled } from '../../utils/function';

const styles = {
  title: {
    marginBottom: 4,
    textTransform: 'uppercase',
    fontSize: '1rem',
  },
  main: {
    borderRadius: 30
  },
  active: {
    color: blue[500],
    fontWeight: 500
  },
  scroll: {
    overflow: 'auto',
    maxHeight: '250px'
  }
};

const mapStateToProps = ({ users, userId }) => ({
  user: users.find(e => e._id === userId)
});

class CallLogs extends PureComponent {
  state = {
    clientCalls: [],
    selectedAudio: {},
    page: 0,
    limit: false,
  };

  getCallsFromApi = (page, showMore = true) => {
    const { numbers, user, direction, collections } = this.props;
    if (numbers) {
      getCalls({
        numbers,
        user,
        page,
        direction,
        collections,
      }).then((newCalls) =>
        this.setState((prevState) => {
          let calls = [];
          if (showMore) {
            calls = (newCalls || []).length
              ? prevState.clientCalls.concat(newCalls)
              : prevState.clientCalls;
          } else {
            calls = (newCalls || []).length ? newCalls : newCalls;
          }
          calls = calls.sort((a, b) =>
            new Date(b.record.callStart).getTime() -
            new Date(a.record.callStart).getTime()
          );
          return {
            clientCalls: calls,
            selectedAudio: calls[0],
            page,
            limit: newCalls.length >= (page + 1) / calls.length,
          };
        })
      );
    }
  }

  componentDidMount() {
    this.getCallsFromApi(this.state.page);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(
        prevProps.direction) !== JSON.stringify(this.props.direction)
    ) {
      this.getCallsFromApi(0, false);
    }
  }

  showMore = () => this.getCallsFromApi(this.state.page + 1)

  selectAudio = (value) => {
    this.setState({ selectedAudio: value });
  }

  render() {
    const { classes, title, noPadding, keyUrl } = this.props;
    const { clientCalls, limit, selectedAudio } = this.state;
    const { key } = keyUrls[keyUrl];
    return (
      !cacheDisabled(SHOW_ENR) &&
      !!clientCalls.length && (
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight='bold' className={classes.title}
            children={title}
          />
          <Card elevation={2} className={classes.main}>
            <CardContent>
              <List className={classes.scroll}>
                {clientCalls.map((e, i) => (
                  <ListItem
                    divider
                    key={i}
                    onClick={() => this.selectAudio(e)}>
                    <ListItemText key={i}
                      primaryTypographyProps={
                        e._id === selectedAudio._id
                          ? { className: classes.active }
                          : {}
                      }
                      primary={keyUrls[keyUrl].text(e)}
                    />
                  </ListItem>
                ))}
                <ListItem>
                  {limit && (
                    <Button
                      color="inherit"
                      children="Afficher plus..."
                      fullWidth
                      onClick={() => this.showMore()}
                    />
                  )}
                </ListItem>
              </List>
              {selectedAudio.record[key] && <AudioPlayer
                url={selectedAudio.record[key]}
                noPadding={noPadding}
                transcription={selectedAudio.transcription}
                user={this.props.user}
                duration={selectedAudio.record.duration}
                transcriptorEngine={selectedAudio.transcriptorEngine}
                processingTextEngine={selectedAudio.processingTextEngine}
                processingTextChatgpt={selectedAudio.processingTextChatgpt}
                processingTextEngineQuality={
                  selectedAudio.processingTextEngineQuality
                }
                processingTextChatgptQuality={
                  selectedAudio.processingTextChatgptQuality
                }
              />}
            </CardContent>
          </Card>
        </Grid>
      )
    );
  }
}

export default connect(mapStateToProps)(withStyles(CallLogs, styles));
