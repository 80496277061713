import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import { Add, Cancel } from '@mui/icons-material';
import green from '@mui/material/colors/green';
import { List, fromJS } from 'immutable';
import CustomAddress from '../CustomInputs/CustomAddress';
import {
  projectLink,
  name as companyName
} from '@lba-dev/package.local-globals/company';
import notifSystem from '../../notifSystem';
import withTheme from '../../hoc/withTheme';

const styles = theme => ({
  actions: {
    display: 'flex',
  },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  categories: {
    background: '#80808042',
    borderRadius: 5,
    width: '85%',
    margin: 'auto',
    align: 'center'
  },
  textField: {
    width: 180,
    marginRight: 10
  },
  bootstrapRoot: {
    padding: 0,
    'label + &': {
    }
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: 'white',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  },
  bootstrapFormLabel: {
    fontSize: 18
  },
  removeButton: {
    width: '10%',
    minWidth: 0
  },
  divContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  adresseDiv: {
    width: '100%'
  },
  divAdd: {
    display: 'flex',
    width: '100%',
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
  },
  fabGreen2: {
    width: '10%',
    minWidth: 0,
    color: theme.palette.common.white,
    backgroundColor: green[500],
  },
  align: {
    'align-items': 'center'
  },
  container: { position: 'relative' }
});

const initAdresse = {
  name: '',
  source: companyName,
  url: projectLink,
  location: null
};

const initForfait = {
  name: '',
  number: ''
};

class AddressProsp extends PureComponent {

  state = {
    newAdresse: initAdresse,
    address: '',
    forfait: initForfait
  };

  addAdresse = () => {
    const { merge, setData, path } = this.props;
    const { newAdresse } = this.state;
    if (!newAdresse.location) {
      return notifSystem.warning('Message', 'Veuillez choisir une adresse');
    }
    let adresses = merge.getIn(path, new List());
    adresses = adresses.push(fromJS(newAdresse));
    this.setState({ newAdresse: initAdresse });
    setData( path, adresses);
  }

  updateAddress = (place) => {
    if (!(place || {}).geometry) {
      return false;
    }
    const { housenumber, name, street, postcode, city } = place.properties;

    let location = fromJS({ type: 'Point', coordinates:
    [place.geometry.coordinates[0], place.geometry.coordinates[1]] });
    this.setState({ newAdresse: {
      source: companyName,
      url: projectLink,
      name: `${housenumber ? `${housenumber}, ` : ''}${
        street || name || ''} ${postcode}, ${city}`,
      location
    } });
  };

  removeAdresse = (index) => {
    const { merge, setData, path } = this.props;
    let adresses = merge.getIn(path, new List());
    if (adresses.size > 1) {
      adresses = adresses.filter((adresse, i) => i !== index );
      setData( path, adresses);
    }
  };

  onChangeAdresse = name => {
    let { newAdresse } = this.state;
    this.setState({ newAdresse: {
      ...newAdresse,
      name
    } });
  };

  setData = path => event => {
    this.props.setData(path,
      event.target.type === 'number' ? parseInt(event.target.value, 10) || 0 :
        event.target.value);
  };

  getTextField = () => {
    const { merge, path } = this.props;
    const ArrayTextField = [];
    merge.getIn(path, [])
      .forEach((d, i) => ArrayTextField.push({
        path: [...path, i, 'name'],
        width: '90%',
        type: 'text',
        placeholder: `Adresse ${i} ...`,
        index: i,
        disabled: true
      }));
    return ArrayTextField;
  };

  render() {
    const { classes, merge, path } = this.props;
    const { newAdresse } = this.state;
    const ArrayTextField = this.getTextField();
    const InputLabelProps = {
      shrink: true,
      className: classes.bootstrapFormLabel
    };
    const InputProps = {
      disableUnderline: true,
      classes: {
        root: classes.bootstrapRoot,
        input: classes.bootstrapInput
      }
    };
    return (
      <React.Fragment>
        {
          ArrayTextField.map((el, i) => (
            <Grid container key={i} className={classes.align}>
              <Grid item sm={8} >
                <TextField
                  variant="standard"
                  disabled={el.disabled}
                  style={{ width: el.width }}
                  placeholder={el.placeholder}
                  className={classes.textField}
                  value={el.type === 'number' ?
                    String(merge.getIn(el.path, '') || '').replace(/ /g, '') :
                    String(merge.getIn(el.path, '') || '') }
                  margin="normal"
                  type={el.type}
                  InputProps={InputProps}
                  InputLabelProps={InputLabelProps}
                  onChange={this.setData(el.path)} />
              </Grid>
              <Grid item sm={3} >
                <SourceLink
                  merge={merge}
                  classes={classes}
                  el={el}
                  path={path}
                />
              </Grid>
              <Grid item sm={1} >
                <Tooltip disableInteractive title="Supprimer l'adresse">
                  <Button
                    aria-label="Add"
                    mini
                    className={classes.removeButton}
                    onClick={() => this.removeAdresse(el.index)}
                    color="secondary"
                    children={<Cancel />}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          ))
        }
        <div className={`${classes.divAdd} ${classes.container}`}>
          <CustomAddress
            updateAddress={this.updateAddress}
            setAddress={this.onChangeAdresse}
            address={newAdresse.name}
            InputProps={InputProps}
            InputLabelProps={InputLabelProps}
            placeholder={'nouvelle adresse ...'}
          />
          <Tooltip disableInteractive title="Ajouter une adresse">
            <Fab color="inherit"
              size='small'
              onClick={() => this.addAdresse()}
              className={classes.fabGreen}>
              <Add />
            </Fab>
          </Tooltip>
        </div>
      </React.Fragment>
    );
  }
}

const SourceLink = ({ merge, el, path }) => (
  <a
    href={merge.getIn([...path, el.index, 'url'], '')}
    target="_blank"
    rel="noopener noreferrer"
  >
    {merge.getIn([...path, el.index, 'source'], '')}
  </a>
);

AddressProsp.propTypes = {
  classes: PropTypes.object.isRequired,
  setData: PropTypes.func,
  theme: PropTypes.object.isRequired
};

export default withTheme(withStyles(AddressProsp, styles));
