import React from 'react';
import { connect } from 'react-redux';
import CustomTextField from '../../CustomInputs/CustomTextField';

import { withStyles } from 'tss-react/mui';
import Attachments from '../../Utils/Attachments';
import { setDialogContentProp } from '../../../actions/dialog';
import Grid from '@mui/material/Grid';

const setTitleArtisan = setDialogContentProp('titleArtisan');
const setArtisanMail = setDialogContentProp('message');
const setAttachments = setDialogContentProp('attachments');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      attachments,
      message,
      titleArtisan
    },
  },
}) => ({
  attachments,
  message,
  titleArtisan
});

const mapDispatchToProps = {
  setTitleArtisan,
  setArtisanMail,
  setAttachments
};

const styles = {
  gridItem: {
    display: 'flex',
    flexGrow: 1,
    minHeight: 105,
    borderColor: 'red',
    borderWidth: 2
  }
};

const DialogMailSST = ({
  attachments = [],
  titleArtisan, message,
  setAttachments, setTitleArtisan,
  setArtisanMail }) =>
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <CustomTextField
        label="Titre du mail"
        texttransform="none"
        value={titleArtisan}
        setData={(p, v) => setTitleArtisan(v)}
      />
    </Grid>
    <Grid item xs={12}>
      <CustomTextField
        label="Corps du mail"
        value={message}
        texttransform="none"
        rows={30}
        setData={(p, v) => setArtisanMail(v)}
      />
    </Grid>
    <Grid item xs={12} style={{ 'minHeight': '100px' }}>
      <Attachments
        data={attachments}
        onAdd={(f, files) => setAttachments(files)}
        onDelete={(f, i, files) => setAttachments(files)}
      />
    </Grid>
  </Grid>;

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(DialogMailSST, styles)
);

