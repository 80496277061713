import React from 'react';
import TimePicker from '../TimePicker';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../actions/dialog';
import Grid from '@mui/material/Grid';

const setHour = setDialogContentProp('hour');

const mapStateToProps = ({
  dialog: {
    contentProps: { hour },
  },
}) => ({
  hour,
});

const mapDispatchToProps = {
  setHour,
};
const TimerDialogs = ({ setHour, hour }) => (
  <Grid container spacing={2}>
    <Grid container item xs={12}
      children="Merci de Prolonger Votre delais">
    </Grid>
    <Grid container item xs={12}>
      <TimePicker
        label={'Délai'}
        name="delay"
        value={hour}
        onChange={(e) => {
          setHour(e.target.value);
        }}
      />
    </Grid>
  </Grid>
);

export default connect(mapStateToProps, mapDispatchToProps)(TimerDialogs);
