import React, { useEffect, useState } from 'react';
import collections from '../../../constants/collections';
import ListLogic from '../ListLogic';
import {
  functionType,
  listData,
  project,
  format,
} from '../../../utils/List/ListeDemandes';
import demandeActions from './demandeActions';
import DemandeMenu from './DemandeMenu';
import { useSelector } from 'react-redux';

const DemandeList = (props) => {
  const {
    name = collections.demandes.capitalize(),
    listen = collections.demandes,
    handlerFilterByColumns,
    sort,
    filterName,
    count,
    filter: filterProps,
    filteredByColumn = true,
  } = props;

  const { user, users } = useSelector(
    ({ users, userId }) => ({
      users,
      user: users.find(e => e._id === userId),
    })
  );

  const [filter, setFilter] = useState(filterProps);
  const [display] = useState(count || 25);

  useEffect(() => {
    setFilter(filterProps);
  }, [filterProps]);

  if (filterName !== 'default' && Object.keys(filter).length === 0) {
    return null;
  }

  const newListData = listData({ users, user });
  return (
    <React.Fragment>
      <ListLogic
        {...newListData}
        functionType={functionType}
        name={name}
        actions={demandeActions}
        collection={listen}
        filteredByColumn={filteredByColumn}
        handlerFilterByColumns={handlerFilterByColumns}
        listen={listen}
        filter={filter}
        count={display}
        sort={sort}
        menu={DemandeMenu}
        formatItem={format}
        project={project}
      />
    </React.Fragment>
  );
};

export default DemandeList;

