import { List } from 'immutable';
export const modelMarkers = new List([
  {
    color: '',
    dis: 1.4102503239555897,
    obj: {
      coordinates: { lat: 0, lng: 0 },
      remStatus: 1,
      selected: false,
      tel1: '0123456789',
      title: 'FIRST',
      categories: [],
      value: '5bed42391095ad1929a2f895',
    }
  }
]);
