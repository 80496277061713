import { CALL_DELETE_CITY, getNewListCities } from '../actions/etatReseau';
import notifSystem from '../notifSystem';
import api from '../api';


const deleteCity = (store) => next => async (action) => {
  switch (action.type) {
    case CALL_DELETE_CITY: {
      try {
        await api.all('cities').delete(action.deleteIdCity);
      } catch (e) {
        notifSystem.error(e, e.message);
      }
    }
      notifSystem.success('Opération réussie',
        'La ville a bien été supprimée...');
      store.dispatch(getNewListCities());
  }
  next(action);
};

export default deleteCity;
