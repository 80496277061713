import {
  Assignment,
  Call,
  MailOutline,
  InsertDriveFile,
  Close,
  Forward,
  Sms,
  HowToReg,
  Psychology,
  DateRange,
} from '@mui/icons-material';
import { fromJS } from 'immutable';
import {
  cancel,
  transfer,
  sendFromMenu,
  previewDevis,
  sendSmsFromMenu,
  openAssignDevisDialog,
  showSMS,
  relaunchUnconfirmedQuoteHandler,
  reminderAction,
} from '../../../actions/devis';
import {
  D_ENV, D_ATT, D_ANN, D_TRA
} from '@lba-dev/package.local-globals/dStatus';
import { checkCaldeo } from '../../../actions/verifications';
import { call } from '../../../utils/call';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import { sliceNumber } from '../../Calls/FomatText';

export default [
  {
    name: 'Modifier',
    icon: Assignment,
    link: ({ id }) => {
      window.open(`/devis/${id}`);
    },
    action: (props, elem) => {
      props.navigate(`/devis/${elem.id}`);
    },
  },
  {
    name: 'Prévisualiser',
    icon: InsertDriveFile,
    action: (props, elem) => previewDevis(elem),
  },
  {
    name: 'Appeler',
    icon: Call,
    sub: [1, 2, 3].map((e) => ( {
      name: `Téléphone Client ${e}`,
      value: (props, elem) => sliceNumber(elem?.client[`tel${e}`]),
      action: (props, elem) => call(elem.client[`tel${e}`]),
    })),
  },
  {
    name: 'SMS',
    icon: Sms,
    action: showSMS,
  },
  {
    name: 'Envoyer',
    icon: MailOutline,
    customVisible: (devis, props) =>
      props.selected &&
      (props.selected.status === D_ENV || props.selected.status === D_ANN),
    action: sendFromMenu,
  },
  {
    name: 'Envoyer par SMS',
    icon: Sms,
    customVisible: (devis, props) =>
      props.selected &&
      !(
        checkCaldeo(fromJS(props.selected), 'devis') &&
        (!props.selected.login.fillCaldeo)
      ),
    action: (props, elem) => sendSmsFromMenu(props, elem),
  },
  {
    name: 'Programmer un rappel',
    icon: DateRange,
    action: (elem) => reminderAction(elem),
  },
  {
    name: 'Relancer 1',
    icon: MailOutline,
    customVisible: (devis, props) =>
      props.selected &&
      props.selected.status === D_ATT &&
      props.selected.history &&
      props.selected.history.length <= 1,
    action: sendFromMenu,
  },
  {
    name: 'Relancer 2',
    icon: MailOutline,
    customVisible: (devis, props) =>
      props.selected &&
      props.selected.status === D_ATT &&
      props.selected.history &&
      props.selected.history.length > 1,
    action: sendFromMenu,
  },
  {
    name: 'Transferer',
    customVisible: (devis, props) =>
      props.selected && props.selected.status !== D_TRA,
    icon: Forward,
    action: transfer,
  },
  {
    name: 'Annuler',
    icon: Close,
    action: cancel,
  },
  {
    name: 'Attribuer le devis',
    customVisible: (elem, props) =>
      props.selected &&
      props.selected.status !== D_TRA &&
      permit(props.users.find((u) => u._id === props.userId)),
    icon: HowToReg,
    action: (props, devis) => openAssignDevisDialog(props, devis),
  },
  {
    name: 'Relance Intelligente',
    icon: Psychology,
    customVisible: (_devis, props) => props.selected?.status === D_ATT &&
      permit(props.users.find(user => user._id === props.userId)),
    action: relaunchUnconfirmedQuoteHandler
  }
];
