import React from 'react';
import { columns } from './constants';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { withStyles } from 'tss-react/mui';
import CustomMaterialTable from '../CustomMaterialTable';
import {
  data as pCategories
} from '@lba-dev/package.local-globals/PaymentCategories';
import {
  PAN_VAL, PAN_REF, PAN_ENC
} from '@lba-dev/package.local-globals/paymentAnnexeStatus';
import { TYP_DE } from '@lba-dev/package.local-globals/paymentAnnexes';
import { amber, green, red } from '@mui/material/colors';
import { connect } from 'react-redux';

const styles = {
  table: {
    '&td': {
      backgroundColor: '#e67300',
    }
  }
};

const mapStateToProps = ({ fournisseurs }) => ({ fournisseurs });

const backgroundColors = (rowData, isRec) => {
  if (rowData.status === PAN_REF || rowData.reglement === TYP_DE) {
    return red[100];
  }
  if (rowData.status === PAN_VAL) {
    return green[100];
  }
  if (isRec && rowData.status === PAN_ENC) {
    return amber[100];
  }
  return null;
};

const PaymentAnnexesTable = ({
  handleTabChange, options, hideCheckInput, isRec, isArchive,
  selectedTab, classes, user, fournisseurs, title, ...props
}) => {
  const tabData = pCategories.find(e => e._id === selectedTab + 1);
  return <>
    <Tabs
      onChange={handleTabChange}
      value={selectedTab}
      variant="fullWidth"
      children={pCategories.map((e, i) =>
        <Tab
          label={e.name}
          key={i}
          disabled={!e.service.includes(user.service)}
        />
      )}
    />
    <CustomMaterialTable
      columns={columns({
        hideCheckInput,
        tab: selectedTab + 1,
        isArchive,
        canEdit: props.canEdit,
        fournisseurs
      })}
      className={classes.table}
      title={`${title}${tabData && tabData.name ? `- ${tabData.name}` : ''}`}
      {...props}
      options={{
        search: false,
        headerStyle: {
          borderColor: '#000'
        },
        rowStyle: (rowData) => ({
          backgroundColor: backgroundColors(rowData, isRec)
        }),
        ...options
      }}
    />
  </>;
};

export default connect(mapStateToProps)(
  withStyles(PaymentAnnexesTable, styles));
