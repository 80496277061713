import React, { PureComponent } from 'react';

import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import { ExpandMore } from '@mui/icons-material';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
  panelDetailsStyle: { display: 'contents' },
  panelSummaryStyle: {},
  expansionPanelStyle: { margin: `${theme.spacing(2)} 0px` },
});

const withExpansionPanel = (
  WrappedComponent,
  options = {
    elevation: 2
  }
) => withStyles((class extends PureComponent {
  state = {
    expanded: !!this.props.expanded,
  };

  render() {
    const {
      expansionTitle,
      summaryChild,
      classes: {
        panelSummaryStyle,
        panelDetailsStyle,
        expansionPanelStyle,
        ...classes
      },
      noMargin,
      ...otherProps
    } = this.props;
    const { expanded } = this.state;
    return (
      <Accordion
        elevation={options.elevation}
        className={!noMargin ? expansionPanelStyle : ''}
        onChange={() => this.setState({ expanded: !expanded })}
        expanded={expanded}
      >
        <AccordionSummary
          className={panelSummaryStyle}
          expandIcon={<ExpandMore />}
          children={summaryChild ||
              <Typography variant="subtitle1" children={expansionTitle} />
          }
        />
        <AccordionDetails className={panelDetailsStyle}>
          {expanded &&
              <WrappedComponent
                classes={classes}
                expansionTitle={expansionTitle}
                {...otherProps}
              />
          }
        </AccordionDetails>
      </Accordion>
    );
  }
}), styles);

export default withExpansionPanel;
