import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withDisplaying } from '../../hoc';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';

class CustomSwitch extends PureComponent {
  setData = (event) => {
    const { setData, path } = this.props;
    setData(path, event.target.checked);
  }
  render() {
    const { xs, sm, md, lg, xl, label, grid, gridClasses,
      labelPlacement, fullWidth, formProps = {}, helperText,
      justifyContent = 'flex-start', ...props } = this.props;
    delete props.setData;

    const input = (
      <FormControl>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              {...props}
              onChange={this.setData}
            />
          }
          labelPlacement={labelPlacement}
          label={label}
          {...formProps}
          style={{
            ...(fullWidth ? { width: '100%' } : {}),
            justifyContent, ...formProps.style }}
        />
        {helperText ? <FormHelperText children={helperText} /> : ''}
      </FormControl>
    );
    if (grid) {
      return (
        <Grid item xs={xs} sm={sm} md={md}lg={lg} xl={xl}
          className={ gridClasses }>
          {input}
        </Grid>
      );
    }
    return input;
  }
}

CustomSwitch.propTypes = {
  path: PropTypes.arrayOf(PropTypes.string),
  setData: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  grid: PropTypes.bool
};
CustomSwitch.displayName = 'CustomSwitch';

export default withDisplaying()(CustomSwitch);
