import Immutable, { fromJS, Map } from 'immutable';

import {
  Assignment,
  Call,
  Check,
  EventNote,
  Feedback,
  MailOutline,
  Person,
  ReportProblem,
  Policy
} from '@mui/icons-material';

import {
  permit
} from '@lba-dev/package.local-globals/restrictions';

import { call } from '../../../utils/call';
import { appel } from '../../../actions/artisan';
import { signalement } from '../../../actions/inter';
import {
  addReminderDialog,
  changeStatus,
  openMailDialog
} from '../../../actions/achat';
import { A_DEMC, A_DEMT } from '@lba-dev/package.local-globals/statusAchat';

import {
  MA_CLOSR, MA_CLOT, MA_RAPP
} from '@lba-dev/package.local-globals/achatMail';

export default [
  {
    name: 'Programmer un rappel',
    icon: Assignment,
    action: (props, elem) => addReminderDialog(props, elem),
  },
  {
    name: 'Modifier',
    icon: Assignment,
    link: ({ id }) => {
      window.open(`/intervention/${id}`);
    },
    action: (props, elem) => {
      props.navigate(`/intervention/${elem.id}`);
    },
  },
  {
    name: 'Appeler',
    icon: Call,
    sub: [1, 2, 3].map((e) => ({
      name: `Téléphone Artisan ${e}`,
      action: (props, elem) => {
        appel(
          {
            intervention: fromJS(elem),
            artisan: new Map({
              _id: elem.artisan,
              companyName: elem.currentArtisan.companyName || ''
            })
          },
          elem.currentArtisan[`tel${e}`]
        ).then(() => {
          call(elem.currentArtisan[`tel${e}`]);
        });
      },
    })),
  }, {
    name: 'Mail SST',
    icon: MailOutline,
    customVisible: (elem, props) => props.selected &&
      props.selected.signalAchat,
    sub: [
      {
        name: 'Rappel',
        icon: EventNote,
        action: (props, elem) => openMailDialog(elem, MA_RAPP)
      },
      {
        name: 'Clôture sans réponse',
        icon: Feedback,
        action: (props, elem) => openMailDialog(elem, MA_CLOSR)
      },
      {
        name: 'Clôture global',
        icon: Check,
        action: (props, elem) => openMailDialog(elem, MA_CLOT)
      },
    ]
  },
  {
    name: 'Signalement',
    icon: ReportProblem,
    customVisible: (elem, props) =>
      Immutable.List.isList(props.users) && props.selected,
    action: signalement,
  },
  {
    name: 'Récap. Artisan',
    icon: Person,
    visible: 'artisan',
    link: ({ artisan }) => {
      window.open(`/artisan/${artisan}/recap`);
    },
    action: (props, elem) => {
      props.navigate(`/artisan/${elem.artisan}/recap`);
    },
    customVisible: (elem, props) =>
      permit(
        props.users.find(u => u._id === props.userId),
        { key: 'externSAV' }
      ),
  },
  {
    name: 'Garantie',
    icon: Policy,
    customVisible: (elem, props) => props.selected &&
      !props.selected.signalAchat,
    sub: [
      {
        name: 'Passer à la liste « En cours »',
        action: (p, e) => changeStatus(
          e._id,
          A_DEMC,
          p.filter['supplies.warranty.status']
        )
      },
      {
        name: 'Demande traitée',
        action: (p, e) => changeStatus(
          e._id,
          A_DEMT,
          p.filter['supplies.warranty.status']
        )
      }
    ]
  }
];
