import {
  CheckCircle, Warning, Error, Info, Sms, Email
} from '@mui/icons-material';

//Look for Check / CheckCircle to look more consistant
export default {
  critical: { color: '#ef4444', icon: Error, name: 'error' },
  error: { color: '#ef6644', icon: Error, name: 'error' },
  warning: { color: '#efaa33', icon: Warning, name: 'warning' },
  success: { color: '#66aa33', icon: CheckCircle, name: 'success' },
  info: { color: '#4488ab', icon: Info, name: 'success' },
  sms: { color: '#ffffff', icon: Sms, name: 'sms' },
  email: { color: '#ffffff', icon: Email, name: 'email' },
  popUp: { color: '#ffffff', icon: Warning, name: 'popUp' },
};
