import React from 'react';

import { Chip } from '@mui/material';
import { EuroSymbol } from '@mui/icons-material';

const PriceChip = ({ price }) =>
  price ? (
    <Chip
      color="primary"
      size="small"
      icon={<EuroSymbol />}
      label={price}
    />
  ) : (
    ''
  );

export default PriceChip;
