import initialState from '../views/EtatReseau/Utiles/initialState';

/**-------------------------------------------
 * ACTIONS IMPORT
 ---------------------------------------------*/
import {
  CALL_MODAL,
  CLOSE_MODAL,
  SET_INPUT_JOBCAT,
  SET_INPUT_CITY,
  SET_DATE_START,
  SET_DATE_END,
  PUT_ADDRESS_TO_INPUT,
  GET_VALUE_INPUT_DISTANCE_ADD_CITY,
  SWITCH_VIEW_MODAL,
  OUTPUT_FILTERED,
  RESET_FILTERED_TAB,
  SET_COLUMNS_SHOW_MARKERS,
  GET_CITIES,
  SET_INPUT_ZIPCODE,
  RESULT_FILTERED_TAB,
  CLEAR_FILTER,
  SET_DATA_GLOBAL_RESEAU,
  OPEN_MODAL_LIST_ARTISANS_TAB_FILTERED,
  CLOSE_MODAL_LIST_ARTISANS_TAB_FILTERED,
  SET_LIST_MARKERS,
  SET_LIST_CIRCLES,
  GET_EDIT_CITY,
  EDIT_DIST_CITY_UPDATE,
  SET_MARKER_TO_MAP,
  SET_SHOW_LIST_SPECIFIC_ARTISANS,
  LOADING_FINISH,
  SET_EXPANDED,
  FILTER_BY_COLUMNS,
  SET_LIST_ACTIVITIES_ARTISAN
} from '../actions/etatReseau';
/**-------------------------------------------
 * INITIAL STATE
 ---------------------------------------------*/

const etatReseau = (state = initialState, action = {}) => {
  switch (action.type) {
    case CALL_MODAL:
      return {
        ...state,
        statusModal: !state.statusModal
      };
    case CLOSE_MODAL:
      return {
        ...state,
        statusModal: !state.statusModal,
        viewModal: false,
        addresstoInput: {
          distance: '15'
        },
        currentCityEdited: initialState.currentCityEdited
      };
    case GET_CITIES:
      return {
        ...state,
        cities: action.cities,
        currentCityEdited: {}
      };
    case SET_INPUT_JOBCAT:
      return {
        ...state,
        filter: {
          ...state.filter,
          categorie: action.categorie
        }
      };
    case SET_INPUT_ZIPCODE:
      return {
        ...state,
        filter: {
          ...state.filter,
          zipcode: action.zipcode
        }
      };
    case SET_INPUT_CITY:
      return {
        ...state,
        filter: {
          ...state.filter,
          city: action.city
        }
      };
    case SET_DATE_START:
      return {
        ...state,
        filter: {
          ...state.filter,
          dateStart: action.dateStart
        }
      };
    case SET_DATE_END:
      return {
        ...state,
        filter: {
          ...state.filter,
          dateEnd: action.dateEnd
        }
      };
    case OUTPUT_FILTERED:
      return {
        ...state,
        artisans: action.artisans
      };
    case PUT_ADDRESS_TO_INPUT:
      return {
        ...state,
        addresstoInput: {
          ...state.addresstoInput,
          city: action.addresstoInput
        }
      };
    case GET_VALUE_INPUT_DISTANCE_ADD_CITY:
      return {
        ...state,
        addresstoInput: {
          ...state.addresstoInput,
          distance: action.distance
        }
      };
    case SWITCH_VIEW_MODAL:
      return {
        ...state,
        viewModal: !state.viewModal,
        currentCityEdited: initialState.currentCityEdited,
        addresstoInput: {
          city: {},
          distance: '15'
        }
      };
    case RESET_FILTERED_TAB:
      return {
        ...state,
        filteredTab: initialState.filteredTab,
      };
    case SET_COLUMNS_SHOW_MARKERS:
      return {
        ...state,
        columns: action.columns
      };
    case RESULT_FILTERED_TAB:
      return {
        ...state,
        resultFilteredTab: action.resultFilteredTab,
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filter: initialState.filter,
        resultFilteredTab: initialState.resultFilteredTab,
        markerShowToMap: initialState.markerShowToMap,
        citiesFiltered: initialState.citiesFiltered
      };
    case SET_DATA_GLOBAL_RESEAU:
      return {
        ...state,
        globalData: action.globalData,
      };
    case OPEN_MODAL_LIST_ARTISANS_TAB_FILTERED:
      return {
        ...state,
        modalList: {
          statusModalViewListTab: !state.statusModalViewListTab
        }
      };
    case CLOSE_MODAL_LIST_ARTISANS_TAB_FILTERED:
      return {
        ...state,
        modalList: {
          data: state.modalList.data,
          statusModalViewListTab: action.status
        }
      };
    case SET_LIST_MARKERS:
      return {
        ...state,
        markers: action.markers
      };
    case SET_LIST_CIRCLES:
      return {
        ...state,
        citiesFiltered: action.citiesFiltered,
      };
    case GET_EDIT_CITY:
      return {
        ...state,
        currentCityEdited: action.currentCityEdited
      };
    case EDIT_DIST_CITY_UPDATE:
      return {
        ...state,
        updateDistCity: action.updateDistCity
      };
    case SET_MARKER_TO_MAP:
      return {
        ...state,
        markerShowToMap: action.markerShowToMap
      };
    case SET_SHOW_LIST_SPECIFIC_ARTISANS:
      return {
        ...state,
        listSpecificArtisans: action.listSpecificArtisans
      };
    case LOADING_FINISH:
      return {
        ...state,
        stateLoading: action.stateLoading
      };
    case SET_EXPANDED:
      return {
        ...state,
        expanded: action.expanded
      };
    case FILTER_BY_COLUMNS:
      return {
        ...state,
        filterByColumn: {
          status: !state.status,
          currentCol: action.colNumber,
          styl: action.styl
        }
      };
    case SET_LIST_ACTIVITIES_ARTISAN:
      return {
        ...state,
        listActivitiesArtisan: action.listActivitiesArtisan
      };
    case 'NULL':
      return initialState;
    default:
      return state;
  }
};

export default etatReseau;

