import React from 'react';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { NoteAdd } from '@mui/icons-material';

const DisplayNote = ({ validate, interObj, setNote }) => (
  <CardContent>
    <Grid container justifyContent="flex-end">
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={interObj.get('note', '')}
          onChange={(e) => setNote(e.target.value)}
        />
      </Grid>
      <Grid item xs={4} style={{ textAlign: 'right' }}>
        <Tooltip disableInteractive title={'Sauvegarder la note'}>
          <IconButton onClick={() => validate()} size="large">
            <NoteAdd fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  </CardContent>
);
export default DisplayNote;
