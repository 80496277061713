import {
  CONFCAND_FETCH,
  CONFCAND_UPDATE
} from '../constants/ActionTypes';
import DbWsAction from './models/DbWsAction';

const action = new DbWsAction(
  CONFCAND_FETCH,
  CONFCAND_UPDATE
);

export const fetch = action.fetch;
export const update = action.update;
