import React, { useState, useEffect } from 'react';

const HalloweenSurprise = () => {
  const images = [
    'https://media-mcetv.ouest-france.fr/wp-content/uploads/2018/10/Halloween-Le-top-10-des-maquillages-qui-font-peur-grande.jpg',
    'https://i.pinimg.com/originals/6b/35/3c/6b353ca8e274447ff8c7640cea013d8a.jpg',
    'https://www.demotivateur.fr/uploads/941/Le%20visage%20qui%20fait%20peur.jpg',
  ];

  const sounds = [
    'https://soundbible.com/mp3/Female_Scream_Horror-NeoPhyTe-138499973.mp3',
    'https://soundbible.com/mp3/Maniacal%20Witches%20Laugh-SoundBible.com-262127569.mp3',
    'https://soundbible.com/mp3/Scary%20Scream-SoundBible.com-1115384336.mp3',
  ];

  const [showImage, setShowImage] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const playRandomSurprise = () => {
    setCurrentImage(images[Math.floor(Math.random() * images.length)]);
    const audio = new Audio(sounds[Math.floor(Math.random() * sounds.length)]);
    audio.play();
    setShowImage(true);

    setTimeout(() => {
      setShowImage(false);
      audio.pause();
      audio.currentTime = 0;
    }, 3000);
  };

  useEffect(() => {
    const checkTimeAndVisibility = () => {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinute = currentTime.getMinutes();
      const currentDate = currentTime.getDate();
      const currentMonth = currentTime.getMonth();

      const isHalloween = [31, 30].includes(currentDate) && currentMonth === 9;
      const isTargetHour = (currentHour === 10 && currentMinute <= 10) || (
        currentHour === 15 && currentMinute <= 10);

      const displayedKey = `halloween-surprise-${currentHour}`;
      const hasDisplayed = localStorage.getItem(displayedKey);
      if (
        isHalloween &&
        isTargetHour &&
        document.visibilityState === 'visible' &&
        !hasDisplayed
      ) {
        playRandomSurprise();
        localStorage.setItem(displayedKey, 'true');
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkTimeAndVisibility();
      }
    };

    const interval = setInterval(checkTimeAndVisibility, 60000);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(interval);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    showImage && (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
          transition: 'opacity 0.5s ease-in-out',
        }}
      >
        <img
          src={currentImage}
          alt="Halloween Surprise"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            pointerEvents: 'none',
          }}
        />
      </div>
    )
  );
};

export default HalloweenSurprise;
