import {
  GO_FILTERED,
  resultFilteredTab,
  setListCircles,
  loadingFinish,
  callShowMarkerSelected
} from '../actions/etatReseau';
import api from '../api';
import notifSystem from '../notifSystem';

const generateCircle = (city) => ({
  location: city.coordinates,
  distance: city.city.distance,
});

const goFiltered = store => next => action => {
  switch (action.type) {
    case GO_FILTERED: {
      store.dispatch(loadingFinish(false));
      const { city, zipcode, categorie, dateStart, dateEnd } =
        store.getState().etatReseau.filter;
      api.all('cities/getNetwork').getAll({
        query: {
          city: !action.requestGlobal && city ?
            city.toUpperCase() : undefined,
          zipcode: !action.requestGlobal && zipcode ?
            { $regex: `^${zipcode.escapeRegExp()}` } : undefined,
          categories: categorie || undefined,
          date: {
            from: dateStart,
            to: dateEnd
          }
        }
      }).then(res => {
        const currentListCities = res.body().map(e => e.data());

        store.dispatch(resultFilteredTab(currentListCities));
        store.dispatch(setListCircles(currentListCities.map(generateCircle)));
        store.dispatch(callShowMarkerSelected());
        store.dispatch(loadingFinish(true));
      }).catch(e => {
        notifSystem.error(e.name, e.message);
        store.dispatch(loadingFinish(true));
      });
    }
  }
  next(action);
};

export default goFiltered;
