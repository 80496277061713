import React, { useEffect } from 'react';
import CustomTextField from '../../CustomInputs/CustomTextField';
import { closeDialog } from '../../../actions/dialog';
import store from '../../../store';

const Validate2FA = ({
  authCode,
  setCode,
  error = false,
  actions,
}) => {
  const onHandle = ({ key }) => {
    if (
      key === 'Enter' &&
      actions[0] &&
      typeof actions[0].onClick === 'function'
    ) {
      actions[0].onClick({ authCode }, () => store.dispatch(closeDialog()));
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onHandle);
    return () => {
      window.removeEventListener('keydown', onHandle);
    };
  }, [authCode]);

  return (
    <CustomTextField
      label="Code à 6 chiffres"
      value={authCode}
      setData={(p, v) => setCode(v)}
      error={error}
      helperText={error ? 'Code incorrect.' : ''}
      customTimeOut={0}
      multiline={false}
    />
  );
};

export default (Validate2FA);
