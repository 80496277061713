import React from 'react';
import { connect } from 'react-redux';
import SAVList from '../../List/SAVIntervention/SAVList';

const mapStateToProps = ({
  dialog: {
    contentProps: {
      filter,
      sort = { id: -1 },
      count = 10,
      handlerFilterByColumns,
    },
  },
}) => ({
  filter,
  sort,
  handlerFilterByColumns,
  count,
});


const SAVListDialog = ({ filter, sort, count, handlerFilterByColumns }) => (
  <SAVList
    handlerFilterByColumns={handlerFilterByColumns}
    filter={filter}
    sort={sort}
    count={count}
    filterName={'default'}
  />
);
export default connect(mapStateToProps)(SAVListDialog);
