import React, { PureComponent } from 'react';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Money from '../../utils/Money';
import MinCustomDialog from './MinCustomDialog';
import MoneyInput from '../CustomInputs/MoneyInput';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
const mapStateToProps = ({
  dialog: {
    contentProps: { interventions, finalPrice },
    actions,
  },
}) => ({ interventions, finalPrice, actions });
class SettlingInvoiceDialog extends PureComponent {
  state = {
    data: {
      ...this.props,
      interventions: this.props.interventions.map(e => ({
        ...e,
        finalPrice: e.finalPrice + (((e.tva || 10) / 100) * e.finalPrice),
      })),
    },
    update: false,
  };

  setData = (i, newPrice) => {
    const data = { ...this.state.data };
    data.interventions[i].finalPrice = newPrice;
    data.finalPrice = data.interventions
      .reduce((e, v) => e + (v.finalPrice || 0), 0);
    this.setState({ data, update: true });
  };

  render() {
    const { actions } = this.props;
    const { data, update } = this.state;
    return (
      <MinCustomDialog
        open
        middle
        title="Règlement du relevé"
        actions={[
          <Button
            key={1}
            onClick={actions[1].onClick}
            children={actions[1].children}
          />,
          <Button
            key={2}
            onClick={() => actions[0].onClick(!update || data)}
            children={actions[0].children}
          />,
        ]}
      >
        <Typography
          variant="h6"
          children={`Total TTC: ${Money.toString(data.finalPrice)}`}
        />
        <Table>
          <TableBody
            children={data.interventions.map((e, i) => (
              <TableRow
                key={e.id}
                children={[
                  <TableCell key={`${i}-1-${e.id}`} children={e.id} />,
                  <TableCell
                    key={`${i}-2-${e.id}`}
                    children={
                      <MoneyInput
                        label="Montant TTC (€)"
                        defaultValue={e.finalPrice}
                        path={[]}
                        setData={(p, v) => this.setData(i, v)}
                        requiredText={true}
                      />
                    }
                  />,
                ]}
              />
            ))}
          />
        </Table>
      </MinCustomDialog>
    );
  }
}

export default connect(mapStateToProps)(SettlingInvoiceDialog);
