import {
  QUESTIONS_FETCH,
  QUESTIONS_UPDATE
} from '../constants/ActionTypes';
import DbWsAction from './models/DbWsAction';

const action = new DbWsAction(
  QUESTIONS_FETCH,
  QUESTIONS_UPDATE
);

export const fetch = action.fetch;
export const update = action.update;

export * from './communMetiersActions';
