import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import { withDisplaying } from '../../hoc';
import { DisplayText } from './customDefault';

class CustomSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editable: !props.value,
      defaultOpen: false
    };
  }

  setData = (e) => {
    const { setData, path } = this.props;
    setData(path, e.target.value);
  }

  handleEditable = (value) => {
    this.setState({ editable: value, defaultOpen: true });
  }

  render() {
    const {
      xs,
      sm,
      md,
      lg,
      xl,
      label,
      children,
      grid,
      requiredText,
      variant = 'standard',
      displayText,
      ...otherProps
    } = this.props;
    delete otherProps.setData;
    delete otherProps.path;
    const { editable, defaultOpen } = this.state;
    const child = children.reduce((a, p) => {
      if (p && Array.isArray(p) && !a) {
        a = p.find(c => c?.props?.value === otherProps.value);
      } else if (p?.props?.value === otherProps.value && !a) {
        a = p;
      }
      return a;
    }, '');
    const input = displayText && !editable ? (
      <DisplayText
        label={label}
        isSelect
        required={requiredText}
        value={child && child.props ? child.props.children : ''}
        handleEditable={this.handleEditable}
        disabled={otherProps.disabled}
      />
    ) : (
      <FormControl required={requiredText} fullWidth>
        {label ? <InputLabel variant={variant}>{label}</InputLabel> : null}
        <Select
          label={label}
          onChange={this.setData}
          variant={variant}
          defaultOpen={!otherProps.disabled && defaultOpen}
          {...otherProps}
        >
          {children}
        </Select>
      </FormControl>
    );
    if (grid) {
      return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          {input}
        </Grid>
      );
    }
    return input;
  }
}

CustomSelect.propTypes = {
  path: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  setData: PropTypes.func.isRequired,
  grid: PropTypes.bool
};

CustomSelect.displayName = 'CustomSelect';

export default withDisplaying()(CustomSelect);
