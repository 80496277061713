import React, { useCallback, useEffect, useState } from 'react';

import { Close, WhatsApp } from '@mui/icons-material';
import {
  Chip, IconButton, InputAdornment, Popover,
  Collapse, Avatar, Divider, Tooltip
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/system';

import ContactList from './ContactList';
import { smsTypes, getPinned } from '../constant';
import CustomTextField from '../../CustomInputs/CustomTextField';
import {
  checkWP, getOldConv, searchConversations
} from '../../../actions/conversations';
import { checkPhoneNumber } from '../../../utils/function';
import notifSystem from '../../../notifSystem';
import CustomLoadingButton from '../../Utils/CustomLoadingButton';
import { A_ARC } from '@lba-dev/package.local-globals/aStatus';
import collections from '../../../constants/collections';
import loadImages from '../../../utils/loadImages';

const useStyles = makeStyles()((_theme, { isDash }) => ({
  popup: {
    width: 430,
    maxHeight: 'calc(100% - 115px)',
    overflow: 'hidden',
    top: isDash ? 64 : 70,
    height: '100%'
  },
  adornmentDivider: {
    height: 28,
    width: 1,
    paddingLeft: 4
  }
}));

const genContact = (oldConv, user, text, hasWhatsapp) => {
  const {
    sender = true,
    transmitter = user.login,
    recipient = `${text} - ${user.alias}`,
    date = new Date(),
    text: message = '',
    artisanObject, interObject, devisObject,
    mode = 0,
    intervention, devis, artisan,
    seenBy = []
  } = oldConv || {};
  return {
    'companyName': sender ? recipient : transmitter,
    'login': transmitter,
    'date': new Date(date),
    'dataObject': artisanObject || interObject || devisObject || {},
    'mode': mode || 0,
    '_id': artisan || intervention || devis || text,
    'type': (artisan && 'artisan') ||
        (devis && 'devis') || 'intervention',
    message,
    'unSeenCount': 0,
    'seenBy': seenBy || [],
    'sizeOfCommercial': 1,
    newContact: oldConv ? !!oldConv.newContact : true,
    freshContact: !oldConv,
    hasWhatsapp
  };
};

const ContactPopover = ({
  anchorEl, setAnchorEl,
  selected, setSelected,
  user, smsType, pinConversation, isDash
}) => {
  const { classes } = useStyles({ isDash });
  const [text, setText] = useState('');
  const [isNumber, setIsNumber] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasWhatsapp, setHasWhatsapp] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);

  const callBackIsNumber = useCallback(async (isTel, number) => {
    if (isTel) {
      const hasWS = await checkWP(number);
      setHasWhatsapp(hasWS);
    } else {
      setHasWhatsapp(false);
    }
    setIsNumber(isTel);
  }, [isNumber]);


  const checkNumber = (value) => {
    const isTel = smsType === 'intervention' &&
    checkPhoneNumber(value);
    callBackIsNumber(isTel, value);
  };

  const searchContact = (input) => {
    setLoading(true);
    Promise.all([
      ...smsTypes.filter(e => !['all', 'allPinned'].includes(e.type))
        .map(e => e.collection), 'devis'
    ].map(e => (
      searchConversations(input, e, e === collections.artisans ? 20 : 10)
    )))
      .then(([artisans, ...data]) => {
        const artisanPinned = getPinned('artisan', user.pinnedConversations)
          .map(e => e._id);
        artisans = artisans.filter(e => +e.status !== A_ARC)
          .sort((a, b) => +a.status - +b.status)
          .sort((a, b) =>
            artisanPinned.includes(b._id) - artisanPinned.includes(a._id));
        data = [artisans, ...data].flat();
        setContacts(data);
        setFiltered(data);
        if (!data.length) {
          checkNumber(input);
        }
      })
      .finally(() => setLoading(false));
    checkNumber(input);
  };


  const updateSearch = value => {
    setContacts([]);
    setFiltered([]);
    value = value.replace(/\n/g, '');
    if (value.length >= 3) {
      searchContact(value);
    }
    setText(value);
  };

  useEffect(() => {
    if (filter) {
      const nFiltered = [...contacts].filter(e => e.collection === filter);
      setFiltered(nFiltered);
    } else {
      setFiltered([...contacts]);
    }
  }, [filter]);

  const closePopup = () => {
    setAnchorEl(null);
    setText('');
    setContacts([]);
    setFilter([]);
    setIsNumber(false);
  };

  const selectContact = (item) => {
    setSelected(item);
    closePopup();
  };

  const createContact = async () => {
    const isTel = checkPhoneNumber(text);
    if (!isTel) {
      notifSystem.error('Erreur',
        'Veuiller saisir un numero mobile (06, 07 ou mobile étranger)).');
    }
    setButtonLoading(true);
    const oldConv = await getOldConv(text)
      .finally(() => setButtonLoading(false));
    const contact = genContact(oldConv, user, text, hasWhatsapp);
    selectContact(contact);
  };
  return <Popover
    id="contactSearch"
    open={!!anchorEl}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'top',
      horizontal: isDash ? 'right' : 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: isDash ? 'right' : 'left',
    }}
    PaperProps={{ className: classes.popup }}
    sx={{ left: 20 }}
    onClose={closePopup}
  >
    <CustomTextField
      placeholder={`${smsTypes.map(e => e.title).join(', ')}`}
      autoFocus
      variant='outlined'
      value={text}
      path={[]}
      setData={(path, value) => updateSearch(value)}
      capsLocked={false}
      texttransform='unset'
      InputProps={{
        endAdornment: <InputAdornment position="end">
          {isNumber ? <>
            <Tooltip title={hasWhatsapp ? 'avec whatsapp' : 'sans whatsapp'}>
              <Avatar
                className={classes.imageAvatar}
                style={{
                  width: 18,
                  height: 18,
                }}
                src={loadImages(`WhatsApp${hasWhatsapp ? '' : 'Red'}.svg`)}
                children={<WhatsApp />}
              />
            </Tooltip>
            <Divider
              orientation="vertical"
              className={classes.adornmentDivider}
            />
          </> : ''}
          <IconButton onClick={closePopup}>
            <Close fontSize='small' />
          </IconButton>
        </InputAdornment>
      }}
    />
    {contacts.length ?
      <Box display="flex" justifyContent='space-evenly' p={1}>
        <Chip
          label="Tous"
          color="secondary"
          size="small"
          variant={!filter ? 'filled' : 'outlined'}
          onClick={() => setFilter('')}
        />
        {smsTypes.filter(e => !['all', 'allPinned'].includes(e.type))
          .map((e, i) => {
            const count = [...contacts]
              .filter(f => f.collection === e.collection).length;
            return count ? <Chip
              key={i}
              label={e.type.capitalize()}
              color="primary"
              size="small"
              variant={filter === e.collection ? 'filled' : 'outlined'}
              onClick={() => setFilter(e.collection)}
            /> : '';
          })}
      </Box> : ''}
    <Collapse in={isNumber && !contacts.length}>
      <Box sx={{ p: 1 }}>
        <CustomLoadingButton
          variant='contained'
          fullWidth
          disabled={!isNumber}
          color='primary'
          loading={buttonLoading}
          onClick={createContact}>
          Envoyer un message
        </CustomLoadingButton>
      </Box>
    </Collapse>
    <ContactList
      isSearch
      contacts={filtered}
      selected={selected}
      setSelected={selectContact}
      user={user}
      smsType={smsType}
      loading={loading}
      pinConversation={pinConversation}
    />
  </Popover>;

};

export default ContactPopover;
