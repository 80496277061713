/* eslint-disable max-lines */
import React from 'react';
import { grey, blue, red, purple, green } from '@mui/material/colors';

export const titleInters = [
  'Nom',
  'Numéro',
  'Appels',
  'Appels uniques',
  'Prioritaire'
];

export const filterASC = [
  null,
  'callsTotal',
  'uniquesCalls',
  'lengthAllData',
  'intersAPR',
  'intersENV',
  'turnOverInters',
  'intersANN',
  'turnOverIntersANN',
  'devis',
  'devisAENV',
  'turnOverDevisAENV',
  'devisATT',
  'turnOverDevis',
  'devisANN',
  'turnOverDevisANN',
  'devisTRA',
  'turnOverDevisTRA',
];

export const titleCompareInters = [
  'Numéro',
  'Appels',
  'Appels uniques',
  'Appels manqués',
  'Appels non transformés',
  'Photos',
  'Fiches créées',
  'Interventions APR',
  'Interventions Envoyés',
  'Chiffre d\'affaires',
  'Interventions ANN',
  'Chiffre d\'affaires ANN',
  'Devis TOTAL',
  'Devis à envoyer',
  'Chiffre d\'affaires Devis à envoyer',
  'Devis en attente',
  'Chiffre d\'affaires Devis en attente',
  'Devis annulés / refusés',
  'Chiffre d\'affaires Devis annulés / refusés',
  'Devis Transférés',
  'Chiffre d\'affaires Transférés',

];

export const titleListCalls = [
  'Date',
  'Numéro',
  'Agent',
  'Service',
  'Audio',
];

export const titleListDevisStatus = [
  'A Envoyer',
  'En attente',
  'Transféré',
  'Annulé',
];

export const titleListIntersStatus = [
  'À programmer',
  'En cours',
  'À vérifié',
  'Vérifié',
  'Annulé'
];

export const titleListInters = [
  'Date ajout',
  'OS',
  'Ajouté par',
  'Client',
  'Catégorie',
  'Status',
];

export const styles = (theme) => ({
  paper: {
    padding: '30px 0 50px 0',
    overflow: 'auto',
    width: '100%',
    minHeight: 500
  },
  paperRWDStatsAgent: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  overFlowTab: {
    overflow: 'auto',
  },
  table: {
    marginTop: 20,
    maxHeight: 500,
    overflow: 'auto',
  },
  table2: {
    minHeight: 500,
    maxHeight: 500,
    overflow: 'auto',
  },
  textAlign: {
    textAlign: 'center',
  },
  textAlignCompareTitle: {
    textAlign: 'center',
    fontSize: '0.6rem',
    fontWeight: 'bold',
    maxWidth: 0,
  },
  textAlignCompareName: {
    textAlign: 'center',
    fontSize: '0.7rem',
    fontWeight: 'bold',
  },
  textAlignCompare: {
    textAlign: 'center',
    fontSize: '0.7rem',
  },
  textAlignCompareClickable: {
    textAlign: 'center',
    fontSize: '0.7rem',
    color: blue[500],
    cursor: 'pointer',
  },
  alignCenter: {
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: grey[300],
  },
  titleTable: {
    color: '#fff',
    fontSize: 23,
    fontWeight: 'bold',
    letterSpacing: 3,
  },
  searchBar: {
    margin: '20px 0 0 0',
  },
  chipDiv: {
    alignItems: 'center',
    margin: '10px 10px 10px 0',
  },
  chip: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0 0 0 10px',
    borderRadius: 15,
    marginRight: 10,
    marginBottom: 10,
  },
  avatarDeleteIcon: {
    marginLeft: 10,
    width: 25,
    height: 25,
  },
  deleteIcon: {
    width: 18,
    height: 18,
  },
  popper: {
    maxHeight: 500,
    overflow: 'auto'
  },
  popperClosed: {
    display: 'none'
  },
  progress: {
    width: 90,
    height: 90,
    margin: '15%',
  },
  title: {
    fontSize: 25,
    fontWeight: 300,
  },
  subTitle: {
    fontSize: 13,
    fontStyle: 'italic',
    color: grey[400],
  },
  div: {
    display: 'flex',
    justifyContent: 'center',
  },
  chart: {
    width: 130,
    height: 130,
    margin: 15,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderRadius: 105,
  },
  chartTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: grey[400],
  },
  chartTurnover: {
    fontSize: 18,
    fontWeight: 'bold',
    color: grey[600],
  },
  listCalls: {
    overflow: 'auto',
    minWidth: 1300,
    maxWidth: 1300,
  },
  listItemTitle: {
    backgroundColor: grey[800],
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
  tableRow: {
    display: 'flex',
    alignItems: 'center',
  },
  dialogContent: {
    minWidth: 1100,
    maxWidth: 1100,
    maxHeight: 700,
    overflow: 'auto',
  },
  contentGroupName: {
    margin: '20px 0 0 45px',
  },
  marginTop: {
    marginTop: 50,
  },
  titleSearch: {
    margin: '3% 0% 1% 8%',
    fontSize: '1.3rem',
    color: grey[300],
    fontWeight: 'bold',
  },
  displayChips: {
    transform: 'scale(0.7)',
    backgroundColor: '#2196f3',
    color: '#fff',
  },
  uniquesAudios: {
    margin: '30px 0 30px',
    border: '1px solid #e0e0e0',
    backgroundColor: 'rgb(245, 245, 245)',
  },
  displayInline: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  displayInlineEnd: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  fontSize12: {
    fontSize: 12,
  },
  filterSearchWidth: {
    width: '90%',
  },
  fullWidth: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginTop: '2rem',
    },
  },
  displayEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  containerStats: {
    padding: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addContainerStats: {
    width: 100,
    height: 100,
    margin: 80,
    backgroundColor: blue[50],
    border: `1px dashed ${blue[500]}`,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  input: {
    minWidth: 200,
  },
  bigIcon: {
    transform: 'scale(4)',
    color: 'white',
  },
  statsFooter: {
    minHeight: '15%',
    maxHeight: '15%',
    padding: '10px 0 10px',
    display: 'flex',
    alignItems: 'center',
  },
  buttonStyleStats: {
    margin: 2,
    transform: 'scale(0.7)',
  },
  buttonStyleStatsClose: {
    color: 'white',
    backgroundColor: red[300],
  },
  selectWidth: {
    width: 300,
  },
  maxWidthDialog: {
    maxWidth: 500,
  },
  styleTitle: {
    fontSize: 'large',
    margin: '10px 0 10px',
  },
  titleStatsContent: {
    padding: '10px 10px 0',
    fontSize: 'medium',
    fontStyle: 'oblique',
    fontFamily: 'initial',
  },
  titlePaper: {
    fontSize: 25,
    margin: '0 20px 20px',
    fontFamily: 'initial',
  },
  paperStats: {
    margin: '15px -35px 30px -35px',
    padding: '30px 0 50px 0',
    overflow: 'auto',
  },
  displayBlock: {
    display: 'block',
  },
  disAudioMobile: {
    width: '100%',
    margin: '0 auto',
  },
  contentMobileTab: {
    width: '100%',
    border: '1px solid #ccc',
  },
  titleGreyTab: {
    background: '#e8e8e8',
    color: '#7c7c7c',
    padding: '.2rem',
  },
  txtCenter: { textAlign: 'center' },
  txtData: {
    fontSize: '0.850rem',
    margin: '0.6rem',
  },
  audioBG: {
    background: '#F1F3F4',
    marginBottom: '-5px',
  },
  percent50: {
    width: '50%',
    padding: '0',
  },
  cardAgentList: {
    background: '#f1f1f1',
    margin: '10px 0',
  },
  txtCardData: {
    fontSize: '0.850rem',
    padding: '8px 0',
  },
  txtTitleCard: {
    padding: '10px 0',
    fontSize: '.9rem',
  },
  paperDefault: {
    width: '100%',
    height: 900,
  },
  fullHeight: {
    height: '100% !important',
  },
  fullHeightBis: {
    height: '85% !important',
  },
  displayCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  styleInput: {
    margin: '20px 5px',
    backgroundColor: grey[200],
    borderRadius: 5,
    padding: 5,
    fontSize: 15,
  },
  styleDateInput: {
    margin: '20px 10px',
  },
  iconStyle: {
    color: 'white',
    transform: 'scale(0.8)',
  },
  iconPurple: {
    backgroundColor: purple[200],
  },
  iconRed: {
    backgroundColor: red[200],
  },
  iconGreen: {
    backgroundColor: green[500],
  },
  iconBlue: {
    backgroundColor: blue[500],
  },
  menuStyle: {
    backgroundColor: grey[400],
    padding: 10,
    margin: '20px 0',
    borderRight: '1px solid white',
    color: 'white',
    fontSize: 'larger',
    fontWeight: 'bold',
    fontFamily: 'initial',
    cursor: 'pointer',
  },
  titleUpdateOvh: {
    fontSize: 20,
    color: grey[600],
    fontWeight: 500,
    margin: '25px 0px',
  },
  tabsRoot: {
    boxShadow: '0px 0px',
    padding: 5,
    alignItems: 'center',
    backgroundColor: 'transparent',
    marginBottom: 50,
    fontSize: '2rem',
  },
  inputFile: {
    display: 'none',
  },
  rightIcon: {
    marginLeft: 5,
  },
  audio: {
    minWidth: 250,
  },
  audioDialog: {
    minWidth: 350,
  },
  audioHeight: {
    maxHeight: 150,
  },
});


export function getRowsData(result, rowsPerPage, page) {
  return result.slice(page * rowsPerPage,
    (page * rowsPerPage) + rowsPerPage);
}

export const genStatus = (array, status) => {
  const elem = array.find(e => e._id === status);
  return (elem ?
    <span key={elem.name} style={{
      color: elem.color['500'],
      display: 'inline-flex'
    }}>
      {elem.name}
    </span> : null);
};

export const getTurnOverDevis = (arr) => (
  arr.reduce((acc, curr) =>
    (acc += curr.qt * curr.pu), 0)
);

export const getReglements = (arr) => {
  const reducer = (acc, curr) =>
    (acc += curr.ht);
  return arr.reduce(reducer, 0);
};

export const getNumberOf = (arr, status) => (
  arr.length ? arr.filter(e => e.status === status).length : 0
);

export const getNumbers = (inter, changeInfosCalls, callsInfos) => {
  const numbersClient = inter.client ?
    [inter.client.tel1, inter.client.tel2, inter.client.tel3] : [];
  const numbersBilling = inter.billing ?
    [inter.billing.tel1, inter.billing.tel2, inter.billing.tel3] : [];
  changeInfosCalls(callsInfos.filter(audio =>
    [...new Set(numbersClient.concat(numbersBilling).filter(e => e))]
      .find(number => number === audio.record.callerIdNumber)));
};
