import React from 'react';

import { Map } from 'immutable';

import Paper from '@mui/material/Paper';
import { withStyles } from 'tss-react/mui';

import {
  PAN_ENC, PAN_REF, PAN_VAL, PAN_PRE
} from '@lba-dev/package.local-globals/paymentAnnexeStatus';
import {
  P_MATP, P_FRCO
} from '@lba-dev/package.local-globals/PaymentCategories';
import { permit } from '@lba-dev/package.local-globals/restrictions';

import {
  getPaymentAnnexe,
  addPaymentAnnexe,
  updatePaymentAnnexe,
  deletePaymentAnnexe,
  sendMailPaiement,
} from '../../../actions/paymentAnnexes';
import PaymentAnnexesTable from '../../PaymentAnnexesTable';
import PaymentAnxMenu from '../../PaymentAnnexesTable/menu';
import { withDisplaying } from '../../../hoc';
import { S_JUR } from '@lba-dev/package.local-globals/services';
import { calculateAllPrices } from '@lba-dev/package.local-globals/formatPrice';

const styles = {
  div: {
    width: '100%'
  },
};

const getTab = (service) => ![S_JUR].includes(service)
  ? P_MATP - 1
  : P_FRCO - 1;

class PaymentTable extends React.Component {
  state = {
    data: new Map(),
    selectedTab: getTab(this.props.user.service),
    isRec: this.props.user.service === S_JUR
  }
  tableRef = React.createRef();

  handleTabChange = (e, v) => {
    this.setState({ selectedTab: v }, () =>
      this.tableRef &&
      this.tableRef.current.onQueryChange({ page: 0 }));
  }

  addPaymentAnnexe = (data) => {
    const { userId, intervention } = this.props;
    const dates = intervention.date;
    const encaissement = (intervention.encaissement || 0);
    const decaissement = (intervention.decaissement || 0);
    const { price } = calculateAllPrices(
      intervention.products,
      intervention.finalPrice || intervention.annoucedPrice || 0
    );
    const newData = {
      ...data,
      id: intervention.id,
      login: { ajout: userId },
      date: {
        ajout: new Date(),
        intervention: dates.intervention,
        ...(dates.recouvrement ? {
          recouvrement: dates.recouvrement
        } : {})
      },
      montantInter: +price * 100,
      recoveredAmount: encaissement - decaissement,
      type: this.state.selectedTab + 1,
      status: this.state.isRec ? PAN_PRE : PAN_ENC,
      ...((intervention.recovery || {}).recStatus ? {
        recStatus: (intervention.recovery || {}).recStatus
      } : {}),
    };
    return addPaymentAnnexe(newData).then(
      () => this.tableRef.current && this.tableRef.current.onQueryChange())
      .then(() => sendMailPaiement({ ...newData, isAdded: true }));
  };

  updatePaymentAnnexe = ({ ...newData }) =>
    updatePaymentAnnexe(newData).then(
      () => this.tableRef.current && this.tableRef.current.onQueryChange()
    );

  deletePaymentAnnexe = (oldData) =>
    deletePaymentAnnexe(oldData).then(
      () => this.tableRef.current && this.tableRef.current.onQueryChange()
    );

  setInter = (path, value) => {
    const { data } = this.state;
    this.setState({
      data: data.setIn(path, value),
    });
  };

  render() {
    const { intervention, users, user, withPaper } = this.props;
    const { selectedTab, isRec } = this.state;
    const canAdd = user && permit(user, { key: 'menuReglementClient' });
    const canEdit = user && permit(user, { key: 'annexeFlush' });

    const table = <PaymentAnnexesTable
      title="Paiement annexes"
      tableRef={this.tableRef}
      isRec={isRec}
      selectedTab={selectedTab}
      hideCheckInput={true}
      user={user}
      canEdit={canEdit}
      detailPanel={(data) => <PaymentAnxMenu data={data} />}
      handleTabChange={this.handleTabChange}
      data={getPaymentAnnexe({
        id: intervention.id,
        users,
        ...(isRec
          ? { status: { $in: [PAN_ENC, PAN_PRE, PAN_VAL, PAN_REF] } }
          : { status: { $in: [PAN_ENC, PAN_VAL, PAN_REF] } }
        ),
        type: selectedTab + 1
      })}
      editable={{
        onRowAdd: canAdd && this.addPaymentAnnexe,
        onRowUpdate: canAdd && this.updatePaymentAnnexe,
        onRowDelete: canAdd && this.deletePaymentAnnexe,
        isEditable: (data) => canAdd && (isRec
          ? data.status === PAN_PRE
          : data.status === PAN_ENC),
        isDeletable: (data) => canAdd && (isRec
          ? data.status === PAN_PRE
          : data.status === PAN_ENC),
      }}
      options={{
        actionsColumnIndex: -1,
        headerStyle: {
          padding: 0,
        }
      }}
    />;

    return withPaper ? <Paper children={table} /> : table;
  }
}

PaymentTable.displayName = 'PaymentTable';

export default withDisplaying()(withStyles(PaymentTable, styles));
