import {
  ANNULATIONS_FETCH,
  ANNULATIONS_UPDATE
} from '../constants/ActionTypes';
import DbWsAction from './models/DbWsAction';

const action = new DbWsAction(
  ANNULATIONS_FETCH,
  ANNULATIONS_UPDATE
);

export const fetch = action.fetch;
export const update = action.update;

