import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import classNames from 'classnames';

import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { withStyles } from 'tss-react/mui';

const mapStateToProps = ({ grandCompte }, { filterB2B }) => ({
  grandCompte,
  filterB2B
});

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    height: 35,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 100,
    marginTop: theme.spacing(1),
    right: 0,
    width: 408,
    maxWidth: '98%',
    marginRight: -12,
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    background: 'white',
    listStyleType: 'none',
    maxHeight: '300px',
    height: '100%',
    overflow: 'auto',
    width: '98%'
  },
  searchInput: {
    float: 'right',
    padding: '4px',
    border: 'rgba(0, 0, 0, 0.15) 2px solid',
    borderRadius: 6,
    margin: '8px auto',
    minWidth: '12.5em',
    maxWidth: '90%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '28.5em'
    }
  },
  suggestionsCustomList: {
    top: 44,
    boxShadow: '0px 2px 30px 1px rgba(0,0,0,0.14)'
  }
});

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;
  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}


function renderInput(inputProps, onValidate) {
  const { classes, ref, width, ...other } = inputProps;
  delete other.maxWidth;
  return (
    <TextField
      variant="standard"
      className={classes.searchInput}
      onKeyDown={e => e.key === 'Enter' && onValidate(other.value)}
      style={{ width }}
      InputProps={{
        inputRef: ref,
        classes: {
          input: classes.input,
        },
        ...other,
      }} />
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

class SearchBtoB extends Component {
  state = {
    value: '',
    suggestions: [],
    btobId: 0,
    nextBtobId: 0,
    grandCompte: this.props.filterB2B
      ? this.props.grandCompte.filter(this.props.filterB2B)
      : this.props.grandCompte
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value &&
      prevProps.value !== this.state.value) {
      this.setState({ value: prevProps.value });
    }
  }

  findGrandCompte = () => {
    const {
      setGrandCompte,
    } = this.props;
    const { btobId, grandCompte } = this.state;

    if (btobId !== '') {
      const item = grandCompte.find(e => e.get('_id') === btobId);

      if (item) {
        setGrandCompte(item);
        return true;
      }
      return false;
    }
  }

  onChange = (event, { newValue: value }) => {
    const { nextBtobId, btobId } = this.state;

    if (nextBtobId !== btobId) {
      this.setState({
        value,
        btobId: nextBtobId
      }, () => this.findGrandCompte() === true
        && this.setState({ value: '' })
      );
    }
    this.setState({ value });
  }

  getSuggestions = toFind => {
    const grandCompte = this.state.grandCompte.toJS();

    return toFind
      ? grandCompte
        .filter(x =>
          `${x.companyName || ''} ${x.name}`
            .toUpperCase()
            .includes(toFind.toUpperCase())
        )
        .slice(0, 10)
      : grandCompte;
  }

  onSuggestionsFetchRequested = ({ value }) =>
    this.setState({
      suggestions: this.getSuggestions(value)
    })

  onSuggestionsClearRequested = () => this.setState({ suggestions: [] })

  onSuggestionSelected = (e, { suggestionIndex } ) =>
    this.setState({ suggestionIndex })

  onValidate = (value) => {
    const { grandCompte } = this.state;
    const gc = grandCompte.find(gc =>
      ['companyName', 'name']
        .some(key => gc.get(key, '').toLowerCase() === value.toLowerCase()));

    if (gc) {
      this.setState({
        nextBtobId: gc.get('_id'),
        btobId: gc.get('_id'),
      }, this.findGrandCompte);
    }
  }

  renderSuggestion = suggestion =>
    <MenuItem
      button
      onClick={() => this.changeInitState(suggestion)}
      component="div"
    >
      <div>
        <span>{suggestion.companyName || suggestion.name} - </span>
        <span style={{ color: '#616161de', textTransform: 'capitalize' }}>
          {(suggestion.name || '').toLowerCase()
          } {(suggestion.firstname || '').toLowerCase()}
        </span>
      </div>
    </MenuItem>

  changeInitState = suggestion => this.setState({
    nextBtobId: suggestion._id,
    btobId: suggestion._id,
  }, this.findGrandCompte)

  render() {
    const { classes, interMobile } = this.props;
    const { value, suggestions } = this.state;
    const inputProps = {
      classes,
      placeholder: 'Rechercher un grand compte',
      value,
      onChange: this.onChange,
      width: '100%',
      maxWidth: interMobile ? '' : '90%',
    };

    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classNames(
            classes.suggestionsList,
            classes.suggestionsCustomList
          ),
          suggestion: classes.suggestion,
        }}
        inputProps={inputProps}
        suggestions={suggestions}
        renderInputComponent={(inputProps) =>
          renderInput(inputProps, this.onValidate)}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestionsContainer={renderSuggestionsContainer}
        renderSuggestion={this.renderSuggestion}
        onSuggestionSelected={this.onSuggestionSelected}
        shouldRenderSuggestions={() => true}
      />
    );
  }
}

export default connect(mapStateToProps)(withStyles(SearchBtoB, styles));
