import React from 'react';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import Transactions from './TransactionsTable';
import { withStyles } from 'tss-react/mui';

const styles = {
  div: {
    width: '100%'
  },
};

const mapStateToProps = ({
  dialog: {
    contentProps: {
      inter
    },
  },
}) => ({
  inter
});


const DialogTransaction = ({ classes, inter }) => (
  <div className={classes.div}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Transactions inter={inter} />
      </Grid>
    </Grid>
  </div>
);

export default connect(
  mapStateToProps,
)(withStyles(DialogTransaction, styles));

