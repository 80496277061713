import {
  USER_ID_FETCH
} from '../constants/ActionTypes';

const initialState = {};
export default (state = initialState, action) => {
  switch (action.type) {
    case USER_ID_FETCH:
      return action.userId;
    default:
      return state;
  }
};
