import React, { useEffect } from 'react';
import CustomTextField from '../../CustomInputs/CustomTextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';
import { listMailsSST } from '@lba-dev/package.local-globals/listMails';

const useStyles = makeStyles()({
  margin: { marginTop: 20 }
});

const key = 'Changement de SST';

const ChangeSSTMail = ({
  changementSSTMail,
  date,
  savInter,
  artisan,
  updateSAVData,
  user
}) => {
  const { classes } = useStyles();

  const mailChangeSST = () => {
    const changementSSTMail = listMailsSST({ savInter, artisan, date, user })
      .find(e => e.name === key);
    updateSAVData('changementSSTMail', changementSSTMail);
  };

  useEffect(() => {
    mailChangeSST();
  }, []);

  return (
    <Grid container justifyContent="center" align="center" spacing={2}>
      <Grid item xs={12}
        container justifyContent="center" align="center" spacing={2}
      >
        <Grid item xs={12} className={classes.margin}>
          <Button variant="outlined" color="secondary" fullWidth>
            Mail Changement SST
          </Button>
        </Grid>
        <CustomTextField className={classes.margin}
          grid xs={12} texttransform='none'
          rows={1}
          label="Destinataire"
          value={changementSSTMail.desMail || ''}
          setData={(p, v) =>
            updateSAVData(key, { ...changementSSTMail, desMail: v })}
        />
        <CustomTextField className={classes.margin}
          grid xs={12}
          texttransform='none'
          rows={1}
          label="Objet"
          value={changementSSTMail.subject || ''}
          setData={(p, v) =>
            updateSAVData(key, { ...changementSSTMail, subject: v })}
        />
        <CustomTextField
          grid xs={12}
          texttransform='none'
          rows={15}
          label="Corps du Mail"
          value={changementSSTMail.text || ''}
          setData={(p, v) =>
            updateSAVData(key, { ...changementSSTMail, text: v })}
        />
      </Grid>
    </Grid>
  );
};

export default ChangeSSTMail;
