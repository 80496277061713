import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { DINTERRIB } from '@lba-dev/package.local-globals/docTypes';

import api from '../../../api';
import notifSystem from '../../../notifSystem';
import {
  withChanges,
  withGrid,
  withLoading,
} from '../../../hoc';
import RibClient from '../../RibClient';
import FileDisplayer from '../../Utils/FileDisplayer';

const mapStateToProps = state => ({
  user: state.users.find(u => u._id === state.userId),
});

const GridedRibClient = withGrid({ xs: 12, md: true })(RibClient);
const GridedFileDisplayer = withGrid({ xs: 12, md: 6, lg: 4 })(FileDisplayer);

const RibMenu = ({
  merge,
  user,
  updateData,
  setMerge,
  setOriginal,
  setCurrent,
  setLoading,
}) => {
  const validateRib = async () => {
    try {
      const newIban = merge.get('newIban');
      const newBic = merge.get('newBic');

      await setLoading(true);
      await api.interventions.patch(merge.get('_id'), {
        iban: newIban,
        bic: newBic,
        verifRIB: true,
      });
      const data = merge.withMutations(map => {
        map
          .set('iban', newIban)
          .set('bic', newBic)
          .set('verifRIB', true);
      });

      await Promise.all([
        setMerge(data),
        setOriginal(data),
        setCurrent({}),
      ]);
      await setLoading(false);
    } catch (e) {
      notifSystem.error('Erreur', e.response ? e.response.data : e.message);
    }
  };
  const fileToDisplay = merge
    .get('dropbox')
    .findLast(e => e.get('type') === DINTERRIB);

  return (
    <>
      <GridedRibClient
        user={user}
        merge={merge}
        setData={updateData}
        validateRib={validateRib}
      />
      {!!fileToDisplay && (
        <GridedFileDisplayer
          content={fileToDisplay.get('link')}
          name={fileToDisplay.get('fileName')}
        />
      )}
    </>
  );
};

export default compose(
  withLoading({ circularProgressProps: { size: 90 } }),
  withChanges(props => props.elem),
  connect(mapStateToProps),
  withGrid({ item: false, container: true, spacing: 2 }),
)(RibMenu);
