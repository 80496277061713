import React, { PureComponent } from 'react';
import Dialog from '@mui/material/Dialog';
import Content from './Content';

class DialogEnvoiDevis extends PureComponent {
  render() {
    const { open, ...props } = this.props;
    return (
      <Dialog open={open} onClose={this.onClose}
        PaperProps={{
          style: { maxWidth: 700 }
        }} fullWidth>
        <Content {...props}/>
      </Dialog>);
  }
}

export default DialogEnvoiDevis;
