import React from 'react';

import Grid from '@mui/material/Grid';
import { genHTMLContent, Typography } from '../../helpers';
import ShowMore from '../ShowMore';

export default ({
  element: {
    attachementsHelpArtisan, audioAQuestion, audioASpeech,
    ...element
  }
}) => (
  <Grid container>
    <Grid item xs={12}>
      <Typography
        variant="h6"
        component="h5"
        children={'Questions pour l\'artisan ?'}
      />
      {genHTMLContent(element.artisanQuestions)}
    </Grid>
    <ShowMore
      element={{ audio: audioAQuestion }}
      text={element.artisanQuestions}
      title="Questions pour l'artisan ?"
    />
    <Grid item xs={12}>
      <Typography
        variant="h6"
        component="h5"
        children={'Pièges à éviter'}
      />
      {genHTMLContent(element.helpArtisan)}
    </Grid>
    <ShowMore
      element={{ attachements: attachementsHelpArtisan }}
      text={element.helpArtisan}
      title="Pièges à éviter"
    />
    <Grid item xs={12}>
      <Typography
        variant="h6"
        component="h5"
        children={'Discours artisan'}
      />
      {genHTMLContent(element.artisanSpeech)}
    </Grid>
    <ShowMore
      element={{ audio: audioASpeech }}
      text={element.artisanSpeech}
      title="Discours artisan"
    />
  </Grid>
);
