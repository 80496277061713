import {
  NOT_CONFIRMED
} from '@lba-dev/package.local-globals/permissions';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import componentLoader from '../../componentLoader';

const ListDemandes = componentLoader(() =>
  import(
    /* webpackChunkName: "ListDemandes" */
    '../../views/List/ListDemandes'
  )
);

const ListDevis = componentLoader(() =>
  import(
    /* webpackChunkName: "ListDevis" */
    '../../views/List/ListDevis'
  )
);

const AjoutDevis = componentLoader(() =>
  import(
    /* webpackChunkName: "AjoutDevis" */
    '../../views/AjoutDevis'
  )
);

export default [
  {
    path: '/devis/list/:filter/:query',
    component: ListDevis,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/devis/list/:filter',
    component: ListDevis,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/devis/list',
    component: ListDevis,
    restrictions: (user) =>
      permit(user, { key: 'interDevisListAccess' }) && user.auth,
    redirectLink: () => '/'
  },
  {
    path: '/devis/:id/:query',
    component: AjoutDevis,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/devis/:id',
    component: AjoutDevis,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/devis',
    component: AjoutDevis,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/demandes/list/:filter/:query',
    component: ListDemandes,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/demandes/list/:filter',
    component: ListDemandes,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/demandes/list',
    component: ListDemandes,
    restrictions: () => NOT_CONFIRMED,
  },
];
