import {
  CALL_LOAD_ACTIVITIES_ARTISAN,
  setListActivitiesArtisan,
} from '../actions/etatReseau';
import notifSystem from '../notifSystem';
import api from '../api';

const loadActivitiesArtisan = (store) => (next) => (action) => {
  switch (action.type) {
    case CALL_LOAD_ACTIVITIES_ARTISAN: {
      let result = [];
      api.history
        .custom('getHistory')
        .post({ id: action.artisanId })
        .then((history) => {
          result = history.body().map((e) => e.data());
        })
        .catch(() => {
          notifSystem.error('Erreur', 'Récupération d\'activite de l\'artisan');
        })
        .finaly(() => {
          store.dispatch(setListActivitiesArtisan(result));
        });
    }
  }
  next(action);
};

export default loadActivitiesArtisan;
