import {
  SET_DIALOG,
  SET_DIALOG_CONTENT_PROP,
  RESET_DIALOG_CONTENT_PROP
} from '../constants/ActionTypes';

const initialState = {
  name: '',
  open: false,
  hideClose: false,
  contentProps: {},
  dialogProps: {},
  actions: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DIALOG:
      return {
        ...state,
        name: action.name,
        open: action.open,
        hideClose: action.hideClose,
        dialogProps: action.dialogProps,
        contentProps: action.contentProps,
        actions: action.actions
      };
    case SET_DIALOG_CONTENT_PROP:
      return {
        ...state,
        contentProps: {
          ...state.contentProps,
          [action.key]: action.value
        }
      };
    case RESET_DIALOG_CONTENT_PROP:
      return {
        ...state,
        contentProps: action.contentProps
      };
    default:
      return state;
  }
};
