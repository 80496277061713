/* eslint-disable max-lines */
import React from 'react';
import { fromJS, Map } from 'immutable';

import { AssignmentTurnedIn } from '@mui/icons-material';
import { green, orange } from '@mui/material/colors';
import Button from '@mui/material/Button';

import { groupBy } from '@lba-dev/package.local-globals/functions';
import { S_DEVIS } from '@lba-dev/package.local-globals/services';
import {
  MIN_AMOUNT_DEVIS
} from '@lba-dev/package.local-globals/domofinance';
import {
  defaultText as promotionMail
} from '@lba-dev/package.local-globals/promotionConstants';
import devisLetter
  from '@lba-dev/package.local-globals/devisLetter';

import DialogEnvoiDevis
  from '../components/Dialogs/DialogEnvoiDevis/EnvoiDevisFromMenu';
import DialogGetClientNumber from '../components/Dialogs/DialogGetClientNumber';
import ConfirmBox from '../components/Dialogs/ConfirmBox';
import { DialogList } from '../components/Dialogs/';
import IphoneDialog from '../components/Dialogs/IphoneDialog';

import Money from '../utils/Money';
import { formatPrice, throwIt } from '../utils/function';
import { getFinanceConsignes } from '../utils/financement';
import { checkPhoneAndMail } from '../utils/checkInputs';
import { generateSmsDevis } from '../constants/sms/smsDevis';
import {
  checkProducts, checkIsMobileNumber, checkAndOpenCaldeo
} from './verifications';
import { openAssignDialog, openPreview } from './general';
import { setDialog } from './dialog';
import notifSystem from '../notifSystem';
import store from '../store';
import api from '../api';
import { openChatPanel } from './chatPanel';
import {
  copyToIntervention
} from '@lba-dev/package.local-globals/copyData/copyToIntervention';
import { createGoogleCalendar } from './googleCalendar';
import { rappelContent } from '../components/List/RappelContent';
import { site } from '../constants/config';
import { data as jobs } from '@lba-dev/package.local-globals/categories';

export function cancel(props, devis, cb) {
  props.setDialog(
    ConfirmBox,
    true,
    {
      title: 'Etes-vous sûr de vouloir annuler le devis ?',
      confirmBox: 'Oui',
      closeBox: 'Annuler',
    },
    (confirmed) => {
      if (confirmed) {
        api
          .one('devis', devis.id)
          .custom('cancel')
          .post()
          .then(() => {
            if (cb) {
              cb();
            }
            notifSystem.success('Opération réussie', 'Devis annulé');
          })
          .catch(() =>
            notifSystem.error('Erreur', 'L\'action n\' pas pu être réalisée')
          );
      }
      props.setDialog(null, false, null, null);
    }
  );
}

const callbackTransfer = async (props, devis, save, resCallBack) => {
  try {
    if (!resCallBack) {
      return props.setDialog(null, false, '', null);
    }
    resCallBack =
      typeof resCallBack === 'string' ? resCallBack.match(/\d+/g) : '';
    if (resCallBack.length) {
      devis = {
        ...devis,
        products: {
          ...devis.products,
          list: devis.products.list.filter(
            (s) => s.comboIndex === Number(resCallBack[0]) - 1
          ),
        },
      };
    }
    if (save) {
      await api.one('devis', devis.id).custom('transfer').post();
      notifSystem.success('Opération réussie', 'Le devis a été transferé');
    } else {
      let merge = fromJS(devis);
      const consignes = getFinanceConsignes(merge);
      if (consignes) {
        merge = merge.setIn(['infoDesc', 'consignes'], consignes);
      }
      const userAjout = props.users.find((e) => e._id === devis.login.ajout);
      devis.login.ajout = (userAjout && userAjout._id) || devis.login.ajout;
      if (devis?.combination?.desc && !merge.getIn(['infoDesc', 'desc'])) {
        merge = merge.setIn(['infoDesc', 'desc'], devis.combination.desc);
      }
      const devisSuppFromSupp = props.userId === userAjout._id &&
        userAjout.service === S_DEVIS &&
        merge.getIn(['demande', 'isDevisSupp'])
      props.navigate(`/intervention/${devis.id}`, {
        state: {
          copy: copyToIntervention(
            merge.toJS(), {
              collection: 'devis',
              devisSuppFromSupp
            }
          )
        },
      });
    }
  } catch (error) {
    notifSystem.error('Erreur', 'Devis n\'a pas été transferé');
  }
};

export const openPropositionList = (props, devis, groups) =>
  new Promise(res =>
    props.setDialog(DialogList, true, {
      title: 'Quelle proposition voulez-vous transférer ?',
      data: Object
        .keys(groups)
        .map(e => {
          const price = Money.toString(
            devis
              .products
              .list
              .reduce((v, l) =>
                v + (l.comboIndex === Number(e)
                  ? (l.pu * l.qt)
                  : 0
                ), 0),
            true,
          );

          return `Proposition n°${Number(e) + 1}, Prix : ${price} T.T.C`;
        }),
      Icon: AssignmentTurnedIn,
    }, res)
  );

export async function transfer(props, devis, save) {
  try {
    if (devis.products && devis.products.list) {
      const groups = groupBy(devis.products.list, 'comboIndex');
      if (Object.keys(groups).length > 1) {
        const res = await openPropositionList(props, devis, groups);
        callbackTransfer(props, devis, save, res);
      } else {
        callbackTransfer(props, devis, save, true);
      }
    } else {
      notifSystem.error('Erreur', 'Merci d\'ajouter des produits');
    }
  } catch (error) {
    notifSystem.error('Erreur', 'Devis n\'a pas été transferé');
  }
}

export async function send(props, devis, cb) {
  await api.one('devis', devis.id).custom('send').post({
    date: props.date,
    bodyMail: props.multiline,
    attachments: props.files,
  });
  notifSystem.success('Opération réussie', 'Devis envoyé');
  if (cb) {
    cb();
  }
}

export function sendSMS(props, devis) {
  function smsCallback(message, { mode }) {
    props.setDialog(null, false, '', null);
    if (message) {
      props.save().then((newDevis) => newDevis &&
        api.devis
          .custom(`${newDevis._id}/sendSMS`)
          .post({
            message,
            mode,
            comboAttachments: devis.products.comboAttachments || []
          })
          .then(() =>
            notifSystem.success('Opération réussie', 'Le SMS à bien été envoyé')
          )
          .catch((e) => {
            notifSystem.error('Erreur', e.response ?
              e.response.data : 'Le SMS n\'a pu être envoyé.');
          })
      );
    }
  }
  props.setDialog(
    IphoneDialog,
    true,
    {
      text: generateSmsDevis(devis, props.user),
      devisSms: true,
      disabled: true
    },
    smsCallback
  );
}

export function sendFromMenu(props, devis) {
  const callbacks = {
    onClose: () => props.setDialog(null, false, '', null),
    send: () => devis,
    navigate: props.navigate,
  };
  checkAndOpenCaldeo(fromJS(devis), {
    type: 'devis',
    saveOnly: true,
    navigate: props.navigate,
    userId: props.userId,
    fromList: true,
    cb: (nDevis = devis) =>
      props.setDialog(DialogEnvoiDevis, true, nDevis, callbacks)
  });

}

export function sendSmsFromMenu(props, devis) {
  const checkedPhone = checkPhoneAndMail([['tel1', 'tel2', 'tel3']]);
  if (checkedPhone(devis.client)[0].length) {
    return notifSystem.error('Attention',
      'Le client doit avoir un numéro de téléphone');
  } else if (checkProducts(devis)
    && checkIsMobileNumber(fromJS(devis))) {
    sendSMS({ ...props, save: () => Promise.resolve(devis) }, devis);
  }
}

export function previewDevis(devis) {
  openPreview({
    method: 'completeDevis',
    info: { type: 'devis' },
    data: { devis }
  }, `Devis n° ${devis.id || ''}`);
}

export const checkClientNumber = async (props, obj, name, cb) => {
  const { numbers, isNew } = obj;
  const callbacks = (response) => {
    if (response && cb) {
      cb();
    }
    props.setDialog(null, false, '', null);
  };
  if (isNew) {
    const promises = name.map((e) =>
      api[e].custom('checkNumber').get({ numbers })
    );
    let data = await Promise.all(promises);
    data = name.reduce(
      (e, i, index) => ({
        ...e,
        [i]: data[index].body().map((d) => d.data()),
      }),
      {}
    );
    if (name.find((e) => data[e].length)) {
      props.setDialog(DialogGetClientNumber, true, { data, name }, callbacks);
    } else {
      cb();
    }
  } else {
    cb();
  }
};

export function updateDevis(id, data) {
  return new Promise((resolve, reject) =>
    api.devis.patch(id, data).then(resolve).catch(reject)
  );
}

const saveDomofinance = (domofinance, {
  close, setData, save, navigate,
  redirect, remindFacture, cb
}) => {
  Promise.all([
    remindFacture !== !domofinance.get('valid', false) &&
      setData('remindFacture', !domofinance.get('valid', false)),
    setData('domofinance', domofinance)
  ].filter(Boolean)).then(() => save(false).then(data => {
    navigate(`/${redirect || 'devis'}/${data.id}`);
    if (cb) {
      cb();
    } else if (close) {
      close();
    }
  }));
};

const sendDomofinance = (data, domofinance, close) => {
  const devisId = data.getIn(['createdFrom', 'collection']) === 'devis'
    && data.getIn(['createdFrom', '_id']);
  if (devisId) {
    updateDevis(devisId, {
      domofinance,
      finalPrice: data.get('finalPrice'),
      products: data.get('products').toJS(),
    }).then(({ body }) =>
      send({
        multiline: devisLetter(body().data())
      }, body().data()).then(() => close()).catch(throwIt)
    ).catch((e) =>
      notifSystem.error('Erreur', (e && e.message) || 'Devis non envoyé'));
  }
};

const checkDomofinancePrice = (domofinance) => {
  if ((domofinance.get('amount', 0) - domofinance.get('contribution', 0))
    >= MIN_AMOUNT_DEVIS * 100) {
    return true;
  }
  notifSystem.error('Erreur domofinance',
    `Le montant ne peut être inférieur à ${MIN_AMOUNT_DEVIS} €`);
  return false;
};

export const openDomofinance = (data, props) =>
  store.dispatch(setDialog({
    name: 'DomofinanceDialog',
    open: true,
    contentProps: {
      data,
      domofinance: data.get('domofinance', new Map()),
    },
    dialogProps: {
      title: 'Domofinance',
      customHeader: <Button
        style={{
          color: data.getIn(['domofinance', 'modified']) ?
            green[500] : orange[500],
          borderColor: data.getIn(['domofinance', 'modified']) ?
            green[500] : orange[500],
        }}
        variant={'outlined'}
        children={data.getIn(['domofinance', 'modified']) ?
          'FINANCEMENT' : 'EXEMPLE'}
      />
    },
    actions: [
      props.redirect === 'intervention' && {
        children: 'Envoyer',
        color: 'primary',
        onClick: (
          { domofinance },
          close
        ) => {
          if (checkDomofinancePrice(domofinance)) {
            if (!domofinance.get('modified')) {
              domofinance = domofinance.set('modified', true);
            }
            saveDomofinance(domofinance, {
              ...props,
              remindFacture: data.get('remindFacture', false),
              cb: () => sendDomofinance(data, domofinance, close)
            });
          }
        }
      },
      {
        children: 'Enregistrer',
        color: 'primary',
        onClick: (
          { domofinance },
          close
        ) => {
          if (checkDomofinancePrice(domofinance)) {
            if (!domofinance.get('modified')) {
              domofinance = domofinance.set('modified', true);
            }
            saveDomofinance(domofinance, {
              ...props,
              remindFacture: data.get('remindFacture', false),
              close
            });
          }
        }
      }
    ].filter(Boolean),
  }));

export const openAssignDevisDialog = (props, devis) =>
  openAssignDialog({ data: devis, collection: 'devis' });

export const handlePromoMail = (
  text, changes = {}, merge, updateData
) => {
  if (!merge.getIn(['promotion', 'enabled'])) {
    return;
  }
  if (!text) {
    return updateData(
      ['promotion', 'mail'],
      promotionMail({
        categorie: changes.category || merge.get('categorie'),
        products: (
          changes.products ||
            merge.get('products') ||
            new Map()
        ).toJS(),
        promotion: (changes.promotion || merge.get('promotion')).toJS(),
      })
    );
  }
  return updateData(['promotion', 'mail'], text.replace(/\n/g, '<br>'));
};

export function showSMS(props, elem) {
  store.dispatch(openChatPanel(elem._id, 'devis'));
}
/**
 * SMART RELAUNCH OF UNCONFIRMED QUOTE HANDLER
 * @param {*} props - Properties of current component derived
 * @param {*} quote - Current quote to relaunch
 * @param {() => void} cb - handler
 */
export async function relaunchUnconfirmedQuoteHandler(props, quote, cb) {

  try {
    await api.devis
      .custom(`${quote._id}/relaunchQuoteUnconfirmed`)
      .post({
        quote: JSON.stringify(quote)
      })
    if (cb) {
      cb();
    }
    notifSystem.success(
      'Opération réussie !',
      'Relance intelligente effectuée'
    )
  } catch (error) {
    /**
     * @type {{ response: {
     * data: string | any, method: string, statusCode: number, headers: object
     * }}}
     */
    const errorJson = JSON.parse(
      JSON.stringify(error)
    );
    switch (errorJson.response.statusCode) {
      case 404:
        if ('notfoundrecordquoteerror' in errorJson.response.headers) {
          return notifSystem.error(
            'Enregistrement introuvable', errorJson.response.data
          );
        }
        if ('notfoundcommercialquoteerror' in errorJson.response.headers) {
          return notifSystem.error(
            'Commercial introuvable', errorJson.response.data
          );
        }
        return notifSystem.error(
          'Devis introuvable',
          'Nous avons pas trouvé le devis selectionné.'
        );
      case 400:
        return notifSystem.error(
          'Informations manquantes',
          `L'historique des appels de ce devis est 
          requis pour effectuer une relance intelligente.`
        );
      case 422:
        if ('morequotewithsamecallernumber' in errorJson.response.headers) {
          return notifSystem.error(
            'Doublon de numéro client', errorJson.response.data
          );
        }
        if (
          'invalidtranscriptionrecordquoteerror' in errorJson.response.headers
        ) {
          return notifSystem.error(
            'Transcription invalide', errorJson.response.data
          );
        }
        return notifSystem.error(
          'Erreur',
          'La relance intelligente a échouée.'
        );
      default:
        return notifSystem.error(
          'Erreur',
          'La relance intelligente a échouée.'
        );
    }
  }
}

export const createReminder = (devisId, comment, date) =>
{
  api.demandes.custom(`addReminder/${devisId}`).post({
    comment: comment.trim(),
    date,
  })};

export const reminderAction = ({ selected, }) => {
  const cat = jobs.find(c => c._id === selected.categorie)
  const title = `Rappel
  ${selected.client.firstname} ${selected.client.name} - ${cat.name}`;
  store.dispatch(setDialog({
    name: 'ReminderDialog',
    open: true,
    dialogProps: {
      title,
    },
    contentProps: {
      withComment: true,
    },
    actions: [
      {
        children: 'Enregistrer',
        color: 'primary',
        onClick: ({ date = new Date(), commentaire }, close) => {
          createGoogleCalendar(
            date,
            title,
            rappelContent(
              {
                type: 'devis',
                price: formatPrice(selected.finalPrice / 100),
                number: selected.client.address.number,
                road: selected.client.address.road,
                zipCode: selected.client.address.zipcode,
                city: selected.client.address.city,
                firstname: selected.client.firstname,
                name: selected.client.name,
                tel: selected.client.tel1,
                email: selected.client.email1,
                date: selected.date.ajout,
                lien: `${site}/devis/${selected.id}`,
                commentaire: commentaire,
                description: selected.infoDesc?.desc
              }
            )
          );

          close();
        },

      }
    ]
  }));
};
