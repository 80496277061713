import React from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CountDown from '../../components/CountDown';
import ProgressLine from '../Utils/ProgressLine';
import {
  globalStatus,
  allStatus,
  A_CONTACTER
} from '@lba-dev/package.local-globals/statusProsp';
import { AddCircle, Timer } from '@mui/icons-material';
import Money from '../../utils/Money';


const styles = {
  HeaderIntervention: {
    backgroundColor: 'white',
    marginBottom: 15,
    justifyContent: 'center',
    alignItems: 'center'
  },
  addProsp: {
    marginTop: 20
  }
};

const InterDetail = ({ inter, classes, extend, markerList, updateState }) => {
  let statusCounters = allStatus.map(e => ({
    status: e,
    count: markerList ?
      markerList
        .filter(m =>
          m.getIn(['obj', 'inters'])
            .find(int => int.get('id') === inter.id).get('status', 0) === e
        )
        .count() : 0
  })).filter(e => e.count);
  const total = markerList ? markerList.count() : 0;
  return (
    <Grid container spacing={3} className={classes.HeaderIntervention}>
      <Grid item sm={3}>
        <Typography variant="h6">
          {inter.date.demarchageDuration ? (
            <CountDown
              demarchagePause={inter.date.demarchagePause}
              timer={inter.date.demarchageEnd}
            />
          ) : (
            'En attente de premier appel'
          )}
        </Typography>
        {markerList && (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
              <ProgressLine
                visualParts={statusCounters
                  .reduce((acc, curr) => {
                    const status = globalStatus.find((s) =>
                      s.childs.includes(curr.status)
                    );
                    const findElement = acc.findIndex(
                      (s) => s._id === status._id
                    );
                    if (~findElement) {
                      acc[findElement].count += curr.count;
                      acc[findElement].percentage += Math.trunc(
                        (100 * curr.count) / total
                      );
                    } else {
                      acc.push({
                        title: `- ${status.name} (${curr.count})`,
                        percentage: Math.trunc((100 * curr.count) / total),
                        color: status.color,
                        count: curr.count,
                        _id: status._id,
                      });
                    }
                    return acc;
                  }, [])
                  .sort((e, v) => v._id - e._id)}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography>
                {`${
                  total
                    ? Math.trunc(
                      100 -
                          ((100 *
                            (statusCounters.find(
                              (s) => s.status === A_CONTACTER
                            )?.count || 0)) /
                            total)
                    )
                    : 0
                }%`}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item sm={5}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle1">{inter.infoDesc.desc}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              {inter.client.address.city} ({inter.client.address.zipcode})
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={3}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            {inter.date.demarchageDuration && (
              <Button onClick={extend} variant="outlined">
                <Timer /> Prolonger
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.addProsp}
              onClick={() => updateState('openAddProsp', true)}
              variant="outlined"
            >
              <AddCircle /> Ajouter un prosp
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {inter.demarchageInfos && [
        <Grid item sm={2} key="shift">
          <Typography variant="subtitle1">
            Décalage possible ? {inter.demarchageInfos.shift}
          </Typography>
        </Grid>,
        <Grid item sm={2} key="budget">
          <Typography variant="subtitle1">
            Budget: {Money.toString(inter.demarchageInfos.budget || 0, true)}
          </Typography>
        </Grid>,
        <Grid item sm={4} key="withSupplies">
          <Typography variant="subtitle1">
            Fournitures:{' '}
            {inter.demarchageInfos.withSupplies
              ? 'Avec fourniture'
              : 'Sans fourniture'}
          </Typography>
        </Grid>,
        <Grid item sm={4} key="description">
          <Typography variant="subtitle1">
            Information complémentaire:{' '}
            {inter.demarchageInfos.description || 'Pas de description'}
          </Typography>
        </Grid>,
      ]}
    </Grid>
  );
};

export default withStyles(InterDetail, styles);
