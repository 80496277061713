import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import CustomTextField from '../CustomInputs/CustomTextField';
import { withStyles } from 'tss-react/mui';
import MoneyInput from '../CustomInputs/MoneyInput';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../actions/dialog';
const setBillNumber = setDialogContentProp('billNumber');
const setWarrantyPrice = setDialogContentProp('warrantyPrice');
const setEnabled = setDialogContentProp('enabled');

const styles = () => ({
  switch: { display: 'flex' }
});

const mapStateToProps = ({
  dialog: {
    contentProps: {
      billNumber,
      warrantyPrice,
      enabled
    }
  }
}) => ({
  billNumber,
  warrantyPrice,
  enabled
});

const mapDispatchToProps = {
  setBillNumber,
  setWarrantyPrice,
  setEnabled
};

const WarrantyDialog = ({
  classes,
  billNumber,
  warrantyPrice,
  enabled,
  setBillNumber,
  setWarrantyPrice,
  setEnabled
}) => (
  <Grid container spacing={1} >
    <Grid item xs={9}>
      <DialogTitle className={classes.dialogTitle}>
        <Grid children='Signaler une demande de garantie'/>
      </DialogTitle>
    </Grid>
    <Grid item xs={3} className={classes.switch}>
      <FormControlLabel
        control={
          <Switch
            value={enabled}
            checked={enabled}
            name="noSecure"
            color="primary"
            onChange={() => setEnabled(!enabled)}
            label={'Activé'}
          />
        }
        label={'Activé'}
      />
    </Grid>
    {enabled
      ? [
        <Grid key={0} item xs={6}>
          <CustomTextField
            value={billNumber}
            setData={(p, v) => setBillNumber(v)}
            label="N° Facture"
            variant="outlined"
          />
        </Grid>,
        <Grid key={1} item xs={6}>
          <MoneyInput
            defaultValue={warrantyPrice}
            value={warrantyPrice}
            label="Montant avoir attendu"
            setData={(p, v) => setWarrantyPrice(v)}
            variant="outlined"
          />
        </Grid>
      ]
      : ''
    }
  </Grid>
);
export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(WarrantyDialog, styles));
