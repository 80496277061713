import React from 'react';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CustomTextField from '../CustomInputs/CustomTextField';


const InputDisable = ({ iban, bic }) => (
  <CardContent>
    <Grid container spacing={3}>
      <CustomTextField
        grid
        xs={12}
        sm={5}
        disabled
        value={iban.replace(/(.{4})/g, '$1 ').trimEnd()}
      />
      <CustomTextField disabled grid xs={12} sm={5} value={bic} />
    </Grid>
  </CardContent>
);

export default InputDisable;
