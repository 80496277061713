import React from 'react';
import { compose } from 'redux';

import withExpansionPanel from '../../../hoc/withExpansionPanel';

import Decaissement from '../../Reglement/Decaissement';
import Comment from '../../Comment';

import { decaissementDefault } from '../../Reglement/functions';

import Grid from '@mui/material/Grid';

const ExpandedDecaissement = (props) => {
  const { setData } = props;
  const { commentValue, ...otherProps } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Decaissement line={decaissementDefault} {...otherProps} />
      </Grid>
      <Grid item xs={12}>
        <Comment
          fullWidth
          style={{ marginTop: 50, marginBottom: 30 }}
          setData={setData}
          value={commentValue}
        />
      </Grid>
    </Grid>
  );
};

export default compose(
  withExpansionPanel
)(ExpandedDecaissement);
