import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import CustomCheckbox from '../../CustomInputs/CustomCheckbox';

const styles = {
  root: {
    flexGrow: 1,
    marginBottom: 5,
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  ListItem: {
    backgroundColor: '#8080805c',
  },
  button: {
    margin: 2,
    color: 'white'
  },
  Collapse: {
    textAlign: 'center',
    maxHeight: '250px',
    overflowY: 'overlay',
  }
};

class SelectUser extends PureComponent {
  state = {
    open: false
  }

  openCollapse = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const {
      classes,
      users,
      selectedUsers,
      setArrayFilter,
      allUsers
    } = this.props;
    return (
      <List component="nav" className={classes.root}>
        <ListItem
          button
          onClick={this.openCollapse}
          className={classes.ListItem}
        >
          <ListItemText inset primary="Ajouter Par" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
          className={classes.Collapse}
          in={this.state.open}
          timeout="auto"
          unmountOnExit
        >
          <CustomCheckbox
            grid
            xs={12}
            label="TOUS"
            checked={allUsers}
            path="allUsers"
            setData={(p, v) => setArrayFilter(p, v)}
          />
          {users.map((u, i) => (
            <CustomCheckbox
              grid
              key={i}
              xs={12}
              label={u.login}
              checked={selectedUsers.includes(u._id)}
              path="login.ajout"
              setData={(p) => setArrayFilter(p, u._id)}
            />
          ))}
        </Collapse>
      </List>
    );
  }
}

export default withStyles(SelectUser, styles);
