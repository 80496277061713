export const saveAs = (uri, filename) => {
  let link = document.createElement('a');
  if (typeof link.download === 'string') {
    document.body.appendChild(link);
    link.download = filename;
    link.href = uri;
    link.click();
    document.body.removeChild(link);
  } else {
    window.location.replace(uri);
  }
};

export const downloadFromBase64 = (data, type = 'png') => {
  const a = document.createElement('a');
  a.download = `${data.fileName}.${type}`;
  a.href = data.content;
  a.click();
};

const typs = {
  txt: 'text/plain',
  pdf: 'application/pdf',
  html: 'text/plain',
};

export const onDownload = (data, type = 'txt') => {
  if (!data.contents || !data.filename) {
    return false;
  }
  let blob = new Blob([data.contents], { type: typs[type] });
  let url = URL.createObjectURL(blob);
  saveAs(url, `${data.filename}.${type}`);
};
