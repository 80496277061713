import store from '../store';
import { setDialog } from './dialog';

export const dialogGalerie = ({ merge }) =>
  store.dispatch(
    setDialog({
      name: 'ImageList',
      open: true,
      contentProps: { merge },
      dialogProps: {
        middle: true,
        middleAll: true,
        maxWidth: 'md',
      },
    })
  );

