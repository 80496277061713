import React from 'react';
import classNames from 'classnames';

import { withStyles } from 'tss-react/mui';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { OpenInNew, Delete } from '@mui/icons-material';

import FileDisplayer from './FileDisplayer';
import stopPropagation from '../../utils/stopPropagation';
import ValidationButton from './ValidationButton';
import {
  data as docStatus, DOCREF, DOCENC
} from '@lba-dev/package.local-globals/docStatus';

const styles = {
  typo: {
    color: 'lightgrey'
  },
  flexBox: {
    display: 'flex'
  },
  hover: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  tile: {
    height: 'auto',
    marginBottom: 5
  },
  container: {
    height: 200,
  },
  tileBar: {
    backgroundColor: 'rgba(0, 0, 0, 0.64)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.32)'
    }
  },
  icon: {
    color: 'lightGrey'
  },
  overTile: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  statusDiv: {
    display: 'flex',
    width: '100%',
    height: 25,
    zIndex: 3,
    position: 'absolute',
    top: 0
  }
};

const getStatusColor = (status) =>
  docStatus.find(e => [status, DOCREF].includes(e._id)).color[500];

const ImageTile = ({
  classes,
  name,
  title = name,
  description,
  content,
  selected,
  renderTile,
  handleClick,
  handleDelete,
  handleClickTileBar = () => handleClick({ name, title, content }),
  handleClickButton = () => window.open(content, '_blank'),
  status,
  sendStatus,
  loading,
  ...props
}) =>
  <ImageListItem
    className={classNames(classes.tile, classes.hover)}
    {...props}
  >
    {<div className={classes.statusDiv}
      style={{ backgroundColor: getStatusColor(status) }} ></div>}
    {typeof renderTile === 'function'
      ? renderTile({ name, title, content, description })
      : <FileDisplayer
        content={content}
        name={name}
        fileOpenState={true}
        classes={{ container: classes.container }}
      />
    }
    {status === DOCENC ?
      <ValidationButton
        fileName={name}
        sendStatus={sendStatus}
        loading={loading}
      />
      : null
    }
    <div
      className={classes.overTile}
      onClick={stopPropagation(handleClickTileBar)}
    />
    {!!name && <ImageListItemBar
      className={classNames(classes.tileBar, classes.hover)}
      title={title}
      subtitle={description &&
        <Typography
          className={classes.typo}
          variant="body2"
          children={description}
        />
      }
      actionIcon={
        <div className={classes.flexBox}>
          {!!handleDelete &&
            <IconButton
              className={classes.icon}
              onClick={stopPropagation(handleDelete)}
              children={<Delete color={selected ? 'secondary' : 'default'} />}
              size="large" />
          }
          <IconButton
            className={classes.icon}
            onClick={stopPropagation(handleClickButton)}
            children={<OpenInNew />}
            size="large" />
        </div>
      }
    />}
  </ImageListItem>;

export default withStyles(ImageTile, styles);
