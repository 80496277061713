import { CALL_PREPARE_GET_CITIES, getCities } from '../actions/etatReseau';
import api from '../api';
import notifSystem from '../notifSystem';

const getListCities = (store) => next => (action) => {
  switch (action.type) {
    case CALL_PREPARE_GET_CITIES: {
      api.cities.getAll({})
        .then((res) => {
          store.dispatch(getCities(res.body().map(city => city.data())));
        }).catch(e => notifSystem.error(e.name, e.message));
    }
  }
  next(action);
};

export default getListCities;
