import React from 'react';

import {
  GlobalStyles,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from '@mui/material';
import { Box } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

import ContactItem from './ContactItem';
import WhenElementIsVisible from '../../../Utils/WhenElementIsVisible';

const useStyles = makeStyles()((_theme, { isSearch, loading }) => ({
  contactDiv: {
    height: `calc(100% - ${isSearch ? 95 : 42}px)`,
    width: isSearch ? 430 : '',
    overflow: 'hidden auto',
  },
  overflow: {
    overflow: loading ? 'hidden' : 'hidden auto',
  },
  contactList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const globalStyle = <GlobalStyles styles={{
  '#maindiv': {
    '#maindiv::-webkit-scrollbar': {
      display: 'none',
    },
  }
}} />


const genSkeletons = (nb = 10) =>
  [...Array(nb).keys()].map(e =>
    <ListItem key={e} style={{ padding: '0 24px' }}>
      <Skeleton style={{ width: '100%', height: 50 }} />
    </ListItem>);

const ContactList = ({
  isSearch, contacts, loading, isDash,
  user, smsType, pinConversation, loadMore = e => e, setSmsType,
  ...props
}) => {
  const { classes, cx } = useStyles({
    isSearch, isDash, smsType,
    loading: loading && !contacts?.length
  });

  return (
    <Box className={cx(classes.contactDiv, classes.overflow)}>
      {globalStyle}
      <WhenElementIsVisible
        withLoader={false}
        classes={classes} hasMore={loading} onLoadMore={async () => {
          await loadMore();
        }}>
        <List className={classes.contactList}>
          {contacts?.length ?
            contacts.map((m, i) => <ContactItem
              {...props}
              key={i}
              user={user}
              elem={m}
              isSearch={isSearch}
              pinConversation={pinConversation}
              smsType={smsType}
              setSmsType={setSmsType}
              index={i}
            />) :
            ((!loading && <ListItem align='center'>
              <ListItemText
                align="center"
                variant="caption"
                secondary={'Aucun Element trouvé'}
              />
            </ListItem>) || '')}
          {loading ? genSkeletons(contacts?.length ? 1 : 17) : ''}
        </List>
      </WhenElementIsVisible>
    </Box>
  );
};

export default ContactList;
