import React from 'react';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CustomTextField from '../CustomInputs/CustomTextField';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';

const styles = {
  successLabel: {
    color: green[500],
  },
  errorLabel: {
    color: red[500],
  },
};

const Inputs = ({
  permissions,
  newIban,
  paths,
  newBic,
  isValidIBAN,
  isValidBIC,
  setData,
  valideRib,
  clearRib,
  classes,
}) => (
  <CardContent>
    <Grid container spacing={3}>
      <CustomTextField
        grid
        xs={12}
        sm={5}
        error={!isValidIBAN(newIban.toUpperCase())}
        label="IBAN"
        value={newIban
          .replace(/(.{4})/g, '$1 ')
          .trimEnd()}
        path={paths.newIban}
        setData={(p, v) => setData(p, v.replace(/\s/g, ''))}
        InputLabelProps={{
          className:
            newIban && isValidIBAN(newIban.toUpperCase())
              ? classes.successLabel
              : classes.errorLabel,
        }}
      />
      <CustomTextField
        grid
        xs={12}
        sm={3}
        error={!isValidBIC(newBic)}
        label="BIC"
        InputLabelProps={{
          className: isValidBIC(newBic)
            ? classes.successLabel
            : classes.errorLabel,
        }}
        value={newBic}
        path={paths.newBic}
        setData={setData}
      />
      { permissions
        ? <Grid item xs={12} sm={2}>
          <Button
            onClick={valideRib}
            style={{ margin: '10% 0%' }}
            color="primary"
            variant="contained"
            children="Valider le RIB"
          />
        </Grid>
        : <Grid item xs={12} sm={2} />
      }
      { permissions
        ? <Grid item xs={12} sm={2}>
          <Button
            onClick={clearRib}
            style={{ margin: '10% 0%', backgroundColor: red[400] }}
            variant="contained"
            children="Refuser le RIB"
          />
        </Grid>
        : <Grid item xs={12} sm={2} />
      }
    </Grid>
  </CardContent>
);

export default withStyles(Inputs, styles);
