import React from 'react';
import Button from '@mui/material/Button';
import MinCustomDialog from '../../Dialogs/MinCustomDialog';
import DialogContentText from '@mui/material/DialogContentText';

const DialogDelete = ({ openDelete, updateState, categorie, deleteCat }) => (
  <MinCustomDialog
    open={openDelete}
    maxWidth={'xs'}
    title="Supprimer la categorie"
    actions={[<Button onClick={() => updateState('openDelete', false)} key={1}
      color="primary"> Annuler </Button>,
    <Button onClick={() => deleteCat()} color="primary" key={2}
      autoFocus>Confirmer</Button>
    ]}
    middle
  >
    <DialogContentText>
      {`Etes vous sur de vouloir supprimer la categorie ${categorie}`}
    </DialogContentText>
  </MinCustomDialog>
);

export default (DialogDelete);
