import React, { PureComponent } from 'react';
import SupplieRow from './SupplieRow';
import { List } from 'immutable';

class SuppliesRows extends PureComponent {
  render() {
    const { data, setData, remove, swap, interId, artisan } = this.props;
    return data.map((e, pos) => (
      <SupplieRow
        key={pos}
        supplie={e}
        elemPos={pos}
        last={data.size - 1 === pos}
        setData={setData}
        remove={remove}
        swap={swap}
        interId={interId}
        artisan={artisan}
      />
    ));
  }
}

SuppliesRows.defaultProps = {
  data: new List()
};

export default SuppliesRows;
