import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  button: {
    minWidth: '100%'
  },
};

class Horaires extends PureComponent {

  getWeekDay = () => {
    const { merge } = this.props;
    let horraires = merge.getIn(['elem', 'obj', 'horraires']);
    horraires = horraires ? horraires.toJS() : undefined;
    if (!horraires) {
      return;
    }
    let weekDay = [
      { day: 'Lundi', value: '' },
      { day: 'Mardi', value: '' },
      { day: 'Mercredi', value: '' },
      { day: 'Jeudi', value: '' },
      { day: 'Vendredi', value: '' },
      { day: 'Samedi', value: '' },
      { day: 'Dimanche', value: '' },
    ];
    let actKey = 0;
    Object.keys(horraires).map( (index) => {
      let day = weekDay.find((element) => (element.day === horraires[index]));
      if (day) {
        actKey = weekDay.indexOf(day);
      } else {
        weekDay[actKey].value += `${horraires[index]} /`;
      }
    });
    return weekDay;
  }

  getWeekDayGoogle = () => {
    const { merge } = this.props;
    let horraires = merge.getIn(['elem', 'obj', 'horraires']);
    horraires = horraires ? horraires.toJS() : undefined;
    if (!horraires) {
      return;
    }
    let weekDay = [];
    Object.keys(horraires).map( (index) => {
      let arrayWeek = horraires[index].split(' ');
      weekDay[index] = { day: arrayWeek[0],
        value: arrayWeek.slice(1).join(' ') };
    });
    return weekDay;
  }

  render() {
    const { classes, merge } = this.props;
    let weekDay =
    merge.getIn(['elem', 'obj', 'source'], []).includes('google') ?
      this.getWeekDayGoogle() : this.getWeekDay();
    return !weekDay ? '' : (
      <CardContent><Paper className={classes.root}>
        <Table >
          <TableBody>
            {weekDay.map((row, index) =>
              <TableRow key={index}>
                <TableCell component="th" scope="row">{row.day}</TableCell>
                <TableCell align="right">{row.value}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper></CardContent>
    );
  }
}

Horaires.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(Horaires, styles);
