/* eslint-disable max-lines */
import Immutable from 'immutable';
import {
  D_NOT,
  D_PRE,
  D_MAR
} from '@lba-dev/package.local-globals/deStatus';

import {
  Assignment,
  Call,
  Comment,
  Schedule,
  Close,
  Send,
  CancelPresentation,
  Search,
} from '@mui/icons-material';

import {
  S_PART
} from '@lba-dev/package.local-globals/services';
import {
  I_APR,
  I_ANN,
  I_REF,
} from '@lba-dev/package.local-globals/iStatus';

import { permit } from '@lba-dev/package.local-globals/restrictions';

import {
  cancel,
  comment,
  appel,
  schedule,
  refuser,
  sendToMarket,
} from '../../../actions/inter';
import { artisanNumbers } from '../Actions';
import { P_NONE } from '@lba-dev/package.local-globals/isValidStatus';

export default [
  {
    name: 'Modifier',
    icon: Assignment,
    link: (data) => {
      window.open(`/intervention/${data.id}`);
    },
    action: (props, elem) => {
      props.navigate(`/intervention/${elem.id}`);
    },
  },
  {
    name: 'Appeler',
    icon: Call,
    sub: [
      {
        name: 'Téléphone Client 1',
        value: 'client.tel1',
        action: (props, elem) => {
          appel(
            props,
            elem.client.tel1,
            { client: elem.client }
          );
        },
      },
      {
        name: 'Téléphone Client 2',
        value: 'client.tel2',
        action: (props, elem) => {
          appel(
            props,
            elem.client.tel2,
            { client: elem.client }
          );
        },
      },
      {
        name: 'Téléphone Client 3',
        value: 'client.tel3',
        action: (props, elem) => {
          appel(
            props,
            elem.client.tel3,
            { client: elem.client }
          );
        },
      },
      {
        name: 'Payeur tel 1',
        value: 'billing.tel1',
        action: (props, elem) => {
          appel(props, elem.billing.tel1, {
            client: elem.client,
            billing: elem.billing,
          });
        },
      },
      {
        name: 'Payeur tel 2',
        value: 'billing.tel2',
        action: (props, elem) => {
          appel(props, elem.billing.tel2, {
            client: elem.client,
            billing: elem.billing,
          });
        },
      },
      {
        name: 'Payeur tel 3',
        value: 'billing.tel3',
        action: (props, elem) => {
          appel(props, elem.billing.tel3, {
            client: elem.client,
            billing: elem.billing,
          });
        },
      },
      ...artisanNumbers,
    ],
  },
  {
    name: 'Commenter',
    icon: Comment,
    action: comment,
    customVisible: (elem, props) =>
      props.selected &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayInterActions',
        }
      ),
  },
  {
    name: 'Prospection',
    icon: Search,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return props.selected && props.selected.aDemarcher > D_NOT && user;
    },
    link: (elem) => {
      window.open(`/prosp/list?interSearch=${elem.id}`);
    },
    action: (props, elem) => {
      props.navigate(`/prosp/list?interSearch=${elem.id}`);
    },
  },
  {
    name: 'Je prends!',
    icon: Schedule,
    customVisible: (elem, props) =>
      Immutable.List.isList(props.users) &&
      props.selected &&
      props.selected.status === I_APR &&
      props.users.find((e) => e._id === props.userId).service === S_PART &&
      props.selected.aDemarcher === D_MAR,
    action: (elem, props) => schedule(props),
  },
  {
    name: 'Envoyer dans le market',
    icon: Send,
    customVisible: (elem, props) =>
      Immutable.List.isList(props.users) &&
      props.selected &&
      props.selected.status === I_APR &&
      permit(
        props.users.find((u) => u._id === props.userId),
        { key: 'displayInterActions' }
      ) && props.users.find((e) => e._id === props.userId).service === S_PART &&
      props.selected.aDemarcher === D_PRE,
    action: sendToMarket,
  },
  {
    name: 'Refuser',
    icon: CancelPresentation,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        Immutable.List.isList(props.users) &&
        props.selected &&
        props.selected.status === I_APR &&
        props.selected.status !== I_REF &&
        user.service === S_PART &&
        props.selected.aDemarcher > D_NOT
      );
    },
    action: refuser,
  },
  {
    name: 'Annuler',
    customVisible: (elem, props) =>
      props.selected &&
      props.selected.status !== I_ANN &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayInterActions',
        }
      ) &&
      (!props.selected.proposition || (
        (props.selected.proposition || {}).isValid &&
        props.selected.proposition.isValid !== P_NONE)
      ) &&
      (permit(props.users.find((u) => u._id === props.userId)) ||
        !props.selected.noPaymentReceived),
    icon: Close,
    action: cancel,
  },
];
