import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';
import { CatButtons, DialogDelete, DialogAdd } from './Categories/index';
import { List } from 'immutable';

class Categories extends PureComponent {
  state = {
    openDelete: false,
    categorie: undefined,
    openAdd: false
  }

  updateState = (name, value) => {
    this.setState({ [name]: value });
  }

  deleteCat = () => {
    const { categorie } = this.state;
    const { merge, setData, path } = this.props;
    const categories = merge.getIn(path, new List());
    if (categories.includes(categorie) && categories.size > 1) {
      setData(path,
        categories.remove(categories.indexOf(categorie)));
    }
    this.setState({ openDelete: false });
  }

  selectCat = (categorie) => {
    this.setState({ categorie, openDelete: true });
  }

  addCat = (categorie) => {
    const { merge, setData, path } = this.props;
    const categories = merge.getIn(path, new List());
    if (!categories.includes(categorie)) {
      setData(path, categories.push(categorie));
    }
    this.setState({ openAdd: false });
  }

  render() {
    const { classes, merge, path } = this.props;
    const { openDelete, categorie, openAdd } = this.state;
    return (
      <CardContent className={classes.categories}>
        <CatButtons
          merge={merge}
          classes={classes}
          path={path}
          selectCat={this.selectCat}
          updateState={this.updateState}
        />
        <DialogDelete
          openDelete={openDelete}
          categorie={categorie}
          updateState={this.updateState}
          deleteCat={this.deleteCat}
        />
        <DialogAdd
          openAdd={openAdd}
          updateState={this.updateState}
          addCat={this.addCat}
        />
      </CardContent>);
  }
}

Categories.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = {
  categories: {
    background: '#80808042',
    borderRadius: 5,
    width: '85%',
    margin: 'auto',
    align: 'center'
  },
};

export default withStyles(Categories, styles);
