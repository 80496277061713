import store from '../store';

export function formatStr(num) {
  return num < 10 ? `0${num}` : num;
}

export const genId = (firstChar, length = 6) =>
  firstChar + Math.random().toString(36).toUpperCase().substr(2, length);

export const genRandomId = (firstChar, length = 6, retryLimit = 5) => {
  const products = store.getState().products.array;
  const ids = products.map(e => e.ref);
  let retryCounter = retryLimit;
  while (retryCounter > 0) {
    const r = genId(firstChar, length);
    if (ids.includes(r)) {
      retryCounter--;
    } else {
      return r;
    }
  }
  return null;
};

/**
 * Formats large numbers with k, M, B, T, Quadrillion, Quintillion suffixes
 * @param {number} num - Number to format
 * @param {number} [digits=1] - Number of decimal places
 * @returns {string} Formatted number with appropriate suffix
 */
export const formatLargeNumber = (num, digits = 1) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'Q' },
    { value: 1e18, symbol: 'Qi' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(item => num >= item.value);

  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : num;
};
