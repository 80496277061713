import React from 'react';

import { withStyles } from 'tss-react/mui';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
const styles = {
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  fabProgress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
};

const CustomLoadingButton = ({ classes, loading,
  isFab, ...props }) => {
  const customLoad = <CircularProgress
    size={isFab ? 68 : 24}
    className={isFab ? classes.fabProgress : classes.buttonProgress}
  />;
  return <div className={classes.wrapper}>
    {isFab ? <>
      <Fab {...props} />
      {loading ? customLoad : null}
    </> : <Button {...props} disabled={props.disabled || loading} >
      {props.children}
      {loading ? customLoad : null}
    </Button>}
  </div>;
};

export default withStyles(CustomLoadingButton, styles);
