import React, { PureComponent } from 'react';
import { List, Map } from 'immutable';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import notifSystem from '../../notifSystem';
import api from '../../api';
import ConfirmBox from '../Dialogs/ConfirmBox';
import { postComment } from '../../actions/inter';
import CommentsList from './CommentsList';

class Comments extends PureComponent {
  state = {
    openConfirmBox: false,
    commentPropsDelete: {},
    selectedTab: 0
  }

  formatData = commentValue => {
    const {
      type,
      interId
    } = this.props;
    const data = {
      interId,
      commentValue,
    };

    if (type) {
      data.type = type;
    }
    return data;
  }

  postComment = async commentValue => {
    try {
      const { type, interId, collection } = this.props;
      const res = await postComment(interId, commentValue, type
        ? { type } : {}, collection
      );
      notifSystem.success('Commentaire', 'Le commentaire a bien été ajouté');
      return (res.body().data().data);
    } catch (e) {
      notifSystem
        .error('Erreur', 'Le commentaire n\'a pas pu être ajouté');
      throw e;
    }
  }

  setComment = async event => {
    if (event.key !== 'Enter' || !event.target.value.trim()) {
      return;
    }
    event.preventDefault();
    const {
      interId,
      userId,
      setData,
      comments
    } = this.props;
    const commentValue = event.target.value.trim();
    event.target.value = '';
    const value = new Map(
      interId
        ? await this.postComment(commentValue)
        : {
          ...this.formatData(commentValue),
          userId,
          date: new Date().toISOString()
        }
    );
    if (setData) {
      setData(['comments'], comments.push(value));
    }
  }

  confirmBox = (userId, date, commentValue) => {
    this.setState({
      commentPropsDelete: { userId, date, commentValue },
      openConfirmBox: true,
    });
  }

  deleteComment = (confirm, commentPropsDelete) => {
    const {
      interId,
      comments,
      setData,
      collection = 'interventions'
    } = this.props;

    if (confirm) {
      if (interId !== undefined) {
        api.one(collection, interId)
          .custom('comments')
          .delete({
            date: commentPropsDelete.date,
            userId: commentPropsDelete.userId,
            commentValue: commentPropsDelete.commentValue
          })
          .then(() => {
            const index = comments.findIndex(item =>
              item.get('userId') === commentPropsDelete.userId
              && item.get('date') === commentPropsDelete.date
            );

            setData(['comments'], comments.delete(index));
            notifSystem.success(
              'Commentaire',
              'Le commentaire a bien été supprimé'
            );
          })
          .catch(e => {
            notifSystem.error(
              'Erreur',
              'Le commentaire n\'a pas pu être supprimé'
            );
            throw e;
          });
      }
    }
    this.setState({
      commentPropsDelete: {},
      openConfirmBox: false,
    });
  }

  formatComments = () => {
    const {
      type,
      comments
    } = this.props;
    return comments
      && (type
        ? comments.filter(c => c.get('type') === type)
        : comments);
  }

  render() {
    const { openConfirmBox, commentPropsDelete } = this.state;
    const {
      title = this.props.expansionTitle ? null : 'Commentaires',
      elevation = 1,
      subtitle,
      hideDelete,
      ...props
    } = this.props;
    const comments = this.formatComments();
    return (
      <Card className="cards" elevation={elevation}>
        {title ? <CardContent>
          <Typography variant="h5">{title}</Typography>
          {subtitle ? <Typography variant="caption" children={subtitle} /> : ''}
        </CardContent> : null}
        <CardContent>
          <CommentsList
            setComment={this.setComment}
            {...props}
            confirmBox={!hideDelete && this.confirmBox}
            comments={comments}
          />
          {openConfirmBox && (
            <ConfirmBox
              callback={this.deleteComment}
              obj={commentPropsDelete}
            />
          )}
        </CardContent>
      </Card>
    );
  }
}

Comments.defaultProps = {
  comments: new List()
};

export default Comments;
