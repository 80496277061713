import { USER_CONNECT } from '../constants/ActionTypes';

const initialState = {
  isActiveSession: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_CONNECT:
      return {
        ...state,
        isActiveSession: action.isActiveSession,
      };
    default:
      return state;
  }
};
