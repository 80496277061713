import React from 'react';
import Button from '@mui/material/Button';
import MinCustomDialog from '../Dialogs/MinCustomDialog';


const DialogAppel = ({
  openAppel,
  updateState,
  callFunc,
  callObj
}) => (
  <MinCustomDialog
    open={openAppel}
    fullWidth={false}
    onClose={() => updateState('openAppel', false)}
    maxWidth={false}
    title="Attention !"
    actions={[<Button onClick={() => updateState('openAppel', false)} key={1}
      color="secondary"> Annuler </Button>,
    <Button onClick={() => updateState('openAppel', false,
      () => callFunc(callObj.e, callObj.phoneIndex))} key={1}
    color="primary"> Appeler </Button>]}
    middle
  >
    <div>
    L'artisan a déjà été contacté aujourd'hui.
    Êtes-vous sûr de vouloir l'appeler ?
    </div>
  </MinCustomDialog>
);
export default DialogAppel;
