import React from 'react';

import { withStyles } from 'tss-react/mui';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
const styles = {
  select: {
    width: '100%'
  },
  menu: {
    height: 500
  }
};

let i = 0;

const generateName = () => `selector${i++}`;

const Selector = ({
  name = generateName(),
  classes,
  title,
  handleChange,
  value,
  ...selectProps
}) =>
  <FormControl className={classes.select}>
    <InputLabel htmlFor={name} children={title} />
    <Select
      {...selectProps}
      value={value === null || value === undefined ? '' : value}
      inputProps={{ id: name }}
      MenuProps={{ className: classes.menu }}
      onChange={e => handleChange(e.target.value)}
    />
  </FormControl>;

export default withStyles(Selector, styles);
