import React from 'react';
import Timeline from '@mui/lab/Timeline';
import InterMenuInfo from '../List/Intervention/InterMenuInfo';
import api from '../../api';
import { openPreview } from '../../actions/general';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { formatHistory } from '../Utils/FormatHistory';
import { getHistories } from '../../actions/history';
import { CircularProgress, Typography } from '@mui/material';

const styles = {
  overflow: {
    maxHeight: 450,
    overflow: 'auto',
    overflowX: 'hidden',
  },
};

const mapStateToProps = (
  { users },
  { elem, histories, appels, records, sms }) =>
  formatHistory({
    users,
    elem,
    histories,
    appels,
    records,
    sms
  });

const GenTimeLine = connect(mapStateToProps)(({ histories, getDevis }) =>
  histories.map((data, i) => (
    <Timeline key={i} align="alternate">
      <InterMenuInfo {...data} getDevis={getDevis} />
    </Timeline>
  ))
);

class HistoryTimeLine extends React.Component {
  state = {
    histories: [],
    calls: [],
    loading: true,
  };

  getDevis = async (id) => {
    const res = await api.devis.get(id);
    this.setState({ data: res.body().data() }, () =>
      openPreview(
        {
          method: 'completeFac',
          info: { type: 'facture', noCondition: true },
          data: { devis: this.state.data },
        },
        `Devis n°${id || ''}`
      )
    );
  };

  getHistory = (newName) => {
    this.setState({ loading: true });
    let histories = this.state.histories;
    if (newName) {
      histories = [];
    }
    getHistories(this.props).then((data) => {
      this.setState({
        histories: [...histories].concat(data),
        loading: false,
      });
    });
  }

  componentDidMount() {
    this.getHistory('', true);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.elem.id !== prevProps.elem.id ||
      this.props.name !== prevProps.name
    ) {
      this.getHistory(this.props.name !== prevProps.name, true);
    }
  }

  render() {
    const { histories, loading } = this.state;
    const { classes } = this.props;
    return <div className={classes.overflow}>
      {histories.length ? (
        <GenTimeLine
          {...this.props}
          histories={histories}
          setData={this.setData}
          getDevis={this.getDevis}
        />
      ) : (
        <Typography
          sx={{ m: 2 }}
          align="center"
          component="h1"
          variant="caption"
          children={
            loading ? <CircularProgress /> : 'Aucun historique trouvé'
          }
        />
      )}
    </div>;
  }
}

export default withStyles(HistoryTimeLine, styles);
