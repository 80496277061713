import React from 'react';

import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import DatePicker from '../DatePicker';
import { setDialogContentProp } from '../../actions/dialog';

const setDate = setDialogContentProp('date');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      date
    },
  },
}) => ({
  date
});

const mapDispatchToProps = {
  setDate,
};

const DateSelectorDialog = ({ date, setDate }) =>
  <Box style={{ minHeight: 300 }}>
    <DatePicker
      before={true}
      label={'Date de fin'}
      startDate={date}
      onChange={(v) => setDate(v.toDate())}
    />
  </Box>;

export default connect(mapStateToProps, mapDispatchToProps)(DateSelectorDialog);
