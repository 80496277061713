import React from 'react';
import {
  FormControl, FormHelperText, InputLabel, Typography
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(
  (_theme, { texttransform, label, isSelect, disabled, margin }) => {
    let formStyleControl = {
      marginBottom: 0,
      marginTop: 0,
    }
    if (margin === 'dense') {
      formStyleControl.marginBottom = 7;
      formStyleControl.marginTop = 4;
    }
    if (margin === 'normal') {
      formStyleControl.marginBottom = 11;
      formStyleControl.marginTop = 16;
    }
    return {
      formControl: formStyleControl,
      textInput: {
        minHeight: 22,
        borderBottom: `1px ${disabled ?
          'dotted' : 'solid'} rgba(0, 0, 0, 0.42)`,
        textTransform: texttransform,
        fontSize: 16,
        marginTop: label ? '16px' : '',
        padding: '4px 0 3.68px',
        lineHeight: '1.4375em',
        cursor: isSelect ? 'pointer' : 'text',
        textAlign: 'left',
        color: disabled ? 'rgba(0, 0, 0, 0.38)' : 'inherit',
        whiteSpace: 'pre-line',
      },
      adornment: {
        position: 'absolute',
        right: 0,
        bottom: 4,
        color: disabled ? 'rgba(0, 0, 0, 0.38)' : 'inherit',
      },
      helperText: {
        margin: 0,
        marginTop: 3,
      },
    }
  });

export const DisplayText = ({
  label,
  required = false,
  value,
  handleEditable,
  texttransform = 'none',
  isSelect,
  maxLength,
  disabled,
}) => {
  const { classes } = useStyles({ texttransform, label, isSelect, disabled });
  return (
    <FormControl className={classes.formControl} fullWidth>
      {label && (
        <InputLabel
          shrink
          required={required}
          htmlFor="textInput"
          children={label}
        />
      )}
      <Typography
        id="textInput"
        children={value}
        className={classes.textInput}
        onClick={() => handleEditable(true)}
      />
      {isSelect ? (
        <ArrowDropDown
          onClick={() => handleEditable(true)}
          className={classes.adornment}
        />
      ) : (
        ''
      )}
      {!!maxLength && (
        <FormHelperText className={classes.helperText}>
          {value.length}/{maxLength}
        </FormHelperText>
      )}
    </FormControl>
  );
};
