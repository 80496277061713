import React from 'react';

import {
  Avatar, IconButton,
  ListItemSecondaryAction,
  Tooltip, Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { PushPinOutlined, PushPin } from '@mui/icons-material';

import {
  WHATSUP,
  WHATSAPPBUSINESS,
  RINGOVER
} from '@lba-dev/package.local-globals/smsMode';

import {
  formatDate,
} from '../../../../constant';
import ModeIcon from '../../../../ModeIcon';

const SecondaryContent = ({
  elem, typeData, classes, isPinned,
  isSearch, smsType, pinConversation
}) => {
  const date = elem.date && formatDate(elem.date);
  const PinnedIcon = isPinned ? PushPin : PushPinOutlined;
  return <ListItemSecondaryAction className={classes.secondaryAction}>
    {!isSearch ? <Box
      display='flex'
      alignItems={![WHATSUP, WHATSAPPBUSINESS, RINGOVER]
        .includes(elem.mode) ? 'center' : ''}
      className={classes.dateModeBox}>
      <Typography
        variant='caption'
        className={classes.dateString}
        children={date}
      />
      <ModeIcon elemMode={elem.mode}
        isMailReceived={smsType === 'mailReceived'}
      />
    </Box> : ''}
    {elem.unSeenCount && smsType !== 'all'
      ? <Avatar
        className={classes.notifCircle}
        children={elem.unSeenCount} />
      : <>
        <Box className={classes.pinBox}>
          <Tooltip title={isPinned ? 'Retirer' : 'Épingler'}>
            <IconButton
              size='small'
              edge="end"
              aria-label="delete"
              className={classes.dateModeBox}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                pinConversation(elem);
              }}
            >
              <PinnedIcon fontSize='small' className={classes.pinned} />
            </IconButton>
          </Tooltip>
          {elem.search ?
            <typeData.icon className={classes.pinned} />
            : ''}
        </Box>
        <Box className={classes.emptyBox} children="&nbsp;" />
      </>}
  </ListItemSecondaryAction>;
};

export default SecondaryContent;
