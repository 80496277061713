import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const styles = {
  root: {
    marginBottom: 5,
    flexGrow: 1,
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  FormControlLabel: {
    padding: '0px 10px',
    width: '90%'
  },
  ListItem: {
    backgroundColor: '#8080805c'
  },
  label: {
    paddingLeft: '0 !important'
  }
};

class Parametres extends PureComponent {

  state = {
    openFiltre: true
  }

  handleClickFiltre = () => {
    this.setState(state => ({ openFiltre: !state.openFiltre }));
  };

  render() {
    const {
      classes,
      data,
      updateData
    } = this.props;
    const {
      openFiltre
    } = this.state;
    return (
      <List
        component="nav"
        className={classes.root}>
        <ListItem button onClick={this.handleClickFiltre}
          className={classes.ListItem}>
          <ListItemText
            inset primary={'Parametres'} className={classes.label} />
          {openFiltre ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse className={classes.FormControlLabel}
          in={openFiltre} timeout="auto" unmountOnExit>
          {data.get('filters').filter(v => v.get('show', false))
            .mapEntries(([ k, v ], i) => [
              <FormControlLabel
                key={i}
                className={classes.Mobile}
                control={
                  <Checkbox
                    onClick={() =>
                      updateData(['filters', k, 'checked'],
                        !data.getIn(['filters', k, 'checked'], false))}
                    checked={v.get('checked', false)}
                    value={k}
                  />
                } label={k} />
            ]
            )}
        </Collapse>
      </List>
    );
  }
}

export default withStyles(Parametres, styles);
