import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Paper from '@mui/material/Paper';
import { withStyles } from 'tss-react/mui';
import NotifTransition from './NotifTransition';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import {
  Close,
  Email,
  OpenInNew,
  PersonAdd,
  Sms,
  Warning,
} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { fetch as fetchNotifications } from '../../actions/notifications';
import notifSystem from '../../notifSystem';
import store from '../../store';
import api from '../../api';
import { addArtisanFromSMS } from '../../actions/inter';
import withRouter from '../../hoc/withRouter';
import DOMPurify from 'dompurify';

const styles = {
  notif: {
    pointerEvents: 'auto',
    marginTop: 10,
    height: 0,
    minWidth: '600px',
    width: '100%',
    display: 'flex',
    color: '#333',
    transformOrigin: 'bottom left',
  },
  notifIconSection: {
    backgroundColor: '#00000040',
    flex: '0 0 52px',
    position: 'relative',
  },
  icon: {
    color: 'white',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  textSection: {
    fontSize: 14,
    lineHeight: 1.75,
    padding: 10,
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  root: {
    maxWidth: 350,
    height: 100,
    borderRadius: 15,
  },
  content: {
    maxHeight: 70,
    overflow: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
  },
};

const mapStateToProps = state => ({
  user: state.users.find(e => e._id === state.userId)
});

const AnotherNotifMessage = ({ message, style, classes, closeComponent }) => (
  <Paper
    elevation={5}
    style={style}
    className={classes.notif}
    onClick={closeComponent}
  >
    <div className={classes.notifIconSection}>
      <message.type.icon className={classes.icon} />
    </div>
    <div className={classes.textSection}>
      <div className={classes.text} style={{ fontWeight: 'bold' }}>
        {message.title}
      </div>
      <div
        className={classes.text}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.msg) }}
      />
    </div>
  </Paper>
);

const PopupNotification = ({ message, classes, closeComponent, isSMS }) => (
  <Card
    raised
    className={classes.root}
    elevation={24}
    onClick={() => closeComponent()}
  >
    <CardHeader
      avatar={isSMS ? <Sms color="primary" /> : <Warning color="secondary" />}
      title={message.title}
      className={classes.notifHeader}
      action={
        message.interId ? (
          <IconButton aria-label="close" onClick={closeComponent} size="large">
            <Close />
          </IconButton>
        ) : null
      }
    />
    {!!message.msg && (
      <CardContent className={classes.content}>
        <Typography variant="body2" color="textSecondary" component="p">
          {message.msg}
        </Typography>
      </CardContent>
    )}
  </Card>
);

const EmailMessage = ({ message, classes, closeComponent, navigate, user }) => (
  <Card raised className={classes.root} elevation={24}
    onClick={() => closeComponent()}>
    <CardHeader
      avatar={<Email color="primary" />}
      title={message.title}
      action={
        <IconButton
          aria-label="close"
          onClick={closeComponent}
          size="large"
        >
          <Close />
        </IconButton>}
    />
    <CardActions disableSpacing style={{ paddingBottom: 0 }}>
      <Grid container align="center">
        <Grid item xs={message.interId ? 6 : 12}>
          <Tooltip disableInteractive title="Recap">
            <Button
              startIcon={<OpenInNew />}
              size="small"
              color="primary"
              onClick={() => {
                navigate(message.link);
                closeComponent();
              }}
              children="Recap"
            />
          </Tooltip>
          <div style={{ display: 'flex' }}></div>
        </Grid>
        {message.interId ? <Grid item xs={message.interId ? 6 : 12}>
          <Tooltip disableInteractive title="Intervention">
            <Button
              startIcon={<OpenInNew />}
              size="small"
              color="primary"
              onClick={() => {
                navigate(`/intervention/${message.interId}`);
                closeComponent();
              }}
              children="Intervention"
            />
          </Tooltip>
          <div style={{ display: 'flex' }}></div>
        </Grid> : null}
      </Grid>
      <div style={{ display: 'flex' }}></div>
    </CardActions>
    {message.interId && message.selectable ? <CardActions disableSpacing>
      <Button
        fullWidth
        variant="contained"
        startIcon={<PersonAdd />}
        size="small" color="primary"
        onClick={() => {
          addArtisanFromSMS(message.interId, message.artisan, user, navigate);
          closeComponent();
        }}>
        Selectionner pour l'intervention
      </Button>
    </CardActions> : null}
  </Card>
);

class Notification extends PureComponent {
  constructor() {
    super();
    this.state = { open: true };
    this.removeComponent = this.removeComponent.bind(this);
    this.closeComponent = this.closeComponent.bind(this);
    this.onExit = this.onExit.bind(this);
  }

  componentDidMount() {
    const { duration, interId } = this.props.message;
    if ((!duration || duration >= 0) && !interId) {
      this.timeout = setTimeout(
        this.closeComponent,
        this.props.message.duration || 5000
      );
    }
  }

  closeComponent() {
    clearTimeout(this.timeout);
    this.setState({ open: false });
  }

  removeComponent() {
    this.props.removeNotif(this.props.message.id);
  }

  onExit(duration) {
    if (!this.props.message.interId) {
      this.exitTimeout = setTimeout(this.removeComponent, duration);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.exitTimeout);
  }

  getNotification({ message, classes, navigate }) {
    const style = { backgroundColor: message.type.color };
    if (message.type.name === 'popUp') {
      return (
        <PopupNotification
          closeComponent={this.closeComponent}
          message={message}
          classes={classes}
          isSMS={message.type.name === 'sms'}
        />
      );
    } else if (message.type.name === 'email') {
      return <EmailMessage
        closeComponent={this.closeComponent}
        message={message}
        classes={classes}
        navigate={navigate}
      />;
    }
    return <AnotherNotifMessage
      closeComponent={this.closeComponent}
      message={message}
      style={style}
      classes={classes}
    />;
  }

  render() {
    const { message } = this.props;
    return (
      message && (
        <NotifTransition in={this.state.open} onExit={this.onExit}>
          {this.getNotification(this.props)}
        </NotifTransition>
      )
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateNotifications: (notificationId) => {
    api.notifications
      .patch(notificationId, { click: true })
      .then(() => {
        const notifications = store.getState().notifications.map((n) => ({
          ...n,
          click: notificationId === n._id ? true : n.click,
        }));
        dispatch(fetchNotifications(notifications));
      })
      .catch(() =>
        notifSystem.error(
          'Notification',
          'Les notifications n\'ont pas pu être mises à jour'
        )
      );
  },
});
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(withStyles(Notification, styles));
