import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const CustomBackdrop = styled(Backdrop)(() => ({
  background: 'rgba(255,255,255,0.75)',
  zIndex: '1301',
  display: 'flex',
}));
const H3 = styled('h3')(({ theme }) => ({
  margin: theme.spacing(2),
}));

export default function SuspenseLoading({ message = '' }) {
  return (
    <CustomBackdrop open>
      <CircularProgress size={50} color="secondary" />
      <H3
        children={message || 'Chargement en cours...'}
      />
    </CustomBackdrop>
  );
}
