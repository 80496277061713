import React, { PureComponent } from 'react';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import api from '../../../api';
import { withStyles } from 'tss-react/mui';
import notifSystem from '../../../notifSystem';
import DisplayMail from './DisplayMail';
import DisplayAnswers from './DisplayAnswers';
import ReplyComponent from './ReplyComponent';
import ws from '../../../ws';
import DisplayHtmlDom from '../../../components/DisplayHtmlDom';
import WhenElementIsVisible
  from '../../../components/Utils/WhenElementIsVisible';

const styles = {
  lineHeight: {
    lineHeight: 'normal',
    overflowWrap: 'break-word',
  },
  email: { textAlign: 'right' },
  icons: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  send: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  answers: { marginLeft: 'auto', marginRight: 0 },
  alignRight: { marginLeft: 'auto', marginRight: 0 },
  scroll: {
    overflow: 'auto', maxHeight: '300px', minHeight: '350px', width: '100%'
  },
  mailSuggest: {
    maxWidth: 250,
    cursor: 'pointer',
    marginRight: 8,
    '& span': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }
  },
  loadingContainer: {
    textAlign: 'center',
  }
};


class TabEmail extends PureComponent {
  state = {
    emails: [],
    selectedId: null,
    recipient: '',
    subject: '',
    index: 10,
    body: '',
    loading: true
  }

  reply = (email) => {
    const { selectedId } = this.state;
    this.setState(selectedId !== email._id ?
      {
        selectedId: email._id,
        recipient: email.from,
        subject: email.subject
          ? `Re: ${email.subject.split('Re: ').join('')}` : ''
      } :
      { recipient: '', subject: '', body: '', selectedId: null },
    );
  }

  send = () => {
    const { recipient, subject, body, emails, selectedId } = this.state;
    if (!recipient || !subject || !body) {
      return notifSystem.error('Erreur', 'Veuillez compléter les informations');
    }
    const emailId = emails.find(e => e._id === selectedId)?._id;
    if (!emailId) {
      return notifSystem.error('Erreur', 'La réponse n\'a pas pu être envoyée');
    }
    api.mailReceived
      .custom(`sendMail/${emailId}`)
      .post({ recipient, subject, body })
      .then(() => {
        this.setState({
          selectedId: null,
          recipient: '',
          subject: '',
          body: ''
        });
        return notifSystem.success(
          'Opération réussie',
          'Une réponse a été envoyée'
        );
      })
      .catch(err => notifSystem.error('Erreur', err.response.data));
  }

  componentDidMount() {
    ws.on('update_mailReceived', this.wsUpdate);
    this.loadMails();
  }

  componentWillUnmount() {
    ws.removeEventListener('update_mailReceived', this.wsUpdate);
  }

  wsUpdate = mail => {
    const { artisanId } = this.props;
    const { emails } = this.state;
    if (artisanId === mail.artisanId) {
      const index = emails.findIndex((e) => e._id === mail._id);
      if (index !== -1) {
        return api.mailReceived.get(mail._id)
          .then(res => {
            const email = res.body().data();
            this.setState({
              emails: emails.map((m) => (m._id === email._id ? email : m)),
            });
          });
      }
      return api.mailReceived.get(mail._id).then((res) => {
        const email = res.body().data();
        this.setState({ emails: [email, ...emails] });
      });
    }
  };

  updateData = (p, v) => this.setState({ [p]: v });

  loadMore = () => {
    const { index } = this.state;
    return this.promisifiedSetState({
      index: index + 10
    })
  }

  loadMails = () => {
    api.mailReceived
      .getAll({
        sort: { '_id': -1 },
        query: JSON.stringify({ 'login.artisan': this.props.artisanId })
      })
      .then(res => this.setState({
        emails: res.body().map(e => e.data()),
      }))
      .catch(() => notifSystem.error('Erreur', 'Il y a eu une erreur'))
      .finally(() => this.setState({ loading: false }));
  }

  componentDidUpdate(prevProps) {
    if (this.props.artisanId !== prevProps.artisanId) {
      this.loadMails();
    }
  }

  render() {
    const { emails, selectedId, index, loading } = this.state;
    const { classes } = this.props;
    return loading ?
      <div className={classes.loadingContainer}>
        <CircularProgress size={40} />
      </div> :
      <div >
        <WhenElementIsVisible
          hasMore={emails.length > index}
          onLoadMore={this.loadMore}
        >
          {emails && emails.length ? emails.slice(0, index).map((e, i) => (
            <Accordion
              key={i}
              className={classes.padding}
              expanded={selectedId === e._id}>
              <DisplayMail classes={classes} email={e} index={i}
                reply={this.reply} />
              <AccordionDetails>
                <Grid container justifyContent="space-between" spacing={2}>
                  <Grid item xs={12}>
                    <DisplayHtmlDom htmlBody={e.htmlBody || e.body} />
                  </Grid>
                  {selectedId === e._id && (
                    <ReplyComponent
                      classes={classes}
                      state={this.state}
                      updateData={this.updateData}
                      send={this.send}
                    />
                  )}
                  {e.answers?.length ? (
                    <DisplayAnswers classes={classes} answers={e.answers} />
                  ) : ''}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )) :
            <Typography align="center" variant='caption' component='h5'>
            Aucun mail reçu
            </Typography>}
        </WhenElementIsVisible>
      </div>
  }
}
export default withStyles(TabEmail, styles);
