import { createTheme } from '@mui/material';
import { blue } from '@mui/material/colors';
export const theme = createTheme({
  palette: {
    primary: blue,
  },
});
export const styles = {
  login: {
    'margin-top': '30%',
    padding: 20,
    textAlign: 'right',
  },
  img: {
    position: 'fixed',
    maxWidth: 300,
    top: 25,
    left: 50,
  },
  displayInput: {
    width: '45%',
    margin: '0 2.5%',
  },
  marginTopLeft: {
    float: 'left',
  }
};
export default {
  theme,
  styles
};
