import {
  List,
  Map
} from 'immutable';
import {
  CONFPROSP_FETCH
} from '../constants/ActionTypes';

const initialState = new List();
export default (state = initialState, action) => {
  switch (action.type) {
    case CONFPROSP_FETCH: {
      if (List.isList(action.payload)) {
        return action.payload;
      }
      if (Map.isMap(action.payload)) {
        const index = state
          .findIndex(d => d.get('_id') === action.payload.get('_id'));

        if (~index) {
          return state.set(index, action.payload);
        }
      }
      return state;
    }
    default:
      return state;
  }
};
