import React from 'react';
import { connect } from 'react-redux';

import {
  Checkbox,
  List, ListItem, ListItemButton, ListItemText, ListSubheader
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/system';
import { grey } from '@mui/material/colors';

import getDataById from '@lba-dev/package.local-globals/getDataById';

import { setDialogContentProp } from '../../actions/dialog';

const setCheckedCombos = setDialogContentProp('checkedCombos');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      data,
      checkedCombos
    }
  }
}) => ({
  data,
  checkedCombos
});

const mapDispatchToProps = {
  setCheckedCombos
};

const useStyles = makeStyles()({
  itemPadding: { paddingTop: 0, paddingBottom: 0 },
  subHead: {
    display: 'flex',
    justifyContent: 'space-between',
    background: grey[100]
  }
});

const CopyMasterDialog = ({ data, checkedCombos, setCheckedCombos }) => {
  const { classes } = useStyles();
  const handleFields = (value) => {
    const ncheckedCombos = [...checkedCombos].toggleValue(value);
    setCheckedCombos(ncheckedCombos);
  };
  return (
    <Box>
      <List>
        {data.map((elem, index) => [
          <ListSubheader key={`subHeader${index}`} className={classes.subHead}>
            {elem.name}
          </ListSubheader>,
          elem.childs.map((e, i) => {
            const labelId = `checkbox-list-secondary-label-${i}`;
            const categorie = getDataById.categories[e.categorie]?.name;
            return (
              <ListItem key={i} className={classes.itemPadding} disablePadding>
                <ListItemButton
                  className={classes.itemPadding}
                  onClick={() => handleFields(e._id)}
                >
                  <ListItemText
                    id={labelId}
                    primary={`- ${elem.path} - ${e.name}`}
                    secondary={`categorie: ${categorie}`}
                  />
                  <Checkbox
                    edge="end"
                    checked={checkedCombos.includes(e._id)}
                    inputProps={{ 'aria-labelledby': labelId }}
                    disableRipple
                  />
                </ListItemButton>
              </ListItem>
            );
          }),
        ])}
      </List>
    </Box>
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(CopyMasterDialog);
