import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { S_COMPTA, S_RH } from '@lba-dev/package.local-globals/services';

import { getPinned, smsTypes } from './constant';
import Content from './Content';
import DashChat from './DashChat';
import {
  loadConversations, updateUserChatConfig
} from '../../actions/conversations';
import ws from '../../ws';
import { SMSType } from '@lba-dev/package.local-globals/smsType';
import { demandePhoto } from '../../actions/general';
import audioFile from './audioFile/messagesound.mp3';

const ChatContent = [Content, DashChat];

const playSound = () => {
  const audio = new Audio(audioFile);
  audio.play()
    .catch(() => null);
};

const defaultState = (isLoading) =>
  smsTypes
    .reduce((a, b) =>
      ({ ...a, [b.type]: isLoading || [] }), {});

let chatChannel = null;
const Conversations = ({ isDash }) => {
  const cond = window.name === 'chat';
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(defaultState(true));
  const [lastMessages, setLastMessages] = useState(defaultState());
  const [notif, setNotif] = useState([]);
  const [page, setPage] = useState(0);
  const [stopLoadMore, setStopLoadMore] = useState(false);
  const [mounted, setMounted] = useState(false);
  const navigate = useNavigate();
  const [notifSms, setNotifSms] = useState(null);
  const location = useLocation();
  const chatPanel = useSelector(({ chatPanel }) => chatPanel);
  const Component = ChatContent[+!!isDash];

  const [user = {}, setUser] = useState(
    useSelector(({ users, userId }) =>
      users.find(e => e._id === userId)));

  const chatConfig = user.chatConfig || {};
  const [smsType, setSmsType] = useState(
    [S_COMPTA, S_RH].includes(user?.service) ? '' : (
      ((chatConfig.lastSelected || {}).smsType || 'artisan')));

  const reOrderConvs = (data) => data
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .sort((a, b) => b.unSeen - a.unSeen);

  const handleLastMessages = (data, oSmsType, add) => {
    setLastMessages((oldMessages) => ({
      ...oldMessages,
      [oSmsType]: add ? [...oldMessages[oSmsType], ...data] : data
    }));
  };

  const handleLoading = (value, smsType) => {
    setLoading((oldLoading) => ({ ...oldLoading, [smsType]: value }));
  };

  const getConversations = (oSmsType, oNotifsms) => {
    oSmsType = oSmsType || smsType;
    oNotifsms = oNotifsms || notifSms;
    const pinned = getPinned(oSmsType, user.pinnedConversations);
    const lastSelected = (!isDash && chatConfig.lastSelected) || {};
    handleLoading(true, oSmsType);
    setPage(0);
    setStopLoadMore(false);
    const pinnedIds = pinned.map(e => e._id);
    return loadConversations(oSmsType, pinnedIds, 0)
      .then((data) => {
        const nSelected = oNotifsms || (!mounted && lastSelected.selectedId);
        if (nSelected) {
          const selectElem = data.find(e => e._id === nSelected) || {};
          setSelected(selectElem);
          if (localStorage.getItem('chatWin')) {
            localStorage.removeItem('chatWin');
          }
        }
        if (oSmsType !== 'all' && data.some((e) => e.unSeenCount > 0)) {
          setNotif([...notif].updateOrInsert(oSmsType));
        }
        if (!setMounted) {
          setMounted(true);
        }
        handleLastMessages(data, oSmsType);
      })
      .finally(() => handleLoading(false, oSmsType));
  };

  const loadMore = useCallback(async () => {
    if (!stopLoadMore) {
      let currentSmsType = smsType;
      setSmsType((oSmsType) => {
        currentSmsType = oSmsType;
        return oSmsType;
      });
      if (currentSmsType) {
        const nPage = page + 1;
        handleLoading(true, currentSmsType);
        const pinned = getPinned(currentSmsType, user.pinnedConversations || [])
          .map(e => e._id);
        await loadConversations(currentSmsType, pinned, nPage)
          .then(data => {
            if (data.length) {
              return handleLastMessages(data, currentSmsType, true);
            }
            setStopLoadMore(true);
          })
          .finally(() => handleLoading(false, currentSmsType));
        setPage(nPage);
      }
    }
  }, [page]);

  const wsUpdate = obj => {
    let notFound = false;
    if (!obj || typeof obj !== 'object') {
      return;
    }
    let oSmsType = 'artisan';
    setSmsType((smsType) => {
      oSmsType = smsType;
      return smsType;
    });
    const actualType = (obj.artisan && 'artisan') ||
      (obj.intervention && 'intervention') ||
      (obj.devis && 'devis');
    const isForUser = obj.userLogin === user.login ||
    (obj.needToSee || []).includes(user._id);
    if (actualType && isForUser && !obj.sender) {
      playSound();
    }
    if (obj.newContact) {
      let isFresh = false;
      setSelected(s => {
        if (s.freshContact) {
          isFresh = true;
          const dataObject = (actualType === 'artisan' && 'artisanObject') ||
            (actualType === 'intervention' && 'interObject') ||
            (actualType === 'devis' && 'devisObject');
          const nSelect = { ...s };
          nSelect._id = obj[actualType];
          nSelect.dataObject = obj[dataObject] || {};
          nSelect.freshContact = false;
          getConversations(oSmsType);
          return nSelect;
        }
        isFresh = false;
        return s;
      });
      if (isFresh && obj.sender) {
        return;
      }
    }
    if (
      ((oSmsType === 'all' && !!obj.artisan) ||
        oSmsType in obj ||
        (oSmsType === 'intervention' && actualType === 'devis')) &&
      actualType &&
      ((!obj.sender && obj.type === SMSType.SMSIN) || obj.elemAdded)
    ) {
      if (obj.text || obj.path || obj.document || obj.audio) {
        setLastMessages((oldMessages = {}) => {
          const smsInd = (oldMessages[oSmsType] || []).findIndex(
            (e) => e._id === obj[actualType]
          );
          if (~smsInd) {
            const oldTypeMessages = [...oldMessages[oSmsType]];
            const elem = oldTypeMessages[smsInd];
            elem.mode = +obj.mode;
            if (!obj.sender) {
              elem.message = obj.text;
              elem.date = obj.date || new Date();
              elem.dataObject = {
                ...elem.dataObject,
                logo: obj.logo || '',
              };
            }
            let nSelected = {};
            setSelected((selected) => {
              nSelected = selected;
              return selected;
            });
            if (nSelected._id !== elem._id && !obj.sender && isForUser) {
              elem.seenBy = [];
              elem.unSeenCount = (elem.unSeenCount || 0) + 1;
              elem.unSeen = true;
            }
            oldTypeMessages[smsInd] = elem;
            oldMessages[oSmsType] = reOrderConvs(oldTypeMessages);
          } else {
            notFound = true;
          }
          return { ...oldMessages };
        });
      }
      if (notFound && isForUser) {
        getConversations(oSmsType);
      }
    }
    if (actualType && !(oSmsType in obj ||
      (oSmsType === 'intervention' && actualType === 'devis')) &&
      obj.text && isForUser && !obj.sender) {
      setNotif((oNotif) => [...new Set([...oNotif].concat([
        (obj.artisan && 'artisan') ||
        ((obj.intervention || obj.devis) && 'intervention')])
      )]);
    }
  };

  const selectFromNotif = (chatWin) => {
    if ((!!isDash === chatWin.inWindow) &&
        chatWin && (chatWin.selectId || chatWin.selectType)) {
      setNotifSms(chatWin.selectId);
      let oLastMessage = [];
      setLastMessages((lastM) => {
        oLastMessage = lastM[chatWin.selectType];
        return lastM;
      });
      setSmsType((oSmsType) => {
        if (oSmsType === chatWin.selectType) {
          const elem = oLastMessage.find(e => e._id === chatWin.selectId);
          if (elem) {
            setSelected(elem);
          } else {
            getConversations(oSmsType);

          }
          return oSmsType;
        }
        return chatWin.selectType;
      });
    }
  };

  const onStorageChange = () => {
    if (!isDash) {
      if (!localStorage.token) {
        localStorage.removeItem('chatWin');
        window.close();
      }
      const chatWin = (localStorage.getItem('chatWin') || '').parse() || {};
      if (chatWin && chatWin.close) {
        localStorage.removeItem('chatWin');
        window.close();
      }
    }
  };

  const listenChannel = (e) => {
    const data = e && e.data;
    if (data && data.close && !isDash) {
      window.close();
    }
    if (data && (data.selectId || data.selectType)) {
      selectFromNotif(data);
    }
  };

  useEffect(() => {
    if (!isDash && !cond) {
      navigate('/');
    }
    chatChannel = new BroadcastChannel('chatChannel');
    chatChannel.addEventListener('message', listenChannel);
    ws.on('update_sms', wsUpdate);
    window.addEventListener('storage', onStorageChange);
    setMounted(true);
    return () => {
      ws.removeEventListener('update_sms', wsUpdate);
      chatChannel.addEventListener('message', listenChannel);
      chatChannel.close();
      window.removeEventListener('storage', onStorageChange);
    };
  }, []);

  useEffect(() => {
    const elemIndex = smsType && lastMessages[smsType]
      .findIndex(e => e._id === selected._id);
    if (smsType && ~elemIndex) {
      const nMessages = [...lastMessages[smsType]];
      const elem = nMessages[elemIndex];
      if (!(elem.seenBy || []).find(e => e.login === user.login)) {
        nMessages[elemIndex] = {
          ...nMessages[elemIndex],
          unSeenCount: 0,
          seenBy: (nMessages.seenBy || []).concat({
            login: user.login,
            date: new Date()
          })
        };
        setLastMessages({ ...lastMessages, [smsType]: [...nMessages] });
      }
      setNotifSms(null);
    }
    if (selected && selected._id && !selected.freshContact) {
      updateUserChatConfig(user._id, {
        lastSelected: {
          smsType,
          selectedId: selected._id
        }
      });
    }
  }, [selected]);

  useEffect(() => {
    if (smsType) {
      const unSeenExist = [...lastMessages[smsType]]
        .some(e => e.unSeenCount > 0);
      if (!unSeenExist && notif.includes(smsType)) {
        setNotif([...notif].toggleValue(smsType));
      }
    }
  }, [lastMessages]);

  useEffect(() => {
    if (smsType) {
      handleLastMessages([], smsType);
      getConversations();
    }
  }, [smsType]);

  useEffect(() => {
    if (location.search.includes('chatId')) {
      const id = new URLSearchParams(location.search).get('chatId');
      const chatType = new URLSearchParams(location.search).get('chatType');
      const selectedNumber = new URLSearchParams(location.search)
        .get('selectedNumber');
      setSmsType(chatType === 'devis' ? 'intervention' : chatType);
      if (id) {
        setSelected({
          _id: id,
          type: chatType,
          selectedNumber
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (chatPanel.open) {
      setSmsType(
        chatPanel.chatType === 'devis' ? 'intervention' : chatPanel.chatType
      );
      if (chatPanel.id) {
        setSelected({
          _id: chatPanel.id,
          type: chatPanel.chatType,
          selectedNumber: chatPanel.selectedNumber
        });
      }
    }
  }, [chatPanel]);

  useEffect(() => {
    if (smsType) {
      const pinned = getPinned(smsType, user.pinnedConversations);
      let data = lastMessages[smsType].map(e => ({
        ...e, isPinned: !!pinned.find(p => p._id === e._id)
      }));
      if (smsType === 'allPinned' && pinned.length > data.length) {
        getConversations();
      } else {
        handleLastMessages(data, smsType);
      }
    }
  }, [user.pinnedConversations]);

  const setSmsTypeState = (smsType) => {
    if (smsType === 'demandePhoto') {
      demandePhoto('Demande de photo', {
        tel: '',
        categorie: '',
        zipCode: '',
        userId: user._id
      }, () => {
        setSmsType('intervention')
      });
    } else {
      setSmsType(smsType);
    }
  }
  return <Component
    {...{
      setSmsType: setSmsTypeState, smsType, notif, user,
      lastMessages, setLastMessages, selected, setSelected,
      loading, setUser, loadMore
    }}
  />;
};

export default Conversations;
