import React, { PureComponent } from 'react';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CommentsList from './CommentsList';

const ExpandMore = styled((props) =>
  <IconButton {...props} />)(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const mapStateToProps = ({
  users,
  userId,
}) => ({
  userId,
  users,
});

class CommentsToExpandList extends PureComponent {
  state = {
    expanded: false
  };

  handleExpandClick = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  renameKeys = (keysMap, obj) =>
    Object.keys(obj).reduce(
      (acc, key) => ({
        ...acc,
        ...{ [keysMap[key] || key]: obj[key] }
      }),
      {}
    );

  render() {
    const { expanded } = this.state;
    const { userId, users, comments } = this.props;
    const user = users.find(e => e._id === userId);
    const commentsFormated = comments.map(e => this.renameKeys({
      comment: 'commentValue',
      creation: 'date',
      user: 'userId'
    }, e));
    return (
      <>
        <ExpandMore
          expand={expanded}
          onClick={this.handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CommentsList
            users={users}
            userId={user._id}
            comments={fromJS(commentsFormated)}
            hideInput
            customPadding='5px 0px'
            formatComments={true}
          />
        </Collapse>
      </>
    );}
}

export default compose(
  connect(mapStateToProps)
)(CommentsToExpandList);
