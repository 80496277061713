import conversation from './router/conversation';
import sidebar from './sidebar';
import ui from './ui';
import router from './router';

export default {
  conversation,
  router,
  sidebar,
  ui,
};
