export const metiersStepsKeys = [
  { name: 'Titre', key: 'name' },
  { name: 'image', key: 'image' },
  //informations
  { title: 'Informations' },
  { name: 'Description', key: 'description' },
  { name: 'Inclus dans la prestation', key: 'prestations' },
  { name: 'Matériel à vendre sur place', key: 'materials' },
  { name: 'Context', key: 'context' },
  { name: 'Consignes', key: 'consignes' },
  { name: 'Sur devis ?', key: 'obligDevis' },
  { name: 'Fourchette de prix min (€)', key: 'minPrice' },
  { name: 'Fourchette de prix max (€)', key: 'maxPrice' },
  //discours client
  { title: 'Discours client' },
  { name: 'Questions pour le client', key: 'questions' },
  { name: 'Bon à savoir', key: 'help' },
  { name: 'Image (Bon à savoir)', key: 'attachements' },
  { name: 'Conseil commercial', key: 'mailText' },
  { name: 'Audio (Conseil commercial)', key: 'audio' },
  //discours artisan
  { title: 'Discours artisan' },
  { name: 'Questions pour l\'artisan ?', key: 'artisanQuestions' },
  { name: 'Audio (Questions pour l\'artisan ?)', key: 'audioAQuestion' },
  { name: 'Pièges à éviter', key: 'helpArtisan' },
  { name: 'Image (Pièges à éviter)', key: 'attachementsHelpArtisan' },
  { name: 'Discours artisan', key: 'artisanSpeech' },
  { name: 'Audio (Discours artisan)', key: 'audioASpeech' },
];

export const finalKeysFields = {
  metiersSteps: metiersStepsKeys,
  metiersDevis: metiersStepsKeys,
};
