import {
  List,
  AttachMoney,
  VisibilityOff,
  Visibility,
  FiberManualRecord,
  FiberSmartRecord,
  VerifiedUser,
  MoneyOff,
  Warning,
  AssignmentInd,
} from '@mui/icons-material';

import { listOrLinkByUser } from './functions';
import { S_SATCL } from '@lba-dev/package.local-globals/services';
import { permit } from '@lba-dev/package.local-globals/restrictions';

const baseUrl = '/savIntervention/list/';
const baseParams = 'login-ajout';

export default [

  {
    name: 'Demande de Facture',
    icon: List,
    childs: [
      {
        name: 'Non pris en charge',
        icon: List,
        count: true,
        link: () => '/intervention/list/dFact',
      },
      {
        name: 'Envoyées',
        icon: List,
        count: true,
        link: () => '/intervention/list/dFE',
      },
    ],
  },
  {
    name: 'Demande de attestation',
    icon: List,
    childs: [
      {
        name: 'Non pris en charge',
        icon: List,
        count: true,
        link: () => '/intervention/list/dAtt',
      },
      {
        name: 'Envoyées',
        icon: List,
        count: true,
        link: () => '/intervention/list/dAttE',
      },
    ],
  },
  {
    name: 'SAV Non pris en charge',
    icon: VisibilityOff,
    childs: [
      {
        name: 'Ajoutés manuellement',
        icon: FiberManualRecord,
        link: () => '/intervention/list/mSavNpc',
        count: true,
      },
      {
        name: 'Ajoutés automatiquement',
        icon: FiberSmartRecord,
        link: () => '/intervention/list/aSavNpc',
        count: true,
      }
    ]
  },
  {
    name: 'SAV Pris en charge',
    icon: Visibility,
    childs: [
      {
        name: 'Tout',
        icon: List,
        link: () => '/savIntervention/list/newDossier',
        count: true,
      },
      {
        name: 'Avec Reglement',
        icon: AttachMoney,
        childs: [
          {
            name: 'Tout',
            icon: List,
            link: () => '/savIntervention/list/regle',
            count: true,
          },
          {
            name: 'Ordinaires',
            icon: List,
            link: () => '/savIntervention/list/regleOrdinaire',
            count: true,
          },
          {
            name: 'Complexes',
            icon: List,
            link: () => '/savIntervention/list/regleComplexe',
            count: true,
          },
          {
            name: 'Urgent',
            icon: List,
            link: () => '/savIntervention/list/regleUrgent',
            count: true,
          },
        ]
      },
      {
        name: 'Sans Reglement',
        icon: MoneyOff,
        childs: [
          {
            name: 'Tout',
            icon: List,
            link: () => '/savIntervention/list/noRegle',
            count: true,
          },
          {
            name: 'Ordinaires',
            icon: List,
            link: () => '/savIntervention/list/noRegleOrdinaire',
            count: true,
          },
          {
            name: 'Complexes',
            icon: List,
            link: () => '/savIntervention/list/noRegleComplexe',
            count: true,
          },
          {
            name: 'Urgent',
            icon: List,
            link: () => '/savIntervention/list/noRegleUrgent',
            count: true,
          },
        ]
      },
      {
        name: 'Réclamations',
        icon: Warning,
        childs: [
          {
            name: 'Tout',
            icon: List,
            link: () => '/savIntervention/list/reclamations',
            count: true,
          },
          {
            name: 'En cours de gestion',
            icon: List,
            link: () => '/savIntervention/list/ecg',
            count: true,
          },
          {
            name: 'Clôturées',
            icon: List,
            link: () => '/savIntervention/list/rClotures',
            count: true,
          },
          {
            name: 'Pertes',
            icon: List,
            link: () => '/savIntervention/list/pertes',
            count: true,
          },
        ]
      }
    ]
  },
  {
    name: 'SAV clôturés',
    icon: VerifiedUser,
    link: () => '/savIntervention/list/clotures',
    count: true,
  },
  {
    name: (user) => permit(user, { key: 'savAssign' })
      ? 'Mon équipe'
      : 'Mes SAV',
    icon: AssignmentInd,
    childs: [
      {
        name: 'SAV Non pris en charge',
        icon: VisibilityOff,
        childs: ({ user, users }) => [
          listOrLinkByUser({
            user,
            users,
            name: 'Ajoutés manuellement',
            link: 'mSavNpc',
            icon: FiberManualRecord,
            service: S_SATCL,
            baseUrl: '/intervention/list/',
            baseParams: 'login-userSav'
          }),
          listOrLinkByUser({
            user,
            users,
            name: 'Ajoutés automatiquement',
            link: 'aSavNpc',
            icon: FiberSmartRecord,
            service: S_SATCL,
            baseUrl: '/intervention/list/',
            baseParams: 'login-userSav'
          }),
        ]
      },
      {
        name: 'Pris en charge',
        icon: Visibility,
        childs: [
          {
            name: 'Avec Reglement',
            icon: AttachMoney,
            childs: ({ user, users }) => [
              ...[
                { name: 'Liste général', link: 'mesRegle' },
                { name: 'Ordinaires', link: 'mesRegleOrdinaire' },
                { name: 'Complexes', link: 'mesRegleComplexe' },
                { name: 'Urgent', link: 'mesRegleUrgent' },
              ].map(({ name, link }) => listOrLinkByUser({
                user,
                users,
                name,
                link,
                icon: List,
                service: S_SATCL,
                baseUrl,
                baseParams
              }))
            ]
          },
          {
            name: 'Sans Reglement',
            icon: MoneyOff,
            childs: ({ user, users }) => [
              ...[
                { name: 'Liste général', link: 'mesNoRegle' },
                { name: 'Ordinaires', link: 'mesNoRegleOrdinaire' },
                { name: 'Complexes', link: 'mesNoRegleComplexe' },
                { name: 'Urgent', link: 'mesNoRegleUrgent' },
              ].map(({ name, link }) => listOrLinkByUser({
                user,
                users,
                name,
                link,
                icon: List,
                service: S_SATCL,
                baseUrl,
                baseParams
              })),
            ]
          },
          {
            name: 'Réclamations',
            icon: Warning,
            childs: ({ user, users }) => [
              ...[
                { name: 'Liste général', link: 'mesReclamations' },
                { name: 'En cours de gestion', link: 'mesEcg' },
                { name: 'Clôturées', link: 'mesRClotures' },
                { name: 'Pertes', link: 'mesPertes' },
              ].map(({ name, link }) => listOrLinkByUser({
                user,
                users,
                name,
                link,
                icon: List,
                service: S_SATCL,
                baseUrl,
                baseParams
              })),
            ]
          }
        ]
      }
    ]
  }
];
