import Immutable from 'immutable';

import {
  Assignment,
  Call,
  AttachMoney,
  Payment,
  Person,
  Search,
  Send,
  Schedule,
  Assessment,
  GroupAdd
} from '@mui/icons-material';

import { D_NOT, D_PRE, D_MAR } from '@lba-dev/package.local-globals/deStatus';

import {
  S_SATCL,
  S_PART
} from '@lba-dev/package.local-globals/services';

import { I_APR } from '@lba-dev/package.local-globals/iStatus';

import { permit } from '@lba-dev/package.local-globals/restrictions';

import {
  reglement,
  payment,
  schedule
} from '../../../actions/inter';

import { artisanNumbers } from '../Actions';
import { call } from '../../../utils/call';
import {
  priseDeContact,
  sendSavToMarket
} from '../../../actions/priseDeContact';
import { openAssignDialog } from '../../../actions/general';
import { assignNotif } from '../../../actions/savIntervention';

export default [
  {
    name: 'Modifier',
    icon: Assignment,
    link: ({ id }) => {
      window.open(`/savIntervention/${id}`);
    },
    action: (props, elem) => {
      props.navigate(`/savIntervention/${elem.id}`);
    },
  },
  {
    name: 'Appeler',
    icon: Call,
    sub: [
      {
        name: 'Téléphone Client 1',
        value: 'client.tel1',
        action: (props, elem) => call(elem.client.tel1),
      },
      {
        name: 'Téléphone Client 2',
        value: 'client.tel2',
        action: (props, elem) => call(elem.client.tel2),
      },
      {
        name: 'Téléphone Client 3',
        value: 'client.tel3',
        action: (props, elem) => call(elem.client.tel3),
      },
      ...artisanNumbers,
    ],
  },
  {
    name: 'Récap. Artisan',
    icon: Person,
    visible: 'artisan',
    link: ({ artisan }) => {
      window.open(`/artisan/${artisan}/recap`);
    },
    action: (props, elem) => {
      props.navigate(`/artisan/${elem.artisan}/recap`);
    },
    customVisible: (elem, props) =>
      permit(
        props.users.find((u) => u._id === props.userId),
        { key: 'externSAV' }
      ),
  },
  {
    name: 'Prospection',
    icon: Search,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return props.selected && props.selected.aDemarcher > D_NOT && user;
    },
    link: (elem) => {
      window.open(`/prosp/list?interSearch=${elem.id}`);
    },
    action: (props, elem) => {
      props.navigate(`/prosp/list?interSearch=${elem.id}`);
    },
  },
  {
    name: 'Réglement Client',
    icon: AttachMoney,
    customVisible: (elem, props) =>
      props.selected && props.selected.artisan &&
      permit(
        props.users.find((u) => u._id === props.userId),
        { key: 'displaySAVActions' }
      ),
    action: reglement,
  },
  {
    name: 'Je prends!',
    icon: Schedule,
    customVisible: (elem, props) =>
      Immutable.List.isList(props.users) &&
      props.selected &&
      props.selected.status === I_APR &&
      props.users.find((e) => e._id === props.userId).service === S_PART &&
      props.selected.aDemarcher === D_MAR,
    action: (props, elem) => schedule(elem),
  },
  {
    name: 'Envoyer dans market',
    icon: Send,
    customVisible: (elem, props) =>
      Immutable.List.isList(props.users) &&
      props.selected &&
      props.selected.status === I_APR &&
      props.users.find((e) => e._id === props.userId).service === S_PART &&
      props.selected.aDemarcher === D_PRE,
    action: (_, inter) => sendSavToMarket(inter),
  },
  {
    name: 'Paiement SST',
    icon: Payment,
    customVisible: (buttonProps, props) =>
      props.selected && props.selected.artisan &&
      permit(
        props.users.find((u) => u._id === props.userId),
        { key: 'displaySAVActions' }
      ),
    action: payment,
  },
  {
    name: 'Prise de contact',
    icon: Assessment,
    action: (props, savInter) => priseDeContact(savInter, props.user),
  },
  {
    name: 'Réattribuer le dossier',
    icon: GroupAdd,
    customVisible: (inter, props) =>
      permit(props.users.find((u) => u._id === props.userId)),
    action: (props, savInter) => openAssignDialog({
      data: savInter,
      collection: 'savInterventions',
      filterUsers: [S_SATCL],
      cb: assignNotif
    })
  },
];
