import { List, ListItem, ListItemText, Popover } from '@mui/material';
import { Map } from 'immutable';
import React from 'react';
import { appel as appelArtisan } from '../../../actions/artisanContext';
import { appel as appelInter } from '../../../actions/inter';

const TelPopover = ({ isArtisan, anchorEl, setAnchorEl, selected, tels }) => {
  const data = selected.dataObject || {};
  const appel = isArtisan ? appelArtisan : appelInter;
  return <Popover
    id="artisanTels"
    open={!!anchorEl}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    sx={{ left: 20 }}
    onClose={() => {
      setAnchorEl(null);
    }}
  >
    <List>
      {tels.map((e, i) =>
        <ListItem key={i} onClick={() => {
          appel(
            {
              ...(isArtisan ? {
                artisan: new Map({
                  _id: selected._id,
                  companyName: selected.companyName
                })
              } : {
                selected: { id: data.id }
              }),
            },
            e.tel,
            {
              client: data.client,
              ...(!data.onSitePayment ? { billing: data.billing } : {})
            }
          );
          setAnchorEl(null);
        }} button>
          <ListItemText
            primary={e.title}
            secondary={e.tel}
          />
        </ListItem>)}
    </List>
  </Popover>;
};

export default TelPopover;
