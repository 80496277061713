import {
  NOT_CONFIRMED, CHIEF
} from '@lba-dev/package.local-globals/permissions';
import componentLoader from '../../componentLoader';

const BtoBCampaign = componentLoader(() =>
  import(
    /* webpackChunkName: "BtoBCampaign" */
    '../../views/BtoBCampaign'
  )
);

const BtoBCSV = componentLoader(() =>
  import(
    /* webpackChunkName: "BtoBCSV" */
    '../../views/BtoBCSV'
  )
);

const DemarcheBtoB = componentLoader(() =>
  import(
    /* webpackChunkName: "DemarcheBtoB" */
    '../../components/DemarcheBtoB'
  )
);

const ListBtoB = componentLoader(() =>
  import(
    /* webpackChunkName: "ListBtoB" */
    '../../views/List/ListBtoB'
  )
);

const AjoutBtoB = componentLoader(() =>
  import(
    /* webpackChunkName: "AjoutBtoB" */
    '../../views/AjoutBtoB'
  )
);

export default [
  {
    path: '/btob/demarche',
    component: DemarcheBtoB,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/btob/csv',
    component: BtoBCSV,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/btob/campaign',
    component: BtoBCampaign,
    restrictions: () => CHIEF,
  },
  {
    path: '/btob/list/:filter/:query',
    component: ListBtoB,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/btob/list/:filter',
    component: ListBtoB,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/btob/list',
    component: ListBtoB,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/btob/:id',
    component: AjoutBtoB,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/btob',
    component: AjoutBtoB,
    restrictions: () => NOT_CONFIRMED,
  },
];
