import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import red from '@mui/material/colors/red';
import { Warning } from '@mui/icons-material';

import {
  R_CLI,
  L_CLI,
  D_REF,
  R_PJ
} from '@lba-dev/package.local-globals/recStatus';
import { R_DATAS } from '@lba-dev/package.local-globals/rStatus';
import {
  data as rCausesCli,
} from '@lba-dev/package.local-globals/rCauses';
import {
  CauseData as lCauses,
  CatData as categories,
} from '@lba-dev/package.local-globals/lCauses';
import {
  CatData as pjCategories,
} from '@lba-dev/package.local-globals/pjCauses';

const statusToData = {
  [R_CLI]: {
    causes: rCausesCli,
    name: 'Recouvrement client',
  },
  [L_CLI]: {
    causes: lCauses,
    categories,
    name: 'Litige',
  },
  [D_REF]: {
    name: 'Dossier supprimé',
  },
  [R_PJ]: {
    name: 'Procédures judiciaires',
    categories: pjCategories
  },
};

const RecoveryIcon = ({ recovery }) => {
  const data = statusToData[recovery.status] || {};
  const category =
    data.categories && data.categories.find((c) => c._id === recovery.category);
  const cause =
    data.causes && data.causes.find((c) => c._id === recovery.cause);
  const status =
    recovery.recStatus && R_DATAS.find((c) => c._id === recovery.recStatus);

  return (
    <Tooltip disableInteractive
      title={
        <span>
          {data.name} <br />
          {status ? `Status - ${status.name}` : ''}
          {!!status && <br />}
          {category ? `Catégorie - ${category.name}` : ''}
          {!!category && <br />}
          {cause ? `Cause - ${cause.name}` : ''}
        </span>
      }
      children={<Warning style={{ color: red[500], fontSize: 20 }} />}
    />
  );
};

export default RecoveryIcon;
