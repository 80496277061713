import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import CustomTextField from '../CustomInputs/CustomTextField';

const paths = {
  floor: ['floor'],
  code: ['code'],
  building: ['building'],
  compl: ['compl']
};

const AddressComplements = ({ floor, code, building, compl, setData }) => [
  <Grid key="two" container spacing={3}>
    <CustomTextField grid xs={12} sm={12} md={9} lg={6} capsLocked
      label="Complément d'adresse"
      value={compl}
      path={paths.compl}
      setData={setData}
    />
  </Grid>,
  <Grid key="one" container spacing={3}>
    <CustomTextField grid xs={12} sm={4} md={3} lg={2} capsLocked
      label="Etage"
      value={floor}
      path={paths.floor}
      setData={setData}
    />
    <CustomTextField grid xs={12} sm={4} md={3} lg={2} capsLocked
      label="Code"
      value={code}
      path={paths.code}
      setData={setData}
    />
    <CustomTextField grid xs={12} sm={4} md={3} lg={2} capsLocked
      label="Batiment"
      value={building}
      path={paths.building}
      setData={setData}
    />
  </Grid >
];

AddressComplements.propTypes = {
  floor: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  building: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired
};

AddressComplements.defaultProps = {
  floor: '',
  code: '',
  building: ''
};

export default AddressComplements;
