import React, { useState } from 'react';

import {
  getClientInfo
} from '@lba-dev/package.local-globals/getClientInfo';
import { setDialogContentProp } from '../../../actions/dialog';
import { connect } from 'react-redux';
import CustomMaterialTable from '../../CustomMaterialTable';
import { columns } from './constants';
import { withAPISubscription } from '../../../hoc';
import { Typography } from '@mui/material';
import { red } from '@mui/material/colors';

const setInter = setDialogContentProp('inter');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      artisan,
      inter,
      fileLink
    },
  },
}) => ({
  artisan,
  inter,
  fileLink
});

const mapDispatchToProps = { setInter };

const ListInterArtisanDialog = ({
  interventions = [], fileLink, inter, setInter
}) => {
  const [error, setError] = useState(false);
  const docAdded = interventions.find(e =>
    e.dropbox.find(d => d.link === fileLink)
  );

  const handleInter = (nInter) => {
    if (!docAdded) {
      setInter(nInter?._id !== inter?._id && nInter);
    } else if (!error) {
      setError(true);
    }
  };
  return <CustomMaterialTable
    columns={columns}
    data={interventions.map(e => ({
      ...e,
      tableData: {
        checked: e._id === docAdded?._id || e._id === inter?._id
      }
    }))}
    components={{
      Toolbar: () => docAdded ?
        <Typography
          fontWeight='bold'
          textAlign='center'
          style={{ color: error && red[500] }}
        >
          Attention! le document a déjà été ajouté à l'OS {docAdded.id}
          <br />
          Veuillez le supprimer avant.
        </Typography> : null
    }}
    onRowClick={(e, rowData) => handleInter(rowData)}
    options={{
      tableLayout: 'fixed',
      search: false,
      selection: true,
      pageSize: 10,
      showSelectAllCheckbox: false,
      actionsColumnIndex: -1,
      selectionProps: rowData => ({
        ...(docAdded ? {
          color: rowData._id === docAdded?._id ? 'primary' : 'secondary',
          checked: rowData._id === docAdded?._id
        } : {}),
        onClick: () => handleInter(rowData)
      })
    }}
  />;
};

const fetch = (api, { artisan }) =>
  api.interventions.getAll({
    query: JSON.stringify({
      artisan,
    }),
    sort: { _id: -1 }
  })
    .then(res => res.body().map(e => e.data()))
    .then(interventions => interventions.map(i => ({
      ...i,
      client: getClientInfo(i, { shortCivil: true })
    })));

export default connect(
  mapStateToProps, mapDispatchToProps
)(withAPISubscription(fetch, null, {
  default: [],
  name: 'interventions',
  shouldReload: () => false,
  withLoading: true
})(ListInterArtisanDialog));
