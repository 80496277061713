import {
  Check,
  Close,
  Assignment,
  Person,
  Call,
  Sms,
  Edit,
} from '@mui/icons-material';
import {
  checkProposition, openPropositionDialog
} from '../../../actions/proposition';
import { appel, showSMS } from '../../../actions/inter';
import { artisanNumbers } from '../Actions';
import { P_TER } from '@lba-dev/package.local-globals/propositionStatus';
import {
  P_NONE,
  P_ACCEPTED,
  P_REJECTED
} from '@lba-dev/package.local-globals/isValidStatus';

export default [
  {
    name: 'Modifier',
    icon: Assignment,
    action: (props, elem) => {
      props.navigate(`/intervention/${elem.id}`);
    },
    link: ({ id }) => {
      window.open(`/intervention/${id}`);
    },
  },
  {
    name: 'Récap. Artisan',
    icon: Person,
    action: (props, elem) => {
      props.navigate(`/artisan/${elem.artisan}/recap`);
    },
    link: ({ artisan }) => {
      window.open(`/artisan/${artisan}/recap`);
    },
  },
  {
    name: 'SMS',
    icon: Sms,
    sub: [
      {
        name: 'Conversation Artisan ',
        customVisible: (props, elem) => elem.selected && elem.selected.artisan,
        value: 'currentArtisan.tel1',
        action: (props, elem) => {
          showSMS(props, elem.artisan, 'artisan');
        },
      },
    ]
  },
  {
    name: 'Appeler',
    icon: Call,
    sub: [
      {
        name: 'Téléphone Client 1',
        value: 'client.tel1',
        action: (props, elem) => {
          appel(
            props,
            elem.client.tel1,
            { client: elem.client }
          );
        },
      },
      {
        name: 'Téléphone Client 2',
        value: 'client.tel2',
        action: (props, elem) => {
          appel(
            props,
            elem.client.tel2,
            { client: elem.client }
          );
        },
      },
      {
        name: 'Téléphone Client 3',
        value: 'client.tel3',
        action: (props, elem) => {
          appel(
            props,
            elem.client.tel3,
            { client: elem.client }
          );
        },
      },
      {
        name: 'Payeur tel 1',
        value: 'billing.tel1',
        action: (props, elem) => {
          appel(props, elem.billing.tel1, {
            client: elem.client,
            billing: elem.billing,
          });
        },
      },
      {
        name: 'Payeur tel 2',
        value: 'billing.tel2',
        action: (props, elem) => {
          appel(props, elem.billing.tel2, {
            client: elem.client,
            billing: elem.billing,
          });
        },
      },
      {
        name: 'Payeur tel 3',
        value: 'billing.tel3',
        action: (props, elem) => {
          appel(props, elem.billing.tel3, {
            client: elem.client,
            billing: elem.billing,
          });
        },
      },
      ...artisanNumbers,
    ],
  },
  {
    name: 'Modifier la mise à jour',
    icon: Edit,
    customVisible: (elem, props) =>
      props.selected &&
        props.selected.proposition &&
        props.selected.onSitePayment &&
        (props.selected.proposition || {}).isValid === P_NONE &&
        props.selected.proposition.status === P_TER,
    action: (props, elem) => openPropositionDialog(elem),
  },
  {
    name: 'Valider',
    icon: Check,
    customVisible: (elem, props) => props.selected &&
      (props.selected.proposition || {}).isValid !== P_ACCEPTED,
    action: (props, elem) => checkProposition(P_ACCEPTED, elem, 'Accept'),
  },
  {
    name: 'Refuser',
    icon: Close,
    customVisible: (elem, props) => props.selected &&
      ((props.selected.proposition || {}).isValid === P_ACCEPTED ||
      (props.selected.proposition || {}).isValid === P_NONE),
    action: (props, elem) => checkProposition(P_REJECTED, elem, 'Refus'),
  },
];

