import React from 'react';

import { Chip, Tooltip } from '@mui/material';

import { getConversationWithTel } from '../../../actions/conversations';
import { CameraAlt } from '@mui/icons-material';
import {
  data as dPhotoStatus
} from '@lba-dev/package.local-globals/dPhotoStatus';

const DemandePhotoIcon = ({ call, cb }) => {
  const callerIdNumber = call.callerIdNumber;
  const demandePhoto = call?.demandePhoto || {};
  const statusData = dPhotoStatus.find(e => e._id === demandePhoto.status);
  const color = ((statusData || {}).color || {})[500];
  return demandePhoto.nbPhotos ? (
    <Tooltip title='Ouvrir la conversation'>
      <Chip
        clickable
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (cb) {
            cb();
          }
          getConversationWithTel(callerIdNumber);
        }}
        style={{
          borderColor: color,
          color,
        }}
        size="small"
        variant="outlined"
        icon={<CameraAlt
          style={{
            color
          }}
        />}
        label={demandePhoto.nbPhotos}
      />
    </Tooltip>
  ) : '';
};

export default DemandePhotoIcon;
