/* eslint-disable max-len */
const mapKeys = {
  production: 'eyJraWQiOiI4NVY4WDY2NEZRIiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJaWjU2NVc4UTIzIiwiaWF0IjoxNzI2NzU1NDQxLCJvcmlnaW4iOiIqLmxiYS1vZmZpY2UuZnIifQ.aI4jjf3xR9HFgvsQp2P8mEdy5dxcgtuepOMzXwxX6j0ujbLvM4nXBx_dFNNP3ZU2O-56YPcrwl71trwiF11WKA',
  localOrStaging: 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlE0NlNIR0ZDODgifQ.eyJpc3MiOiJaWjU2NVc4UTIzIiwiZXhwIjo0ODQ2ODIwNTI4LCJhdWQiOiJhcHBzdG9yZWNvbm5lY3QtdjEiLCJpYXQiOjE3MjY4MjA1Mjh9.XiEjCiOHftr4NmfAHCvj-ZeV3EKLAnX50NnOJO42V236PNr6BQchDXd4q7DrhMbFy5j3XDhtTksC7T8yZAi-8g'
}

export default {
  mapbox:
    'pk.eyJ1IjoiZGFkb2Y4MTE1MiIsImEiOiJjbTBrcHVyZWM' +
    'wd3VwMmpxd3NkNWFqenlhIn0.yWYY-HWWqn9vBI9c6TFOZg',
  mapkit: process.env.NODE_ENV !== 'production' ? mapKeys.localOrStaging : mapKeys.production,
};
