import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { ThemeProvider } from '@mui/material/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { CSVLink } from 'react-csv';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import { IconButton } from '@mui/material';
import withTheme from '../hoc/withTheme';

const mapStateToProps = (state) => ({
  user: state.users.find((u) => u._id === state.userId),
});

const styles = (theme, _params, classes) => ({
  smallButton: {
    height: 40,
    width: 40,
    marginBottom: 8,
    opacity: 0,
    transform: 'scale(0)',
    transition: 'transform 0.2s, opacity 0.4s',
    [theme.breakpoints.down('sm')]: {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
  subButtons: {
    position: 'absolute',
    width: 40,
    bottom: 56,
    right: 8,
    pointerEvents: 'none',
    [theme.breakpoints.down('sm')]: {
      pointerEvents: 'auto',
    },
  },
  buttons: {
    position: 'fixed',
    bottom: 60,
    zIndex: theme.zIndex.drawer + 50,
    right: 94,
    float: 'right',
    [`&:hover .${classes.subButtons}`]: {
      pointerEvents: 'auto',
    },
    [`&:hover .${classes.subButtons} .${classes.smallButton}`]: {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
  bottom: {
    bottom: 15,
  },
  tooltip: {
    lineHeight: 1.5,
    padding: '4px 8px',
    minHeight: 'inherit',
  },
});

class MyButton extends PureComponent {
  state = {
    visible: false,
  };

  render() {
    const { classes, buttons, disabled, user } = this.props;
    const sideBarStats = user && permit(user, { key: 'sideBarStatsBottom' });
    const SmallButton = (props) => {
      let res = null;
      if (props.condition()) {
        res = (
          <Fab
            color={props.small || props.palette ? 'primary' : 'secondary'}
            disabled={disabled || this.state.visible}
            onClick={(one, two, three) => {
              props.handler(one, two, three);
              this.setState({ visible: true });
              setTimeout(() => {
                this.setState({ visible: false });
              }, 5000);
            }}
            className={props.small ? props.classes.smallButton : undefined}
            children={<props.icon style={{ fill: 'white' }} />}
          />
        );
        if (props.csv) {
          res = (
            <IconButton>
              <CSVLink data={props.data} filename={props.filename}>
                {res}
              </CSVLink>
            </IconButton>
          );
        }
        if (props.name) {
          res = (
            <Tooltip
              disableInteractive
              title={this.state.visible ? '' : props.name}
              classes={{
                tooltip: props.classes.tooltip,
              }}
              placement="left"
            >
              {res}
            </Tooltip>
          );
        }
        if (props.palette) {
          const theme = {
            ...props.theme,
            palette: {
              ...props.theme.palette,
              primary: {
                light: props.palette[300],
                main: props.palette[500],
                dark: props.palette[700],
                contrastText: '#fff',
              },
            },
          };
          res = <ThemeProvider theme={theme}>{res}</ThemeProvider>;
        }
      }
      return res;
    };
    const contactCollapse = document.getElementById('contactCollapse');
    const buttonRightPosition = +(contactCollapse?.offsetWidth || 0) + 57 + 15;

    return (
      <div
        id='buttons'
        className={
          !sideBarStats
            ? `${classes.buttons} ${classes.bottom}`
            : classes.buttons
        }
        style={{ right: `${buttonRightPosition}px` }}
      >
        <SmallButton
          {...buttons[0]}
          classes={classes}
          theme={this.props.theme}
        />
        <div className={classes.subButtons}>
          {buttons
            .slice(1)
            .reverse()
            .map((e, key) => (
              <SmallButton
                key={key}
                {...e}
                small
                classes={classes}
                theme={this.props.theme}
              />
            ))}
        </div>
      </div>
    );
  }
}

MyButton.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object.isRequired,
      name: PropTypes.string,
      palette: PropTypes.object,
      condition: PropTypes.func,
      handler: PropTypes.func.isRequired,
    })
  ).isRequired,
};
export default compose(
  connect(mapStateToProps),
  withTheme
)(withStyles(MyButton, styles))
