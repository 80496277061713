import api from '../api';

import {
  RAISONSARCHIVESST_FETCH,
  RAISONSARCHIVESST_UPDATE
} from '../constants/ActionTypes';
import DbWsAction from './models/DbWsAction';

const action = new DbWsAction(
  RAISONSARCHIVESST_FETCH,
  RAISONSARCHIVESST_UPDATE
);

export const fetch = action.fetch;
export const update = action.update;

export const getRaisonsArchiveSst = filter =>
  api.raisonsArchiveSst.getAll(filter)
    .then(res => res.body().map(e => e.data()));
