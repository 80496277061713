import React, { PureComponent } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import getDataById from '@lba-dev/package.local-globals/getDataById';
import api from '../../../api';

import { dateToString, getTime } from '../../../utils/date';
import Money from '../../../utils/Money';
import notifSystem from '../../../notifSystem';
import { groupBy } from '@lba-dev/package.local-globals/functions';
import CallLogs from '../../Calls/CallLogs';
import { getClientNumbers } from '../../../utils/call';
import DisplayPath from '../DisplayPath';
import constants from '../../../constants/collections';
import devisMenuContainer from './devisMenuContainer';
import Timeline from '@mui/lab/Timeline';
import DevisMenuInfo from './DevisMenuInfo';
import { withStyles } from 'tss-react/mui';
import DevisSmartCallbackHistory from './DevisSmartCallbackHistory';

const styles = {
  padding: {
    padding: '12px',
  },
  title: {
    marginBottom: 4,
    textTransform: 'uppercase',
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  lineHeight: {
    lineHeight: 'normal',
  },
  a: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  timeLine: {
    margin: 0,
    padding: '6px 0px',
  },
  timeLineLimit: {
    maxHeight: 300,
    overflow: 'auto',
  },
};

const genInfoMail = function(type, date, user) {
  date = new Date(date);
  if (date) {
    return (
      <Typography key={`${type}-${date.getTime()}`} variant="body1">
        <b>{`${type}`}</b>
        {` le ${dateToString(date)} à ${getTime(date)} par ${user}`}
      </Typography>
    );
  }
  return null;
};

const genPrice = function(products) {
  let sum = 0;
  if (!(products && products.list)) {
    return Money.toString(sum);
  }
  products.list.forEach(e => {
    sum += e.qt * e.pu;
  });
  return Money.toString(sum);
};

const numberProduct = function(products) {
  if (!(products && products.list)) {
    return 0;
  }
  return products.list.length;
};

const isMulti = function(products) {
  if (products && (products.list || []).length) {
    const groupedBy = groupBy(products.list, 'comboIndex');
    return Object.keys(groupedBy).length > 1;
  }
  return 0;
};

const detailProduct = function(products) {
  let rows = [];
  if (!(products && products.list)) {
    return rows;
  }
  const isMulti = Object.keys(groupBy(products.list, 'comboIndex')).length > 1;
  products.list.forEach(e => {
    rows.push(
      `${e.ref} - ${e.name} ${
        isMulti ? '' : `: ${e.qt} * ${Money.toString(e.pu)} €`
      }`
    );
  });
  return rows;
};

class DevisMenu extends PureComponent {
  state = {
    artisan: {}
  };

  getArtisan = (artisanId) =>
    api.artisans.get(artisanId, {
      field: JSON.stringify({
        companyName: 1,
        name: 1
      })
    })
      .then(e => this.setState({
        artisan: e.body().data()
      }))
      .catch(() =>
        notifSystem.error(
          'Erreur', 'L\'artisan n\'a pas pu être récupéré.'
        ))

  componentDidMount() {
    const { elem } = this.props;
    if (elem && (elem.login || {}).artisan) {
      this.getArtisan(elem.login.artisan);
    }
  }

  getMultiPrice = products => {
    let groups = groupBy(products.list, 'comboIndex');
    return (
      <div>
        <br />
        Multi Devis
        <br />
        {Object.keys(groups).map((e, i) => {
          const price = products.list.reduce(
            (v, l) => v + (l.comboIndex === Number(e) ? l.pu * l.qt : 0),
            0
          );
          return (
            <li key={i}>
              {`${groups[e].length} Produit(s) 
            ( ${Money.toString(price, true)} )`}
            </li>
          );
        })}
      </div>
    );
  };

  render() {
    const { elem, devisHistories, classes } = this.props;
    const client = elem.client;

    const products = detailProduct(elem.products);
    return (
      <Grid container spacing={4} style={styles.padding}>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" style={styles.title}>
            Coordonnées client
          </Typography>
          <Typography variant="body1">
            {client.civility} {client.name} {client.firstname}
          </Typography>
          <Typography variant="body1">
            {client.address.number} {client.address.road}
          </Typography>
          <Typography variant="body1">
            {client.address.zipcode} {client.address.city}
          </Typography>
          <Typography variant="body1">
            <b>{elem.desc}</b>
          </Typography>
          <Typography variant="body1">
            {isMulti(elem.products)
              ? this.getMultiPrice(elem.products)
              : `${numberProduct(elem.products)} Produit(s) ( ${genPrice(
                elem.products
              )} € )`}
            <br />
            {`${
              !elem.onSitePayment
                ? ' Reglement sur Place'
                : ' Reglement par facture'
            } - ${getDataById.categories[elem.categorie].shortName}`}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <CallLogs
            keyUrl="inter"
            title="JOURNAL D'APPELS"
            numbers={getClientNumbers(elem)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {elem &&
              elem.date &&
              elem.login && [
            <Typography key={5} variant="h6" className={classes.title}>
                  Historique
            </Typography>,
            <div key={7} className={classes.timeLineLimit}>
              {devisHistories.map((data, i) => (
                <Timeline
                  key={i}
                  align="alternate"
                  className={classes.timeLine}
                >
                  <DevisMenuInfo {...data} />
                </Timeline>
              ))}
            </div>
          ]}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" style={styles.title}>
            Historique mail
          </Typography>
          {elem.date.read &&
            elem.login &&
            elem.date.read.map((_, i) => [
              genInfoMail('Lu', elem.date.read[i].readTime, elem.client.name),
            ])}
          <br />
          {products &&
            products.map((product, i) => <li key={i}> {product} </li>)}
        </Grid>
        <Grid item xs={12} md={4} />
        {
          elem.smartCallbackData && elem.smartCallbackData.length &&
            <DevisSmartCallbackHistory histories={elem.smartCallbackData} />
        }
        {elem.createdFrom && elem.createdFrom.path ? (
          <DisplayPath path={elem.createdFrom.path} from={constants.devis} />
        ) : null}
      </Grid>
    );
  }
}

export default devisMenuContainer(withStyles(DevisMenu, styles));
