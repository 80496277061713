import api from '../api';
import {
  COMBOS_FETCH,
  COMBOS_UPDATE
} from '../constants/ActionTypes';
import DbWsAction from './models/DbWsAction';

const action = new DbWsAction(
  COMBOS_FETCH,
  COMBOS_UPDATE
);

export const fetch = action.fetch;
export const update = action.update;

export const getSearch = ({ query, sort, limit, match }) =>
  api.combinations
    .custom('search')
    .get({
      query,
      page: 0,
      display: 4,
      sort,
      match,
      limit
    })
    .then(res => res.body().map(e => e.data()));
