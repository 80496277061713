import React from 'react';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import { getAliasFromPhone, cacheDisabled } from '../../../utils/function';
import AudioPlayer from '../../Calls/AudioPlayer';
import { moment } from '@lba-dev/package.local-globals/moment';
import { SHOW_ENR } from '@lba-dev/package.local-globals/configTypes';

const DetailPanel = ({ rowData, classes }) => (
  <Grid>
    <Grid lg={12}>
      <Table className={classes.uniquesAudios}>
        <TableHead>
          <TableRow>
            {(!cacheDisabled(SHOW_ENR) ?
              ['Heure', 'Agent', 'Audio'] :
              ['Heure', 'Agent'])
              .map(title => <TableCell key={title} children={title} />)}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.records.map((call, i) =>
            <TableRow key={i}>
              <TableCell key={'callStart'} >
                {moment(call.callStart).format('HH[h]mm')}
              </TableCell>
              <TableCell key={'agent'}>
                {getAliasFromPhone(call.agent
                  .replace(/^0033/, '0'))}
              </TableCell>
              {!cacheDisabled(SHOW_ENR) && <TableCell
                key={'urlAudio'}
                style={{
                  minWidth: 410,
                  maxWidth: 410,
                  width: 410
                }}
              >
                <AudioPlayer
                  url={call.fileUrl}
                  duration={call.duration}
                  noPadding hide
                />
              </TableCell>}
            </TableRow>)
          }
        </TableBody>
      </Table>
    </Grid>
  </Grid>
);


export default DetailPanel;
